import {
  FETCH_LETTER,
  FETCH_LETTER_SUCCESS,
  FETCH_LETTER_FOLDER,
  FETCH_LETTER_FOLDER_SUCCESS,
  FETCH_LETTER_BY_ID,
  FETCH_LETTER_BY_ID_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: true,
  openFullScreenLoader: false,
  letters: null,
  letter:null,
  folder: null,
  letterParams: null,
  folderParams: null,
  letterApiResponse:null,
  campaignIdOfThisItem: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case Constants.SUCCESS_PUT_LETTER:{
      return {
        ...state,
        openFullScreenLoader: false,
        letterApiResponse: action.apiResponse,
        letter: action.letter
      }
    }
    case Constants.FAILED_RENAME_LETTER:{
      return {
        ...state,
        openFullScreenLoader: false,
        letterApiResponse: action.apiResponse
      }
    }
    case Constants.RESET_LETTER_API_RESPONSE:{
      return {
        ...state,
        letterApiResponse: null
      }
    }
    case Constants.REQUEST_RENAME_LETTER:{
      return {
        ...state,
        openFullScreenLoader: true,
        letterApiResponse: null
      }
    }
    case Constants.SUCCESS_RENAME_LETTER: {
      return {
        ...state,
        openFullScreenLoader:false,
        letterApiResponse: action.apiResponse,
        letter: action.letterObj,
      };
    }
    case Constants.FAILED_RENAME_LETTER:{
      return {
        ...state,
        openFullScreenLoader:false,
        letterApiResponse: action.apiResponse
      }
    }
    case FETCH_LETTER: {
      return {
        ...state,
        loader: true,
        letterParams: action.payload
      };
    }
    case FETCH_LETTER_SUCCESS: {
      return {
        ...state,
        loader: false,
        letters: action.payload
      };
    }
    case FETCH_LETTER_FOLDER: {
      return {
        ...state,
        loader: true,
        folderParams: action.payload
      };
    }
    case FETCH_LETTER_FOLDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        folder: action.payload
      };
    }
    case FETCH_LETTER_BY_ID: {
      return { ...state, loader: true, letterDataParams: action.payload };
    }
    case FETCH_LETTER_BY_ID_SUCCESS: {
      return { ...state, loader: false, letterData: action.payload };
    }
    case "GET_LETTER": {
      return {
        ...state,
        loader: true,
        letter: null,
      };
    }
    case "GET_LETTER_SUCCESS": {
      return {
        ...state,
        loader: false,
        letter: action.data,
      };
    }
    case Constants.REQUEST_PUT_LETTER:{
      return {
        ...state,
        openFullScreenLoader: true,
        letterApiResponse: null
      }
    }
    case Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM:{
      return {
        ...state,
        campaignIdOfThisItem: null
      }
    }
    case Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE:{
      return {
        ...state,
        campaignIdOfThisItem: action.payload
      }
    }
    case Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM:{
      return {
        ...state,
        campaignIdOfThisItem: null
      }
    }
    default:
      return state;
  }
};
