import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const CustomViews = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/simple-list`}/> */}
            <Route
                path={`${match.url}/send`}
                component={asyncComponent(() => import("./routes/send"))}
            />
            <Route
                path={`${match.url}/schedule`}
                component={asyncComponent(() => import("./routes/schedule"))}
            />
            <Route
                path={`${match.url}/categories`}
                component={asyncComponent(() => import("./routes/catergories"))}
            />
            <Route
                path={`${match.url}/report/:type/:id`}
                component={asyncComponent(() =>
                    import("./routes/report/detailView")
                )}
            />
            <Route
                path={`${match.url}/report`}
                component={asyncComponent(() => import("./routes/report"))}
            />
            <Route
                path={`${match.url}/email/getHtml`}
                component={asyncComponent(() =>
                    import("./routes/email/getHtml")
                )}
            />
            <Route
                path={`${match.url}/email/test`}
                component={asyncComponent(() => import("./routes/email/test"))}
            />
            <Route
                path={`${match.url}/email/edit/:id`}
                component={asyncComponent(() => import("./routes/email/edit"))}
            />
            <Route
                path={`${match.url}/email/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/email/folderView")
                )}
            />
            <Route
                path={`${match.url}/email`}
                component={asyncComponent(() => import("./routes/email"))}
            />
            <Route
                path={`${match.url}/form/edit/:id`}
                component={asyncComponent(() => import("./routes/form/edit"))}
            />
            <Route
                path={`${match.url}/form/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/form/folderView")
                )}
            />
            <Route
                path={`${match.url}/form`}
                component={asyncComponent(() => import("./routes/form"))}
            />
            <Route
                path={`${match.url}/letter/edit/:id`}
                component={asyncComponent(() => import("./routes/letter/edit"))}
            />
            <Route
                path={`${match.url}/letter/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/letter/folderView")
                )}
            />
            <Route
                path={`${match.url}/letter`}
                component={asyncComponent(() => import("./routes/letter"))}
            />
            <Route
                path={`${match.url}/page/edit/:id`}
                component={asyncComponent(() => import("./routes/page/edit"))}
            />
            <Route
                path={`${match.url}/page/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/page/folderView")
                )}
            />
            <Route
                path={`${match.url}/page`}
                component={asyncComponent(() => import("./routes/page"))}
            />
            <Route
                path={`${match.url}/sms/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/sms/folderView")
                )}
            />
            <Route
                path={`${match.url}/sms`}
                component={asyncComponent(() => import("./routes/sms"))}
            />
            <Route
                path={`${match.url}/group/edit/:groupId`}
                component={asyncComponent(() =>
                    import("./routes/group/groupView")
                )}
            />
            <Route
                path={`${match.url}/group/:folderId`}
                component={asyncComponent(() =>
                    import("./routes/group/folderView")
                )}
            />
            <Route
                path={`${match.url}/group`}
                component={asyncComponent(() => import("./routes/group"))}
            />
            <Route
                path={`${match.url}/import`}
                component={asyncComponent(() => import("./routes/import"))}
            />
            <Route
                path={`${match.url}/filter/edit/:id`}
                component={asyncComponent(() => import("./routes/filter"))}
            />
            {/*<Route*/}
            {/*    path={`${match.url}/filter/edit/:id`}*/}
            {/*    component={asyncComponent(() => import("./routes/RelationFilter"))}*/}
            {/*/>*/}
            <Route
                path={`${match.url}/campaign/edit/:id`}
                component={asyncComponent(() =>
                    import("./routes/campaign/CampaignEdit")
                )}
            />
            <Route
                path={`${match.url}/campaign/test/:id`}
                component={asyncComponent(() =>
                    import("./routes/campaign/CampaignEditTree")
                )}
            />
            <Route
                path={`${match.url}/campaign/:id`}
                component={asyncComponent(() =>
                    import("./routes/campaign/campaignFolderView")
                )}
            />
            <Route
                path={`${match.url}/campaign`}
                component={asyncComponent(() =>
                    import("./routes/campaign")
                )}
            />
            <Route
                path={`${match.url}/import_translations`}
                component={asyncComponent(() =>
                    import("./routes/importTranslations")
                )}
            />
            <Route
                component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                )}
            />
        </Switch>
    </div>
);

export default CustomViews;
