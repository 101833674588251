import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import config from "config";

class SaveDialogBox extends React.Component {
  state = {
    checkedA: false
  };

  handleRequestClose = () => {
    this.props.handleRequestClose();
  };

  handleChange = name => (event, checked) => {
    this.setState({ [name]: checked });
  };

  handleRequestSave = async () => {
    const {
      token,
      tableName,
      selectedFields,
      orderingFields,
      templateId
    } = this.props;
    const { name, checkedA } = this.state;
    // console.log('name from edit ->', name);
    const response = await fetch(`${config.api.url}/api/template_save`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        template_table_name: tableName,
        template_new_save_name: "",
        template_action_type: "save",
        template_id: templateId,
        template_edit_name: "",
        template_new_name: "",
        template_listing_fields: selectedFields.join(","),
        template_ordering_fields: orderingFields,
        template_for_all_user: checkedA ? "1" : "0"
      })
    })
      .then(res => res.json())
      .then(json => json)
      .catch(e => {
        
      });

    if (response.status === "success") return window.location.reload();
  };

  render() {
    const { openSaveBox } = this.props;
    const label = <IntlMessages id="_SAVE_AS" />;

    return (
      <div>
        <Dialog open={openSaveBox} onClose={this.handleRequestClose}>
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <IntlMessages id="_SAVE_CURRENT_VIEW" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              <IntlMessages id="_CLOSE" />
            </Button>
            <Button onClick={this.handleRequestSave} color="primary">
              <IntlMessages id="_SAVE" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SaveDialogBox;
