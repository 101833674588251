import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";
import { userSignIn } from "actions/Auth";
import { fetchEditView } from "actions/EditView";
import {
	requestSave,
	requestSaveAndNext,
	requestSaveAndClose,
	requestAdd,
	requestDelete,
} from "../../../actions/AccountInformation";
import "./Style.css";
import { DatePicker } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import ContainerHeader from "components/ContainerHeader/index";
import CardBox from "components/CardBox";
import FullWidthTabs from "./components/FullWidthTabs";
import { Card, CardBody, CardHeader, CardSubtitle, CardText } from "reactstrap";

import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Toolbar, Typography, Grid } from "@material-ui/core";

import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";
import config from "config";
import EclicksProTour from "../customComponents/UserTour/EclicksProTour";
import { getFullUserInfo } from './../../../actions/Profile';
import { getAuthData } from "common/helpers/AuthHelper";
import { REQUEST_GET_USER_INFO } from './../../../common/Constants';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from "../customComponents/button/CustomButton";

const styles = {
	input1: {
		height: 1,
	},

};

const AlertBox = ({ flag, closeAlertBox, handleDelete }) => {
	const handleClose = () => {
		closeAlertBox();
	};

	return (
		<div>
			<Dialog
				open={flag}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<IntlMessages id="_ARE_YOU_SURE_TO_DELETE" />
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						<IntlMessages id="_CANCEL" />
					</Button>
					<Button onClick={handleDelete} color="primary" autoFocus>
						<IntlMessages id="_CONFIRM_DELETE" />
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

class EditViewApp extends React.Component {
	state = {
		selectedDate: null,
		disableBtn: true,
		flag: false,
		file: {
			logo: null,
			favicon: null,
			email_logo: null,
			background_image: null,
		},
		permission: [],
		notify_option_1: false,
		notify_option_2: false,
		notiifyPass1: "",
		notiifyPass2: "",
		userInfo: null,
		acountInfoBtnDisable: true,
		isCancelSubscriptionOpen: false
	};

	componentDidMount(){
		this.props.getFullUserInfo();
	}

	componentWillMount() {
		const { authUser, match, history } = this.props;
		if (match.params.menu === "filter") {
			return history.push(
				`/app/custom-views/${match.params.menu}/edit/${match.params.id}`
			);
		}
	}

	setDisable = (value) => {
		this.setState({ disableBtn: value });
	};

	handleTenantCancelSubscription = () =>{
		const id = this.props.match.params.menu;
		let {token} = getAuthData()
		let url = config.api.url+ "api/Subcriptions/cancelTenantSubscriptions/" + this.props.match.params.id;

		axios({
            method: "GET",
            url: url,
            headers: {
                Authorization: token,
            },
        })
		.then((res) => {
			// let apiResponse = getResponseForNotificaion(res)
			if (res.data.status === "success") {
				NotificationManager.success(res.data.message);
				this.setState({isCancelSubscriptionOpen: false});
			} else {
				NotificationManager.error("Operation not successful.");
				this.setState({isCancelSubscriptionOpen: false})
			}
		})
		.catch((e) => {
			NotificationManager.error("Operation not successful.");
			this.setState({isCancelSubscriptionOpen: false})
		});
	}

	handleTenantSubscriptionOpen = () =>{
		this.setState({isCancelSubscriptionOpen: true})
	}

	handleTenantSubscriptionClose = () =>{
		this.setState({isCancelSubscriptionOpen: false})
	}

	render() {
		const { authUser, match, history, loader, item } = this.props;
		const { notify_option_1, notify_option_2, notify_option_3 } = this.state;
		// console.log("this.props from edit view ---->>>", this.props);
		//  console.log("items ->", item);
		//  console.log("filee",this.state)
		return (
			<div className="app-wrapper">
				<div className="animated slideInUpTiny animation-duration-3"><EclicksProTour
					menu={this.props.match.params.menu}
					view={"edit"}
				/>
					<div className="row">
						<div className="col-md-6">
							<h1>
								Item:{" "}
								<span style={{ color: "#4caf50" }}>
									<IntlMessages id={"_" + match.params.menu.toUpperCase()} />{" "}
									{item && item.position_data && item.position_data.current_position} of{" "}
									{item && item.position_data && item.position_data.total_rows}
								</span>
								<span style={{ marginLeft: "20px" }}>
									<a href={`/app/listing/${match.params.menu}`}>
										<Button
											// onClick={() =>
											//   history.push({
											//     pathname: `/app/listing/${match.params.menu}`,
											//   })
											// }
											variant="outlined"
											className="jr-btn jr-btn-lg"
											style={{
												border: "1px #00bcd4 solid",
												color: "#00bcd4",
												marginTop: "5px",
											}}
										>
											<span>&#8676;</span> <IntlMessages id="_BACK_TO_LIST_PAGE" />
										</Button>
									</a>
								</span>
							</h1>
						</div>
						<div
							className="col-md-6"
							style={{
								display: "flex",
								justifyContent: "flex-end",
								flexWrap: "wrap",
							}}
						>
							{match.params.menu === "accounts" && this.props.accountInformation?.subcriptions_id &&  this.props.accountInformation?.mollie_customer_id && (
								<Button
									// disabled={this.props.disableAcInfoBtnInEditView}
									variant="contained"
									className="jr-btn bg-red text-white jr-btn-lg"
									onClick={this.handleTenantSubscriptionOpen}
									style={{
										maxHeight: "39.19px",
										minHeight: "39.19px",
										marginTop: "5px",
									}}
								>
                                    <IntlMessages id="_ECLICKSPRO_TENANTS_SUBSCRIPTION_CANCEL_TITLE" />
								</Button>
							 )}
							{match.params.menu !== "permissions_row" && (
								<Button
									disabled={this.props.disableAcInfoBtnInEditView}
									variant="contained"
									color="primary"
									className="jr-btn text-white jr-btn-lg"
									onClick={() => this.props.requestSave()}
									style={{
										maxHeight: "39.19px",
										minHeight: "39.19px",
										marginTop: "5px",
									}}
								>
									<IntlMessages id="_SAVE" />
								</Button>
							)}
							{item &&
								item.position_data.next_record_id !== 0 &&
								match.params.menu !== "permissions_row" && (
									<Button
										disabled={this.props.disableAcInfoBtnInEditView}
										variant="contained"
										color="primary"
										className="jr-btn text-white jr-btn-lg"
										onClick={() => this.props.requestSaveAndNext()}
										style={{
											maxHeight: "39.19px",
											minHeight: "39.19px",
											marginTop: "5px",
										}}
									>
										<IntlMessages id="_SAVE_AND_NEXT" />
									</Button>
								)}
							{match.params.menu !== "permissions_row" && (
								<Button
									disabled={this.props.disableAcInfoBtnInEditView}
									variant="contained"
									color="primary"
									className="jr-btn text-white jr-btn-lg"
									onClick={() => this.props.requestSaveAndClose()}
									style={{
										maxHeight: "39.19px",
										minHeight: "39.19px",
										marginTop: "5px",
									}}
								>
									<IntlMessages id="_SAVE_AND_CLOSE" />
								</Button>
							)}
							{item &&
								item.add_delete_permissions.add_permission === "1" &&
								match.params.menu !== "permissions_row" && (
									<Button
										variant="contained"
										className="jr-btn bg-cyan text-white jr-btn-lg"
										style={{
											maxHeight: "39.19px",
											minHeight: "39.19px",
											marginTop: "5px",
										}}
										onClick={() => this.props.requestAdd()}
									>
										{<IntlMessages id="_ADD" />}
									</Button>
								)}

							{item && item.add_delete_permissions.delete_permission === "1" && (
								<Button
									variant="contained"
									className="jr-btn bg-red text-white jr-btn-lg"
									onClick={() => this.props.requestDelete()}
									style={{
										maxHeight: "39.19px",
										minHeight: "39.19px",
										marginTop: "5px",
									}}
								>
									<IntlMessages id="_DELETE" />
								</Button>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							{
								<div id="editView">
									{
										<div className="row">
											<CardBox styleName="col-lg-12">
												{
													<FullWidthTabs
														ref="fullWidthTab"
														history={history}
														authUser={authUser}
														disableBtn={this.setDisable}
														match={match}
													/>
												}
											</CardBox>
										</div>
									}
								</div>
							}
						</div>
					</div>

					<NotificationContainer />
					<AlertBox
						flag={this.state.flag}
						closeAlertBox={this.closeAlertBox}
						handleDelete={this.handleDelete}
					/>
				</div>

				<Dialog
                        open={this.state.isCancelSubscriptionOpen}
                        fullWidth={false}
                        // maxWidth={maxWidth}
                        onClose={this.handleTenantSubscriptionClose}
                        aria-labelledby="custom-dailog-title"
                    >
                        <AppBar position="static">
                            <Toolbar variant="dense">
                                <Grid container>
                                    <Grid item md={11}>
                                        <Typography variant="h6" color="inherit">
                                            <IntlMessages id="_ECLICKSPRO_TENANTS_SUBSCRIPTION_CANCEL_TITLE" />
                                        </Typography>
                                    </Grid>
                                    <Grid container item md={1} justify="flex-end">
                                        {/*this.handleDelete*/}
                                        <IconButton
                                            size="small"
                                            className="actionDialogTitleButton"
                                            onClick={this.handleTenantSubscriptionClose}
                                        >
                                            <CloseIcon style={{ color: "white" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <DialogContent dividers>
                            <DialogContentText>
                                <IntlMessages id="_ECLICKSPRO_TENANTS_SUBSCRIPTION_CANCEL_DIALOG_CONTENT" />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CustomButton color="red" onClick={this.handleTenantCancelSubscription}>
                                <IntlMessages id="_OK" ></IntlMessages>
                            </CustomButton>
                            <CustomButton color="grey" onClick={this.handleTenantSubscriptionClose}>
                                <IntlMessages id="_CLOSE"></IntlMessages>
                            </CustomButton>
                        </DialogActions>
                    </Dialog>
			</div>
		);
	}
}

const mapStateToProps = ({ auth, editView, accountInformation, profile }) => {
	// console.log("auth->", auth);
	// console.log("editview->", editView);
	const { authUser } = auth;
	const { loader, params, item } = editView;
	return {
		authUser,
		loader,
		params,
		item,
		disableAcInfoBtnInEditView: accountInformation.disableAcInfoBtnInEditView,
		accountInformation: profile?.info?.accountDetails
	};
};

const EditView = compose(
	connect(mapStateToProps, {
		userSignIn,
		fetchEditView,
		requestSave,
		requestAdd,
		requestDelete,
		requestSaveAndNext,
		requestSaveAndClose,
		getFullUserInfo
	}),
	withStyles(styles)
);

export default EditView(EditViewApp);
