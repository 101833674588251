import React from "react";

//material ui
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

//custom
import CustomIcon from "../../../../../customComponents/icon/CustomIcon";
import {eclicksProColors} from "../../../../../../../common/functions/EproCommonFunctions";
import IntlMessages from "../../../../../../../util/IntlMessages";


const useStyle = makeStyles({
    packageAddFormWrapper:{
        margin:"0px 15px 0px 15px"
    },
    packageAddForm:{
        // margin:"0px 15px 0px 15px"
    },
    formHeader:{
        // padding:"10px"
    },
    formHeaderTitle:{
        margin:"10px 0px 0px 10px"
    },
    packageInfoCard:{
        padding:"15px",
        borderRadius:"5px"
    },
    creditFormBody:{
        padding:"15px 20px 20px 20px",
        // margin:"0px 0px 20px 0px"
    },
    form:{
        '& > *':{
            marginBottom:"10px"
        }
    }

})

const PackageAddFormPaper = withStyles({
    root:{
        backgroundColor:"#F6F9F6",
        border:`1px solid ${eclicksProColors.green}`
    }
})(Paper)


function CreditAddForm(props) {
    const classes = useStyle()
    const {packageInfo,types=[]} = props

    function handleChange(event){
        let packageId = event.target.id.split("_")[1]
        let valueFor = event.target.id.split("_")[2].replace("X","_")
        let value = event.target.value;
        props.handlePackages(packageId,valueFor,value)
    }

    function handleDelete(event){
        let packageId = event.target.id.split("-")[1];
        if(packageId){
            props.handleDelete(packageId)
        }
    }
    return(
        <Grid item md={2}>
            <div className={classes.packageAddFormWrapper}>
                <PackageAddFormPaper elevation={3}>
                    <div className={classes.packageAddForm}>
                        <div className={classes.formHeader}>
                            <Grid container>
                                <Grid item md={10}>
                                    <div className={classes.formHeaderTitle}>
                                        <h3>{<IntlMessages id={"_ECLICKSPRO_ADD_CREDIT"}/>}</h3>
                                    </div>
                                </Grid>
                                <Grid item md={2}>
                                    <IconButton id={`contact-${packageInfo.id}-del-btn`} onClick={handleDelete}>
                                        <CustomIcon id={`contact-${packageInfo.id}-del-icon`} name={"CloseIcon"} color={eclicksProColors.red}></CustomIcon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{margin:"10px 0 2px 0"}}>
                            <Divider/>
                        </div>

                        <div className={classes.creditFormBody}>
                            <form className={classes.form}>
                                <TextField id={`credit_${packageInfo.id}_creditXamount`} onChange={handleChange} fullWidth={true} value={packageInfo.credit_amount} label={<IntlMessages id={"_ECLICKSPRO_CREDIT_AMOUNT"}/>}/>
                                <TextField id={`credit_${packageInfo.id}_price`} onChange={handleChange} fullWidth={true} value={packageInfo.price} label={<IntlMessages id={"_ECLICKSPRO_PRICE"}/>}/>
                            </form>
                        </div>







                    </div>
                </PackageAddFormPaper>
            </div>
        </Grid>

    )
}
export default CreditAddForm