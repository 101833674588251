import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
// import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "actions/Auth";

import ReCAPTCHA from "react-google-recaptcha";
import config from "config";
import { bindActionCreators } from "redux";
import { actionCreators } from "actions/Auth";
import { getAuthData } from "../common/helpers/AuthHelper";


let listFor = "auth"
let responseName = "authApiResponse"

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      g_recaptcha_response: ""
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let user = getAuthData()
    // if(this.props.auth.authUser !== null){
    //   this.props.history.push("/app/home");
    // }
    if (prevProps[listFor][responseName] !== this.props[listFor][responseName] && this.props[listFor][responseName] !== null) {
      //let res = this.props.apiResponse
      NotificationManager[this.props[listFor][responseName].alertType](this.props[listFor][responseName].message)
      this.props.resetResponse()
    }
  }

  onChangeCaptcha = value => {
    this.setState({ g_recaptcha_response: value });
  };
  handleUserSignIn = () => {
    this.props.signIn(this.state.email, this.state.password)
  }
  handleChange = (event) => {
    event.preventDefault()
    let id = event.target.id
    this.setState({
      [id]: event.target.value
    })
  }

  render() {
    const { email, password, g_recaptcha_response, captchaStatus } = this.state;
    const { showMessage, alertMessage, history } = this.props;
    let loader = this.props.auth.loader
    let user = getAuthData()
    if(user.token){
      return <Redirect to="/app/home"/>
    }
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="MyBizzmail">
              <img
                src={require("assets/images/logo.png")}
                alt="bizzmail"
                title="MyBizzmail"
                width="250"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="_EMAIL" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="_EMAIL" />}
                    id={"email"}
                    fullWidth
                    onChange={this.handleChange}
                    // defaultValue={email}
                    value={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="_PASSWORD" />}
                    fullWidth
                    id={"password"}
                    onChange={this.handleChange}
                    value={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  {/*{localStorage.getItem("captchaStatus") == 1 && (*/}
                  {/*  <ReCAPTCHA*/}
                  {/*    sitekey={config.siteKey}*/}
                  {/*    onChange={this.onChangeCaptcha}*/}
                  {/*  />*/}
                  {/*)}*/}

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      // onClick={() => {
                      //   this.props.showAuthLoader();
                      //   this.props.userSignIn({
                      //     email,
                      //     password,
                      //     g_recaptcha_response
                      //   });
                      // }}
                      onClick={this.handleUserSignIn}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="_LOGIN" />
                    </Button>

                    <Link to={{pathname: "https://funnel.e-clicks.pro/form/register-e-clicks"}} target="_blank">
                      <IntlMessages id="_SIGN_UP" />
                    </Link>
                  </div>
                  <Link to="/resetPassword">
                    <IntlMessages id="_RESET_PASSWORD" />
                  </Link>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

// const mapStateToProps = ({ auth }) => {
//   console.log("auth ->", auth);
//   const { loader, alertMessage, showMessage, authUser } = auth;
//   return { loader, alertMessage, showMessage, authUser };
// };
//
// export default connect(mapStateToProps, {
//   userSignIn,
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGoogleSignIn,
//   userGithubSignIn,
//   userTwitterSignIn
// })(SignIn);

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(SignIn)
