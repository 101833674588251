import React from "react";
import PublishIcon from "@material-ui/icons/Publish";
import IntlMessages from "util/IntlMessages";
import { Button, Select, MenuItem, TextField } from "@material-ui/core";
import { convertStringToCurrency, eclicksProColors, reverseString } from "../../../../common/functions/EproCommonFunctions";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const numberWithCommas = (x) => {
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    x = reverseString(x)
    let re = ""
    let commaPositions = [3,5,7,9,11,13,15,17]
    for(let i = 0; i< x.length; i++){
        if(commaPositions.includes(i)){
            re = re + "," + x[i]
        }else {
            re = re + x[i]
        }
    }
    re = reverseString(re)
    return re
};

const useStyles = theme => ({
    packageDetailItem:{
        marginTop:"-15px",
        fontSize:"12px",
        lineHeight:"0.1",
        '& span':{
            fontWeight:"bold"
        }
    },
    packageDetailTitle:{
        color: eclicksProColors.green,
        fontSize: "14px",
        fontWeight: "bold",
        marginLeft: "10px"
    },
    currentPackageWrapper:{
        padding:"20px 10px 0px 20px",
        minHeight: "219px"
    },
    paperWrapper:{
        padding:"20px",
    },
    paperOuterWrapper:{
        width:"100%"
    },
    currentPackageInfo:{
        paddingTop:"20px",
        paddingLeft:"10px",
        height: "100%",
        minHeight:"160px"
    },
    '@media screen and (max-width: 1580px)':{
        packageDetailTitle:{
            color: eclicksProColors.green,
            fontSize: "12px",
            fontWeight: "bold",
            marginTop: "5px",
            marginLeft: "0px"
        },
        currentPackageWrapper:{
            padding:"20px 10px 0px 20px"
        },
        packageDetailItem:{
            marginTop: "-12px",
            fontSize:"10px",
            //color:"red",
            '& span':{
                fontWeight:"bold"
            }
        },
        currentPackageInfo:{
            paddingTop:"20px",
            paddingLeft:"2px",
            height: "100%",
            width: "100%",
            // overflow:"auto",
            //margin:"0px -15px -15px 0px"
            //"overflow-x":"hidden"
        },
    }

});

class PackageDetail extends React.Component {
    state = {
        value: "0",
    };

    handleClickOrder = () =>{
        this.props.handleClickOrder()
    }
    handleChangeCredit = (event) =>{
        this.props.handleChangeCredit(event.target.value)
    }
    render() {
        const { data, relations, totalSales,defaultMoneyFormat,showPackageUpdate,showSendCredit,enableBuyNowBtn,buyNowBtnStatus,enableSelectAmount,selectAmountStatus,credits,totalCredit, classes,selectedCredit,selectedCreditPrice,selectedCreditAmount,currentPackage=null, totalRelations } = this.props;
        return(
            <div>
                <Grid container spacing={3}>
                    {showPackageUpdate &&
                    <Grid item lg={8} md={12} sm={12}>
                        <Paper elevation={3}>
                            <div className={classes.paperWrapper}>
                                <Grid container spacing={3}>
                                    <Grid item md={5}>
                                        <Link to={"/app/product-upgrade"}>
                                            <div
                                                style={{
                                                    backgroundColor: "#4CAF50",
                                                    height: "180px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    border: "1px solid #4CAF50",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <PublishIcon
                                                    htmlColor="white"
                                                    style={{ fontSize: "100px" }}
                                                />
                                                <h2
                                                    style={{
                                                        color: "white",
                                                        fontWeight: "500",
                                                        textAlign: "center",
                                                        width: "120px",
                                                    }}
                                                >
                                                    {/* <IntlMessages id="_ECLICKSPRO_UPGRADE_TODAY" /> */}
                                                    Upgrade Today
                                                </h2>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item md={7}>
                                        <div
                                            style={{
                                                height: "180px",
                                                backgroundColor: "#e8f5e9",
                                                border: "1px solid #e8f5e9",
                                                borderRadius: "5px",
                                                padding: "10px 0px 0px 20px",
                                            }}
                                        >
                                            <p>
                                                <strong>
                                                    <IntlMessages id="_ECLICKSPRO_CURRENT_PACKAGE" />
                                                </strong>
                                            </p>
                                            {currentPackage && currentPackage.current_package &&
                                            <div style={{marginTop:"-5px"}}>
                                                <p style={{ fontSize: "20px", color: "#4CAF50" }}>
                                                    €<span style={{ fontSize: "20px" }}>{`${currentPackage.price}`}</span>/{`${currentPackage.pay_per}`}
                                                </p>
                                            </div>
                                            }
                                            <div style={{height:"100px",overflow:"auto",marginBottom:"-10px"}}>
                                                {currentPackage && currentPackage.current_package &&
                                                <p><IntlMessages id={`_ECLICKSPRO_PACKAGE_DESCRIPTION_FOR_${currentPackage.current_package.toUpperCase()}`}/></p>
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>
                    }

                    <Grid item lg={4} md={12} sm={12}>
                        {/*<div className={classes.paperOuterWrapper}>*/}
                        <Paper elevation={3}>
                            <Grid container justify={'center'}>
                                <Grid item md={12}>
                                    <Paper>
                                        <div className={classes.currentPackageWrapper}>
                                            <Grid container justify={'center'}>
                                                <Grid container justify={'flex-start'} item md={12}>
                                                    <p className={classes.packageDetailTitle}>
                                                        <IntlMessages id="_ECLICKSPRO_CURRENT_PACKAGE_INFORMATION" />
                                                    </p>
                                                </Grid>
                                            </Grid>
                                            {currentPackage ?
                                                <div className={classes.currentPackageInfo}>
                                                    <Grid container spacing={3} justify={'center'}>
                                                        <Grid item  md={6}>
                                                            <div className={classes.packageDetailItem}>
                                                                <p><IntlMessages id={"_ECLICKSPRO_PACKAGES"}/>:</p>
                                                                {/*<p><span>{currentPackage.current_package}</span></p>*/}
                                                                <p><span><p><IntlMessages id={`_ECLICKSPRO_${currentPackage.current_package.toUpperCase()}_PACK_NAME`} /></p>{}</span></p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <div className={classes.packageDetailItem}>
                                                                <p><IntlMessages id={"_ECLICKSPRO_PAY_PER"}/></p>
                                                                <p><span>{currentPackage.pay_per}</span></p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <div className={classes.packageDetailItem}>
                                                                <p><IntlMessages id={"_FROM"}/></p>
                                                                <p><span>{currentPackage.start_date}</span></p>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <div className={classes.packageDetailItem}>
                                                                <p><IntlMessages id={"_TO"}/></p>
                                                                <p><span>{currentPackage.end_date}</span></p>
                                                            </div>
                                                        </Grid>

                                                        <Grid item md={12}>
                                                            <div className={classes.packageDetailItem}>
                                                                <p><IntlMessages id={"_ECLICKSPRO_REMAINING"}/></p>
                                                                <p><span>{currentPackage.remaining.toString().substring(0,70)}</span></p>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                :
                                                <div className={classes.currentPackageInfo}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <p>No Package Selected</p>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                            }
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper>
                        {/*</div>*/}
                    </Grid>


                    {showSendCredit &&
                    <Grid item lg={6} md={12} sm={12}>
                        <div className={classes.paperOuterWrapper}>
                            <Paper elevation={3}>
                                <div className={classes.paperWrapper}>
                                    <p>
                                        <strong>
                                            <IntlMessages id="_ECLICKSPRO_SEND_CREDIT"/>
                                        </strong>
                                    </p>
                                    <p style={{ fontSize: "20px", color: "#4CAF50" }}>
                                        <IntlMessages id="_NUMBER_CREDITS"/> {totalCredit}
                                    </p>
                                    {
                                        selectAmountStatus !== "0" && <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                        className="mb-3"
                                    >
                                        <div style={{ flexGrow: 1 }}>
                                            <label htmlFor="amount" className="mb-1">
                                                {" "}
                                                <IntlMessages id="_SELECT_AMOUNT"/>
                                            </label>
                                            <br/>
                                            <Select
                                                id="amount"
                                                style={{ padding: "4px", width: "85%" }}
                                                value={selectedCreditAmount}
                                                // disabled={!enableSelectAmount}
                                                disabled={selectAmountStatus === "1"}
                                                onChange={this.handleChangeCredit}
                                            >
                                                <MenuItem
                                                    value="0"
                                                    style={{ justifyContent: "flex-end", display: "flex" }}
                                                >
                                                    <em>Please Select</em>
                                                </MenuItem>
                                                {credits.map((credit, index) => (
                                                    <MenuItem
                                                        key={credit + "-" + index}
                                                        value={credit.credit_amount}
                                                        style={{ justifyContent: "flex-end", display: "flex" }}
                                                    >
                                                        {numberWithCommas(credit.credit_amount)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div>
                                            <label htmlFor="price" className="mb-1">
                                                {" "}
                                                <IntlMessages id="_PRICE"/>
                                            </label>
                                            <br/>
                                            <TextField
                                                id="price"
                                                value={selectedCreditPrice}
                                                disabled={true}
                                                margin="normal"
                                                style={{
                                                    width: "100%",
                                                    marginTop: "9px",
                                                }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: "end",
                                                        paddingRight: "10px",
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    }
                                    
                                    {
                                        buyNowBtnStatus !== "0" && <Button
                                            variant="contained"
                                            className="jr-btn bg-green text-white"
                                            style={{ width: "120px" }}
                                            // disabled={!enableBuyNowBtn}
                                            disabled={buyNowBtnStatus === "1" || selectedCreditPrice.toString() === '0'}
                                            onClick={this.handleClickOrder}
                                        >
                                            Buy Now
                                        </Button>
                                    }
                                    
                                </div>
                            </Paper>
                        </div>
                    </Grid>
                    }

                    <Grid item lg={3} md={12} sm={12}>
                        <div className={classes.paperOuterWrapper}>
                            <Paper elevation={3}>
                                <div className={classes.paperWrapper}>
                                    <Grid container justify={'center'}>
                                        <Grid item>
                                            <h4 style={{ width: "130px", textAlign: "center", margin: "2px" }}>
                                                <IntlMessages id="_ECLICKSPRO_TOTAL_NUMBER_RELATION" />
                                            </h4>
                                            <p style={{ fontSize: "40px", textAlign:"center", color: "#4CAF50" }}>{relations}</p>
                                        </Grid>
                                        <Grid item>
                                            <h4 style={{ width: "130px", textAlign: "center", margin: "2px" }}>
                                                {/* <IntlMessages id="_ECLICKSPRO_TOTAL_NUMBER_RELATION" /> */}
                                                Total Relations
                                            </h4>
                                            <p style={{ fontSize: "40px", textAlign:"center", color: "#4CAF50", marginBottom: 0 }}>{totalRelations}</p>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Paper>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} container justify={'center'}>
                        <div className={classes.paperOuterWrapper}>
                            <Paper elevation={3}>
                                <div className={classes.paperWrapper}>
                                    <Grid container justify={'center'}>
                                        <Grid item>
                                            <h4 style={{ width: "130px", textAlign: "center", margin: "2px 0 40px 0" }}>
                                                <IntlMessages id="_ECLICKSPRO_TOTAL_SALES_PER_MONTH" />
                                            </h4>
                                            <p style={{ fontSize: "23px", textAlign:"center", color: "#4CAF50", margin: "0 0 87px 0"  }}>{convertStringToCurrency(totalSales, defaultMoneyFormat, ".").formattedPrice}</p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </div>
                    </Grid>



                </Grid>

            </div>
        )
    }

}
export default withStyles(useStyles)(PackageDetail)