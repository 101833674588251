import React, { useEffect } from "react";

//material ui
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IntlMessages from "../../../../../../util/IntlMessages";
import { Tooltip } from "@material-ui/core";
import { eclicksProColors } from "../../../../../../common/functions/EproCommonFunctions";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators} from "../../../../../../actions/Profile";

//Others
import ContainerHeader from "../../../../../../components/ContainerHeader/index"

const useStyle = makeStyles({
    header:{

    },
    historyBody:{
        marginTop:"38px",
        // padding:"20px 0px"
    },
    tableViewHeader:{
        padding:"20px 10px 2px 10px",
        color:eclicksProColors.textGray,
        fontWeight:"bold",
        margin:"20px 0px"
    },
    tableViewRow:{
        border:`1px solid #F2F2F2`,
        borderRadius:"5px",
        padding:"10px 10px",
        margin:"20px 5px",
        color:eclicksProColors.textGray,
        '&:hover':{
            backgroundColor:"#F2F2F2"
        }
    }
})

const PaymentHistoryPaper = withStyles({

})(Paper)

function PaymentHistory(props) {
    const classes = useStyle()
    const {match} = props
    const {loader,paymentHistory} = props.profileData

    //componentDidMount
    useEffect(()=>{
        props.getPaymentHistory()
    },[])

    const handleBack = () =>{
        props.history.push("/app/home")
    }
    const handleClickOnRow = (event) =>{
        debugger
        let id = event.currentTarget.id.split("-")[1]
        props.history.push(`/app/payment-invoice/${id}`)
    }
    return(
        <React.Fragment>
            <div className={"app-wrapper"}>
                <ContainerHeader
                    title={"Payment History"}
                    //name={"abc"}
                    match={match}
                />

                <div className={classes.header}>
                    <Grid container>
                        {/* <Grid item>
                            <h1 style={{marginTop: "4px"}}>{"Payment History"}</h1>
                        </Grid> */}
                        <Grid item>
                            <Tooltip title={<IntlMessages id="_BACK"/>}>
                                <Button
                                    variant="contained"
                                    style={{
                                        maxHeight: "34.19px",
                                        minHeight: "34.19px",
                                        //marginLeft: "20px",
                                        marginTop: "5px",
                                        color: "white",
                                        backgroundColor: "#2196F3",
                                        borderRadius: "5px"
                                    }}
                                    onClick={handleBack}
                                >
                                    {<IntlMessages id="_BACK"/>}
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.historyBody}>
                    {loader?
                        <div>
                            Loading......
                        </div>
                        :
                        <div>
                            <PaymentHistoryPaper elevation={2} square={false}>
                                <div style={{margin:"0px 30px 0px 30px",paddingBottom:"30px"}}>
                                    <div className={classes.tableViewHeader}>
                                        <Grid container justify={"center"} spacing={3}>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_PACKAGE_TYPE"}/>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_START_DATE"}/>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_END_DATE"}/>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_PAYMENT_DETAIL"}/>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_PRICE"}/>
                                            </Grid>
                                            <Grid item md={2}>
                                                <IntlMessages id={"_ECLICKSPRO_STATUS"}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                    {paymentHistory.map((h,i) =>{
                                        return(
                                        <div key={`row-${i}`} className={classes.tableViewRow} id={`payment-${h.id}`} onClick={handleClickOnRow}>
                                            <Grid container justify={"center"} spacing={3}>
                                                <Grid item md={2}>{h.package_type}</Grid>
                                                <Grid item md={2}>{h.start_date}</Grid>
                                                <Grid item md={2}>{h.end_date}</Grid>
                                                <Grid item md={2}>{h.end_date}</Grid>
                                                <Grid item md={2}>{`€${h.price}`}</Grid>
                                                <Grid item md={2}>{h.payment_status}</Grid>
                                            </Grid>
                                        </div>
                                    )
                                    })}
                                </div>
                            </PaymentHistoryPaper>
                        </div>    
                    }


                </div>
            </div>

        </React.Fragment>
    )
}
//export default PaymentHistory
const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(PaymentHistory);