import React, {Component} from "react";
import {NavLink, Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
// import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

import {userMenu} from "actions/Menu";
import {userSignOut, showAuthLoader, userSignIn} from "actions/Auth";
import {MenuContext} from "context/MenuContext";
import "./SideNav.css"

var tempPathname;

class SidenavContent extends Component {
    static contextType = MenuContext;
    state = {
        menu: null,
    };

    componentDidMount() {
        const {history, authUser} = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`; // get current path
        tempPathname = pathname;

        this.props.userMenu(authUser);

        const menuLi = document.getElementsByClassName("menu");
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                const parentLiEle = that.closest(this, "li");
                if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
                    event.stopPropagation();

                    if (menuLi[i].classList.contains("open")) {
                        menuLi[i].classList.remove("open", "active");
                    } else {
                        menuLi[i].classList.add("open", "active");
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, "li");
                        if (
                            menuLi[j] !== this &&
                            (parentLi === null || !parentLi.classList.contains("open"))
                        ) {
                            menuLi[j].classList.remove("open");
                        } else {
                            if (menuLi[j].classList.contains("open")) {
                                menuLi[j].classList.remove("open");
                            } else {
                                menuLi[j].classList.add("open");
                            }
                        }
                    }
                }
            };
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
        try {
            const activeNav = this.closest(activeLi, "ul"); // select closest ul
            if (activeNav) {
                if (activeNav.classList.contains("sub-menu")) {
                    this.closest(activeNav, "li").classList.add("open");
                } else {
                    this.closest(activeLi, "li").classList.add("open");
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    componentDidUpdate() {
        const {history} = this.props;
        const that = this;
        let pathname = `${history.location.pathname}`; // get current path
        if (pathname.includes(tempPathname) || pathname.includes("/edit/listing")) {
            pathname = tempPathname;
        } else {
            tempPathname = pathname;
        }


        const menuLi = document.getElementsByClassName("menu");
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                const parentLiEle = that.closest(this, "li");
                if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
                    // event.stopPropagation();

                    if (menuLi[i].classList.contains("open")) {
                        menuLi[i].classList.remove("open", "active");
                    } else {
                        menuLi[i].classList.add("open", "active");
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, "li");
                        if (
                            menuLi[j] !== this &&
                            (parentLi === null || !parentLi.classList.contains("open"))
                        ) {
                            menuLi[j].classList.remove("open");
                        } else {
                            if (menuLi[j].classList.contains("open")) {
                                menuLi[j].classList.remove("open");
                            } else {
                                menuLi[j].classList.add("open");
                            }
                        }
                    }
                }
            };
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
        try {
            const activeNav = this.closest(activeLi, "ul"); // select closest ul
            if (activeNav) {
                if (activeNav.classList.contains("sub-menu")) {
                    this.closest(activeNav, "li").classList.add("open");
                } else {
                    this.closest(activeLi, "li").classList.add("open");
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    componentWillReceiveProps(nextProps) {
        const {history} = nextProps;
        let pathname = `${history.location.pathname}`; // get current path
        if (pathname.includes(tempPathname) || pathname.includes("/edit/listing")) {
            pathname = tempPathname;
        } else {
            tempPathname = pathname;
        }


        const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
        try {
            const activeNav = this.closest(activeLi, "ul"); // select closest ul
            if (activeNav) {
                if (activeNav.classList.contains("sub-menu")) {
                    this.closest(activeNav, "li").classList.add("open");
                } else {
                    this.closest(activeLi, "li").classList.add("open");
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            [
                "matches",
                "webkitMatchesSelector",
                "mozMatchesSelector",
                "msMatchesSelector",
                "oMatchesSelector",
            ].some(function (fn) {
                if (typeof document.body[fn] === "function") {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    renderMenu = (menus) => {
        const {history} = this.props;

        return (
            <div>
                {menus.child &&
                menus.child.map((menu) => {
                    return (
                        <li className="menu">
                            <NavLink to={"/app/" + menu.name + "/listing"}>
                                {/* <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" /> */}
                                <span className="nav-text">
										<IntlMessages id={"_" + menu.name.toUpperCase()}/>
									</span>
                            </NavLink>
                        </li>
                    );
                })}
            </div>
        );
    };

    render() {
        // const menus = this.context.menu;
        const {loader, menus} = this.props;
        if (menus && menus.status === "error") {
            localStorage.clear();
            window.location.reload();
        }
        return (
            <CustomScrollbars className=" scrollbar">
                {!menus ? (
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                ) : (
                    <ul className="nav-menu">
                        {menus &&
                        menus.data[0].child.map((menu) => {
                            let dynamicClass = "menu curserPointer";
                            let currentUrl = window.location.href.toLowerCase()
                            let isExist = currentUrl.includes(menu.name.toLowerCase())
                            if (isExist) {
                                dynamicClass = dynamicClass + " open"
                            }
                            if (menu.has_child === 0) {
                                dynamicClass = dynamicClass + " no-arrow";
                                // dynamicRoute = "/app/listing/" + menu.name;
                            }
                            return (
                                <li key={Math.random()} className={dynamicClass}>
                                    {menu.name === "help" ? (
                                        <Link
                                            target="_blank"
                                            to={{pathname: "https://e-clicks.pro/e-clicks-help/"}}
                                        >
												<span className="nav-text">
													<IntlMessages id={"_" + menu.name.toUpperCase()}/>
												</span>
                                        </Link>
                                    ) : menu.has_child === 1 ? (
                                        <a href={undefined}>
                                            {/* <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" /> */}
                                            <span className="nav-text">
													<IntlMessages id={"_" + menu.name.toUpperCase()}/>
												</span>
                                        </a>
                                    ) : (
                                        <NavLink
                                            to={
                                                menu.name === "email" ||
                                                menu.name === "sms" ||
                                                menu.name === "Letter" ||
                                                menu.name === "form" ||
                                                menu.name === "page" ||
                                                menu.name === "report" ||
                                                menu.name === "campaign"
                                                    ? "/app/custom-views/" + menu.name
                                                    : "/app/listing/" + menu.name
                                            }
                                        >
                                            {/* <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" /> */}
                                            <span className="nav-text">
													<IntlMessages id={"_" + menu.name.toUpperCase()}/>
												</span>
                                        </NavLink>
                                    )}
                                    {menu.child.length > 0 && (
                                        <ul className="sub-menu">
                                            {menu.child.map((menu) => {
                                                if (
                                                    menu.name === "send" ||
                                                    menu.name === "schedule" ||
                                                    menu.name === "group" ||
                                                    menu.name === "categories" ||
                                                    menu.name === "import"||
                                                    menu.name === "import_translations"||
                                                    menu.name === "Subscription-fee"||
                                                    menu.name === "Credit"||
                                                    menu.name === "Payment-History"
                                                ) {
                                                    return (
                                                        <li key={Math.random()} className="menu no-arrow">
                                                            <NavLink to={"/app/custom-views/" + menu.name}>
                                                                {/* <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" /> */}
                                                                <span className="nav-text">
																		<IntlMessages
                                                                            id={"_" + menu.name.toUpperCase()}/>
																	</span>
                                                            </NavLink>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li key={Math.random()} className="menu no-arrow">
                                                            <NavLink to={"/app/listing/" + menu.name}>
                                                                {/* <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw" /> */}
                                                                <span className="nav-text">
																		<IntlMessages
                                                                            id={"_" + menu.name.toUpperCase()}/>
																	</span>
                                                            </NavLink>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </CustomScrollbars>
        );
    }
}

const mapStateToProps = ({menu, auth}) => {
    const {loader, menus} = menu;
    const {authUser} = auth;
    return {loader, menus, authUser};
};

// export default withRouter(SidenavContent);
export default compose(
    withRouter,
    connect(mapStateToProps, {
        userMenu,
        userSignOut,
        userSignIn,
        showAuthLoader,
    })
)(SidenavContent);
