import {
    FETCH_LETTER,
    FETCH_LETTER_SUCCESS,
    FETCH_LETTER_FOLDER,
    FETCH_LETTER_FOLDER_SUCCESS,
    FETCH_LETTER_BY_ID,
    FETCH_LETTER_BY_ID_SUCCESS
} from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import {getResponseForNotificaion} from "../common/functions/EproCommonFunctions";

let token = localStorage.getItem("user_token");
let actionsFor = "letter"

export const fetchLetter = params => {
    return {
        type: FETCH_LETTER,
        payload: params
    };
};

export const fetchLetterSuccess = letters => {
    return {
        type: FETCH_LETTER_SUCCESS,
        payload: letters
    };
};

export const fetchLetterFolder = params => {
    return {
        type: FETCH_LETTER_FOLDER,
        payload: params
    };
};

export const fetchLetterFolderSuccess = folders => {
    return {
        type: FETCH_LETTER_FOLDER_SUCCESS,
        payload: folders
    };
};

export const fetchLetterById = params => {
    return {
        type: FETCH_LETTER_BY_ID,
        payload: params
    };
};

export const fetchLetterByIdSuccess = letterData => {
    return {
        type: FETCH_LETTER_BY_ID_SUCCESS,
        payload: letterData
    };
};
export const actionCreators = {
    //getById
    getLetterById: (id) => async (dispatch, getState) => {
        dispatch({type: "GET_LETTER"});
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/letter/" + id;
        let token = localStorage.getItem("user_token");
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                dispatch({
                    type: "GET_LETTER_SUCCESS",
                    data: res.data.data,
                });
            })
            .catch((e) => {
                let aaaa = e;
            });
    },

    //get all in folder
    //getAllInFolder
    getItemsInFolder:(folderId)=>async (dispatch,getState) =>{
        dispatch({ type: Constants.REQUEST_GET_ALL_LETTER_IN_FOLDER });

        let url = config.api.url + "v1/"+actionsFor+"?folder_id=" + folderId;
        let folderUrl = config.api.url + "v1/folder/"+actionsFor+"/" + folderId;

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                debugger
                // dispatch({ type: "GET_ALL_FORM_SUCCESS", forms: res.data.data.items });
                if(res.data.status === "success"){
                    let items = res.data.data.items

                    axios({
                        method: "get",
                        url: folderUrl,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            debugger
                            if(res.data.status === "success"){
                                dispatch({type:Constants.SUCCESS_GET_ALL_LETTER_IN_FOLDER,items:items,folders:res.data.data.items})
                                //dispatch({type:Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER,items:[],folders:[]})
                            }else {
                                let apiResponse = getResponseForNotificaion(res)
                                dispatch({type:Constants.FAILED_GET_ALL_LETTER_IN_FOLDER,apiResponse:apiResponse})
                            }
                            //dispatch({ type: "GET_ALL_FOLDER_SUCCESS", folders: res.data.data.items });
                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_ALL_LETTER_IN_FOLDER,apiResponse:errorResponse})
                        });
                     }

            })
            .catch((e) => {

            });
    },

    putLetterData: (letter, jsonData, htmlData) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_PUT_LETTER})
        letter.data = jsonData;
        const parseData = JSON.parse(jsonData);
        parseData.page.title = letter.name;
        parseData.page.description = letter.name;
        // const strData = JSON.stringify(jsonData);
        // const strDataFrom = JSON.stringify(parseData);
        // const updatedData = form;
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/letter/" + letter.id;
        let token = localStorage.getItem("user_token");

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {data: parseData, html: htmlData},
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    let letter = res.data.data
                    dispatch({type:Constants.SUCCESS_PUT_LETTER,letter:letter,apiResponse:apiResponse})
                }else {
                    dispatch({type:Constants.FAILED_PUT_LETTER,letter:letter,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_PUT_LETTER,apiResponse:errorResponse });
            });
    },
    putLetterName: (id, name) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_RENAME_LETTER})

        let url = config.api.url+ "v1/letter/" + id;
        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {name: name},
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_RENAME_LETTER,
                        letterObj: res.data.data,
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({type:Constants.FAILED_RENAME_LETTER,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_RENAME_LETTER,apiResponse:errorResponse})
            });
    },
    getCampaignOfThisItem:(type,id)=>async (dispatch,getState) =>{
        let url = config.api.url + "api/campaign/getCampaignOfThisItem"
        dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: { type: type, typeId:id},
        })
            .then((res) => {
                debugger
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    let obj = null
                    if(res.data.isSelected !== 0){
                        obj = {
                            campaignId:res.data.campaignId,
                            campaignName:res.data.campaignName
                        }
                    }
                    dispatch({ type: Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,payload:obj });
                } else {
                    dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
            });
    },
    resetResponse:() => async (dispatch,getState) =>{
        dispatch({type:Constants.RESET_LETTER_API_RESPONSE})
    },
}