// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const SWITCH_LANGUAGE_SUCCESS = "switch-language-success";
export const SWITCH_LANGUAGE_FAILED = "switch-language-failed";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";
export const START_USER_TOUR = "start_user_tour"

//Contact Module const

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESS = "reset_password_success";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// Menu const
export const FETCH_MENU = "fetch_menu";
export const FETCH_MENU_SUCCESS = "fetch_menu_success";

// Home Const
export const FETCH_HOME = "fetch_home";
export const FETCH_HOME_SUCCESS = "fetch_home_success";

//Profile Const
export const FETCH_PROFILE = "fetch_profile";
export const FETCH_PROFILE_SUCCESS = "fetch_profile_success";

//Report Const
export const FETCH_REPORT = "fetch_report";
export const FETCH_REPORT_SUCCESS = "fetch_report_success";

// Table View Const
export const FETCH_TABLE = "fetch_table";
export const FETCH_TABLE_COLUMN_SEARCH = "fetch_table_column_search";
export const FETCH_TABLE_SUCCESS = "fetch_table_success";

// Edit View Const
export const FETCH_EDITVIEW = "fetch_editview";
export const FETCH_EDITVIEW_SUCCESS = "fetch_editview_success";

// Trash View Const
export const FETCH_TRASHVIEW = "fetch_trashview";
export const FETCH_TRASHVIEW_SUCCESS = "fetch_trashview_success";

// Email Const
export const FETCH_EMAIL_FOLDER = "fetch_email_folder";
export const FETCH_EMAIL_FOLDER_SUCCESS = "fetch_email_folder_success";
export const FETCH_EMAIL = "fetch_email";
export const FETCH_EMAIL_SUCCESS = "fetch_email_success";
export const FETCH_EMAIL_BY_ID = "fetch_email_by_id";
export const FETCH_EMAIL_BY_ID_SUCCESS = "fetch_email_by_id_success";

//Sms const
export const FETCH_SMS_FOLDER = "fetch_sms_folder";
export const FETCH_SMS_FOLDER_SUCCESS = "fetch_sms_folder_success";
export const FETCH_SMS = "fetch_sms";
export const FETCH_SMS_SUCCESS = "fetch_sms_success";

// Letter const
export const FETCH_LETTER_FOLDER = "fetch_letter_folder";
export const FETCH_LETTER_FOLDER_SUCCESS = "fetch_letter_folder_success";
export const FETCH_LETTER = "fetch_letter";
export const FETCH_LETTER_SUCCESS = "fetch_letter_success";
export const FETCH_LETTER_BY_ID = "fetch_letter_by_id";
export const FETCH_LETTER_BY_ID_SUCCESS = "fetch_letter_by_id_success";

// Page const
export const FETCH_PAGE_FOLDER = "fetch_page_folder";
export const FETCH_PAGE_FOLDER_SUCCESS = "fetch_page_folder_success";
export const FETCH_PAGE = "fetch_page";
export const FETCH_PAGE_SUCCESS = "fetch_page_success";
export const FETCH_PAGE_BY_ID = "fetch_page_by_id";
export const FETCH_PAGE_BY_ID_SUCCESS = "fetch_page_by_id_success";

// Group const
export const FETCH_GROUP_FOLDER = "fetch_group_folder";
export const FETCH_GROUP_FOLDER_SUCCESS = "fetch_group_folder_success";
export const FETCH_GROUP = "fetch_group";
export const FETCH_GROUP_SUCCESS = "fetch_group_success";
export const FETCH_GROUP_RELATION = "fetch_group_relation";
export const FETCH_GROUP_RELATION_SUCCESS = "fetch_group_relation_success";

//send
export const FETCH_USERGROUPSEND = "fetch_group";
export const FETCH_USERTEXT = "fetch_text";

//filter
export const FETCH_FILTER = "fetch_filter";
export const FETCH_FILTER_SUCCESS = "fetch_filter_success";
export const FETCH_FILTER_BY_ID = "fetch_filter_by_id";
export const FETCH_FILTER_BY_ID_SUCCESS = "fetch_filter_by_id_success";

//relation
export const FETCH_SMS_RELATION = "fetch_sms_relation";
export const FETCH_SMS_RELATION_SUCCESS = "fetch_sms_relation_success";
export const FETCH_EMAIL_RELATION = "fetch_email_relation";
export const FETCH_EMAIL_RELATION_SUCCESS = "fetch_email_relation_success";
export const FETCH_LETTER_RELATION = "fetch_letter_relation";
export const FETCH_LETTER_RELATION_SUCCESS = "fetch_letter_relation_success";

//forms const
export const FETCH_FORM = "fetch_form";
export const FETCH_FORM_SUCCESS = "fetch_form_success";
export const FETCH_FORM_BY_ID = "fetch_form_by_id";
export const FETCH_FORM_BY_ID_SUCCESS = "fetch_form_by_id_success";

// report const
export const FETCH_REPORT_GROUP = "fetch_report_group";
export const FETCH_REPORT_GROUP_SUCCESS = "fetch_report_group_success";
export const FETCH_REPORT_FILTER = "fetch_report_filter";
export const FETCH_REPORT_FILTER_SUCCESS = "fetch_report_filter_success";
export const FETCH_REPORT_RELATION = "fetch_report_relation";
export const FETCH_REPORT_RELATION_SUCCESS = "fetch_report_relation_success";
export const FETCH_REPORT_API = "fetch_report_api";
export const FETCH_REPORT_API_SUCCESS = "fetch_report_api_success";
export const FETCH_REPORT_LETTER = "fetch_report_letter";
export const FETCH_REPORT_LETTER_SUCCESS = "fetch_report_letter_success";

// categories const
export const FETCH_CATEGORIES_ROOT = "fetch_categories_root";
export const FETCH_CATEGORIES_ROOT_SUCCESS = "fetch_categories_root_success";
export const FETCH_CATEGORIES = "fetch_categories";
export const FETCH_CATEGORIES_SUCCESS = "fetch_categories_success";
export const FETCH_CATEGORIES_CHILD = "fetch_categories_child";
export const FETCH_CATEGORIES_CHILD_SUCCESS = "fetch_categories_child_success";

// schedule const
export const FETCH_SCHEDULE_HISTORY = "fetch_schedule_history";
export const FETCH_SCHEDULE_HISTORY_SUCCESS = "fetch_schedule_history_success";
export const FETCH_SCHEDULE_SEND_QUEUE = "fetch_schedule_send_queue";
export const FETCH_SCHEDULE_SEND_QUEUE_SUCCESS =
  "fetch_schedule_send_queue_success";

// Relation const
export const FETCH_RELATION_GROUP_FOLDER = "fetch_relation_group_folder";
export const FETCH_RELATION_GROUP_FOLDER_SUCCESS =
  "fetch_relation_group_folder_success";
export const FETCH_RELATION_GROUP = "fetch_relation_group";
export const FETCH_RELATION_GROUP_SUCCESS = "fetch_relation_group_success";
