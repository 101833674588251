import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_FILTER, FETCH_FILTER_BY_ID } from "constants/ActionTypes";
import { fetchFilterSuccess, fetchFilterByIdSuccess } from "actions/Filter";

import config from "../config";

// fetch filter
const fetchFilterData = async (params) => {
  const { token } = params;

  const response = await fetch(`${config.api.url}v1/filter`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((json) => json);
  return response;
};

function* getFetchFilterData({ payload }) {
  try {
    const getFilterData = yield call(fetchFilterData, payload);
    yield put(fetchFilterSuccess(getFilterData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFilterData() {
  yield takeEvery(FETCH_FILTER, getFetchFilterData);
}

// fetch filter by id
const fetchFilterByIdData = async ({ authUser, id }) => {
  const response = await fetch(`${config.api.url}api/filter/get/${id}`, {
    method: "GET",
    headers: {
      Authorization: authUser,
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
  return response;
};

function* getFetchFilterByIdData({ payload }) {
  try {
    const getFilterByIdData = yield call(fetchFilterByIdData, payload);
    yield put(fetchFilterByIdSuccess(getFilterByIdData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFilterByIdData() {
  yield takeEvery(FETCH_FILTER_BY_ID, getFetchFilterByIdData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getFilterData), fork(getFilterByIdData)]);
}
