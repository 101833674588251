import React from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceFooterWrapper:{
        // flexDirection:"col",
        alignItems:"flex-end",
        height:"100px"
    },
    invoiceFooter:{
        marginTop:"20px",
        width:"100%",
        borderTop:true,
        borderTopColor:"#A3A3A3",
        padding:"10px 0px"
    },
    text:{
        textAlign:"center",
        fontSize:"10",
        color:"#A3A3A3"

    }
});

function InvoiceFooter(props) {
    const {data} = props
    return(
        <View style={styles.invoiceFooterWrapper}>
            <View style={styles.invoiceFooter}>
                <Text style={styles.text}>Address Two | Telefoon 030 6090111 | support@e-clicks.pro | mybizzmail.com</Text>
                <Text style={styles.text}>Mybizzmail en Bizzmark zijn handelsnamen van B-first consultancy kvk nr. 30175049. BTW nr. 1764.56.752.B.01</Text>
            </View>
        </View>
    )
}
export default InvoiceFooter