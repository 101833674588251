import {
  FETCH_SMS,
  FETCH_SMS_SUCCESS,
  FETCH_SMS_FOLDER,
  FETCH_SMS_FOLDER_SUCCESS
} from "constants/ActionTypes";

export const fetchSms = params => {
  return {
    type: FETCH_SMS,
    payload: params
  };
};

export const fetchSmsSuccess = smss => {
  return {
    type: FETCH_SMS_SUCCESS,
    payload: smss
  };
};

export const fetchSmsFolder = params => {
  return {
    type: FETCH_SMS_FOLDER,
    payload: params
  };
};

export const fetchSmsFolderSuccess = folders => {
  return {
    type: FETCH_SMS_FOLDER_SUCCESS,
    payload: folders
  };
};
