import React from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

const styles = StyleSheet.create({
    row:{
        width:"100%",
        padding:"10px 5px",
        flexDirection:"row"
    },
    column:{
        width:"20%"
    },
    columnWithBorder:{
        width:"20%",
        paddingTop:"10px",
        borderTop:true,
        borderTopColor:eclicksProColors.textGray
    },
    text:{
        fontSize:"12",
        color:eclicksProColors.textGray
    }
});

function InvoiceTableFooter(props) {
    const {data} = props
    return(
        <View style={styles.row}>
            <View style={styles.column}>
                <Text style={styles.text}>{}</Text>
            </View>

            <View style={styles.column}>
                <Text style={styles.text}>{}</Text>
            </View>

            <View style={styles.column}>
                <Text style={styles.text}>{}</Text>
            </View>

            <View style={styles.columnWithBorder}>
                <Text style={styles.text}>{`Total:`}</Text>
            </View>

            <View style={styles.columnWithBorder}>
                <Text style={styles.text}>{`€ ${data.price}`}</Text>
            </View>
        </View>
    )
}

export default InvoiceTableFooter