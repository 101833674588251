import {
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FOLDER,
  FETCH_GROUP_FOLDER_SUCCESS,
  FETCH_GROUP_RELATION,
  FETCH_GROUP_RELATION_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: true,
  openFullScreenLoader:true,
  group:null,
  groups: [],
  folder: [],
  relations: null,
  groupParams: null,
  folderParams: null,
  relationParams: null,
  newItemCreated:false,
  newItem:null,
  apiResponse:null,
  campaignIdOfThisItem:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GROUP: {
      return {
        ...state,
        loader: true,
        groupParams: action.payload
      };
    }
    case FETCH_GROUP_SUCCESS: {
      return {
        ...state,
        loader: false,
        groups: action.payload
      };
    }
    case FETCH_GROUP_FOLDER: {
      return {
        ...state,
        loader: true,
        folderParams: action.payload
      };
    }
    case FETCH_GROUP_FOLDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        folder: action.payload
      };
    }
    case FETCH_GROUP_RELATION: {
      return {
        ...state,
        loader: true,
        relationParams: action.payload
      };
    }
    case FETCH_GROUP_RELATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        relations: action.payload
      };
    }
    case Constants.REQUEST_GET_ALL_GROUP_IN_FOLDER:{
      return {
        ...state,
        loader: true,
        groups:[],
        folder: []
      }
    }
    case Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER:{
      debugger
      return {
        ...state,
        loader: false,
        groups: action.items,
        folder: action.folders
      }
    }
    case Constants.REQUEST_ADD_FOLDER_IN_GROUP:{
      return {
        ...state,
        openFullScreenLoader: true
      }
    }
    case Constants.SUCCESS_ADD_FOLDER_IN_GROUP:{
      return {
        ...state,
        folder: [...action.folders, ...state.folder],
        openFullScreenLoader: false
      }
    }
    case Constants.FAILED_ADD_FOLDER_IN_GROUP:{
      return {
        ...state,
        openFullScreenLoader: false
      }
    }
    case Constants.REQUEST_ADD_GROUP:{
      return {
        ...state,
        openFullScreenLoader: true,
      }
    }
    case Constants.SUCCESS_ADD_GROUP:{
      return {
        ...state,
        groups: [...action.item, ...state.groups],
        newItemCreated: true,
        newItem: action.item[0],
        openFullScreenLoader: false,
        apiResponse: action.apiResponse
      }
    }
    case Constants.SUCCESS_RENAME_GROUP:{
      let itemId = action.itemId;
      let item = state.groups.filter((i) => i.id === itemId);
      let index = state.groups.indexOf(item[0]);
      state.groups.splice(index, 1, action.item[0]);
      return {
        ...state,
        apiResponse: action.apiResponse

      }
    }
    case Constants.REQUEST_RENAME_GROUP:{
      return {
        ...state,
        apiResponse: null,
      }
    }
    case Constants.FAILED_RENAME_GROUP:{
      return {
        ...state,
        apiResponse: action.apiResponse
      }
    }
    case Constants.REQUEST_RENAME_GROUP_FOLDER:{
      return {
        ...state,
        apiResponse: null,
      }
    }
    case Constants.SUCCESS_RENAME_GROUP_FOLDER:{
      let folderId = action.folderId;
      let item = state.folder.filter((i) => i.id === folderId);
      let index = state.folder.indexOf(item[0]);
      state.folder.splice(index, 1, action.folder[0]);

      return {
        ...state,
      };
    }
    case Constants.FAILED_RENAME_GROUP_FOLDER:{
      return {
        ...state,
        apiResponse: action.apiResponse
      }
    }
    case Constants.SUCCESS_DELETE_GROUP:{
      let itemId = action.itemId;
      let items = state.groups.filter((i) => i.id !== itemId);

      return {
        ...state,
        loader: false,
        groups: items,
        apiResponse: action.apiResponse
      };
    }
    case Constants.FAILED_DELETE_GROUP:{
      return {
        ...state,
        apiResponse: action.apiResponse
      }
    }
    case Constants.SUCCESS_DELETE_GROUP_FOLDER: {
      let folderId = action.folderId;
      let items = state.folder.filter((i) => i.id !== folderId);

      return {
        ...state,
        loader: false,
        folder: items,
        apiResponse: action.apiResponse
      };
    }
    case Constants.SUCCESS_UPDATE_GROUP_PARENT:{
      let items = state.groups.filter((i) => i.id !== action.fromId);
      return {
        ...state,
        groups: items,
      };
    }
    case Constants.SUCCESS_UPDATE_GROUP_FOLDER_PARENT:{
      let folders = state.folder.filter((i) => i.id !== action.fldrId);
      return {
        ...state,
        folder: folders,
      };
    }
    case Constants.RESET_GROUP_RESPONSE:{
      return {
        ...state,
        apiResponse: null
      }
    }
    case Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM:{
      return {
        ...state,
        campaignIdOfThisItem: null
      }
    }
    case Constants.SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM:{
      return {
        ...state,
        campaignIdOfThisItem: action.payload
      }
    }
    case Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM:{
      return {
        ...state,
        campaignIdOfThisItem: null
      }
    }

    case Constants.RESET_GROUP_STATE:{
      return {
        ...state,
        loader: true,
        openFullScreenLoader:true,
        group:null,
        groups: [],
        folder: [],
        relations: null,
        groupParams: null,
        folderParams: null,
        relationParams: null,
        newItemCreated:false,
        newItem:null,
        apiResponse: null,
      }
    }
    default:
      return state;
  }
};
