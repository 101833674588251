import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_GROUP,
  FETCH_GROUP_FOLDER,
  FETCH_GROUP_RELATION
} from "constants/ActionTypes";
import {
  fetchGroupSuccess,
  fetchGroupFolderSuccess,
  fetchGroupRelationSuccess
} from "actions/Group";

import config from "../config";

// group
const fetchGroupData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/group?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchGroupData({ payload }) {
  try {
    const getGroupData = yield call(fetchGroupData, payload);
    yield put(fetchGroupSuccess(getGroupData));
  } catch (e) {
    console.error(e);
  }
}

export function* getGroupData() {
  yield takeEvery(FETCH_GROUP, getFetchGroupData);
}

// Folder
const fetchFolderData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/group/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFolderData({ payload }) {
  try {
    const getFolderData = yield call(fetchFolderData, payload);
    yield put(fetchGroupFolderSuccess(getFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFolderData() {
  yield takeEvery(FETCH_GROUP_FOLDER, getFetchFolderData);
}

// relation
const fetchGroupRelationData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}api/relation/listview`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: 10,
      page_no: 0,
      search: "",
      order_by_field: "",
      order_direction: "",
      table_sort_field: "",
      table_sort_direction: "",
      multi_search_fields: "",
      group_id: id
    })
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response;
  }else {
      return response
  }
};

function* getFetchGroupRelationData({ payload }) {
  try {
    const getGroupRelationData = yield call(fetchGroupRelationData, payload);
    yield put(fetchGroupRelationSuccess(getGroupRelationData));
  } catch (e) {
    console.error(e);
  }
}

export function* getGroupRelationData() {
  yield takeEvery(FETCH_GROUP_RELATION, getFetchGroupRelationData);
}

//   export saga
export default function* rootSaga() {
  yield all([
    fork(getGroupData),
    fork(getFolderData),
    fork(getGroupRelationData)
  ]);
}
