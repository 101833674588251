import { FETCH_PROFILE, FETCH_PROFILE_SUCCESS } from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: false,
  token: localStorage.getItem("user_token"),
  info: null,
  profileApiResponse:null,
  openFullScreenLoader:false,
  personalDomain:null,
  isExistDomain:false,
  personalDomainItems:[], // list of form or page,
  publicKey: "",
  secretKey: "",
  subscriptionFees:[],
  companyInfo:null,
  subscriptionVat:"0",
  credits:[],
  creditVat:"0",
  companyInfoLoader:false,
  paymentHistory:[],
  paymentInvoice:null,
  publicKey: "",
  secretKey: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PROFILE: {
      return {
        ...state,
        loader: true,
        token: action.payload
      };
    }
    case FETCH_PROFILE_SUCCESS: {
      return {
        ...state,
        loader: false,
        info: action.payload
      };
    }
    case Constants.REQUEST_GET_USER_INFO:{
      return {
        ...state,
        token: action.token,
        loader: true,
        info: null
      }
    }
    case Constants.SUCCESS_GET_USER_INFO:{
      return {
        ...state,
        info: action.userInfo,
        loader: false
      }
    }
    case Constants.FAILED_GET_USER_INFO:{
      return {
        ...state,
        info: null
      }
    }
    case Constants.REQUEST_POST_USER_MOLLIE_INFO:{
      return {
        ...state,
        profileApiResponse: null,
        openFullScreenLoader: true
      }
    }
    case Constants.SUCCESS_POST_USER_MOLLIE_INFO:{
      return {
        ...state,
        profileApiResponse: action.apiResponse,
        openFullScreenLoader: false
      }
    }
    case Constants.FAILED_POST_USER_MOLLIE_INFO:{
      return {
        ...state,
        profileApiResponse: action.apiResponse,
        openFullScreenLoader: false
      }
    }
    case Constants.REQUEST_GET_PERSONAL_DOMAIN:{
      return {
        ...state,
        loader: true,
        personalDomain: null,
      }
    }
    case Constants.SUCCESS_GET_PERSONAL_DOMAIN:{
      return {
        ...state,
        loader: false,
        personalDomain: action.domain
      }
    }
    case Constants.FAILED_GET_PERSONAL_DOMAIN:{
      return {
        ...state,
        loader: false,
        personalDomain: null
      }
    }
    case Constants.REQUEST_GET_PERSONAL_DOMAIN_ITEMS:{
      return {
        ...state,
        personalDomainItems:[]
      }
    }
    case Constants.SUCCESS_GET_PERSONAL_DOMAIN_ITEMS:{
      return {
        ...state,
        personalDomainItems: action.items
      }
    }
    case Constants.FAILED_GET_PERSONAL_DOMAIN_ITEMS:{
      return {
        ...state,
        personalDomainItems: []
      }
    }
    case Constants.REQUEST_SAVE_PERSONAL_DOMAIN:{
      return {
        ...state,
        profileApiResponse:null,
        //personalDomain:null
      }
    }
    case Constants.SUCCESS_SAVE_PERSONAL_DOMAIN:{
      return {
        ...state,
        profileApiResponse:action.apiResponse,
       // personalDomain: action.domain
      }
    }
    case Constants.FAILED_SAVE_PERSONAL_DOMAIN:{
      return {
        ...state,
        profileApiResponse:action.apiResponse
      }
    }
    case Constants.REQUEST_GENERATE_API_KEY:{
      return {
        ...state,
        publicKey: null,
        secretKey: null
      }
    }
    case Constants.SUCCESS_GENERATE_API_KEY:{
      return {
        ...state,
        publicKey: action.pKey,
        secretKey: action.sKey
      }
    }
    case Constants.FAILED_GENERATE_API_KEY:{
      return {
        ...state,
        publicKey: null,
        secretKey: null
      }
    }
    case Constants.CHECK_PERSONAL_DOMAIN:{
      return {
        ...state,
        isExistDomain: action.isExist
      }
    }
    case Constants.REQUEST_UPDATE_COMPANY_INFO:{
      return {
        ...state,
        profileApiResponse:null
      }
    }
    case Constants.SUCCESS_UPDATE_COMPANY_INFO:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_UPDATE_COMPANY_INFO:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.RESET_PROFILE_RESPONSE:{
      return {
        ...state,
        profileApiResponse: null
      }
    }
    case Constants.REQUEST_GET_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: true,
        subscriptionFees: [],
        subscriptionVat: "0"
      }
    }
    case Constants.SUCCESS_GET_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: false,
        subscriptionFees: action.packages,
        subscriptionVat: action.svat
      }
    }
    case Constants.FAILED_GET_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: false,
        subscriptionFees: [],
        subscriptionVat: "0"
      }
    }
    case Constants.REQUEST_SAVE_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: true,
        profileApiResponse: null
      }
    }
    case Constants.SUCCESS_SAVE_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: false,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_SAVE_SUBSCRIPTION_FEES:{
      return {
        ...state,
        loader: false,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.REQUEST_GET_COMPANY_INFO:{
      return {
        ...state,
        companyInfo: null,
        companyInfoLoader:true
      }
    }
    case Constants.SUCCESS_GET_COMPANY_INFO:{
      return {
        ...state,
        companyInfo: action.companyInfo,
        companyInfoLoader:false
      }
    }
    case Constants.FAILED_GET_COMPANY_INFO:{
      return {
        ...state,
        companyInfo: null,
        companyInfoLoader:false
      }
    }
    case Constants.REQUEST_DELETE_SUBSCRIPTION_FEE:{
      return {
        ...state,
        profileApiResponse: null
      }
    }
    case Constants.SUCCESS_DELETE_SUBSCRIPTION_FEE:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_DELETE_SUBSCRIPTION_FEE:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.REQUEST_CANCEL_CURRENT_SUBSCRIPTION:{
      return {
        ...state,
        profileApiResponse: null
      }
    }
    case Constants.SUCCESS_CANCEL_CURRENT_SUBSCRIPTION:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_CANCEL_CURRENT_SUBSCRIPTION:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.REQUEST_ADD_CREDIT:{
      return {
        ...state,
        profileApiResponse: null
      }
    }
    case Constants.SUCCESS_ADD_CREDIT:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_ADD_CREDIT:{
      return {
        ...state,
        profileApiResponse: action.apiResponse
      }
    }
    case Constants.REQUEST_GET_CREDIT:{
      return {
        ...state,
        loader: true,
        credits: [],
        creditVat:"0"
        //subscriptionVat: "0"
      }
    }
    case Constants.SUCCESS_GET_CREDIT:{
      return {
        ...state,
        loader: false,
        credits: action.packages,
        creditVat:action.cvat
       // subscriptionVat: action.svat
      }
    }
    case Constants.FAILED_GET_CREDIT:{
      return {
        ...state,
        loader: false,
        credits: [],
        creditVat:"0"
        //subscriptionVat: "0"
      }
    }
    case Constants.REQUEST_GET_PAYMENT_HISTORY:{
      return {
        ...state,
        loader: true,
        paymentHistory: [],
        profileApiResponse:null
      }
    }
    case Constants.SUCCESS_GET_PAYMENT_HISTORY:{
      return {
        ...state,
        loader: false,
        paymentHistory: action.payload,
       // subscriptionVat: action.svat
      }
    }
    case Constants.FAILED_GET_PAYMENT_HISTORY:{
      return {
        ...state,
        loader: false,
        paymentHistory: [],
        profileApiResponse:action.res
      }
    }
    case Constants.REQUEST_GET_PAYMENT_INVOICE_DATA:{
      return {
        ...state,
        loader: true,
        paymentInvoice: null,
        profileApiResponse:null
      }
    }
    case Constants.SUCCESS_GET_PAYMENT_INVOICE_DATA:{
      return {
        ...state,
        loader: false,
        paymentInvoice: action.payload,
       // subscriptionVat: action.svat
      }
    }
    case Constants.FAILED_GET_PAYMENT_INVOICE_DATA:{
      return {
        ...state,
        loader: false,
        paymentInvoice: null,
        profileApiResponse:action.res
      }
    }
    default:
      return state;
  }
};


