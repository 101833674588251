import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import CustomButton from "../button/CustomButton";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
    dialogContentText: {
        //backgroundColor: 'red',
        //color: props => props.color,
        marginTop:"23px"
    },
    dialogAction:{
        //padding:"6px 22px 8px 24px"
        padding:"8px 22px 22px 24px !important"
    }
});

const CustomDeleteDialog = (props) => {
    const classes = useStyles(props);
    //let titleKey = props.titleKey === undefined ? "_CLOSE":props.titleKey;
    let contentTextKey = props.contentTextKey === undefined ? "_CLOSE":props.contentTextKey;
    let fullWidth = props.fullWidth === undefined ? true:props.fullWidth
    let maxWidth = props.maxWidth === undefined ? "xs":props.maxWidth
    return(
        <Dialog
            open={props.open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={props.handleClose}
            aria-labelledby="custom-dailog-title"
        >
            <DialogTitle id="custom-dailog-title">
                <Grid container>
                    <Grid container item md={11} justify="flex-start">
                        <IntlMessages id="_DELETE"/>
                    </Grid>
                    <Grid container item md={1} justify="flex-end">
                        <div style={{marginRight:"-11px"}}>
                            <IconButton
                                size="small"
                                className="actionDialogTitleButton"
                                onClick={props.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Grid container>
                    <Grid container item md={12} justify="flex-end">
                        <CustomButton color="red" onClick={props.handleDelete}>
                            <IntlMessages id="_DELETE"></IntlMessages>
                        </CustomButton>
                        <CustomButton color="grey" onClick={props.handleClose}>
                            <IntlMessages id="_CLOSE"></IntlMessages>
                        </CustomButton>
                    </Grid>
                </Grid>
            </DialogActions>



        </Dialog>
    )

}
export default CustomDeleteDialog



