
import * as Constants from "../common/Constants"

const INIT_STATE = {
    importedItem: null,
    status: "",
    statusShow: false,
    loading: false,
    previewData: [],
    headers: [],
    customHeader:[],
    steperStatus: 0,
    mappedGender:[],
    importSettings:false,
    showGenderDialog:false,
    finalImport:false,
    finalImportSuccess:false,
    groups:[],
    fields:[],
    relImportApiResponse: null,
    importStepStatus:{
        "upload": false,
        "import": false,
        "preview": false,
        "importSettings": false,
        "finalImport": false
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case Constants.REQUEST_RELATION_IMPORT: {
            return {
                ...state,
                loading: true,
                importedItem: null,
                statusShow: false,
                status: "",
                finalImport: false

            }
        }
        case Constants.SUCCESS_RELATION_IMPORT: {
            return {
                ...state,
                loading: false,
                importedItem: action.item,
                statusShow: true,
                status: action.status,
                importStepStatus: action.impSts
            };
        }
        case Constants.FAILED_RELATION_IMPORT:{
            return {
                ...state,
                loading: false,
                statusShow: true,
                status: action.status,
            }
        }
        case Constants.SUCCESS_RELATION_IMPORT_PREVIEW: {
            return {
                ...state,
                statusShow: false,
                previewData: action.data,
                steperStatus: action.steper,
                headers: action.header,
                customHeader: action.customHeader
            };
        }
        case "GET_GROUP_SUCCESS": {
            return {
                ...state,
                groups:action.allGroups,
            };
        }
        case "GET_ALL_FIELDS_SUCCESS": {          
            let cusH = state.customHeader
            if(cusH.length !== 0){
                state.headers = state.customHeader
            }
            return {
                ...state,
                fields:action.allFields,
                loading: false,
                steperStatus: 2
            };
        }
        case "MAP_GENDER_SUCCESS": {
            return {
                ...state,
                mappedGender: action.genderMap,
                showGenderDialog: true
            };
        }
        case Constants.SUCCESS_IMPORT_SETTINGS: {
            return {
                ...state,
                importSettings: true

            };
        }
        case Constants.REQUEST_FINAL_RELATION_IMPORT:{
            return {
                ...state,
                relImportApiResponse: null,
                finalImport: false,
                finalImportSuccess:false
            }
        }
        case Constants.SUCCESS_FINAL_RELATION_IMPORT: {
            return {
                ...state,
                importSettings: false,
                finalImport: true,
                finalImportSuccess:true,
                importedItem: null,
                status: "",
                statusShow: false,
                loading: false,
                previewData: [],
                headers: [],
                customHeader:[],
                steperStatus: 0,
                mappedGender:[],
                showGenderDialog:false,
                groups:[],
                fields:[],
                relImportApiResponse: null,
                importStepStatus:{
                    "upload": false,
                    "import": false,
                    "preview": false,
                    "importSettings": false,
                    "finalImport": false
                }
                //steperStatus: 0
            };
        }
        case Constants.FAILED_FINAL_RELATION_IMPORT:{
            return {
                ...state,
                relImportApiResponse: action.apiResponse,
                finalImportSuccess:false
            }
        }
        case "STEPPER_CHANGE_SUCCESS": {
            return {
                ...state,
                steperStatus: action.key

            };
        }
        case "CLOSE_GEN_DIALOG": {
            return {
                ...state,
                showGenderDialog: false,
                importSettings: false
            };
        }

        case "UPDATE_HEADER": {
            return {
                ...state,
                customHeader: action.header,
                loading: true
            };
        }
        case Constants.ClOSE_FINAL_IMPORT_SUCCESS:{
            return {
                ...state,
                finalImportSuccess: false,
                steperStatus:0
            }
        }
        case Constants.CHANGE_IMPORT_STEP_STATUS:{
            return {
                ...state,
                importStepStatus: action.impSts
            }
        }
        case Constants.RESET_RELATION_IMPORT_API_RESPONSE:{
            return {
                ...state,
                relImportApiResponse: null
            }
        }
        default:
            return state;
    }

}