import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_PAGE,
  FETCH_PAGE_FOLDER,
  FETCH_PAGE_BY_ID
} from "constants/ActionTypes";
import {
  fetchPageSuccess,
  fetchPageFolderSuccess,
  fetchPageByIdSuccess
} from "actions/Page";

import config from "../config";

// page
const fetchPageData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/page?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchPageData({ payload }) {
  try {
    const getPageData = yield call(fetchPageData, payload);
    yield put(fetchPageSuccess(getPageData));
  } catch (e) {
    console.error(e);
  }
}

export function* getPageData() {
  yield takeEvery(FETCH_PAGE, getFetchPageData);
}

// Folder
const fetchFolderData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/page/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFolderData({ payload }) {
  try {
    const getFolderData = yield call(fetchFolderData, payload);
    yield put(fetchPageFolderSuccess(getFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFolderData() {
  yield takeEvery(FETCH_PAGE_FOLDER, getFetchFolderData);
}

//Page Data
const fetchPageDataById = async params => {
  const { token, id } = params;
  const response = await fetch(`${config.api.url}v1/page/${id}`, {
    method: "GET",
    headers: {
      Authorization: token
    }
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

function* getFetchPageDataById({ payload }) {
  try {
    const getPageDataById = yield call(fetchPageDataById, payload);
    yield put(fetchPageByIdSuccess(getPageDataById));
  } catch (e) {
    console.error(e);
  }
}

export function* getPageDataById() {
  yield takeEvery(FETCH_PAGE_BY_ID, getFetchPageDataById);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getPageData), fork(getFolderData), fork(getPageDataById)]);
}
