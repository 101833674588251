import React from "react";
import Avatar from "@material-ui/core/Avatar";
import ImageUploader from "react-images-upload";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import config from "config";

class ImageAvatar extends React.Component {
  state = {
    pictures: [],
    pictureUrl: null,
    newImage: this.props.image
  };
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles),
      pictureUrl: pictureDataURLs
    });
  }
  render() {
    const { token } = this.props;
    const { newImage } = this.state;
    return (
      <div
        className="manage-margin d-flex align-items-center flex-wrap"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Avatar className="size-150" alt="Profile Picture" src={newImage} />
        <ImageUploader
          buttonStyles={{ color: "white", backgroundColor: "#4caf50" }}
          withIcon={true}
          withPreview={true}
          buttonText="Choose images"
          onChange={this.onDrop}
          imgExtension={[".jpg", ".png", ".gif"]}
          maxFileSize={5242880}
        />
        <Button
          onClick={async () => {
            const response = await fetch(
              `${config.api.url}api/profile_pic_upload`,
              {
                method: "POST",
                headers: {
                  Authorization: token
                },
                body: JSON.stringify({
                  image: this.state.pictureUrl[0]
                })
              }
            )
              .then(res => res.json())
              .then(json => json);

            if (response.status === "success") {
              NotificationManager.info(response.message);
              this.setState({ newImage: response.imageUrl });
            }
            if (response.status === "error")
              NotificationManager.error(response.message);
          }}
        >
          Upload Image
        </Button>
      </div>
    );
  }
}

export default ImageAvatar;
