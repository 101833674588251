import {
  FETCH_SCHEDULE_HISTORY,
  FETCH_SCHEDULE_SEND_QUEUE,
  FETCH_SCHEDULE_HISTORY_SUCCESS,
  FETCH_SCHEDULE_SEND_QUEUE_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants";
import config from "../config";
import axios from "axios";
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
//variables
// const token = localStorage.getItem("user_token");

export const fetchScheduleHistory = params => {
  return {
    type: FETCH_SCHEDULE_HISTORY,
    payload: params
  };
};

export const fetchScheduleHistorySuccess = history => {
  return {
    type: FETCH_SCHEDULE_HISTORY_SUCCESS,
    payload: history
  };
};

export const fetchScheduleSendQueue = params => {
  return {
    type: FETCH_SCHEDULE_SEND_QUEUE,
    payload: params
  };
};

export const fetchScheduleSendQueueSuccess = queue => {
  return {
    type: FETCH_SCHEDULE_SEND_QUEUE_SUCCESS,
    payload: queue
  };
};

export const actionCreators={
  getSendQueue:()=>async (dispatch,getState)=>{
    dispatch({type:Constants.REQUEST_GET_SEND_QUEUE})

    let url = config.api.url + "v1/get/send_queue?limit=&offset=&type=&campaign_id=";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: localStorage.getItem("user_token"),
      },
    })
        .then((res) => {
          if (res.data.status === "success") {
            dispatch({type:Constants.SUCCESS_GET_SEND_QUEUE,queue:res.data})
          } else {
            dispatch({type:Constants.FAILED_GET_SEND_QUEUE})
          }
        })
        .catch((e) => {
          dispatch({type:Constants.FAILED_GET_SEND_QUEUE})
        });
  },
  getScheduleHistory:()=>async (dispatch,getState)=>{
    dispatch({type:Constants.REQUEST_GET_SCHEDULE_HISTORY})

    let url = config.api.url + "v1/get/mailings?limit=&offset=&type=";
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: localStorage.getItem("user_token"),
      },
    })
        .then((res) => {
          if (res.data.status === "success") {
            dispatch({type:Constants.SUCCESS_GET_SCHEDULE_HISTORY,history:res.data})
          } else {
            dispatch({type:Constants.FAILED_GET_SEND_QUEUE})
          }
        })
        .catch((e) => {
          dispatch({type:Constants.FAILED_GET_SCHEDULE_HISTORY})
        });
  },
  deleteSendQueueItems:(ids) =>async (dispatch,getState) =>{
      dispatch({type:Constants.REQUEST_DELETE_SEND_QUEUE_ITEMS})
      let url = config.api.url + "api/send_queue/itemDelete";
      axios({
          method: "POST",
          url: url,
          headers: {
              Authorization: localStorage.getItem("user_token"),
          },
          data: {
              hardDelete:"0",
              ids:ids
          },
      })
          .then((res) => {
              let apiResponse = getResponseForNotificaion(res)
              if (res.data.status === "success") {
                  dispatch({
                      type: Constants.SUCCESS_DELETE_SEND_QUEUE_ITEMS,
                      apiResponse:apiResponse
                  });

                  dispatch({type:Constants.REQUEST_GET_SEND_QUEUE})

                  let url = config.api.url + "v1/get/send_queue?limit=&offset=&type=&campaign_id=";
                  axios({
                      method: "get",
                      url: url,
                      headers: {
                          Authorization: localStorage.getItem("user_token"),
                      },
                  })
                      .then((res) => {
                          if (res.data.status === "success") {
                              dispatch({type:Constants.SUCCESS_GET_SEND_QUEUE,queue:res.data})
                          } else {
                              dispatch({type:Constants.FAILED_GET_SEND_QUEUE})
                          }
                      })
                      .catch((e) => {
                          dispatch({type:Constants.FAILED_GET_SEND_QUEUE})
                      });
              }else {
                  dispatch({type:Constants.FAILED_DELETE_SEND_QUEUE_ITEMS,apiResponse:apiResponse})
              }
          })
          .catch((e) => {
              let errorResponse = getResponseForNotificaion(null,{})
              dispatch({type:Constants.FAILED_DELETE_SEND_QUEUE_ITEMS,apiResponse:errorResponse})
          });
  },
    resetResponse:() => async (dispatch,getState) =>{
        dispatch({type:Constants.RESET_SCHEDULE_API_RESPONSE})
    }
}
