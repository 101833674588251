import React, { Component } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import darkTheme from "./themes/darkTheme";
import AppLocale from "../lngProvider";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";

import MainApp from "app/index";
import SignIn from "./SignIn";
// import SignUp from "./SignUp";
import ResestPassword from "./ResetPassword";
import RetypePassword from "./RetypePassword";
import Preview from "./Preview";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import RegSuccessPage from "./RegSuccessPage";
import CreatePassword from "./CreatePassword";
import { getAuthData } from "../common/helpers/AuthHelper";

//settings
import {developerSettings} from "../common/settings/developerSettings"

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  state = {
    appLocale: null
  };
  async componentWillMount() {
    const EN = await AppLocale.en();
    const NL = await AppLocale.nl();
    this.setState({ appLocale: { en: EN, nl: NL } });
    getAuthData()
    // console.log('value ->', value)

    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme-name")) {
      this.props.setThemeColor(params.get("theme-name"));
    }
    if (params.has("dark-theme")) {
      this.props.setDarkTheme();
    }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  render() {
    const { match, location, isDarkTheme, locale,initURL, isDirectionRTL} = this.props;
    let { themeColor } = this.props;
    let authUser = getAuthData().token
    let applyTheme = createMuiTheme(greenTheme)
    // if (isDarkTheme) {
    //   document.body.classList.add("dark-theme");
    //   applyTheme = createMuiTheme(darkTheme);
    // } else {
    //   // applyTheme = this.getColorTheme(themeColor, applyTheme);
    //   applyTheme = createMuiTheme(greenTheme)
    // }

    //this code block is for redirect if url is "/"
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/home"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    //this code block is for redirect if url is "/"

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }
    const currentAppLocale = this.state.appLocale
      ? this.state.appLocale[locale.locale]
      : null;
    // if(currentAppLocale){
    //   console.log(currentAppLocale)
    // }


    //for development loggin
    if(developerSettings.logMuThemeInConsole){
      console.log(applyTheme)
    }

    return (
      <ThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {currentAppLocale && (
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <RTL>
                <div className="app-main">
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      authUser={authUser}
                      component={MainApp}
                    />
                    <Route path="/signin" component={SignIn} />
                    {/* <Route path="/signup" component={SignUp} /> */}
                    <Route path="/regsuccess" component={RegSuccessPage} />
                    <Route path="/resetPassword" component={ResestPassword} />
                    {/*<Route*/}
                    {/*  path="/retypePassword/:token"*/}
                    {/*  component={RetypePassword}*/}
                    {/*/>*/}
                    <Route
                        path="/retypePassword/:token"
                        component={CreatePassword}
                    />
                    {/*<Route*/}
                    {/*    path="/test"*/}
                    {/*    component={CreatePassword}*/}
                    {/*/>*/}
                    {/* <Route path="/test/:validator" component={Preview} /> */}
                    <Route
                      component={asyncComponent(() =>
                        import("app/routes/extraPages/routes/404")
                      )}
                    />
                  </Switch>
                </div>
              </RTL>
            </IntlProvider>
          )}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL
  } = settings;
  const { authUser, initURL } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    authUser,
    initURL
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  setThemeColor,
  setDarkTheme
})(App);





// import React,{useState} from 'react'
//
//
//
// import URLSearchParams from "url-search-params";
// import MomentUtils from "@date-io/moment";
// import { MuiPickersUtilsProvider } from "material-ui-pickers";
// import { Redirect, Route, Switch } from "react-router-dom";
//
// //material ui
// import { createMuiTheme } from "@material-ui/core/styles";
// import { ThemeProvider } from "@material-ui/styles";
//
// //translation
// import { IntlProvider } from "react-intl";
//
// //theme
// import greenTheme from "./themes/greenTheme";
//
//
// import MainApp from "app/index";
// import SignIn from "./SignIn";
// import SignUp from "./SignUp";
// import ResestPassword from "./ResetPassword";
// import RetypePassword from "./RetypePassword";
// import Preview from "./Preview";
// import { setInitUrl } from "../actions/Auth";
// import RTL from "util/RTL";
// import asyncComponent from "util/asyncComponent";
// import { setDarkTheme, setThemeColor } from "../actions/Setting";
// import RegSuccessPage from "./RegSuccessPage";
// import CreatePassword from "./CreatePassword";
// import { getAuthData } from "../common/helpers/AuthHelper";
//
// import "assets/vendors/style";
//
//
// const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       authUser ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/signin",
//             state: { from: props.location }
//           }}
//         />
//       )
//     }
//   />
// );
//
//
// function App(props){
//   const {match,location,locale} = props;
//   let applyTheme = createMuiTheme(greenTheme)
//   let authUser = getAuthData().token
//
//   const [appLocale,setAppLocale] = useState(null)
//
//   //const currentAppLocale = appLocale ? appLocale[locale.locale]: null;
//   const currentAppLocale = {
//     icon: "gb",
//     languageId: "english",
//     locale: "en",
//     name: "English"
//   }
//
//
//
//   return(
//     <ThemeProvider theme={applyTheme}>
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//           {currentAppLocale && (
//             <IntlProvider
//               locale={currentAppLocale.locale}
//               messages={currentAppLocale.messages}
//             >
//               <RTL>
//                 <div className="app-main">
//                   <Switch>
//                     <RestrictedRoute
//                       path={`${match.url}app`}
//                       authUser={authUser}
//                       component={MainApp}
//                     />
//                     <Route path="/signin" component={SignIn} />
//                     <Route path="/signup" component={SignUp} />
//                     <Route path="/regsuccess" component={RegSuccessPage} />
//                     <Route path="/resetPassword" component={ResestPassword} />
//                     {/*<Route*/}
//                     {/*  path="/retypePassword/:token"*/}
//                     {/*  component={RetypePassword}*/}
//                     {/*/>*/}
//                     <Route
//                         path="/retypePassword/:token"
//                         component={CreatePassword}
//                     />
//                     {/*<Route*/}
//                     {/*    path="/test"*/}
//                     {/*    component={CreatePassword}*/}
//                     {/*/>*/}
//                     <Route path="/test/:validator" component={Preview} />
//                     <Route
//                       component={asyncComponent(() =>
//                         import("app/routes/extraPages/routes/404")
//                       )}
//                     />
//                   </Switch>
//                 </div>
//               </RTL>
//             </IntlProvider>
//           )}
//         </MuiPickersUtilsProvider>
//       </ThemeProvider>
//   )
// }
// export default App;


