    import React, { Component } from "react";
import { Dialog, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IntlMessages from "../../../../util/IntlMessages";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomDialog from "../dialog/customDialog";
import TextField from '@material-ui/core/TextField';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import * as SettingsKey from "../../../../common/SettingsKey";
import { NotificationManager } from "react-notifications";
import CustomDeleteDialog from "../dialog/customDeleteDialog";
import CustomButton from "../button/CustomButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { TENANT_SWITCH_PERMISSION } from "../../../../common/SettingsKey";
import { getAuthData } from "../../../../common/helpers/AuthHelper";

//settings
import {itemSettings} from "../../../../common/settings/generalSettings";

    class CustomIndexToolBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddMenu: false,
            openRenameDialog: false,
            openDeleteDialog: false,
            openAddItemDialog: false,
            openAddFolderDialog: false,
            openCopyAlertDialog: false,
            name: "",
            newItemName: null,
            newAddType: null,
            anchorEl: null
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value && this.props.value !== "") {
            this.setState({
                name: this.props.itemType === "letter" ? this.props.value.split(' ').join("_") : this.props.value,
            })
        }
    }

    openAddItemDialog = () => {
        this.setState({
            anchorEl: null,
            newAddType: "item",
            openAddItemDialog: true
        })
    }

    openCopyAlert = () => {
        let isValid = this.validateSingleSelected()
        if (isValid) {
            this.setState({
                openCopyAlertDialog: true
            })
        }

    }

    openAddFolderDialog = () => {
        this.setState({
            anchorEl: null,
            newAddType: "folder",
            openAddFolderDialog: true
        })
    }
    handleOpenRename = () => {
        // this.props.handleOpenRename()
        let isValid = this.validateRename()
        if (isValid) {
            this.setState({
                openRenameDialog: true
            })
        }
    }
    addNewItem = () => {
        this.props.addNewItem()
    }
    addNewFolder = () => {
        this.props.addNewFolder()
    }
    handleChange = (event) => {
        let itemType = this.props.itemType
        let id = event.target.id.split("-")[0]
        let name = event.target.value;
        
        //this block is for replace " " with "-" in form and page when adding and renaming
        if(itemSettings.itemsWithDashedName.find(a =>a === itemType)){
            if((id === "newItemName" || id === "name") && this.state.newAddType !== "folder"){
                name = name.toString().replace(itemSettings.itemNameRegx,'-')
            }
        }

        this.setState({
            [id]: name
        })
    }
    handleCopy = () => {
        let isValid = this.validateSingleSelected()
        if (isValid) {
            this.props.handleCopy()
        }
        this.handleClose()
        this.handleCloseCopyAlert()
    }
    handleShare = () => {
        let isValid = this.validateSingleSelected()
        if (isValid) {
            this.props.handleShare()
        }
    }
    handleClose = () => {
        this.setState({
            newItemName: null,
            newAddType: null,
            openRenameDialog: false,
            openDeleteDialog: false,
            openAddItemDialog: false,
            openAddFolderDialog: false,
        })
    }
    handleRename = () => {
        this.props.handleRename(this.state.name)
        this.handleClose()
    }
    handleCloseRename = () => {
        this.setState({
            name: this.props.itemType === "letter" ? this.props.value.split(' ').join("_") : this.props.value,
            openRenameDialog: false,
        })
        //this.props.handleCloseRename()
    }
    handleAdd = () => {
        if (this.state.newAddType === "folder") {
            this.props.handleAdd(this.state.newItemName, "folder")
        }
        if (this.state.newAddType === "item") {
            this.props.handleAdd(this.state.newItemName, this.props.itemType)
        }
        this.handleClose()
    }
    openDelete = () => {
        let isValid = this.validateDelete()
        if (isValid) {
            this.setState({
                openDeleteDialog: true
            })
        }

    }
    handleDelete = () => {
        this.props.handleDelete()
        this.handleClose()
    }
    validateSingleSelected = () => {
        let isValid = false
        let selectedItems = this.props.selectedItems
        let selectedFolders = this.props.selectedFolders

        if (selectedFolders.length > 0) {
            NotificationManager.error("Can not select folder");
        } else if (selectedItems.length > 1) {
            NotificationManager.error("Cannot select multiple items");
        } else if (selectedItems.length < 1) {
            NotificationManager.error("No item is selected");
        } else {
            // if (selectedCampaign.includes(this.state.tempSharedEmail)) {
            // 	this.setState({ type: "shared" });
            // }
            // this.props.getOtherAccounts();
            // this.setState({
            //     shareDialogOpen: true,
            // });
            isValid = true
        }
        return isValid
    }

    validateRename = () => {
        let isValid = false
        let selectedItems = this.props.selectedItems
        let selectedFolders = this.props.selectedFolders

        if (selectedItems.length > 0 && selectedFolders.length > 0) {
            NotificationManager.error("You cannot select both item and folder");
        } else if (selectedItems.length > 1 || selectedFolders.length > 1) {
            NotificationManager.error("You cannot select mulitple " + this.state.type);
        } else if (selectedItems.length === 0 && selectedFolders.length === 0) {
            NotificationManager.error("No item selected");
        }
        else {
            isValid = true
        }
        return isValid
    }
    validateDelete = () => {
        let isValid = false
        let selectedItems = this.props.selectedItems
        let selectedFolders = this.props.selectedFolders

        if (selectedItems.length > 0 || selectedFolders.length > 0) {
            isValid = true
        } else {
            NotificationManager.error("Nothing is selected");
        }
        return isValid
    }
    handleCloseCopyAlert = () => {
        this.setState({
            openCopyAlertDialog: false
        })
    }
    render() {
        let showShare = false
        //itemType
        //
        let isShowTenantSwitch = false
        let accountId = null
        let allAccounts = this.props.allAccounts
        let addItemText = typeof (this.props.itemType) === "string" ? "_ADD_" + this.props.itemType.toUpperCase() : ""
        let renameText = this.props.selectedType === "folder" ? "Rename Folder" : "Rename " + this.props.itemType
        let showAddMenu = this.props.showAddMenu === undefined? true:this.props.showAddMenu
        const token = localStorage.getItem("user_token");

        if (this.props.itemType === "campaign") {
            if (token !== null) {
                let authInfo = JSON.parse(atob(token.split(".")[1]));
                let sharePermittedRoles = this.props.settings[SettingsKey.CAMPAIGN_SHARE_SETTINGS_KEY] ? this.props.settings[SettingsKey.CAMPAIGN_SHARE_SETTINGS_KEY] : ""
                let currentUserRoleId = authInfo.role_id
                showShare = sharePermittedRoles.split(",").some(a => a === currentUserRoleId)
            }
        } else {
            showShare = true
        }



        let tenantSwitchSettings = this.props.settings[TENANT_SWITCH_PERMISSION]
        if(tenantSwitchSettings === undefined){
            isShowTenantSwitch = false
        }else {
            let user = getAuthData()
            if(user.user !== null){
                let isExistUser = tenantSwitchSettings.split(",").find(a =>a === user.user.role_id)
                if(isExistUser === undefined){
                    isShowTenantSwitch = false
                }else {
                    isShowTenantSwitch = true
                }
            }

        }


        let tools = Array.isArray(this.props.tools) ? this.props.tools : ['add', 'copy', 'share', 'rename', 'delete']
        return (
            <div className={`toolbar-wrapper`} style={{display: this.props.isInline ? "inline-block": "" }}>
                {tools.find(a => a === 'add') !== undefined &&
                    <Tooltip
                        title="Add New Item"
                        style={{ position: "relative", display: "inline-block" }}
                    >
                        {showAddMenu?
                            <IconButton aria-label="Add New" size="small" style={{padding: "5.6px"}}>
                                <AddCircleIcon
                                    color="primary"
                                    fontSize="default"
                                    onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                                />
                                {this.state.anchorEl && (
                                    <div>
                                        <Menu
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={true}
                                            onClose={() => this.setState({ anchorEl: null })}
                                            style={{
                                                position: "absolute",
                                                marginTop: "10px",
                                                marginLeft: "35px",
                                            }}
                                        >
                                            {/* <MenuItem onClick={() => this.handleSaveDialogBox("form")}>
                                                <IntlMessages id="_ADD_FORM" />
                                            </MenuItem> */}
                                            <MenuItem id="folder" onClick={this.openAddFolderDialog}>
                                                <IntlMessages id="_ADD_FOLDER" />
                                            </MenuItem>
                                            <MenuItem id="campaign" onClick={this.openAddItemDialog}>
                                                <IntlMessages id={addItemText} />
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </IconButton>
                            :
                            <IconButton aria-label="Add New" onClick={this.props.handleAdd} style={{padding: "5.6px"}}>
                                <AddCircleIcon
                                    color="primary"
                                    fontSize="default"
                                    // onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                                />
                            </IconButton>
                        }

                    </Tooltip>
                }
                {tools.find(a => a === 'copy') !== undefined &&
                    <Tooltip title="Copy Item">
                        <IconButton aria-label="Copy Email" onClick={this.openCopyAlert}  style={{padding: "5.6px"}}>
                            <FileCopyIcon color="secondary" fontSize="default" />
                        </IconButton>
                    </Tooltip>
                }
                {tools.find(a => a === 'share') !== undefined &&
                    <>
                        {showShare && (
                            <Tooltip title="Share Item">
                                <IconButton aria-label="Share Item" onClick={this.handleShare}  style={{padding: "5.6px"}}>
                                    <ShareIcon
                                        // color="action"
                                        style={{ color: "rgb(33, 150, 243)" }}
                                        fontSize="default"
                                    />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                }

                {tools.find(a => a === 'rename') !== undefined &&
                    <Tooltip title="Rename">
                        <IconButton aria-label="Rename" onClick={this.handleOpenRename}  style={{padding: "5.6px"}}>
                            <EditIcon color="action" fontSize="default" />
                        </IconButton>
                    </Tooltip>
                }

                {tools.find(a => a === 'delete') !== undefined &&
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete Email" onClick={this.openDelete}  style={{padding: "5.6px"}}>
                            <DeleteIcon color="error" fontSize="default" />
                        </IconButton>
                    </Tooltip>
                }


                {/*{isShowTenantSwitch &&*/}
                {/*<FormControl className="ml-2 template-area-for-tg" style={{ width: "150px", float:"right" }}>*/}
                {/*    <Select*/}
                {/*        value={accountId}*/}
                {/*        onChange={this.handleChangeAccount}*/}
                {/*        displayEmpty*/}
                {/*    >*/}
                {/*        <MenuItem value="0">*/}
                {/*            <em>*/}
                {/*                <IntlMessages id="_PLEASE_SELECT" />*/}
                {/*            </em>*/}
                {/*        </MenuItem>*/}
                {/*        {allAccounts && allAccounts.length > 0 &&*/}
                {/*        allAccounts.map((account) => {*/}
                {/*            return (*/}
                {/*                <MenuItem value={account.id}>*/}
                {/*                    {account.name}*/}
                {/*                </MenuItem>*/}
                {/*            );*/}
                {/*        })}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*}*/}


                {/*for item adding*/}
                <CustomDialog
                    open={this.state.openAddItemDialog}
                    maxWidth={"sm"}
                    handleClose={this.handleClose}
                    titleTextKey={addItemText}
                    dialogContent={false}
                    handleAdd={this.handleAdd}
                    actionButtons={['add', 'close']}
                >
                    <DialogContent>
                        <TextField onChange={this.handleChange}
                            id="newItemName"
                            fullWidth={true}
                            value={this.state.newItemName}
                            // autoFocus={true}
                            label={<IntlMessages id={addItemText} />}
                        />
                    </DialogContent>

                </CustomDialog>

                {/*for folder adding*/}
                <CustomDialog
                    open={this.state.openAddFolderDialog}
                    maxWidth={"sm"}
                    handleClose={this.handleClose}
                    titleTextKey={"_ADD_FOLDER"}
                    dialogContent={false}
                    handleAdd={this.handleAdd}
                    actionButtons={['add', 'close']}
                >
                    <DialogContent>
                        <TextField onChange={this.handleChange}
                            id="newItemName"
                            fullWidth={true}
                            // autoFocus={true}
                            label={<IntlMessages id={"_ADD_FOLDER"} />}
                        />
                    </DialogContent>
                </CustomDialog>

                {/*for renaming*/}
                <CustomDialog
                    open={this.state.openRenameDialog}
                    maxWidth={"sm"}
                    handleClose={this.handleCloseRename}
                    titleTextKey={"_RENAME"}
                    dialogContent={false}
                    handleSave={this.handleRename}
                    actionButtons={['save', 'close']}
                >
                    <DialogContent style={{ marginTop: "7px" }}>
                        <TextField onChange={this.handleChange}
                            id="name"
                            fullWidth={true}
                            value={this.state.name}
                            // autoFocus={true}
                            label={<IntlMessages id={renameText} />}
                        />
                    </DialogContent>
                </CustomDialog>

                {/*copy alert*/}
                <CustomDialog
                    open={this.state.openCopyAlertDialog}
                    maxWidth={"sm"}
                    handleClose={this.handleCloseCopyAlert}
                    handleNo={this.handleCloseCopyAlert}
                    titleTextKey={"_COPY"}
                    dialogContent={false}
                    handleYes={this.handleCopy}
                    actionButtons={['yes', 'no']}
                >
                    <DialogContent style={{ marginTop: "7px" }}>
                        {/*<TextField onChange={this.handleChange}*/}
                        {/*           id="name"*/}
                        {/*           fullWidth={true}*/}
                        {/*           value={this.state.name}*/}
                        {/*    // autoFocus={true}*/}
                        {/*           label={<IntlMessages id={renameText}/>}*/}
                        {/*/>*/}
                        <DialogContentText>
                            <IntlMessages id={"_ECLICKSPRO_ITEM_COPY_ALERT_MESSAGE"} /> "{this.state.name}" ?
                        </DialogContentText>
                    </DialogContent>
                </CustomDialog>

                {/*for delete*/}
                <CustomDeleteDialog
                    open={this.state.openDeleteDialog}
                    handleDelete={this.handleDelete}
                    handleClose={this.handleClose}
                />
            </div>
        );
    }
}

export default CustomIndexToolBar;