import {
    FETCH_EMAIL_RELATION,
    FETCH_EMAIL_RELATION_SUCCESS
  } from "constants/ActionTypes";
  
  export const fetchEmailRelation = params => {
    return {
      type: FETCH_EMAIL_RELATION,
      payload: params
    };
  };
  
  export const fetchEmailRelationSuccess = filters => {
    return {
      type: FETCH_EMAIL_RELATION_SUCCESS,
      payload: filters
    };
  };
  