import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { NavLink } from "react-router-dom";
import { profileInfo } from "actions/Profile";
import { userSignIn } from "actions/Auth";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  componentDidMount() {
    const { authUser } = this.props;
    this.props.profileInfo(authUser);
  }

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { info } = this.props;
    // console.log("info from Userinfo ->", info);
    if (info && info.status === "error") {
      localStorage.clear();
      window.location.reload();
    }
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt="..."
          src={info && info.data.image}
          className="user-avatar "
        />
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {info && info.data.first_name} {info && info.data.last_name}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/profile"
              style={{ textDecoration: "none", color: "#4caf50" }}
            >
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <IntlMessages id="_PROFILE_SETTINGS" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/account/subscription"
              style={{ textDecoration: "none", color: "#ff9800" }}
            >
              <i className="zmdi zmdi-archive zmdi-hc-fw mr-1" />
              <IntlMessages id="_PACKAGE_SUBSCRIPTION" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/home"
              style={{ textDecoration: "none", color: "#2196f3" }}
            >
              <i className="zmdi zmdi-account-calendar zmdi-hc-fw mr-1" />
              <IntlMessages id="_ACCOUNT_INFORMATION" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
                to="/app/custom-views/Payment-History"
                style={{ textDecoration: "none"}}
            >
              <i className="zmdi  zmdi-money-box zmdi-hc-fw mr-1" />
              <IntlMessages id="_ECLICKSPRO_ACCOUNT_INFORMATION" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink to="/app/domain" style={{ textDecoration: "none", color: "#000000" }}>
              <i className="zmdi zmdi-globe-alt mr-1"></i>
              <IntlMessages id="_ECLICKSPRO_ADD_PERSONAL_DOMAIN" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink to="" style={{ textDecoration: "none", color: "#000000" }}>
              <i className="zmdi zmdi-view-toc zmdi-hc-fw mr-1" />
              <IntlMessages id="_VARIABLES" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/chat"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
              <IntlMessages id="_PORTAL" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/gdrp"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <i className="zmdi zmdi-check zmdi-hc-fw mr-1" />
              <IntlMessages id="_GDPR_COMPLIANCE" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="app/contact"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <i className="zmdi zmdi-accounts-outline zmdi-hc-fw" />
              <IntlMessages id="_CONNECTED_ACCOUNT" />
            </NavLink>
          </MenuItem>

          <MenuItem onClick={this.handleRequestClose}>
            <NavLink
              to="/app/to-do"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <i className="zmdi zmdi-calendar zmdi-hc-fw" />
              <IntlMessages id="_SCHEDULER" />
            </NavLink>
          </MenuItem>

          <MenuItem
            style={{ textDecoration: "none", color: "red" }}
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="_LOGOUT" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, profile, auth }) => {
  // console.log("auth from userinfo ->", auth);
  const { authUser } = auth;
  const { loader, info } = profile;
  const { locale } = settings;
  return { locale, loader, authUser, info };
};
export default connect(mapStateToProps, {
  userSignOut,
  profileInfo,
  userSignIn
})(UserInfo);
