import React from "react";
import IntlMessages from "util/IntlMessages";
import LineIndicator from "./LineIndicator";

export default function EmailPercentage({ data }) {
  return (
    <div className="jr-card jr-card-widget jr-card-ticketlist card">
      <div className="pt-xl-2">
        <p
          className="jr-fs-lg text-uppercase"
          style={{ fontWeight: "bold", fontSize: "1rem" }}
        >
          <IntlMessages id="_ECLICKSPRO_EMAIL_PERCENTAGE" />
        </p>
        <ul className="jr-line-indicator jr-fs-sm">
          <li className="mb-3">
            <LineIndicator
              width={data.open + "%"}
              title="Email Opened"
              // title2="8.74"
              color="#2196F3"
              value={data.open + "%"}
            />
          </li>
          <li className="mb-3">
            <LineIndicator
              width={data.click + "%"}
              title="Email Clicked"
              // title2="1.23"
              color="#00BCD4"
              value={data.click + "%"}
            />
          </li>
          <li className="mb-3">
            <LineIndicator
              width={data.bounce + "%"}
              title="Email Bounced"
              // title2="0.71"
              color="#F44336"
              value={data.bounce + "%"}
            />
          </li>
          <li className="mb-2">
            <LineIndicator
              width={data.unsubscribe + "%"}
              title="Email Unsubscribed"
              // title2="0.71"
              color="#FFC107"
              value={data.unsubscribe + "%"}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
