import React, { useEffect } from "react";
import axios from "axios";

import LanguageItem from "./LanguageItem";
import CustomScrollbars from "util/CustomScrollbars";

import config from "../../config";
import { useContext } from "react";
import { SettingContext } from "../../context/SettingContext";
import { getAuthData } from "../../common/helpers/AuthHelper";

const languages = [];



const LanguageSwitcher = ({ switchLanguage, handleRequestClose }) => {

  const getProfileData = (cb=() => {}) => {
    let {token} = getAuthData()
    let url = config.api.url+ "api/profile";

    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: token,
      },
      })
        .then((res) => {
          // let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {
            cb("success",res.data)
          } else {
            cb("failed", {})
          }
        })
        .catch((e) => {
          // let errorResponse = getResponseForNotificaion(null,{})
          cb("failed", {})
      });
  }
  useEffect(()=>{
    fetch(`${config.api.url}api/languages`, {
      method: "POST"
    })
      .then(res => res.json())
      .then(json =>
        json.data.map(lan =>{
          const lanObj = {
              languageId: lan?.name || 'english',
              locale: lan?.code || 'en',
              name: lan?.name.replace(/^\w/, c => c.toUpperCase()) || "English",
              icon: lan?.code === "en" ? "gb" : lan.code
            };
            languages.push(lanObj);

            getProfileData((status,data) => {
              if(status === "success"){
                if(!localStorage.getItem("languageDefault")){
                  if(data.data.language === lan.name){
                    localStorage.setItem('languageDefault', JSON.stringify(lanObj));
                    switchLanguage(lanObj);
                  }
                }
              }
            })
            
            
          }
        )
      );
  },[])

  return (
    <CustomScrollbars
      className="messages-list language-list scrollbar"
      style={{ height: 78 }}
    >
      <ul className="list-unstyled">
        {languages.map((language, index) => (
          <LanguageItem
            key={language.id}
            language={language}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default LanguageSwitcher;
