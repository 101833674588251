import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import keycode from "keycode";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import IntlMessages from "util/IntlMessages";
import Header from "./Header";
import config from "config";
import Pagination from "react-mui-pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DynamicIcons from "components/DynamicIcons/DynamicIcons";
import { NotificationManager } from "react-notifications";
import "./style.css";

import { fetchEditView } from "actions/EditView";
import CircularLoader from "../../customComponents/loader/CircularLoader";
import EclicksProTour from "../../customComponents/UserTour/EclicksProTour";
import { LISTING_FIELD_ELLIPSIS_LENGTH } from "../../../../common/SettingsKey";


class EnhancedTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  state = {
    suggestionItems: null,
    flag: false,
    multipleSearchOptions: this.props.columnSearchText || [],
    searchLists: this.props.searchLists || [],
    multipleSearchText:"",
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  //this function hits when we click on any header search
  onFocusHandler = async (event) => {
    const { token, tableName } = this.props;

    const response = await fetch(
      `${config.api.url}api/field_search/${tableName}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field_name: event.target.id,
          search: "",
          lookup: "",
          datetime: "",
          input_type: "",
          data_select: "",
          ref_table: "",
          data_key: "",
          data_value: "",
          attr_table: "",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => json);
    if (response.status === "success") {
      if(this.props.tableName === "relation"){
        const newData = response.data.map(item=>{return {value: item.text, text: item.text}})
        this.setState({ suggestionItems: newData });
      }else{
        this.setState({ suggestionItems: response.data });
      }
    }
  };

  //this function hits when we write or remove on any header search
  onChageHandler = async (event) => {
    const { token, tableName } = this.props;
    this.setState({
      multipleSearchText:event.target.value
    })
    const response = await fetch(
      `${config.api.url}api/field_search/${tableName}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          field_name: event.target.id,
          search: event.target.value,
          lookup: "",
          datetime: "",
          input_type: "",
          data_select: "",
          ref_table: "",
          data_key: "",
          data_value: "",
          attr_table: "",
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => json);

    if (response.status === "success") {
      if(this.props.tableName === "relation"){
        const newData = response.data.map(item=>{return {value: item.text, text: item.text}})
        this.setState({ suggestionItems: newData });
      }else{
        this.setState({ suggestionItems: response.data });
      }
    }
  };

  // columSearch = (name) => (e) => {
  //   // console.log("e from onChange", e.target);
  //   if (e.target.value) {
  //     const garbageValue = `<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>`;
  //     if (e.target.value === "") {
  //       return;
  //     }
  //
  //     if (e.target.value === garbageValue) {
  //       localStorage.removeItem("state");
  //       localStorage.removeItem(name);
  //       return window.location.reload();
  //     }
  //     const { multipleSearchOptions } = this.state;
  //     let item = e.target.value;
  //     this.setState((state, e) => ({
  //       searchLists: [...state.searchLists, { value: item }],
  //     }));
  //
  //     if (localStorage.getItem(name)) {
  //       let items = localStorage.getItem(name);
  //       items = JSON.parse(items);
  //       items.push({ value: item });
  //       localStorage.setItem(name, JSON.stringify(items));
  //     } else {
  //       const newItem = [];
  //       newItem.push({ value: item });
  //       localStorage.setItem(name, JSON.stringify(newItem));
  //     }
  //
  //     if (Object.keys(multipleSearchOptions).length === 0) {
  //       this.setState({
  //         multipleSearchOptions: {
  //           [name]: [e.target.value],
  //         },
  //       });
  //     } else {
  //       for (let key in multipleSearchOptions) {
  //         if (key === name) {
  //           let value = multipleSearchOptions[name];
  //           value.push(e.target.value);
  //           this.setState((state) => ({
  //             multipleSearchOptions: {
  //               ...state.multipleSearchOptions,
  //               [name]: value,
  //             },
  //           }));
  //         } else {
  //           const value = e.target.value;
  //           this.setState((state) => ({
  //             multipleSearchOptions: {
  //               ...state.multipleSearchOptions,
  //               [name]: [value],
  //             },
  //           }));
  //         }
  //       }
  //     }
  //     setTimeout(() => this.fetchSearchResult(name), 300);
  //   } else {
  //     const garbageValue = `<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>`;
  //     if (e.target.innerHTML === "") {
  //       return;
  //     }
  //
  //     if (e.target.innerHTML === garbageValue) {
  //       localStorage.removeItem("state");
  //       localStorage.removeItem(name);
  //       return window.location.reload();
  //     }
  //     const { multipleSearchOptions } = this.state;
  //     let item = e.target.innerHTML;
  //     this.setState((state, e) => ({
  //       searchLists: [...state.searchLists, { value: item }],
  //     }));
  //
  //     if (localStorage.getItem(name)) {
  //       let items = localStorage.getItem(name);
  //       items = JSON.parse(items);
  //       items.push({ value: item });
  //       localStorage.setItem(name, JSON.stringify(items));
  //     } else {
  //       const newItem = [];
  //       newItem.push({ value: item });
  //       localStorage.setItem(name, JSON.stringify(newItem));
  //     }
  //
  //     if (Object.keys(multipleSearchOptions).length === 0) {
  //       this.setState({
  //         multipleSearchOptions: {
  //           [name]: [e.target.innerHTML],
  //         },
  //       });
  //     } else {
  //       for (let key in multipleSearchOptions) {
  //         if (key === name) {
  //           let value = multipleSearchOptions[name];
  //           value.push(e.target.innerHTML);
  //           this.setState((state) => ({
  //             multipleSearchOptions: {
  //               ...state.multipleSearchOptions,
  //               [name]: value,
  //             },
  //           }));
  //         } else {
  //           const value = e.target.innerHTML;
  //           this.setState((state) => ({
  //             multipleSearchOptions: {
  //               ...state.multipleSearchOptions,
  //               [name]: [value],
  //             },
  //           }));
  //         }
  //       }
  //     }
  //     //setTimeout(() => this.fetchSearchResult(name), 300);
  //     this.fetchSearchResult(name)
  //   }
  // };

  columSearch = (name) => (e,value, reason) => {
    //this.props.setLocalValues([name],[e.target.value])
   // this.props.handleColumnSearch(name,e.target.innerHTML)
    //let value = e.target.outerText
    // if(value === ""){
    //   value = this.state.multipleSearchText
    // }
    
    this.props.handleColumnSearch(name,value[value.length-1]?.value)
    // this.setState({
    //   multipleSearchText:""
    // })
  }

  fetchSearchResult = (name) => {
    const { multipleSearchOptions } = this.state;
    let searchLists = localStorage.getItem(name);
    const {columnList} = this.props

    JSON.parse(searchLists);
    // console.log("searchLists --->>>", searchLists);

    let newMultiSearch = null
    for (let key in localStorage) {
      if (columnList.includes(key)) {
        let items = localStorage.getItem(key);
        items = JSON.parse(items);
        const defaultItems = [];
        // console.log("items -->", items);
        if (items) {
          items.map((item) => {
            if (item) {
              defaultItems.push(item.value);
            }
          });
        }
        newMultiSearch = {
          [key]: defaultItems,
        };
      }
    }


    this.props.fetchColumnSearchResult(newMultiSearch, searchLists);

  };
  getFromLocalStorage = (name,tableName) =>{
    let localData = JSON.parse(localStorage.getItem("localData"))
    let columnData = []
    if(localData){
      let selectedTable = localData[tableName]
      if(selectedTable !== null){
        let selectedObj = selectedTable.multipleSearchOptions
        if(selectedObj !== null || selectedObj !== undefined){
          let selectedItems = selectedObj[name]
          // if(selectedItems.isArray()){
            columnData = selectedItems
          // }
        }
      }
    }
    if(columnData === undefined){
      columnData = []
    }
    return columnData
  }

  onKeyDownHandler = async (e) => {
    // console.log(e.target.keyCode);
  };
  removeSearchChip = (event) =>{
    //let value = event.target.parentNode.previousSibling.innerHTML
    let value = event.target.parentElement.parentElement.innerText
    let name = event.target.parentElement.parentElement.id
    this.props.removeSearchItem(value,this.props.tableName,name,this.props.multipleSearchOptions)
  }

  render() {
    const {
      onSelectAllClick,
      numSelected,
      rowCount,
      columnData,
      customOrderingField,
      columnList,
      tableName,
      token,
      searchFlag,
    } = this.props;

    // for (let key in localStorage) {
    //   if (columnList.includes(key)) {
    //     let items = localStorage.getItem(key);
    //     items = JSON.parse(items);
    //     const defaultItems = [];
    //     // console.log("items -->", items);
    //     if (items) {
    //       items.map((item) => {
    //         if (item) {
    //           defaultItems.push(item.value);
    //         }
    //       });
    //     }
    //     const newMultiSearch = {
    //       [key]: defaultItems,
    //     };
    //     // console.log("newMultiSearch ---->", newMultiSearch);
    //     if (!localStorage.getItem("state")) {
    //       localStorage.setItem("state", true);
    //       this.props.fetchColumnSearchResult(newMultiSearch, items);
    //     }
    //     if (localStorage.getItem("state") && searchFlag) {
    //       this.props.fetchColumnSearchResult(newMultiSearch, items);
    //     }
    //   }
    // }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && rowCount !== 0}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map((column, i) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
              >
                <div
                  onClick={async () => {
                    if (
                      customOrderingField[i] === "none" ||
                      customOrderingField[i] === "desc"
                    ) {
                      customOrderingField[i] = "asc";
                    } else if (customOrderingField[i] === "asc") {
                      customOrderingField[i] = "desc";
                    }

                    const response = await fetch(
                      `${config.api.url}api/${tableName}/listview`,
                      {
                        method: "POST",
                        headers: {
                          Authorization: token,
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          item_per_page: "10",
                          page_no: "0",
                          search: "",
                          order_by_field: "",
                          order_direction: "",
                          table_sort_field: columnList.join(","),
                          table_sort_direction: customOrderingField.join(","),
                        }),
                      }
                    )
                      .then((res) => res.json())
                      .then((json) => json);

                    if (response.status === "success") {
                      return window.location.reload();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <IntlMessages id={"_" + column.label.toUpperCase()} />
                  <span>
                    &nbsp;
                    <DynamicIcons id={customOrderingField[i]} table={true} />
                  </span>
                </div>
              </TableCell>
            );
          }, this)}
        </TableRow>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {columnData.map((column, i) => {
            //let columnSearchItem = this.getFromLocalStorage(column.label,tableName)
            let columnSearchItem = []
            if(this.props.multipleSearchOptions){
              let items = this.props.multipleSearchOptions[column.label]
              if(Array.isArray(items)){
                columnSearchItem = items
              }
            }


            //let items = this.getFromLocalStorage(label,tableName)
            //let localData = this.getFromLocalStorage(column.label,tableName)
            // if (localStorage.getItem(column.label)) {
            //   // columnSearchItem = localStorage.getItem(column.label);
            //   // columnSearchItem = JSON.parse(columnSearchItem);
            //   columnSearchItem = this.getFromLocalStorage()
            //
            // } else {
            //   columnSearchItem = [];
            // }
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
              >
                <div style={{ padding: "3px 0px" }}>
                  <Autocomplete
                    ChipProps={{
                      id:column.label,
                      onDelete: this.removeSearchChip
                    }}
                    key={i}
                    multiple
                    size="small"
                    id={column.label}
                    options={this.state.suggestionItems || []}
                    getOptionLabel={(option) => option.value}
                    onChange={this.columSearch(column.label)}
                    defaultValue={columnSearchItem}
                    onKeyDown={this.onKeyDownHandler}
                    // autoComplete="off"
                   // freeSolo
                   // autoHighlight
                    //autoSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        onFocus={this.onFocusHandler}
                        style={{ width: 150 }}
                        onChange={this.onChageHandler}
                        name={column.label}
                        id={column.label}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

const AlertBox = ({
  flag,
  selectedItems,
  token,
  menu,
  closeAlertBox,
  getLoading,
}) => {
  const handleClose = () => {
    closeAlertBox();
  };

  return (
    <div>
      <Dialog
        open={flag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id="_ARE_YOU_SURE_TO_DELETE" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    handleClose();
                    fetch(`${config.api.url}api/${menu}/itemDelete`, {
                        method: "POST",
                        headers: {
                            Authorization: token,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            ids: selectedItems,
                            hardDelete: "0",
                        }),
                    })
                        .then((res) => res.json())
                        .then((json) => {
                            if (json.status === "success") {
                                let msg = <IntlMessages id="_DELETION_SUCCESSFULL" />;
                                getLoading(true);
                                NotificationManager.info(msg);
                                setTimeout(() => window.location.reload(), 1000);
                            } else {
                                getLoading(false);
                                NotificationManager.error(json.message);
                            }
                        });
                }}
                color="primary"
                autoFocus
            >
                <IntlMessages id="_CONFIRM_DELETE" />
            </Button>
          <Button onClick={handleClose} color="secondary">
            <IntlMessages id="_CANCEL" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    title,
    openAlertBox,
    rowsPerPage,
    handleItemsRowsPerPage,
    total,
    permissions,
  } = props;

  return (
    <Toolbar className="table-header">
      {/* <span
        style={{
          width: "175px",
          marginLeft: "-10px",
          fontWeight: "bold",
          color: "#4caf50",
        }}
      >
        <IntlMessages id="_TOTAL" /> {total} {total > 1 ? "Items" : "Item"}
      </span> */}
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">
            <h3 style={{ color: "#4caf50" }}>{title}</h3>
          </Typography>
        )}
      </div>
      <div className="spacer" />
      <div className="actions">
        {permissions.delete_permission === "1" && numSelected > 0 && (
          <Tooltip title="Delete">
            <button
              style={{
                border: "none",
                backgroundColor: "Transparent",
                backgroundRepeat: "no-repeat",
              }}
              onClick={() => openAlertBox()}
            >
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </button>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class EnhancedTable extends React.Component {
  componentDidMount() {
    const { column, rows } = this.props;
    const columnData = [];
    const rowData = [];

    column.forEach((value) =>
      columnData.push({
        id: value,
        align: false,
        disablePadding: true,
        label: value,
      })
    );
    rows.forEach((row) => {
      rowData.push(this.createData(row.id, row));
    });

    this.setState({ data: rowData });

    const { token, menu } = this.props;

    const params = {
      token: token,
      tableName: menu,
      id: "99999999",
    };
    this.props.fetchEditView(params);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.rows !== this.props.rows && this.props.rows !== []){
      const { column, rows } = this.props;
      const columnData = [];
      const rowData = [];

      column.forEach((value) =>
          columnData.push({
            id: value,
            align: false,
            disablePadding: true,
            label: value,
          })
      );
      rows.forEach((row) => {
        rowData.push(this.createData(row.id, row));
      });

      this.setState({ data: rowData });

      const { token, menu } = this.props;

      const params = {
        token: token,
        tableName: menu,
        id: "99999999",
      };
      this.props.fetchEditView(params);
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map((n) => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleItemsRowsPerPage = (event) => {
    // console.log("event.target.value -> ", event.target.value);
    this.setState({ rowsPerPage: event.target.value });
    this.props.fetchItemsPerPage(event.target.value);
  };
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "",
      orderBy: "",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: props.itemPerPage,
      customPage: props.pageNumber,
      flag: false,
    };
  }

  createData = (id, args) => {
    let obj = [];
    // console.log("arg---->", args);
    for (const index of Object.keys(args)) {
      // console.log("index----->", index);
      obj.push(args[index]);
    }
    // console.log("obj---->", obj);
    return { id: id, obj };
  };

  searchValueFromHeader = (value, flag) => {
    this.props.searchValue(value, flag);
  };

  setPage = (event, page) => {
    // console.log("page ->", page);
    this.setState({ customPage: page });
    this.props.getPageNumber(page);
  };

  closeAlertBox = () => {
    this.setState({ flag: false });
  };

  openAlertBox = () => {
    this.setState({ flag: true });
  };

  fetchColumnSearchResult = (value, lists) => {
    // console.log(value);
    // localStorage.setItem("state", false);

    //this.props.fetchColumnSearchResult(value, lists);

    this.props.fetchColumnSearch(value, lists)
  };

  getLoading = (loading) => {
    this.setState({ tableLoading: loading });
  };
  render() {
    // console.log("this.props from enhanched table ->", this.props);
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      customPage,
    } = this.state;
    const {
      title,
      column,
      token,
      menu,
      flag,
      allTemplate,
      allAccounts,
      selectedTemplate,
      tableName,
      availableFields,
      orderingFields,
      customOrderingField,
      totalItems,
      searchText,
      columnSearchText,
      searchLists,
      item,
      permissions,
      settings
    } = this.props;
    const columnData = [];
    const pagesPerRow = ["10", "20", "50", "100", "1000"];

    // fields to trancate
    const textAreaFiels = ["language_value", "description", "script", "code", "queries"];

    // console.log("column", column);
    column.forEach((value) =>
      columnData.push({
        id: value,
        align: false,
        disablePadding: true,
        label: value,
      })
    );
    return (
      <div>
        <Header
          searchValueFromHeader={this.searchValueFromHeader}
          allTemplate={allTemplate}
          allAccounts={allAccounts}
          accountId={this.props.accountId}
          selectedTemplate={selectedTemplate}
          token={token}
          settings={settings}
          tableName={tableName}
          availableFields={availableFields}
          selectedFields={column}
          selectedData={selected}
          orderingFields={orderingFields}
          customOrderingField={customOrderingField}
          searchText={searchText}
          item={item}
          totalItemToExport={rowsPerPage}
          permissions={permissions}
          total={totalItems}
          handleChangeAccount={this.props.handleChangeAccount}
          postOrderView={this.props.postOrderView}
        />
        {(selected.length > 0 && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            openAlertBox={this.openAlertBox}
            rowsPerPage={rowsPerPage}
            // handleItemsRowsPerPage={this.handleItemsRowsPerPage}
            // total={totalItems}
            permissions={permissions}
          />
        )) || <div className="pt-3 pb-3"></div>}
        <div className="flex-auto">
          <div className="table-responsive-material">
            <EclicksProTour
                menu={menu}
                view={"list"}
            />
            <Table>
              <EnhancedTableHead
                searchFlag={flag}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columnData={columnData}
                token={token}
                tableName={tableName}
                customOrderingField={customOrderingField}
                columnList={column}
                columnSearchText={columnSearchText}
                fetchColumnSearchResult={this.fetchColumnSearchResult}
                // setLocalValues={this.props.setLocalValues}
                handleColumnSearch={this.props.handleColumnSearch}
                multipleSearchOptions={this.props.multipleSearchOptions}
                removeSearchItem={this.props.removeSearchItem}
                searchLists={searchLists}
              />
              <TableBody id="tablebody" style={{ backgroundColor: "white" }}>
                {this.props.rowLoader === false &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      const isSelected = this.isSelected(n.id);
                      const textAreaFieldOfTheTable = column.map((item, pos)=>textAreaFiels.includes(item)? pos : -1).filter(e=> e!== -1);
                      const maxEllipsisLength = settings[LISTING_FIELD_ELLIPSIS_LENGTH];

                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, n.id)}
                          onKeyDown={(event) => this.handleKeyDown(event, n.id)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                          style={{ cursor: "pointer" }}
                          className={"common-list-row"}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox color="primary" checked={isSelected} />
                          </TableCell>

                          {column.length + 1 === n.obj.length
                            ? n.obj.pop() &&
                              n.obj.map((value, index) => {
                                //this if block to not render id column.
                                if(value === n.id){
                                  return null
                                }else{
                                  return(
                                      <TableCell padding="none" key={`${value}_${index}`}>
                                        <Link
                                            to={`/app/listing/${tableName}/edit/${n.id}`}
                                        >
                                          {value ? (
                                              tableName !== "variables" &&
                                              (value.includes(".jpg") ||
                                                  value.includes(".png") ||
                                                  value.includes(".ico")) ? (
                                                  <img
                                                      style={{
                                                        width: "80px",
                                                        padding: "10px",
                                                      }}
                                                      src={value}
                                                  />
                                              ) : (
                                                textAreaFieldOfTheTable.length !== 0 && value.length > maxEllipsisLength && textAreaFieldOfTheTable.includes(index) ?
                                                    value.slice(0, maxEllipsisLength).concat("...") : value
                                              )
                                          ) : (
                                            value
                                          )}
                                        </Link>
                                      </TableCell>
                                  )
                                }

                              })
                            :
                              //n.obj.pop()
                              n.obj.map((value, index) => {
                                //this if block to not render id column.
                                if(value === n.id){
                                  return null
                                }else{
                                  return(
                                      <TableCell padding="none">
                                        <Link
                                            to={`/app/listing/${tableName}/edit/${n.id}`}
                                        >
                                          {value ? (
                                              tableName !== "variables" &&
                                              (value.includes(".jpg") ||
                                                  value.includes(".png") ||
                                                  value.includes(".ico")) ? (
                                                  <img
                                                      style={{
                                                        width: "80px",
                                                        padding: "10px",
                                                      }}
                                                      src={value}
                                                  />
                                              ) : (
                                                textAreaFieldOfTheTable.length !== 0 && value.length > maxEllipsisLength && textAreaFieldOfTheTable.includes(index) ? 
                                                  value.slice(0, maxEllipsisLength).concat("...") : value
                                              )
                                          ) : (
                                              value
                                          )}
                                        </Link>
                                      </TableCell>
                                  )
                                }
                              })}
                        </TableRow>
                      );
                    }
                )}
              </TableBody>
            </Table>

            {/*showing loader in table*/}
            {this.props.rowLoader &&
              <CircularLoader/>
            }
            {/*showing loader in table*/}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
              }}
            >
              <FormControl className="mr-2" style={{ width: "100px" }}>
                <Select
                  onChange={this.handleItemsRowsPerPage}
                  value={rowsPerPage}
                >
                  {pagesPerRow.map((value, i) => {
                    if (rowsPerPage == value) {
                      return (
                        <MenuItem value={value} selected={value} key={`${i}${value}`}>
                          {value}
                        </MenuItem>
                      );
                    } else {
                      return <MenuItem value={value} key={`${i}${value}`}>{value}</MenuItem>;
                    }
                  })}
                </Select>
              </FormControl>
              <Pagination
                page={customPage}
                setPage={this.setPage}
                total={totalItems}
                perPage={rowsPerPage}
              />
            </div>
            <AlertBox
              flag={this.state.flag}
              closeAlertBox={this.closeAlertBox}
              selectedItems={selected}
              token={token}
              menu={menu}
              getLoading={this.getLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ editView }) => {
  // console.log("auth->", auth);
  // console.log("editview->", editView);
  const { loader, params, item } = editView;
  return { loader, params, item };
};

export default  connect(mapStateToProps, {
  fetchEditView,
})(EnhancedTable);
