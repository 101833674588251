import { FETCH_MENU, FETCH_MENU_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  token: localStorage.getItem("user_token"),
  menus: null,
  gdpr:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MENU: {
      return {
        ...state,
        loader: true,
        token: action.payload
      };
    }
    case FETCH_MENU_SUCCESS: {
      return {
        ...state,
        loader: false,
        menus: action.payload
      };
    }
    case "SUCCESS_GET_GDPR":{
      return {
        ...state,
        gdpr: action.gdpr
      }
    }

    case "SUCCESS_ACCEPT_GDPR":
      return {
        ...state
      }
    default:
      return state;
  }
};
