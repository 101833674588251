import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_SMS_RELATION } from "constants/ActionTypes";
import { fetchSmsRelationSuccess} from "actions/SmsRelation";

import config from "../config";

// Sms
const fetchSmsRelationData = async params => {
  const { token } = params;

  const response = await fetch(`${config.api.url}v1/get/relations?limit=5&offset=0&phonenumber_mobile=%2B`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchSmsRelationData({ payload }) {
  try {
    const getSmsRelationData = yield call(fetchSmsRelationData, payload);
    yield put(fetchSmsRelationSuccess(getSmsRelationData));
  } catch (e) {
    console.error(e);
  }
}

export function* getSmsRelationData() {
  yield takeEvery(FETCH_SMS_RELATION, getFetchSmsRelationData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getSmsRelationData)]);
}
