import React, { Component, createRef } from "react";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as folderViewActions, actionCreators } from "../../../../actions/FolderViewAction";

//language
import IntlMessages from "util/IntlMessages";

//Context
import { SettingContext } from "context/SettingContext";
import { withStyles } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import DialogContent from '@material-ui/core/DialogContent';
import CustomIndexToolBar from "../toolbar/CustomIndexToolBar";
import CircularLoader from "../loader/CircularLoader";
import { Avatar, Box, Button, Checkbox, Grid, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, Tooltip } from "@material-ui/core";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";
//custom
import CustomLoaderDidalog from "../dialog/CustomLoaderDialog";
import CustomIcon from "../icon/CustomIcon"
import { NotificationContainer, NotificationManager } from "react-notifications";
import CustomDialog from "../dialog/customDialog";
import { dialogStyles } from "../dialog/customDialog";
import CustomDeleteDialog from "../dialog/customDeleteDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import EclicksProTour from "../UserTour/EclicksProTour";
import { setLocalData } from "../../../../common/helpers/LocalDataHandler";
import { capitalizeFirstLetter } from '../../../../util/customMethods'

//settings
import { itemSettings } from "../../../../common/settings/generalSettings"

const useStyles = theme => ({

    mainWrapperBox: {
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        padding: "20px"
    },
    customCol_lg_20: {
        flex: "0 0 20%",
        maxWidth: "20%",
        position: "relative",
        width: "100%",
        paddingRight: "15px",
        paddingLeft: "15px",
        ["@media (max-width:1550px)"]: {
            flex: "0 0 25%",
            maxWidth: "25%"
        }
    },
    listItem: {
        borderRadius: "5px",
        border: "1px solid #cfcfcf",
        height: "145px",
        '&:hover': {
            border: "2px solid rgb(67, 160, 71)",
            "& $checkbox": {
                top: '2px'
            }
        },
        paddingLeft: "11px",
        paddingRight: "11px",
        marginBottom: "13px",
        ["@media (max-width:1950px)"]: {
            height: "125px",
        }
    },
    emptyItem: {
        border: '1px dashed #cfcfcf',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            border: '1px dashed #cfcfcf !important',
            "& $checkbox": {
                top: '2px'
            },
            // cursor: 'crosshair'
        },
    },
    iconPaper: {
        height: "125px",
        width: "125px",
        backgroundColor: "rgb(0, 0, 0, 0.04)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        padding: "10px",
        ["@media (max-width:1950px)"]: {
            height: "105px",
            width: "105px",
        }
    },
    itemName: {
        display: "-webkit-box",
        boxOrient: "vertical",
        height: "45px",
        lineClamp: 2,
        wordBreak: "break-all",
        overflow: "hidden",
        fontSize: "15px",
        fontWeight: "bold",
        lineHeight: "22px",
        color: "#555555",
        ["@media (max-width:1950px)"]: {
            height: "38px",
            fontSize: "14px",
            marginBottom: '.5rem',
            lineHeight: "18px",
        }
    },
    line: {
        marginTop: "0 !important",
        marginBottom: "11px !important",
    },
    checkbox: {
        position: "absolute",
        margin: "5px",
        top: '3px',
        left: '4px',
        backgroundColor: "white",
        padding: "2px",
        borderRadius: "2px",
    },
    image: {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        marginBottom: "4px"
    },
    formControl: {
        minWidth: 140,
    },
    searchBoxPaper: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        borderradius: '10px',
        backgroundColor: "rgba(200, 230, 201, 1)",
        overflow: 'hidden',
        width: "317px",
        height: "32px",
        marginBottom: "2px"
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchIconButton: {
        padding: "7px 15px",
        backgroundColor: "rgb(67, 160, 71)",
        borderRadius: 0,
        margin: 0,
        color: 'white',
    },
    trashIcon: {
        display: "inline-block",
        height: "15px",
        color: 'red',
    }
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '6px 26px 6px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const CustomColorCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#43a047"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

class FolderView extends Component {
    static contextType = SettingContext;
    constructor(props) {
        super(props);
        this.inputFieldRef = createRef();
        this.state = {
            type: null,
            value: "",
            smsContent: "",
            smsId: null,
            smsEditLoader: true,
            tempId: null,
            openShareDialog: false,
            openShareDeleteDialog: false,
            openSmsEditDialog: false,
            accountToShare: "",
            selectedItems: [],
            selectedFolders: [],
            isCampaignDeleteDialogOpen: false,
            singleDeleteItemId: "",
            singleDeleteFolderId: "",
            searchValue: "",
            sortBy: "",
            currentDraggedItem: "",
            enteredItemId: ""
        }
    }
    componentDidMount() {
        this.props.getItemsInFolder(this.getFolderId(), this.props.listFor)
        this.props.getSharedData()
        this.props.getAllAccounts()
        // this.props.getWesp(this.props.listFor)
        //setLocalData()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.folderView.folderViewApiResponse !== this.props.folderView.folderViewApiResponse && this.props.folderView.folderViewApiResponse !== null) {
            //let res = this.props.apiResponse
            NotificationManager[this.props.folderView.folderViewApiResponse.alertType](this.props.folderView.folderViewApiResponse.message)
            this.props.resetResponse()
        }
        if(this.props.folderView.newItemCreated && (prevProps.folderView.newItem?.id !== this.props.folderView.newItem?.id)){
            if(this.props.listFor === "sms"){
                this.handleDoubleClickOnItem(this.props.folderView.newItem.id, this.props.folderView.newItem.name, this.props.folderView.newItem.content);
            }
        }
    }
    componentWillUnmount() {
        this.props.resetState()
    }

    //add
    addNew = (name, type) => {
        if (type === this.props.listFor) {
            this.props.addItem(name, this.props.listFor, this.getFolderId());
        }
        if (type === "folder") {
            this.props.addFolder(name, this.props.listFor, this.getFolderId());
        }
    };

    //share
    openShareDialog = () => {
        //ser
        //let isShared = this.props.folderView.sharedData.find(a =>a.intern_id === this.state.selectedItems[0]) === undefined ? false:true
        let isShared = false
        let item = this.props.folderView.items.find(a => a.id === this.state.selectedItems[0])
        if (item.data !== null && item.data !== "") {
            let data = JSON.parse(item.data)
            isShared = data.sharePortal === "true" ? true : false
        }
        if (isShared) {
            this.setState({
                openShareDeleteDialog: true
            })
        } else {
            this.props.getWesp(this.props.listFor)
            this.setState({
                openShareDialog: true
            })
        }

    }
    handleUpdateShare = () => {
        let externalId = this.state.accountToShare
        this.props.updateSharePortal(this.state.selectedItems[0], externalId, this.props.listFor, this.getFolderId())
        this.setSelectedItemsEmpty()
        this.handleClose()
    }

    handleDeleteShare = () => {
        let externalId = ""
        this.props.updateSharePortal(this.state.selectedItems[0], externalId, this.props.listFor, this.getFolderId())
        this.handleClose()
    }

    //copy
    handleOnClickCopy = () => {
        let selectedItems = this.state.selectedItems
        if (this.props.listFor === "campaign") {
            this.props.copyCampaign(selectedItems[0], this.getFolderId());
        } else {
            this.props.handleCopy(selectedItems[0], this.getFolderId(), this.props.listFor);
        }
        //document.getElementById(`${selectedCampaign[0]}`).style.backgroundColor = null;
        this.setSelectedItemsEmpty();
    };


    //rename
    handleRename = (name) => {
        if (this.state.type === this.props.listFor) {
            this.props.handleRename(this.state.tempId, name, this.props.listFor)
        }

        if (this.state.type === "folder") {
            this.props.renameFolder(this.state.tempId, name)
        }
    }

    //delete
    handleDelete = () => {
        if (this.state.singleDeleteItemId) {
            this.props.deleteSelected([this.state.singleDeleteItemId], [], this.props.listFor);
        } else if (this.state.singleDeleteFolderId) {
            this.props.deleteSelected([], [this.state.singleDeleteFolderId], this.props.listFor);
        } else {
            this.props.deleteSelected(this.state.selectedItems, this.state.selectedFolders, this.props.listFor);
            this.setSelectedItemsEmpty();
        }
        this.handleClose();
    };

    handleSaveSms = () => {
        this.props.handlePutSms(this.state.smsId, this.state.smsContent)
        this.handleClose()
    }
    //common

    handleDoubleClickOnItem = (id, name, content) => {
        if (this.props.listFor === "sms") {
            this.setState({
                openSmsEditDialog: true,
                smsId: id,
                smsContent: content
            })
            setTimeout(function () {
                this.setState({
                    smsEditLoader: false
                })
            }.bind(this), 2000);
        } else {
            this.props.history.push(`/app/custom-views/${this.props.listFor}/edit/${id}`, { name: name })
        }
    }
    handleOnClickFolder = (id, name) => (e) => {
        let selectedFolders = this.state.selectedFolders
        if (selectedFolders.includes(id)) {
            // document.getElementById(`${id}`).style.backgroundColor = null;
            let newSelectedFolders = selectedFolders.filter(a => a !== id)
            this.setState({
                selectedFolders: newSelectedFolders
            })
        } else {
            this.setState({ value: name, type: "folder", tempId: id });
            selectedFolders.push(id);
            // selectedFolders.forEach(() => {
            //     document.getElementById(`${id}`).style.backgroundColor = "#c8e6c9";
            // });
            this.setState({
                selectedFolders: selectedFolders
            })
        }
    };

    handleClickOnItem = (id, name) => (e) => {
        let selectedItems = this.state.selectedItems
        if (selectedItems.includes(id)) {
            // document.getElementById(`${id}`).style.backgroundColor = null;
            let newSelectedItems = selectedItems.filter((old) => old !== id);
            this.setState({
                selectedItems: newSelectedItems
            })
        } else {
            this.setState({ value: name, type: this.props.listFor, tempId: id });

            selectedItems.push(id);
            // selectedItems.forEach(() => {
            //     document.getElementById(`${id}`).style.backgroundColor = "#c8e6c9";
            // });
            this.setState({
                selectedItems: selectedItems
            })
        }
    };

    //drag and drop
    dragStart = (e) => {
        if (e.target.className.includes(this.props.listFor)) {
            this.setState({ currentDraggedItem: this.props.listFor })
            e.dataTransfer.setData(
                "data",
                JSON.stringify({
                    id: e.target.id,
                    type: this.props.listFor,
                })
            );
        } else {
            this.setState({ currentDraggedItem: "folder" })
            e.dataTransfer.setData(
                "data",
                JSON.stringify({
                    id: e.target.id,
                    type: "folder",
                })
            );
        }
    };

    dragOver = (e) => {
        e.preventDefault();
    };

    dragEnter = (e) => {
        let parentId = e.target.parentNode.id;
        this.setState({ enteredItemId: parentId });
    }

    drop = (e) => {
        e.preventDefault();
        this.setState({ currentDraggedItem: "", enteredItemId: "" })
        const data = JSON.parse(e.dataTransfer.getData("data"));
        let parentId = e.target.parentNode.id;
        if (data.id === parentId || !parentId) {
            return
        }

        if (this.state.sortBy === "manually") {
            if (data.type === this.props.listFor) {
                this.props.manuallySortItems(this.props.folderView.items, this.props.listFor, data.id, parentId, this.getFolderId());
            } else if (data.type === "folder") {
                this.props.manuallySortItems(this.props.folderView.folders, this.props.listFor, data.id, parentId, this.getFolderId(), "folder");
            }
        } else {
            if (data.type === this.props.listFor) {
                this.props.updateItemParent(data.id, parentId, this.props.listFor);
            } else if (data.type === "folder") {
                this.props.updateFolderParent(data.id, parentId);
            }
        }
    };

    dragEnd = (e) => {
        this.setState({ currentDraggedItem: "", enteredItemId: "" });
        e.target.style.opacity = "1";
    }

    setSelectedItemsEmpty = () => {
        let selectedItems = this.state.selectedItems
        let selectedFolders = this.state.selectedFolders

        if (selectedItems.length > 0) {
            for (let f = 0; f < selectedItems.length; f++) {
                let elementId = document.getElementById(`${selectedItems[f]}`);
                if (elementId !== null) {
                    document.getElementById(
                        `${selectedItems[f]}`
                    ).style.backgroundColor = null;
                }
            }
        }

        if (selectedFolders.length > 0) {
            for (let l = 0; l < selectedFolders.length; l++) {
                let elementId = document.getElementById(`${selectedFolders[l]}`);
                if (elementId !== null) {
                    document.getElementById(
                        `${selectedFolders[l]}`
                    ).style.backgroundColor = null;
                }
            }
        }

        this.setState({
            selectedItems: [],
            selectedFolders: []
        })
    };
    getFolderId = () => {
        let url = window.location.href;
        let urlArr = url.split("/");
        let id = urlArr[urlArr.length - 1];
        let isNumber = isNaN(parseInt(id))
        if (!isNumber) {
            id = id.toString()
        } else {
            id = "root"
        }
        return id
    }
    handleChangeSelect = (event) => {
        let id = event.currentTarget.id.split("-")[0]
        this.setState({
            [id]: event.target.value
        })

    }
    handleChange = (event) => {
        let id = event.target.id.split("-")[0]
        this.setState({
            [id]: event.target.value
        })
    }
    handleClose = () => {
        this.setState({
            openShareDialog: false,
            openSmsEditDialog: false,
            openShareDeleteDialog: false,
            smsContent: "",
            smsId: null,
            smsEditLoader: true,
            isCampaignDeleteDialogOpen: false,
            singleDeleteItemId: "",
            singleDeleteFolderId: ""
        })
    }
    // common
    getItemIconClass = (itemType) => {
        let iconClass = "zmdi-email";
        switch (itemType) {
            case "email":
                return iconClass = "zmdi-email";
            case "letter":
                return iconClass = "zmdi-assignment"
            case "form":
                return iconClass = "zmdi-format-subject"
            case "page":
                return iconClass = "zmdi-collection-text"
            case "sms":
                return iconClass = "zmdi-comment-alt-text"
            case "group":
                return iconClass = "zmdi-accounts"
            case "campaign":
                return iconClass = "zmdi-arrow-split"
            default:
                return iconClass = "zmdi-email";
        }
    }

    getItemIconProp = (itemType) => {
        let iconProp = "AlternateEmailIcon";
        switch (itemType) {
            case "email":
                return iconProp = "AlternateEmailIcon";
            case "letter":
                return iconProp = "MailIcon"
            case "form":
                return iconProp = "DescriptionIcon"
            case "page":
                return iconProp = "PagesIcon"
            case "sms":
                return iconProp = "PermPhoneMsgIcon"
            case "group":
                return iconProp = "GroupIcon"
            case "campaign":
                return iconProp = "CallSplitIcon"
            default:
                return iconProp = "AlternateEmailIcon";
        }
    }

    onSearchClick = (e) => {
        this.setState({ searchValue: this.inputFieldRef.value }, () => {
            this.props.getItemsInFolder(this.getFolderId(), this.props.listFor, this.inputFieldRef.value, this.state.sortBy)
        });
    }

    handleSortOnChange = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.props.getItemsInFolder(this.getFolderId(), this.props.listFor, this.state.searchValue, e.target.value)
        });
    }

    render() {
        const { match, history, listFor, folderView, classes } = this.props
        let settings = [];
        if (this.context.status === "success") {
            settings = JSON.parse(this.context.settings.data);
        }
        let itemIconClass = this.getItemIconClass(listFor)
        let iconProp = this.getItemIconProp(listFor)


        //for redirect if new item created
        if (this.props.folderView.newItemCreated) {
            if (listFor === "sms") {
                // this.props.getItemsInFolder(this.getFolderId(), listFor)
                // this.props.resetResponse();
                // this.handleDoubleClickOnItem(this.props.folderView.newItem.id, this.props.folderView.newItem.name, this.props.folderView.newItem.content);

            } else if (this.getFolderId() === "root") {
                let rUrl =
                    this.props.match.url + "/edit/" + this.props.folderView.newItem.id;
                this.props.history.push(rUrl, { name: JSON.stringify([this.props.folderView.newItem.name]), prevId: "root" });
            } else {
                const splitedUrl = match.url.split("/");
                let editUrl = "/" + splitedUrl[1] + "/" + splitedUrl[2] + "/" + splitedUrl[3];
                let rUrl = editUrl + "/edit/" + this.props.folderView.newItem.id;
                this.props.history.push(rUrl, { name: JSON.stringify([this.props.folderView.newItem.name]), prevId: this.getFolderId() });
            }

        }
        //for redirect if new item created

        return (
            <Paper variant="outlined" style={{ borderRadius: "5px" }}>
                <Grid container direction="row" style={{ justifyContent: "space-between", alignItems: "center", padding: "23px 27px 28px 27px" }}>
                    <Grid item>
                        <h1 className="font-weight-bold">{listFor.toUpperCase()}</h1>
                        {history.location.state?.name && <h3 style={{ marginBottom: 0 }}>{JSON.parse(history.location.state.name).join(" > ")}</h3>}
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
                            <Grid item>
                                {/*tool bar*/}
                                <CustomIndexToolBar
                                    itemType={listFor}
                                    tools={this.props.tools}
                                    selectedItems={this.state.selectedItems}
                                    selectedFolders={this.state.selectedFolders}
                                    value={this.state.value}
                                    selectedType={this.state.type}
                                    selectedId={this.state.templateId}
                                    settings={settings}
                                    handleOpenRename={this.handleOnClickRename}
                                    handleAdd={this.addNew}
                                    handleCopy={this.handleOnClickCopy}
                                    handleShare={this.openShareDialog}
                                    handleCloseRename={this.handleCloseRenameDialog}
                                    handleRename={this.handleRename}
                                    handleDelete={this.handleDelete}
                                    allAccounts={folderView.allAccounts}
                                />
                                {/*tool bar*/}
                            </Grid>
                            <Grid item>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    {/* <InputLabel id="demo-simple-select-outlined-label">Select Sorting</InputLabel> */}
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.sortBy}
                                        onChange={this.handleSortOnChange}
                                        label="Age"
                                        input={<BootstrapInput />}
                                        displayEmpty

                                    >
                                        <MenuItem value="">
                                            <em>Select Sorting</em>
                                        </MenuItem>
                                        <MenuItem value={"by_name"}>By Name</MenuItem>
                                        <MenuItem value={"by_date"}>By Date</MenuItem>
                                        <MenuItem value={"manually"}>Manually</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Paper component="form" className={classes.searchBoxPaper}>
                                    <InputBase
                                        className={classes.searchInput}
                                        placeholder="Search Text"
                                        inputProps={{ 'aria-label': 'Search Text' }}
                                        // onChange={this.searchBoxOnChange}
                                        defaultValue={this.state.searchValue}
                                        inputRef={el => this.inputFieldRef = el}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                e.preventDefault();
                                                this.onSearchClick(e)
                                            }
                                        }}
                                    />
                                    <IconButton className={classes.searchIconButton} aria-label="search" onClick={(e) => this.onSearchClick(e)}>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <hr style={{ margin: '0' }} />

                {/*tool bar*/}
                {/* <CustomIndexToolBar
                    itemType={listFor}
                    tools={this.props.tools}
                    selectedItems={this.state.selectedItems}
                    selectedFolders={this.state.selectedFolders}
                    value={this.state.value}
                    selectedType={this.state.type}
                    selectedId={this.state.templateId}
                    settings = {settings}
                    handleOpenRename={this.handleOnClickRename}
                    handleAdd={this.addNew}
                    handleCopy={this.handleOnClickCopy}
                    handleShare={this.openShareDialog}
                    handleCloseRename={this.handleCloseRenameDialog}
                    handleRename={this.handleRename}
                    handleDelete={this.handleDelete}
                    allAccounts={folderView.allAccounts} 
                /> */}
                {/*tool bar*/}
                {folderView.loader ?
                    <div>
                        <CircularLoader />
                    </div>
                    :
                    <div>
                        <EclicksProTour
                            menu={listFor}
                            view={"list"}
                        />
                        <div className="row folder-view-wrapper" style={{ padding: "19px 27px 27px 27px" }}>
                            {folderView.folders.length === 0 && folderView.items.length === 0 && this.getFolderId() === "root" &&
                                <div
                                    className="d-flex justify-content-center align-items-center w-100"
                                >
                                    <h3>
                                        <IntlMessages id="_ECLICKSPRO_NO_ITEM_FOUND" />
                                    </h3>
                                </div>
                            }

                            {this.getFolderId() !== "root" &&
                                <div
                                    id={history.location.state.prevId}
                                    className={classes.customCol_lg_20}
                                    draggable="true"
                                    onDragStart={this.dragStart}
                                    onDragOver={this.dragOver}
                                    onDrop={this.drop}
                                >
                                    <List
                                        id={history.location.state.prevId}
                                        onClick={() => history.goBack()}
                                    >
                                        <ListItem button id={history.location.state.prevId}>
                                            <ListItemAvatar id={history.location.state.prevId}>
                                                <Avatar
                                                    id={history.location.state.prevId}
                                                    style={{ backgroundColor: "#43a047" }}
                                                >
                                                    <i
                                                        id={history.location.state.prevId}
                                                        className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
                                                    />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                id={history.location.state.prevId}
                                                primary=". . ."
                                            // secondary={moment
                                            //   .unix(item.created_at)
                                            //   .format("MMM Do, YYYY")}
                                            />
                                        </ListItem>
                                    </List>
                                </div>

                            }
                            {folderView.folders.map((item, i) => {
                                const labelId = `checkbox-list-label-${item.id}`;
                                const disableStyle = {
                                    opacity: ".3",
                                    pointerEvents: "none"
                                }

                                return (
                                    <div
                                        id={item.id}
                                        className={classes.customCol_lg_20}
                                        key={i}
                                        draggable="true"
                                        onDragStart={this.dragStart}
                                        onDragOver={this.dragOver}
                                        onDrop={this.drop}
                                        style={this.state.currentDraggedItem === this.props.listFor && this.state.sortBy === "manually" ? disableStyle : {}}
                                        onDragEnter={this.dragEnter}
                                        // onDragLeave={this.dragLeave}
                                        onDragEnd={this.dragEnd}
                                    >
                                        <List
                                            id={item.id}
                                            onDoubleClick={() =>
                                                this.props.history.push(`/app/custom-views/${listFor}/${item.id}`, {
                                                    name: this.props.history.location.state?.name ? JSON.stringify([...JSON.parse(this.props.history.location.state.name), item.name]) : JSON.stringify([item.name]),
                                                    prevId: this.getFolderId(),
                                                })
                                            }
                                            onClick={this.handleOnClickFolder(item.id, item.name)}
                                        >
                                            {
                                                this.state.enteredItemId === item.id ? <ListItem button className={classes.listItem + ' ' + classes.emptyItem} id={"drag"} >
                                                    <span style={{ pointerEvents: 'none' }}>
                                                        <IntlMessages id="_ECLICKSPRO_DRAG_AND_DROP_HERE" />
                                                    </span>
                                                </ListItem> : <ListItem button id={item.id} className={classes.listItem} style={{ pointerEvents: this.state.currentDraggedItem ? "none" : 'auto' }}>
                                                    <div className={classes.checkbox}>
                                                        <CustomColorCheckbox
                                                            style={{ width: "20px", height: '20px', padding: "0 !important", }}
                                                            // edge="start"
                                                            checked={this.state.selectedFolders.includes(item.id)}
                                                            size={"small"}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}

                                                        />
                                                    </div>
                                                    <Grid container style={{ display: "flex" }} >
                                                        <Grid item xs={5} >
                                                            <Paper className={classes.iconPaper} variant={"outlined"}>
                                                                <Avatar style={{ backgroundColor: "#43a047" }} id={item.id}>
                                                                    <i
                                                                        id={item.id}
                                                                        className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
                                                                    />
                                                                </Avatar>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Grid container direction="column" style={{ height: "100%", paddingLeft: "8px" }} justify="space-between" >
                                                                <Grid item >
                                                                    <Grid
                                                                        container

                                                                        direction="row"
                                                                        style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}
                                                                    >

                                                                        <Typography variant="body2">
                                                                            {moment.unix(item.created_at).format("MMM Do, YYYY")}
                                                                        </Typography>
                                                                        <CustomDeleteDialog open={this.state.isCampaignDeleteDialogOpen} handleDelete={this.handleDelete} handleClose={this.handleClose} />
                                                                        <DeleteForeverOutlinedIcon className={classes.trashIcon} fontSize="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ isCampaignDeleteDialogOpen: true, singleDeleteFolderId: item.id });
                                                                            }} />
                                                                    </Grid>
                                                                    <Tooltip title={item.name} placement="top">
                                                                        <Box component="p" className={classes.itemName}>
                                                                            {item.name}
                                                                        </Box>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item >
                                                                    <hr className={classes.line} />
                                                                    <Box compnent="div" align="right" style={{ overflow: 'hidden' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="jr-btn text-white jr-btn-lg"
                                                                            // onClick={this.handleSaveButton}
                                                                            style={{ maxHeight: "27px", Width: "103px" }}
                                                                            onClick={() =>
                                                                                this.props.history.push(`/app/custom-views/${listFor}/${item.id}`, {
                                                                                    name: this.props.history.location.state?.name ? JSON.stringify([...JSON.parse(this.props.history.location.state.name), item.name]) : JSON.stringify([item.name]),
                                                                                    prevId: this.getFolderId(),
                                                                                })
                                                                            }

                                                                        >
                                                                            <IntlMessages id="_OPENS" />
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {/* <ListItemAvatar id={item.id}>
                                                        <Avatar style={{ backgroundColor: "#43a047" }} id={item.id}>
                                                            <i
                                                                id={item.id}
                                                                className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
                                                            />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        id={item.id}
                                                        primary={item.name}
                                                        secondary={moment.unix(item.created_at).format("MMM Do, YYYY")}
                                                    /> */}
                                                </ListItem>
                                            }
                                        </List>
                                    </div>
                                );
                            })}

                            {/* {this.state.sortBy === "manually" && 
									<div
									id={"drag"}
									className={classes.customCol_lg_20}
									// key={i}
									draggable="false"
									// onDragStart={this.dragStart}
									onDragOver={this.dragOver}
									onDrop={this.drop}
									// onDragEnter={this.dragEnter}
									// onDragLeave={this.dragLeave}
								>
									<List
										id={"drag"}
										
									>
										<ListItem button  className={classes.listItem + ' '+classes.emptyItem} id={"drag"}>
											<span style={{pointerEvents: 'none'}}>
                                                <IntlMessages id="_ECLICKSPRO_DRAG_AND_DROP_HERE" />
                                            </span>
										</ListItem>
									</List>
								</div>
								} */}

                            {folderView.items.map((item, index) => {
                                const labelId = `checkbox-list-label-${item.id}`;
                                let sharePortal = false
                                if (item.data !== null && item.data !== "" && item.data !== undefined) {
                                    let data = JSON.parse(item.data)
                                    if (item.id === "188") {
                                    }
                                    try {
                                        sharePortal = data.sharePortal === "true" ? true : false
                                    } catch (e) {
                                        sharePortal = false
                                    }

                                }
                                const disableStyle = {
                                    opacity: ".3",
                                    pointerEvents: "none"
                                }
                                return (
                                    <div
                                        id={item.id}
                                        // className={`col-3 ${listFor} folder-item-${index}`}
                                        className={classes.customCol_lg_20 + ` ${listFor} folder-item-${index}`}
                                        key={index}
                                        draggable="true"
                                        onDragStart={this.dragStart}
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            if (this.state.currentDraggedItem === this.props.listFor && this.state.sortBy !== "manually") {
                                                e.target.style.pointerEvents = 'none'
                                            }
                                        }}
                                        onDrop={(e) => {
                                            if (this.state.sortBy === 'manually') {
                                                this.drop(e)
                                            } else {
                                                e.target.style.pointerEvents = 'none'
                                            }
                                        }}
                                        onDragEnd={this.dragEnd}
                                        onDragEnter={this.dragEnter}
                                        style={this.state.currentDraggedItem === "folder" && this.state.sortBy === "manually" ? disableStyle : {}}
                                    >
                                        <List
                                            id={item.id}
                                            onDoubleClick={() =>
                                                this.handleDoubleClickOnItem(item.id, item.name, item.content)
                                            }
                                            onClick={this.handleClickOnItem(item.id, item.name)}
                                        >
                                            {
                                                this.state.enteredItemId === item.id ? <ListItem button className={classes.listItem + ' ' + classes.emptyItem} id={"drag"} >
                                                    <span style={{ pointerEvents: 'none' }}>
                                                        <IntlMessages id="_ECLICKSPRO_DRAG_AND_DROP_HERE" />
                                                    </span>
                                                </ListItem> : <ListItem button id={item.id} className={classes.listItem} style={{ pointerEvents: this.state.currentDraggedItem ? "none" : 'auto' }}>
                                                    <div className={classes.checkbox}>
                                                        <CustomColorCheckbox
                                                            style={{ width: "20px", height: '20px', padding: "0 !important", }}
                                                            // edge="start"
                                                            checked={this.state.selectedItems.includes(item.id)}
                                                            size={"small"}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </div>

                                                    <Grid container style={{ display: "flex" }}>
                                                        <Grid item xs={5} >
                                                            <Paper className={classes.iconPaper} variant={"outlined"}>
                                                                {/* <Avatar style={{ backgroundColor: "#43a047" }} id={item.id}>
                                                                       <i
                                                                           id={item.id}
                                                                           className="zmdi zmdi-arrow-split zmdi-hc-fw zmdi-hc-lg"
                                                                       />
                                                                   </Avatar> */}
                                                                <img src={item?.image ? item.image : "https://i.imgur.com/E93VkVW.png"} alt="" className={classes.image} draggable="false" />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Grid container direction="column" style={{ height: "100%", paddingLeft: "8px" }} justify="space-between">
                                                                <Grid item>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}
                                                                    >

                                                                        <Typography variant="body2">
                                                                            {moment.unix(item.created_at).format("MMM Do, YYYY")}
                                                                        </Typography>
                                                                        <CustomDeleteDialog open={this.state.isCampaignDeleteDialogOpen} handleDelete={this.handleDelete} handleClose={this.handleClose} />
                                                                        <DeleteForeverOutlinedIcon className={classes.trashIcon} fontSize="small"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ isCampaignDeleteDialogOpen: true, singleDeleteItemId: item.id });
                                                                            }} />
                                                                    </Grid>
                                                                    <Tooltip title={item.name} placement="top">
                                                                        <Box component="p" className={classes.itemName}>
                                                                            {item.name}
                                                                        </Box>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid>
                                                                    <hr className={classes.line} />
                                                                    <Box compnent="div" align="right" style={{ overflow: 'hidden' }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            className="jr-btn text-white jr-btn-lg"
                                                                            // onClick={this.handleSaveButton}
                                                                            style={{ maxHeight: "27px", Width: "103px" }}
                                                                            onClick={() =>
                                                                                this.handleDoubleClickOnItem(item.id, item.name, item.content)
                                                                            }
                                                                        >
                                                                            <IntlMessages id="_OPENS" />
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                    {/* <ListItemAvatar id={item.id} style={{ position: "relative" }}> */}
                                                    {/*{folderView.sharedData.find(a =>a.intern_id === item.id) !== undefined && (*/}
                                                    {/*    <i*/}
                                                    {/*      className="zmdi zmdi-share zmdi-hc-fw"*/}
                                                    {/*      style={{*/}
                                                    {/*        color: "#388e3c",*/}
                                                    {/*        position: "absolute",*/}
                                                    {/*        marginTop: "-10px",*/}
                                                    {/*        marginLeft: "30px",*/}
                                                    {/*      }}*/}
                                                    {/*    />*/}
                                                    {/*)}*/}
                                                    {/* {sharePortal === true && (
                                                       <i
                                                           className="zmdi zmdi-share zmdi-hc-fw"
                                                           style={{
                                                               color: "#388e3c",
                                                               position: "absolute",
                                                               marginTop: "-10px",
                                                               marginLeft: "30px",
                                                           }}
                                                       />
                                                   )}
                                                   <Avatar style={{ backgroundColor: "#43a047" }} id={item.id}>
                                                       <CustomIcon name={iconProp}/>
                                                   </Avatar>
                                               </ListItemAvatar>
                                               <ListItemText
                                                   id={item.id}
                                                   primary={item.name}
                                                   secondary={moment.unix(item.created_at).format("MMM Do, YYYY")}
                                               /> */}
                                                </ListItem>
                                            }
                                        </List>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                <CustomLoaderDidalog
                    open={folderView.openFullScreenLoader}
                />
                {/*share dialog*/}
                <CustomDialog
                    open={this.state.openShareDialog}
                    handleClose={this.handleClose}
                    handleAdd={this.handleUpdateShare}
                    dialogContent={false}
                    titleTextKey={"_SHARE"}
                    maxWidth={"xs"}
                    actionButtons={['add', 'close']}
                >
                    <DialogContent>
                        <FormControl fullWidth={true}>
                            <InputLabel id="accountToShare-label">
                                <IntlMessages id="_PLEASE_SELECT" />
                            </InputLabel>
                            <Select
                                labelId="accountToShare-label"
                                id="accountToShare"
                                value={this.state.accountToShare}
                                //onChange={handleChange}
                                onChange={this.handleChangeSelect}

                            >
                                <MenuItem value="">
                                    Please Select
                                </MenuItem>
                                {this.props.folderView.wesp.map((w, index) => (
                                    <MenuItem id={"accountToShare-" + w.value} key={w.value} value={w.value}>
                                        {w.label}
                                    </MenuItem>
                                ))}
                                {/*{this.props.folderView.wesp.map((w,index) => {*/}
                                {/*    return(*/}
                                {/*        <MenuItem key={w.value} value={w.value}>*/}
                                {/*            {w.label}*/}
                                {/*        </MenuItem>*/}
                                {/*    )}*/}
                                {/*)}*/}


                            </Select>
                        </FormControl>
                    </DialogContent>
                </CustomDialog>

                {/*share delete*/}
                <CustomDialog
                    open={this.state.openShareDeleteDialog}
                    handleClose={this.handleClose}
                    handleNo={this.handleClose}
                    handleYes={this.handleDeleteShare}
                    dialogContent={false}
                    titleTextKey={"_ALERT"}
                    maxWidth={"sm"}
                    actionButtons={['yes', 'no']}
                >
                    <DialogContent style={dialogStyles.dialogContentStyles}>
                        <DialogContentText>
                            <IntlMessages id={"_ECLICKSPRO_SHARE_PORTAL_REMOVE_CONFIRM_MESSAGE"} />  ?
                        </DialogContentText>
                    </DialogContent>
                </CustomDialog>

                {/*sms edit dialog*/}
                <CustomDialog
                    open={this.state.openSmsEditDialog}
                    handleClose={this.handleClose}
                    handleSave={this.handleSaveSms}
                    dialogContent={false}
                    titleTextKey={"_EDIT_SMS"}
                    maxWidth={"sm"}
                    actionButtons={['save', 'close']}
                >
                    <DialogContent style={dialogStyles.dialogContentStyles}>
                        {this.state.smsEditLoader ?
                            <div>
                                <Skeleton variant="text" width={100} height={20} />
                                <Skeleton variant="text" width={550} height={20} />
                                <Skeleton variant="text" width={550} height={50} />
                                <Skeleton variant="text" width={550} height={20} />
                                <Skeleton variant="text" width={180} height={20} />
                                <Skeleton variant="text" width={80} height={20} />
                            </div>
                            :
                            <TextField
                                id="smsContent"
                                label={<IntlMessages id={"_CONTENTS_OF_THE_MESSAGE"} />}
                                onChange={this.handleChange}
                                multiline
                                fullWidth={true}
                                rows={6}
                                value={this.state.smsContent}
                                variant="outlined"
                            />
                        }


                    </DialogContent>
                </CustomDialog>




                {/*delete dialog*/}
                {/*<CustomDeleteDialog*/}
                {/*    open={this.state.openShareDeleteDialog}*/}
                {/*    handleClose={this.handleClose}*/}
                {/*    handleDelete={this.handleDeleteShare}*/}
                {/*/>*/}
                <NotificationContainer />
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    folderView: state.folderView
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators, ...folderViewActions }, dispatch)
)(withStyles(useStyles)(FolderView));