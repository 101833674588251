import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CustomHeader from "../../../customComponents/header/CustomHeader";
import CardBox from "../../../../../components/CardBox";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../../actions/CampaignAction";
//import { withStyles } from "@material-ui/core/styles";

const useStyles = theme => ({

})

class PackageSubscription extends Component {
    render() {
        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                    <Grid container>
                        <Grid item md={12}>
                            <CustomHeader
                                titleKey="_ECLICKSPRO_PACKAGE_SUBSCRIPTION"
                                buttons={['editAccountData','buyExtraMailCredits','createUser','paymentHistory']}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <CardBox styleName="col-lg-12">
                                <h1>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                    It has roots in a piece of classical Latin literature from 45 BC, making it ov
                                    er 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
                                    College in Virginia, looked up one of the more obscure Latin words, consectetur,
                                    from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                                    discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                                    Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book
                                    is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem
                                    Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                                    Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in
                                    their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                                </h1>
                            </CardBox>
                        </Grid>
                        <Grid item md={6}>
                            <CardBox styleName="col-lg-12">
                                <h1>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                    It has roots in a piece of classical Latin literature from 45 BC, making it ov
                                    er 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
                                    College in Virginia, looked up one of the more obscure Latin words, consectetur,
                                    from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                                    discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                                    Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book
                                    is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem
                                    Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                                    Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in
                                    their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                                </h1>
                            </CardBox>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <CardBox styleName="col-lg-12">
                                <h1>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                    It has roots in a piece of classical Latin literature from 45 BC, making it ov
                                    er 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
                                    College in Virginia, looked up one of the more obscure Latin words, consectetur,
                                    from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                                    discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                                    Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book
                                    is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem
                                    Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                                    Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in
                                    their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                                </h1>
                            </CardBox>
                        </Grid>

                    </Grid>

                 </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    campaign: state.campaign
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyles)(PackageSubscription));

//export default PackageSubscription;