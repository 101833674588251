import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddressPopper from "../../home/components/AddressPopper";


const CustomToolTip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const DetailToolTip = (props) =>{
    return(
        <CustomToolTip open={false} PopperComponent={AddressPopper} PopperProps={{title:"test"}}>
            {props.children}
        </CustomToolTip>
    )
}
export default DetailToolTip