import { FETCH_HOME, FETCH_HOME_SUCCESS } from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: true,
  dialogLoader:false,
  token: null,
  data: null,
  authorizedItems:null,
  homePermissions:null,
  paymentList:[],
  credits:[],
  selectedCredit: null,
  totalCredit: "",
  schedulerUrl: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_HOME: {
      return {
        ...state,
        loader: true,
        token: action.payload
      };
    }
    case FETCH_HOME_SUCCESS: {
      return {
        ...state,
        loader: false,
        data: action.payload
      };
    }
    case Constants.REQUEST_GET_HOME_DATA:{
      return {
        ...state,
        loader: true,
        data: null
      }
    }
    case Constants.SUCCESS_GET_HOME_DATA:{
      return {
        ...state,
        loader: false,
        data:action.data
      }
    }
    case Constants.REQUEST_AUTHORIZED_ITEMS_HOME:{
      return {
        ...state,
        authorizedItems: null,
      }
    }
    case Constants.SUCCESS_AUTHORIZED_ITEMS_HOME:{
      return {
        ...state,
        authorizedItems: action.items
      }
    }
    case Constants.REQUEST_GET_ALL_HOME_PERMISSIONS:{
      return {
        ...state,
        homePermissions: null
      }
    }
    case Constants.SUCCESS_GET_ALL_HOME_PERMISSIONS:{
      return {
        ...state,
        homePermissions: action.items
      }
    }
    case Constants.FAILED_GET_ALL_HOME_PERMISSIONS:{
      return {
        ...state,
        homePermissions: null
      }
    }
    case Constants.REQUEST_GET_PAYMENT_LIST:{
      return {
        ...state,
        paymentList: []
      }
    }
    case Constants.SUCCESS_GET_PAYMENT_LIST:{
      return {
        ...state,
        paymentList: action.paymentList
      }
    }
    case Constants.FAILED_GET_PAYMENT_LIST:{
      return {
        ...state,
        paymentList: []
      }
    }
    case Constants.REQUEST_GET_CREDITS_HOME:{
      return {
        ...state,
        credits: [],
        totalCredit: ""
      }
    }
    case Constants.SUCCESS_GET_CREDITS_HOME:{
      return {
        ...state,
        credits: action.credits,
        totalCredit: action.totalCredit
      }
    }
    case Constants.FAILED_GET_CREDITS_HOME:{
      return {
        ...state,
        credits: [],
        totalCredit: ""
      }
    }
    case Constants.REQUEST_GET_SELECTED_CREDIT_HOME:{
      return {
        ...state,
        dialogLoader: true,
        selectedCredit: null
      }
    }
    case Constants.SUCCESS_GET_SELECTED_CREDIT_HOME:{
      return {
        ...state,
        dialogLoader: false,
        selectedCredit: action.payload
      }
    }
    case Constants.FAILED_GET_SELECTED_CREDIT_HOME:{
      return {
        ...state,
        dialogLoader: false,
        selectedCredit: null
      }
    }
    case Constants.REQUEST_GET_SCHEDULER_INFO:{
      return {
        ...state,
        dialogLoader: true,
        schedulerUrl: ""
      }
    }
    case Constants.SUCCESS_GET_SCHEDULER_INFO:{
      return {
        ...state,
        schedulerUrl: action.schedulerUrl,
        dialogLoader: false
      }
    }
    case Constants.FAILED_GET_SCHEDULER_INFO:{
      return {
        ...state,
        schedulerUrl: action.schedulerUrl,
        dialogLoader: false
      }
    }
    default:
      return state;
  }
};
