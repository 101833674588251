import { FETCH_HOME, FETCH_HOME_SUCCESS } from "constants/ActionTypes";
import * as Constants from "../common/Constants";
import config from "../config";
import axios from "axios";
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";
import { postAsync } from "../common/helpers/HttpService";

// let token = "";
// token = localStorage.getItem("user_token");

export const fetchHomeData = token => {
    return {
        type: FETCH_HOME,
        payload: token
    };
};

export const fetchHomeDataSuccess = data => {
    return {
        type: FETCH_HOME_SUCCESS,
        payload: data
    };
};

export const fetchClientScheduler = () => async (dispatch, getState) =>{
        let {token} = getAuthData()
        dispatch({ type: Constants.REQUEST_GET_SCHEDULER_INFO,token:token});
        let url = config.api.url+ "api/Profilesetting_api/getScheduler";
        
        
        axios({
            method: "GET",
            url: url,
            headers: {
            Authorization: token,
            },
        })
        .then((res) => {
            let apiResponse = getResponseForNotificaion(res)
            if (res.data.status === "success") {
            dispatch({ type: Constants.SUCCESS_GET_SCHEDULER_INFO,schedulerUrl:res.data.url });
            } else {
            dispatch({ type: Constants.FAILED_GET_SCHEDULER_INFO,apiResponse:apiResponse });
            }
        })
        .catch((e) => {
            let errorResponse = getResponseForNotificaion(null,{})
            dispatch({ type: Constants.FAILED_GET_SCHEDULER_INFO,apiResponse:errorResponse });
    });
}

export const actionCreators = {
    getHomeData: () => async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_GET_HOME_DATA})
        dispatch({type:Constants.REQUEST_GET_PAYMENT_LIST})
        //let tokenn = localStorage.getItem("user_token");
        let {token} = getAuthData()
        let url = config.api.url+"api/home"
        axios({
            method: "get",
            url: url,
            headers: {
              Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    let data = res.data
                    dispatch({type:Constants.SUCCESS_GET_HOME_DATA,data:data})

                    let productPaymentList = []
                    if (data !== null) {
                        let productPaymentListObj = {}
                        if (data.new) {
                            productPaymentListObj = data.new.productPaymentList
                        }
                        let keys = Object.keys(productPaymentListObj)
                        let b = keys
                        let objId = 1
                        for (let i = 0; i < keys.length; i++) {
                            let key = productPaymentListObj[keys[i]]
                            let obj = {
                                id: objId,
                                name: keys[i],
                                itemRows: productPaymentListObj[keys[i]].itemRows,
                                totalPrice: productPaymentListObj[keys[i]].totalPrice
                            }
                            productPaymentList.push(obj)
                            objId = objId + 1
                        }

                        dispatch({type:Constants.SUCCESS_GET_PAYMENT_LIST,paymentList:productPaymentList})
                    }
                }else {
                    let apiResponse = getResponseForNotificaion(res)
                    dispatch({type:Constants.FAILED_GET_HOME_DATA,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_HOME_DATA,apiResponse:errorResponse})
            });
    },
    getHomePermissions:() => async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_GET_ALL_HOME_PERMISSIONS})
        let url = config.api.url + "api/Permissions_row/getAllHomePermission"
        let {token} = getAuthData()
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                let resObj = {
                    buy_credits:{
                        buy_now_button: "0",
                        credit_amount: "0",
                        credit_price: "0",
                        package_upgrade_button: "0",
                        send_credit: "0",
                    }
                }
                // if (res.data.status === "success") {
                //     stateItems.push(item)
                // }
               dispatch({ type: Constants.SUCCESS_GET_ALL_HOME_PERMISSIONS,items:res.data.buy_credits })
            })
            .catch((e) => {
                dispatch({ type: Constants.FAILED_GET_ALL_HOME_PERMISSIONS})
            });
    },
    getMenuItems:(items) => async (dispatch,getState) =>{
        if(items === undefined){
            items = []
        }
        //dispatch({type:Constants.REQUEST_AUTHORIZED_ITEMS_HOME})


        let url = config.api.url + "api/menu"
        let {token} = getAuthData()
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                let menuItems = []
                try {
                    menuItems = res.data.data[0].child
                }catch (e) {
                    menuItems = []
                }
                // if (res.data.status === "success") {
                //     stateItems.push(item)
                // }
               dispatch({ type: Constants.SUCCESS_AUTHORIZED_ITEMS_HOME,items:menuItems })
            })
            .catch((e) => {
                // dispatch({ type: Constants.FAILED_CHECK_AUTHORIZATION })
            });
    },
    updateOrderStatus:(paymentId,itemId)=> async (dispatch,getState) =>{
        let url = config.api.url + "api/updatePayment"
        let paymentList = getState().home.paymentList
        let {token} = getAuthData()
        let status = false
        let payment = paymentList.find(a =>a.id === parseInt(paymentId))
        let paymentItemRow = payment.itemRows.find(i =>i.id === itemId)
        let indexOfItem = payment.itemRows.indexOf(paymentItemRow)
        let indexOfPayment = paymentList.indexOf(payment)

        if(paymentItemRow.delivered === null ||paymentItemRow.delivered === "0"){
            status = true
            paymentList[indexOfPayment].itemRows[indexOfItem].delivered = "1"
        }else{
            status = false
            paymentList[indexOfPayment].itemRows[indexOfItem].delivered = "0"
        }
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data:{
                id:itemId,
                status:status
            }
        })
            .then((res) => {
                if(res.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_PAYMENT_LIST,paymentList:paymentList})
                }
                // dispatch({ type: Constants.SUCCESS_AUTHORIZED_ITEMS_HOME,items:menuItems })
            })
            .catch((e) => {
                debugger
                // dispatch({ type: Constants.FAILED_CHECK_AUTHORIZATION })
            });
    },
    getCredits:() => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getCredits`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_CREDITS_HOME})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    let {list = [],apply_vat = '0',total_credit = ""} = res.data
                    dispatch({type:Constants.SUCCESS_GET_CREDITS_HOME,credits:list,totalCredit: total_credit})
                }else{
                    dispatch({type:Constants.FAILED_GET_CREDITS_HOME})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_CREDITS_HOME})
            });
    },
    getSelectedCredit:(id) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getSelectCredit`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_SELECTED_CREDIT_HOME})

        try{
            const response = await postAsync(url,{"id":id})
            if(response && response.data.status === 'success'){
                dispatch({type:Constants.SUCCESS_GET_SELECTED_CREDIT_HOME, payload:response.data.data})
            }else {
                dispatch({type:Constants.FAILED_GET_SELECTED_CREDIT_HOME})
            }
        }catch (e) {
            let errorResponse = getResponseForNotificaion(null,{})
            dispatch({type:Constants.FAILED_GET_SELECTED_CREDIT_HOME})
        }
    },
    
}
