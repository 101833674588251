import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  SWITCH_LANGUAGE_FAILED,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  START_USER_TOUR
} from 'constants/ActionTypes';

import config from "../config";
import * as Constants from "../common/Constants"
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { tourSettings } from "../common/functions/TourGuideSettings";
import axios from "axios";
import { getAuthData } from "../common/helpers/AuthHelper";


export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function startUserTour() {
  return { type: START_USER_TOUR }
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setDarkTheme() {
  return { type: DARK_THEME };
}

export function changeDirection() {
  return { type: CHANGE_DIRECTION };
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}

export const switchLanguage = (locale, isSaveToLocal = true) => async (dispatch, getState) => {
  let { token } = getAuthData()
  let url = config.api.url + "api/language_update";

  axios({
    method: "post",
    url: url,
    headers: {
      Authorization: token,
    },
    data: {
      language: locale?.languageId
    }
  })
    .then((res) => {
      // let apiResponse = getResponseForNotificaion(res)
      if (res.data.status === "success") {
        if (isSaveToLocal) {
          localStorage.setItem('languageDefault', JSON.stringify(locale));
        }
        dispatch({
          type: SWITCH_LANGUAGE,
          payload: locale
        })
      } else {
        dispatch({
          type: SWITCH_LANGUAGE_FAILED,
        })

      }
    })
    .catch((e) => {
      // let errorResponse = getResponseForNotificaion(null,{})
      dispatch({
        type: SWITCH_LANGUAGE_FAILED,
      })
    });


}

export const actionCreators = {
  showTour: () => async (dispatch, getState) => {
    dispatch({ type: Constants.SHOW_USER_TOUR })
  },
  hideTour: () => async (dispatch, getState) => {
    dispatch({ type: Constants.HIDE_USER_TOUR })
  },
  startUserTour: (menu, view) => async (dispatch, getState) => {
    let guide = tourSettings.filter(a => a.menu === menu && a.view === view)
    if (guide.length > 0) {
      dispatch({ type: Constants.START_USER_TOUR })
    }
  },
  stopUserTour: () => async (dispatch, getState) => {
    dispatch({ type: Constants.STOP_USER_TOUR })
  },
  showVideo: () => async (dispatch, getState) => {
    dispatch({ type: Constants.SHOW_TOUR_VIDEO })
  },
  closeTourVideo: () => async (dispatch, getState) => {
    dispatch({ type: Constants.CLOSE_TOUR_VIDEO })
  },
  resetTourGuide: () => async (dispatch, getState) => {
    dispatch({ type: Constants.RESET_TOUR_GUIDE })
  },
  getMenuTour: (menu, view) => async (dispatch, getState) => {
    let url = config.api.url + "api/tour_guide/gettourguidedata";
    let { token } = getAuthData()

    let authInfo = null
    if (token !== null) {
      authInfo = JSON.parse(atob(token.split(".")[1]));
    }
    let guide = tourSettings.filter(a => a.menu === menu && a.view === view)
    if (guide.length > 0) {
      let video = null
      if (guide[0].video !== null) {
        video = guide[0].video
      }
      dispatch({ type: Constants.SET_TOUR_STEPS, steps: guide[0].steps, video: video })
    }

    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { user_id: authInfo.uid, menu_name: menu, view: view },
    })
      .then((res) => {
        let result = res.data.data.filter(a => a.is_deleted === "0")
        if (result.length > 0) {
          let showStatus = result[result.length - 1].status
          if (showStatus === "0") {
            dispatch({ type: Constants.SHOW_USER_TOUR })
          } else {
            dispatch({ type: Constants.HIDE_USER_TOUR })
          }
        }


        //dispatch({type:Constants.SHOW_USER_TOUR})
      })
      .catch((e) => {
        let aaaa = e;
      });
  },
  updateTour: (menu, view) => async (dispatch, getState) => {
    let url = config.api.url + "api/tour_guide/savetourguidedata";
    let { token } = getAuthData()

    let authInfo = null
    if (token !== null) {
      authInfo = JSON.parse(atob(token.split(".")[1]));
    }

    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { user_id: authInfo.uid, menu_name: menu, view: view },
    })
      .then((res) => {

      })
      .catch((e) => {
        let aaaa = e;
      });
  },


}
