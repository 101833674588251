import React, { Component } from "react";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";

class Report extends Component {
  render() {
    const { match } = this.props;
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="sidebar.appModule.report" />}
            match={match}
          />
        </div>
      </div>
    );
  }
}

export default Report;
