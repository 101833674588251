import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_CHILD,
  FETCH_CATEGORIES_ROOT
} from "constants/ActionTypes";

import {
  fetchCategoriesSuccess,
  fetchCategoriesChildSuccess,
  fetchCategoriesRootSuccess
} from "actions/Categories";

import config from "config";

// fetching data
const fetchCategories = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}api/categories/get_category`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cat_id: id
    })
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

const fetchCategoriesChild = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}api/categories/get_childs`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      cat_id: id
    })
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

const fetchCategoriesRoot = async params => {
  const { token } = params;

  const response = await fetch(
    `${config.api.url}api/categories/getRootCategories`,
    {
      method: "GET",
      headers: {
        Authorization: token
      }
    }
  )
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

// Generators
function* getFetchCategories({ payload }) {
  try {
    const getCategoriesData = yield call(fetchCategories, payload);
    yield put(fetchCategoriesSuccess(getCategoriesData));
  } catch (e) {
    console.error(e);
  }
}

function* getFetchCategoriesChild({ payload }) {
  try {
    const getCategoriesChildData = yield call(fetchCategoriesChild, payload);
    yield put(fetchCategoriesChildSuccess(getCategoriesChildData));
  } catch (e) {
    console.error(e);
  }
}

function* getFetchCategoriesRoot({ payload }) {
  try {
    const getCategoriesRootData = yield call(fetchCategoriesRoot, payload);
    yield put(fetchCategoriesRootSuccess(getCategoriesRootData));
  } catch (e) {
    console.error(e);
  }
}

// export generators
export function* getCategories() {
  yield takeEvery(FETCH_CATEGORIES, getFetchCategories);
}

export function* getCategoriesChild() {
  yield takeEvery(FETCH_CATEGORIES_CHILD, getFetchCategoriesChild);
}

export function* getCategoriesRoot() {
  yield takeEvery(FETCH_CATEGORIES_ROOT, getFetchCategoriesRoot);
}
// expost the saga
export default function* rootSaga() {
  yield all([
    fork(getCategories),
    fork(getCategoriesChild),
    fork(getCategoriesRoot)
  ]);
}
