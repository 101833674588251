import React from "react";
import IntlMessages from "util/IntlMessages";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { cos } from "@amcharts/amcharts4/.internal/core/utils/Math";

export default function EmailStatistics({ emailStat }) {
  // const dummyData = [
  //   {date:"07-Sep-2020", open:10, click:5,bounce:3,unsubscribe:3},
  //   {date:"08-Sep-2020", open:2, click:5,bounce:3,unsubscribe:3},
  //   {date:"09-Sep-2020", open:1, click:15,bounce:30,unsubscribe:300},
  //   {date:"09-Sep-2020", open:11, click:5,bounce:3,unsubscribe:3},
  //   {date:"10-Sep-2020", open:50, click:5,bounce:3,unsubscribe:3},
  //   {date:"11-Sep-2020", open:20, click:250,bounce:50,unsubscribe:3},
  //   {date:"12-Sep-2020", open:30, click:250,bounce:3,unsubscribe:3},
  //   {date:"13-Sep-2020", open:5, click:5,bounce:3,unsubscribe:3},
  //   {date:"14-Sep-2020", open:100, click:50,bounce:3,unsubscribe:30},
  //   {date:"15-Sep-2020", open:50, click:80,bounce:3,unsubscribe:3},
  //   {date:"16-Sep-2020", open:20, click:5,bounce:90,unsubscribe:3},
  //   {date:"17-Sep-2020", open:150, click:5,bounce:3,unsubscribe:3},
  //   {date:"18-Sep-2020", open:500, click:5,bounce:3,unsubscribe:50},
  //   {date:"19-Sep-2020", open:10, click:5,bounce:500,unsubscribe:3},
  //   {date:"20-Sep-2020", open:0, click:25,bounce:30,unsubscribe:3},
  //   {date:"21-Sep-2020", open:12, click:5,bounce:3,unsubscribe:3},
  //   {date:"22-Sep-2020", open:70, click:5,bounce:3,unsubscribe:3}
  // ]
  return (
    <div className="jr-card jr-card-widget jr-card-ticketlist card home-graph">
      <div className="pr-xl-5 pt-xl-2">
        <p
          className="jr-fs-lg text-uppercase"
          style={{ fontWeight: "bold", fontSize: "1rem" }}
        >
          <IntlMessages id="_ECLICKSPRO_EMAIL_SENT" />
        </p>
        <ResponsiveContainer width="100%" height={203}>
          <AreaChart
            data={emailStat}
            margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
          >
            <XAxis dataKey="date" hide={true} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="send"
              fillOpacity={1}
              stroke="orange"
              fill="#4cb051"
            />
            <Area
              type="monotone"
              dataKey="open"
              fillOpacity={1}
              stroke="#2d6930"
              fill="#4cb051"
            />
            <Area
              type="monotone"
              dataKey="click"
              fillOpacity={0.75}
              stroke="#92363f"
              fill="#F45B69"
            />
            <Area
              type="monotone"
              dataKey="bounce"
              fillOpacity={0.5}
              stroke="#00707f"
              fill="#00BBD4"
            />
            <Area
              type="monotone"
              dataKey="unsubscribe"
              fillOpacity={0.25}
              stroke="#0b3442"
              fill="#114B5F"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
