import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IntlMessages from "util/IntlMessages";
import DragNDrop from "./DND/DragNDrop";
import config from "config";
import { withStyles } from "@material-ui/core/styles";
import CustomDialog from "../../../customComponents/dialog/customDialog";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../../../customComponents/button/CustomButton";
import DialogActions from "@material-ui/core/DialogActions";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const useStyles = {
  paperWidthMd: {
    maxWidth: "700px",
  },
    customContent:{
      margin:"2px 0px 0px -14px"
    }
};

class DialogBox extends React.Component {
  state = {
    fields: this.props.selectedFields,
    sort: this.props.customOrderingField,
  };
  handleRequestClose = () => {
    this.props.handleRequestClose(false);
  };

  getFields = (value) => {
    this.setState({ fields: value });
  };

  getSort = (value) => {
    this.setState({ sort: value });
  };
  handleSave = async () =>  {
    const { fields, sort } = this.state;
    const {token, tableName, userId} = this.props

    // console.log("fields ->", fields);
    // console.log("sort ->", sort);
    // const response = await fetch(
    //     `${config.api.url}api/${tableName}/add_order_view`,
    //     {
    //       method: "POST",
    //       headers: {
    //         Authorization: token,
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         fields: fields,
    //         sort: sort,
    //       }),
    //     }
    // )
    //     .then((res) => res.json())
    //     .then((json) => json)
    //     .catch((e) => console.error(e));
    //
    // if (response.status === "success") {
    //   return window.location.reload();
    // }


    //this.function i created using redux
      this.props.postOrderView(fields,sort)
  }
  handleReset = async () => {
    const {token, tableName, userId} = this.props
      let authInfo = null
      if(token !== null){
          authInfo = JSON.parse(atob(token.split(".")[1]));
      }
    const response = await fetch(
        `${config.api.url}api/${tableName}/reset_order_view`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: parseInt(authInfo.uid),
          }),
        }
    )
        .then((res) => res.json())
        .then((json) => json)
        .catch((e) => console.log(e));

    if (response.status === "success") {
      return window.location.reload();
    }
  }

  render() {
    const {
      open,
      availableFields,
      selectedFields,
      customOrderingField,
      classes,
    } = this.props;
    const { fields, sort } = this.state;
    // console.log("fields ->", fields);
    // console.log("sort ->", sort);
    return (
      <React.Fragment>
          {/*<CustomDialog*/}
          {/*    open={open}*/}
          {/*    fullWidth={true}*/}
          {/*    maxWidth = "sm"*/}
          {/*    appBarInTop={true}*/}
          {/*    titleTextKey={"_SELECT_FIELDS_TO_ORDER_IN_LIST"}*/}
          {/*    dialogContent={false}*/}
          {/*    dialogAction={true}*/}
          {/*    actionButtons={['save','reset']}*/}
          {/*    onClose={this.handleRequestClose}*/}
          {/*    handleSave={this.handleSave}*/}
          {/*    handleReset={this.handleReset}*/}
          {/*    handleClose={this.handleRequestClose}*/}
          {/*>*/}
              <Dialog
                  open={open}
                  fullWidth={true}
                  maxWidth = "sm"
                  onClose={this.handleRequestClose}
              >
                  <div>
                      <AppBar position="static">
                          <Toolbar variant="dense">
                              <Grid container>
                                  <Grid item md={11}>
                                      <div style={{marginLeft:"-10px"}}>
                                          <Typography variant="h6" color="inherit">
                                              <IntlMessages id="_SELECT_FIELDS_TO_ORDER_IN_LIST"/>
                                          </Typography>
                                      </div>
                                  </Grid>
                                  <Grid container item md={1} justify="flex-end">
                                      {/*this.handleDelete*/}
                                      <div style={{marginRight:"-17px"}}>
                                          <IconButton
                                              size="small"
                                              className="actionDialogTitleButton"
                                              onClick={this.handleRequestClose}
                                          >
                                              <CloseIcon style={{ color: "white" }} />
                                          </IconButton>
                                      </div>

                                  </Grid>
                              </Grid>
                          </Toolbar>
                      </AppBar>
                      <div className={classes.customContent}>
                          <DragNDrop
                              availableFields={availableFields}
                              selectedFields={selectedFields}
                              fields={this.state.fields}
                              getFields={this.getFields}
                              getSort={this.getSort}
                              customOrderingField={customOrderingField}
                          />
                      </div>
                      <div style={{width:"100%",borderTop:"1px solid #E0E0E0",marginTop:"10px"}}></div>
                      <DialogActions className={classes.dialogAction}>
                          <div style={{marginRight:"8px"}}>
                              <CustomButton color="green" onClick={this.handleSave}>
                                  <IntlMessages id="_SAVE"></IntlMessages>
                              </CustomButton>
                              <CustomButton color="white" onClick={this.handleReset}>
                                  <IntlMessages id="_RESET"></IntlMessages>
                              </CustomButton>
                          </div>

                      </DialogActions>
                  </div>
              </Dialog>




          {/*</CustomDialog>*/}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(DialogBox);
