import * as Constants from "../common/Constants"

const INIT_STATE = {
	campaignLoader: false,
	campaignFolderViewLoader:false,
	campaign: null,
	campaigns: [],
	campaignFolders: [],
	newCampaignCreated: false,
	newCampaignId: "",
	campaignUpdateSuccess:false,
	cards: [],
	campaignItems: [],
	campaignActions: [],
	campaignSendActions: [],
	campaignItemFolders: [],
	relationFields: [],
    startPoint:null,
	accounts: [],
	shareSuccess: false,
	authInfo: null,
	executeNowSuccess:false,
    cgSettings:null,
    inUseItems:null,
	campaignUserProfile:null,
	campaignTemplateCategories:[],
	campaignTemplates:[],
	campaignApiResponse:null,
	allTagList: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case Constants.REQUEST_GET_CAMPAIGN: {
			return {
				...state,
				campaign: null,
                startPoint: null,
				cards: [],
				campaignActions: [],
				authInfo: action.auth,
			};
		}
		case Constants.SUCCESS_GET_CAMPAIGN: {
			return {
				...state,
				campaign: action.campaign,
				cards: action.cards,
				campaignActions: action.campaignActions,
                startPoint: action.startPoint
			};
		}
		case Constants.FAILED_GET_CAMPAIGN: {
			return {
				...state,
				campaign: action.campaign,
				cards: action.cards,
				campaignActions: action.campaignActions,
				startPoint: action.startPoint
			};
		}
		case "SUCCESS_GET_ALL_RELATIONAL_FIELD": {
			return {
				...state,
				relationFields: action.fields,
			};
		}
		case "ADD_CAMPAIGN_SUCCESS": {
			return {
				...state,
				loader: false,
				campaigns: [...action.campaign, ...state.campaigns],
				newCampaignCreated: true,
				newCampaignId: action.campaign[0].id,
			};
		}
		case "ADD_CAMPAIGN_FOLDER_SUCCESS": {
			return {
				...state,
				loader: false,
				campaignFolders: [...action.folders, ...state.campaignFolders],
			};
		}
		case "GET_ALL_CAMPAIGN": {
			return {
				...state,
				campaignLoader: true,
				campaigns: [],
				campaignFolders: [],
				authInfo: action.auth,
			};
		}
		case "GET_ALL_CAMPAIGN_SUCCESS": {
			return {
				...state,
				campaignLoader: false,
				campaigns: action.data,
			};
		}
		case "GET_ALL_CAMPAIGN_FOLDER_SUCCESS": {
			return {
				...state,
				campaignFolders: action.folders,
			};
		}
		case "CAMPAIGN_DELETE_ROOTCARD_SUCCESS": {
			return {
				...state,
				campaign: null,
				campaignActions: [],
				cards: []
			}
		}
		case "CAMPAIGN_DELETE_SUCCESS": {
			let campaignId = action.campId;
			let items = state.campaigns.filter((i) => i.id !== campaignId);
			// let index = state.forms.indexOf(item[0]);
			// let newFoms = state.forms;
			// if (index !== -1) {
			// 	newFoms = state.forms.splice(index, 1);
			// }

			return {
				...state,
				loader: false,
				campaigns: items,
			};
		}
		case "CAMPAIGN_FOLDER_DELETE_SUCCESS": {
			let folderId = action.folderId;
			let items = state.campaignFolders.filter((i) => i.id !== folderId);
			// let index = state.folders.indexOf(item[0]);
			// let newFolder = state.folders;
			// if (index !== -1) {
			// 	newFolder = state.folders.splice(index, 1);
			// }

			return {
				...state,
				loader: false,
				campaignFolders: items,
			};
		}
		case "SUCCESS_PUT_CAMPAIGN": {
			return {
				...state,
				campaign: action.campaignObj,
				campaignUpdateSuccess: true
			}
		}
		case "UPDATE_CAMPAIGN_PARENT_SUCCESS": {
			let camp = state.campaigns.filter((i) => i.id !== action.frmId);
			return {
				...state,
				campaigns: camp,
			};
		}
		case "UPDATE_FOLDER_PARENT_SUCCESS": {
			let fldrs = state.campaignFolders.filter((i) => i.id !== action.fldrId);
			return {
				...state,
				campaignFolders: fldrs,
			};
		}
		case "ADD_CARDS_SUCCESS": {
			//let newTriggers = state.triggers.splice(state.triggers.length,0,{"type":action.triggerType})
			return {
				...state,
				cards: action.cards,
			};
		}
		case "ADD_ACTION_SUCCESS": {
			//let newTriggers = state.triggers.splice(state.triggers.length,0,{"type":action.triggerType})
			return {
				...state,
				campaignActions: action.campaignActions,
			};
		}
		case "ADD_SEND_ACTION_SUCCESS": {
			//let newTriggers = state.triggers.splice(state.triggers.length,0,{"type":action.triggerType})
			return {
				...state,
				campaignSendActions: action.campaignSendActions,
			};
		}
		case "REQUEST_CAMPAIGN_IN_FOLDER": {
			return {
				...state,
				campaignLoader: true,
				//campaignFolderViewLoader: true,
				// campaignForms: [],
				// campaignFormFolders: [],
				campaignItems: [],
				campaignItemFolders: [],
			};
		}
		case "RESET_ITEMS_IN_FOLDER": {
			return {
				...state,
				campaignItems: [],
				campaignItemFolders: [],
			};
		}
		case "SUCCESS_GET_ALL_ITEMS": {
			return {
				...state,
				campaignLoader: false,
				//campaignFolderViewLoader: false,
				campaignItems: action.items,
			};
		}
		case "SUCCESS_GET_ALL_ITEM_FOLDER": {
			return {
				...state,
				campaignLoader: false,
				//campaignFolderViewLoader: false,
				campaignItemFolders: action.itemFolders,
			};
		}
		case "GET_ALL_EMPTY": {
			return {
				...state,
				newCampaignCreated: false,
				newCampaignId: "",
				shareSuccess: false,
				accounts: [],
				campaignUpdateSuccess: false
			};
		}
		case Constants.REQUEST_EXECUTE_CAMPAIGN_NOW:{
			debugger
			return {
				...state,
				campaignApiResponse: null
			}
		}
		case Constants.SUCCESS_EXECUTE_CAMPAIGN_NOW: {
			debugger
			return {
				...state,
				campaignApiResponse: action.apiResponse

			};
		}
		case Constants.SUCCESS_EXECUTE_CAMPAIGN_NOW: {
			debugger
			return {
				...state,
				campaignApiResponse: action.apiResponse
			};
		}
		case "REQUEST_GET_ACCOUNTS": {
			return {
				...state,
				accounts: [],
			};
		}
		case "SUCCESS_GET_ACCOUNTS": {
			return {
				...state,
				accounts: action.accounts,
			};
		}
		case Constants.REQUEST_GET_SHARE_CAMPAIGN:{
			return {
				...state,
				campaignLoader: true,
				campaignApiResponse: null

			}
		}
		case Constants.SUCCESS_GET_SHARE_CAMPAIGN:{
			return {
				...state,
				campaignLoader: false,
				campaignApiResponse: action.apiResponse
			}
		}
		case Constants.FAILED_GET_SHARE_CAMPAIGN:{
			return {
				...state,
				campaignLoader: false,
				campaignApiResponse: action.apiResponse
			}
		}
		case "SUCCESS_SHARE_CAMPAIGN": {
			return {
				...state,
				shareSuccess: true,
			};
		}
		case "RESET_SHARE_CAMPAIGN": {
			return {
				...state,
				shareSuccess: false,
			};
		}
        case "SUCCESS_SET_START_POINT":{
            return {
                ...state,
                startPoint: action.startPoint
            }
        }
        case "SUCCESS_GET_GLOBAL_SETTINGS":{
            return {
                ...state,
                cgSettings:action.data
            }
        }
        case "REQUEST_IN_USE_ITEMS":{
            return {
                ...state,
				campaignFolderViewLoader: true,
                inUseItems: null
            }
        }
        case "SUCCESS_IN_USE_ITEMS":{
            return {
                ...state,
				campaignFolderViewLoader: false,
                inUseItems: action.items
            }
        }
        case "FAILED_IN_USE_ITEMS":{
            return {
                ...state,
				campaignFolderViewLoader: false,
                inUseItems: null
            }
        }
		case Constants.SUCCESS_RENAME_CAMPAIGN:{
			let campaignId = action.campaignId;
			let item = state.campaigns.filter((i) => i.id === campaignId);
			let index = state.campaigns.indexOf(item[0]);
			state.campaigns.splice(index, 1, action.campaign[0]);
			return {
				...state,

			}
		}
		case Constants.SUCCESS_RENAME_FOLDER: {
			let folderId = action.folderId;
			let item = state.campaignFolders.filter((i) => i.id === folderId);
			let index = state.campaignFolders.indexOf(item[0]);
			state.campaignFolders.splice(index, 1, action.folder[0]);

			return {
				...state,
			};
		}
		case Constants.REQUEST_GET_USER_PROFILE_DATA_FOR_CAMPAIGN:{
			return {
				...state
			}
		}
		case Constants.SUCCESS_GET_USER_PROFILE_DATA_FOR_CAMPAIGN:{
			return {
				...state,
				campaignUserProfile: action.profile
			}
		}
		case Constants.REQUEST_CAMPAIGN_GET_TEMPLATE_CATEGORIES:{
			return {
				...state,
				campaignTemplateCategories: []

			}
		}
		case Constants.SUCCESS_CAMPAIGN_GET_TEMPLATE_CATEGORIES:{
			return {
				...state,
				campaignTemplateCategories: action.categories
			}
		}
		case Constants.FAILED_CAMPAIGN_GET_TEMPLATE_CATEGORIES:{
			return {
				...state,
				campaignTemplateCategories: []
			}
		}
		case Constants.REQUEST_GET_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignTemplates:[]
			}
		}
		case Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignTemplates:action.templates
			}
		}
		case Constants.FAILED_GET_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignTemplates:[]
			}
		}
		case Constants.RESET_CAMPAIGN_RESPONSE:{
			return {
				...state,
				campaignApiResponse: null
			}
		}
		case Constants.REQUEST_DELETE_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignApiResponse: null
			}
		}
		case Constants.SUCCESS_DELETE_CAMPAIGN_TEMPLATE:{
			state.campaignTemplates = state.campaignTemplates.filter(a =>a.id !== action.tempId)
			return {
				...state,
				campaignApiResponse: action.apiResponse,
			}
		}
		case Constants.FAILED_DELETE_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignApiResponse: action.apiResponse
			}
		}
		case Constants.REQUEST_MANUAL_SORT_CAMPAIGN:{
			return{
				...state,
				campaignLoader: true,
			}
		}
		case Constants.SUCCESS_MANUAL_SORT_CAMPAIGN:{
			return{
				...state,
				campaignLoader: false,
				campaigns: action.campaigns
			}
		}
		case Constants.SUCCESS_MANUAL_SORT_CAMPAIGN_FOLDERS:{
			return{
				...state,
				campaignLoader: false,
				campaignFolders: action.folders
			}
		}
		case Constants.FAILED_MANUAL_SORT_CAMPAIGN:{
			return{
				...state,
				campaignLoader: false,
				campaignApiResponse: action.campaignApiResponse
			}
		}
		case Constants.REQUEST_GET_ALL_TAGS:{
			return{
				...state
			}
		}
		case Constants.SUCCESS_GET_ALL_TAGS:{
			return{
				...state,
				allTagList: action.tagList
			}
		}
		default: {
			return state;
		}
	}
};
