import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    green: {
        backgroundColor: "#4CAF50",
        color: "white",
        maxHeight: "34.19px",
        minHeight: "34.19px",
        minWidth:"80px",
        marginTop: "3px",
        borderRadius: "5px",
        marginLeft: "10px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#388E3C"
        }
    },
    dodgerBlue: {
        backgroundColor: "#2196F3",
        color: "white",
        maxHeight: "34.19px",
        minHeight: "34.19px",
        minWidth:"80px",
        marginTop: "3px",
        borderRadius: "5px",
        marginLeft: "10px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#52ACF2"
        }
    },
    red: {
        backgroundColor: "#D9001B",
        color: "white",
        maxHeight: "34.19px",
        minHeight: "34.19px",
        minWidth:"80px",
        marginTop: "3px",
        borderRadius: "5px",
        marginLeft: "10px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#D82036"
        }
    },
    grey:{
        backgroundColor: "#7F7F7F",
        color: "white",
        maxHeight: "34.19px",
        minHeight: "34.19px",
        minWidth:"80px",
        marginTop: "3px",
        borderRadius: "5px",
        marginLeft: "10px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#BBBBBB"
        }
    },
    yellow:{
        backgroundColor: "#FAC10B",
            color: "white",
            maxHeight: "34.19px",
            minHeight: "34.19px",
            minWidth:"80px",
            marginTop: "3px",
            borderRadius: "5px",
            marginLeft: "10px",
            "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#ffdb69"
        }
    },
    white:{
        backgroundColor: "#FFFFFF",
        color: "#514E4E",
        maxHeight: "34.19px",
        minHeight: "34.19px",
        minWidth:"80px",
        marginTop: "3px",
        borderRadius: "5px",
        border:"1px solid #b9b8b8",
        marginLeft: "10px",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#BBBBBB"
        }
    },
}));

const CustomButton = props => {
    const classes = useStyles();
    return (
        <Button
            className={classes[props.color]}
            id={props.id}
            color={"default"}
            variant={props.variant}
            onClick={props.onClick}
            style={props.style}
            fullWidth={props.fullWidth === true ? true:false}
            {...props}
        >
            {props.children}
        </Button>
    );
};
export default CustomButton;
