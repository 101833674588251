import React, { Component } from "react";
import { compose } from "redux";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsIcon from "@material-ui/icons/Settings";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import Button from "@material-ui/core/Button";
import SearchBox from "components/SearchBox";
import DialogBox from "./components/DialogBox";
import EditDialogBox from "./components/EditDialogBox";
import DeleteDialogBox from "./components/DeleteDialogBox";
import IntlMessages from "util/IntlMessages";
import RefreshIcon from "@material-ui/icons/Refresh";
import SaveAsDialogBox from "./components/SaveAsDialogBox";
import SaveDialogBox from "./components/SaveDialogBox";
import config from "config";
import { injectIntl, intlShape } from "react-intl";
import { fetchHomeData } from "actions/Home";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BlockIcon from "@material-ui/icons/Block";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import RelationDialogBox from "../../editView/components/RelationDialogBox";
import CustomFieldDialog from "../../editView/components/CustomFieldDialog";
import { TENANT_SWITCH_PERMISSION } from "../../../../common/SettingsKey";
import { getAuthData } from "../../../../common/helpers/AuthHelper";

const RenderSuggestItems = ({ suggestValue, searchValueFromHeader }) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        minHeight: "25px",
        marginTop: "3px",
        backgroundColor: "white",
        padding: "10px",
        zIndex: 1,
      }}
    >
      {suggestValue.map((value, i) => (
        <Button
          key={i}
          style={{ padding: "5px", width: "100%", textAlign: "left" }}
          onClick={(e) => searchValueFromHeader(e.target.innerHTML, true)}
        >
          {value.value}
        </Button>
      ))}
    </div>
  );
};

class HeaderApp extends Component {
  state = {
    searchText: this.props.searchText,
    open: false,
    anchorEl: null,
    anchorElExport: null,
    templateId: this.props.selectedTemplate || "0",
    openEditBox: false,
    openDeleteBox: false,
    openSaveAsBox: false,
    openSaveBox: false,
    anchorElSearch: false,
    suggestValue: null,
  };

  componentWillMount() {
    const { token } = this.props;
    this.props.fetchHomeData(token);
  }

  async componentDidMount() {
    const { token, tableName } = this.props;

    const response = await fetch(
      `${config.api.url}api/${tableName}/get_trash_status`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((e) => console.error(e));

    if (response.showTrashBtn === 1) {
      this.setState({ trashStatus: true });
    }
  }

  handleChangeAccount = (event) =>{
    let id = event.target.value
    this.props.handleChangeAccount(id)
  }

  handleChange = (name) => async (event) => {
    const { token, tableName } = this.props;

    this.setState({ [name]: event.target.value });

    const response = await fetch(`${config.api.url}api/template_save`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        template_table_name: tableName,
        template_new_save_name: "",
        template_action_type: "show_template",
        template_id: event.target.value,
        template_edit_name: "",
        template_new_name: "",
        template_listing_fields: "",
        template_ordering_fields: "",
        template_for_all_user: "0",
      }),
    })
      .then((res) => res.json())
      .then((json) => json)
      .catch((e) => console.log(e));

    if (response.status === "success") window.location.reload();
    if (response.status === "error") console.log(response.message);
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
      openEditBox: false,
      openDeleteBox: false,
      openSaveAsBox: false,
      openSaveBox: false,
    });
  };

  async updateSearchText(evt) {
    const { token, tableName } = this.props;
    localStorage.setItem("searchTextForTrans",evt.target.value)
    this.setState({
      searchText: evt.target.value,
      anchorElSearch: true,
    });

    const response = await fetch(
      `${config.api.url}api/${tableName}/get_search_words_api/${evt.target.value}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => json);

    // console.log(response);

    if (response.status === "success") {
      this.setState({ suggestValue: response.data });
    }
  }

  searchNow(e) {
    const { searchFlag, searchText } = this.state;
    if (e.keyCode === 13) {
      this.setState({ searchFlag: true });
      this.props.searchValueFromHeader(searchText, searchFlag);
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSaveDialogBox = () => {
    this.setState({ anchorEl: null, openSaveBox: true });
  };

  handleSaveAsDialogBox = () => {
    this.setState({ anchorEl: null, openSaveAsBox: true });
  };

  handleEditDialogBox = () => {
    this.setState({ anchorEl: null, openEditBox: true });
  };

  handleDeleteDialogBox = () => {
    this.setState({ anchorEl: null, openDeleteBox: true });
  };

  onFocusHandle = (e) => {
    // console.log(e.target.value);
  };

  onBlurHandle = (e) => {
    setTimeout(() => this.setState({ anchorElSearch: false }), 100);
  };

  handleCloseCustomField = () => {
    this.setState({ openCustomField: false, openRelationDialog: false });
  };

  addCustomFields = async (name, type, description) => {
    this.setState({ openCustomField: false });
    const response = await fetch(`${config.api.url}v1/relation/field`, {
      method: "POST",
      headers: {
        Authorization: this.props.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        type: type,
        description: description,
      }),
    }).then((res) => res.json());

    if (response.status === "success") {
      NotificationManager.info(response.message);
    } else {
      NotificationManager.error(response.message);
    }
  };

  handleAddRelationToGroup = async (value) => {
    this.setState({ openRelationDialog: false, typeRelationDialog: null });
    const { selectedData, token } = this.props;
    // console.log(`selectedData ----> ${selectedData} || value ---->${value}`);
    for (let i = 0; i < value.length; i++) {
      let response;
      for (let j = 0; j < selectedData.length; j++) {
        response = await fetch(`${config.api.url}v1/group/add/${value[i]}`, {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            relation: selectedData[j],
          }),
        }).then((res) => res.json());
      }

      if (i === value.length - 1) {
        if (response.status === "success") {
          NotificationManager.info(response.message);
        } else {
          NotificationManager.error(response.message);
        }
      }
    }
  };

  handleUnsubscribeRelation = async () => {
    this.setState({ openRelationDialog: false });
    const { selectedData, token } = this.props;
    for (let i = 0; i < selectedData.length; i++) {
      const response = await fetch(
        `${config.api.url}v1/relation/unsubscribe/${selectedData[i]}`,
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            optin: 0,
          }),
        }
      ).then((res) => res.json());

      if (i === selectedData.length - 1) {
        if (response.status === "success") {
          NotificationManager.info(response.message);
        } else {
          NotificationManager.error(response.message);
        }
      }
    }
  };

  render() {
    const { open, anchorEl, anchorElExport, openSaveBox, openSaveAsBox, openEditBox, openDeleteBox, anchorElSearch, suggestValue, } = this.state;
    const { allTemplate, allAccounts, token, tableName, availableFields, selectedFields, selectedData, orderingFields, customOrderingField, item, data, intl, totalItemToExport, permissions, total, accountId, settings } = this.props;
    let isShowTenantSwitch = false
    //this if block for only showing name field when adding a new filter from add view
    if(tableName === "filter" && item !== null){
      let nameField = item && item.data.find(i =>i?.field_name === "name")
      if(item !== null){
        item.data = [nameField]
      }

    }

    let tenantSwitchSettings = settings[TENANT_SWITCH_PERMISSION]
    if(tenantSwitchSettings === undefined){
      isShowTenantSwitch = false
    }else {
      let user = getAuthData()
      if(user.user !== null){
        let isExistUser = tenantSwitchSettings.split(",").find(a =>a === user.user.role_id)
        if(isExistUser === undefined){
          isShowTenantSwitch = false
        }else {
          isShowTenantSwitch = true
        }
      }

    }


    const search_placeholder = intl.formatMessage({ id: "_SEARCH" });
    return (
      <div>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="mb-2"
        >
          <h3 style={{ display: "flex", alignItems: "center", margin: "0px" }}>
            <IntlMessages id={"_" + tableName.toUpperCase()} />
          </h3>
          <div>
            {isShowTenantSwitch &&
              <FormControl className="ml-2 template-area-for-tg" style={{ width: "150px" }}>
                <Select
                    value={accountId}
                    onChange={this.handleChangeAccount}
                    displayEmpty
                >
                  <MenuItem value="0">
                    <em>
                      <IntlMessages id="_PLEASE_SELECT" />
                    </em>
                  </MenuItem>
                  {allAccounts && allAccounts.length > 0 &&
                  allAccounts.map((account) => {
                    return (
                        <MenuItem value={account.id}>
                          {account.name}
                        </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            }
            {tableName === "relation" && (
              <Tooltip title="Create Custom Field" className="ml-2">
                <IconButton
                  aria-label="Create Custom Field"
                  style={{ padding: "7px" }}
                  onClick={() => this.setState({ openCustomField: true })}
                >
                  <DynamicFeedIcon style={{ color: "#4caf50" }} />
                </IconButton>
              </Tooltip>
            )}

            {tableName === "relation" && (
              <Tooltip title="Add Relations To Group" className="ml-2">
                <IconButton
                  aria-label="Add Relations To Group"
                  style={{ padding: "7px" }}
                  onClick={() => {
                    if (selectedData.length === 0) {
                      return NotificationManager.error(
                        "Must Select A Relation"
                      );
                    }
                    this.setState({
                      typeRelationDialog: "relation",
                      openRelationDialog: true,
                    });
                  }}
                >
                  <GroupAddIcon style={{ color: "#ffc107" }} />
                </IconButton>
              </Tooltip>
            )}

            {tableName === "relation" && (
              <Tooltip title="Unsubscribe" className="ml-2">
                <IconButton
                  aria-label="Unsubscribe"
                  style={{ padding: "7px" }}
                  onClick={() => {
                    if (selectedData.length === 0) {
                      return NotificationManager.error(
                        "Must Select A Relation"
                      );
                    }
                    this.setState({
                      typeRelationDialog: "unsubscribe",
                      openRelationDialog: true,
                    });
                  }}
                >
                  <BlockIcon style={{ color: "#f44336" }} />
                </IconButton>
              </Tooltip>
            )}

            <FormControl className="ml-2 template-area-for-tg" style={{ width: "150px" }}>
              <Select
                value={this.state.templateId}
                onChange={this.handleChange("templateId")}
                displayEmpty
              >
                <MenuItem value="0">
                  <em>
                    <IntlMessages id="_PLEASE_SELECT" />
                  </em>
                </MenuItem>
                {allTemplate && allTemplate.length > 0 &&
                  allTemplate.map((template) => {
                    return (
                      <MenuItem value={template.id}>
                        {template.template_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Tooltip
              className="ml-2"
              title="Options"
              style={{ position: "relative", display: "inline-block" }}
            >
              <IconButton aria-label="Options" style={{ padding: "7px" }}>
                <SettingsIcon
                  onClick={(e) => {
                    this.setState({
                      anchorEl: e.currentTarget,
                    });
                  }}
                />
                {anchorEl && (
                  <div>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={this.handleClose}
                      style={{
                        position: "absolute",
                        marginTop: "10px",
                        marginLeft: "35px",
                      }}
                    >
                      <MenuItem
                        disabled={this.props.selectedTemplate === "0"}
                        onClick={this.handleSaveDialogBox}
                      >
                        <IntlMessages id="_SAVE" />
                      </MenuItem>
                      <MenuItem onClick={this.handleSaveAsDialogBox}>
                        <IntlMessages id="_SAVE_AS" />
                      </MenuItem>
                      <MenuItem
                        disabled={this.props.selectedTemplate === "0"}
                        onClick={this.handleEditDialogBox}
                      >
                        <IntlMessages id="_EDIT" />
                      </MenuItem>
                      <MenuItem
                        disabled={this.props.selectedTemplate === "0"}
                        onClick={this.handleDeleteDialogBox}
                      >
                        <IntlMessages id="_DELETE" />
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </IconButton>
            </Tooltip>

            {this.state.trashStatus && (
              <Tooltip
                title="Click To View Your Deleted Items"
                className="ml-2"
              >
                <Link to={`/app/trash/${tableName}`}>
                  <IconButton
                    aria-label="View Deleted Items"
                    style={{ padding: "7px" }}
                  >
                    <RestoreFromTrashIcon style={{ color: "#2196f3" }} />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {permissions.add_permission === "1" && (
              <Link
                to={{
                  pathname: `/app/new/listing/${tableName}`,
                  state: { data: item },
                }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  className="jr-btn jr-btn-lg bg-green text-white ml-2"
                >
                  <span>
                    <IntlMessages id="_ADD" />
                  </span>
                  <i className="zmdi zmdi-plus zmdi-hc-lg" />
                </Button>
              </Link>
            )}

            {permissions.export_permission === "1" && (
              <span>
                <Button
                  variant="contained"
                  className="jr-btn jr-btn-lg bg-orange text-white ml-2"
                  onClick={(e) => {
                    this.setState({
                      anchorElExport: e.currentTarget,
                    });
                  }}
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <span>
                    <IntlMessages id="_EXPORT" />
                  </span>
                  <i className="zmdi zmdi-download zmdi-hc-lg" />
                </Button>
                {anchorElExport && (
                  <Menu
                    anchorEl={anchorElExport}
                    keepMounted
                    open={Boolean(anchorElExport)}
                    onClose={() => this.setState({ anchorElExport: null })}
                    style={{
                      position: "absolute",
                      marginTop: "40px",
                    }}
                  >
                    <MenuItem
                      onClick={() => this.setState({ anchorElExport: null })}
                    >
                      <a
                        style={{ color: "#ff9800" }}
                        href={`${
                          config.api.url
                        }api/${tableName}/export_api/excel/0/${selectedData.join(
                          "-"
                        )}?token=${token}`}
                      >
                        <i className="zmdi zmdi-download zmdi-hc-fw" /> XLS
                      </a>
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.setState({ anchorElExport: null })}
                    >
                      <a
                        style={{ color: "#ff9800" }}
                        href={`${
                          config.api.url
                        }api/${tableName}/export_api/pdf/0/${selectedData.join(
                          "-"
                        )}?token=${token}&perPage=${totalItemToExport}`}
                      >
                        <i className="zmdi zmdi-download zmdi-hc-fw" /> PDF
                      </a>
                    </MenuItem>
                  </Menu>
                )}
              </span>
            )}
          </div>
        </div>

        <hr />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              color: "#4caf50",
            }}
          >
            <IntlMessages id="_TOTAL" /> {total}{" "}
            {total > 1 ? (
              <IntlMessages id="_ITEMS" />
            ) : (
              <IntlMessages id="_ITEM" />
            )}
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              className="jr-btn bg-cyan text-white ml-2"
              onClick={() => this.setState({ open: true })}
            >
              <IntlMessages id="_SHOW_FIELDS_WITH_ORDER" />
            </Button>

            <span style={{ position: "relative", width: "350px" }}>
              <SearchBox
                // styleName="d-none d-lg-block"
                placeholder={search_placeholder}
                onChange={this.updateSearchText.bind(this)}
                value={this.state.searchText}
                onKeyDown={this.searchNow.bind(this)}
                onFocus={this.onFocusHandle.bind(this)}
                onBlur={this.onBlurHandle.bind(this)}
              />
              {anchorElSearch && suggestValue && (
                <RenderSuggestItems
                  suggestValue={suggestValue}
                  searchValueFromHeader={this.props.searchValueFromHeader}
                />
              )}
            </span>

            {/* {this.props.searchFlag && (
              <div className="mr-1">
                <Tooltip title="Refresh">
                  <IconButton
                    aria-label="Refresh"
                    style={{ marginTop: "11px" }}
                  >
                    <RefreshIcon
                      onClick={() => window.location.reload()}
                      style={{ color: "#4caf50" }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )} */}
          </div>
        </div>

        <SaveDialogBox
          token={token}
          tableName={tableName}
          templateId={this.state.templateId}
          selectedFields={selectedFields}
          orderingFields={orderingFields}
          openSaveBox={openSaveBox}
          handleRequestClose={this.handleRequestClose}
        />
        <SaveAsDialogBox
          token={token}
          tableName={tableName}
          selectedFields={selectedFields}
          orderingFields={orderingFields}
          openSaveAsBox={openSaveAsBox}
          handleRequestClose={this.handleRequestClose}
        />
        <EditDialogBox
          token={token}
          tableName={tableName}
          templateId={this.state.templateId}
          openEditBox={openEditBox}
          handleRequestClose={this.handleRequestClose}
        />
        <DeleteDialogBox
          token={token}
          tableName={tableName}
          templateId={this.state.templateId}
          openDeleteBox={openDeleteBox}
          handleRequestClose={this.handleRequestClose}
        />
        <DialogBox
          open={open}
          handleRequestClose={this.handleRequestClose}
          availableFields={availableFields}
          selectedFields={selectedFields}
          customOrderingField={customOrderingField}
          postOrderView={this.props.postOrderView}
          token={token}
          tableName={tableName}
          userId={data && data.id}
        />
        <CustomFieldDialog
          tableName={tableName}
          open={this.state.openCustomField}
          handleRequestClose={this.handleCloseCustomField}
          addCustomFields={this.addCustomFields}
        />
        {this.state.typeRelationDialog && (
          <RelationDialogBox
            open={this.state.openRelationDialog}
            type={this.state.typeRelationDialog}
            handleRequestClose={this.handleCloseCustomField}
            handleUnsubscribeRelation={this.handleUnsubscribeRelation}
            handleAddRelationToGroup={this.handleAddRelationToGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ home }) => {
  const { data } = home;
  return { data };
};

const Header = compose(connect(mapStateToProps, { fetchHomeData }), injectIntl);
export default Header(HeaderApp);
