import React,{useContext} from "react";

//material
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IntlMessages from "../../../../../util/IntlMessages";

//custom
import {eclicksProColors} from "../../../../../common/functions/EproCommonFunctions";


const useStyles = makeStyles({
    cardWrapper:{
        textAlign:"center",
        marginTop:"36px"
    },
    cardWrapperPopular:{
        textAlign:"center"
    },
    dividerWrapper:{
        marginTop:"10px 0 10px 0"
    },
    packagePrice:{
        padding:"10px 0px 50px 0px",
    "& span":{
        fontWeight:"bold",
        color:eclicksProColors.green
    }
    },
    header:{
        padding:"5px 20px 0px 20px",
        color:eclicksProColors.textGray,
        fontSize:"30px",
        fontWeight:"bold",
        textTransform:"capitalize",
        overflow: "auto"
    },
    headerPopular:{
        padding:"20px",
        color:"#ffffff",
        backgroundColor:eclicksProColors.green,
        fontSize:"30px",
        fontWeight:"bold",
        textTransform:"capitalize",
        overflow: "auto"
    },
    orderButtonArea:{
        padding:"20px 0 20px 0"
    },
    include:{
        padding:"10px 0 10px 0"
    },
    includeList:{
        padding:"10px 0px 50px 0px",
        height:"180px",
        backgroundColor:"#ECF3ED"
    },
    includeText:{
        margin:"10px",
        height:"180px",
        padding:"20px 15px",
        textAlign:"justify",
        overflow:"auto"
    },
    listItem:{
        // width:"100%"
    },
    unOrderList:{
        listStyleType:"none"
    }
})

const PriceVatChip = withStyles({
    root:{
        backgroundColor:"#ECF3ED",
        "& span":{
            color:eclicksProColors.textBlack
        }
    },
})(Chip)

const IncludeList = withStyles({
    root:{
        width: '100%',
        // height: 400,
        maxWidth: 300,
    }
})(ListItem)

const CustomPaper = withStyles({
    root:{
        border:`1px solid ${eclicksProColors.green}`
    }
})(Paper)


function PackageDetailCard(props) {
    const classes = useStyles();
    const {name, pack, duration, settings} = props
    let vat = pack[`${name}_${duration}_vat`]
    let vatPercent = "0"
    if(settings){
        vatPercent = settings["#ECLICKSPRO_SUBSCRIPTION_DEFAULT_VAT"] ? settings["#ECLICKSPRO_SUBSCRIPTION_DEFAULT_VAT"]: "0"
    }
    const openOrder = (event) =>{
        let pType = event.currentTarget.id.split('-')[0]
        let pId = event.currentTarget.id.split('-')[1]
        let pDuration = event.currentTarget.id.split('-')[2]
        props.openOrder(pType,pId,pDuration)
    }

    return(
        <div className={name === "plus"?classes.cardWrapperPopular:classes.cardWrapper}>
            <CustomPaper elevation={3}>
                <div className={name === "plus"?classes.headerPopular:classes.header}>
                    <p><IntlMessages id={`_ECLICKSPRO_${name.toUpperCase()}_PACK_NAME`} /></p>
                </div>
                <div className={classes.dividerWrapper}>
                    <Divider/>
                </div>
                <div className={classes.packagePrice}>
                    <h3><span>€ {pack[`${name}_${duration}`]}</span>/{duration === "monthly"? "MO":"Y"}</h3>
                    <p>Paid per {duration}(EURO)</p>
                    <PriceVatChip
                        // label={`Plus VAT (21%)-€12`}
                        label={`Plus VAT (${vatPercent}%)-€ ${vat}`}
                    ></PriceVatChip>
                </div>
                <div className={classes.dividerWrapper}>
                    <Divider/>
                </div>
                <div className={classes.orderButtonArea}>
                    {name === "plus"?
                        <Button variant="contained" id={`${name}-${pack.id}-${duration}`} onClick={openOrder} color={"primary"}>Order Now</Button>
                        :
                        <Button variant="outlined" id={`${name}-${pack.id}-${duration}`} onClick={openOrder}>Order Now</Button>
                    }

                </div>
                <div className={classes.dividerWrapper}>
                    <Divider/>
                </div>
                <div className={classes.include}>
                    <p>Include</p>
                </div>
                <div className={classes.dividerWrapper}>
                    <Divider/>
                </div>
                {/* <div className={classes.includeList}>
                    <ul className={classes.unOrderList}>
                        <li><span></span> a list item</li>
                        <li>a list item</li>
                        <li>a list item</li>
                        <li>a list item</li>
                        <li>a list item</li>
                    </ul>
                </div> */}
                <div className={classes.includeText}>
                    <p><IntlMessages id={`_ECLICKSPRO_PACKAGE_DESCRIPTION_FOR_${name.toUpperCase()}`} /></p>
                </div>
            </CustomPaper>
        </div>
    )
}
export default PackageDetailCard