import appLocaleData from "react-intl/locale-data/en";
import { enMessage } from "../locales/en-US";

const EnLang = async () => {
  const lang = await enMessage();

  return {
    messages: {
      ...lang
    },
    locale: "en-US",
    data: appLocaleData
  };
}
export default EnLang;
