import { FETCH_USERTEXT,FETCH_USERGROUPSEND } from "constants/ActionTypes";

const INIT_STATE = {
  userText:"",
  userGroupsend:""
};

export const textReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USERTEXT:
      return {
        ...state,
        userText:action.payload
      }
        case FETCH_USERGROUPSEND:
        return {
          ...state,
          userGroupsend:action.payload
        }
    default:
      return {
        ...state
      };
  }
};
