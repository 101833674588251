import { all } from "redux-saga/effects";
import mailSagas from "./Mail";
import toDoSagas from "./Todo";
import contactSagas from "./Contact";
import chatSagas from "./Chat";
import authSagas from "./Auth";
import menuSagas from "./Menu";
import profileSaga from "./Profile";
import tableSaga from "./Table";
import homeSaga from "./Home";
import editViewSaga from "./EditView";
import trashViewSaga from "./TrashView";
import emailSaga from "./Email";
import smsSaga from "./Sms";
import letterSaga from "./Letter";
import pageSaga from "./Page";
import groupSaga from "./Group";
import formSaga from "./Form";
import filterSaga from "./Filter";
import emailRelationSaga from "./EmailRelation";
import letterRelationSaga from "./LetterRelation";
import smsRelationSaga from "./SmsRelation";
import reportSaga from "./Report";
import categoriesSaga from "./Categories";
import scheduleSaga from "./Schedule";
import relationSaga from "./Relation";

export default function* rootSaga(getState) {
  yield all([
    mailSagas(),
    toDoSagas(),
    contactSagas(),
    chatSagas(),
    authSagas(),
    menuSagas(),
    profileSaga(),
    tableSaga(),
    homeSaga(),
    editViewSaga(),
    trashViewSaga(),
    emailSaga(),
    smsSaga(),
    letterSaga(),
    groupSaga(),
    filterSaga(),
    emailRelationSaga(),
    letterRelationSaga(),
    smsRelationSaga(),
    formSaga(),
    pageSaga(),
    reportSaga(),
    categoriesSaga(),
    scheduleSaga(),
    relationSaga(),
  ]);
}
