import React from "react";
import LooksOneOutlinedIcon from "@material-ui/icons/LooksOneOutlined";
import LooksTwoOutlinedIcon from "@material-ui/icons/LooksTwoOutlined";
import Looks3OutlinedIcon from "@material-ui/icons/Looks3Outlined";
import Looks4OutlinedIcon from "@material-ui/icons/Looks4Outlined";
import Looks5OutlinedIcon from "@material-ui/icons/Looks5Outlined";

import { NavLink, withRouter } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import "./index.css";

const LineChain = () => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        width: "35px",
        marginTop: "-27px",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#575757",
          width: "3px",
          height: "35px",
        }}
      ></div>
    </div>
  );
};

const QuickStart = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "5px",
        fontSize: "1rem",
      }}
      className="shadow border-0"
    >
      <div className="pr-5 pt-2">
        <p
          className="jr-fs-lg text-uppercase pt-2"
          style={{ fontWeight: "bold", fontSize: "1rem" }}
        >
          <IntlMessages id="_ECLICKSPRO_FOLLOW_STEPS" />
        </p>

        <div style={{ position: "relative" }}>
          <div className="home-steps mb-3">
            <LooksOneOutlinedIcon fontSize="large" />
            <span className="ml-3">
              <div className="home-steps-child" style={{ fontSize: "13px" }}>
                <strong>
                  <IntlMessages id="_ECLICKSPRO_FIRST_STEP" />
                </strong>
                <div className="mt-1">
                  <NavLink to="/app/custom-views/email">
                    <IntlMessages id="_ECLICKSPRO_CREATE_MAILING" />
                  </NavLink>
                </div>
              </div>
            </span>
          </div>

          <LineChain />

          <div className="home-steps mb-3">
            <LooksTwoOutlinedIcon fontSize="large" />
            <span className="ml-3">
              <div className="home-steps-child" style={{ fontSize: "13px" }}>
                <strong>
                  <IntlMessages id="_ECLICKSPRO_ORGANIZE" />
                </strong>
                <div className="mt-1">
                  <NavLink to="/app/custom-views/group">
                    <IntlMessages id="_ECLICKSPRO_CREATE_GROUP" />
                  </NavLink>
                </div>
              </div>
            </span>
          </div>

          <LineChain />

          <div className="home-steps mb-3">
            <Looks3OutlinedIcon fontSize="large" />
            <span className="ml-3">
              <div className="home-steps-child" style={{ fontSize: "13px" }}>
                <strong>
                  <IntlMessages id="_ECLICKSPRO_SETTING_UP" />
                </strong>
                <div className="mt-1">
                  <NavLink to="/app/custom-views/import">
                    <IntlMessages id="_ECLICKSPRO_IMPORT_EMAILS_ADDRESSES" />
                  </NavLink>
                </div>
              </div>
            </span>
          </div>

          <LineChain />

          <div className="home-steps mb-3">
            <Looks4OutlinedIcon fontSize="large" />
            <span className="ml-3">
              <div className="home-steps-child" style={{ fontSize: "13px" }}>
                <strong>
                  <IntlMessages id="_ECLICKSPRO_AWAY_WE_GO" />{" "}
                </strong>
                <div className="mt-1">
                  <NavLink to="/app/custom-views/send">
                    <IntlMessages id="_ECLICKSPRO_SEND_EMAILS" />
                  </NavLink>
                </div>
              </div>
            </span>
          </div>

          <LineChain />

          <div className="home-steps mb-3">
            <Looks5OutlinedIcon fontSize="large" />
            <span className="ml-3">
              <div className="home-steps-child" style={{ fontSize: "13px" }}>
                <strong>
                  <IntlMessages id="_ECLICKSPRO_RESULTS" />
                </strong>
                <div className="mt-1">
                  <NavLink to="/app/custom-views/report">
                    <IntlMessages id="_ECLICKSPRO_SEE_MAILING_RESULT" />
                  </NavLink>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(QuickStart);
