import {
  FETCH_SMS,
  FETCH_SMS_SUCCESS,
  FETCH_SMS_FOLDER,
  FETCH_SMS_FOLDER_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  smss: null,
  folder: null,
  smsParams: null,
  folderParams: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SMS: {
      return {
        ...state,
        loader: true,
        smsParams: action.payload
      };
    }
    case FETCH_SMS_SUCCESS: {
      return {
        ...state,
        loader: false,
        smss: action.payload
      };
    }
    case FETCH_SMS_FOLDER: {
      return {
        ...state,
        loader: true,
        folderParams: action.payload
      };
    }
    case FETCH_SMS_FOLDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        folder: action.payload
      };
    }
    default:
      return state;
  }
};
