import React, { Component } from "react";
import ContainerHeader from "../../../components/ContainerHeader";
import IntlMessages from "../../../util/IntlMessages";


//material
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators} from "../../../actions/Profile";

//custom
import AuthorizationWrapper from "../customComponents/AuthComponents/AuthorizationWrapper";
import CustomButton from "../customComponents/button/CustomButton";


//notification
import { NotificationContainer,NotificationManager } from "react-notifications";
import { SettingContext } from "../../../context/SettingContext";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { eclicksProColors } from "../../../common/functions/EproCommonFunctions";
import CircularLoader from "../customComponents/loader/CircularLoader";



let listFor = "personal domain"

let responseFor = "profile"
let responseName = "profileApiResponse"
const useStyles = theme => ({

    mainWrapperBox:{
        border:"1px solid #D7D7D7",
        borderRadius:"10px",
        padding:"20px"
    }
});

class PersonalDomain extends Component {
    static contextType = SettingContext;
    constructor(props) {
        super(props);
        this.state={
            selectedRadioItem:null,
            itemId:null,
            domainName:"",
            isExistDomain:false,
            is_verified:"null"
        }
    }
    componentDidMount() {
        //this.props.getListItemPersonalDomain(this.state.selectedRadioItem)
        this.props.getPersonalDomain()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //for domainname get
        if(prevProps.profile.personalDomain !== this.props.profile.personalDomain && this.props.profile.personalDomain !== null){
            let personalDomain = this.props.profile.personalDomain
            this.setState({
                domainName: personalDomain.name,
                itemId:personalDomain.type_id,
                selectedRadioItem:personalDomain.type ? personalDomain.type: null,
                is_verified:personalDomain.is_verified

            })
            let type = personalDomain.type ? personalDomain.type: null
            if(type){
                this.props.getListItemPersonalDomain(type)
            }
        }

        //for notification
        if(prevProps[responseFor][responseName] !== this.props[responseFor][responseName] && this.props[responseFor][responseName] !== null){
            //let res = this.props.apiResponse
            NotificationManager[this.props[responseFor][responseName].alertType](this.props[responseFor][responseName].message)
            this.props.resetResponse()
        }

        //for domain check status
        if(prevProps.profile.isExistDomain !== this.props.profile.isExistDomain){
            this.setState({
                isExistDomain:this.props.profile.isExistDomain
            })
        }

    }

    handleChangeRadio = (event) =>{
        let id = event.currentTarget.id
        if(id === "form"){
            this.setState({
                selectedRadioItem:"form",
                itemId:null,
            })
            this.props.getListItemPersonalDomain("form")
        }
        if(id === "page"){
            this.setState({
                selectedRadioItem:"page",
                itemId:null
            })
            this.props.getListItemPersonalDomain("page")
        }
    }
    handleChangeSelect = (event) =>{
        this.setState({
            itemId:event.target.value
        })
    }
    handleChange = (event) =>{
        this.setState({
            [event.target.id]:event.target.value
        })

        //for domain name check
        if(event.target.id === "domainName"){
            this.checkDomainName(event.target.value)
        }
    }
    handleSavePersonalDomain = () =>{
        let dName = this.state.domainName.toString().trim()
        this.props.savePersonalDomain(dName,this.state.selectedRadioItem,this.state.itemId,false)
        //this.props.getPersonalDomain()
    }
    handleSaveAndVerifyPersonalDomain = () =>{
        let dName = this.state.domainName.toString().trim()
        this.props.savePersonalDomain(dName,this.state.selectedRadioItem,this.state.itemId,true)
        //this.props.getPersonalDomain()
    }
    handleEdit = () =>{
        this.setState({
            is_verified:"no"
        })
    }
    handleRemove = () =>{
        let dName = "";
        this.setState({
            domainName:dName
        })
        this.props.savePersonalDomain(dName,this.state.selectedRadioItem,this.state.itemId,true)
    }
    checkDomainName = (name) =>{
        this.props.checkDuplicateDomain(name)
    }

    render() {
        const {match,profile} = this.props
        let settings = []
        if(this.context.settings.status === "success"){
            settings = JSON.parse(this.context.settings.data);
        }
        let dnsValue = settings["#ECLICKSPRO_DEFAULT_DNS"]
        return (
            <div className={"app-wrapper"}>
                {/*<AuthorizationWrapper menu={listFor}>*/}
                {profile.loader ?
                    <div>
                        <CircularLoader/>
                    </div>
                    :
                    <div>
                        <div>
                            <div className="animated slideInUpTiny animation-duration-3">
                                <ContainerHeader title={<IntlMessages id={`_ECLICKSPRO_PERSONAL_DOMAIN`} />} match={match} />
                            </div>

                        </div>
                        <Grid container spacing={3}>
                            <Grid item md={3}>
                                <TextField
                                    id="domainName"
                                    value={this.state.domainName}
                                    label={<IntlMessages id="_ECLICKSPRO_DOMAIN_NAME" />}
                                    onChange={this.handleChange}
                                    fullWidth={true}
                                    disabled={this.state.is_verified === "yes"}
                                    error={this.state.isExistDomain}
                                    helperText={this.state.isExistDomain? <IntlMessages id={"_ECLICKSPRO_DOMAIN_EXIST_MESSAGE"}/> : ""}
                                />
                            </Grid>
                            <Grid container item justify={"center"} md={2}>
                                <FormControlLabel control={<Radio color="primary" disabled={this.state.is_verified === "yes"} checked={this.state.selectedRadioItem === "form"} id={"form"} onChange={this.handleChangeRadio} />} label={<IntlMessages id="_FORM" />} />
                                <FormControlLabel  control={<Radio color="primary" disabled={this.state.is_verified === "yes"} checked={this.state.selectedRadioItem === "page"} id={"page"} onChange={this.handleChangeRadio} />} label={<IntlMessages id="_PAGE" />} />
                            </Grid>
                            {this.state.selectedRadioItem === null?
                                <div></div>
                                :
                                <Grid item md={2}>
                                    {this.state.selectedRadioItem === "form" &&
                                    <FormControl fullWidth={true} disabled={this.state.is_verified === "yes"}>
                                        <InputLabel id="demo-simple-select-label">
                                            <IntlMessages id="_FORM" />
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.itemId}
                                            onChange={this.handleChangeSelect}
                                        >
                                            {/*<MenuItem value="null">Please Select</MenuItem>*/}
                                            {
                                                this.props.profile.personalDomainItems.map((item,index) =>{
                                                    return(
                                                        <MenuItem key={`fr-${index}`}  id={item.id.toString()} value={item.id}>{item.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    }

                                    {this.state.selectedRadioItem === "page" &&
                                    <FormControl fullWidth={true} disabled={this.state.is_verified === "yes"}>
                                        <InputLabel id="demo-simple-select-label">
                                            <IntlMessages id="_PAGE" />
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.itemId}
                                            onChange={this.handleChangeSelect}
                                        >
                                            {/*<MenuItem value={10}>test page 1</MenuItem>*/}
                                            {/*<MenuItem value={20}>test page 2</MenuItem>*/}
                                            {/*<MenuItem value={30}>test page 3</MenuItem>*/}
                                            {/*<MenuItem value={null}>Please Select</MenuItem>*/}
                                            {
                                                this.props.profile.personalDomainItems.map((item,index) =>{
                                                    return(
                                                        <MenuItem key={`pg-${index}`} id={item.id.toString()}  value={item.id}>{item.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    }

                                </Grid>
                            }

                            <Grid item md={2} container justify={this.state.is_verified === "null"?'flex-start':'flex-end'}>


                                {this.state.is_verified === "null" &&
                                <div style={{marginTop:"-18px"}}>
                                    <Button
                                        variant="contained"
                                        className="jr-btn bg-success text-white"
                                        style={{ marginTop: "18px" }}
                                        onClick={this.handleSavePersonalDomain}
                                        disabled={this.state.isExistDomain}
                                    >
                                        <SaveIcon fontSize="large" />
                                    </Button>
                                </div>
                                }

                                {this.state.is_verified === "no" &&
                                <Button
                                    color={"Primary"}
                                    variant={"contained"}
                                    onClick={this.handleSaveAndVerifyPersonalDomain}
                                    disabled={this.state.isExistDomain}
                                >
                                    <IntlMessages id="_ECLICKSPRO_VERIFY_AND_SAVE" />
                                </Button>
                                }

                                {this.state.is_verified === "yes" &&
                                <>
                                    <CustomButton color={"green"} onClick={this.handleEdit}>
                                        <IntlMessages id="_EDIT" />
                                    </CustomButton>
                                    <CustomButton color={"red"} onClick={this.handleRemove}>
                                        <IntlMessages id="_REMOVE" />
                                    </CustomButton>
                                </>
                                    

                                }

                            </Grid>

                            <Grid item md={3}>
                                {this.state.is_verified === "yes" &&
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Avatar style={{backgroundColor:eclicksProColors.green}}>
                                            <CheckIcon/>
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <div style={{marginTop:"9px"}}>
                                            <Typography>{`Domain is verified. DNS : ${dnsValue}.`}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <div style={{marginTop:"20px"}}>
                                    {this.state.is_verified === "no" &&
                                    <Typography>
                                        {/*<IntlMessages id="_ECLICKSPRO_DOMAIN_CHANGE_MESSAGE" />*/}
                                        {/*<IntlMessages id="_ECLICKSPRO_CHANGE_DOMAIN_DNS_MSG" />*/}
                                        {/*<IntlMessages id="_ECLICKSPRO_AFTER_CHANGE_DOMAIN_DNS_MSG" />*/}
                                        {`Please change the domain DNS to ${dnsValue}. After changing the DNS, verify the domain by clicking the verify button`}
                                    </Typography>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                }

                {/*</AuthorizationWrapper>*/}
                <NotificationContainer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyles)(PersonalDomain));