import enLang from "./entries/en-US";
import nlLang from "./entries/nl_NL";

import { addLocaleData } from "react-intl";

const addLocal = async () => {
  const en = await enLang();
  const nl = await nlLang();

  addLocaleData(en.data);
  addLocaleData(nl.data);
}


const AppLocale = {
  en: enLang,
  nl: nlLang
};

addLocal();

export default AppLocale;
