import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../firebase/firebase";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  RESET_PASSWORD
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  resetPasswordSuccess
} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";

import config from "../config";
import { clearLocalStorage } from "../common/helpers/AuthHelper";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (
  email,
  password,
  g_recaptcha_response
) => {
  debugger
  const response = await fetch(`${config.api.url}api/login_api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      "email": email,
      "password": password,
      "g_recaptcha_response": g_recaptcha_response
    })
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  // console.log("response ->", response);
  return response;
};

const resetPasswordRequest = async email => {
  // console.log("Email ->", email);
  const response = await fetch(`${config.api.url}api/reset_password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      reset_email: email
    })
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  return response;
};

const signOutRequest = async () =>
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_token", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, g_recaptcha_response } = payload;
  debugger
  try {
    debugger
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
      g_recaptcha_response
    );
    // console.log("signInUser ->", signInUser);
    if (signInUser.message && signInUser.status === "error") {
      localStorage.setItem("captchaStatus", signInUser.captchaStatus);
      yield put(showAuthMessage(signInUser.message));
    } else {
      debugger
      localStorage.setItem("user_token", signInUser.user.token);
      localStorage.setItem("captchaStatus", signInUser.captchaStatus);
      yield put(userSignInSuccess(signInUser.user.token));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetPasswordWithEmail({ payload }) {
  const { email } = payload;
  try {
    const resetPassword = yield call(resetPasswordRequest, email);
    if (resetPassword.message && resetPassword.status === "error") {
      yield put(showAuthMessage(resetPassword.message));
      yield put(resetPasswordSuccess(resetPassword.status));
    } else {
      yield put(showAuthMessage(resetPassword.message));
      yield put(resetPasswordSuccess(resetPassword.status));
    }
    // console.log("resetPassword", resetPassword);
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  debugger
  try {
    // const signOutUser = yield call(signOutRequest);
    let signOutUser;
    // if (signOutUser === undefined) {
      // localStorage.removeItem("user_token");
      // localStorage.removeItem("localData");
      clearLocalStorage()
      //localStorage.clear()
      yield put(userSignOutSuccess(signOutUser));
      //window.location.reload()
    // } else {
    //   yield put(showAuthMessage(signOutUser.message));
    // }
  } catch (error) {
    yield put(showAuthMessage("Something went wrong"));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordWithEmail);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(resetPassword),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser)
  ]);
}
