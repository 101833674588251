import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IntlMessages from "util/IntlMessages";

import AccountInformation from "./AccountInformation";
import InputField from "./InputField";
import ImageAvatar from "./ImageAvatar";
import AddPaymentMethod from "./AddPaymentMethod";
import ApiData from "./ApiData";
import CompanyInformation from "./CompanyInformation";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

class FullWidthTabs extends Component {
  state = {
    value: 0
  };

  componentDidMount() {
      if(this.props.tabValue === undefined || this.props.tabValue === null){
        this.setState({
          value:0
        })
      }else{
        this.setState({
          value:this.props.tabValue
        })
      }

  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    // console.log("value ->", this.props.value);
    const { theme, value, token } = this.props;

    return (
      <div className="w-100">
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="on"
          >
            <Tab
              className="tab"
              label={<IntlMessages id="_ACCOUNT_INFORMATION" />}
            />
            <Tab
              className="tab"
              label={<IntlMessages id="_CHANGE_PROFILE_PICTURE" />}
            />
            <Tab
              className="tab"
              label={<IntlMessages id="_CHANGE_EMAIL" />}
            />
            <Tab
              className="tab"
              label={<IntlMessages id="_CHANGE_PASSWORD" />}
            />
            <Tab
                className="tab"
                label={<IntlMessages id="_ECLICKSPRO_ADD_PAYMENT_METHOD" />}
            />
            <Tab
                className="tab"
                label={<IntlMessages id="_ECLICKSPRO_COMPANY_INFORMATION" />}
            />
            <Tab
                className="tab"
                label={<IntlMessages id="_ECLICKSPRO_GENERATE_KEY" />}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            {
              <AccountInformation
                data={value.data}
                mobileCodes={value.mobileCodes}
                languages={value.languages}
                token={token}
              />
            }
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <ImageAvatar image={value.data.image} token={token} />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {<InputField type="email" token={token} />}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {<InputField type="password" token={token} />}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {/*{<InputField type="password" token={token} />}*/}
            {/*<h1>Test</h1>*/}
            <AddPaymentMethod
                info={value}
              updateMollieInfo={this.props.updateMollieInfo}
            />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {/*{<InputField type="password" token={token} />}*/}
            {/*<h1>Test</h1>*/}
            
            <CompanyInformation
                info={value}
                updateCompanyInfo={this.props.updateCompanyInfo}
                cancelSubscription={this.props.cancelSubscription}
                mobileCodes={value.mobileCodes}
            />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            {/*{<InputField type="password" token={token} />}*/}
            {/*<h1>Test</h1>*/}
            
            <ApiData
                info={value}
                generateKey={this.props.generateKey}
                publicKey={this.props.publicKey}
                secretKey={this.props.secretKey}
            />
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

FullWidthTabs.propTypes = {
  theme: PropTypes.object.isRequired
};

export default withStyles(null, { withTheme: true })(FullWidthTabs);
