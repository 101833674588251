import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import config from "config";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: grey[50],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class CircularFab extends React.Component {
  state = {
    loading: false,
    success: false
  };
  handleButtonClick = () => {
    const { data, token } = this.props;
    if (!this.state.loading) {
      this.setState({
        success: false,
        loading: true
      });
      fetch(`${config.api.url}api/profile_setting`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          language: data.language ? data.language : "english",
          first_name: data.firstname ? data.firstname : "",
          last_name: data.lastname ? data.lastname : "",
          mobile_prefix: data.countryCode ? data.countryCode : "",
          mobile_postfix: data.mobileNumber ? data.mobileNumber : "",
          user_2fa_options: 0
        })
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === "success") {
            this.setState({
              loading: false,
              success: true
            });
          }
          if (json.status === "error") {
            this.setState({
              loading: false,
              success: false
            });
          }
        })
        .catch(error => console.log("error ->", error));
    }

    // if (!this.state.loading) {
    //   this.setState(
    //     {
    //       success: false,
    //       loading: true
    //     },
    //     () => {
    //       this.timer = setTimeout(() => {
    //         this.setState({
    //           loading: false,
    //           success: true
    //         });
    //       }, 2000);
    //     }
    //   );
    // }
  };
  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loading, success } = this.state;
    const { classes } = this.props;
    // console.log("data from CircularFab ->", data);
    // console.log("token from CircularFab ->", token);
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success
    });

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            color="primary"
            className={buttonClassname}
            onClick={this.handleButtonClick}
          >
            {success ? <CheckIcon /> : <SaveIcon />}
          </Fab>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      </div>
    );
  }
}

CircularFab.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CircularFab);
