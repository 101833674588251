import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


function EproPaper(props) {
    return(
        <Paper {...props}>
            {props.children}
        </Paper>
    )
}
export default EproPaper;