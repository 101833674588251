import React from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

const styles = StyleSheet.create({
    row:{
        width:"100%",
        padding:"10px 5px",
        flexDirection:"row"
    },
    column:{
        width:"20%"
    },
    text:{
        fontSize:"10",
        color:eclicksProColors.textGray
    }
});

function InvoiceTableRow(props) {
    const {rowData} = props
    return(
        <View style={styles.row}>
            {
                rowData.map((r,i) =>{
                    return(
                        <View style={styles.column}>
                            {/* column4 is for price, if value is Price then its the header, or not "" */}
                            <Text style={styles.text}>{`${i === 4 & r !== 'Price' & r !== "" ? '€ ':''}${r}`}</Text>
                        </View>
                    )
                })
            }
        </View>
    )
}

export default InvoiceTableRow