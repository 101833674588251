import React from "react";
import PropTypes from "prop-types";
import keycode from "keycode";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IntlMessages from "util/IntlMessages";
import config from "config";
import Pagination from "react-mui-pagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

class EnhancedTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };

  render() {
    const { onSelectAllClick, numSelected, rowCount, columnData } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map((column, i) => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? "none" : "default"}
              >
                <IntlMessages id={"_" + column.label.toUpperCase()} />
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

const AlertBox = ({ flag, selectedItems, token, menu, closeAlertBox }) => {
  const handleClose = () => {
    closeAlertBox();
  };

  return (
    <div>
      <Dialog
        open={flag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id="_DELETE_PERMANENTLY" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            <IntlMessages id="_CANCEL" />
          </Button>
          <Button
            onClick={() => {
              fetch(`${config.api.url}api/${menu}/trash_data`, {
                method: "POST",
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  ids: selectedItems,
                  un_trash: "0"
                })
              })
                .then(res => res.json())
                .then(json => {
                  if (json.status === "success") {
                    window.location.reload();
                  }
                });
            }}
            color="primary"
            autoFocus
          >
            <IntlMessages id="_CONFIRM_DELETE" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

let EnhancedTableToolbar = props => {
  const {
    numSelected,
    title,
    openAlertBox,
    rowsPerPage,
    handleChangeRowsPerPage,
    total
  } = props;
  const pagesPerRow = ["10", "20", "50", "100", "1000"];

  return (
    <Toolbar className="table-header">
      <select
        style={{ width: "100px" }}
        onChange={e => handleChangeRowsPerPage(e)}
      >
        {pagesPerRow.map(value => {
          if (rowsPerPage === value) {
            return (
              <option value={value} selected={value}>
                {value}
              </option>
            );
          } else {
            return <option value={value}>{value}</option>;
          }
        })}
      </select>
      <span
        style={{
          width: "175px",
          marginLeft: "10px",
          fontWeight: "bold",
          color: "#4caf50"
        }}
      >
        <IntlMessages id="_TOTAL" /> {total} {total > 1 ? "Items" : "Item"}
      </span>
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">
            <h3 style={{ color: "#4caf50" }}>{title}</h3>
          </Typography>
        )}
      </div>
      <div className="spacer" />
      <div className="actions">
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <button
              style={{
                border: "none",
                backgroundColor: "Transparent",
                backgroundRepeat: "no-repeat"
              }}
              onClick={() => openAlertBox()}
            >
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </button>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

class EnhancedTable extends React.Component {
  componentDidMount() {
    const { column, rows } = this.props;
    const columnData = [];
    const rowData = [];

    column.forEach(value =>
      columnData.push({
        id: value,
        align: false,
        disablePadding: true,
        label: value
      })
    );
    rows.forEach(row => {
      rowData.push(this.createData(row.id, row));
    });

    this.setState({ data: rowData });
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    // console.log("event.target.value -> ", event.target.value);
    this.setState({ rowsPerPage: event.target.value });
    this.props.fetchItemsPerPage(event.target.value);
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "",
      orderBy: "",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: props.itemPerPage,
      customPage: props.pageNumber,
      flag: false
    };
  }

  createData = (id, args) => {
    let obj = [];
    // console.log("arg---->", args);
    for (const index of Object.keys(args)) {
      // console.log("index----->", index);
      obj.push(args[index]);
    }
    // console.log("obj---->", obj);
    return { id: id, obj };
  };

  searchValueFromHeader = (value, flag) => {
    this.props.searchValue(value, flag);
  };

  columSearch = (value, flag) => {
    this.props.columnSearchValue(value);
  };

  setPage = (event, page) => {
    // console.log("page ->", page);
    this.setState({ customPage: page });
    this.props.getPageNumber(page);
  };

  closeAlertBox = () => {
    this.setState({ flag: false });
  };

  openAlertBox = () => {
    this.setState({ flag: true });
  };

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      customPage
    } = this.state;
    const {
      column,
      token,
      menu,
      tableName,
      customOrderingField,
      totalItems,
      columnSearchText
    } = this.props;
    const columnData = [];

    column.forEach(value =>
      columnData.push({
        id: value,
        align: false,
        disablePadding: true,
        label: value
      })
    );

    this.props.getItemsId(selected);

    return (
      <div>
        <EnhancedTableToolbar
          numSelected={selected.length}
          openAlertBox={this.openAlertBox}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          total={totalItems}
        />
        <div className="flex-auto">
          <div className="table-responsive-material">
            <Table>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                columnData={columnData}
                token={token}
                tableName={tableName}
                columSearch={this.columSearch}
                customOrderingField={customOrderingField}
                columnList={column}
                columnSearchText={columnSearchText}
              />
              <TableBody style={{ backgroundColor: "white" }}>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    // console.log("n ->", n);
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, n.id)}
                        onKeyDown={event => this.handleKeyDown(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isSelected} />
                        </TableCell>
                        {n.obj.map(value => (
                          <TableCell
                            padding="none"
                            style={{ color: "#4caf50" }}
                          >
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Pagination
              page={customPage}
              setPage={this.setPage}
              total={totalItems}
              perPage={rowsPerPage}
            />
            <AlertBox
              flag={this.state.flag}
              closeAlertBox={this.closeAlertBox}
              selectedItems={selected}
              token={token}
              menu={menu}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EnhancedTable;
