import React from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

// Create styles
const styles = StyleSheet.create({
    pdfViewer:{
        width:"100%",
        height:"800px"
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    header:{
        width: "100%",
        height: "100px",
        // backgroundColor: "#FAD8D6",
        borderBottom:true,
        borderBottomColor:"#A3A3A3",
        borderBottomStyle:{
            height:"5px"
        }
        // justifyContent:"center",
        // flexDirection: "row"
    },
    headerTitleView:{
        marginTop:"18px",
    },
    headerTitleText:{
        fontSize:"32",
        color:eclicksProColors.textGray,
        fontWeight:"bold",
        textAlign:"center",
        marginBottom:"10px"
    },
    headerSubTitleText:{
        fontSize:"15",
        color:eclicksProColors.textGray,
        textAlign:"center",
        fontWeight:"bold",
    }
});

function InvoiceHeader(props) {
    return(
        <View style={styles.header}>
            <View style={styles.headerTitleView}>
                <Text style={styles.headerTitleText}>Invoice</Text>
                <Text style={styles.headerSubTitleText}>Eclicks pro</Text>
            </View>
        </View>
    )
}
export default InvoiceHeader