import React, { Component } from "react";
//material ui
import { withStyles } from "@material-ui/core/styles";

// notifications
import {
    NotificationManager,
    NotificationContainer,
} from "react-notifications";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../actions/Table.js";
import IntlMessages from "../../../util/IntlMessages";
import { Button } from "@material-ui/core";
import CustomBackButton from "../customComponents/button/CustomBackButton";
import FieldsView from "../customComponents/FieldsView/FieldsView";

const useStyles = theme => ({
    // drawerPaper: {
    //     width: drawerWidth
    // },
    customDrawerWrapperHeader: {
        margin: "0 0 0 22px"
    },
    contentArea: {
        position: "relative",
        width: "79%",
        float: "right"
        //width: "3000px"
    },
    mainWrapperBox:{
        border:"1px solid #D7D7D7",
        borderRadius:"10px",
        padding:"20px"
    }
});

class CommonEditView extends Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }
    componentDidMount() {
        this.props.getTableRowById(this.props.match.params.menu,this.props.match.params.id)
    }


    handleGoBack = () =>{
        this.props.history.goBack()
    }
    render() {
        debugger
        const {item,match,table} = this.props
        return (
            <div className="app-wrapper">
                <div className="animated slideInUpTiny animation-duration-3">
                {/*    header*/}
                    <div className="row">
                        <div className="col-md-6">
                            <h1>
                                Item:{" "}
                                <span style={{ color: "#4caf50" }}>
									<IntlMessages id={"_" + match.params.menu.toUpperCase()} />{" "}
                                    {table.positionData && table.positionData.current_position} of{" "}
                                    {table.positionData && table.positionData.total_rows}
								</span>
                                <span style={{ marginLeft: "20px" }}>
									<a href={`/app/listing/${match.params.menu}`}>
										<CustomBackButton
                                            onClick={this.props.handleGoBack}
                                        />
									</a>
								</span>
                            </h1>
                        </div>
                        <div
                            className="col-md-6"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                            }}
                        >
                            {match.params.menu !== "permissions_row" && (
                                <Button
                                    disabled={this.props.disableAcInfoBtnInEditView}
                                    variant="contained"
                                    color="primary"
                                    className="jr-btn text-white jr-btn-lg"
                                    onClick={() => this.props.requestSave()}
                                    style={{
                                        maxHeight: "39.19px",
                                        minHeight: "39.19px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <IntlMessages id="_SAVE" />
                                </Button>
                            )}
                            {table.positionData && table.positionData.next_record_id !== 0 &&
                            match.params.menu !== "permissions_row" && (
                                <Button
                                    disabled={this.props.disableAcInfoBtnInEditView}
                                    variant="contained"
                                    color="primary"
                                    className="jr-btn text-white jr-btn-lg"
                                    onClick={() => this.props.requestSaveAndNext()}
                                    style={{
                                        maxHeight: "39.19px",
                                        minHeight: "39.19px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <IntlMessages id="_SAVE_AND_NEXT" />
                                </Button>
                            )}
                            {match.params.menu !== "permissions_row" && (
                                <Button
                                    disabled={this.props.disableAcInfoBtnInEditView}
                                    variant="contained"
                                    color="primary"
                                    className="jr-btn text-white jr-btn-lg"
                                    onClick={() => this.props.requestSaveAndClose()}
                                    style={{
                                        maxHeight: "39.19px",
                                        minHeight: "39.19px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <IntlMessages id="_SAVE_AND_CLOSE" />
                                </Button>
                            )}
                            {table.permissions && table.permissions.add_permission === "1" &&
                            match.params.menu !== "permissions_row" && (
                                <Button
                                    variant="contained"
                                    className="jr-btn bg-cyan text-white jr-btn-lg"
                                    style={{
                                        maxHeight: "39.19px",
                                        minHeight: "39.19px",
                                        marginTop: "5px",
                                    }}
                                    onClick={() => this.props.requestAdd()}
                                >
                                    {<IntlMessages id="_ADD" />}
                                </Button>
                            )}

                            {table.permissions && table.permissions.delete_permission === "1" && (
                                <Button
                                    variant="contained"
                                    className="jr-btn bg-red text-white jr-btn-lg"
                                    onClick={() => this.props.requestDelete()}
                                    style={{
                                        maxHeight: "39.19px",
                                        minHeight: "39.19px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <IntlMessages id="_DELETE" />
                                </Button>
                            )}
                        </div>
                    </div>
                {/*    header*/}

                {/*fields*/}
                <FieldsView
                    loader={false}
                    tableName={this.props.match.params.menu}
                />

                {/*fields*/}
                </div>
            </div>
        );
    }
}

//export default CommonEditView;

const mapStateToProps = state => ({
    table: state.table
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyles)(CommonEditView));