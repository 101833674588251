import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import IntlMessages from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import config from "config";

class InputField extends React.Component {
  state = {
    value: "",
    reValue: "",
    loader: false
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { type, token } = this.props;
    const { value, reValue, loader } = this.state;
    return (
      <div className="row animated slideInUpTiny animation-duration-3">
        <div className="col-md-5 col-12">
          <TextField
            type={type}
            id="value"
            label={<IntlMessages id={"_CHANGE_" + type.toUpperCase()} />}
            value={this.state.value}
            onChange={this.handleChange("value")}
            margin="normal"
            fullWidth
          />
        </div>
        {/* {loader && <CircularProgress />} */}
        <div className="col-md-5 col-12">
          <TextField
            type={type}
            id="reValue"
            label={<IntlMessages id={"_REPEAT_" + type.toUpperCase()} />}
            value={this.state.reValue}
            onChange={this.handleChange("reValue")}
            margin="normal"
            fullWidth
          />
        </div>
        <div className="col-md-2 col-12">
          <Button
            variant="contained"
            className="jr-btn bg-success text-white"
            style={{ marginTop: "18px" }}
            onClick={async () => {
              let msg;
              const regexIE = new RegExp("^([a-zA-Z0-9_\\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$");
              const reGexLC = new RegExp("^(?=.*[a-z])");
              const reGexUC = new RegExp("(?=.*[A-Z])");
              const reGexN = new RegExp("(?=.*[0-9])");
              const reGexSC = new RegExp("(?=.*[!@#\$%\^&\*])");

              if(type === "email"){
                if(value.length > 1){
                  msg = <IntlMessages id="_ECLICKSPRO_EMAIL_NULL"/>
                  return NotificationManager.error(msg);
                }
                if(regexIE.test(value)){
                  msg = <IntlMessages id="_ECLICKSPRO_EMAIL_INVALID" />;
                  return NotificationManager.error(msg);
                }
              }

              // if (type === "password") {
              //   if (value.length < 8) {
              //     msg = <IntlMessages id="msg.passwordValidation8C" />;
              //     return NotificationManager.error(msg);
              //   }
              //   if (!reGexLC.test(value)) {
              //     msg = <IntlMessages id="msg.passwordValidationLC" />;
              //     return NotificationManager.error(msg);
              //   }
              //   if (!reGexUC.test(value)) {
              //     msg = <IntlMessages id="msg.passwordValidationUC" />;
              //     return NotificationManager.error(msg);
              //   }
              //   if (!reGexN.test(value)) {
              //     msg = <IntlMessages id="msg.passwordValidationN" />;
              //     return NotificationManager.error(msg);
              //   }
              //   if (!reGexSC.test(value)) {
              //     msg = <IntlMessages id="msg.passwordValidationSC" />;
              //     return NotificationManager.error(msg);
              //   }
              // }
              if (type === "password") {
                if (value.length < 8) {
                  msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION_MSG_8C" />;
                  return NotificationManager.error(msg);
                }
                if (!reGexLC.test(value)) {
                  msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION_MSG_UC" />;
                  return NotificationManager.error(msg);
                }
                if (!reGexUC.test(value)) {
                  msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION_MSG_UC" />;
                  return NotificationManager.error(msg);
                }
                if (!reGexN.test(value)) {
                  msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION_MSG_N" />;
                  return NotificationManager.error(msg);
                }
                if (!reGexSC.test(value)) {
                  msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION_MSG_SC" />;
                  return NotificationManager.error(msg);
                }
              }

              if (value !== reValue) {
                return NotificationManager.error(
                  `${type.toUpperCase()} NOT SAME`
                );
              }

              const response = await fetch(
                `${config.api.url}api/change_password_or_mail`,
                {
                  method: "POST",
                  headers: {
                    Authorization: token,
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    type: type,
                    value: value
                  })
                }
              )
                .then(res => res.json())
                .then(json => json);

              if (response.status === "success")
                NotificationManager.info(response.message);
              if (response.status === "error")
                NotificationManager.error(response.message);
            }}
          >
            <SaveIcon fontSize="large" />
          </Button>
        </div>
      </div>
    );
  }
}

export default InputField;
