import {
	FETCH_FORM,
	FETCH_FORM_SUCCESS,
	FETCH_FORM_BY_ID,
	FETCH_FORM_BY_ID_SUCCESS,
} from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
	loader: false,
	openFullScreenLoader: false,
	forms: [],
	folders: [],
	formParams: null,
	formData: null,
	formDataParams: null,
	form: null,
	successPage: "",
	newFormCreated: false,
	newFormId: "",
	pixelCode:null,
	footerPixelCode:null,
	productPrice:"",
	productVat:"",
	productDescription:"",
	formApiResponse:null,
	campaignIdOfThisItem: null
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_FORM: {
			return {
				...state,
				loader: true,
				formParams: action.payload,
			};
		}
		case FETCH_FORM_SUCCESS: {
			return {
				...state,
				loader: false,
				forms: action.payload,
			};
		}
		case FETCH_FORM_BY_ID: {
			return { ...state, loader: true, formDataParams: action.payload };
		}
		case FETCH_FORM_BY_ID_SUCCESS: {
			return { ...state, loader: false, formData: action.payload };
		}
		case Constants.REQUEST_GET_FROM_BY_ID: {
			return {
				...state,
				loader: true,
				form: null,
				successPage: "",
				pixelCode:null,
				footerPixelCode:null,
				productPrice: "",
				productDescription: "",
				productVat:""


			};
		}
		case Constants.SUCCESS_GET_FROM_BY_ID: {
			return {
				...state,
				loader: false,
				form: action.data,
				successPage: action.successPage,
				pixelCode: action.pixelCode,
				footerPixelCode: action.footerPixelCode,
				productPrice: action.productPrice,
				productDescription: action.productDescription,
				productVat:action.productVat
			};
		}
		case Constants.REQUEST_RENAME_FORM:{
			return {
				...state,
				openFullScreenLoader: true,
				formApiResponse: null
			}
		}
		case Constants.SUCCESS_RENAME_FORM: {
			return {
				...state,
				form: action.formObj,
				openFullScreenLoader: false,
				formApiResponse: action.apiResponse
			};
		}
		case Constants.FAILED_RENAME_FORM:{
			return {
				...state,
				openFullScreenLoader: false,
				formApiResponse: action.apiResponse
			}
		}
		case "GET_FORMS": {
			return {
				...state,
				loader: true,
			};
		}
		case "GET_ALL_EMPTY": {
			return {
				...state,
				forms: [],
				folder: [],
				newFormCreated: false,
				newFormId: "",
			};
		}
		case "GET_FORM_EMPTY": {
			return {
				...state,
				newFormCreated: false,
				newFormId: "",
			};
		}
		case "ADD_FOLDER_SUCCESS": {
			return {
				...state,
				loader: false,
				folders: [...action.folders, ...state.folders],
			};
		}
		case "ADD_FORM_SUCCESS": {
			return {
				...state,
				loader: false,
				forms: [...action.forms, ...state.forms],
				newFormCreated: true,
				newFormId: action.forms[0].id,
			};
		}
		case Constants.REQUEST_PUT_FORM_DATA:{
			return {
				...state,
				formApiResponse: null
			}
		}
		case Constants.SUCCESS_PUT_FORM_DATA:{
			return {
				...state,
				form: action.form,
				openFullScreenLoader:false,
				formApiResponse: action.apiResponse
			}
		}
		case Constants.FAILED_PUT_FORM_DATA:{
			return {
				...state,
				formApiResponse: action.apiResponse
			}
		}
		case "DELETE_FORM_SUCCESS": {
			let formId = action.formId;
			let items = state.forms.filter((i) => i.id !== formId);
			// let index = state.forms.indexOf(item[0]);
			// let newFoms = state.forms;
			// if (index !== -1) {
			// 	newFoms = state.forms.splice(index, 1);
			// }

			return {
				...state,
				loader: false,
				forms: items,
			};
		}
		case "DELETE_FOLDER_SUCCESS": {
			let folderId = action.folderId;
			let items = state.folders.filter((i) => i.id !== folderId);
			// let index = state.folders.indexOf(item[0]);
			// let newFolder = state.folders;
			// if (index !== -1) {
			// 	newFolder = state.folders.splice(index, 1);
			// }

			return {
				...state,
				loader: false,
				folders: items,
			};
		}
		case "GET_ALL_FORM_SUCCESS": {
			return {
				...state,
				loader: false,
				forms: action.forms,
			};
		}
		case "GET_ALL_FOLDER_SUCCESS": {
			return {
				...state,
				loader: false,
				folders: action.folders,
			};
		}

		// case "UPDATE_SUCCESS_PAGE": {
		// 	return {
		// 		...state,
		// 		successPage: action.successPage,
		// 	};
		// }
		case "RENAME_FORM_SUCCESS": {
			let formId = action.formId;
			let item = state.forms.filter((i) => i.id === formId);
			let index = state.forms.indexOf(item[0]);
			state.forms.splice(index, 1, action.form[0]);

			return {
				...state,
			};
		}
		case "RENAME_FOLDER_SUCCESS": {
			let folderId = action.folderId;
			let item = state.folders.filter((i) => i.id === folderId);
			let index = state.folders.indexOf(item[0]);
			state.folders.splice(index, 1, action.folder[0]);

			return {
				...state,
			};
		}
		case "UPDATE_FORM_PARENT_SUCCESS": {
			let frms = state.forms.filter((i) => i.id !== action.frmId);
			return {
				...state,
				forms: frms,
			};
		}
		case "UPDATE_FOLDER_PARENT_SUCCESS": {
			let fldrs = state.folders.filter((i) => i.id !== action.fldrId);
			return {
				...state,
				folders: fldrs,
			};
		}
		case Constants.RESET_FORM_API_RESPONSE:{
			return {
				...state,
				formApiResponse: null
			}
		}
		case Constants.START_FORM_LOADER_DIALOG: {
			return {
				...state,
				openFullScreenLoader: true
			}
		}
		case Constants.STOP_FORM_LOADER_DIALOG: {
			return {
				...state,
				openFullScreenLoader: false
			}
		}
		case Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM:{
			return {
				...state,
				campaignIdOfThisItem: null
			}
		}
		case Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE:{
			return {
				...state,
				campaignIdOfThisItem: action.payload
			}
		}
		case Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM:{
			return {
				...state,
				campaignIdOfThisItem: null
			}
		}
		default:
			return state;
	}
};
