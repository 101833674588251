import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_TRASHVIEW } from "constants/ActionTypes";
import { fetchTrashViewSuccess } from "actions/TrashView";

import config from "../config";

const fetchTrashViewData = async params => {
  const { token, value, search, itemPerPage, pageNumber } = params;
  const response = await fetch(`${config.api.url}api/${value}/trash_view`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber - 1,
      search: search,
      order_by_field: "",
      order_direction: "",
      table_sort_field: "",
      table_sort_direction: "",
      multi_search_fields: {}
    })
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  return response;
};

function* getFetchTrashViewData({ payload }) {
  try {
    const getTrashData = yield call(fetchTrashViewData, payload);
    yield put(fetchTrashViewSuccess(getTrashData));
  } catch (e) {
  }
}

export function* getTrashViewData() {
  yield takeEvery(FETCH_TRASHVIEW, getFetchTrashViewData);
}

export default function* rootSaga() {
  yield all([fork(getTrashViewData)]);
}
