import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import config from "config";

class DeleteDialogBox extends Component {
  handleRequestClose = () => {
    this.props.handleRequestClose();
  };

  handleRequestDelete = async () => {
    const { token, tableName, templateId } = this.props;
    debugger

    const response = await fetch(
      `${config.api.url}/api/template_delete/${tableName}/${templateId}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((json) => json)
      .catch((e) => console.log(e));


    if (response.status === "success") return window.location.reload();
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.openDeleteBox}
          onClose={this.handleRequestClose}
        >
          <DialogTitle>
            <IntlMessages id="_DELETE_VIEW" />
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="primary">
              <IntlMessages id="_CLOSE" />
            </Button>
            <Button
              onClick={this.handleRequestDelete}
              className="jr-btn bg-danger text-white"
            >
              <IntlMessages id="_DELETE" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DeleteDialogBox;
