import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import IntlMessages from "util/IntlMessages";
import { NotificationManager } from "react-notifications";
import config from "config";
import { Card, CardBody, CardHeader, CardSubtitle, CardText } from "reactstrap";
import CardBox from "components/CardBox";

class InputField extends React.Component {
  state = {
    userInfo: null
  };

  componentWillMount() {
    if (this.props.match) {
      if (this.props.match.params.menu === "dashboard_login") {
        this.fetchUserInfo();
      }
    }
  }
  fetchUserInfo = async () => {
    const { authUser, match } = this.props;
    const response = await fetch(
      `${config.api.url}api/${match.params.menu}/ListingEdit/${match.params.id}`,
      {
        method: "GET",
        headers: {
          Authorization: authUser,
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.json())
      .then(json => json)
      .catch(err => console.error(err));

    if (response) {
      if (response.status === "success") {
        this.setState({ userInfo: response });
      } else {
        this.setState({ userInfo: null });
      }
    } else {
      this.setState({ userInfo: null });
    }
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    // console.log("input",this.props)
    return (
      <div className="row animated slideInUpTiny animation-duration-3">
        {this.state.userInfo ? (
          <CardBody>
            <CardText>
              Created By :{" "}
              <strong>
                {this.state.userInfo
                  ? this.state.userInfo.create_update_info.created_by
                  : null}
              </strong>
            </CardText>
            <CardText>
              Created At :{" "}
              <strong>
                {this.state.userInfo
                  ? this.state.userInfo.create_update_info.created_at
                  : null}
              </strong>
            </CardText>
            <CardText>
              Modified At :{" "}
              <strong>
                {this.state.userInfo
                  ? this.state.userInfo.create_update_info.modified_at
                  : null}
              </strong>
            </CardText>
            <CardText>
              Modified By :{" "}
              <strong>
                {this.state.userInfo
                  ? this.state.userInfo.create_update_info.modified_by
                  : null}
              </strong>
            </CardText>
          </CardBody>
        ) : (
          <h1>No Info Of creation</h1>
        )}
      </div>
    );
  }
}

export default InputField;
