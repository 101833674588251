import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_REPORT_GROUP,
  FETCH_REPORT_FILTER,
  FETCH_REPORT_RELATION,
  FETCH_REPORT_API,
  FETCH_REPORT_LETTER
} from "constants/ActionTypes";

import {
  fetchReportGroupSuccess,
  fetchReportFilterSuccess,
  fetchReportRelationSuccess,
  fetchReportApiSuccess,
  fetchReportLetterSuccess
} from "actions/Report";

import config from "config";

// Group
const fetchReportGroup = async params => {
  const { token, itemPerPage, pageNumber } = params;

  const response = await fetch(`${config.api.url}api/report/report`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber,
      type: "group"
    })
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchReportGroup({ payload }) {
  try {
    const getReportGroupData = yield call(fetchReportGroup, payload);
    yield put(fetchReportGroupSuccess(getReportGroupData));
  } catch (e) {
    console.error(e);
  }
}

export function* getReportGroup() {
  yield takeEvery(FETCH_REPORT_GROUP, getFetchReportGroup);
}

// Filter
const fetchReportFilter = async params => {
  const { token, itemPerPage, pageNumber } = params;

  const response = await fetch(`${config.api.url}api/report/report`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber,
      type: "filter"
    })
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchReportFilter({ payload }) {
  try {
    const getReportFilterData = yield call(fetchReportFilter, payload);
    yield put(fetchReportFilterSuccess(getReportFilterData));
  } catch (e) {
    console.error(e);
  }
}

export function* getReportFilter() {
  yield takeEvery(FETCH_REPORT_FILTER, getFetchReportFilter);
}

// Relation
const fetchReportRelation = async params => {
  const { token, itemPerPage, pageNumber } = params;

  const response = await fetch(`${config.api.url}api/report/report`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber,
      type: "relation"
    })
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchReportRelation({ payload }) {
  try {
    const getReportRelationData = yield call(fetchReportRelation, payload);
    yield put(fetchReportRelationSuccess(getReportRelationData));
  } catch (e) {
    console.error(e);
  }
}

export function* getReportRelation() {
  yield takeEvery(FETCH_REPORT_RELATION, getFetchReportRelation);
}

// Api
const fetchReportApi = async params => {
  const { token, itemPerPage, pageNumber } = params;

  const response = await fetch(`${config.api.url}api/report/report`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber,
      type: "api"
    })
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchReportApi({ payload }) {
  try {
    const getReportApiData = yield call(fetchReportApi, payload);
    yield put(fetchReportApiSuccess(getReportApiData));
  } catch (e) {
    console.error(e);
  }
}

export function* getReportApi() {
  yield takeEvery(FETCH_REPORT_API, getFetchReportApi);
}

// Letter
const fetchReportLetter = async params => {
  const { token, itemPerPage, pageNumber } = params;

  const response = await fetch(`${config.api.url}api/report/report`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber,
      type: "letter"
    })
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchReportLetter({ payload }) {
  try {
    const getReportLetterData = yield call(fetchReportLetter, payload);
    yield put(fetchReportLetterSuccess(getReportLetterData));
  } catch (e) {
    console.error(e);
  }
}

export function* getReportLetter() {
  yield takeEvery(FETCH_REPORT_LETTER, getFetchReportLetter);
}

//   export saga
export default function* rootSaga() {
  yield all([
    fork(getReportGroup),
    fork(getReportFilter),
    fork(getReportRelation),
    fork(getReportApi),
    fork(getReportLetter)
  ]);
}
