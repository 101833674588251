import {
  FETCH_RELATION_GROUP_FOLDER,
  FETCH_RELATION_GROUP_FOLDER_SUCCESS,
  FETCH_RELATION_GROUP,
  FETCH_RELATION_GROUP_SUCCESS,
} from "constants/ActionTypes";

export const fetchRelationGroup = (params) => {
  return {
    type: FETCH_RELATION_GROUP_FOLDER,
    payload: params,
  };
};

export const fetchRelationGroupSuccess = (groups) => {
  return {
    type: FETCH_RELATION_GROUP_FOLDER_SUCCESS,
    payload: groups,
  };
};

export const fetchRelationGroupFolder = (params) => {
  return {
    type: FETCH_RELATION_GROUP,
    payload: params,
  };
};

export const fetchRelationGroupFolderSuccess = (folders) => {
  return {
    type: FETCH_RELATION_GROUP_SUCCESS,
    payload: folders,
  };
};
