import React, { Component, useEffect, useState } from "react";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../actions/Profile";

//material ui
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PackageAddForm from "./component/PackageAddForm";
import IntlMessages from "../../../../util/IntlMessages";
import ContainerHeader from "../../../../components/ContainerHeader";
import CustomButton from "../../customComponents/button/CustomButton";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialog, { dialogStyles } from "../../customComponents/dialog/customDialog";
import DialogContentText from "@material-ui/core/DialogContentText";

//notification
import { NotificationContainer, NotificationManager } from "react-notifications";
import CircularLoader from "../../customComponents/loader/CircularLoader";
import CustomDeleteDialog from "../../customComponents/dialog/customDeleteDialog";


const useStyle = theme =>({
    addButtonWrapper:{
        margin:"20px 20px 30px 7px"
    }
})

let componentFor = "profileData"
let responseName = "profileApiResponse"
class PackageSubscriptionFee extends Component {
    constructor(props) {
        super(props);
        this.state={
            packages:[],
            unsavedAlertOpen:false,
            applyVat:"0",
            invalidCards:[],
            openDeleteAlert:false,
            deletePacId:null

        }
    }
    componentDidMount() {
        this.props.getPackageSubscriptionFees()
        this.props.getCompanyInfo()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profileData.subscriptionFees !== this.props.profileData.subscriptionFees && this.props.profileData.subscriptionFees !== []){
            this.setState({
                packages:this.props.profileData.subscriptionFees
            })
        }

        if(prevProps.profileData.subscriptionVat !== this.props.profileData.subscriptionVat && this.props.profileData.subscriptionVat !== "0"){
            this.setState({
                applyVat:this.props.profileData.subscriptionVat
            })
        }

        if(prevProps.profileData.companyInfo !== this.props.profileData.companyInfo && this.props.profileData.companyInfo.country == 'Netherlands'){
            this.setState({
                applyVat:"1"
            })
        }

        //notification
        if(prevProps[componentFor][responseName]!== this.props[componentFor][responseName] && this.props[componentFor][responseName] !== null){
            //let res = this.props.apiResponse
            NotificationManager[this.props[componentFor][responseName].alertType](this.props[componentFor][responseName].message)
            this.props.resetResponse()
        }

    }

    addPackage = () =>{
        let pData = this.state.packages.filter(p =>p.id !== null);
        let negId = 0;
        for(let i= 0; i < pData.length;i++){
            let id = parseInt(pData[i].id)
            if(id < negId){
                negId = id
            }

        }
        let isExistUnSaved = pData.find(p =>p.id === '0')
        if(isExistUnSaved){
            this.setState({
                unsavedAlertOpen:true
            })
        }else {
            let newItem = {
                id:negId-1,
                contacts:"",
                light_monthly:"",
                light_yearly:"",
                light_up_to_contacts:"",
                plus_monthly:"",
                plus_yearly:"",
                plus_up_to_contacts:"",
                professional_monthly:"",
                professional_yearly:"",
                professional_up_to_contacts:""
            }
            pData.splice(pData.length,0,newItem)
            this.setState({
                packages:pData
            })
        }


    }
    openDeleteAlert = (packageId) =>{
        this.setState({
            openDeleteAlert:true,
            deletePacId:packageId
        })
    }
    deletePackage = () =>{
        let packageId = this.state.deletePacId
        let pData = this.state.packages.filter(p =>p.id !== null);
        let p = pData.find(p =>p.id === packageId);
        let pIndex = pData.indexOf(p)
        pData.splice(pIndex,1)
        this.setState({
            packages:pData
        })
        if(parseInt(packageId) > 0){
            this.props.deleteSubscriptionFee(packageId)
        }
        this.handleClose()
    }
    handlePackages = (packageId,packageType,value) =>{
        let pData = this.state.packages.filter(p =>p.id !== null);
        let p = pData.find(p =>p.id.toString() === packageId);
        let pIndex = pData.indexOf(p)
        // pData[pIndex].contacts = value
        let item = pData[pIndex]
        let isNumTest = /^\d+$/.test(value);

        if(packageType === "contact"){
            //validate
            let {invalidCards} = this.state
            let isExist = pData.find(a => a.contacts.toString() === value.toString())

            if(isExist || value === ""){
                invalidCards.push(item.id)
            }else{
                if(invalidCards.find(a => a === item.id)){
                    invalidCards = invalidCards.filter(a =>a.toString() !== item.id.toString())
                }
            }

            if(isNumTest || value === ""){
                item.contacts = value
                this.setState({
                    invalidCards:invalidCards
                })
            }
        }else {
            if(isNumTest || value === ""){
                item[packageType] = value
           }
        }

        pData[pIndex] = item

        this.setState({
            packages:pData
        })
    }
    handleSave = () =>{
        this.props.savePackageSubscriptionFees(this.state.packages,this.state.applyVat)
    }
    handleChangeCheck = (event) =>{
        this.setState({
            [event.target.id]:event.target.checked? "1":"0"
        })
    }
    handleClose = () =>{
        this.setState({
            unsavedAlertOpen:false,
            openDeleteAlert:false,
            deletePacId:null
        })
    }
    render() {
        const {classes,match,profileData} = this.props
        const {packages} = this.state
        let disabledVat = false;
        if(this.props.profileData.companyInfo && this.props.profileData.companyInfo.country == 'Netherlands') {
            disabledVat = true;
        }
        return (
            <div className={"app-wrapper"}>
                <ContainerHeader
                    title={<IntlMessages id="_ECLICKSPRO_SUBSCRIPTION_FEE" />}
                    match={match}
                />
                {profileData.loader?
                    <div>
                        <CircularLoader/>
                    </div>
                    :
                    <div>
                        <div className={classes.addButtonWrapper}>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <CustomButton onClick={this.addPackage} variant="contained" color={"dodgerBlue"}>{<IntlMessages id={"_ECLICKSPRO_ADD_CONTACT"}/>}</CustomButton>
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox name="applyVat" checked={this.state.applyVat === "1"} disabled={disabledVat} onChange={this.handleChangeCheck} id={"applyVat"}  color={"primary"} />}
                                                label={<IntlMessages id={"_ECLICKSPRO_APPLY_VAT"}/>} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container md={6} justify={"flex-end"}>
                                    <CustomButton
                                        onClick={this.handleSave}
                                        variant="contained"
                                        color={"green"}
                                        disabled={this.state.invalidCards.length > 0}
                                    >
                                        {<IntlMessages id={"_ECLICKSPRO_SAVE"}/>}
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </div>

                        {profileData.loader ?
                            <div>
                                <CircularLoader/>
                            </div>
                            :
                            <Grid container spacing={3}>
                                {packages.map((p,index)=>{
                                    return(
                                        <PackageAddForm
                                            packageInfo={p}
                                            handlePackages={this.handlePackages}
                                            handleDelete={this.openDeleteAlert}
                                            invalidCards={this.state.invalidCards}
                                        ></PackageAddForm>
                                    )
                                })}
                            </Grid>
                        }
                    </div>

                }

                {/*dialog*/}
                <CustomDialog
                    open={this.state.unsavedAlertOpen}
                    handleClose={this.handleClose}
                    handleNo={this.handleClose}
                    handleYes={this.handleDeleteShare}
                    dialogContent={false}
                    titleTextKey={"_ECLICKSPRO_ALERT"}
                    maxWidth={"sm"}
                    actionButtons={['close']}
                >
                    <DialogContent style={dialogStyles.dialogContentStyles}>
                        <DialogContentText>
                            <IntlMessages id={"_ECLICKSPRO_UNSAVED_ALERT_MESSAGE"}/>  ?
                        </DialogContentText>
                    </DialogContent>
                </CustomDialog>

                {/*delete alert*/}
                <CustomDeleteDialog
                    open={this.state.openDeleteAlert}
                    handleDelete={this.deletePackage}
                    handleClose={this.handleClose}
                />
                {/*delete alert*/}

                <NotificationContainer />
            </div>
        );
    }
}

// export default withStyles(useStyle)(PackageSubscriptionFee);

const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyle)(PackageSubscriptionFee));

