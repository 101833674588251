import {
    FETCH_PAGE,
    FETCH_PAGE_SUCCESS,
    FETCH_PAGE_FOLDER,
    FETCH_PAGE_FOLDER_SUCCESS,
    FETCH_PAGE_BY_ID,
    FETCH_PAGE_BY_ID_SUCCESS
} from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants";
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData } from "common/helpers/AuthHelper";

export const fetchPage = params => {
    return {
        type: FETCH_PAGE,
        payload: params
    };
};

export const fetchPageSuccess = pages => {
    return {
        type: FETCH_PAGE_SUCCESS,
        payload: pages
    };
};

export const fetchPageFolder = params => {
    return {
        type: FETCH_PAGE_FOLDER,
        payload: params
    };
};

export const fetchPageFolderSuccess = folders => {
    return {
        type: FETCH_PAGE_FOLDER_SUCCESS,
        payload: folders
    };
};

export const fetchPageById = params => {
    return {
        type: FETCH_PAGE_BY_ID,
        payload: params
    };
};

export const fetchPageByIdSuccess = pageData => {
    return {
        type: FETCH_PAGE_BY_ID_SUCCESS,
        payload: pageData
    };
};

export const actionCreators = {
    getPageById: id => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_GET_PAGE_BY_ID });
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/page/" + id;
        let token = localStorage.getItem("user_token");
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                let pixelCode = null;
                let footerPixelCode = null;
                let productPrice = "";
                let productDescription = "";
                let productVat = "";

                if (res.data.status === "success") {
                    if (res.data.data.data !== null) {
                        pixelCode = res.data.data.data.pixelCode;
                        footerPixelCode = res.data.data.data.footerPixelCode;
                        productPrice = res.data.data.data.price;
                        productDescription = res.data.data.data.item;
                        productVat = res.data.data.data.vat;
                    }
                    dispatch({
                        type: Constants.SUCCESS_GET_PAGE_BY_ID,
                        data: res.data.data,
                        pixelCode: pixelCode,
                        footerPixelCode: footerPixelCode,
                        productPrice: productPrice,
                        productDescription: productDescription,
                        productVat: productVat
                    });
                }
            })
            .catch(e => {
                let aaaa = e;
            });
    },

    //put
    putPageData: (
        page,
        jsonData,
        htmlData,
        pixelCode,
        footerPixelCode,
        useAsProduct,
        productPrice,
        productDescription,
        productVat
    ) => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_PUT_PAGE });

        debugger;

        let apiUrl = config.api.url;
        let authInfo = null;
        let url = apiUrl + "v1/page/" + page.id;
        let token = localStorage.getItem("user_token");

        if (token !== null) {
            authInfo = JSON.parse(atob(token.split(".")[1]));
        }

        page.data = jsonData;
        const parseData = JSON.parse(jsonData);
        parseData.page.title = page.name;
        parseData.page.description = page.name;
        parseData.pixelCode = pixelCode;
        parseData.footerPixelCode = footerPixelCode;
        parseData.useAsProduct = useAsProduct === true ? true : false;
        parseData.price = productPrice === undefined ? "" : productPrice;
        parseData.item =
            productDescription === undefined ? "" : productDescription;
        parseData.vat = productVat === undefined ? "" : productVat;
        parseData.userId = authInfo.uid;
        // const strData = JSON.stringify(jsonData);
        // const strDataFrom = JSON.stringify(parseData);
        // const updatedData = form;

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token
            },
            data: { data: parseData, html: htmlData }
        })
            .then(res => {
                let page = res.data.data;
                let apiResponse = getResponseForNotificaion(res);

                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_PUT_PAGE,
                        page: page,
                        apiResponse: apiResponse
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_PUT_PAGE,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_PUT_PAGE,
                    apiResponse: errorResponse
                });
            });
    },
    putPageName: (id, name) => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_RENAME_PAGE });
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/page/" + id;
        let token = localStorage.getItem("user_token");

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token
            },
            data: { name: name }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_RENAME_PAGE,
                        formObj: res.data.data,
                        apiResponse: apiResponse
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_RENAME_PAGE,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_RENAME_PAGE,
                    apiResponse: errorResponse
                });
            });
    },
    getCampaignOfThisItem: (type, id) => async (dispatch, getState) => {
        let url = config.api.url + "api/campaign/getCampaignOfThisItem";
        dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
        let token = localStorage.getItem("user_token");
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token
            },
            data: { type: type, typeId: id }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    let obj = null;
                    if (res.data.isSelected !== 0) {
                        obj = {
                            campaignId: res.data.campaignId,
                            campaignName: res.data.campaignName
                        };
                    }
                    dispatch({
                        type: Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,
                        payload: obj
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,
                        payload: null
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,
                    payload: null
                });
            });
    },
    startLoaderDialog: () => async (dispatch, getState) => {
        dispatch({ type: Constants.START_PAGE_LOADER_DIALOG });
    },
    stopLoaderDialog: () => async (dispatch, getState) => {
        dispatch({ type: Constants.STOP_PAGE_LOADER_DIALOG });
    },
    resetResponse: () => async (dispatch, getState) => {
        dispatch({ type: Constants.RESET_PAGE_API_RESPONSE });
    },
    resetSuccessStatus: () => async (dispatch, getState) => {
        dispatch({ type: Constants.RESET_SUCCESS_STATUS });
    },

    getProfileData1: () => async dispatch => {
        let { token } = getAuthData();
        let url = config.api.url + "api/profile";

        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                // let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.GET_PROFILE,
                        payload: res.data
                    });
                } else {
                    // cb("failed", {});
                }
            })
            .catch(e => {
                // let errorResponse = getResponseForNotificaion(null,{})
                // cb("failed", {});
            });
    },

    handleGenerateText: (id, target, context) => async (dispatch, getState) => {
        let apiUrl = config.api.url;
        let url = apiUrl + "api/chatgpt";

        let { token } = getAuthData();

        var data = new FormData();

        dispatch({ type: Constants.Loader1, payload: true });

        data.append("search", target);
        var newContext = context?.reduce((accumulator, el, i) => {
            return `${accumulator}${el.context}`;
        }, "");

        data.append("context", newContext);
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data"
            },
            data: data
        })
            .then(res => {
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_TEXT_GENERATE,
                        apiResponse: res.data.data
                    });
                    dispatch({ type: Constants.Loader1, payload: false });
                } else {
                    dispatch({
                        type: Constants.FAILED_TEXT_GENERATE,
                        apiResponse: res.data.data
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: Constants.FAILED_TEXT_GENERATE,
                    apiResponse: "Error Case"
                });
            });
        //dispatch({ type: "EMAIL_SENT_SUCCESS" });
    }
};
