import * as Constants from "../common/Constants"

const INIT_STATE = {
    importTranslationsLoader: false,
    openFullScreenLoader: false,
    uploadedTranslationFilePath:null,
    uploadedTranslationFileRowCount:0,
    uploadedTranslationFileImportPercentage:0,
    uploadTranslationFilePercentage:0,
    translationFileUploadSuccess:false,
    translationImportSuccess:false,
    importTranslationsApiResponse:null,
    totalTranslationUpdateCount:0,
    englishUpdateCount:0,
    dutchUpdateCount:0,
    totalTranslationInsertCount:0,
    englishInsertCount:0,
    dutchInsertCount:0,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case Constants.REQUEST_UPLOAD_IMPORT_TRANSLATION_FILE:{
            return {
                ...state,
                translationFileUploadSuccess: false,
                uploadedTranslationFilePath:null,
                uploadedTranslationFileRowCount:0,
                uploadTranslationFilePercentage:30
            }
        }
        case Constants.SUCCESS_UPLOAD_IMPORT_TRANSLATION_FILE:{
            return {
                ...state,
                translationFileUploadSuccess: true,
                uploadedTranslationFilePath:action.path,
                uploadedTranslationFileRowCount:action.totalCount,
                uploadTranslationFilePercentage:100
            }
        }
        case Constants.FAILED_UPLOAD_IMPORT_TRANSLATION_FILE:{
            return {
                ...state,
                translationFileUploadSuccess: false,
                uploadedTranslationFilePath:null,
                uploadedTranslationFileRowCount:0,
            }
        }
        case Constants.REQUEST_IMPORT_UPLOADED_TRANSLATION_FILE:{
            return {
                ...state,
                translationImportSuccess:false,
                uploadedTranslationFileImportPercentage: 0
            }
        }
        case Constants.SUCCESS_IMPORT_UPLOADED_TRANSLATION_FILE:{
            return {
                ...state,
                uploadedTranslationFileImportPercentage: action.percentage,
                translationImportSuccess:action.finish,
                totalTranslationUpdateCount:action.totalUpdate,
                englishUpdateCount:action.englishUpdate,
                dutchUpdateCount:action.dutchUpdate,
                totalTranslationInsertCount:action.totalInsert,
                englishInsertCount:action.englishInsert,
                dutchInsertCount:action.dutchInsert,
                importTranslationsApiResponse: action.apiResponse


            }
        }
        case Constants.RESET_IMPORT_TRANSLATION_API_RESPONSE:{
            return {
                ...state,
                importTranslationsApiResponse: null
            }
        }



        default: {
            return state;
        }
    }
};