import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import {getResponseForNotificaion} from "../common/functions/EproCommonFunctions";


import {
  FETCH_TABLE,
  FETCH_TABLE_SUCCESS,
  FETCH_TABLE_COLUMN_SEARCH
} from "constants/ActionTypes";
import { getAuthData } from "../common/helpers/AuthHelper";

export const fetchTableData = params => {
  return {
    type: FETCH_TABLE,
    payload: params
  };
};

export const fetchTableDataSuccess = data => {
  return {
    type: FETCH_TABLE_SUCCESS,
    payload: data
  };
};

export const fetchTableColumnSearch = params => {
  return {
    type: FETCH_TABLE_COLUMN_SEARCH,
    payload: params
  };
};

export const actionCreators = {
  getTableData:(tableName,search,itemPerPage,pageNumber,multipleSearchText,accountId,itemId)=>async (dispatch,getState)=>{
    dispatch({type:Constants.REQUEST_GET_TABLE_DATA})
    let url = config.api.url+"api/"+tableName+"/listview"
    let {token} = getAuthData()

    let requestData = {
      item_per_page:itemPerPage,
      page_no:pageNumber-1,
      search: search,
      order_by_field: "",
      order_direction: "",
      table_sort_field: "",
      table_sort_direction: "",
      multi_search_fields: multipleSearchText,
      current_account_id:accountId
    }
    if(tableName === "relation"){
      requestData.group_id = itemId
    }
    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: requestData,
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if(res.data.status === "success"){
            dispatch({
              type:Constants.SUCCESS_GET_TABLE_DATA,
              rows:res.data.data,
              columns:res.data.listing_field,
              allSavedTemplate:res.data.all_saved_template,
              totalItem:res.data.total_item,
              data:res.data,
             // apiResponse:apiResponse
            })
          }else {
            if(res.data.status_code === "403"){
              dispatch({type:Constants.FAILED_GET_TABLE_DATA,status:"403"})
            }
          }
        })
        .catch((e) => {
          let aaaa = e;
        });
  },
  getTableRowById:(table,id)=>async (dispatch,getState) =>{
    dispatch({ type: Constants.REQUEST_GET_TABLE_ROW_BY_ID });
    let apiUrl = config.api.url;
    let url = apiUrl + "api/"+table+"/ListingEdit/"+id;
    let {token} = getAuthData()

    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
        .then((res) => {
          if(res.data.status === "success"){
            dispatch({
              type:Constants.SUCCESS_GET_TABLE_ROW_BY_ID,
              rowFields: res.data.data,
              permissions:res.data.add_delete_permissions,
              positionData: res.data.position_data
            })
          }
        })
        .catch((e) => {
          let aaaa = e;
        });
  },
  getTableRows:(tableName,search,itemPerPage,pageNumber,multipleSearchText,accountId,itemId)=>async (dispatch,getState)=>{
    dispatch({type:Constants.REQUEST_GET_TABLE_ROWS})
    let url = config.api.url+"api/"+tableName+"/listview"
    let {token} = getAuthData()

    let requestData = {
      item_per_page:itemPerPage,
      page_no:pageNumber-1,
      search: search,
      order_by_field: "",
      order_direction: "",
      table_sort_field: "",
      table_sort_direction: "",
      multi_search_fields: multipleSearchText,
      current_account_id:accountId
    }

    if(tableName === "relation"){
      requestData.group_id = itemId
    }
    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: requestData,
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if(res.data.status === "success"){
            dispatch({
              type:Constants.SUCCESS_GET_TABLE_ROWS,
              rows:res.data.data,
              totalItem: res.data.total_item,
              apiResponse:apiResponse
            })
          }else{
            // if(res.data.statusCode === "403"){
            //   dispatch({type:Constants.FAILED_GET_TABLE_DATA,apiResponse:apiResponse})
            // }
          }
        })
        .catch((e) => {
          let aaaa = e;
        });
  },
  postOrderView:(tableName,search,itemPerPage,pageNumber,multipleSearchText,fields,sort)=>async (dispatch,getState) =>{
    //dispatch({type:Constants.REQUEST_GET_TABLE_ROWS})
    let url = config.api.url+"api/"+tableName+"/add_order_view"
    let {token} = getAuthData()

    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: {
        fields:fields,
        short:sort
      },
    })
        .then((res) => {
          dispatch({type:Constants.REQUEST_GET_TABLE_DATA})
          let url = config.api.url+"api/"+tableName+"/listview"
          axios({
            method: "POST",
            url: url,
            headers: {
              Authorization: token,
            },
            data: {
              item_per_page:itemPerPage,
              page_no:pageNumber-1,
              search: search,
              order_by_field: "",
              order_direction: "",
              table_sort_field: "",
              table_sort_direction: "",
              multi_search_fields: multipleSearchText
            },
          })
              .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                  dispatch({
                    type:Constants.SUCCESS_GET_TABLE_DATA,
                    rows:res.data.data,
                    columns:res.data.listing_field,
                    allSavedTemplate:res.data.all_saved_template,
                    totalItem:res.data.total_item,
                    data:res.data,
                    //apiResponse:apiResponse
                  })
                }else{
                  if(res.data.status_code === "403"){
                    dispatch({type:Constants.FAILED_GET_TABLE_DATA,status:"403"})
                  }
                }

              })
              .catch((e) => {
                let aaaa = e;
              });


        })
        .catch((e) => {
          let aaaa = e;
        });
  },
  getAllAccounts:()=>async (dispatch,getState)=>{
    dispatch({type:Constants.REQUEST_GET_ALL_ACCOUNT})
    let url = config.api.url+"api/accounts/getOtherAccounts"
    let {token} = getAuthData()

    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
        .then((res) => {
          if(res.data.status === "success"){
            dispatch({
              type:Constants.SUCCESS_GET_ALL_ACCOUNT,
              accounts:res.data.data
              // apiResponse:apiResponse
            })
          }else {
            //let apiResponse = getResponseForNotificaion(res)
            dispatch({type:Constants.FAILED_GET_ALL_ACCOUNT})
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({type:Constants.FAILED_GET_ALL_ACCOUNT,apiResponse:errorResponse})
        });
  },
  deleteRows:(tableName,ids) => async(dispatch,getState) =>{
    let url = `${config.api.url }api/${tableName}/itemDelete`
    dispatch({ type: Constants.REQUEST_DELETE_TABLE_ROWS });
    let {token} = getAuthData()
    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { ids: ids, hardDelete: "0"},
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {
            
          dispatch({ type: Constants.SUCCESS_DELETE_TABLE_ROWS, apiResponse:apiResponse });
          } else {
            dispatch({ type: Constants.FAILED_DELETE_TABLE_ROWS, apiResponse:apiResponse});
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({ type: Constants.FAILED_DELETE_TABLE_ROWS, apiResponse:errorResponse});
        });

  },
  resetResponse:() => async (dispatch,getState) =>{
    dispatch({type:Constants.RESET_LISTING_API_RESPONSE})
  }
}
