

//Campaign
export const CAMPAIGN_SHARE_SETTINGS_KEY = "#ECLICKSPRO_CAMPAIGN_SHARE_ICON_EDIT_BY_USER_ROLE"
export const CAMPAIGN_REGISTER_ON_SETTINGS_KEY = "#ECLICKSPRO_CAMPAIGN_FORM_REGISTER_ON_USER_ROLL"

// CommonListView
export const TENANT_SWITCH_PERMISSION = "#ECLICKS_PRO_TENANT_SWITCHER_DROPDOWN_ENABLE_FOR_USER_GROUP"
export const LISTING_FIELD_ELLIPSIS_LENGTH = "#LISTING_FIELD_ELLIPSIS_LENGTH"

// Campaign Card settings keys
export const CAMPAIGN_CARD_TITLE_CHAR_COUNT = "#ECLICKSPRO_CAMPAIGN_CARD_TITLE_CHAR_COUNT";
