import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_TABLE } from "constants/ActionTypes";
import { fetchTableDataSuccess } from "actions/Table";

import config from "../config";

// Api Functions
const fetchTableData = async params => {
  // console.log("params from saga->", params);
  const {
    token,
    value,
    search,
    itemPerPage,
    pageNumber,
    multipleSearchText,

  } = params;
  const response = await fetch(`${config.api.url}api/${value}/listview`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      item_per_page: itemPerPage,
      page_no: pageNumber - 1,
      search: search,
      order_by_field: "",
      order_direction: "",
      table_sort_field: "",
      table_sort_direction: "",
      multi_search_fields: multipleSearchText
    })
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  // console.log("response ->", response);
  return response;
};

// Saga Functions
function* getFetchTableData({ payload }) {
  try {
    const getTableData = yield call(fetchTableData, payload);
    yield put(fetchTableDataSuccess(getTableData));
  } catch (e) {
  }
}

// exporting the saga function
export function* getTableData() {
  yield takeEvery(FETCH_TABLE, getFetchTableData);
}

export default function* rootSaga() {
  yield all([fork(getTableData)]);
}
