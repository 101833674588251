import {
  FETCH_FILTER,
  FETCH_FILTER_SUCCESS,
  FETCH_FILTER_BY_ID_SUCCESS,
  FETCH_FILTER_BY_ID,
} from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: true,
  filters: null,
  filter:null,//this is created by me for replace filterById
  filterParams: null,
  filterColumns:[],
  filterById: null,
  filterByIdParams: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FILTER: {
      return {
        ...state,
        loader: true,
        filterParams: action.payload,
      };
    }
    case FETCH_FILTER_SUCCESS: {
      return {
        ...state,
        loader: false,
        filters: action.payload,
      };
    }
    case FETCH_FILTER_BY_ID: {
      return {
        ...state,
        loader: true,
        filterByIdParams: action.payload,
      };
    }
    case FETCH_FILTER_BY_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        filterById: action.payload,
      };
    }
    case Constants.REQUEST_GET_FILTER_BY_ID:{
      return {
        ...state,
        loader: true,
        filter: null,
        filterColumns: []
      }
    }

    case Constants.SUCCESS_GET_FILTER_BY_ID:{
      return {
        ...state,
        loader: false,
        filter: action.filter,
        filterColumns: action.columns
      }
    }
    case Constants.FAILED_GET_FILTER_BY_ID:{
      return {
        ...state,
        loader: false,
        filter: null,
        filterColumns: []
      }
    }
    default:
      return state;
  }
};
