import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_SMS, FETCH_SMS_FOLDER } from "constants/ActionTypes";
import { fetchSmsSuccess, fetchSmsFolderSuccess } from "actions/Sms";

import config from "../config";

// Sms
const fetchSmsData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/sms?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchSmsData({ payload }) {
  try {
    const getSmsData = yield call(fetchSmsData, payload);
    yield put(fetchSmsSuccess(getSmsData));
  } catch (e) {
    console.error(e);
  }
}

export function* getSmsData() {
  yield takeEvery(FETCH_SMS, getFetchSmsData);
}

// Folder
const fetchFolderData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/sms/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFolderData({ payload }) {
  try {
    const getFolderData = yield call(fetchFolderData, payload);
    yield put(fetchSmsFolderSuccess(getFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFolderData() {
  yield takeEvery(FETCH_SMS_FOLDER, getFetchFolderData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getSmsData), fork(getFolderData)]);
}
