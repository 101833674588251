import * as Constants from "../common/Constants"

const INIT_STATE = {
    acInfoBtnclickSave: false,
    acInfoBtnClickAdd:false,
    acInfoBtnClickDelete:false,
    acInfoBtnclickSaveAndNext:false,
    acInfoBtnclickSaveAndClose:false,
    disableAcInfoBtnInEditView:true,
    users:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "CHANGE_ACINFO_BTN_STATUS":{
            return {
                ...state,
                disableAcInfoBtnInEditView:false
            }
        }
        case "CHANGE_ACINFO_BTN_STATUS_DISABLE":{
            return {
                ...state,
                disableAcInfoBtnInEditView:true
            }
        }
        case "REQUEST_SAVE": {
            return {
                ...state,
                acInfoBtnclickSave: true
            };
        }
        case "REQUEST_ACINFO_SAVE_AND_NEXT":{
            return {
                ...state,
                acInfoBtnclickSaveAndNext:true

            }
        }
        case "REQUEST_ACINFO_SAVE_AND_CLOSE":{
            return {
                ...state,
                acInfoBtnclickSaveAndClose:true,

            }
        }
        case "REQUEST_ACINFO_ADD":{
            return {
                ...state,
                acInfoBtnClickAdd:true

            }
        }
        case "REQUEST_ACINFO_DELETE":{
            return {
                ...state,
                acInfoBtnClickDelete:true

            }
        }
        case "RESET_AC":
            return {
                ...state,
                acInfoBtnclickSave: false,
                acInfoBtnclickSaveAndNext:false,
                acInfoBtnclickSaveAndClose:false,
                acInfoBtnClickAdd:false,
                acInfoBtnClickDelete:false
            }
        case Constants.REQUEST_GET_ALL_USERS_BY_ACCOUNT:
            return {
                ...state,
                user:null
            }
        case Constants.SUCCESS_GET_ALL_USERS_BY_ACCOUNT:
            return {
                ...state,
                users: action.users
            }
        case Constants.FAILED_GET_ALL_USERS_BY_ACCOUNT:
            return {
                ...state,
                users: []
            }

        default:
            return state;
    }
};