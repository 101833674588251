import React, { Component } from "react";
import ReactTour from "./ReactTour";
import YouTube from 'react-youtube';
// react
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators} from "../../../../actions/Setting";
import { actionCreators as folderViewActions } from "../../../../actions/FolderViewAction";
import { withStyles } from "@material-ui/core/styles";


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid } from "@material-ui/core";
import {eclicksProColors} from "../../../../common/functions/EproCommonFunctions";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IntlMessages from "../../../../util/IntlMessages";
import "./style.css"
import CustomDialog, { dialogStyles } from "../dialog/customDialog";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";

//context
import { SettingContext } from "context/SettingContext";


const useStyles = theme => ({
    mainWrapperBox:{
        border:"1px solid #D7D7D7",
        borderRadius:"10px",
        padding:"20px"
    },
    tour:{
        height:"100px",
        width:"300px",
        border:"3px dashed"+eclicksProColors.green
    },
    mainTitle:{
        fontSize:"50px",
        color:eclicksProColors.green,
        fontWeight:"bold"
    },
});

class EclicksProTour extends Component {
    static contextType = SettingContext;
    constructor(props) {
        super(props);
        this.state = {
            showNoTourAlert:false
        }
    }
    componentDidMount() {
        this.props.getMenuTour(this.props.menu,this.props.view)
    }
    componentWillUnmount() {
        this.props.resetTourGuide()
    }

    startTour = () =>{
        if (this.props.settings.tourSteps.length === 0){
            this.handleOpenAlert()
        }else {
            this.props.startUserTour(this.props.menu,this.props.view)
        }
    }
    stopTour = () =>{
        this.props.stopUserTour()
        this.props.updateTour(this.props.menu,this.props.view)
    }
    handleClose = () =>{
        this.props.hideTour()
        this.props.updateTour(this.props.menu,this.props.view)
    }
    handleShowVideo = () =>{
        this.props.showVideo()
    }
    handleCloseVideo = () =>{
        this.props.closeTourVideo()
        this.props.updateTour(this.props.menu,this.props.view)
    }
    handleOpenAlert =() =>{
        this.setState({
            showNoTourAlert:true
        })
    }
    handleCloseAlert =() =>{
        this.setState({
            showNoTourAlert:false
        })
    }

    render() {
        const {classes} = this.props
        let settings = null

        try {
            settings = JSON.parse(this.context.settings.data);
        }catch (e) {
            settings = null
        }


        let videoSettingId = "#"+this.props.menu+"_"+this.props.view+"_TOUR_VIDEO"
        videoSettingId = videoSettingId.toUpperCase()
        let videoId = ""

        if(settings !== null){
            videoId = settings[videoSettingId]
        }

        let showStartTour = false
        let showWatchVideo = false
        const playerOption = {
            // height: '500',
            width:'900',
            height: '600'
        }
        if(this.props.settings.tourSteps.length > 0){
            showStartTour = true
        }
        // if(this.props.settings.tourVideo !== null){
        //     showWatchVideo = true
        // }
        if(videoId !== undefined){
            showWatchVideo = true
        }
        return (
            <div>
                <ReactTour
                    start={this.props.settings.startTour}
                    stop={this.stopTour}
                    steps={this.props.settings.tourSteps}
                ></ReactTour>


                <Dialog
                    fullWidth={true}
                    //fullScreen={true}
                    maxWidth={"lg"}
                    open={this.props.settings.showTour}
                    onClose={this.handleClose}
                    aria-labelledby="max-width-dialog-title"
                    // PaperProps={{
                    //     style: {
                    //         backgroundColor: 'transparent',
                    //         boxShadow: 'none',
                    //     },
                    // }}
                >
                    {/*<DialogTitle id="max-width-dialog-title">Optional sizes</DialogTitle>*/}
                    <DialogContent>
                        <Grid container justify="center">
                            <Grid item md={11}>

                            </Grid>
                            <Grid item md={1} container justify={"flex-end"}>
                                <IconButton onClick={this.handleClose}>
                                    <CloseIcon style={{color:eclicksProColors.red}}/>

                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item md={12}>
                                <Typography className={classes.mainTitle} align={"center"}>
                                    <IntlMessages id="_TOUR_GUIDE" />
                                </Typography>
                            </Grid>
                            {/*<Grid item md={1} container justify={"flex-end"}>*/}
                            {/*    */}
                            {/*</Grid>*/}
                        </Grid>
                        {/*<DialogContentText>*/}
                        {/*    You can set my maximum width and whether to adapt or not.*/}
                        {/*</DialogContentText>*/}
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.tour}>

                                </div>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item md={12}>
                                <div style={{margin:"20px 0 20px 0"}}>
                                    <Typography className={classes.tourMessage} align={"center"}>
                                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_MESSAGE" />
                                    </Typography>
                                </div>
                            </Grid>
                            {/*<Grid item md={1} container justify={"flex-end"}>*/}
                            {/*    */}
                            {/*</Grid>*/}
                        </Grid>





                    </DialogContent>
                    <DialogActions>
                        <Grid container justify="center">
                            {/*<Grid item md={4}></Grid>*/}
                            <Grid item md={12}>
                                <Grid container justify="center" spacing={3}>
                                    {showStartTour &&
                                        <Grid item>
                                            <Button variant="contained"
                                                    onClick={this.startTour}
                                                    style={{
                                                        backgroundColor:eclicksProColors.green,
                                                        height:"70px",
                                                        color:"#fff",
                                                        marginBottom:"20px"
                                                        // minWidth:"130px",
                                                        // maxWidth:"130px",
                                                    }}
                                            >
                                                <IntlMessages id="_ECLICKSPRO_START_TOUR" />
                                            </Button>
                                        </Grid>
                                    }

                                    {showWatchVideo &&
                                        <Grid item>
                                            <Button variant="contained"
                                                    onClick={this.handleShowVideo}
                                                    style={{
                                                        backgroundColor:eclicksProColors.blue1,
                                                        height:"70px",
                                                        color:"#fff",
                                                        // minWidth:"130px",
                                                        // maxWidth:"130px",
                                                    }}
                                            >
                                                <IntlMessages id="_ECLICKSPRO_WATCH_VIDEO" />
                                            </Button>
                                        </Grid>
                                    }

                                    <Grid item>
                                        <Button variant="contained"
                                                onClick={this.handleClose}
                                                style={{
                                                    backgroundColor:eclicksProColors.red,
                                                    height:"70px",
                                                    color:"#fff",
                                                    marginBottom:"20px"
                                                    // minWidth:"130px",
                                                    // maxWidth:"130px",
                                                }}
                                        >
                                            <IntlMessages id="_CLOSE" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*<Grid item md={4}></Grid>*/}
                        </Grid>
                    </DialogActions>
                </Dialog>




                <Dialog
                    fullWidth={true}
                    //fullScreen={true}
                    maxWidth={"md"}
                    open={this.props.settings.showTourVideo}
                    onClose={this.handleCloseVideo}
                    aria-labelledby="max-width-dialog-title"
                    // PaperProps={{
                    //     style: {
                    //         backgroundColor: 'transparent',
                    //         boxShadow: 'none',
                    //     },
                    // }}
                >
                    <DialogContent>

                        <Grid container justify="center">
                            <Grid item md={11}>

                            </Grid>
                            <Grid item md={1} container justify={"flex-end"}>
                                <IconButton onClick={this.handleCloseVideo}>
                                    <CloseIcon style={{color:eclicksProColors.red}}/>

                                </IconButton>
                            </Grid>
                        </Grid>

                        {/*{this.props.settings.tourVideo &&*/}
                        <Grid container justify="center">
                            {/*<Grid item md={2}></Grid>*/}
                            <Grid item md={12}>
                                {/*<YouTube videoId={this.props.settings.tourVideo.id} opts={playerOption}/>*/}
                                <YouTube videoId={videoId} opts={playerOption}/>
                            </Grid>
                            {/*<Grid item md={2}></Grid>*/}
                        </Grid>
                        {/*}*/}
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>


            {/*    no tour alert*/}
                <CustomDialog
                    open={this.state.showNoTourAlert}
                    handleClose={this.handleCloseAlert}
                    //handleSave={this.handleSaveSms}
                    dialogContent={false}
                    titleTextKey={"_ECLICKSPRO_ALERT"}
                    maxWidth={"sm"}
                    actionButtons={['close']}
                >

                    <DialogContent style={dialogStyles.dialogContentStyles}>
                        <DialogContentText>
                            <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_NOT_SET_MESSAGE" />
                        </DialogContentText>


                        {/*<Typography className={classes.tourMessage} align={"center"}>*/}
                        {/*    <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_MESSAGE" />*/}
                        {/*</Typography>*/}

                    </DialogContent>
                </CustomDialog>
            {/*    no tour alert*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings:state.settings
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyles)(EclicksProTour))