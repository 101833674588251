import React, { Component } from "react";
import { connect } from "react-redux";
import { userSignIn } from "actions/Auth";
import { fetchEmailById } from "actions/Email";
import { NotificationManager } from "react-notifications";
import config from "config";
// The editor core
import Editor from "ory-editor-core";
import "ory-editor-core/lib/index.css"; // we also want to load the stylesheets
import "ory-editor-ui/lib/index.css";

// Load some exemplary plugins:
import slate from "ory-editor-plugins-slate"; // The rich text area plugin
import "ory-editor-plugins-slate/lib/index.css"; // Stylesheets for the rich text area plugin
import background from "ory-editor-plugins-background";
import "ory-editor-plugins-background/lib/index.css";
import image from "ory-editor-plugins-image";
import "ory-editor-plugins-image/lib/index.css";
import spacer from "ory-editor-plugins-spacer";
import "ory-editor-plugins-spacer/lib/index.css";
import video from "ory-editor-plugins-video";
import "ory-editor-plugins-video/lib/index.css";
import divider from "ory-editor-plugins-divider";
import "ory-editor-plugins-divider/lib/index.css";
import { HTMLRenderer } from "ory-editor-renderer";
//require("react-tap-event-plugin")(); // react-tap-event-plugin is required by material-ui which is used by ory-editor-ui so we need to call it here

// const content = require("../email/seed.json");

// Define which plugins we want to use. We only have slate and parallax available, so load those.
const plugins = {
  content: [slate(), image, spacer, video, divider], // Define plugins for content cells. To import multiple plugins, use [slate(), image, spacer, divider]
  layout: [background({ defaultPlugin: slate() })] // Define plugins for layout cells
};

// const editor = new Editor({
//   plugins,
//   // pass the content state - you can add multiple editables here
//   editables: [content]
// });

class Preview extends Component {
  state = {
    emailId:
      this.props.location &&
      new URLSearchParams(this.props.location.search).get("id")
  };
  componentWillMount() {
    const params = {
      id: this.state.emailId,
      token: this.props.authUser
    };
    this.props.fetchEmailById(params);
  }

  render() {
    const { emailData, loader } = this.props;

    const data = emailData && JSON.parse(emailData.data);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          overflowY: "scroll"
        }}
      >
        {!loader && (
          <div
            style={{
              display: "table",
              width: "700px",
              border: "1px solid #e0e0e0",
              margin: "5px auto"
            }}
          >
            <HTMLRenderer state={data && data.content} plugins={plugins} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, email }) => {
  const { authUser } = auth;
  const { loader, emailData } = email;

  return { authUser, loader, emailData };
};

export default connect(mapStateToProps, {
  userSignIn,
  fetchEmailById
})(Preview);
