import IntlMessages from "../../util/IntlMessages";
import React from "react";
import {eclicksProColors} from "./EproCommonFunctions";

const tourGuideStyles = {
    popupWrapper:{
        margin:"15px 30px 15px 5px"
    },
    popupStyle:{
        backgrounColor: "#4CAF50",
        // color:"#fff"
    }
}

export const tourSettings=[
    {
        menu:"home",
        view:"list",
        steps:[
            {
                selector: '.home-graph',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_HOME_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.email-card',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_HOME_LIST_2" />
                    </div>
                ),
            },
            {
                selector: '.MuiTouchRipple-root',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_HOME_LIST_3" />
                    </div>
                ),
                style:tourGuideStyles.popupStyle
            },

        ],
        video:{
            id:"Oxv6IRcuNaI",
            url:""
        }
    },
    {
        menu:"campaign",
        view:"template",
        steps:[
            {
                selector: '.campaign-template-button-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_CAMPAIGN_TEMPLATE_1" />
                    </div>
                ),
            },
            {
                selector: '.template-card-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_CAMPAIGN_TEMPLATE_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"campaign",
        view:"list",
        steps:[
            {
                selector: '.toolbar-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_CAMPAIGN_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.folder-view-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_CAMPAIGN_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"campaign",
        view:"edit",
        steps:[],
        video:{
            id:"Oxv6IRcuNaI",
            url:""
        }
    },
    {
        menu:"form",
        view:"edit",
        steps:[],
        video:{
            id:"Oxv6IRcuNaI",
            url:""
        }
    },
    {
        menu:"form",
        view:"list",
        steps:[
            {
                selector: '.toolbar-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_FORM_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.folder-view-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_FORM_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"page",
        view:"list",
        steps:[
            {
                selector: '.toolbar-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_PAGE_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.folder-view-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_PAGE_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"email",
        view:"list",
        steps:[
            {
                selector: '.toolbar-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_EMAIL_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.folder-view-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_EMAIL_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"group",
        view:"list",
        steps:[
            {
                selector: '.toolbar-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_GROUP_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.folder-view-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_GROUP_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"relation",
        view:"list",
        steps:[
            {
                selector: '.table-responsive-material',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_GROUP_LIST_1" />
                    </div>
                ),
            },
            {
                selector: '.template-area-for-tg',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_RELATION_LIST_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"relation",
        view:"edit",
        steps:[
            {
                selector: '.account-info-wrapper',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_RELATION_EDIT_2" />
                    </div>
                ),
            }

        ],
        video:null
    },
    {
        menu:"filter",
        view:"list",
        steps:[
            {
                selector: '.table-responsive-material',
                content: () =>(
                    <div style={tourGuideStyles.popupWrapper}>
                        <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_FILTER_LIST_1" />
                    </div>
                ),
            },
            // {
            //     selector: '.template-area-for-tg',
            //     content: () =>(
            //         <div style={tourGuideStyles.popupWrapper}>
            //             <IntlMessages id="_ECLICKSPRO_TOUR_GUIDE_FILTER_LIST_2" />
            //         </div>
            //     ),
            // }

        ],
        video:null
    }

]