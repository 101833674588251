import React from "react";
import { connect } from "react-redux";
import { userSignIn } from "actions/Auth";
import { fetchRelationGroup, fetchRelationGroupFolder } from "actions/Relation";
// import IntlMessages from "util/IntlMessages";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";

let selectedGroup = [];

class SelectGroup extends React.Component {
  state = {
    folderId: "root",
    open: false,
    type: null,
    value: "",
    tempId: null,
    anchorEl: null,
    openSave: false,
  };

  componentWillMount() {
    const params = {
      id: this.state.folderId,
      token: this.props.authUser,
    };
    this.props.fetchRelationGroup(params);
    this.props.fetchRelationGroupFolder(params);
  }

  handleOnClickGroup = (id, name) => (e) => {
    this.setState({ value: name, type: "group", tempId: id });
    if (selectedGroup.includes(id)) {
      document.getElementById(`${id}`).style.backgroundColor = null;
      selectedGroup = selectedGroup.filter((old) => old !== id);
    } else {
      selectedGroup.push(id);
      selectedGroup.forEach(() => {
        document.getElementById(`${id}`).style.backgroundColor = "#c8e6c9";
      });
    }
    this.props.getSelectedItem(selectedGroup);
  };

  getFolderItems = (id) => (e) => {
    this.setState({ folderId: id });
    setTimeout(() => {
      const params = {
        id: this.state.folderId,
        token: this.props.authUser,
      };
      this.props.fetchRelationGroup(params);
      this.props.fetchRelationGroupFolder(params);
    }, 500);
  };
  render() {
    const { match, loader, folder, relationGroups, history } = this.props;
    // const settings = JSON.parse(this.context.data);

    // console.log("this.props from select group ----> ", this.props);
    // console.log("selectedGroup ------->", selectedGroup);
    return (
      <div style={{ width: "500px", minHeight: "450px" }}>
        {(loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )) || (
          <div className="row">
            {this.state.folderId !== "root" && (
              <div
                // id={history.location.state.prevId}
                className="col-6"
              >
                <List
                  // id={history.location.state.prevId}
                  onClick={this.getFolderItems("root")}
                >
                  <ListItem
                    button
                    // id={history.location.state.prevId}
                  >
                    <ListItemAvatar
                    // id={history.location.state.prevId}
                    >
                      <Avatar
                        // id={history.location.state.prevId}
                        style={{ backgroundColor: "#43a047" }}
                      >
                        <i
                          // id={history.location.state.prevId}
                          className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      // id={history.location.state.prevId}
                      primary=". . ."
                    />
                  </ListItem>
                </List>
              </div>
            )}

            {folder &&
              folder.data.items.map((item, index) => {
                return (
                  <div className="col-6" id={item.id} key={index}>
                    <List
                      id={item.id}
                      // onDoubleClick={() =>
                      //   history.push(`/app/custom-views/group/${item.id}`, {
                      //     name: item.name,
                      //     prevId: "root",
                      //   })
                      // }
                      //   onClick={this.handleOnClickFolder(item.id, item.name)}
                      onClick={this.getFolderItems(item.id)}
                    >
                      <ListItem button id={item.id}>
                        <ListItemAvatar id={item.id}>
                          <Avatar
                            style={{ backgroundColor: "#43a047" }}
                            id={item.id}
                          >
                            <i className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          id={item.id}
                          primary={item.name}
                          //   primary={
                          //     item.name.length >
                          //     settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
                          //       ? item.name.slice(
                          //           0,
                          //           settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
                          //         ) + "..."
                          //       : item.name
                          //   }
                          secondary={moment
                            .unix(item.created_at)
                            .format("MMM Do, YYYY")}
                        />
                      </ListItem>
                    </List>
                  </div>
                );
              })}

            {relationGroups &&
              relationGroups.data.items.map((item, index) => {
                return (
                  <div className="col-6" id={item.id} key={index}>
                    <List
                      id={item.id}
                      onClick={this.handleOnClickGroup(item.id, item.name)}
                    >
                      <ListItem button id={item.id}>
                        <ListItemAvatar id={item.id}>
                          <Avatar
                            style={{ backgroundColor: "#ff9800" }}
                            id={item.id}
                          >
                            <i className="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-lg" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          id={item.id}
                          primary={item.name}
                          //   primary={
                          //     item.name.length >
                          //     settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
                          //       ? item.name.slice(
                          //           0,
                          //           settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
                          //         ) + "..."
                          //       : item.name
                          //   }
                          secondary={`Relation Count: ${
                            item.relation_count ? item.relation_count : 0
                          }`}
                        />
                      </ListItem>
                    </List>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, relation }) => {
  const { authUser } = auth;
  const { loader, relationGroups, folder } = relation;

  return { authUser, loader, relationGroups, folder };
};

export default connect(mapStateToProps, {
  userSignIn,
  fetchRelationGroup,
  fetchRelationGroupFolder,
})(SelectGroup);
