import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import IntlMessages from "../../../../../util/IntlMessages";
import { dialogStyles } from "../../../customComponents/dialog/customDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import CustomButton from "../../../customComponents/button/CustomButton";




function OrderForm(props) {
    const {info} = props

    const [companyName,setCompanyName] = useState("")
    const [notes,setNotes] = useState("")
    const [addressLineOne,setAddressLineOne] = useState("")
    const [addressLineTwo,setAddressLineTwo] = useState("")
    const [zipcode,setZipcode] = useState("")
    const [city,setCity] = useState("")
    const [country,setCountry] = useState("")

    useEffect(() =>{
        if(info){
            setCompanyName(info.company_name)
            setNotes(info.notes)
            setAddressLineOne(info.address_line_one)
            setAddressLineTwo(info.address_line_two)
            setZipcode(info.zipcode)
            setCity(info.city)
            setCountry(info.country)
        }
    },[props.info])

    const handleClose = () =>{
        debugger
        props.handleClose()
    }

    const handleSave = () =>{
        props.handleSave(companyName,addressLineOne,addressLineTwo,zipcode,city,country,notes,"light")
    }
    const handleChange = (event) =>{
        debugger
        switch (event.target.id) {
            case "companyName":
                setCompanyName(event.target.value)
                break;
            case "notes":
                setNotes(event.target.value)
                break;
            case "addressLineOne":
                setAddressLineOne(event.target.value)
                break;
            case "addressLineTwo":
                setAddressLineTwo(event.target.value)
                break;
            case "zipcode":
                setZipcode(event.target.value)
                break;
            case "city":
                setCity(event.target.value)
                break;
            case "country":
                setCountry(event.target.value)
                break;
            default:
                break;
        }
    }

    return(
        <React.Fragment>
            <DialogContent style={dialogStyles.dialogContentStyles}>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="companyName"
                            label={<IntlMessages id="_ECLICKSPRO_COMPANY_NAME" /> }
                            type="text"
                            value={companyName}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="notes"
                            label={<IntlMessages id="_ECLICKSPRO_NOTE" /> }
                            type="text"
                            value={notes}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="addressLineOne"
                            label={<IntlMessages id="_ECLICKSPRO_ADDRESS_LINE_ONE" /> }
                            type="text"
                            value={addressLineOne}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="addressLineTwo"
                            label={<IntlMessages id="_ECLICKSPRO_ADDRESS_LINE_TWO" /> }
                            type="text"
                            value={addressLineTwo}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="zipcode"
                            label={<IntlMessages id="_ECLICKSPRO_ZIPCODE" /> }
                            type="text"
                            value={zipcode}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="city"
                            label={<IntlMessages id="_ECLICKSPRO_CITY" /> }
                            type="text"
                            value={city}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="country"
                            label={<IntlMessages id="_ECLICKSPRO_COUNTRY" /> }
                            type="text"
                            value={country}
                            fullWidth
                            // error={this.state.mollieApiKey === ""}
                            // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={dialogStyles.dialogActionStyles}>
                <CustomButton color="grey" onClick={handleClose}>
                    <IntlMessages id="_CLOSE" ></IntlMessages>
                </CustomButton>
                <CustomButton color="green" onClick={handleSave}>
                    <IntlMessages id="_SAVE"></IntlMessages>
                </CustomButton>
            </DialogActions>
        </React.Fragment>
    )
}
export default OrderForm