import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

class CustomLoaderDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Dialog
                open={this.props.open}
                //style={{ height: "500px", width: "500px" }}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
            >
                <DialogContent>
                    <CircularProgress />
                </DialogContent>

            </Dialog>
        );
    }
}

export default CustomLoaderDialog;