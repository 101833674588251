import {
    FETCH_EMAIL_RELATION,
    FETCH_EMAIL_RELATION_SUCCESS
  } from "constants/ActionTypes";
  
  const INIT_STATE = {
    loader: true,
    emailRelations: null,
    emailRelationParams: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_EMAIL_RELATION: {
        return {
          ...state,
          loader: true,
          emailRelationParams: action.payload
        };
      }
      case FETCH_EMAIL_RELATION_SUCCESS: {
        return {
          ...state,
          loader: false,
          emailRelations: action.payload
        };
      }
      default:
        return state;
    }
  };
  