import React, { useState } from "react";
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DynamicIcons from "components/DynamicIcons/DynamicIcons";
import "./App.css";
import Grid from '@material-ui/core/Grid';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"

function Field(props) {
  const drop = e => {
    e.preventDefault();
    let tempSelectedField = [];
    let orderingValue = [];
    const itemId = e.dataTransfer.getData("item_id");

    const item = document.getElementById(itemId);
    item.style.display = "block";

    if (
      !(e.target.id === "field-1" || e.target.id === "field-2") &&
      e.target.nextSibling
    ) {
      // putting the item in between the the other items
      if (e.target.parentNode.id === "flexbox") {
        e.target.insertBefore(item, e.target.nextSibling);
      } else if (e.target.id === "beforeItem") {
        const parent = document.getElementById("field-2");
        parent.insertBefore(item, e.target.parentNode);
      } else {
        e.target.parentNode.insertBefore(item, e.target.nextSibling);
      }
    } else if (!e.target.nextSibling) {
      if (!(e.target.parentNode.id === "flexbox")) {
        e.target.parentNode.appendChild(item);
      } else {
        e.target.appendChild(item);
      }
      // if(e.target.parentNode.id === 'flexbox')
    } else if (
      e.target.nextSibling.id === "field-1" ||
      e.target.nextSibling.id === "field-2"
    ) {
      e.target.appendChild(item);
    }

    let children = document.getElementById("field-2").childNodes;
    children.forEach(child => {
      if (!(child.nodeName === "H3")) {
        // console.log("child ordeing ->", child.childNodes[2].childNodes[0].id);
        orderingValue.push(child.childNodes[2].childNodes[0].id);
        tempSelectedField.push(child.textContent);
        // props.getSort(child.textContent, orderingValue);
      }
    });

    props.getFields(tempSelectedField);
    props.setSort(orderingValue);
  };

  const dragOver = e => {
    e.preventDefault();
  };

  return (
    <div
      id={props.id}
      className={props.className}
      onDrop={drop}
      onDragOver={dragOver}
    >
      {props.children}
    </div>
  );
}

function Item(props) {
  // console.log('props from DND ->', props.icon);
  const [icon, setIcon] = useState(props.icon || "none");
  const dragStart = e => {
    const target = e.target;

    e.dataTransfer.setData("item_id", target.id);

    // setTimeout(() => {
    //     target.style.display = "none";
    // }, 0);
  };

  const dragOver = e => {
    // e.stopPropagation();
  };

  const handleOrdering = e => {
    if (e.target.id === "none") {
      setIcon("asc");
      props.getSort(e.target.parentNode.id, "asc");
    }
    if (e.target.id === "asc") {
      setIcon("desc");
      props.getSort(e.target.parentNode.id, "desc");
    }
    if (e.target.id === "desc") {
      setIcon("none");
      props.getSort(e.target.parentNode.id, "none");
    }
  };

  return (
    <div
      id={props.id}
      className={props.className}
      draggable={props.draggable}
      onDragStart={dragStart}
      onDragOver={dragOver}
      style={
        props.available
          ? { backgroundColor: "#F2E8BB",color:"#555555" }
          : { backgroundColor: "#80C683",color:"#F2F2F2" }
      }
    >
      <div id="beforeItem" style={{ height: "25px", marginTop: "-25px" }}></div>
      {props.children}
      <span style={{ float: "right",color:props.available?"#7F7F7F":"#F2F2F2" }} onClick={handleOrdering} id={props.name}>
        <DynamicIcons id={icon} />
      </span>
    </div>
  );
}

export default class DragNDrop extends React.Component {
  state = {
    sort: null
  };

  getFields = field => {
    this.props.getFields(field);
  };

  getSort = (name, value) => {
    const { fields } = this.props;
    this.setState(state => ({
      sort: {
        ...state.sort,
        [name]: value
      }
    }));

    setTimeout(() => {
      let tempSort = [];
      fields.map(field => {
        if (this.state.sort[field]) {
          if (this.state.sort[field] === "none") {
            tempSort.push("0");
          } else {
            tempSort.push(this.state.sort[field]);
          }
        } else {
          tempSort.push("0");
        }
      });
      this.props.getSort(tempSort);
    }, 50);
  };

  render() {
    const { availableFields, selectedFields, customOrderingField } = this.props;

    // console.log("custom ordering -> ", customOrderingField);
    // console.log("this.state.sort ->", this.state.sort);
    return (
      <div className="App">
        <div id="flexbox" className="flexbox">
          <Field
            id="field-1"
            className="field"
            getFields={this.getFields}
            setSort={this.props.getSort}
          >
            <h3>Availavle Items</h3>
            {availableFields &&
              availableFields.map((item, i) => {
                return (
                  <Item
                    name={item}
                    id={`available-${i}`}
                    className="item"
                    draggable={true}
                    available
                    getSort={this.getSort}
                  >
                    {item}
                  </Item>
                );
              })}
          </Field>
          <Field
            id="field-2"
            className="field"
            getFields={this.getFields}
            setSort={this.props.getSort}
          >
            <h3>Selected Items</h3>
            {selectedFields.map((item, i) => {
              return (
                <Item
                  name={item}
                  id={`selected-${i}`}
                  className="item"
                  draggable={true}
                  icon={customOrderingField[i]}
                  getSort={this.getSort}
                >
                  {item}
                </Item>
              );
            })}
          </Field>
        </div>
        <Grid container>
          <Grid item container justify="center">
            <div>
              Click
              <FiberManualRecordIcon style={{fontSize:"18px",marginTop:"-2px",color:"#D3D3D3"}}/>
              <ArrowUpwardIcon style={{fontSize:"15px",marginTop:"-2px",fontWeight:"bold",color:"#2A8DF0"}}/>
              <ArrowDownwardIcon style={{fontSize:"15px",marginTop:"-2px",fontWeight:"bold",color:"#2A8DF0"}}/>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
