import React from "react";

//material ui
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

//custom
import CustomIcon from "../../../customComponents/icon/CustomIcon";
import { eclicksProColors } from "../../../../../common/functions/EproCommonFunctions";
import IntlMessages from "../../../../../util/IntlMessages";


const useStyle = makeStyles({
    packageAddFormWrapper:{
        margin:"0px 15px 0px 15px"
    },
    packageAddForm:{
        // margin:"0px 15px 0px 15px"
    },
    formHeader:{
        // padding:"10px"
    },
    formHeaderTitle:{
        margin:"10px 0px 0px 10px"
    },
    packageInfoCard:{
        padding:"15px",
        borderRadius:"5px"
    },
    packageInfoBody:{
        padding:"15px 20px 20px 20px",
        // margin:"0px 0px 20px 0px"
    },
    packageInfoAppBar:{
        color:"#ffffff"
    },
    appBarTitle:{
        color:"#ffffff",
        fontSize:"20px",
        fontWeight:"bold",
        textAlign:"center",
        marginTop:"2px",
        textTransform:"capitalize"
    }
})

const PackageAddFormPaper = withStyles({
    root:{
        backgroundColor:"#F6F9F6",
        border:`1px solid ${eclicksProColors.green}`
    }
})(Paper)


function PackageAddForm(props) {
    const classes = useStyle()
    const {packageInfo,invalidCards} = props

    function handleChange(event){
        let packageId = event.target.id.split("_")[1];
        // let packageDuration = event.target.id.split("-")[2];
        let packageType = event.target.id.split("_")[2].replaceAll("X","_");

        let value = event.target.value;
        props.handlePackages(packageId, packageType, value)
    }

    function handleDelete(event){
        let packageId = event.target.id.split("_")[1];
        if(packageId){
            props.handleDelete(packageId)
        }
    }
    return(
        <Grid item md={2} sm={12} lg={2} xs={12}>
            <div className={classes.packageAddFormWrapper}>
                <PackageAddFormPaper elevation={3}>
                    <div className={classes.packageAddForm}>
                        <div className={classes.formHeader}>
                            <Grid container>
                                <Grid item md={8} sm={8} xs={8} lg={8}>
                                    <div className={classes.formHeaderTitle}>
                                        {invalidCards.find(a => a === packageInfo.id.toString()) ?
                                            <TextField id={`contact_${packageInfo.id}_contact`} error={true} fullWidth={true} onChange={handleChange} value={packageInfo.contacts} label={"Contacts"}/>
                                            :
                                            <TextField id={`contact_${packageInfo.id}_contact`} fullWidth={true} onChange={handleChange} value={packageInfo.contacts} label={"Contacts"}/>
                                        }

                                    </div>
                                </Grid>
                                <Grid item container justify={"flex-end"} md={4} sm={4} xs={4} lg={4}>
                                    <div id={`contact-${packageInfo.id}-del-dv`} style={{margin:"8px 2px 0 0"}}>
                                        <IconButton id={`contact_${packageInfo.id}_del_btn`} onClick={handleDelete}>
                                            <CustomIcon id={`contact_${packageInfo.id}_del_icon`} name={"CloseIcon"} color={eclicksProColors.red}></CustomIcon>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{margin:"10px 0 2px 0"}}>
                            <Divider/>
                        </div>

                        <div>
                            <div className={classes.packageInfoCard}>
                                <Paper>
                                    <div className={classes.packageInfoAppBar}>
                                        <AppBar position={"sticky"} style={{height:"40px",borderTopRightRadius:"5px",borderTopLeftRadius:"5px"}}>
                                            <div className={classes.appBarTitle}>
                                                {/*<p>{"Light"}</p>*/}
                                                <p><IntlMessages id={`_ECLICKSPRO_LIGHT_PACK_NAME`} /></p>
                                            </div>
                                        </AppBar>
                                    </div>
                                    <div className={classes.packageInfoBody}>
                                        {/*<TextField fullWidth={true} label={"monthly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"yearly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"Up to contacts"}/>*/}

                                        <TextField id={`contact_${packageInfo.id}_lightXmonthly`} onChange={handleChange} fullWidth={true} value={packageInfo.light_monthly} label={"monthly"}/>
                                        <TextField id={`contact_${packageInfo.id}_lightXyearly`} onChange={handleChange} fullWidth={true} value={packageInfo.light_yearly} label={"yearly"}/>
                                        <TextField id={`contact_${packageInfo.id}_lightXupXtoXcontacts`} onChange={handleChange} fullWidth={true} value={packageInfo.light_up_to_contacts} label={"Up to contacts"}/>
                                    </div>
                                </Paper>
                            </div>
                        </div>

                        <div>
                            <div className={classes.packageInfoCard}>
                                <Paper>
                                    <div className={classes.packageInfoAppBar}>
                                        <AppBar position={"sticky"} style={{height:"40px",borderTopRightRadius:"5px",borderTopLeftRadius:"5px"}}>
                                            <div className={classes.appBarTitle}>
                                                <p><IntlMessages id={`_ECLICKSPRO_PLUS_PACK_NAME`} /></p>
                                            </div>
                                        </AppBar>
                                    </div>
                                    <div className={classes.packageInfoBody}>
                                        {/*<TextField fullWidth={true} label={"monthly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"yearly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"Up to contacts"}/>*/}

                                        <TextField id={`contact_${packageInfo.id}_plusXmonthly`} onChange={handleChange} fullWidth={true} value={packageInfo.plus_monthly} label={"monthly"}/>
                                        <TextField id={`contact_${packageInfo.id}_plusXyearly`} onChange={handleChange} fullWidth={true} value={packageInfo.plus_yearly} label={"yearly"}/>
                                        <TextField id={`contact_${packageInfo.id}_plusXupXtoXcontacts`} onChange={handleChange} fullWidth={true} value={packageInfo.plus_up_to_contacts} label={"Up to contacts"}/>
                                    </div>
                                </Paper>
                            </div>
                        </div>

                        <div>
                            <div className={classes.packageInfoCard}>
                                <Paper>
                                    <div className={classes.packageInfoAppBar}>
                                        <AppBar position={"sticky"} style={{height:"40px",borderTopRightRadius:"5px",borderTopLeftRadius:"5px"}}>
                                            <div className={classes.appBarTitle}>
                                                <p><IntlMessages id={`_ECLICKSPRO_PROFESSIONAL_PACK_NAME`} /></p>
                                            </div>
                                        </AppBar>
                                    </div>
                                    <div className={classes.packageInfoBody}>
                                        {/*<TextField fullWidth={true} label={"monthly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"yearly"}/>*/}
                                        {/*<TextField fullWidth={true} label={"Up to contacts"}/>*/}

                                        <TextField id={`contact_${packageInfo.id}_professionalXmonthly`} onChange={handleChange} fullWidth={true} value={packageInfo.professional_monthly} label={"monthly"}/>
                                        <TextField id={`contact_${packageInfo.id}_professionalXyearly`} onChange={handleChange} fullWidth={true} value={packageInfo.professional_yearly} label={"yearly"}/>
                                        <TextField id={`contact_${packageInfo.id}_professionalXupXtoXcontacts`} onChange={handleChange} fullWidth={true} value={packageInfo.professional_up_to_contacts} label={"Up to contacts"}/>
                                    </div>
                                </Paper>
                            </div>
                        </div>





                    </div>
                </PackageAddFormPaper>
            </div>
        </Grid>

    )
}
export default PackageAddForm