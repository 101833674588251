import React from 'react'

//material ui
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";

//custom
import CustomButton from "../button/CustomButton";

const useStyles = makeStyles({
    dialogContent: {
        //backgroundColor: 'red',
        //color: props => props.color,
        margin: "18px 0px 10px 0px"
    },
    dialogAction: {
        padding: "16px 21px 21px 24px",//"16px 22px 21px 24px     6px 22px 8px 24px"
        marginRight: "2px",
    },
    closeIconWrapper: {
        marginRight: "-7px"
    }
});


function EproDialog(props){
    const {open = false, actions=[], title="_TITLE", onlyText = true} = props
    const classes = useStyles()
    return(
        <Dialog
            open={open}
            {...props}
        >
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Grid container>
                        <Grid item md={11}>
                            <Typography variant="h6" style={{color:"#ffffff"}}>
                                <IntlMessages id={title}></IntlMessages>
                            </Typography>
                        </Grid>
                        <Grid container item md={1} justify='flex-end'>
                            <div style={{marginRight:"-7px"}}>
                                <IconButton
                                    size="small"
                                    className="actionDialogTitleButton"
                                    onClick={props.handleClose}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <DialogContent className={classes.dialogContent}>
                {onlyText?
                    <DialogContentText>{props.children}</DialogContentText>
                    :
                    <div>
                        {props.children}
                    </div>
                }
                
            </DialogContent>

            <Divider/>
            <DialogActions className={classes.dialogAction}>
                {actions.some(a => a === "ok") &&
                    <CustomButton color="green" onClick={props.handleOk}>
                        <IntlMessages id="_OK"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "add") &&
                    <CustomButton color="green" onClick={props.handleAdd}>
                        <IntlMessages id="_ADD"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "save") &&
                    <CustomButton color="green" onClick={props.handleSave}>
                        <IntlMessages id="_SAVE"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "share") &&
                    <CustomButton color="green" onClick={props.handleShare}>
                        <IntlMessages id="_SHARE"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "send") &&
                    <CustomButton color="green" onClick={props.handleSend}>
                        <IntlMessages id="_SEND"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "executeNow") &&
                <CustomButton color="green" onClick={props.handleExecuteNow}>
                    <IntlMessages id="_EXECUTE_NOW"></IntlMessages>
                </CustomButton>
                }
                {actions.some(a => a === "copy") &&
                    <CustomButton color="green" onClick={props.handleCopy}>
                        <IntlMessages id="_COPY"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "yes") &&
                    <CustomButton color="green" onClick={props.handleYes}>
                        <IntlMessages id="_YES"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "import") &&
                <CustomButton color="green" onClick={props.handleImport}>
                    <IntlMessages id="_IMPORT"></IntlMessages>
                </CustomButton>
                }
                {actions.some(a => a === "order") &&
                <CustomButton color="green" onClick={props.handleOrder}>
                    <IntlMessages id="_ORDER"></IntlMessages>
                </CustomButton>
                }
                {actions.some(a => a === "reset") &&
                    <CustomButton color="white" onClick={props.handleReset}>
                        <IntlMessages id="_RESET"></IntlMessages>
                    </CustomButton>
                }


                {/*reds*/}
                {actions.some(a => a === "delete") &&
                    <CustomButton color="red" onClick={props.handleDelete}>
                        <IntlMessages id="_DELETE"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "remove") &&
                <CustomButton color="red" onClick={props.handleRemove}>
                    <IntlMessages id="_REMOVE"></IntlMessages>
                </CustomButton>
                }
                {actions.some(a => a === "close") &&
                    <CustomButton color="grey" onClick={props.handleClose}>
                        <IntlMessages id="_CLOSE"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "cancel") &&
                    <CustomButton color="red" onClick={props.handleCancel}>
                        <IntlMessages id="_CANCEL"></IntlMessages>
                    </CustomButton>
                }
                {actions.some(a => a === "no") &&
                    <CustomButton color="red" onClick={props.handleNo}>
                        <IntlMessages id="_NO"></IntlMessages>
                    </CustomButton>
                }
                </DialogActions>
        </Dialog>
    )
}
export default EproDialog