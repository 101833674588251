import React from 'react'
import IntlMessages from "../../../../util/IntlMessages";
import { Button } from "@material-ui/core";

const CustomBackButton = (props) =>{
    return(
        <Button
            onClick={props.onClick}
            variant="outlined"
            className="jr-btn jr-btn-lg"
            style={{
                border: "1px #00bcd4 solid",
                color: "#00bcd4",
                marginTop: "5px",
            }}
        >
            <span>&#8676;</span> {props.buttonText === undefined? <IntlMessages id="_BACK_TO_LIST_PAGE" /> : <IntlMessages id={props.buttonText} />}
        </Button>
    )
}
export default CustomBackButton