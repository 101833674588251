import appLocaleData from "react-intl/locale-data/nl";
import { nlMessage } from "../locales/nl_NL";

const NlLang = async () => {
  const lang = await nlMessage();

  return {
    messages: {
      ...lang
    },
    locale: "nl-NL",
    data: appLocaleData
  };
}

export default NlLang;
