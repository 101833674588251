import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_MENU } from "constants/ActionTypes";
import { userMenuSuccess } from "actions/Menu";

import config from "../config";

// Api Functions
const tokenBasedMenu = async token => {
  const response = await fetch(`${config.api.url}api/menu`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  return response;
};

// Saga Functions
function* getTokenBasedMenu({ payload }) {
  try {
    const getMenu = yield call(tokenBasedMenu, payload);
    yield put(userMenuSuccess(getMenu));
  } catch (e) {
  }
}

// exporting the saga function
export function* getMenu() {
  yield takeEvery(FETCH_MENU, getTokenBasedMenu);
}

export default function* rootSaga() {
  yield all([fork(getMenu)]);
}
