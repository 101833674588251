import * as Constants from "../common/Constants"
import * as Apis from "../common/ApiUrls"
import config from "../config";
import axios from "axios";
import { getAuthData } from "../common/helpers/AuthHelper";

export const requestEnableButton = () =>{
    return{
        type:"CHANGE_ACINFO_BTN_STATUS"
    }
}

export const requestDisableButton = () =>{
    return{
        type:"CHANGE_ACINFO_BTN_STATUS_DISABLE"
    }
}

export const requestSave = () => {
    return {
        type: "REQUEST_SAVE"
    };
};
export const requestSaveAndNext = () =>{
    return{
        type:"REQUEST_ACINFO_SAVE_AND_NEXT"
    }
}
export const requestSaveAndClose = ()=>{
    return{
        type:"REQUEST_ACINFO_SAVE_AND_CLOSE"
    }
}
export const requestAdd = ()=>{
    return{
        type:"REQUEST_ACINFO_ADD"
    }
}
export const requestDelete = ()=>{
    return{
        type:"REQUEST_ACINFO_DELETE"
    }
}
export const resetAll = () => {
    return {
        type: "RESET_AC"
    };
};

export const actionCreators = {
    getUsers:()=>async (dispatch,getState)=>{
        dispatch({type:Constants.REQUEST_GET_ALL_USERS_BY_ACCOUNT})
        let url = config.api.url+Apis.GET_USERS_BY_ACCOUNT
        let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.REQUEST_GET_ALL_USERS_BY_ACCOUNT,
                        users: res.data.data,
                    });
                }
            })
            .catch((e) => {
                let aaaa = e;
            });
    }

}