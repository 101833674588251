import React from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "config";


class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      loader: false
    };
  }

  submitHandler = async () => {
    const { email } = this.state;
    this.setState({ loader: true });
    const response = await fetch(`${config.api.url}api/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        reset_email: email
      })
    })
      .then(res => res.json())
      .then(json => {
        this.setState({ loader: false });
        return json;
      });

    if (response.status === "success") {
      return NotificationManager.success(response.message);
    }

    if (response.status === "error") {
      return NotificationManager.error(response.message);
    }
  };

  render() {
    const { email, loader } = this.state;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="MyBizzmail">
              <img
                src={require("assets/images/logo.png")}
                alt="bizzmail"
                title="MyBizzmail"
                width="250"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="_RESET_PASSWORD" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="_EMAIL" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        this.submitHandler();
                      }
                    }}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={this.submitHandler}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="_ECLICKSPRO_SEND_RESET_PASSWORD_LINK" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <NotificationContainer />
      </div>
    );
  }
}

export default ResetPassword;
