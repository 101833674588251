import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_SCHEDULE_HISTORY,
  FETCH_SCHEDULE_SEND_QUEUE
} from "constants/ActionTypes";

import {
  fetchScheduleHistorySuccess,
  fetchScheduleSendQueueSuccess
} from "actions/Schedule";

import config from "config";

//  Fetching
const fetchScheduleHistory = async token => {
  const response = await fetch(
    `${config.api.url}v1/get/mailings?limit=&offset=&type=`,
    {
      method: "GET",
      headers: {
        Authorization: token
      }
    }
  )
    .then(res => res.json())
    .then(json => json);

  return response;
};

const fetchScheduleSendQueue = async token => {
  const response = await fetch(
    `${config.api.url}v1/get/send_queue?limit=&offset=&type=&campaign_id=`,
    {
      method: "GET",
      headers: {
        Authorization: token
      }
    }
  )
    .then(res => res.json())
    .then(json => json);

  return response;
};

// Generator
function* getFetchScheduleHistory({ payload }) {
  try {
    const getHistoryData = yield call(fetchScheduleHistory, payload);
    yield put(fetchScheduleHistorySuccess(getHistoryData));
  } catch (e) {
    console.error(e);
  }
}

function* getFetchScheduleSendQueue({ payload }) {
  try {
    const getSendQueueData = yield call(fetchScheduleSendQueue, payload);
    yield put(fetchScheduleSendQueueSuccess(getSendQueueData));
  } catch (e) {
    console.error(e);
  }
}

// export generators
export function* getHistory() {
  yield takeEvery(FETCH_SCHEDULE_HISTORY, getFetchScheduleHistory);
}

export function* getSendQueue() {
  yield takeEvery(FETCH_SCHEDULE_SEND_QUEUE, getFetchScheduleSendQueue);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getHistory), fork(getSendQueue)]);
}
