import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "../../../../util/IntlMessages";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    sumRow: {
        '& td': {
            borderBottom: 'none'
        },
        '& th': {
            borderBottom: 'none'
        }
    }
});

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
];



function OrderInvoice(props) {
    const classes = useStyles()
    const { item, vat } = props

    let totalInc = 0;
    let date = new Date();

    // if(item){
    //     if(item.vat){
    //         totalInc =
    //     }else{
    //
    //     }
    // }
    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Credit
                        </TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Vat</TableCell>
                        <TableCell align="right">Price incl</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">{item.credit_amount}</TableCell>
                        <TableCell align="right">{date.getDate().toString() + "-" + date.getMonth() + "-" + date.getFullYear()}</TableCell>
                        <TableCell align="right">{item.price}</TableCell>
                        <TableCell align="right">{item.total_vat}</TableCell>
                        <TableCell align="right">{item.total_price}</TableCell>
                    </TableRow>

                    <TableRow className={classes.sumRow}>
                        <TableCell component="th" scope="row">

                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"><IntlMessages id={"_TOTAL"} /></TableCell>
                        <TableCell align="right">{item.total_price}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>

            {/*<Table className={classes.sumTable} aria-label="simple table">*/}
            {/*    <TableBody>*/}
            {/*        <TableRow>*/}
            {/*            <TableCell component="th" scope="row"><p className={classes.par}>Some information</p></TableCell>*/}
            {/*            <TableCell><p className={classes.par}>20-12-2020</p></TableCell>*/}
            {/*            <TableCell><p className={classes.par}>04.00</p></TableCell>*/}
            {/*            <TableCell align="right">*/}
            {/*                <IntlMessages id={"_TOTAL"}/>*/}
            {/*            </TableCell>*/}
            {/*            <TableCell align="right">04.21</TableCell>*/}
            {/*        </TableRow>*/}

            {/*    </TableBody>*/}
            {/*</Table>*/}
        </TableContainer>
    )
}
export default OrderInvoice