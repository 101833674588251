import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_EDITVIEW } from "constants/ActionTypes";
import { fetchEditViewSuccess } from "actions/EditView";

import config from "../config";

const fetchEditViewData = async params => {
  const { token, tableName, id } = params;

  const response = await fetch(
    `${config.api.url}api/${tableName}/ListingEdit/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => res.json())
    .then(json => json)
    .catch(error =>{});
  return response;
};

// Saga Functions
function* getFetchEditViewData({ payload }) {
  // console.log(payload);
  try {
    const getEditView = yield call(fetchEditViewData, payload);
    yield put(fetchEditViewSuccess(getEditView));
  } catch (e) {
  }
}

// exporting the saga function
export function* getEditViewData() {
  yield takeEvery(FETCH_EDITVIEW, getFetchEditViewData);
}

export default function* rootSaga() {
  yield all([fork(getEditViewData)]);
}
