import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import {getResponseForNotificaion} from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";

export const actionCreators = {
    //getAllInFolder
    getItemsInFolder:(folderId,listFor, searchValue="", sortBy="")=>async (dispatch,getState) =>{
        dispatch({ type: Constants.REQUEST_GET_ALL_ITEMS_IN_FOLDER });
        let url = config.api.url + "v1/"+listFor+"?folder_id=" + folderId + "&sort_by=" + sortBy+ "&search_value="+ searchValue;
        let folderUrl = config.api.url + "v1/folder/"+listFor+"/" + folderId + "?sort_by=" + sortBy+ "&name="+searchValue;
        let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                // dispatch({ type: "GET_ALL_FORM_SUCCESS", forms: res.data.data.items });
                if(res.data.status === "success"){
                    let items = res.data.data.items
                    axios({
                        method: "get",
                        url: folderUrl,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            if(res.data.status === "success"){
                                dispatch({type:Constants.SUCCESS_GET_ALL_ITEMS_IN_FOLDER,items:items,folders:res.data.data.items})
                                //dispatch({type:Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER,items:[],folders:[]})
                            }else {
                                let apiResponse = getResponseForNotificaion(res)
                                dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:apiResponse})
                            }
                            //dispatch({ type: "GET_ALL_FOLDER_SUCCESS", folders: res.data.data.items });
                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
                        });
                }else {
                    let errorResponse = getResponseForNotificaion(null,{})
                    dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
            });


        const response = await fetch(`${config.api.url}api/home`, {
            method: "POST",
            headers: {
                Authorization: token,
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(json => json)
            .catch(error => error);




    },

    //add
    addFolder: (name,listFor, parentId) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_ADD_NEW_FOLDER})
        let url = config.api.url + "v1/folder/";
        let {token} = getAuthData()

        axios({
            method: "POST",
            url: url,
            headers: {
                Authorization: token,
            },
            data: { name: name, type: listFor, parent_id: parentId },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_ADD_NEW_FOLDER,
                        folders: [res.data.data],
                        apiResponse:apiResponse

                    });
                }else {
                    dispatch({type:Constants.FAILED_ADD_NEW_FOLDER,apiResponse:apiResponse})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_ADD_NEW_FOLDER,apiResponse:errorResponse})
            });
    },
    addItem: (name,listFor,parentId,cb=()=>{}) => async (dispatch, getState) => {
        dispatch({type: Constants.REQUEST_ADD_NEW_ITEM})
        let url = config.api.url + "v1/"+listFor;
        let {token} = getAuthData()

        axios({
            method: "POST",
            url: url,
            headers: {
                Authorization: token,
            },
            data: { name: name, parent_id: parentId },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_ADD_NEW_ITEM,
                        item: [res.data.data],
                        apiResponse:apiResponse
                    });
                    cb(res.data.status);
                }else {
                    dispatch({type:Constants.FAILED_ADD_NEW_ITEM,apiResponse:apiResponse})
                    cb(apiResponse);
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_ADD_NEW_ITEM,apiResponse:errorResponse});
                cb(errorResponse);
            });
    },

    //rename
    handleRename: (id, name,listFor) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_RENAME_ITEM})
        let url = config.api.url+ "v1/"+listFor+"/" + id;
        let {token} = getAuthData()

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token,
            },
            data: { name: name },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_RENAME_ITEM,
                        itemObj: res.data.data,
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({type:Constants.FAILED_RENAME_ITEM,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_RENAME_ITEM,apiResponse:errorResponse})
            });
    },
    renameFolder: (id, name) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_RENAME_FOLDER})
        let url = config.api.url + "v1/folder/" + id;
        let {token} = getAuthData()

        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                name: name,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_RENAME_FOLDER,
                        folder: [res.data.data],
                        folderId: id,
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({type: Constants.FAILED_RENAME_FOLDER,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type: Constants.FAILED_RENAME_FOLDER,apiResponse:errorResponse})
            });
    },

    //delete
    deleteSelected: (itemIds, folderIds,listFor) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_DELETE_ITEM})
        let itemUrl = config.api.url + "v1/"+listFor+"/";
        let folderUrl = config.api.url + "v1/folder/";
        let {token} = getAuthData()

        let deletedItems = []
        let failedItems = []
        let deletedFolders = []
        let failedFolders = []

        let customResponseItem = {
            "status":"success",
            "message":deletedItems.length +" deleted",
            "status_code":"200",
            "alertType": "info",
        }
        let customResponseFolder = {
            "status":"success",
            "message":deletedFolders.length +" deleted",
            "status_code":"200",
            "alertType": "info",
        }



        if (itemIds.length > 0) {
            for (let i = 0; i < itemIds.length; i++) {
                let url = "";
                url = itemUrl + itemIds[i];
                axios({
                    method: "DELETE",
                    url: url,
                    headers: {
                        Authorization: token,
                    },
                })
                    .then((res) => {
                        let apiResponse = getResponseForNotificaion(res)
                        if(res.data.status === "success"){
                            deletedItems.push(itemIds[i])
                            dispatch({ type: Constants.SUCCESS_DELETE_ITEM, itemId: itemIds[i],apiResponse: apiResponse });
                        }else {
                            dispatch({type:Constants.FAILED_DELETE_ITEM,apiResponse: apiResponse})
                            //failedItems.push(itemIds[i])
                        }

                    })
                    .catch((e) => {
                    });
            }
            // dispatch({
            //     type:Constants.SUCCESS_DELETE,
            //     apiResponse:customResponseItem,
            //     deletedItems:deletedItems,
            //     deletedFolders:deletedItems,
            // })

        }
        if (folderIds.length > 0) {
            for (let j = 0; j < folderIds.length; j++) {
                let url = "";
                url = folderUrl + folderIds[j];
                axios({
                    method: "DELETE",
                    url: url,
                    headers: {
                        Authorization: token,
                    },
                })
                    .then((res) => {
                        let apiResponse = getResponseForNotificaion(res)
                        if(res.data.status === "success"){
                            //deletedFolders.push(folderIds[j])
                            dispatch({
                                type: Constants.SUCCESS_DELETE_FOLDER,
                                folderId: folderIds[j],
                                apiResponse:apiResponse
                            });
                        }else{
                            //failedFolders.push(folderIds[j])
                            dispatch({type:Constants.SUCCESS_DELETE_FOLDER,apiResponse:apiResponse})
                        }

                    })
                    .catch((e) => {});
            }
            // dispatch({
            //     type:Constants.SUCCESS_DELETE,
            //     apiResponse:customResponseFolder,
            //     deletedItems:deletedItems,
            //     deletedFolders:deletedItems,
            // })

        }


        let errorResponse = getResponseForNotificaion(null,{})

        // dispatch({
        //     type:Constants.SUCCESS_DELETE,
        //     deletedItems:deletedItems,
        //     failedItems:failedItems,
        //     deletedFolders:deletedFolders,
        //     failedFolders:failedFolders
        // })


    },

    getSharedData:() =>async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_GET_SHARE_PORTAL_DATA})
        let url = config.api.url+"v1/portal/accounts"
        let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_SHARE_PORTAL_DATA,sharedData:res.data.data.shared_data})
                }else {
                    let apiResponse = getResponseForNotificaion(res)
                    dispatch({type:Constants.FAILED_GET_SHARE_PORTAL_DATA,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_SHARE_PORTAL_DATA,apiResponse:errorResponse})
            });
    },

    getWesp:(listFor)=>async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_GET_WESP})
        let url = config.api.url+"v1/wesp/defaultwesp/"+listFor
        let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_WESP,wesp:res.data.data})
                }else {
                    let apiResponse = getResponseForNotificaion(res)
                    dispatch({type:Constants.FAILED_GET_WESP,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_WESP,apiResponse:errorResponse})
            });
    },

    updateSharePortal:(id,externalId,listFor,folderId) => async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_UPDATE_SHARE_PORTAL})
        let url = config.api.url + "v1/"+listFor+"/shareportal/" + id;
        let {token} = getAuthData()

        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                external_id: externalId,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                //     dispatch({
                //         type: Constants.SUCCESS_UPDATE_SHARE_PORTAL,
                //         folder: [res.data.data],
                //         folderId: id,
                //         apiResponse:apiResponse
                //     });
                //
                // //    update share data
                //     dispatch({type:Constants.REQUEST_GET_SHARE_PORTAL_DATA})
                //     let url = config.api.url+"v1/portal/accounts"
                //     axios({
                //         method: "get",
                //         url: url,
                //         headers: {
                //             Authorization: token,
                //         },
                //     })
                //         .then((res) => {
                //             if(res.data.status === "success"){
                //                 dispatch({type:Constants.SUCCESS_GET_SHARE_PORTAL_DATA,sharedData:res.data.data.shared_data})
                //             }else {
                //                 let apiResponse = getResponseForNotificaion(res)
                //                 dispatch({type:Constants.FAILED_GET_SHARE_PORTAL_DATA,apiResponse:apiResponse})
                //             }
                //         })
                //         .catch((e) => {
                //             let errorResponse = getResponseForNotificaion(null,{})
                //             dispatch({type:Constants.FAILED_GET_SHARE_PORTAL_DATA,apiResponse:errorResponse})
                //         });
                // //    update share data

                //    reload share

                    dispatch({ type: Constants.REQUEST_GET_ALL_ITEMS_IN_FOLDER });

                    let url = config.api.url + "v1/"+listFor+"?folder_id=" + folderId;
                    let folderUrl = config.api.url + "v1/folder/"+listFor+"/" + folderId;

                    axios({
                        method: "get",
                        url: url,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            // dispatch({ type: "GET_ALL_FORM_SUCCESS", forms: res.data.data.items });
                            if(res.data.status === "success"){
                                let items = res.data.data.items
                                axios({
                                    method: "get",
                                    url: folderUrl,
                                    headers: {
                                        Authorization: token,
                                    },
                                })
                                    .then((res) => {
                                        if(res.data.status === "success"){
                                            dispatch({type:Constants.SUCCESS_GET_ALL_ITEMS_IN_FOLDER,items:items,folders:res.data.data.items})
                                            //dispatch({type:Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER,items:[],folders:[]})
                                        }else {
                                            let apiResponse = getResponseForNotificaion(res)
                                            dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:apiResponse})
                                        }
                                        //dispatch({ type: "GET_ALL_FOLDER_SUCCESS", folders: res.data.data.items });
                                    })
                                    .catch((e) => {
                                        let errorResponse = getResponseForNotificaion(null,{})
                                        dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
                                    });
                            }else {
                                let errorResponse = getResponseForNotificaion(null,{})
                                dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
                            }

                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER,apiResponse:errorResponse})
                        });
                //    reload share


                }else {
                    dispatch({type: Constants.FAILED_UPDATE_SHARE_PORTAL,apiResponse:apiResponse})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type: Constants.FAILED_UPDATE_SHARE_PORTAL,apiResponse:errorResponse})
            });
    },

    handleCopy: (id, parentId,listFor) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_COPY_ITEM})
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/"+listFor+"/" + id;
        let {token} = getAuthData()

        let getForm = await axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        });

        let form = await getForm.data.data;
        if (form !== null || form !== undefined) {
            let postUrl = config.api.url + "v1/"+listFor;
            let cName = "Copy-" + form.name;
            if(listFor === 'letter'){
                cName = cName.split(' ').join("_");
            }

            axios({
                method: "POST",
                url: postUrl,
                headers: {
                    Authorization: token,
                },
                data: {
                    name: cName,
                    html: form.html,
                    data: form.content,
                    parent_id: parentId,
                },
            })
                .then((res) => {
                    let apiResponse = getResponseForNotificaion(res)
                    if(res.data.status === "success"){
                        dispatch({
                            type: Constants.SUCCESS_COPY_ITEM,
                            items: [res.data.data],
                            apiResponse:apiResponse
                        });
                    }else {
                        dispatch({type: Constants.FAILED_COPY_ITEM,apiResponse:apiResponse})
                    }

                })
                .catch((e) => {
                    let aaaa = e;
                });
        }
    },

    updateItemParent: (itemId, parentId,listFor) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_UPDATE_ITEM_PARENT})
        let url = config.api.url + "v1/"+listFor+"/" + itemId;
        let {token} = getAuthData()

        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                folder_id: parentId,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_UPDATE_ITEM_PARENT,
                        itemId: itemId,
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({
                        type: Constants.FAILED_UPDATE_ITEM_PARENT,
                        apiResponse:apiResponse
                    });
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type: Constants.FAILED_UPDATE_ITEM_PARENT,apiResponse:errorResponse})
            });
    },
    updateFolderParent: (folderId, parentId) => async (dispatch, getState) => {
        dispatch({type:Constants.REQUEST_UPDATE_FOLDER_PARENT})
        let url = config.api.url + "v1/folder/" + folderId;
        let {token} = getAuthData()

        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                parent_id: parentId,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_UPDATE_FOLDER_PARENT,
                        folderId: folderId,
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({type: Constants.FAILED_UPDATE_FOLDER_PARENT,apiResponse:apiResponse})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type: Constants.FAILED_UPDATE_FOLDER_PARENT,apiResponse:errorResponse})
            });
    },

    handlePutSms:(id,content) =>async (dispatch,getState) =>{
        dispatch({type:Constants.REQUEST_PUT_SMS})
        let url = config.api.url + "v1/sms/" + id;
        let {token} = getAuthData()

        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                content: content,
            },
        })
            .then((res) => {

                let apiResponse = getResponseForNotificaion(res)
                if(res.data.status === "success"){
                    dispatch({
                        type: Constants.SUCCESS_PUT_SMS,
                        sms:[res.data.data],
                        apiResponse:apiResponse
                    });
                }else {
                    dispatch({type: Constants.FAILED_PUT_SMS,apiResponse:apiResponse})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type: Constants.FAILED_PUT_SMS,apiResponse:errorResponse})
            });
    },
    getAllAccounts:()=>async (dispatch,getState)=>{
        dispatch({type:Constants.REQUEST_GET_ALL_ACCOUNT})
        let url = config.api.url+"api/accounts/getOtherAccounts"
        let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    dispatch({
                        type:Constants.SUCCESS_GET_ALL_ACCOUNT,
                        accounts:res.data.data
                        // apiResponse:apiResponse
                    })
                }else {
                    //let apiResponse = getResponseForNotificaion(res)
                    dispatch({type:Constants.FAILED_GET_ALL_ACCOUNT})
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_ALL_ACCOUNT,apiResponse:errorResponse})
            });
    },
    resetResponse:() => async (dispatch,getState) =>{
        dispatch({type:Constants.RESET_FOLDER_VIEW_RESPONSE})
    },
    resetState:()=>async (dispatch,getState) => {
        dispatch({type:Constants.RESET_FOLDER_VIEW_STATE})
    },
    manuallySortItems:(currentItems,listFor,itemId,parentId,folderId, type="")=>(dispatch,getState)=>{
		dispatch({type: Constants.REQUEST_MANUAL_SORT_ITEM});
        const selectedItem = currentItems.find((i) => i.id === itemId);

        let sortedItems=[];
        for(let i = 0, j=0; i < currentItems.length; i++,j++){
            if(currentItems[i].id === parentId){
                sortedItems[j] = selectedItem;
                sortedItems[j+1] = currentItems[i];
            }else if(currentItems[i].id === itemId){

            }else{
                sortedItems.push(currentItems[i]);
            }
        }
        // let sortedItems = currentItems;
        // const itemIndex = sortedItems.findIndex(item=>item.id === itemId);
        // const parentIndex = sortedItems.findIndex(item=>item.id === parentId);
        // if(itemIndex>-1 && parentIndex>-1){
        //     let temp = sortedItems[itemIndex];
        //     sortedItems[itemIndex] = sortedItems[parentIndex];
        //     sortedItems[parentIndex] = temp;
        // }

        
        const sortedItemsIds = sortedItems.map(item=> item.id);

        let {token} = getAuthData()
		let url;
        if(type){
            url = config.api.url + `api/${listFor}/sortFolderManually/${folderId}`;
        }else{
            url = config.api.url + `api/${listFor}/sortManually/${folderId}`;
        }
        
		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				data: sortedItemsIds
			},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if (res.data.status === "success") {
                    if(type){
					    dispatch({type: Constants.SUCCESS_MANUAL_SORT_ITEM_FOLDERS, folders: res.data.data.items});
                    }else{
					    dispatch({type: Constants.SUCCESS_MANUAL_SORT_ITEM, items: res.data.data.items});
                    }
				}
				else {
					dispatch({
						type: Constants.FAILED_MANUAL_SORT_ITEM,
						folderViewApiResponse:apiResponse
					});
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_MANUAL_SORT_ITEM,folderViewApiResponse:errorResponse})
			});

		
	}
}