import {
    FETCH_EMAIL,
    FETCH_EMAIL_SUCCESS,
    FETCH_EMAIL_FOLDER,
    FETCH_EMAIL_FOLDER_SUCCESS,
    FETCH_EMAIL_BY_ID,
    FETCH_EMAIL_BY_ID_SUCCESS
} from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants";
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";

let actionsFor = "email";

export const fetchEmail = params => {
    return {
        type: FETCH_EMAIL,
        payload: params
    };
};

export const fetchEmailSuccess = emails => {
    return {
        type: FETCH_EMAIL_SUCCESS,
        payload: emails
    };
};

export const fetchEmailFolder = params => {
    return {
        type: FETCH_EMAIL_FOLDER,
        payload: params
    };
};

export const fetchEmailFolderSuccess = folders => {
    return {
        type: FETCH_EMAIL_FOLDER_SUCCESS,
        payload: folders
    };
};

export const fetchEmailById = params => {
    return {
        type: FETCH_EMAIL_BY_ID,
        payload: params
    };
};

export const fetchEmailByIdSuccess = emailData => {
    return {
        type: FETCH_EMAIL_BY_ID_SUCCESS,
        payload: emailData
    };
};

export const actionCreators = {
    //getById
    getEmailById: id => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_GET_EMAIL_BY_ID });
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/" + actionsFor + "/" + id;
        let { token } = getAuthData();

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                let emailObj = null;
                let subjectLine = "";
                let fromAddress = "";
                let fromName = "";
                let toAddress = "";
                let toName = "";
                if (res.data.data !== null) {
                    emailObj = res.data.data;
                    if (res.data.data.data !== null) {
                        //pageObj: res.data.data.data.page,
                        subjectLine = res.data.data.data.subjectLine;
                        fromAddress = res.data.data.data.fromAddress;
                        fromName = res.data.data.data.fromName;
                        toAddress = res.data.data.data.toAddress;
                        toName = res.data.data.data.toName;
                    }
                }
                dispatch({
                    type: Constants.SUCCESS_GET_EMAIL_BY_ID,
                    emailObj: emailObj,
                    //pageObj: res.data.data.data.page,
                    subjectLine: subjectLine,
                    fromAddress: fromAddress,
                    fromName: fromName,
                    toAddress: toAddress,
                    toName: toName
                });
            })
            .catch(e => {
                let aaaa = e;
            });
    },
    //getAllItemsInFolder

    //put
    putEmailData: (
        email,
        jsonData,
        htmlData,
        subjectLine,
        fromAddress,
        fromName,
        toAddress,
        toName
    ) => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_PUT_EMAIL });
        email.data = jsonData;
        const parseData = JSON.parse(jsonData);
        parseData.subjectLine = subjectLine;
        parseData.fromAddress = fromAddress;
        parseData.fromName = fromName;
        parseData.toAddress = toAddress;
        parseData.toName = toName;
        parseData.page.title = email.name;
        // const strData = JSON.stringify(jsonData);
        // const strDataFrom = JSON.stringify(parseData);
        // const updatedData = form;
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/" + actionsFor + "/" + email.id;
        let { token } = getAuthData();

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token
            },
            data: { data: parseData, html: htmlData }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_PUT_EMAIL,
                        apiResponse: apiResponse
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_PUT_EMAIL,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_PUT_EMAIL,
                    apiResponse: errorResponse
                });
            });
    },

    //rename
    handleRename: (id, name) => async (dispatch, getState) => {
        dispatch({ type: Constants.REQUEST_RENAME_EMAIL });
        let apiUrl = config.api.url;
        let url = apiUrl + "v1/" + actionsFor + "/" + id;
        let { token } = getAuthData();

        axios({
            method: "put",
            url: url,
            headers: {
                Authorization: token
            },
            data: { name: name }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_RENAME_EMAIL,
                        emailObj: res.data.data,
                        apiResponse: apiResponse
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_RENAME_EMAIL,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_RENAME_EMAIL,
                    apiResponse: errorResponse
                });
            });
    },
    renameFolder: (id, name) => async (dispatch, getState) => {
        let url = config.api.url + "v1/folder/" + id;
        let { token } = getAuthData();
        axios({
            method: "PUT",
            url: url,
            headers: {
                Authorization: token
            },
            data: {
                name: name
            }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_RENAME_EMAIL_FOLDER,
                        folder: [res.data.data],
                        folderId: id
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_RENAME_EMAIL_FOLDER,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_RENAME_EMAIL_FOLDER,
                    apiResponse: errorResponse
                });
            });
    },

    getEmailSuggestion: (limit, email) => async (dispatch, getState) => {
        dispatch({
            //type: "REQUEST_EMAIL_SUGGESTION",
            type: Constants.REQUEST_GET_EMAIL_SUGGESTION
        });
        let apiUrl = config.api.url;
        let { token } = getAuthData();
        let url =
            apiUrl +
            "v1/get/relations?limit=" +
            limit +
            "&offset=0&email=" +
            email;
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    let emails = res.data.data.items;
                    dispatch({
                        type: Constants.SUCCESS_GET_EMAIL_SUGGESTION,
                        emailSuggestions: emails
                    });
                }
            })
            .catch();
    },
    updateEmailInfo: (id, value) => async (dispatch, getState) => {
        if (id === "subjectLine") {
            dispatch({ type: "UPDATE_SUBJECT_LINE", subjectLine: value });
        }
        if (id === "fromAddress") {
            dispatch({ type: "UPDATE_FROM_ADDRESS", fromAddress: value });
        }
        if (id === "toAddress") {
            dispatch({ type: "UPDATE_TO_ADDRESS", toAddress: value });
        }
        if (id === "fromName") {
            dispatch({ type: "UPDATE_FROM_NAME", fromName: value });
        }
        if (id === "toName") {
            dispatch({ type: "UPDATE_TO_NAME", toName: value });
        }
    },

    getProfileData: () => async dispatch => {
        let { token } = getAuthData();
        let url = config.api.url + "api/profile";

        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                // let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.GET_PROFILE,
                        payload: res.data
                    });
                } else {
                    // cb("failed", {});
                }
            })
            .catch(e => {
                // let errorResponse = getResponseForNotificaion(null,{})
                // cb("failed", {});
            });
    },

    handleGenerateEmail: (id, target, emailData, context) => async (
        dispatch,
        getState
    ) => {
        let apiUrl = config.api.url;
        let url = apiUrl + "api/chatgpt";
        let { token } = getAuthData();
        // dispatch({ type: Constants.REQUEST_SEND_EMAIL });

        dispatch({ type: Constants.Loader1, payload: true });

        var data = new FormData();
        // target = { ...target, context: emailData.generatedEmailData };
        data.append("search", target);

        var newContext = context?.reduce((accumulator, el, i) => {
            return `${accumulator}${el.context}`;
        }, "");

        data.append("context", newContext);

        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
                "Content-Type": "multipart/form-data"
            },
            data: data
            // body: { context: emailData.generatedEmailData }
        })
            .then(res => {
                if (res.data.status === "success") {
                    // const response = res.data.data.choices[0].text.split(
                    //     "\n\n"
                    // );
                    // console.log(response.shift(), "response is here");
                    dispatch({
                        type: Constants.SUCCESS_GENERATE_EMAIL,

                        apiResponse: res.data.data
                        // .choices[0].text
                        // .split("\n\n")

                        // .join("<br />")
                    });
                    dispatch({ type: Constants.Loader1, payload: false });
                } else {
                    dispatch({
                        type: Constants.FAILED_GENERATE_EMAIL,
                        apiResponse: res.data.data
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_SEND_EMAIL,
                    apiResponse: errorResponse
                });
            });
        //dispatch({ type: "EMAIL_SENT_SUCCESS" });
    },

    sendEmail: (id, target) => async (dispatch, getState) => {
        let apiUrl = config.api.url;
        let url = apiUrl + "api/send/" + actionsFor + "/" + id;
        let { token } = getAuthData();
        dispatch({ type: Constants.REQUEST_SEND_EMAIL });
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token
            },
            data: { type: "relation", target: target }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                if (res.data.status === "success") {
                    dispatch({
                        type: Constants.SUCCESS_SEND_EMAIL,
                        apiResponse: apiResponse
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_SEND_EMAIL,
                        apiResponse: apiResponse
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_SEND_EMAIL,
                    apiResponse: errorResponse
                });
            });
        //dispatch({ type: "EMAIL_SENT_SUCCESS" });
    },
    getAllRelationField: () => async (dispatch, getState) => {
        let url = config.api.url + "v1/relation/field/all ";
        let { token } = getAuthData();
        dispatch({ type: Constants.REQUEST_GET_ALL_RELATION_FIELDS });
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    let fields = res.data.data.public;
                    fields.push({ key: "EMAIL_VIEW" });
                    fields.push({ key: "UNSUBSCRIPTION_LINK" });
                    fields.push({ key: "coaching_session" });
                    fields.push({ key: "scheduled_time" });
                    fields.push({ key: "date" });
                    fields.push({ key: "guest_phone" });
                    fields.push({ key: "cancel_link" });

                    dispatch({
                        type: Constants.SUCCESS_GET_ALL_RELATION_FIELDS,
                        fields: fields
                    });
                }
            })
            .catch(e => {
                let aaaa = e;
            });
    },
    getCampaignOfThisItem: (type, id) => async (dispatch, getState) => {
        let url = config.api.url + "api/campaign/getCampaignOfThisItem";
        let { token } = getAuthData();
        dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
        ///let request = await httpService.postAsync(url,{'type':type,'id':id})
        // if(request.data.status === "success"){
        // 	let obj = null
        // 	if(request.data.isSelected !== 0){
        // 		obj = {
        // 			campaignId:request.data.campaignId,
        // 			campaignName:request.data.campaignName
        // 		}
        // 	}
        // 	dispatch({ type: Constants.SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM,payload:obj });
        // }else{
        // 	dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
        // }
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token
            },
            data: { type: type, typeId: id }
        })
            .then(res => {
                let apiResponse = getResponseForNotificaion(res);
                // debugger;
                if (res.data.status === "success") {
                    let obj = null;
                    if (res.data.isSelected !== 0) {
                        obj = {
                            campaignId: res.data.campaignId,
                            campaignName: res.data.campaignName
                        };
                    }
                    dispatch({
                        type: Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,
                        payload: obj
                    });
                } else {
                    dispatch({
                        type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,
                        payload: null
                    });
                }
            })
            .catch(e => {
                let errorResponse = getResponseForNotificaion(null, {});
                dispatch({
                    type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,
                    payload: null
                });
            });
    },
    startLoaderDialog: () => async (dispatch, getState) => {
        dispatch({ type: Constants.START_EMAIL_LOADER_DIALOG });
    },
    stopLoaderDialog: () => async (dispatch, getState) => {
        dispatch({ type: Constants.STOP_EMAIL_LOADER_DIALOG });
    },
    resetResponse: () => async (dispatch, getState) => {
        dispatch({ type: Constants.RESET_EMAIL_API_RESPONSE });
    },
    resetSuccessStatus: () => async (dispatch, getState) => {
        dispatch({ type: Constants.RESET_SUCCESS_STATUS });
    }
};
