import React, { Component } from "react";
import Tour from 'reactour'
import "./style.css"
import IntlMessages from "../../../../util/IntlMessages";

// import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const steps = [
    // {
    //     serial:1,
    //     selector: '.test-tour',
    //     content: <IntlMessages id="_PLEASE_SELECT" />,
    // },
    // {
    //     selector: '.home-graph',
    //     content: <IntlMessages id="_PLEASE_SELECT" />,
    // },
    // {
    //     selector: '.jr-card-ticketlist',
    //     content: <IntlMessages id="_PLEASE_SELECT" />,
    // },
];
class ReactTour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTourOpen:false
        }
    }
    closeTour = () =>{
        this.props.stop()
    }
    render() {
        //const {isTourOpen} = this.state
        const {steps} = this.props

        return (
            <div>
                <Tour
                    steps={steps}
                    isOpen={this.props.start}
                    disableFocusLock={false}
                    // maskClassName="mask"
                    //className="helper"
                    // disableKeyboardNavigation={['right']}
                    onRequestClose={this.closeTour}
                />
            </div>
        );
    }
}

export default ReactTour;