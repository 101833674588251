import { FETCH_PROFILE, FETCH_PROFILE_SUCCESS } from "constants/ActionTypes";
import config from "../config";
import * as Constants from "../common/Constants";
import axios from "axios";
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";
import {getAsync} from "../common/helpers/HttpService"


export const profileInfo = token => {
  return {
    type: FETCH_PROFILE,
    payload: token
  };
};

export const profileInfoSuccess = info => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: info
  };
};

export const getFullUserInfo = (apiKey,apiSecret) => async (dispatch, getState) => {
    let {token} = getAuthData()
    dispatch({ type: Constants.REQUEST_GET_USER_INFO,token:token});
  let url = config.api.url+ "api/profile";


  axios({
    method: "post",
    url: url,
    headers: {
      Authorization: token,
    },
    data: {
      mollieApiKey: apiKey,
      mollieApiSecret: apiSecret
    },
    })
      .then((res) => {
        let apiResponse = getResponseForNotificaion(res)
        if (res.data.status === "success") {
          dispatch({ type: Constants.SUCCESS_GET_USER_INFO,userInfo:res.data });
        } else {
          dispatch({ type: Constants.FAILED_GET_USER_INFO,apiResponse:apiResponse });
        }
      })
      .catch((e) => {
        let errorResponse = getResponseForNotificaion(null,{})
        dispatch({ type: Constants.FAILED_GET_USER_INFO,apiResponse:errorResponse });
    });
  }

export const actionCreators = {

  getUserInfo:(apiKey,apiSecret) => async (dispatch,getState) =>{
  let {token} = getAuthData()
  dispatch({ type: Constants.REQUEST_GET_USER_INFO,token:token});
  let url = config.api.url+ "api/profile";


  axios({
    method: "post",
    url: url,
    headers: {
      Authorization: token,
    },
    data: {
      mollieApiKey: apiKey,
      mollieApiSecret: apiSecret
    },
    })
      .then((res) => {
        let apiResponse = getResponseForNotificaion(res)
        if (res.data.status === "success") {
          dispatch({ type: Constants.SUCCESS_GET_USER_INFO,userInfo:res.data });
        } else {
          dispatch({ type: Constants.FAILED_GET_USER_INFO,apiResponse:apiResponse });
        }
      })
      .catch((e) => {
        let errorResponse = getResponseForNotificaion(null,{})
        dispatch({ type: Constants.FAILED_GET_USER_INFO,apiResponse:errorResponse });
    });},

    postUserMollieInfo:(apiKey,apiSecret,name,email) => async (dispatch,getState) =>{
        dispatch({ type: Constants.REQUEST_POST_USER_MOLLIE_INFO });
        let url = config.api.url+ "api/save_user_mollie_info";
        let {token} = getAuthData()
        axios({
        method: "post",
        url: url,
        headers: {
            Authorization: token,
        },
        data: {
            mollieApiKey: apiKey,
            mollieApiSecret: apiSecret,
            mollieName: name,
            mollieEmail: email
        },
        })
        .then((res) => {
           // let aa = JSON.parse(res.data)
          let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {
            dispatch({ type: Constants.SUCCESS_POST_USER_MOLLIE_INFO,apiResponse:apiResponse });
          } else {
            dispatch({ type: Constants.FAILED_POST_USER_MOLLIE_INFO,apiResponse:apiResponse });
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({ type: Constants.FAILED_POST_USER_MOLLIE_INFO,apiResponse:errorResponse });
        });
    },
    getCompanyInfo:() => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/companyInformation`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_COMPANY_INFO})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                dispatch({type:Constants.SUCCESS_GET_COMPANY_INFO,companyInfo:res.data.data})
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_COMPANY_INFO})
            });
    },
    updateCompanyInfo:(companyName,add1,add2,zip,city,country,notes,subsId,payPer,packType) =>async (dispatch,getState) =>{
        dispatch({ type: Constants.REQUEST_UPDATE_COMPANY_INFO});
        let url = config.api.url+ "api/update_company_info";
        let {token} = getAuthData()
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                company_name: companyName,
                address_line_one: add1,
                address_line_two: add2,
                zipcode: zip,
                city:city,
                country: country,
                subscriptions_id:subsId,
                pay_per:payPer,
                package_type:packType
            },
        })
            .then((res) => {
                // let aa = JSON.parse(res.data)
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_UPDATE_COMPANY_INFO,apiResponse:apiResponse });
                } else {
                    dispatch({ type: Constants.FAILED_UPDATE_COMPANY_INFO,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_UPDATE_COMPANY_INFO,apiResponse:errorResponse });
            });
    },
    getPersonalDomain:() => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Personal_domain/getPersonalDomain`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_PERSONAL_DOMAIN})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                dispatch({type:Constants.SUCCESS_GET_PERSONAL_DOMAIN,domain:res.data.data})
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_PERSONAL_DOMAIN})
            });
    },
    getListItemPersonalDomain:(type) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Personal_domain/getPageFormList`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_PERSONAL_DOMAIN_ITEMS})
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                type:type
            },
        })
            .then((res) => {
                //let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_GET_PERSONAL_DOMAIN_ITEMS,items:res.data.list });
                } else {
                   // dispatch({ type: Constants.FAILED_POST_USER_MOLLIE_INFO,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_GET_PERSONAL_DOMAIN_ITEMS });
            });
    },
    savePersonalDomain:(name,type,typeId,isVerify) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Personal_domain/saveDomain`
        if(isVerify){
            url = `${config.api.url}api/Personal_domain/verifyAndSaveDomain`
        }
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_SAVE_PERSONAL_DOMAIN})
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                name:name,
                type:type,
                typeId:typeId
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_SAVE_PERSONAL_DOMAIN,apiResponse:apiResponse });

                    let urll = `${config.api.url}api/Personal_domain/getPersonalDomain`
                    let {token} = getAuthData()
                    dispatch({type:Constants.REQUEST_GET_PERSONAL_DOMAIN})
                    axios({
                        method: "get",
                        url: urll,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            dispatch({type:Constants.SUCCESS_GET_PERSONAL_DOMAIN,domain:res.data.data})
                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_PERSONAL_DOMAIN})
                        });


                } else {
                     dispatch({ type: Constants.FAILED_SAVE_PERSONAL_DOMAIN,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_SAVE_PERSONAL_DOMAIN,apiResponse:errorResponse });
            });
    },
    checkDuplicateDomain: (name) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Personal_domain/checkDuplicateDomain`
        let {token} = getAuthData()
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                domain_name:name
            },
        })
            .then((res) => {
                dispatch({ type: Constants.CHECK_PERSONAL_DOMAIN,isExist:res.data.status });
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_SAVE_PERSONAL_DOMAIN,isExist:true });
            });
    },
    generateKey: () => async (dispatch,getState) =>{
      debugger
        dispatch({ type: Constants.REQUEST_GENERATE_API_KEY });
        let url = `${config.api.url}api/generate_key`
        try{
            let response = await getAsync(url)
            if(response && response.data.status === "success"){
                let pKey = response.data.accounts.public_key
                let sKey = response.data.accounts.secret_key
                dispatch({ type: Constants.SUCCESS_GENERATE_API_KEY, pKey: pKey, sKey: sKey });
            }else{
                dispatch({ type: Constants.FAILED_GENERATE_API_KEY });
            }
        }catch (e) {
            dispatch({ type: Constants.FAILED_GENERATE_API_KEY });
        }
    },
    getPackageSubscriptionFees:() => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getSubscriptions`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_SUBSCRIPTION_FEES})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    let {list = [],apply_vat = '0'} = res.data
                    dispatch({type:Constants.SUCCESS_GET_SUBSCRIPTION_FEES,packages:list,svat:apply_vat})
                }else{
                    dispatch({type:Constants.FAILED_GET_SUBSCRIPTION_FEES})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_SUBSCRIPTION_FEES})
            });
    },
    savePackageSubscriptionFees:(packages,applyVat) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/addSubscriptions`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_SAVE_PERSONAL_DOMAIN})
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                packages:packages,
                apply_vat:applyVat
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_SAVE_PERSONAL_DOMAIN,apiResponse:apiResponse });


                    let urll = `${config.api.url}api/Subcriptions/getSubscriptions`
                    let {token} = getAuthData()
                    dispatch({type:Constants.REQUEST_GET_SUBSCRIPTION_FEES})
                    axios({
                        method: "get",
                        url: urll,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            let {list = [],apply_vat = '0'} = res.data
                            dispatch({type:Constants.SUCCESS_GET_SUBSCRIPTION_FEES,packages:list,svat:apply_vat})
                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_SUBSCRIPTION_FEES})
                        });


                } else {
                    dispatch({ type: Constants.FAILED_SAVE_PERSONAL_DOMAIN,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_SAVE_PERSONAL_DOMAIN,apiResponse:errorResponse });
            });
    },
    deleteSubscriptionFee:(id) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/deleteSubscriptions/${id}`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_DELETE_SUBSCRIPTION_FEE})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_DELETE_SUBSCRIPTION_FEE,apiResponse:apiResponse });


                    let urll = `${config.api.url}api/Subcriptions/getSubscriptions`
                    let {token} = getAuthData()
                    dispatch({type:Constants.REQUEST_GET_SUBSCRIPTION_FEES})
                    axios({
                        method: "get",
                        url: urll,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            dispatch({type:Constants.SUCCESS_GET_SUBSCRIPTION_FEES,packages:res.data.list})
                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_SUBSCRIPTION_FEES})
                        });



                } else {
                    dispatch({ type: Constants.FAILED_DELETE_SUBSCRIPTION_FEE,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_DELETE_SUBSCRIPTION_FEE})
            });
    },
    cancelCurrentPackage:() => async(dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/cancelSubscription`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_CANCEL_CURRENT_SUBSCRIPTION })
        try{
            const request = await getAsync(url);
            if(request){
                let apiResponse = getResponseForNotificaion(request)
                if(request.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_CANCEL_CURRENT_SUBSCRIPTION, apiResponse:apiResponse})
                }else{
                    dispatch({type:Constants.FAILED_CANCEL_CURRENT_SUBSCRIPTION, apiResponse:apiResponse})
                }
            }
        }catch(e){
            let errorResponse = getResponseForNotificaion(null,{})
            dispatch({type:Constants.FAILED_CANCEL_CURRENT_SUBSCRIPTION,res:errorResponse})
        }
    },
    saveCredit:(credits,applyVat) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/addCredit`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_ADD_CREDIT})
        axios({
            method: "post",
            url: url,
            headers: {
                Authorization: token,
            },
            data: {
                packages:credits,
                apply_vat:applyVat
            },
        })
            .then((res) => {                
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_ADD_CREDIT,apiResponse:apiResponse });

                    let urll = `${config.api.url}api/Subcriptions/getCredits`
                    let {token} = getAuthData()
                    dispatch({type:Constants.REQUEST_GET_CREDIT})
                    axios({
                        method: "get",
                        url: urll,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            if(res.data.status === "success"){
                                let {list = []} = res.data
                                let appVat = res.data.apply_vat ? res.data.apply_vat : '0'
                                dispatch({type:Constants.SUCCESS_GET_CREDIT,packages:list,cvat:appVat})
                            }else{
                                dispatch({type:Constants.FAILED_GET_CREDIT})
                            }

                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_CREDIT})
                        });
                } else {
                    dispatch({ type: Constants.FAILED_ADD_CREDIT,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({ type: Constants.FAILED_ADD_CREDIT,apiResponse:errorResponse });
            });
    },
    getCredits:() => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getCredits`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_CREDIT})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if(res.data.status === "success"){
                    let {list = [],apply_vat = '0'} = res.data
                    dispatch({type:Constants.SUCCESS_GET_CREDIT,packages:list,cvat:apply_vat})
                }else{
                    dispatch({type:Constants.FAILED_GET_CREDIT})
                }

            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_GET_CREDIT})
            });
    },
    deleteCredit:(id) => async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/deleteCredit/${id}`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_DELETE_SUBSCRIPTION_FEE})
        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                let apiResponse = getResponseForNotificaion(res)
                if (res.data.status === "success") {
                    dispatch({ type: Constants.SUCCESS_DELETE_SUBSCRIPTION_FEE,apiResponse:apiResponse });


                    let urll = `${config.api.url}api/Subcriptions/getCredits`
                    let {token} = getAuthData()
                    dispatch({type:Constants.REQUEST_GET_CREDIT})
                    axios({
                        method: "get",
                        url: urll,
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((res) => {
                            if(res.data.status === "success"){
                                let {list = [],apply_vat = '0'} = res.data
                                dispatch({type:Constants.SUCCESS_GET_CREDIT,packages:list,svat:apply_vat})
                            }else{
                                dispatch({type:Constants.FAILED_GET_CREDIT})
                            }

                        })
                        .catch((e) => {
                            let errorResponse = getResponseForNotificaion(null,{})
                            dispatch({type:Constants.FAILED_GET_CREDIT})
                        });



                } else {
                    dispatch({ type: Constants.FAILED_DELETE_SUBSCRIPTION_FEE,apiResponse:apiResponse });
                }
            })
            .catch((e) => {
                let errorResponse = getResponseForNotificaion(null,{})
                dispatch({type:Constants.FAILED_DELETE_SUBSCRIPTION_FEE})
            });
    },
    getPaymentHistory:() =>async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getSubscriptionHistory`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_PAYMENT_HISTORY}) 

        try{
            const request = await getAsync(url);
            if(request){
                if(request.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_PAYMENT_HISTORY,payload:request.data.list})
                }else{
                    dispatch({type:Constants.FAILED_GET_PAYMENT_HISTORY})
                }
            }
        }catch(e){
            let errorResponse = getResponseForNotificaion(null,{})
            dispatch({type:Constants.FAILED_GET_PAYMENT_HISTORY,res:errorResponse})
        }
        
    },
    getPaymentInvoiceData:(id) =>async (dispatch,getState) =>{
        let url = `${config.api.url}api/Subcriptions/getPaymentDetails/${id}`
        let {token} = getAuthData()
        dispatch({type:Constants.REQUEST_GET_PAYMENT_INVOICE_DATA })
        try{
            const request = await getAsync(url);
            if(request){
                if(request.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_PAYMENT_INVOICE_DATA,payload:request.data.data})
                }else{
                    dispatch({type:Constants.FAILED_GET_PAYMENT_INVOICE_DATA})
                }
            }
        }catch(e){
            let errorResponse = getResponseForNotificaion(null,{})
            dispatch({type:Constants.FAILED_GET_PAYMENT_INVOICE_DATA,res:errorResponse})
        }
    },
    resetResponse:() => async (dispatch,getState) =>{
        dispatch({type:Constants.RESET_PROFILE_RESPONSE})
    },
}
