import {
  FETCH_REPORT_GROUP,
  FETCH_REPORT_GROUP_SUCCESS,
  FETCH_REPORT_FILTER,
  FETCH_REPORT_FILTER_SUCCESS,
  FETCH_REPORT_RELATION,
  FETCH_REPORT_RELATION_SUCCESS,
  FETCH_REPORT_API,
  FETCH_REPORT_API_SUCCESS,
  FETCH_REPORT_LETTER,
  FETCH_REPORT_LETTER_SUCCESS
} from "constants/ActionTypes";

export const fetchReportGroup = params => {
  return {
    type: FETCH_REPORT_GROUP,
    payload: params
  };
};

export const fetchReportGroupSuccess = group => {
  return {
    type: FETCH_REPORT_GROUP_SUCCESS,
    payload: group
  };
};

export const fetchReportFilter = params => {
  return {
    type: FETCH_REPORT_FILTER,
    payload: params
  };
};

export const fetchReportFilterSuccess = filter => {
  return {
    type: FETCH_REPORT_FILTER_SUCCESS,
    payload: filter
  };
};

export const fetchReportRelation = params => {
  return {
    type: FETCH_REPORT_RELATION,
    payload: params
  };
};

export const fetchReportRelationSuccess = relation => {
  return {
    type: FETCH_REPORT_RELATION_SUCCESS,
    payload: relation
  };
};

export const fetchReportApi = params => {
  return {
    type: FETCH_REPORT_API,
    payload: params
  };
};

export const fetchReportApiSuccess = api => {
  return {
    type: FETCH_REPORT_API_SUCCESS,
    payload: api
  };
};

export const fetchReportLetter = params => {
  return {
    type: FETCH_REPORT_LETTER,
    payload: params
  };
};

export const fetchReportLetterSuccess = letter => {
  return {
    type: FETCH_REPORT_LETTER_SUCCESS,
    payload: letter
  };
};
