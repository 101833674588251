import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
    DialogContentText
} from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import config from "config";
import en from "@amcharts/amcharts4/lang/en";

class FieldTable extends React.Component {
    // deleteField = async (id) => (e) => {
    //   console.log(id);
    // };
    constructor(props) {
        super(props);
        this.state = {
            fieldId: "",
            deleteConfirmDialogOpen: false,
        }
    }

    handleClickDelete = (event) => {
        let id = event.currentTarget.id.split("-")[1]
        let idInNumber = parseInt(id)
        if (Number.isInteger(idInNumber)) {
            this.setState({deleteConfirmDialogOpen: true, fieldId: id})
        }


    }
    handleDelete = () => {
        this.props.handleRefresh();
        const token = localStorage.getItem("user_token");
        fetch(`${config.api.url}v1/relation/field/${this.state.fieldId}`, {
            method: "DELETE",
            headers: {
                Authorization: token,
            },
        })
            .then((res) => res.json()
            )
            .then(
                (json) => {
                    if (json.status === "error") {
                        console.error(json)
                    } else {
                        this.setState({deleteConfirmDialogOpen: false, fieldId: ""})
                    }

                }
            );
    }
    handleClose = () => {
        this.setState({deleteConfirmDialogOpen: false, fieldId: ""})
    }
    getTypeName = (name) =>{
        if(name === "int"){
            return <IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_INT"/>
        }else if(name === "string"){
            return <IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_STRING"/>
        }else if(name === "date"){
            return <IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_DATE"/>
        }else if(name === "float"){
            return <IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_FLOAT"/>
        }else{
            return <IntlMessages id="_ECLICKSPRO_NULL"/>
        }
    }

    render() {
        const {data} = this.props;
        return (
            <div>
                <div
                    className="table-responsive-material"
                    style={{width: "800px", border: "1px solid #4caf50"}}
                >
                    <Table>
                        <TableHead style={{backgroundColor: "#4caf50"}}>
                            <TableRow>
                                <TableCell>
                                    <IntlMessages id="_FIELD_NAME"/>
                                </TableCell>
                                <TableCell align="center">
                                    <IntlMessages id="_TYPE"/>
                                </TableCell>
                                <TableCell>
                                    <IntlMessages id="_DESCRIPTION"/>
                                </TableCell>
                                <TableCell align="center">
                                    <IntlMessages id="_DELETE"/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((n) => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell>{n.name}</TableCell>
                                        <TableCell align="center">{this.getTypeName(n.type)}</TableCell>
                                        {/*<TableCell align="center">{n.type === "int" ? "number" : n.type}</TableCell>*/}
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell align="center">
                                            <i
                                                id={"dlt-" + n.id}
                                                class="zmdi zmdi-delete zmdi-hc-2x"
                                                style={{color: "red"}}
                                                onClick={this.handleClickDelete}
                                            ></i>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <Dialog open={this.state.deleteConfirmDialogOpen} onClose={this.handleClose}>
                    <DialogTitle>{<IntlMessages id="_ARE_YOU_SURE_TO_DELETE"/>}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {<IntlMessages id="_DELETE_CONFIRMATION_MESSAGE"/>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            {<IntlMessages id="_CANCEL"/>}
                        </Button>
                        <Button onClick={this.handleDelete} color="primary">
                            {<IntlMessages id="_CONFIRM_DELETE"/>}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );

    }
}

class CustomFieldDialog extends React.Component {
    state = {
        types: null,
        items: null,
        name: "",
        type: "0",
        description: "",
        refresh: false,
        disableAddButton: true
    };

    async componentWillMount() {
        const token = localStorage.getItem("user_token");
        const response = await fetch(`${config.api.url}v1/relation/field`, {
            method: "GET",
            headers: {
                Authorization: token,
            },
        }).then((res) => res.json());

        if (response.status === "success") {
            this.setState({types: response.data.types, items: response.data.items});
        }
    }

    handleRefresh = () => {
        this.setState({refresh: false});
    };

    handleAddRequest = () => {
        const {name, type, description} = this.state;
        if (name !== "" || type !== "0" || description !== "") {
            this.handleRefresh();
            this.props.addCustomFields(name, type, description);
            this.props.handleRequestClose();
            this.setState({name: "", type: "0", description: ""});
        } else {
            alert("Not ok")
        }

    };

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.tableName === "relation" && !this.state.refresh) {
            const token = localStorage.getItem("user_token");
            const response = await fetch(`${config.api.url}v1/relation/field`, {
                method: "GET",
                headers: {
                    Authorization: token,
                },
            }).then((res) => res.json());

            if (response.status === "success") {
                this.setState({refresh: true});
                if (prevState.items !== response.data.items) {
                    this.setState({items: response.data.items});
                }
            }
        }
    }

    handleChange = (e) => {
        if (e.target.id === "name" || e.target.id === "description") {
            this.setState({[e.target.id]: e.target.value})
        } else {
            this.setState({type: e.target.value})
            if (this.state.name === "" || this.state.description === "") {
                this.setState({disableAddButton: true})
            } else {
                this.setState({disableAddButton: false})
            }
        }
        if (this.state.name === ""
            || this.state.description === ""
            || e.target.value === ""
            || e.target.value === "0") {
            this.setState({disableAddButton: true})
        } else {
            this.setState({disableAddButton: false})
        }

    }
    handleClose = () => {
        this.props.handleRequestClose()
        this.setState({name: "", type: "0", description: ""});
    }

    render() {
        const {open} = this.props;
        const {types, items, name, type, description} = this.state;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.handleRequestClose}
                    maxWidth="lg"
                >
                    <DialogTitle>
                        <IntlMessages id="_ECLICKSPRO_CUSTOM_FIELDS"/>
                    </DialogTitle>

                    {items && types && (
                        <DialogContent>
                            <FieldTable data={items} handleRefresh={this.handleRefresh}/>
                            <div style={{display: "flex", margin: "1rem auto"}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Value*"
                                    fullWidth
                                    id="name"
                                    value={name}
                                    onChange={this.handleChange}
                                />
                                <FormControl
                                    fullWidth
                                    style={{paddingTop: "5px", paddingLeft: "10px"}}
                                >
                                    <InputLabel style={{marginLeft: "10px"}}>
                                        <IntlMessages id="_TYPE"/><span>*</span>
                                    </InputLabel>
                                    <Select
                                        value={type}
                                        onChange={this.handleChange}
                                        id="type"
                                        // input={<Input id="ageSimple1" />}
                                    >
                                        <MenuItem value="0" disabled>
                                            <em>Please Select</em>
                                        </MenuItem>
                                        <MenuItem value={"int"}><IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_INT"/></MenuItem>
                                        <MenuItem value={types.string}><IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_STRING"/></MenuItem>
                                        <MenuItem value={types.date}><IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_DATE"/></MenuItem>
                                        <MenuItem value={types.float}><IntlMessages id="_ECLICKSPRO_CUSTOM_FIELD_TYPE_FLOAT"/></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                <span style={{color: "#4caf50"}}>
                  <IntlMessages id="_DESCRIPTION"/><span>*</span>
                </span>
                                <TextareaAutosize
                                    style={{width: "100%"}}
                                    rowsMin={2}
                                    id="description"
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </DialogContent>
                    )}

                    <DialogActions>
                        <Button onClick={this.handleAddRequest} disabled={this.state.disableAddButton} color="primary">
                            <IntlMessages id="_ADD"/>
                        </Button>
                        <Button onClick={this.handleClose} color="secondary">
                            <IntlMessages id="_CANCEL"/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default CustomFieldDialog;
