import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS
} from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData, setAuthData } from "../common/helpers/AuthHelper";
import {postAsync} from "../common/helpers/HttpService";


export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const resetPassword = email => {
  return {
    type: RESET_PASSWORD,
    payload: email
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};

export const resetPasswordSuccess = status => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: status
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = authUser => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = authUser => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = authUser => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = authUser => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const actionCreators = {
  checkAuthorization: (menu) => async (dispatch, getState) => {
    dispatch({ type: Constants.REQUEST_CHECK_AUTHORIZATION })
    let url = config.api.url + "api/" + menu + "/getPermisions"
    let {token} = getAuthData()

    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          dispatch({ type: Constants.SUCCESS_CHECK_AUTHORIZATION })
        } else {
          dispatch({ type: Constants.FAILED_CHECK_AUTHORIZATION })
        }
      })
      .catch((e) => {
        dispatch({ type: Constants.FAILED_CHECK_AUTHORIZATION })
      });
  },
  signIn: (email, password) => async (dispatch, getState) => {
    dispatch({ type: Constants.REQUEST_SIGN_IN })
    let url = config.api.url + "api/login_api/login";
    let postData = {email,password}
    try {
      let response = await postAsync(url,postData);
      if(response){
        let apiResponse = getResponseForNotificaion(response)
        if(response.data.status === "success"){
          setAuthData(response.data)
          dispatch({ type: Constants.SUCCESS_SIGN_IN,token:response.data.user.token })
        }else {
          dispatch({ type: Constants.FAILED_SIGN_IN, apiResponse: apiResponse })
        }
      }

    }catch (e) {
      let errorResponse = getResponseForNotificaion(null, {})
      dispatch({ type: Constants.FAILED_SIGN_IN, apiResponse: errorResponse })
    }
  },
  resetResponse: () => async (dispatch, getState) => {
    dispatch({ type: Constants.RESET_AUTH_API_RESPONSE })
  }
}
