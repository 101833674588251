import React, { Component } from 'react';
import PaymentInvoice from "./PaymentInvoice"

//Material
import { withStyles } from "@material-ui/core/styles";

//Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators} from "../../../../../../actions/Profile";

const useStyles = theme => ({

    mainWrapperBox:{
        border:"1px solid #D7D7D7",
        borderRadius:"10px",
        padding:"20px"
    }
});

class PaymentInvoiceContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            invoiceData:null
         }
    }
    componentDidMount(){
        let url = window.location.href;
        let urlArray = url.split("/");
        let id = parseInt(urlArray[urlArray.length - 1]);

        this.props.getPaymentInvoiceData(id)
    }
    componentDidUpdate(prevProps,prevState,snapshot){
        //debugger
        if(prevProps.profileData.paymentInvoice != this.props.profileData.paymentInvoice && this.props.profileData.paymentInvoice !== null){
            this.setState({
                invoiceData:this.props.profileData.paymentInvoice
            })
        }
    }
    render() { 
        return ( 
            <div className={"app-wrapper"}>
                {this.state.invoiceData?
                    <PaymentInvoice data={this.state.invoiceData}></PaymentInvoice>
                    :
                    <div>Loading........</div>
                }
                
            </div>
         );
    }
}
 
// export default PaymentInvoiceContainer;
const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyles)(PaymentInvoiceContainer));