import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_CHILD,
  FETCH_CATEGORIES_CHILD_SUCCESS,
  FETCH_CATEGORIES_ROOT,
  FETCH_CATEGORIES_ROOT_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  root: null,
  rootParam: null,
  categoryParams: null,
  childParams: null,
  category: null,
  child: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES: {
      return {
        ...state,
        loader: true,
        categoryParams: action.payload
      };
    }
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        category: action.payload
      };
    }
    case FETCH_CATEGORIES_ROOT: {
      return {
        ...state,
        loader: true,
        rootParam: action.payload
      };
    }
    case FETCH_CATEGORIES_ROOT_SUCCESS: {
      return {
        ...state,
        loader: false,
        root: action.payload
      };
    }
    case FETCH_CATEGORIES_CHILD: {
      return {
        ...state,
        loader: true,
        childParams: action.payload
      };
    }
    case FETCH_CATEGORIES_CHILD_SUCCESS: {
      return {
        ...state,
        loader: false,
        child: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
