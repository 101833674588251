import {
    FETCH_LETTER_RELATION,
    FETCH_LETTER_RELATION_SUCCESS
  } from "constants/ActionTypes";
  
  export const fetchLetterRelation = params => {
    return {
      type: FETCH_LETTER_RELATION,
      payload: params
    };
  };
  
  export const fetchLetterRelationSuccess = filters => {
    return {
      type: FETCH_LETTER_RELATION_SUCCESS,
      payload: filters
    };
  };