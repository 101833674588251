import React, { Component } from "react";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../actions/Profile";

import CircularProgress from "@material-ui/core/CircularProgress";
import ContainerHeader from "components/ContainerHeader/index";
import CardBox from "components/CardBox";
import FullWidthTabs from "./components/FullWidthTabs";
import IntlMessages from "util/IntlMessages";
import { profileInfo } from "actions/Profile";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Redirect } from "react-router";
import EproPaper from "../customComponents/Paper/EproPaper";


let componentFor = "profile"
let responseName = "profileApiResponse"

class Profile extends Component {
  componentDidMount() {
    this.props.getUserInfo();

    //this block is going to tab 4 when click from campaign mollie card update profile
    if(this.props.location.pathname !== undefined || this.props.location.pathname !== null){
      let pathName = this.props.location.pathname
      if(pathName !== undefined){
        let pathNameArr = pathName.split("/")
        let tabValue = pathNameArr[pathNameArr.length-1]
        let tabValueInInt = parseInt(tabValue)
        if(!isNaN(tabValueInInt)){
          this.props.history.push("/app/profile",{tabValue:4})
        }
      }
    }
    //this block is going to tab 4 when click from campaign mollie card update profile
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
  	if(prevProps[componentFor][responseName]!== this.props[componentFor][responseName] && this.props[componentFor][responseName] !== null){
  		//let res = this.props.apiResponse
  		NotificationManager[this.props[componentFor][responseName].alertType](this.props[componentFor][responseName].message)
  		this.props.resetResponse()
  	}
  }
  updateMollieInfo = (key,secret,name,email) =>{
    this.props.postUserMollieInfo(key,secret,name,email)
  }
  generateKey = () =>{
    this.props.generateKey()
  }
  
  updateCompanyInfo = (companyName,addressLineOne,addressLineTwo,zipcode,city,country) =>{
    this.props.updateCompanyInfo(companyName,addressLineOne,addressLineTwo,zipcode,city,country)
  }
  render() {
    // console.log("this.props", this.props);
    //const { match, loader, token, info } = this.props;
    const { match, profile } = this.props;
    // console.log(info);
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="_PROFILE" />}
            match={match}
          />
          {(profile.loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )) || (
            <div className="row">
              <CardBox styleName="col-lg-12">
                {profile.info &&
                <FullWidthTabs
                    value={profile.info}
                    token={profile.token}
                    updateMollieInfo={this.updateMollieInfo}
                    updateCompanyInfo={this.updateCompanyInfo}
                    tabValue={this.props.location.state}
                    cancelSubscription={this.props.cancelCurrentPackage}
                    tabValue={this.props.location.state?.tabValue}
                    generateKey={this.generateKey}
                    publicKey={profile.info.accountDetails?.public_key ? profile.info.accountDetails.public_key : ""}
                    secretKey={profile.info.accountDetails?.secret_key ? profile.info.accountDetails.secret_key : ""}
                />}
              </CardBox>
            </div>
          )}
          <NotificationContainer />
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({ profile }) => {
//   // console.log("profile->", profile);
//   const { loader, token, info } = profile;
//   return { loader, token, info };
// };
//
// export default connect(mapStateToProps, {
//   profileInfo
// })(Profile);

const mapStateToProps = (state) =>({
  profile:state.profile
})

export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators}, dispatch)
)(Profile);
