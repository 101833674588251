// import React, { Component } from "react";
// import moment from "moment";
// import { connect } from "react-redux";
// import { userSignIn } from "actions/Auth";
// import { fetchEmail, fetchEmailFolder } from "actions/Email";
// import ContainerHeader from "components/ContainerHeader/index";
// import IntlMessages from "util/IntlMessages";
// import {
// 	NotificationContainer,
// 	NotificationManager,
// } from "react-notifications";
// import {
// 	IconButton,
// 	Tooltip,
// 	List,
// 	ListItem,
// 	ListItemAvatar,
// 	ListItemText,
// 	Avatar,
// 	CircularProgress,
// 	Menu,
// 	MenuItem,
// } from "@material-ui/core";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import ShareIcon from "@material-ui/icons/Share";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
// import config from "config";
//
// import DialogBox from "./components/DialogBox";
// import SaveDialogBox from "./components/SaveDialogBox";
// import { SettingContext } from "context/SettingContext";
// import "./index.css";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
//
// import { bindActionCreators } from "redux";
// import { actionCreators } from "../../../../../actions/Form";
// import AuthorizationWrapper from "../../../customComponents/AuthComponents/AuthorizationWrapper";
//
// let selectedForm = [];
// let selectedFolder = [];
//
// class FormFolderView extends Component {
// 	static contextType = SettingContext;
// 	state = {
// 		folderId: this.props.match.params.folderId,
// 		open: false,
// 		type: null,
// 		value: "",
// 		tempId: null,
// 		anchorEl: null,
// 		addDialogOpen: false,
// 		addFormDialogOpen: false,
// 		renameDialogOpen: false,
// 		renameFolderDialogOpen: false,
// 		folderName: "",
// 		formName: "",
// 	};
//
// 	componentWillMount() {
// 		const params = {
// 			id: this.state.folderId,
// 			token: this.props.authUser,
// 		};
// 		let url = window.location.href;
// 		let urlArr = url.split("/");
// 		let id = urlArr[urlArr.length - 1];
//
// 		this.props.fetchForm(id);
// 	}
// 	componentWillUnmount() {
// 		this.props.cleanList();
// 	}
// 	handleOnClickform = (id, name) => (e) => {
// 		this.setState({ value: name, type: "form", tempId: id });
// 		if (selectedForm.includes(id)) {
// 			document.getElementById(`${id}`).style.backgroundColor = null;
// 			selectedForm = selectedForm.filter((old) => old !== id);
// 		} else {
// 			selectedForm.push(id);
// 			selectedForm.forEach(() => {
// 				document.getElementById(`${id}`).style.backgroundColor = "#c8e6c9";
// 			});
// 		}
// 	};
// 	handleOnClickFolder = (id, name) => (e) => {
// 		this.setState({ value: name, type: "folder", tempId: id });
// 		if (selectedFolder.includes(id)) {
// 			document.getElementById(`${id}`).style.backgroundColor = null;
// 			selectedFolder = selectedFolder.filter((old) => old !== id);
// 		} else {
// 			selectedFolder.push(id);
// 			selectedFolder.forEach(() => {
// 				document.getElementById(`${id}`).style.backgroundColor = "#c8e6c9";
// 			});
// 		}
// 	};
// 	handleDelete = () => {
// 		this.setState({ type: null, open: false });
//
// 		if (selectedForm.length > 0) {
// 			for (let f = 0; f < selectedForm.length; f++) {
// 				let elementId = document.getElementById(`${selectedForm[f]}`);
// 				if (elementId !== null) {
// 					document.getElementById(`${selectedForm[f]}`).style.backgroundColor = null;
// 				}
// 			}
// 		}
// 		if (selectedFolder.length > 0) {
// 			for (let l = 0; l < selectedFolder.length; l++) {
// 				let elementId = document.getElementById(`${selectedForm[l]}`);
// 				if (elementId !== null) {
// 					document.getElementById(
// 						`${selectedFolder[l]}`
// 					).style.backgroundColor = null;
// 				}
// 			}
// 		}
// 		this.props.deleteAll(selectedForm, selectedFolder);
// 		this.setSelectedItemsEmpty();
// 	};
// 	handleOnClickDelete = () => {
// 		// if (selectedForm.includes(this.state.tempSharedForm)) {
// 		// 	NotificationManager.error("Cannot delete the shared form");
// 		// } else
// 		if (selectedForm.length > 0 || selectedFolder.length > 0) {
// 			this.setState({ open: true, type: "delete" });
// 		} else {
// 			NotificationManager.error("Nothing is selected");
// 		}
// 	};
//
// 	handleOnClickRename = () => {
// 		if (selectedForm.length > 1) {
// 			NotificationManager.error("You cannot select mulitple " + this.state.type);
// 		} else {
// 			this.setState({ open: true });
// 		}
// 	};
//
// 	handleOnClickCopy = async () => {
// 		if (selectedForm.length > 1) {
// 			return NotificationManager.error("Cannot select multiple forms");
// 		}
// 		if (selectedForm.length < 1) {
// 			return NotificationManager.error("No form is selected");
// 		}
// 		let url = window.location.href;
// 		let urlArr = url.split("/");
// 		let id = urlArr[urlArr.length - 1];
// 		this.props.copyForm(selectedForm[0], id);
// 		this.setSelectedItemsEmpty();
// 		// if (selectedForm.length > 1) {
// 		// 	return NotificationManager.error("Cannot select multiple forms");
// 		// }
// 		// if (selectedForm.length < 1) {
// 		// 	return NotificationManager.error("No form is selected");
// 		// }
//
// 		// const getSelectedData = await fetch(
// 		// 	`${config.api.url}v1/form/${selectedForm[0]}`,
// 		// 	{
// 		// 		method: "GET",
// 		// 		headers: {
// 		// 			Authorization: this.props.authUser,
// 		// 		},
// 		// 	}
// 		// )
// 		// 	.then((res) => res.json())
// 		// 	.then((json) => json);
//
// 		// console.log("getSelectedData --->", getSelectedData);
// 		// if (getSelectedData.status === "success") {
// 		// 	this.setSelectedItemsEmpty();
// 		// 	const response = await fetch(`${config.api.url}v1/form`, {
// 		// 		method: "POST",
// 		// 		headers: {
// 		// 			Authorization: this.props.authUser,
// 		// 			"Content-Type": "application/json",
// 		// 		},
// 		// 		body: JSON.stringify({
// 		// 			name: getSelectedData.data.name + " - Copy",
// 		// 			data: getSelectedData.data.content,
// 		// 		}),
// 		// 	})
// 		// 		.then((res) => res.json())
// 		// 		.then((json) => json);
// 		// 	// console.log("response --->", response);
// 		// 	if (response.status === "success") {
// 		// 		const params = {
// 		// 			token: this.props.authUser,
// 		// 		};
// 		// 		this.props.fetchForm(params);
// 		// 	}
// 		// }
// 	};
//
// 	fetchEditRequest = () => {
// 		this.setSelectedItemsEmpty();
// 		const params = {
// 			token: this.props.authUser,
// 		};
// 		this.props.fetchForm(params);
// 	};
// 	dragStart = (e) => {
// 		// console.log("drag start", e.target.className);
// 		if (e.target.className.includes("form")) {
// 			e.dataTransfer.setData(
// 				"data",
// 				JSON.stringify({
// 					id: e.target.id,
// 					type: "form",
// 				})
// 			);
// 		} else {
// 			e.dataTransfer.setData(
// 				"data",
// 				JSON.stringify({
// 					id: e.target.id,
// 					type: "folder",
// 				})
// 			);
// 		}
// 	};
//
// 	dragOver = (e) => {
// 		e.preventDefault();
// 	};
//
// 	drop = (e) => {
// 		e.preventDefault();
// 		const data = JSON.parse(e.dataTransfer.getData("data"));
// 		let parentId = e.target.parentNode.id;
// 		// console.log("drag data ->", data);
// 		// console.log("folder id->", e.target.parentNode.id);
//
// 		if (data.type === "form") {
// 			this.props.updateFormParent(data.id, parentId);
// 		}
//
// 		if (data.type === "folder") {
// 			this.props.updateFolderParent(data.id, parentId);
// 		}
// 	};
//
// 	handleRequestClose = () => {
// 		this.setState({
// 			open: false,
// 			type: null,
// 			openShare: false,
// 			openSave: false,
// 		});
// 	};
//
// 	setSelectedItemsEmpty = () => {
// 		selectedForm = [];
// 		selectedFolder = [];
// 	};
//
// 	handleSaveDialogBox = (name) => {
// 		this.setState({ anchorEl: null, type: name, openSave: true });
// 	};
//
// 	handleClose = () => {
// 		this.setState({
// 			addDialogOpen: false,
// 			addFormDialogOpen: false,
// 			renameDialogOpen: false,
// 			renameFolderDialogOpen: false,
// 			folderName: "",
// 		});
// 	};
// 	openFolderAddDialog = () => {
// 		this.setState({ addDialogOpen: true, anchorEl: null });
// 	};
// 	openFormAddDialog = () => {
// 		this.setState({ addFormDialogOpen: true, anchorEl: null });
// 	};
// 	openRenameDialog = () => {
// 		let selectedCount = selectedForm.length + selectedFolder.length;
// 		if (selectedCount === 1) {
// 			if (selectedForm.length === 1) {
// 				let item = this.props.formData.forms.filter(
// 					(i) => i.id === selectedForm[0]
// 				);
// 				if (item.length > 0) {
// 					this.setState({ formName: item[0].name });
// 				}
// 				this.setState({ renameDialogOpen: true });
// 			}
// 			if (selectedFolder.length === 1) {
// 				let item = this.props.formData.folders.filter(
// 					(i) => i.id === selectedFolder[0]
// 				);
// 				if (item.length > 0) {
// 					this.setState({ folderName: item[0].name });
// 				}
// 				this.setState({ renameFolderDialogOpen: true });
// 			}
// 		}
// 		if (selectedCount > 1) {
// 			NotificationManager.error("You cannot select mulitple ");
// 		}
// 		if (selectedCount < 1) {
// 			NotificationManager.error("Nothing is selected");
// 		}
// 	};
// 	renameForm = () => {
// 		this.props.renameForm(selectedForm[0], this.state.formName);
// 		this.setState({ renameDialogOpen: false });
// 		this.setSelectedItemsEmpty();
// 	};
// 	renameFolder = () => {
// 		this.props.renameFolder(selectedFolder[0], this.state.folderName);
// 		this.setState({ renameFolderDialogOpen: false });
// 		this.setSelectedItemsEmpty();
// 	};
// 	handleChangeTextBox = (e) => {
// 		let id = e.currentTarget.id;
// 		this.setState({ [id]: e.target.value });
// 	};
// 	addNewFolder = () => {
// 		let url = window.location.href;
// 		let urlArr = url.split("/");
// 		let id = urlArr[urlArr.length - 1];
// 		this.props.addFolder(this.state.folderName, id);
// 		this.setState({ addDialogOpen: false, folderName: "" });
// 	};
// 	addNewForm = () => {
// 		let url = window.location.href;
// 		let urlArr = url.split("/");
// 		let id = urlArr[urlArr.length - 1];
// 		this.props.addForm(this.state.formName, id);
// 		this.setState({ addFormDialogOpen: false, formName: "" });
// 	};
//
// 	render() {
// 		debugger
// 		const { match, loader, folder, emails, history } = this.props;
// 		const {
// 			open,
// 			type,
// 			value,
// 			tempId,
// 			openShare,
// 			openSave,
// 			folderId,
// 			anchorEl,
// 		} = this.state;
// 		// console.log("props from email folderview ---->>>", this.props);
//
// 		let folderName = ""
// 		let prevId = "root"
// 		if(history.location.state !== undefined){
// 			folderName = history.location.state.name
// 			prevId = history.location.state.prevId
// 		}
// 		if(history.location.state !== undefined){
// 			match.url = match.url.replace(
// 				this.props.match.params.folderId.toString(),
// 				folderName
// 			);
// 		}
//
// 		const splitedUrl = match.url.split("/");
// 		let editUrl = "/" + splitedUrl[1] + "/" + splitedUrl[2] + "/" + splitedUrl[3];
//
// 		const settings = JSON.parse(this.context.settings.data);
//
// 		if (this.props.formData.newFormCreated) {
// 			let rUrl = editUrl + "/edit/" + this.props.formData.newFormId;
// 			this.props.history.push(rUrl,{name:"",prevId:""});
// 		}
//
// 		let folderDiv = [];
// 		let formsDiv = [];
// 		if (this.props.formData.forms.length > 0) {
// 			let forms = this.props.formData.forms;
// 			for (let i = 0; i < forms.length; i++) {
// 				formsDiv.push(
// 					<div
// 						id={forms[i].id}
// 						className="col-3 form"
// 						key={i}
// 						draggable="true"
// 						onDragStart={this.dragStart}
// 					>
// 						<List
// 							onDoubleClick={() =>
// 								history.push(`${editUrl}/edit/${forms[i].id}`, {
// 									name: forms[i].name,
// 								})
// 							}
// 							onClick={this.handleOnClickform(forms[i].id, forms[i].name)}
// 						>
// 							<ListItem button id={forms[i].id}>
// 								<ListItemAvatar style={{ position: "relative" }}>
// 									<Avatar alt={forms[i].name} src={forms[i].preview} />
// 								</ListItemAvatar>
// 								<ListItemText
// 									primary={
// 										forms[i].name.length > settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
// 											? forms[i].name.slice(
// 													0,
// 													settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
// 											  ) + "..."
// 											: forms[i].name
// 									}
// 									secondary={moment.unix(forms[i].created_at).format("MMM Do, YYYY")}
// 								/>
// 							</ListItem>
// 						</List>
// 					</div>
// 				);
// 			}
// 		}
// 		if (this.props.formData.folders.length > 0) {
// 			let folders = this.props.formData.folders;
// 			for (let j = 0; j < folders.length; j++) {
// 				folderDiv.push(
// 					<div
// 						id={folders[j].id}
// 						className="col-3"
// 						key={j}
// 						draggable="true"
// 						onDragStart={this.dragStart}
// 						onDragOver={this.dragOver}
// 						onDrop={this.drop}
// 						// onDragEnter={this.dragEnter}
// 						// onDragLeave={this.dragLeave}
// 					>
// 						<List
// 							id={folders[j].id}
// 							onDoubleClick={() =>
// 								history.push(`/app/custom-views/form/${folders[j].id}`, {
// 									name: folders[j].name,
// 									prevId: "root",
// 								})
// 							}
// 							onClick={this.handleOnClickFolder(folders[j].id, folders[j].name)}
// 						>
// 							<ListItem button id={folders[j].id}>
// 								<ListItemAvatar id={folders[j].id}>
// 									<Avatar style={{ backgroundColor: "#43a047" }} id={folders[j].id}>
// 										<i
// 											id={folders[j].id}
// 											className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
// 										/>
// 									</Avatar>
// 								</ListItemAvatar>
// 								<ListItemText
// 									id={folders[j].id}
// 									primary={
// 										folders[j].name.length > settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
// 											? folders[j].name.slice(
// 													0,
// 													settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
// 											  ) + "..."
// 											: folders[j].name
// 									}
// 									secondary={moment.unix(folders[j].created_at).format("MMM Do, YYYY")}
// 								/>
// 							</ListItem>
// 						</List>
// 					</div>
// 				);
// 			}
// 		}
//
// 		return (
// 			<AuthorizationWrapper menu={"form"}>
// 				<div>
// 					<div className="animated slideInUpTiny animation-duration-3">
// 						<ContainerHeader title={<IntlMessages id="_FORM" />} match={match} />
// 					</div>
// 					<div>
// 						<Tooltip
// 							title="Add New form"
// 							style={{ position: "relative", display: "inline-block" }}
// 						>
// 							<IconButton aria-label="Add New form">
// 								<AddCircleIcon
// 									color="primary"
// 									fontSize="default"
// 									onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
// 								/>
// 								{anchorEl && (
// 									<div>
// 										<Menu
// 											anchorEl={anchorEl}
// 											keepMounted
// 											open={Boolean(anchorEl)}
// 											onClose={() => this.setState({ anchorEl: null })}
// 											style={{
// 												position: "absolute",
// 												marginTop: "10px",
// 												marginLeft: "35px",
// 											}}
// 										>
// 											{/* <MenuItem onClick={() => this.handleSaveDialogBox("form")}>
// 												<IntlMessages id="_ADD_FORM" />
// 											</MenuItem> */}
// 											<MenuItem onClick={this.openFormAddDialog}>
// 												<IntlMessages id="_ADD_FORM" />
// 											</MenuItem>
// 											<MenuItem onClick={this.openFolderAddDialog}>
// 												<IntlMessages id="_ADD_FOLDER" />
// 											</MenuItem>
// 										</Menu>
// 									</div>
// 								)}
// 							</IconButton>
// 						</Tooltip>
//
// 						<Tooltip title="Copy form">
// 							<IconButton aria-label="Copy form" onClick={this.handleOnClickCopy}>
// 								<FileCopyIcon color="secondary" fontSize="default" />
// 							</IconButton>
// 						</Tooltip>
//
// 						<Tooltip title="Rename">
// 							<IconButton aria-label="Rename" onClick={this.openRenameDialog}>
// 								<EditIcon color="action" fontSize="default" />
// 							</IconButton>
// 						</Tooltip>
//
// 						<Tooltip title="Delete form">
// 							<IconButton aria-label="Delete form" onClick={this.handleOnClickDelete}>
// 								<DeleteIcon color="error" fontSize="default" />
// 							</IconButton>
// 						</Tooltip>
// 					</div>
// 					<div>
// 						{this.props.formData.loader === true ? (
// 							<div className="loader-view">
// 								<CircularProgress />
// 							</div>
// 						) : (
// 							<div className="row">
// 								<div
// 									id={prevId}
// 									className="col-3"
// 									draggable="true"
// 									onDragStart={this.dragStart}
// 									onDragOver={this.dragOver}
// 									onDrop={this.drop}
// 								>
// 									<List
// 										id={prevId}
// 										onClick={() => history.goBack()}
// 									>
// 										<ListItem button id={prevId}>
// 											<ListItemAvatar id={prevId}>
// 												<Avatar
// 													id={prevId}
// 													style={{ backgroundColor: "#43a047" }}
// 												>
// 													<i
// 														id={prevId}
// 														className="zmdi zmdi-folder zmdi-hc-fw zmdi-hc-lg"
// 													/>
// 												</Avatar>
// 											</ListItemAvatar>
// 											<ListItemText
// 												id= {prevId}
// 												primary=". . ."
// 												// secondary={moment
// 												//   .unix(item.created_at)
// 												//   .format("MMM Do, YYYY")}
// 											/>
// 										</ListItem>
// 									</List>
// 								</div>
//
// 								{folderDiv}
// 								{formsDiv}
// 							</div>
// 						)}
// 					</div>
//
// 					{type && (
// 						<DialogBox
// 							token={this.props.authUser}
// 							open={open}
// 							type={type}
// 							value={value}
// 							id={tempId}
// 							handleRequestClose={this.handleRequestClose}
// 							fetchEditRequest={this.fetchEditRequest}
// 							handleDelete={this.handleDelete}
// 						/>
// 					)}
// 					{type && (
// 						<SaveDialogBox
// 							token={this.props.authUser}
// 							open={openSave}
// 							type={type}
// 							handleRequestClose={this.handleRequestClose}
// 							parentId={folderId}
// 							fetchEditRequest={this.fetchEditRequest}
// 						/>
// 					)}
// 					{/* add folder dialog */}
// 					<Dialog
// 						open={this.state.addDialogOpen}
// 						onClose={this.handleClose}
// 						aria-labelledby="alert-dialog-title"
// 						aria-describedby="alert-dialog-description"
// 					>
// 						<DialogTitle id="alert-dialog-title">
// 							<IntlMessages id="_ADD_FOLDER" />
// 						</DialogTitle>
//
// 						<DialogContent>
// 							<TextField
// 								id="folderName"
// 								label={<IntlMessages id="_ADD_FOLDER" />}
// 								fullWidth
// 								onChange={this.handleChangeTextBox}
// 							/>
// 						</DialogContent>
//
// 						<DialogActions>
// 							<Button onClick={this.handleClose} color="primary">
// 								{<IntlMessages id="_CLOSE" />}
// 							</Button>
// 							<Button onClick={this.addNewFolder} color="primary" autoFocus>
// 								{<IntlMessages id="_SAVE" />}
// 							</Button>
// 						</DialogActions>
// 					</Dialog>
// 					{/* add form dialog */}
// 					<Dialog
// 						open={this.state.addFormDialogOpen}
// 						onClose={this.handleClose}
// 						aria-labelledby="alert-dialog-title"
// 						aria-describedby="alert-dialog-description"
// 					>
// 						<DialogTitle id="alert-dialog-title">
// 							<IntlMessages id="_ADD_FORM" />
// 						</DialogTitle>
//
// 						<DialogContent>
// 							<TextField
// 								id="formName"
// 								label={<IntlMessages id="_ADD_FORM" />}
// 								fullWidth
// 								onChange={this.handleChangeTextBox}
// 							/>
// 						</DialogContent>
//
// 						<DialogActions>
// 							<Button onClick={this.handleClose} color="primary">
// 								{<IntlMessages id="_CLOSE" />}
// 							</Button>
// 							<Button onClick={this.addNewForm} color="primary" autoFocus>
// 								{<IntlMessages id="_SAVE" />}
// 							</Button>
// 						</DialogActions>
// 					</Dialog>
// 					{/* rename form dialog */}
// 					<Dialog
// 						open={this.state.renameDialogOpen}
// 						onClose={this.handleClose}
// 						aria-labelledby="alert-dialog-title"
// 						aria-describedby="alert-dialog-description"
// 					>
// 						<DialogTitle id="alert-dialog-title">
// 							<IntlMessages id="_RENAME_FORM" />
// 						</DialogTitle>
//
// 						<DialogContent>
// 							<TextField
// 								id="formName"
// 								label={<IntlMessages id="_RENAME_FORM" />}
// 								fullWidth
// 								onChange={this.handleChangeTextBox}
// 								value={this.state.formName}
// 							/>
// 						</DialogContent>
//
// 						<DialogActions>
// 							<Button onClick={this.handleClose} color="primary">
// 								{<IntlMessages id="_CLOSE" />}
// 							</Button>
// 							<Button onClick={this.renameForm} color="primary" autoFocus>
// 								{<IntlMessages id="_SAVE" />}
// 							</Button>
// 						</DialogActions>
// 					</Dialog>
// 					{/* rename folder */}
// 					<Dialog
// 						open={this.state.renameFolderDialogOpen}
// 						onClose={this.handleClose}
// 						aria-labelledby="alert-dialog-title"
// 						aria-describedby="alert-dialog-description"
// 					>
// 						<DialogTitle id="alert-dialog-title">
// 							<IntlMessages id="_RENAME_FOLDER" />
// 						</DialogTitle>
//
// 						<DialogContent>
// 							<TextField
// 								id="folderName"
// 								label={<IntlMessages id="_RENAME_FOLDER" />}
// 								fullWidth
// 								onChange={this.handleChangeTextBox}
// 								value={this.state.folderName}
// 							/>
// 						</DialogContent>
//
// 						<DialogActions>
// 							<Button onClick={this.handleClose} color="primary">
// 								{<IntlMessages id="_CLOSE" />}
// 							</Button>
// 							<Button onClick={this.renameFolder} color="primary" autoFocus>
// 								{<IntlMessages id="_SAVE" />}
// 							</Button>
// 						</DialogActions>
// 					</Dialog>
// 					<NotificationContainer />
// 				</div>
// 			</AuthorizationWrapper>
// 		);
// 	}
// }
//
// // const mapStateToProps = (state) => ({
// // 	formData: state.form,
// // });
// // export default connect(
// // 	(state) => mapStateToProps,
// // 	(dispatch) => bindActionCreators({ ...actionCreators }, dispatch)
// // )(Form);
// const mapStateToProps = (state) => ({
// 	formData: state.form,
// });
// export default connect(
// 	(state) => mapStateToProps,
// 	(dispatch) => bindActionCreators({ ...actionCreators }, dispatch)
// )(FormFolderView);

import React, { Component } from "react";
import AuthorizationWrapper from "../../../customComponents/AuthComponents/AuthorizationWrapper";
import ContainerHeader from "../../../../../components/ContainerHeader";
import FolderView from "../../../customComponents/FolderView/FolderView";
import IntlMessages from "../../../../../util/IntlMessages";

let listFor = "form"
class FormFolderView extends Component {
	render() {
		const {match,history} = this.props
		//const settings = JSON.parse(this.context.settings.data);
		return (
			<AuthorizationWrapper menu={listFor}>
				<div>
					{/* <div className="animated slideInUpTiny animation-duration-3">
						<ContainerHeader title={<IntlMessages id={`_${listFor.toUpperCase()}`} />} match={match} />
					</div> */}

					<FolderView
						listFor={listFor}
						tools={['add','copy','rename','delete']}
						match={match}
						history={history}
					/>
				</div>
			</AuthorizationWrapper>
		);
	}
}

export default FormFolderView;
