import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  SWITCH_LANGUAGE_FAILED,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  START_USER_TOUR
} from "constants/ActionTypes";
import { DARK_GREEN } from "constants/ThemeColors";
import * as Constants from "../common/Constants"

const rltLocale = ["ar"];
const initialSettings = {
  navCollapsed: false,
  isTourGuidExist: true,
  tourSteps: [],
  tourVideo: null,
  showTour: false,
  showTourVideo: false,
  startTour: false,
  tourMenuName: null,
  tourViewName: null,
  drawerType: FIXED_DRAWER,
  themeColor: DARK_GREEN,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: localStorage.getItem('languageDefault') ?
    JSON.parse(localStorage.getItem('languageDefault')) : {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "gb"
    }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };
    case SWITCH_LANGUAGE_FAILED:
      return {
        ...state
      }
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };
    case START_USER_TOUR:
      return {
        ...state,
        showTour: true
      }
    case Constants.SHOW_USER_TOUR:
      return {
        ...state,
        showTour: true
      }
    case Constants.HIDE_USER_TOUR:
      return {
        ...state,
        showTour: false
      }
    case Constants.START_USER_TOUR:
      return {
        ...state,
        showTour: false,
        startTour: true
      }
    case Constants.STOP_USER_TOUR:
      return {
        ...state,
        showTour: false,
        startTour: false
      }
    case Constants.SET_TOUR_STEPS:
      return {
        ...state,
        tourSteps: action.steps,
        tourVideo: action.video
        // isTourGuidExist:false
      }
    case Constants.SHOW_TOUR_VIDEO:
      return {
        ...state,
        showTourVideo: true,
        showTour: false,
        startTour: false

        // isTourGuidExist:false
      }
    case Constants.CLOSE_TOUR_VIDEO:
      return {
        ...state,
        showTourVideo: false,
        showTour: false,
        startTour: false

        // isTourGuidExist:false
      }
    case Constants.RESET_TOUR_GUIDE:
      return {
        ...state,
        isTourGuidExist: true,
        tourSteps: [],
        tourVideo: null,
        showTour: false,
        showTourVideo: false,
        startTour: false,
        tourMenuName: null,
        tourViewName: null

        // isTourGuidExist:false
      }
    default:
      return state;
  }
};

export default settings;
