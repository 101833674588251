import React, { Component } from "react";
//material
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SettingContext } from "../../../../context/SettingContext";
import IntlMessages from "../../../../util/IntlMessages";
import CustomBackButton from "../button/CustomBackButton";
import CustomButton from "../button/CustomButton";

const  useStyles = theme => ({

})


class CustomHeader extends Component {
    static contextType = SettingContext;
    render() {
        debugger
        const {classes} = this.props
        const settings = JSON.parse(this.context.settings.data);

        let buttons = []
        if(Array.isArray(this.props.buttons)){
            buttons = this.props.buttons
        }
        let titleKey = "_NO_TEXT"
        if(this.props.titleKey !== undefined){
            titleKey = this.props.titleKey
        }



        let name = "....."
        if(this.props.campaign !== null){
            name = ""
            let nameLengthSetting = settings["#LISTING_FIELD_ELLIPSIS_LENGTH"]
            if(name.length > nameLengthSetting){
                name = name.slice(0,nameLengthSetting)+"..."
            }
        }

        return (
            <div>
                <Grid container>
                    <Grid item container md={6} justify="flex-start">
                        <Grid container>
                            <Grid item>
                                <h1>
                                    {/*<span style={{ color: "#4caf50" }}>*/}
                                    <span>
									<IntlMessages id={titleKey} />{" "}
                                        {/*{item && item.position_data.current_position} of{" "}*/}
                                        {/*{item && item.position_data.total_rows}*/}
								    </span>
                                    {"("+ name +")"}
                                    <span style={{ marginLeft: "20px" }}>
										<CustomBackButton
                                            onClick={this.props.handleGoBack}
                                        />
								</span>
                                </h1>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container md={6} justify="flex-end">
                        {this.props.headerOf === undefined &&
                            <div>
                                {/*save*/}
                                {buttons.some(a => a === 'save') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleSave}
                                >
                                    <IntlMessages id="_SAVE" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'saveAndClose') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleSaveAndClose}
                                >
                                    <IntlMessages id="_SAVE_AND_CLOSE" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'editAccountData') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleEditAccountData}
                                >
                                    <IntlMessages id="_ECLICKSPRO_EDIT_ACCOUNT_DATA" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'buyExtraMailCredits') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleBuyExtraMailCredits}
                                >
                                    <IntlMessages id="_ECLICKSPRO_BUY_EXTRA_MAIL_CREDITS" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'createUser') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleCreateUser}
                                >
                                    <IntlMessages id="_ECLICKSPRO_CREATE_USER" />
                                </CustomButton>
                                }

                                {/*    payment History*/}
                                {buttons.some(a => a === 'paymentHistory') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handlePaymentHistory}
                                >
                                    <IntlMessages id="_ECLICKSPRO_PAYMENT_HISTORY" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'saveandclose') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleSaveAndClose}
                                >
                                    <IntlMessages id="_SAVE_AND_CLOSE" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'saveandclose') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleSaveAndClose}
                                >
                                    <IntlMessages id="_SAVE_AND_CLOSE" />
                                </CustomButton>
                                }

                                {/*    save and close*/}
                                {buttons.some(a => a === 'saveandclose') &&
                                <CustomButton
                                    color="green"
                                    onClick={this.props.handleSaveAndClose}
                                >
                                    <IntlMessages id="_SAVE_AND_CLOSE" />
                                </CustomButton>
                                }
                            </div>

                        }


                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(useStyles)(CustomHeader);


/*
* props
* headerOf - string - "campaing" - perpose: to identify which component header if want to some this extra in buttons
* */