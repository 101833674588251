import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import "../../app/routes/home/components/index.css"

const getDisplayString = sub => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      " " +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1)
    );
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return "/";
  } else {
    return "/" + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({ title, match, name, data = null }) => {
  const path = match.url.substr(1);
  const subPath = path.split("/");
  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">

      {name === undefined ?
        <h2 className="title mb-3 mb-sm-0">{title}</h2>
        :
        <h2 className="mb-3 mb-sm-0 homepage">Welcome <span>{name}</span></h2>
      }

      <div className="d-flex align-items-center">
        {
          data && data?.remaining_trail_in_days && data.remaining_trail_in_days.includes("days") && <h3 className="mb-3 mb-sm-0 homepage mr-4">
            <IntlMessages id="_ECLICKSPRO_TRIAL_REMAINING" /> <strong>{data.remaining_trail_in_days}</strong>
          </h3>
        }

        <Breadcrumb className="mb-0" tag="nav">
          {subPath.map((sub, index) => {
            if (sub === "custom-views" || sub === "edit") {
              return null;
            } else {
              return (
                <BreadcrumbItem
                  active={subPath.length === index + 1}
                  tag={subPath.length === index + 1 ? "span" : "a"}
                  key={index}
                  href={getUrlString(path, sub, index)}
                >
                  {getDisplayString(sub)}
                </BreadcrumbItem>
              );
            }
          })}
        </Breadcrumb>
      </div>

    </div>
  );
};

export default ContainerHeader;
