import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    hideMessage,
    showAuthLoader,
    userSignIn,
    resetPassword,
    resetPasswordSuccess
} from "actions/Auth";

import config from "../config";
import { Grid } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { eclicksProColors } from "common/functions/EproCommonFunctions";

const classes = {
    stepCount: {
        width: "30%"
    },
    stepCountCircle: {
        height: "50px",
        width: "50px",
        borderRadius: "100%",
        backgroundColor: "#7A7D7A"
    },
    stepCountCircleActive: {
        height: "50px",
        width: "50px",
        borderRadius: "100%",
        backgroundColor: eclicksProColors.green
    },
    stepCountSpan: {
        textAlign: "center",
        fontSize: "32px",
        color: "#fff",
        fontWeight: "bold",
        margin: "24px 0px 0px 14px"
    },


    stepperWrapper: {
        margin: "120px 0px 80px 0px",
        paddingLeft: "55px"
    },


    savePassWrapper: {

    },
    logoArea: {
        backgroundColor: eclicksProColors.green,
        width: "100%",
        height: "260px",
        borderRadius: "0px 10px 10px 0px"
    },
    formWrapper: {
        margin: "20px 30px"
    },
    stepLineWrapper: {

    },
    stepLine: {
        height: "8px",
        width: "100%",
        marginTop: "22px",
        backgroundColor: "#7A7D7A"
    },
    stepTitleWrapper: {
        marginLeft: "-30px",
        width: "70%",
        textAlign: "center"
    },
    stepTitleSpan: {
        color: "#7A7D7A",
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center"
    }

}
class CreatePassword extends React.Component {
    constructor() {
        super();
        this.state = {
            newPassword: "",
            repeatPassword: "",
            loader: false
        };
    }

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
    }

    handleChangePassword = () =>{
        let msg;
        let isValid = false
        const {newPassword,repeatPassword} = this.state
        // const reGexLC = new RegExp("^(?=.*[a-z])");
        // const reGexUC = new RegExp("(?=.*[A-Z])");
        // const reGexN = new RegExp("(?=.*[0-9])");
        // const reGexSC = new RegExp("(?=.*[!@#\$%\^&\*])");

        // if (newPassword.length < 8 || !reGexLC.test(newPassword) || !reGexUC.test(newPassword) || !reGexN.test(newPassword) || !reGexSC.test(newPassword)) {
        //     msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION" />;
        //     return NotificationManager.error(msg);
        // }
        //
        // if (repeatPassword.length === 0) {
        //     msg = <IntlMessages id="_ECLICKSPRO_REPEAT_PASSWORD_NULL" />
        //     return NotificationManager.error(msg);
        // }
        //
        // if (!(newPassword === repeatPassword)) {
        //     msg = <IntlMessages id="_PASSWORD_IS_NOT_MATCHED" />;
        //     return NotificationManager.error(msg);
        // }


        if (repeatPassword.length === 0) {
            msg = <IntlMessages id="_ECLICKSPRO_REPEAT_PASSWORD_NULL" />
            return NotificationManager.error(msg);
        }

        if (!(newPassword === repeatPassword)) {
            msg = <IntlMessages id="_PASSWORD_IS_NOT_MATCHED" />;
            return NotificationManager.error(msg);
        }

        this.setState({ loader: true });
        fetch(`${config.api.url}api/password_save`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                token: this.props.match.params.token,
                new_password: newPassword,
                re_password: repeatPassword
            })
        })
            .then(response => response.json())
            .then(json => {
                if (json.status === "success") {
                    this.setState({ loader: false });
                    return window.location.replace("/signin");
                }
                if (json.status === "error") {
                    this.setState({ loader: false });
                    return NotificationManager.error(json.message);
                }
            })
            .catch(error => NotificationManager.error(error));
    }

    render() {
        const { newPassword, repeatPassword, loader } = this.state;
        return (
            <div className="app-main">
                <div className={"app-main-container"}>
                    <Container maxWidth="lg">
                        <div style={classes.stepperWrapper}>
                            <Grid container>
                                <Grid item sm={3}></Grid>
                                <Grid item sm={6} item justify={"center"}>
                                    <Grid container>
                                        <Grid item sm={3} xs={3} md={3}>
                                            <Grid container direction="row-reverse">
                                                <Grid item sm={8}>
                                                    <div style={classes.stepLineWrapper}>
                                                        <div style={classes.stepLine}></div>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <div style={classes.stepCountCircleActive}>
                                                        <span style={classes.stepCountSpan}>1</span>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item sm={1}>
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <div style={classes.stepTitleWrapper}>
                                                        <span style={classes.stepTitleSpan}>
                                                            <IntlMessages id="_ECLICKSPRO_START_TRIAL" />
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={1}>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/*    step 2*/}
                                        <Grid item sm={3} xs={3} md={3}>
                                            <Grid container direction="row-reverse">
                                                <Grid item sm={8}>
                                                    <div style={classes.stepLineWrapper}>
                                                        <div style={classes.stepLine}></div>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <div style={classes.stepCountCircleActive}>
                                                        <span style={classes.stepCountSpan}>2</span>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item sm={1}>
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <div style={classes.stepTitleWrapper}>
                                                        <span style={classes.stepTitleSpan}>
                                                            <IntlMessages id="_ECLICKSPRO_CONFIRM_BY_EMAIL" />
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={1}>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/*    step 2*/}

                                        {/*    step 2*/}
                                        <Grid item sm={3} xs={3} md={3}>
                                            <Grid container direction="row-reverse">
                                                <Grid item sm={8}>
                                                    <div style={classes.stepLineWrapper}>
                                                        <div style={classes.stepLine}></div>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <div style={classes.stepCountCircleActive}>
                                                        <span style={classes.stepCountSpan}>3</span>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item sm={1}>
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <div style={classes.stepTitleWrapper}>
                                                        <span style={classes.stepTitleSpan}>
                                                            <IntlMessages id="_ECLICKSPRO_SET_YOUR_PASSWORD" />
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={1}>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/*    step 2*/}

                                        {/*    step 2*/}
                                        <Grid item sm={3} xs={3} md={3}>
                                            <Grid container>
                                                <Grid item>
                                                    <div style={classes.stepCountCircle}>
                                                        <span style={classes.stepCountSpan}>4</span>
                                                    </div>
                                                </Grid>
                                                {/*<Grid item sm={8}>*/}
                                                {/*    <div style={classes.stepLineWrapper}>*/}
                                                {/*        /!*<div style={classes.stepLine}></div>*!/*/}
                                                {/*    </div>*/}
                                                {/*</Grid>*/}
                                            </Grid>

                                            <Grid container>
                                                <Grid item sm={1}>
                                                </Grid>
                                                <Grid item sm={10}>
                                                    <div style={classes.stepTitleWrapper}>
                                                        <span style={classes.stepTitleSpan}>
                                                            <IntlMessages id="_ECLICKSPRO_LOGIN" />
                                                        </span>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={1}>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/*    step 2*/}
                                    </Grid>
                                </Grid>
                                <Grid item sm={3}></Grid>
                            </Grid>
                        </div>
                    </Container>
                    {/* end of stepper container */}

                    <Container maxWidth="lg">
                        <div>
                            <Grid container>
                                <Grid item sm={3}></Grid>
                                <Grid item sm={6}>
                                    <div style={classes.savePassWrapper}>
                                        <Paper
                                            elevation={3}
                                            style={{ borderRadius: "10px" }}
                                        >
                                            <Grid container>
                                                <Grid item sm={8}>
                                                    <div style={classes.formWrapper}>
                                                        <form>
                                                            <fieldset>
                                                                <TextField
                                                                    type="password"
                                                                    label={<IntlMessages id="_NEW_PASSWORD" />}
                                                                    fullWidth
                                                                    onChange={event =>
                                                                        this.setState({ newPassword: event.target.value })
                                                                    }
                                                                    defaultValue={newPassword}
                                                                    margin="normal"
                                                                    className="mt-1 my-sm-3"
                                                                />

                                                                <TextField
                                                                    type="password"
                                                                    label={<IntlMessages id="_REPEAT_PASSWORD" />}
                                                                    fullWidth
                                                                    onChange={event =>
                                                                        this.setState({ repeatPassword: event.target.value })
                                                                    }
                                                                    defaultValue={repeatPassword}
                                                                    margin="normal"
                                                                    className="mt-1 my-sm-3"
                                                                />

                                                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                                                    <Button
                                                                        onClick={this.handleChangePassword}
                                                                        variant="contained"
                                                                        color="primary"

                                                                    >
                                                                        <IntlMessages id="_ECLICKSPRO_ADD_PASSWORD" />
                                                                    </Button>
                                                                    <Link to="/signin">
                                                                        <IntlMessages id="_GO_LOGIN_PAGE" />
                                                                    </Link>
                                                                </div>
                                                            </fieldset>
                                                        </form>
                                                    </div>
                                                </Grid>
                                                <Grid item sm={4}>
                                                    <div style={classes.logoArea}>
                                                        <img
                                                            src={require("assets/images/logo.png")}
                                                            alt="bizzmail"
                                                            title="MyBizzmail"
                                                            width="100%"
                                                            style={{ marginTop: "60px" }}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </div>
                                </Grid>
                                <Grid item sm={3}></Grid>
                            </Grid>
                        </div>
                        {loader && (
                            <div className="loader-view">
                                <CircularProgress />
                            </div>
                        )}
                        <NotificationContainer />
                    </Container>
                </div>





            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    // console.log("auth->", auth);
    const { loader, alertMessage, showMessage, authUser, status } = auth;
    return { loader, alertMessage, showMessage, authUser, status };
};

export default connect(mapStateToProps, {
    userSignIn,
    resetPassword,
    resetPasswordSuccess,
    hideMessage,
    showAuthLoader
})(CreatePassword);