import { FETCH_MENU, FETCH_MENU_SUCCESS } from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import { getAuthData } from "../common/helpers/AuthHelper";

// let token = localStorage.getItem("user_token");
let {token} = getAuthData()

export const userMenu = token => {
  return {
    type: FETCH_MENU,
    payload: token
  };
};

export const userMenuSuccess = menus => {
  return {
    type: FETCH_MENU_SUCCESS,
    payload: menus
  };
};

export const actionCreators = {
  getGdprStatus:()=>async (dispatch,getState)=>{
    let url = config.api.url+"api/accounts/statusgdpr"
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
        .then((res) => {
          debugger
          if(res.data.status === "success"){
            dispatch({type:"SUCCESS_GET_GDPR", gdpr:res.data.data})
            //dispatch({type:"SUCCESS_GET_GDPR", gdpr:null})
          }
        })
        .catch((e) => {
          let aaaa = e;
        });
  },
  acceptGdpr:()=>async (dispatch,getState)=>{
    let url = config.api.url+"api/accounts/acceptgdpr"
    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      }
    })
        .then((res) => {
          let getUrl = config.api.url+"api/accounts/statusgdpr"
          axios({
            method: "get",
            url: getUrl,
            headers: {
              Authorization: token,
            },
          })
              .then((res) => {
                debugger
                if(res.data.status === "success"){
                  dispatch({type:"SUCCESS_GET_GDPR", gdpr:res.data.data})
                }
              })
              .catch((e) => {
                let aaaa = e;
              });


        })
        .catch((e) => {
          let aaaa = e;
        });




  }
}
