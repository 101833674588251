import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_PROFILE } from "constants/ActionTypes";
import { profileInfoSuccess } from "actions/Profile";

import config from "../config";

// Api Function
const fetchProfileInfo = async token => {
  const response = await fetch(`${config.api.url}api/profile`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);

  return response;
};

// Saga Function
function* getProfileInfo({ payload }) {
  try {
    const getProfileData = yield call(fetchProfileInfo, payload);
    yield put(profileInfoSuccess(getProfileData));
  } catch (e) {
    console.log(e);
  }
}

// export saga function
export function* getProfile() {
  yield takeEvery(FETCH_PROFILE, getProfileInfo);
}

export default function* rootSaga() {
  yield all([fork(getProfile)]);
}
