import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import SelectGroup from "../../customViews/routes/group/components/SelectGroup";

class RelationDialogBox extends React.Component {
  state = {
    value: [],
  };

  handleRequestClose = () => {
    this.setState({ value: [] });
    this.props.handleRequestClose();
  };

  handleRequestAdd = async () => {
    this.props.handleAddRelationToGroup(this.state.value);
  };

  render() {
    const { open, type } = this.props;
    const label =
      type === "unsubscribe" ? (
        <IntlMessages id={"_" + type.toUpperCase()} />
      ) : (
        <IntlMessages id={"_ECLICKSPRO_" + type.toUpperCase() + "_TO_GROUP"} />
      );
    // console.log(type);
    // console.log(this.state.value);
    return (
      <div>
        <Dialog open={open} onClose={this.handleRequestClose}>
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            {(type === "unsubscribe" && (
              <IntlMessages id="_ARE_YOU_SURE_TO_UNSUBSCRIBE" />
            )) || (
              <SelectGroup
                getSelectedItem={(items) => this.setState({ value: items })}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              <IntlMessages id="_CLOSE" />
            </Button>
            {(type === "unsubscribe" && (
              <Button
                onClick={() => this.props.handleUnsubscribeRelation()}
                style={{ color: "red" }}
              >
                <IntlMessages id="_UNSUBSCRIBE" />
              </Button>
            )) || (
              <Button
                onClick={this.handleRequestAdd}
                color="primary"
                disabled={this.state.value.length === 0}
              >
                <IntlMessages id="_ADD" />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default RelationDialogBox;
