import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_EMAIL,
  FETCH_EMAIL_FOLDER,
  FETCH_EMAIL_BY_ID
} from "constants/ActionTypes";
import {
  fetchEmailSuccess,
  fetchEmailFolderSuccess,
  fetchEmailByIdSuccess
} from "actions/Email";

import config from "../config";

// Email
const fetchEmailData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/email?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchEmailData({ payload }) {
  try {
    const getEmailData = yield call(fetchEmailData, payload);
    yield put(fetchEmailSuccess(getEmailData));
  } catch (e) {
    console.error(e);
  }
}

export function* getEmailData() {
  yield takeEvery(FETCH_EMAIL, getFetchEmailData);
}

// Folder
const fetchFolderData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/email/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFolderData({ payload }) {
  try {
    const getFolderData = yield call(fetchFolderData, payload);
    yield put(fetchEmailFolderSuccess(getFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFolderData() {
  yield takeEvery(FETCH_EMAIL_FOLDER, getFetchFolderData);
}

// Email Data
const fetchEmailDataById = async params => {
  const { token, id } = params;
  const response = await fetch(`${config.api.url}v1/email/${id}`, {
    method: "GET",
    headers: {
      Authorization: token
    }
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

function* getFetchEmailDataById({ payload }) {
  try {
    const getEmailDataById = yield call(fetchEmailDataById, payload);
    yield put(fetchEmailByIdSuccess(getEmailDataById));
  } catch (e) {
    console.error(e);
  }
}

export function* getEmailDataById() {
  yield takeEvery(FETCH_EMAIL_BY_ID, getFetchEmailDataById);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getEmailData), fork(getFolderData), fork(getEmailDataById)]);
}
