import config from "config";

export const nlMessage = async () => {
    const respone = await fetch(`${config.api.languageUrl}dutch.json`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/octet-stream"
        }
    });

    const json = respone.json();

    return json
}