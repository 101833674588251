import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_LETTER, FETCH_LETTER_FOLDER,FETCH_LETTER_BY_ID } from "constants/ActionTypes";
import { fetchLetterSuccess, fetchLetterFolderSuccess,fetchLetterByIdSuccess } from "actions/Letter";

import config from "../config";

// letter
const fetchLetterData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/letter?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchLetterData({ payload }) {
  try {
    const getLetterData = yield call(fetchLetterData, payload);
    yield put(fetchLetterSuccess(getLetterData));
  } catch (e) {
    console.error(e);
  }
}

export function* getLetterData() {
  yield takeEvery(FETCH_LETTER, getFetchLetterData);
}

// Folder
const fetchFolderData = async params => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/letter/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFolderData({ payload }) {
  try {
    const getFolderData = yield call(fetchFolderData, payload);
    yield put(fetchLetterFolderSuccess(getFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFolderData() {
  yield takeEvery(FETCH_LETTER_FOLDER, getFetchFolderData);
}

//Letter Data
const fetchLetterDataById = async params => {
  const { token, id } = params;
  const response = await fetch(`${config.api.url}v1/letter/${id}`, {
    method: "GET",
    headers: {
      Authorization: token
    }
  })
    .then(res => res.json())
    .then(json => json);

  if (response.status === "success") {
    return response.data;
  }
};

function* getFetchLetterDataById({ payload }) {
  try {
    const getLetterDataById = yield call(fetchLetterDataById, payload);
    yield put(fetchLetterByIdSuccess(getLetterDataById));
  } catch (e) {
    console.error(e);
  }
}

export function* getLetterDataById() {
  yield takeEvery(FETCH_LETTER_BY_ID, getFetchLetterDataById);
}


//   export saga
export default function* rootSaga() {
  yield all([fork(getLetterData), fork(getFolderData),fork(getLetterDataById)]);
}
