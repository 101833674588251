import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyle = makeStyles((theme) =>({
    paperStyle:{
        width:300
    }
}))


const AddressPopper = (props) =>{
    const classes = useStyle()
    return(
        <div>
            <Paper className={classes.paperStyle}>
                <h1>{props.title}</h1>
            </Paper>
        </div>
    )
}
export default AddressPopper