import axios from "axios";
import { getResponseForNotificaion } from "../functions/EproCommonFunctions";
import * as Constants from "../Constants";
import { getAuthData } from "./AuthHelper";

// export const httpService = {
//     getAsync:(url) =>{
//         let token = localStorage.getItem("user_token");
//         const response = axios({
//             method: "get",
//             url: url,
//             headers: {
//                 Authorization: token,
//             },
//         })
//         return response
//
//
//     },
//     postAsync:(url,data) =>{
//         let token = localStorage.getItem("user_token");
//         const request =  axios({
//             method: "post",
//             url: url,
//             headers: {
//                 Authorization: token,
//             },
//             data: data,
//         })
//         return request
//     }
// }

export const getAsync = (url) =>{
    let {token} = getAuthData()
    const instance = axios.create()

    let response = instance.request({
        method:'get',
        url:url,
        headers:{Authorization: token}
    });
    return response;
}

export const postAsync = (url,d) =>{
    let {token} = getAuthData()
    const instance = axios.create()
    let response = instance.request({
        method:'POST',
        url:url,
        data:d,
        headers:{Authorization: token}
    });
    return response;

}

export const putAsync = (url,d) =>{
    let {token} = getAuthData()
    const instance = axios.create()
    let response = instance.request({
        method:'PUT',
        url:url,
        data:d,
        headers:{Authorization: token}
    });
    return response;

}