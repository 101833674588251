import {
    FETCH_SMS_RELATION,
    FETCH_SMS_RELATION_SUCCESS
  } from "constants/ActionTypes";
  
  export const fetchSmsRelation = params => {
    return {
      type: FETCH_SMS_RELATION,
      payload: params
    };
  };
  
  export const fetchSmsRelationSuccess = filters => {
    return {
      type: FETCH_SMS_RELATION_SUCCESS,
      payload: filters
    };
  };