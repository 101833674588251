import React, { Component, useEffect, useState } from "react";
import ContainerHeader from "../../../../../../components/ContainerHeader";
import IntlMessages from "../../../../../../util/IntlMessages";
import Grid from "@material-ui/core/Grid";
import CustomButton from "../../../../customComponents/button/CustomButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CreditAddForm from "./component/CreditAddForm";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../../../actions/Profile";
import { NotificationContainer, NotificationManager } from "react-notifications";
import CustomDeleteDialog from "../../../../customComponents/dialog/customDeleteDialog";
import CustomDialog, { dialogStyles } from "../../../../customComponents/dialog/customDialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


const packageData = [
    {
        id:"1",
        credit_amount:"10",
        price:"22"
    },
    {
        id:"2",
        credit_amount:"20",
        price:"22"
    },
    {
        id:"3",
        credit_amount:"30",
        price:"22"
    },
]



// const useStyle = makeStyles({
//     addButtonWrapper:{
//         margin:"20px 20px 30px 7px"
//     }
// })

// function CreditAdd(props) {
//     const classes = useStyle();
//     const {match} = props
//     //states
//     const [packages,setPackages] = useState(packageData)
//
//     //handleDelete
//     function deletePackage(packageId) {
//         let pData = packages.filter(p =>p.id !== null);
//         let p = pData.find(p =>p.id === packageId);
//         let pIndex = pData.indexOf(p)
//         pData.splice(pIndex,1)
//         setPackages(pData);
//     }
//
//     //handlePackages
//     function handlePackages(packageId,packageTypeId,value) {
//         if(packageTypeId){
//
//         }else{
//             let pData = packages.filter(p =>p.id !== null);
//             let p = pData.find(p =>p.id === packageId);
//             let pIndex = pData.indexOf(p)
//             pData[pIndex].contacts = value
//             debugger
//
//
//             const pack = [
//                 {
//                     id:"1",
//                     contacts:"50",
//                     types:[
//                         {name:"light",monthly:"200",yearly:"20",uptoContacts:600},
//                         {name:"plus",monthly:"200",yearly:"20",uptoContacts:600},
//                         {name:"professional",monthly:"200",yearly:"20",uptoContacts:600}
//                     ]
//                 }
//             ]
//             setPackages(pData)
//
//
//         }
//     }
//     return(
//
//     )
// }
//export default CreditAdd



const useStyle = theme =>({
    addButtonWrapper:{
        margin:"20px 20px 30px 7px"
    }
})

let componentFor = "profileData"
let responseName = "profileApiResponse"
class CreditAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            packages:[],
            applyVat:"0",
            invalidCards:[],
            openDeleteAlert:false,
            deletePacId:null,
            unsavedAlertOpen:false
        }
    }
    componentDidMount() {
        this.props.getCredits()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.profileData.credits !== this.props.profileData.credits && this.props.profileData.credits !== []){
            this.setState({
                packages:this.props.profileData.credits
                //packages:packageData
            })
        }

        if(prevProps.profileData.creditVat !== this.props.profileData.creditVat && this.props.profileData.creditVat !== "0"){
            this.setState({
                applyVat:this.props.profileData.creditVat
            })
        }

        //notification
        if(prevProps[componentFor][responseName]!== this.props[componentFor][responseName] && this.props[componentFor][responseName] !== null){
            //let res = this.props.apiResponse
            NotificationManager[this.props[componentFor][responseName].alertType](this.props[componentFor][responseName].message)
            this.props.resetResponse()
        }
    }
    addPackage = () =>{
        let pData = this.state.packages.filter(p =>p.id !== null);
        let negId = 0;
        for(let i= 0; i < pData.length;i++){
            let id = parseInt(pData[i].id)
            if(id < negId){
                negId = id
            }

        }
        let isExistUnSaved = pData.find(p =>p.id === '0')
        if(isExistUnSaved){
            this.setState({
                unsavedAlertOpen:true
            })
        }else {
            let newItem = {
                id:negId-1,
                credit_amount:"",
                price:""

            }
            pData.splice(pData.length,0,newItem)
            this.setState({
                packages:pData
            })
        }


    }
    openDeleteAlert = (packageId) =>{
        this.setState({
            openDeleteAlert:true,
            deletePacId:packageId
        })
    }
    deletePackage = () =>{
        let packageId = this.state.deletePacId
        let pData = this.state.packages.filter(p =>p.id !== null);
        let p = pData.find(p =>p.id === packageId);
        let pIndex = pData.indexOf(p)
        pData.splice(pIndex,1)
        this.setState({
            packages:pData
        })
        if(parseInt(packageId) > 0){
            this.props.deleteCredit(packageId)
        }
        this.handleClose()
    }
    handlePackages = (packageId,valueFor,value) =>{
        let pData = this.state.packages.filter(p =>p.id !== null);
        let p = pData.find(p =>p.id.toString() === packageId);
        let pIndex = pData.indexOf(p)
        // pData[pIndex].contacts = value
        let item = pData[pIndex]
        item[valueFor] = value
        // if(packageType === "contact"){
        //
        //     //validate
        //     let {invalidCards} = this.state
        //     let isNotNumber = isNaN(value)
        //     let isExist = pData.find(a => a.contacts.toString() === value.toString())
        //
        //
        //     if(isNotNumber || isExist || value === ""){
        //         invalidCards.push(item.id)
        //     }else{
        //         if(invalidCards.find(a => a === item.id)){
        //             invalidCards = invalidCards.filter(a =>a.toString() !== item.id.toString())
        //         }
        //     }
        //     this.setState({
        //         invalidCards:invalidCards
        //     })
        //
        //     item.contacts = value
        // }else {
        //     item[packageType] = value
        // }

        pData[pIndex] = item

        this.setState({
            packages:pData
        })
    }
    handleSave = () =>{
        this.props.saveCredit(this.state.packages,this.state.applyVat)
    }
    handleChangeCheck = (event) =>{
        this.setState({
            [event.target.id]:event.target.checked? "1":"0"
        })
    }
    handleClose = () =>{
        this.setState({
            openDeleteAlert:false,
            deletePacId:null,
            unsavedAlertOpen:false
        })
    }
    render() {
        const {classes,match,profileData} = this.props
        const {packages} = this.state
        return(
            <div className={"app-wrapper"}>
                <ContainerHeader
                    title={<IntlMessages id={"_ECLICKSPRO_ADD_CREDIT"}/>}
                    match={match}
                />

                <div className={classes.addButtonWrapper}>
                    {/*<Grid container spacing={3}>*/}
                    {/*    <Grid item>*/}
                    {/*        <CustomButton variant="contained" color={"dodgerBlue"}>{<IntlMessages id={"_ECLICKSPRO_ADD_CREDIT"}/>}</CustomButton>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item>*/}
                    {/*        <FormControlLabel control={<Checkbox name="checkedC" color={"primary"} />} label={<IntlMessages id={"_ECLICKSPRO_APPLY_VAT"}/>} />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}

                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <CustomButton onClick={this.addPackage} variant="contained" color={"dodgerBlue"}>{<IntlMessages id={"_ECLICKSPRO_ADD_CREDIT"}/>}</CustomButton>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox name="applyVat" checked={this.state.applyVat === "1"}  onChange={this.handleChangeCheck} id={"applyVat"}  color={"primary"} />}
                                        label={<IntlMessages id={"_ECLICKSPRO_APPLY_VAT"}/>} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container md={6} justify={"flex-end"}>
                            <CustomButton
                                onClick={this.handleSave}
                                variant="contained"
                                color={"green"}
                                disabled={this.state.invalidCards.length > 0}
                            >
                                {<IntlMessages id={"_ECLICKSPRO_SAVE"}/>}
                            </CustomButton>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={3}>
                    {packages.map((p,index)=>{
                        return(
                            <CreditAddForm
                                packageInfo={p}
                                types={p.types}
                                handlePackages={this.handlePackages}
                                handleDelete={this.openDeleteAlert}
                            ></CreditAddForm>
                        )
                    })}
                </Grid>

                {/*dialog*/}
                <CustomDialog
                    open={this.state.unsavedAlertOpen}
                    handleClose={this.handleClose}
                    handleNo={this.handleClose}
                    handleYes={this.handleDeleteShare}
                    dialogContent={false}
                    titleTextKey={"_ECLICKSPRO_ALERT"}
                    maxWidth={"sm"}
                    actionButtons={['close']}
                >
                    <DialogContent style={dialogStyles.dialogContentStyles}>
                        <DialogContentText>
                            <IntlMessages id={"_ECLICKSPRO_UNSAVED_ALERT_MESSAGE"}/>  ?
                        </DialogContentText>
                    </DialogContent>
                </CustomDialog>

                {/*delete alert*/}
                <CustomDeleteDialog
                    open={this.state.openDeleteAlert}
                    handleDelete={this.deletePackage}
                    handleClose={this.handleClose}
                />
                {/*delete alert*/}
                <NotificationContainer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(withStyles(useStyle)(CreditAdd));
