import React, { Component } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../actions/Auth";
import CircularLoader from "../loader/CircularLoader";
import AccessDenied from "../errors/AccessDenied";

class AuthorizationWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        this.props.checkAuthorization(this.props.menu)
    }

    render() {
        const {auth} = this.props
        return (
            <div>
                {auth.authLoader?
                    <CircularLoader/>
                    :
                    <div>
                        {auth.isAuthorized ?
                            <div>
                                {this.props.children}
                            </div>

                            :
                            <AccessDenied/>
                        }
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state =>({
    auth:state.auth
})

export default connect(
    state =>mapStateToProps,
    dispatch => bindActionCreators({...actionCreators},dispatch)
)(AuthorizationWrapper);