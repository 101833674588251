import React from "react";
import { PDFViewer } from '@react-pdf/renderer';


function PdfViewer(props) {
    return(
        <PDFViewer {...props}>
            {props.children}
        </PDFViewer>
    )
}
export default PdfViewer