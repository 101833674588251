import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import EnhancedTable from "./components/EnhancedTable";
import IntlMessages from "util/IntlMessages";
import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";
import { fetchTableData } from "actions/Table";
import {NotificationContainer, NotificationManager} from "react-notifications";
import AccessDenied from "../customComponents/errors/AccessDenied";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {actionCreators} from "../../../actions/Table";

//settings
import { SettingContext } from "context/SettingContext";

import AuthorizationWrapper from "../customComponents/AuthComponents/AuthorizationWrapper";
import { getAuthData } from "../../../common/helpers/AuthHelper";


class TableView extends Component {
  static contextType = SettingContext;
  state = {
    searchText: "",
    itemPerPage: "10",
    pageNumber: 1,
    searchFlag: true,
    flag: false,
    columnSearchText: "",
    title: "",
    multipleSearch: null,
    searchLists: null,
    menuName:null,
    accountId:null
  };

  componentDidMount() {
    const { authUser, match } = this.props;
    let { searchText, pageNumber, multipleSearch } = this.state;
    let localSearchText = localStorage.getItem("searchTextForTrans")
    let acId = this.getAccountId()

    this.setState({searchText: localSearchText})
      this.props.getAllAccounts()
      this.fetchTableData(null,null,true,true,null,null,null,null,acId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.tableData.listingApiResponse !== this.props.tableData.listingApiResponse && this.props.tableData.listingApiResponse !== null){
            //let res = this.props.apiResponse
            NotificationManager[this.props.tableData.listingApiResponse.alertType](this.props.tableData.listingApiResponse.message)
            this.props.resetResponse()
        }
        if(this.props.match.params.menu !== prevProps.match.params.menu ) {
          let localSearchText = localStorage.getItem("searchTextForTrans")
          let acId = this.getAccountId()

          this.setState({searchText: localSearchText})
            this.props.getAllAccounts()
            this.fetchTableData(null,null,true,true,null,null,null,null,acId)
       };
    }




	setLocalValues = (multipleSearchOptions,searchList,itemsPerPage) =>{
    let localObj = {
      [this.props.match.params.menu]:{
        multipleSearchOptions:multipleSearchOptions,
        searchList:searchList,
        itemsPerPage:itemsPerPage
      }
    }
    //let processedData = btoa(JSON.stringify(localObj))
    let processedData = JSON.stringify(localObj)
    localStorage.setItem("localData",processedData)

  }
  handleColumnSearch = (name,value) =>{
	  this.fetchTableData(null,null,false,true,null,null,null,{"name":name,"value":value},this.state.accountId)
  }

  removeSearchItem = (value,tableName,name,multipleSearchOption) =>{
    const { authUser, match } = this.props;
    const { itemPerPage, searchText,pageNumber,multipleSearch } = this.state;
	  if(name === ""){
	  	return
	  }
    let items = multipleSearchOption[name]
      //find out why items undefined
      if(items !== undefined){
          items = items.filter(a =>a.value !== value)
          multipleSearchOption[name] = items
          this.setState({
              multipleSearch:multipleSearchOption
          })
          let localData = this.getFromLocalStorage()
          let multipleSearchOptions = multipleSearchOption
          let arrayForMultiple = {}
          let keys = Object.keys(multipleSearchOptions)
          for(let k = 0;k < keys.length;k++){
              let key = multipleSearchOptions[keys[k]]
              arrayForMultiple[keys[k]] = []
              for(let i = 0;i < key.length;i++ ){
                  arrayForMultiple[keys[k]].push(
                      key[i].value
                  )
              }
          }

          localData[this.props.match.params.menu].multipleSearchOptions = multipleSearchOptions
          this.setInLocalStorage(localData)
      }

    //window.location.reload()

    //this.props.getTableRows(match.params.menu,searchText,itemPerPage,pageNumber,arrayForMultiple)
      this.fetchTableData(null,null,true,true,null,null,null,null,this.state.accountId)

  }

  //fetchFullTable =
  fetchTableData = (tableName,viewName,fullSearch,setInLocal,searchTextParam,itemPerPageParam,pageNumberParam,multipleSearchObjParam,accountId) =>{
    const { authUser, match } = this.props;
      let searchText = null;
      let pageNumber = 1;
      let itemPerPage = "10";
      let multipleSearchOptions = {}

      let localData = this.getFromLocalStorage()

      //set some common data
	  if(accountId === null || accountId === undefined){
	      accountId = "0"
      }
      if(!tableName) { tableName = match.params.menu }
      if(!viewName) { viewName = match.params.menu }


      if(setInLocal){
          if(localData ===  null){
              this.setInitialLocalData()
              localData = this.getFromLocalStorage()
          }

          if(localData[viewName] === undefined){
              this.setInitialLocalData()
              localData = this.getFromLocalStorage()
          }
          searchText = localData[viewName] && localData[viewName].searchText
          if(searchText === undefined){
              searchText = null
          }

          pageNumber = localData[viewName] && localData[viewName].pageNumber
          if(pageNumber === undefined){
              pageNumber = 1
          }

          itemPerPage = localData[viewName] && localData[viewName].itemsPerPage
          if(itemPerPage === undefined || itemPerPage === null){
              itemPerPage = "10"
          }

          multipleSearchOptions = localData[viewName] && localData[viewName].multipleSearchOptions
          if(multipleSearchOptions === undefined || multipleSearchOptions === null){
              multipleSearchOptions = {}
          }

      }



	  //set new data from parameter
	  if(pageNumberParam === null){
	  	pageNumber = 1
	  }else{
		  pageNumber = parseInt(pageNumberParam)
	  }

	  if(itemPerPageParam !== null){
	  	itemPerPage = itemPerPageParam
	  }
	  if(searchTextParam !== null){
	  	searchText = searchTextParam
	  }


	  //multiple search
	  if(multipleSearchObjParam !== undefined && multipleSearchObjParam !== null){
		  // if(multipleOp === null){
			//   multipleOp={[multipleSearchObjParam.name]:[]}
		  // }
          if(multipleSearchObjParam.value === undefined){
            delete multipleSearchOptions[multipleSearchObjParam.name]
          }else if(multipleSearchObjParam.value !== ""){
            if(multipleSearchOptions[multipleSearchObjParam.name] === undefined){
                multipleSearchOptions={...multipleSearchOptions,[multipleSearchObjParam.name]:[]}
            }
            if(multipleSearchOptions[multipleSearchObjParam.name].length === 0){
                multipleSearchOptions[multipleSearchObjParam.name] = [{"value":multipleSearchObjParam.value}]
                //localData[this.props.match.params.menu].multipleSearchOptions = multipleOp
                //this.setInLocalStorage(localData)
            }else{
                multipleSearchOptions[multipleSearchObjParam.name].push({"value":multipleSearchObjParam.value})
                //localData[this.props.match.params.menu].multipleSearchOptions = multipleOp
                //this.setInLocalStorage(localData)
            }
          }
	  }

	  // set value in localdata object
      if(setInLocal){
          // set value in localdata object
          localData[viewName].searchText = searchText
          localData[viewName].pageNumber = pageNumber
          localData[viewName].itemsPerPage = itemPerPage
          localData[viewName].multipleSearchOptions = multipleSearchOptions

          this.setInLocalStorage(localData)
      }

	  this.setState({
		  pageNumber:pageNumber,
		  itemPerPage:itemPerPage,
		  searchText:searchText,
		  multipleSearch:multipleSearchOptions,
	  })
	  //set new data from parameter
	  let multipleSearchParam = this.getMultipleSearchParam(multipleSearchOptions)
	  if(fullSearch){
		  this.props.getTableData(tableName,searchText,itemPerPage,pageNumber,multipleSearchParam,accountId)
	  }else{
		  this.props.getTableRows(tableName,searchText,itemPerPage,pageNumber,multipleSearchParam,accountId)
	  }
	  
  }
  //end of fetchTable function

  //dependency functions for fetchTable function
  setInitialLocalData = (viewName) =>{
      let localData = this.getFromLocalStorage()
      if(localData === null){ localData = {} }

      if(!viewName) {viewName = this.props.match.params.menu}

      localData[viewName]={
          multipleSearchOptions:null,
          searchList:null,
          searchText:"",
          itemsPerPage:"10"
      }

	this.setInLocalStorage(localData)
  }
  getMultipleSearchParam = (multipleSearchOptions) =>{
		let arrayForMultiple = {}
		if(multipleSearchOptions === null || multipleSearchOptions === undefined){
            multipleSearchOptions = {}
		}

	  let keys = Object.keys(multipleSearchOptions)
	  for(let k = 0;k < keys.length;k++){
		  let key = multipleSearchOptions[keys[k]]
		  arrayForMultiple[keys[k]] = []
		  for(let i = 0;i < key.length;i++ ){
			  arrayForMultiple[keys[k]].push(
				  key[i].value
			  )
		  }
	  }
		return arrayForMultiple
  }
  setInLocalStorage = (data) =>{
    let processedData = JSON.stringify(data)
    localStorage.setItem("localData",processedData)
  }
  getFromLocalStorage = () =>{
    let localData = JSON.parse(localStorage.getItem("localData"))
	  if(localData === undefined){
	  	localData = null
	  }
    return localData
  }
  //dependency functions for fetchTable function

  fetchItemsPerPage = (value) => {
	  this.fetchTableData(null,null,false,true,null,value,null,null,this.state.accountId)
  };

  searchValue = (result, flag) => {
	  this.fetchTableData(null,null,false,true,result,null,null,null,this.state.accountId)
    //this.setState({ searchText: result, searchFlag: true, flag: true });
    // console.log("from tableView ->", result);
  };

  getPageNumber = (value) => {
    this.setState({ pageNumber: value });
    const { authUser, match } = this.props;
    const { itemPerPage, searchText,pageNumber } = this.state;
      this.fetchTableData(null,null,false,true,null,null,value,null,this.state.accountId)
  };

  fetchColumnSearchResult = (result, lists) => {
    // console.log("column result --->", result);
    // console.log("comlumn lists --->", lists);
    this.setState({
      multipleSearch: result,
      searchLists: lists,
      searchFlag: true,
      flag: true,
    });
  };

  //this is making to replace fetchColumnSearchResult
  fetchColumnSearch = (result,lists) =>{
    const { authUser, match } = this.props;
    const { itemPerPage, searchText,pageNumber,multipleSearch } = this.state;
    this.setState({
      multipleSearch: result,
      searchLists: lists,
    })
    this.props.getTableRows(match.params.menu,searchText,itemPerPage,pageNumber,result)
  }

  postOrderView = (fields,sort) =>{
    const { authUser, match } = this.props;
    const { itemPerPage, searchText,pageNumber,multipleSearch } = this.state;
    this.props.postOrderView(match.params.menu,searchText,itemPerPage,pageNumber,multipleSearch,fields,sort)
  }
  getAccountId = () =>{
      let localData = this.getFromLocalStorage()
      let acId = "0"
      let user = getAuthData()

      if(localData ===  null){
          this.setInitialLocalData()
          if(user.user !== null){
              acId = user.user.account_id
              localData = this.getFromLocalStorage()
              localData.account_id = user.user.account_id
              this.setInLocalStorage(localData)
          }

      }else {
          if(localData.account_id === undefined){
              if(user.user !== null){
                  acId = user.user.account_id
                  localData = this.getFromLocalStorage()
                  localData.account_id = user.user.account_id
                  this.setInLocalStorage(localData)
              }
          }else {
            acId = localData.account_id
          }
      }
      this.setState({
          accountId:acId
      })
      return acId
  }
  handleChangeAccount = (id) =>{
      let localData = this.getFromLocalStorage()
      if(localData === null){
          localData = this.setInitialLocalData()
      }
      localData.account_id = id
      this.setState({
          accountId:id
      })
      this.setInLocalStorage(localData)
      this.fetchTableData(null,null,false,true,null,null,null,null,id)
  }

  render() {
    const { searchText, searchFlag, flag, itemPerPage, pageNumber, multipleSearch, searchLists,accountId } = this.state;
    // console.log("this.props from table ->", this.props);
    // console.log("this.state from tableView ->", this.state);
    // console.log("render -> ", this.props.location.state);
    const { match, params, loader, data,tableData } = this.props;
    const settings = JSON.parse(this.context.settings.data);

    let authUser = localStorage.getItem("user_token");
    if ((params && !(params.value === match.params.menu)) || flag) {
      const newParams = {
        token: authUser,
        value: match.params.menu,
        search: searchText,
        itemPerPage: itemPerPage,
        pageNumber: pageNumber,
        multipleSearchText: multipleSearch,
      };
      // localStorage.removeItem("state");
      this.setState({ flag: false, searchFlag: false });
     // this.props.getTableData(match.params.menu,searchText,itemPerPage,pageNumber,this.state.multipleSearch)
      //this.props.fetchTableData(newParams);
    }
    const title = <IntlMessages id={"_" + match.params.menu.toUpperCase()} />;
    return (
		<AuthorizationWrapper menu={match.params.menu}>
		  <div className="app-wrapper">
			<div className="animated slideInUpTiny animation-duration-3">
			  {(tableData.loader && (
				<div className="loader-view">
					{this.props.tableData.listingResponseStatus === "403"?
						<AccessDenied/>
						:
						<CircularProgress />
					}
				</div>
			  )) ||
				(tableData && tableData.status === "error" ? (
				  // <AccessDenied/>
					<h1>access denied</h1>
				) : (
				  <EnhancedTable
					title={title}
					column={tableData.columns}
					rows={tableData.rows}
					rowLoader={tableData.rowLoader}
					token={authUser}
				   // menu={params.value}
					menu={match.params.menu}
                    settings={settings}
					searchValue={this.searchValue}
					fetchItemsPerPage={this.fetchItemsPerPage}
					flag={searchFlag}
                    accountId={accountId}
					allTemplate={tableData.allSavedTemplate}
                    allAccounts={tableData.allAccounts}
					selectedTemplate={tableData.data && tableData.data.selected_template_id}
					tableName={match.params.menu}
					availableFields={tableData.data && tableData.data.available_fields}
					orderingFields={tableData.data && tableData.data.ordering_fields}
					customOrderingField={tableData.data && tableData.data.ordering_fields_custom}
					itemPerPage={itemPerPage}
					//totalItems={tableData.data && tableData.data.total_item}
					totalItems={tableData.data && tableData.totalItem}
					getPageNumber={this.getPageNumber}
					pageNumber={pageNumber}
					searchText={searchText}
					columnSearchText={multipleSearch}
					fetchColumnSearchResult={this.fetchColumnSearchResult}
					fetchColumnSearch={this.fetchColumnSearch}
					postOrderView={this.postOrderView}
					// setLocalValues = {this.setLocalValues}
					handleColumnSearch={this.handleColumnSearch}
                    handleChangeAccount={this.handleChangeAccount}
					searchLists={searchLists}
					multipleSearchOptions={this.state.multipleSearch}
					removeSearchItem={this.removeSearchItem}
					permissions={tableData.data && tableData.data.add_delete_permissions}
				  />
				))}
			  <NotificationContainer />
			</div>
		  </div>
		</AuthorizationWrapper>
    );
  }
}

// const mapStateToProps = ({ auth, table }) => {
//   // console.log("auth->", auth);
//   // console.log("table->", table);
//
//   const { alertMessage, showMessage, authUser, status } = auth;
//   const { loader, params, data } = table;
//   return { alertMessage, showMessage, authUser, status, loader, params, data };
// };


// export default connect(mapStateToProps,{
//   userSignIn,
//   hideMessage,
//   showAuthLoader,
//   fetchTableData
// }
// )(TableView);

const mapStateToProps = (state) =>({
  tableData:state.table
})

export default connect(mapStateToProps,(dispatch) => bindActionCreators({...actionCreators},dispatch)
)(TableView);
