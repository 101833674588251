import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { userSignIn } from "actions/Auth";
import "./Style.css";
import { DatePicker } from "material-ui-pickers";
import { withStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Select from "react-select";

import CustomAlertDialog from "../customComponents/dialog/customAlertDialog";
import {
	Input,
	TextField,
	Checkbox,
	Button,
	TextareaAutosize,
	CircularProgress,
} from "@material-ui/core";
import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications";
import { injectIntl } from "react-intl";
import config from "config";
import md5 from "md5";
import FormFolderView from "../customViews/routes/form/folderView";
import CustomDialog from "../customComponents/dialog/customDialog";

const styles = {
	input1: {
		height: 1,
	},
};

class AddNewApp extends React.Component {
	state = {
		selectedDate: null,
		notifyAlertOpen:false,
		disableBtn: true,
		loader: false,
		slug: "",
		current_account_id:"0",
		file: {
			logo: null,
			favicon: null,
			email_logo: null,
			background_image: null,
		},
	};

	componentDidMount() {
		let currentAcId = "0"
		let localData = this.getFromLocalStorage()
		if(localData !== null){
			if(localData["account_id"] !== undefined){
				currentAcId = localData["account_id"]
			}
		}

		this.setState({
			current_account_id:currentAcId
		})
	}

	getFromLocalStorage = () =>{
		let localData = JSON.parse(localStorage.getItem("localData"))
		if(localData === undefined){
			localData = null
		}
		return localData
	}
	handleSaveButton = async () => {
		this.setState({ disableBtn: true, loader: true });
		const { authUser, match, history } = this.props;
		let newBody = {};
		for (let key in this.state) {
			if (
				!(
					key === "selectedDate" ||
					key === "validationStatus" ||
					key === "disableBtn" ||
					key === "loader"
				)
			) {
				if (key === "password") {
					newBody[key] = md5(this.state[key]);
				} else {
					newBody[key] = this.state[key];
				}
			}
		}
		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/savePostValue/0`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			NotificationManager.info(response.msg);
			this.setState({ loader: false });
			setTimeout(goToEditPage,3000)
			function goToEditPage(){
				history.push(`/app/listing/${match.params.menu}/edit/${response.id}`)
			}
		}else{
			NotificationManager.error(response.msg);
			this.setState({ loader: false });
		}
	};

	handleSaveAndClose = async () => {
		this.setState({ disableBtn: true, loader: true });
		const { authUser, match, history } = this.props;
		let newBody = {};
		for (let key in this.state) {
			if (
				!(
					key === "selectedDate" ||
					key === "validationStatus" ||
					key === "disableBtn" ||
					key === "loader"
				)
			) {
				if (key === "password") {
					newBody[key] = md5(this.state[key]);
				} else {
					newBody[key] = this.state[key];
				}
			}
		}

		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/savePostValue/0`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			NotificationManager.info(response.msg);
			this.setState({ loader: false });
			setTimeout(goBackToList,3000)
			function goBackToList(){
				return history.goBack();
			}
		}else {
			NotificationManager.error(response.msg);
			this.setState({ loader: false });
		}
	};

	toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	handleSlug = (name) => {
		if (name === "slug") {
			let slg = this.state.slug;
			return slg
				.toLowerCase()
				.split(" ")
				.join("-");
		}
	};

	validate = () =>{
		const required = document.querySelectorAll("#require");

		let isValidTextField = false
		let isValidImageField = false
		let isValidLookup = false
		let isValidSelectField = false
		let isValidTextArea = false
		let isValidEmail = false

		if (required.length !== 0) {
			let i = 0;
			while (i < required.length) {
				if (required[i].value.length === 0) {
					//this.setState({ disableBtn: true });
					isValidTextField = false
					i = required.length;
				} else {
					//this.setState({ disableBtn: false });
					isValidTextField = true
					i++;
				}
			}
		} else {
			//this.setState({ disableBtn: false });
			isValidTextField = true
		}


		let fields = this.props.location.state.data.data
		if(fields === null || fields === undefined){
			fields = []
		}
		// validation for image
		let requiredImageFields = fields.filter(a =>a.required === "true" && a.field_type === "image")
		if(requiredImageFields.length > 0){
			let invalidCount = 0;
			for(let i = 0;i<requiredImageFields.length;i++){
				let isValid =  this.state.file[requiredImageFields[i].field_name] === null ? false:true
				if(!isValid){
					invalidCount = invalidCount+1
				}
				//let aa = isExistImage
			}
			if(invalidCount > 0){
				isValidImageField = false
			}else{
				isValidImageField = true
			}
		}else {
			isValidImageField = true
		}
		// validation for image

		//validation for lookup
		let requiredLookupFields = fields.filter(a =>a.required === "true" && a.field_type === "lookup")
		if(requiredLookupFields.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredLookupFields.length;i++){
				let ddlValue = this.state[requiredLookupFields[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === " " || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidLookup = false
				}else {
					isValidLookup = true
				}
			}
		}else {
			isValidLookup = true
		}
		//validation for lookup

		//validation for select
		let requiredSelectFields = fields.filter(a =>a.required === "true" && a.field_type === "select")
		if(requiredSelectFields.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredSelectFields.length;i++){
				let ddlValue = this.state[requiredSelectFields[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === " " || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidSelectField = false
				}else {
					isValidSelectField = true
				}
			}
		}else {
			isValidSelectField = true
		}
		//validation for select

		//validation for text area
		let requiredTextArea = fields.filter(a =>a.required === "true" && a.field_type === "textarea")
		if(requiredTextArea.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredTextArea.length;i++){
				let ddlValue = this.state[requiredTextArea[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === " " || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidTextArea = false
				}else {
					isValidTextArea = true
				}
			}
		}else {
			isValidTextArea = true
		}
		//validation for text area

		//validation for text email
		let requiredEmail = fields.filter(a =>a.required === "true" && a.field_type === "email")
		if(requiredEmail.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredEmail.length;i++){
				let emailValue = this.state[requiredEmail[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(emailValue === " " ||emailValue === "" || emailValue === undefined || emailValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidEmail = false
				}else {
					isValidEmail = true
				}
			}
		}else {
			isValidEmail = true
		}
		//validation for text area
		if(isValidTextField  && isValidImageField && isValidLookup && isValidSelectField && isValidTextArea && isValidEmail){
			this.setState({disableBtn:false})
		}else{
			this.setState({disableBtn:true})
		}
	}
	//end of validate

	handleInput = (name, type) => async (event) => {
		if (type === "image") {
			if (!event.target.files[0]) {
				this.setState({
					file: { ...this.state.file, [name]: null },
				});
			} else {
				const fileName = URL.createObjectURL(event.target.files[0]);
				this.setState({
					file: { ...this.state.file, [name]: fileName },
				});
			}
		}
		//set-time-in-month
		if (name === "set_time_in_month") {
			if (event.target.value < 1) {
				event.target.value = "";
			} else {
				event.target.value = Math.round(event.target.value);
			}
		}
		//SLUG
		if (name === "role_name") {
			this.setState({ slug: event.target.value });
		}

		if (type && type === "datetime") {
			this.setState({ [name]: event, selectedDate: event });
		} else if (type && type === "single_checkbox") {
			this.setState({ [name]: event.target.checked });
		} else if (type && (type === "image" || type === "file")) {
			if (event.target.files[0]) {
				const file = event.target.files[0];
				const base64 = await this.toBase64(file);
				this.setState({ [name]: base64 });
			}
		} else {
			this.setState({ [name]: event.target.value });
		}
		setTimeout(this.validate,5000)
	};

	handleChange = (name) => (selectedOption) => {

		// const required = document.querySelectorAll("#require");
		//
		// if (required.length !== 0) {
		// 	let i = 0;
		// 	while (i < required.length) {
		// 		if (required[i].value.length === 0) {
		// 			this.setState({ disableBtn: true });
		// 			i = required.length;
		// 		} else {
		// 			this.setState({ disableBtn: false });
		// 			i++;
		// 		}
		// 	}
		// } else {
		// 	this.setState({ disableBtn: false });
		// }


		this.setState({ [name]: selectedOption.value });

		if (name === "mandatory_password_change") {
			if (selectedOption.value === "0") {
				document.getElementById("mnth").disabled = true;
			} else {
				document.getElementById("mnth").disabled = false;
			}
		}
		setTimeout(this.validate,5000)
		//this.validate()
	};

	handleEmailUniqueness = (name) => async (event) => {
		const { authUser, match } = this.props;

		const response = await fetch(
			`${config.api.url}/api/${match.params.menu}/check_field_uniqueness_api`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					table_name: match.params.menu,
					field_name: name,
					field_value: event.target.value,
					id: "",
				}),
			}
		)
			.then((res) => res.json())
			.then((json) => json);

		if (response.status === "error") {
			this.setState({ validationStatus: response.message });
		} else {
			this.setState({ validationStatus: "" });
		}
	};

	handleRequired = (required) => (e) => {
		if (required === "true" && e.target.value.length === 0) {
			this.setState({ disableBtn: true });
			NotificationManager.error("The field is empty");
		}
	};

	handleNotifyOption = () => {
		const { authUser, match } = this.props;
		if (!this.state.email || this.state.email === "") {
			NotificationManager.error(<IntlMessages id={"_ECLICKSPRO_EMAIL_IS_REQUIRED"}/>);
		}else{
			if (this.state.notify_option === "1") {
				// fetch(`${config.api.url}api/${match.params.menu}/send_invitation`, {
				// 	method: "POST",
				// 	headers: {
				// 		Authorization: authUser,
				// 		"Content-Type": "application/json",
				// 	},
				// 	body: JSON.stringify({
				// 		send_password: 0,
				// 		user_id: "",
				// 		raw_password: "",
				// 		language: "english",
				// 		email: this.state.email,
				// 	}),
				// })
				// 	.then((res) => res.json())
				// 	.then((json) => {
				// 		if (json.status === "success") {
				// 			NotificationManager.info(json.message);
				// 		} else {
				// 			debugger
				// 			NotificationManager.error(json.message);
				// 		}
				// 	});
				this.setState({
					notifyAlertOpen:true
				})
			}

			if (this.state.notify_option === "2") {
				if (
					!this.state.password ||
					this.state.password !== this.state.repeatPassword
				) {
					NotificationManager.error(<IntlMessages id={"_BOTH_PASSWORD_DO_NOT_MATCH"}/>);
				}else{
					// fetch(`${config.api.url}api/dashboard_login/send_invitation`, {
					// 	method: "POST",
					// 	headers: {
					// 		Authorization: authUser,
					// 		"Content-Type": "application/json",
					// 	},
					// 	body: JSON.stringify({
					// 		send_password: 1,
					// 		user_id: "",
					// 		raw_password: this.state.password,
					// 		language: "english",
					// 		email: this.state.email,
					// 	}),
					// })
					// 	.then((res) => res.json())
					// 	.then((json) => {
					// 		if (json.status === "success") {
					// 			NotificationManager.info(json.message);
					// 		} else {
					// 			NotificationManager.error(json.message);
					// 		}
					// 	});
					this.setState({
						notifyAlertOpen:true
					})
					}

				}
			}

	};

	// handleOpenNotifyOption = () =>{
	// 	this.setState({
	// 		notifyAlertOpen:true
	// 	})
	// }
	handleCloseNotityAlert = () =>{
		this.setState({
			notifyAlertOpen:false
		})
	}

	render() {
		const { match, history, location, intl } = this.props;
		const { data } = location.state.data;
		let asteriskColor = "grey"

		return (
			<div className="app-wrapper">
				{/*<div className="page-heading">*/}
				<div className="jr-card">
					<div className="animated slideInUpTiny animation-duration-3">
						<div className="row">
							<div className="col-md-6 col-12">
								<h1>
									<IntlMessages id="_NEW" />:{" "}
									<span style={{ color: "#4caf50" }}>
										<IntlMessages id={"_" + match.params.menu.toUpperCase()} />
									</span>
									<span style={{ marginLeft: "20px" }}>
										<Button
											onClick={() => history.push(`/app/listing/${match.params.menu}`)}
											variant="outlined"
											className="jr-btn jr-btn-lg"
											style={{
												border: "1px #00bcd4 solid",
												color: "#00bcd4",
											}}
										>
											<span>&#8676;</span> <IntlMessages id="_BACK_TO_LIST_PAGE" />
										</Button>
									</span>
									<span></span>
								</h1>
							</div>
							<div
								className="col-md-6 col-12"
								style={{ display: "flex", justifyContent: "flex-end" }}
							>
								<Button
									variant="contained"
									color="primary"
									className="jr-btn text-white jr-btn-lg"
									onClick={this.handleSaveButton}
									disabled={this.state.disableBtn}
									style={{ maxHeight: "39.19px", minHeight: "39.19px" }}
								>
									{this.state.loader && <CircularProgress size={15} />}
									<IntlMessages id="_SAVE" />
								</Button>
								<Button
									variant="contained"
									color="primary"
									className="jr-btn text-white jr-btn-lg"
									onClick={this.handleSaveAndClose}
									disabled={this.state.disableBtn}
									style={{ maxHeight: "39.19px", minHeight: "39.19px" }}
								>
									{this.state.loader && <CircularProgress size={15} />}
									<IntlMessages id="_SAVE_AND_CLOSE" />
								</Button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div id="editView">
									<div className="row">
										{data.map((field, i) => {
											if (field.field_type !== "hidden") {
												return (
													<div className="col-4" style={{ marginTop: "10px" }} key={`${field.field_type}_${field.field_name}`}>
														{/* <label style={{ fontSize: "16px" }}>
                              {" "}
                              <IntlMessages
                                id={"_" + field.field_name.toUpperCase()}
                              />{" "}
                              {field.required === "true" && (
                                <span style={{ color: "red" }}>* </span>
                              )}
                              :{" "}
                            </label> */}
														{field.field_type === "input" && (
															<TextField
																label={intl.formatMessage({
																	id: "_" + field.field_name.toUpperCase(),
																})}
																className=""
																id={field.required === "true" ? "require" : "non-required"}
																required={field.required === "true"}
																key={i}
																type="text"
																defaultValue=""
																onChange={this.handleInput(field.field_name)}
																fullWidth
																style={{
																	backgroundColor: "white",
																	marginTop: "13px",
																}}
																onBlur={this.handleRequired(field.required)}
																value={this.handleSlug(field.field_name)}
																disabled={field.field_name === "current_package" || field.field_name === "pay_per"}
															/>
														)}

														{field.field_type === "textarea" && (
															<div>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<TextareaAutosize
																	className="mb-3"
																	key={i}
																	defaultValue=""
																	rowsMin={10}
																	rowsMax={10}
																	cols={40}
																	onChange={this.handleInput(field.field_name)}
																	style={{
																		backgroundColor: "white",
																		width: "100%",
																	}}
																/>
															</div>
														)}

														{field.field_type === "email" && field.unique === "true" && (
															<div>
																<TextField
																	label={intl.formatMessage({
																		id: "_" + field.field_name.toUpperCase(),
																	})}
																	required={field.required === "true"}
																	className="mb-3"
																	key={i}
																	type="text"
																	defaultValue=""
																	onChange={this.handleInput(field.field_name)}
																	fullWidth
																	style={{
																		backgroundColor: "white",
																		marginTop: "13px",
																	}}
																	onBlur={this.handleEmailUniqueness(field.field_name)}
																/>
																<span style={{ color: "red" }}>
																	{this.state.validationStatus}
																</span>
															</div>
														)}

														{field.field_type === "email" && field.unique === "false" && (
															<TextField
																label={intl.formatMessage({
																	id: "_" + field.field_name.toUpperCase(),
																})}
																required={field.required === "true"}
																id={field.required === "true" ? "require" : "non-required"}
																className="mb-3"
																key={i}
																type="text"
																defaultValue=""
																onChange={this.handleInput(field.field_name)}
																fullWidth
																style={{
																	backgroundColor: "white",
																	marginTop: "13px",
																}}
																onBlur={this.handleRequired(field.required)}
															/>
														)}

														{field.field_type === "money" && (
															<TextField
																label={intl.formatMessage({
																	id: "_" + field.field_name.toUpperCase(),
																})}
																required={field.required === "true"}
																className="mb-3"
																key={i}
																type="number"
																defaultValue=""
																onChange={this.handleInput(field.field_name)}
																fullWidth
																style={{
																	backgroundColor: "white",
																	marginTop: "13px",
																}}
															/>
														)}

														{field.field_type === "number" && (
															<TextField
																label={intl.formatMessage({
																	id: "_" + field.field_name.toUpperCase(),
																})}
																required={field.required === "true"}
																className="mb-3"
																key={i}
																type="number"
																id="mnth"
																defaultValue=""
																onChange={this.handleInput(field.field_name)}
																fullWidth
																style={{
																	backgroundColor: "white",
																	alignContent: "center",
																	marginTop: "13px",
																}}
																inputProps={{ min: "1", step: "1" }}
															/>
														)}

														{field.field_type === "datetime" && (
															<div>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<DatePicker
																	key={i}
																	className="mb-3"
																	fullWidth
																	disabled={field.field_name === "start_date" || field.field_name === "end_date"}
																	value={this.state.selectedDate}
																	onChange={this.handleInput(field.field_name, field.field_type)}
																	animateYearScrolling={false}
																	leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
																	rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
																/>
															</div>
														)}

														{field.field_type === "single_checkbox" && (
															<div>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<Checkbox
																	key={i}
																	className="ml-2"
																	color="primary"
																	defaultValue=""
																	onChange={this.handleInput(field.field_name, field.field_type)}
																/>
															</div>
														)}

														{field.field_type === "file" && (
															<Fragment>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<Input
																	id="fileItem"
																	key={i}
																	type="file"
																	className="ml-2"
																	disableUnderline={true}
																	onChange={this.handleInput(field.field_name, field.field_type)}
																	style={{ display: "none" }}
																/>
																<label htmlFor="fileItem">
																	<Button
																		variant="contained"
																		className="jr-btn bg-orange text-white jr-btn-lg"
																		component="span"
																		style={{
																			maxHeight: "30.19px",
																			minHeight: "30.19px",
																			marginLeft: "20px",
																		}}
																	>
																		Choose file
																	</Button>
																</label>
															</Fragment>
														)}

														{field.field_type === "image" && (
															<Fragment>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<Input
																	id={field.field_name}
																	//id={field.required === "true" ? field.field_name+"-require" : field.field_name+"-non-required"}
																	key={i}
																	type="file"
																	className="ml-2"
																	disableUnderline={true}
																	onChange={this.handleInput(field.field_name, field.field_type)}
																	style={{ display: "none" }}
																/>
																<label htmlFor={field.field_name}>
																	<Button
																		variant="contained"
																		className="jr-btn bg-cyan text-white jr-btn-lg"
																		component="span"
																		style={{
																			maxHeight: "30.19px",
																			minHeight: "30.19px",
																			marginLeft: "20px",
																		}}
																	>
																		Choose file
																	</Button>
																	{this.state.file[field.field_name] ? (
																		<img
																			style={{ width: "80px" }}
																			src={this.state.file[field.field_name]}
																		/>
																	) : field.value ? (
																		<img style={{ width: "80px" }} src={field.value} />
																	) : null}
																</label>
															</Fragment>
														)}

														{field.field_type === "select" && (
															<div>
																{field.field_name === "notify_option" ?
																	<div>
																		<label style={{ fontSize: "16px" }}>
																			{" "}
																			<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																			{field.required === "true" && (
																				<span style={{ color: asteriskColor }}>* </span>
																			)}
																			:{" "}
																		</label>
																		<Select
																			autoWidth={true}
																			//inputProps={{ readOnly: true }}
																			onChange={this.handleChange(field.field_name)}
																			options={field.option.map((option) => ({
																				value: option.key.toString(),
																				label: option.values,
																			}))}
																			//value={" "}
																			//options={[]}
																		/>
																	</div>
																	:
																	<div>
																		<label style={{ fontSize: "16px" }}>
																			{" "}
																			<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																			{field.required === "true" && (
																				<span style={{ color: asteriskColor }}>* </span>
																			)}
																			:{" "}
																		</label>
																		<Select
																			onChange={this.handleChange(field.field_name)}
																			defaultValue={field.value}
																			options={field.option.map((option) => ({
																				value: option.key.toString(),
																				label: option.values,
																			}))}
																		/>
																	</div>

																}
															</div>
														)}

														{(field.field_type === "lookup" ||
															field.field_type === "custom_select_opening_view" ||
															field.field_type === "custom_select") && (
															<div>
																<label style={{ fontSize: "16px" }}>
																	{" "}
																	<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																	{field.required === "true" && (
																		<span style={{ color: asteriskColor }}>* </span>
																	)}
																	:{" "}
																</label>
																<Select
																	className="primary"
																	defaultValue={field.value}
																	onChange={this.handleChange(field.field_name)}
																	options={field.option.map((option) => ({
																		value: option.key.toString(),
																		label: option.value,
																	}))}
																/>
															</div>
														)}
													</div>
												);
											}
										})}
									</div>
									{/* for notification options */}
									<div className="row">
										{match.params.menu === "dashboard_login" && this.state.notify_option && (
											<div className="col-4" style={{ marginTop: "10px" }}>
												{(this.state.notify_option === "2" ||
													this.state.notify_option === "3") && (
													<div className="mt-1">
														<TextField
															className="mb-3"
															type="password"
															label={<IntlMessages id={"_PASSWORD"} />}
															onChange={(e) => this.setState({ password: e.target.value })}
															fullWidth
															variant="outlined"
															style={{
																backgroundColor: "white",
																padding: "auto",
																zIndex: "0",
															}}
															autoComplete="off"
															size="small"
														/>
														<TextField
															label={<IntlMessages id={"_REPEAT_PASSWORD"} />}
															className="mb-1"
															type="password"
															autoComplete="off"
															onChange={(e) =>
																this.setState({
																	repeatPassword: e.target.value,
																})
															}
															fullWidth
															variant="outlined"
															style={{
																backgroundColor: "white",
																padding: "auto",
																zIndex: "0",
															}}
															size="small"
														/>
													</div>
												)}

												{(this.state.notify_option === "1" ||
													this.state.notify_option === "2") && (
													<div>
														<Button
															variant="contained"
															className="jr-btn bg-green text-white jr-btn-lg"
															onClick={this.handleNotifyOption}
															style={{
																maxHeight: "39.19px",
																minHeight: "39.19px",
																marginTop: "10px",
															}}
														>
															<IntlMessages id="_SEND_INVITATION_EMAIL" />
														</Button>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<NotificationContainer />
				<CustomAlertDialog
					open={this.state.notifyAlertOpen}
					handleClose={this.handleCloseNotityAlert}
					contentTextKey ="_ECLICKSPRO_USER_ADD_NOTIFY_OPTION_ALERT"
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { authUser } = auth;
	return { authUser };
};

const AddNew = compose(
	connect(mapStateToProps, {
		userSignIn,
	}),
	withStyles(styles),
	injectIntl
);

export default AddNew(AddNewApp);
