import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import configureStore, { history } from "./store";
// import "./firebase/firebase";
import App from "./containers/App";
import { SettingProvider } from "context/SettingContext";
import { MenuProvider } from "context/MenuContext";

export const store = configureStore();

const MainApp = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<MenuProvider>
					<SettingProvider>
						<Route path="/" component={App} />
					</SettingProvider>
				</MenuProvider>
			</Switch>
		</ConnectedRouter>
	</Provider>
);

export default MainApp;
