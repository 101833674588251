import React from "react";
import IntlMessages from "util/IntlMessages";
import SendIcon from "@material-ui/icons/Send";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ContactsIcon from "@material-ui/icons/Contacts";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PaymentIcon from '@material-ui/icons/Payment';

export default function Notification({
  emailSent,
  emailPending,
  campaignRunning,
  relations,
  appointments,
  payments,
  openPayments
}) {
  return (
    <div className="jr-card jr-card-widget jr-card-ticketlist card">
      <div className="jr-wel-ema pt-xl-2 mb-1">
        <p className="jr-fs-lg text-uppercase" style={{ fontWeight: "bold" }}>
          <IntlMessages id="_ECLICKSPRO_YOU_HAVE" />
        </p>
        <ul className="list-unstyled">
          <li className="mb-2">
            <SendIcon fontSize="default" />
            <span className="ml-3">{emailSent} Emails Send This Week </span>
          </li>

          <li className="mb-2">
            <CancelScheduleSendIcon fontSize="default" />
            <span className="ml-3">
              {emailPending} Emails Pending This Week{" "}
            </span>
          </li>

          <li className="mb-2">
            <AccountTreeIcon fontSize="default" />
            <span className="ml-3">{campaignRunning} Campaigns Running </span>
          </li>

          <li className="mb-2">
            <ContactsIcon fontSize="default" />
            <span className="ml-3">
              Total {relations} Numbers of Relations{" "}
            </span>
          </li>

          <li className="mb-2">
            <EventAvailableIcon fontSize="default" />
            <span className="ml-3">{appointments} Appointments </span>
          </li>

          <li className="mb-2" onClick={openPayments} style={{cursor:"pointer"}}>
            <PaymentIcon fontSize="default" />
            <span className="ml-3">{payments} Payments </span>
          </li>
        </ul>
      </div>
    </div>
  );
}
