import React from 'react'

//material
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


const CircularLoader = () =>{
    return(
        <div className="loader-view">
            <div style={{margin:"80px 0 80px 0"}}>
                <CircularProgress />
            </div>
        </div>
    )
}
export default CircularLoader