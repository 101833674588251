import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

class RegSuccessPage extends Component {
	render() {
		return (
			<Container maxWidth={"sm"}>
				<div>
					<div style={{marginTop:"100px"}}>
						<Grid container>
							<Grid item md={8} container justify={"center"}>


								{/*<li>Create your mybizzmail account Trial 14 days free.</li>*/}
								{/*<li>Check your e-mail and verify your account.</li>*/}
								{/*<li>Get started creating your newsletter.</li>*/}

							</Grid>
						</Grid>
					</div>
					<div style={{marginTop:"30px"}}>
						<Grid container>
							<Grid item md={8} container justify={"center"}>
								<Typography style={{color:"green",fontSize:"30px"}}>Congratulations! Your account is created</Typography>
								<Typography style={{color:"gray",fontSize:"30px"}}>Check your e-mail and verify your account.</Typography>
								{/*<li>Create your mybizzmail account Trial 14 days free.</li>*/}
								{/*<li>Check your e-mail and verify your account.</li>*/}
								{/*<li>Get started creating your newsletter.</li>*/}

							</Grid>
						</Grid>
					</div>
				</div>
			</Container>
		);
	}
}

export default RegSuccessPage;