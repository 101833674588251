import {
  FETCH_REPORT_GROUP,
  FETCH_REPORT_GROUP_SUCCESS,
  FETCH_REPORT_FILTER,
  FETCH_REPORT_FILTER_SUCCESS,
  FETCH_REPORT_RELATION,
  FETCH_REPORT_RELATION_SUCCESS,
  FETCH_REPORT_API,
  FETCH_REPORT_API_SUCCESS,
  FETCH_REPORT_LETTER,
  FETCH_REPORT_LETTER_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  params: null,
  group: null,
  filter: null,
  relation: null,
  api: null,
  letter: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_REPORT_GROUP: {
      return { ...state, loader: true, params: action.payload };
    }
    case FETCH_REPORT_GROUP_SUCCESS: {
      return { ...state, loader: false, group: action.payload };
    }
    case FETCH_REPORT_FILTER: {
      return { ...state, loader: true, params: action.payload };
    }
    case FETCH_REPORT_FILTER_SUCCESS: {
      return { ...state, loader: false, filter: action.payload };
    }
    case FETCH_REPORT_RELATION: {
      return { ...state, loader: true, params: action.payload };
    }
    case FETCH_REPORT_RELATION_SUCCESS: {
      return { ...state, loader: false, relation: action.payload };
    }
    case FETCH_REPORT_API: {
      return { ...state, loader: true, params: action.payload };
    }
    case FETCH_REPORT_API_SUCCESS: {
      return { ...state, loader: false, api: action.payload };
    }
    case FETCH_REPORT_LETTER: {
      return { ...state, loader: true, params: action.payload };
    }
    case FETCH_REPORT_LETTER_SUCCESS: {
      return { ...state, loader: false, letter: action.payload };
    }
    default:
      return state;
  }
};
