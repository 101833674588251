import React from "react";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const DynamicIcons = ({ id, table }) => {
  if (id === "none") {
    if (table) {
      return <span></span>;
    } else {
      return <RadioButtonUncheckedIcon id={id} fontSize="small" />;
    }
  }
  if (id === "asc") {
    return <ArrowUpwardIcon id={id} fontSize="small" />;
  }
  if (id === "desc") {
    return <ArrowDownwardIcon id={id} fontSize="small" />;
  }
};

export default DynamicIcons;
