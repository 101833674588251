import * as Constants from "../common/Constants"
import { SUCCESS_MANUAL_SORT_ITEM_FOLDERS } from './../common/Constants';

const INIT_STATE = {
    loader: true,
    openFullScreenLoader: false,
    items: [],
    folders: [],
    newItemCreated:false,
    newItem:null,
    portalAccounts:[],
    sharedData:[],
    wesp:[],
    folderViewApiResponse:null,
    allAccounts:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case Constants.REQUEST_GET_ALL_ITEMS_IN_FOLDER:{
            return {
                ...state,
                loader: true,
                items:[],
                folders: [],
            }
        }
        case Constants.SUCCESS_GET_ALL_ITEMS_IN_FOLDER:{
            return {
                ...state,
                loader: false,
                items: action.items,
                folders: action.folders,
                openFullScreenLoader: false
            }
        }
        case Constants.FAILED_GET_ALL_ITEMS_IN_FOLDER:{
            return {
                ...state,
                loader: false,
                items:[],
                folders: [],
                folderViewApiResponse: action.apiResponse,
                openFullScreenLoader: false


            }
        }
        case Constants.REQUEST_ADD_NEW_FOLDER:{
            return {
                ...state,
                folderViewApiResponse: null,
                openFullScreenLoader: true,
            }
        }
        case Constants.SUCCESS_ADD_NEW_FOLDER:{
            return {
                ...state,
                openFullScreenLoader: false,
                folders: [...action.folders, ...state.folders],
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_ADD_NEW_FOLDER:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.RESET_FOLDER_VIEW_RESPONSE:{
            return {
                ...state,
                folderViewApiResponse: null
            }
        }
        case Constants.REQUEST_ADD_NEW_ITEM:{
            return{
                ...state,
                folderViewApiResponse: null,
                openFullScreenLoader: true
            }
        }
        case Constants.SUCCESS_ADD_NEW_ITEM:{
            return {
                ...state,
                items: [...action.item, ...state.items],
                newItemCreated: true,
                newItem: action.item[0],
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_ADD_NEW_ITEM:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }


        case Constants.REQUEST_RENAME_FOLDER:{
            return {
                ...state,
                folderViewApiResponse: null,
            }
        }
        case Constants.SUCCESS_RENAME_FOLDER:{
            let folderId = action.folderId;
            let item = state.folders.filter((i) => i.id === folderId);
            let index = state.folders.indexOf(item[0]);
            state.folders.splice(index, 1, action.folder[0]);

            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            };
        }
        case Constants.FAILED_RENAME_FOLDER:{
            return {
                ...state,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_RENAME_ITEM:{
            return {
                ...state,
                openFullScreenLoader:true,
                folderViewApiResponse: null
            }
        }
        case Constants.SUCCESS_RENAME_ITEM: {
            let itemId = action.itemObj.id;
            let item = state.items.find((i) => i.id === itemId);
            if(item){
                let index = state.items.indexOf(item);
                state.items.splice(index, 1, action.itemObj);
            }

            return {
                ...state,
                openFullScreenLoader:false,
                folderViewApiResponse: action.apiResponse
            };
        }
        case Constants.FAILED_RENAME_ITEM:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }


        case Constants.SUCCESS_DELETE:{
            
            let items = state.items
            let folders = state.folders
            for(let i=0;i < action.deletedItems.length;i++){
                items.filter(a =>a.id !== action.deletedItems[i])
            }
            for(let j=0;j < action.deletedFolders.length;j++){
                folders.filter(a =>a.id !== action.deletedFolders[j])
            }

            return {
                ...state,
                items: items,
                folders: folders,
                folderViewApiResponse: action.apiResponse
            }
        }

        case Constants.REQUEST_GET_SHARE_PORTAL_DATA:{
            return {
                ...state,
                sharedData: []
            }
        }
        case Constants.SUCCESS_GET_SHARE_PORTAL_DATA:{
            return {
                ...state,
                sharedData: action.sharedData
            }
        }
        case Constants.FAILED_GET_SHARE_PORTAL_DATA:{
            return {
                ...state,
                sharedData: []
            }
        }
        case Constants.REQUEST_GET_WESP:{
            return {
                ...state,
                wesp: []
            }
        }
        case Constants.SUCCESS_GET_WESP:{
            return {
                ...state,
                wesp: action.wesp
            }
        }
        case Constants.FAILED_GET_WESP:{
            return {
                ...state,
                wesp: action.wesp
            }
        }
        case Constants.REQUEST_UPDATE_SHARE_PORTAL:{
            return {
                ...state,
                folderViewApiResponse: null,
                openFullScreenLoader: true,
            }
        }
        case Constants.SUCCESS_UPDATE_SHARE_PORTAL:{
            return {
                ...state,
                folderViewApiResponse: action.apiResponse,
                openFullScreenLoader: false
            }
        }
        case Constants.FAILED_UPDATE_SHARE_PORTAL:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_COPY_ITEM:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null,
            }
        }
        case Constants.SUCCESS_COPY_ITEM:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse,
                items: [...action.items, ...state.items],
                newItemCreated:true,
                newItem:action.items[0]

            }
        }
        case Constants.FAILED_COPY_ITEM:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_DELETE_ITEM:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null
            }
        }
        case Constants.SUCCESS_DELETE_ITEM:{
            let itemId = action.itemId;
            let items = state.items.filter((i) => i.id !== itemId);
            return {
                ...state,
                items: items,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_DELETE_ITEM:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_DELETE_FOLDER:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null,
            }
        }
        case Constants.SUCCESS_DELETE_FOLDER:{
            let folderId = action.folderId;
            let folders = state.folders.filter((i) => i.id !== folderId);
            return {
                ...state,
                openFullScreenLoader: false,
                folders: folders,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_DELETE_FOLDER:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_UPDATE_ITEM_PARENT:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null
            }
        }
        case Constants.SUCCESS_UPDATE_ITEM_PARENT:{
            let items = state.items.filter((i) => i.id !== action.itemId);
            return {
                ...state,
                items: items,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_UPDATE_ITEM_PARENT:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_UPDATE_FOLDER_PARENT:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null
            }
        }
        case Constants.SUCCESS_UPDATE_FOLDER_PARENT:{
            let folders = state.folders.filter((i) => i.id !== action.folderId);
            return {
                ...state,
                openFullScreenLoader: false,
                folders: folders,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_UPDATE_FOLDER_PARENT:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_PUT_SMS:{
            return {
                ...state,
                openFullScreenLoader: true,
                folderViewApiResponse: null
            }
        }
        case Constants.SUCCESS_PUT_SMS:{
            let smsId = action.sms[0].id;
            let item = state.items.filter((i) => i.id === smsId);
            let index = state.items.indexOf(item[0]);
            state.items.splice(index, 1, action.sms[0]);
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.FAILED_PUT_SMS:{
            return {
                ...state,
                openFullScreenLoader: false,
                folderViewApiResponse: action.apiResponse
            }
        }
        case Constants.REQUEST_GET_ALL_ACCOUNT:{
            return {
                ...state,
                allAccounts: []
            }
        }
        case Constants.SUCCESS_GET_ALL_ACCOUNT:{
            return {
                ...state,
                allAccounts: action.accounts
            }
        }
        case Constants.FAILED_GET_ALL_ACCOUNT:{
            return {
                ...state,
                allAccounts: []
            }
        }

        case Constants.RESET_FOLDER_VIEW_STATE:{
            return {
                ...state,
                openFullScreenLoader: false,
                items: [],
                folders: [],
                newItemCreated:false,
                newItem:null,
                portalAccounts:[],
                sharedData:[],
                wesp:[],
                folderViewApiResponse:null
            }
        }
        case Constants.REQUEST_MANUAL_SORT_ITEM:{
			return{
				...state,
                openFullScreenLoader: true,
			}
		}
		case Constants.SUCCESS_MANUAL_SORT_ITEM:{
			return{
				...state,
                openFullScreenLoader: false,
				items: action.items
			}
		}
		case Constants.SUCCESS_MANUAL_SORT_ITEM_FOLDERS:{
			return{
				...state,
                openFullScreenLoader: false,
				folders: action.folders
			}
		}
		case Constants.FAILED_MANUAL_SORT_ITEM:{
			return{
				...state,
                openFullScreenLoader: false,
				folderViewApiResponse: action.folderViewApiResponse
			}
		}


        default: {
            return state;
        }
    }
};
