import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import { getResponseForNotificaion } from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";
import { getAsync } from "../common/helpers/HttpService";

//variables

const actionsFor = "campaign"



export const actionCreators = {
	cleanList: () => async (dispatch, getState) => {
		dispatch({ type: "GET_ALL_EMPTY" });
	},
	resetShareSuccess: () => async (dispatch, getState) => {
		dispatch({ type: "RESET_SHARE_CAMPAIGN" });
	},
	getCampaign: (id) => async (dispatch, getState) => {
		let {token} = getAuthData()
		let authInfo = null
		if(token !== null){
			authInfo = getAuthData().user
		}
		dispatch({ type: Constants.REQUEST_GET_CAMPAIGN,auth: authInfo });
		let url = config.api.url + "v1/campaign/" + id;
		// axios({
		// 	method: "get",
		// 	url: url,
		// 	headers: {
		// 		Authorization: token,
		// 	},
		// })
		// 	.then((res) => {
		// 		dispatch({
		// 			type: Constants.SUCCESS_GET_CAMPAIGN,
		// 			campaign: res.data.data,
		// 			cards:
		// 				res.data.data.data.cards === undefined ? [] : res.data.data.data.cards,
		// 			campaignActions:
		// 				res.data.data.data.actions === undefined
		// 					? []
		// 					: res.data.data.data.actions,
        //             startPoint:res.data.data.data.startPoint === undefined ? null:res.data.data.data.startPoint
		// 		});
		// 	})
		// 	.catch((e) => {
		// 		let aaaa = e;
		// 	});

		let response = await getAsync(url)
		if(response){
			let campaign = response.data && response.data.data
			let {cards = [],actions = [],startPoint = null}  = response.data && response.data.data.data
			if(response.data.status === "success"){
				dispatch({
					type: Constants.SUCCESS_GET_CAMPAIGN,
					campaign: campaign,
					cards: cards,
					campaignActions:actions,
					startPoint:startPoint
				});
			}else {
				dispatch({
					type: Constants.FAILED_GET_CAMPAIGN,
					campaign: campaign,
					cards: [],
					campaignActions:[],
					startPoint:null
				});
			}
		}
	},
	getAllCampaing: (fldId, searchValue="", sortBy="") => async (dispatch, getState) => {
		// let token = localStorage.getItem("user_token");
		let authInfo = null
		let {token} = getAuthData()
		if(token !== null){
			authInfo = JSON.parse(atob(token.split(".")[1]));
		}
		dispatch({ type: "GET_ALL_CAMPAIGN", auth: authInfo });
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/campaign?folder_id=" + fldId + "&sort_by=" + sortBy+ "&search_value="+ searchValue;
		let folderUrl = apiUrl + "v1/folder/campaign/" + fldId+"?sort_by=" + sortBy+ "&name="+searchValue;

        axios({
            method: "get",
            url: apiUrl+"api/settings",
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                let settings = JSON.parse(res.data.data)
                dispatch({ type: "SUCCESS_GET_GLOBAL_SETTINGS", data: settings });
            })
            .catch((e) => {
                let aaaa = e;
            });


		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({ type: "GET_ALL_CAMPAIGN_SUCCESS", data: res.data.data.items });
			})
			.catch((e) => {
				let aaaa = e;
			});

		axios({
			method: "get",
			url: folderUrl,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({
					type: "GET_ALL_CAMPAIGN_FOLDER_SUCCESS",
					folders: res.data.data.items,
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	addFolder: (name, parentId) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/folder/";
		let {token} = getAuthData()

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { name: name, type: "campaign", parent_id: parentId },
		})
			.then((res) => {
				dispatch({
					type: "ADD_CAMPAIGN_FOLDER_SUCCESS",
					folders: [res.data.data],
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	addCampaign: (name, parentId,data) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/campaign";
		let {token} = getAuthData()
		if(data === undefined || data === null){
			data = ""
		}

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { name: name, parent_id: parentId,data:data },
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({
						type: "ADD_CAMPAIGN_SUCCESS",
						campaign: [res.data.data],
					});
				}
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	putCampaign: (campaign, id) => async (dispatch, getState) => {
		let url = config.api.url + "v1/campaign/" + id;
		let {token} = getAuthData()
		axios({
			method: "put",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { data: campaign },
		})
			.then((res) => {
				let campObj = res.data.data
				dispatch({
					type: "SUCCESS_PUT_CAMPAIGN",
					campaignObj: campObj,
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},

	renameCampaign: (id, name) => async (dispatch, getState) => {
		let url = config.api.url + "v1/campaign/" + id;
		let {token} = getAuthData()
		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				name: name,
			},
		})
			.then((res) => {
				dispatch({
					type: Constants.SUCCESS_RENAME_CAMPAIGN,
					campaign: [res.data.data],
					campaignId: id,
				});
			})
			.catch((e) => console.log(e));
	},
	renameFolder: (id, name) => async (dispatch, getState) => {
		let url = config.api.url + "v1/folder/" + id;
		let {token} = getAuthData()
		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				name: name,
			},
		})
			.then((res) => {
				dispatch({
					type: Constants.SUCCESS_RENAME_FOLDER,
					folder: [res.data.data],
					folderId: id,
				});
			})
			.catch((e) => console.log(e));
	},
	deleteRootCard: () => async (dispatch, getState) => {
		dispatch({ type: "CAMPAIGN_DELETE_ROOTCARD_SUCCESS" })

	},
	deleteSelected: (campIds, folderIds) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let campUrl = apiUrl + "v1/campaign/";
		let folderUrl = apiUrl + "v1/folder/";
		let {token} = getAuthData()
		if (campIds.length > 0) {
			for (let i = 0; i < campIds.length; i++) {
				let url = "";
				url = campUrl + campIds[i];
				axios({
					method: "DELETE",
					url: url,
					headers: {
						Authorization: token,
					},
				})
					.then((res) => {
						dispatch({ type: "CAMPAIGN_DELETE_SUCCESS", campId: campIds[i] });
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}
		if (folderIds.length > 0) {
			for (let j = 0; j < folderIds.length; j++) {
				let url = "";
				url = folderUrl + folderIds[j];
				axios({
					method: "DELETE",
					url: url,
					headers: {
						Authorization: token,
					},
				})
					.then((res) => {
						dispatch({
							type: "CAMPAIGN_FOLDER_DELETE_SUCCESS",
							folderId: folderIds[j],
						});
					})
					.catch((e) => console.log(e));
			}
		}
	},
	updateCampaignParent: (campId, parentId) => async (dispatch, getState) => {
		let a = campId;
		let b = parentId;
		let url = config.api.url + "v1/campaign/" + campId;
		let {token} = getAuthData()

		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				folder_id: parentId,
			},
		})
			.then((res) => {
				dispatch({
					type: "UPDATE_CAMPAIGN_PARENT_SUCCESS",
					frmId: campId,
				});
			})
			.catch((e) => console.log(e));
	},
	updateFolderParent: (folderId, parentId) => async (dispatch, getState) => {
		let a = parentId;
		let url = config.api.url + "v1/folder/" + folderId;
		let {token} = getAuthData()

		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				parent_id: parentId,
			},
		})
			.then((res) => {
				dispatch({
					type: "UPDATE_FOLDER_PARENT_SUCCESS",
					fldrId: folderId,
				});
			})
			.catch((e) => console.log(e));
	},
	copyCampaign: (campId, parentId) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "api/campaign/copyCampaign/" + campId;
		let {token} = getAuthData()


		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({
						type: "ADD_CAMPAIGN_SUCCESS",
						campaign: [res.data.data],
					});
				}
			})
			.catch((e) => {
				let aaaa = e;
			});

	},
	campaignExecuteNow:(id,type,typeId,cards)=>async (dispatch,getState)=>{
		let url = config.api.url + "api/campaign/executeNow/"+id+"/"+type+"/"+typeId;
		let {token} = getAuthData()
		dispatch({type:Constants.REQUEST_EXECUTE_CAMPAIGN_NOW,})
		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if(res.data.status === "success"){
					dispatch({ type: Constants.SUCCESS_EXECUTE_CAMPAIGN_NOW,apiResponse:apiResponse});
				}
				else{
					dispatch({ type: Constants.FAILED_EXECUTE_CAMPAIGN_NOW,apiResponse:apiResponse});
				}

			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type: Constants.FAILED_EXECUTE_CAMPAIGN_NOW,apiResponse:errorResponse})
			});
	},
	addCards: (cards) => async (dispatch, getState) => {
		dispatch({ type: "ADD_CARDS_SUCCESS", cards: cards });
	},
	addAction: (actions) => async (dispatch, getState) => {
		dispatch({ type: "ADD_ACTION_SUCCESS", campaignActions: actions });
	},
	addSendAction: (actions) => async (dispatch, getState) => {
		dispatch({ type: "ADD_SEND_ACTION_SUCCESS", campaignSendActions: actions });
	},
    changeStartPoint:(value)=>async (dispatch,getState)=>{
        dispatch({type:"SUCCESS_SET_START_POINT",startPoint:value})
    },
	getAllForms: () => async (dispatch, getState) => {
		let url = config.api.url + "api/form/getAll";
		let {token} = getAuthData()

		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({ type: "SUCCESS_GET_ALL_FORM", forms: res.data.items });
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	getItemsInFolder: (folderId, type) => async (dispatch, getState) => {
		dispatch({ type: "REQUEST_CAMPAIGN_IN_FOLDER" });
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/" + type + "?folder_id=" + folderId;
		let folderUrl = apiUrl + "v1/folder/" + type + "/" + folderId;
		let {token} = getAuthData()

		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({ type: "SUCCESS_GET_ALL_ITEMS", items: res.data.data.items });
			})
			.catch((e) => {
				let aaaa = e;
			});

		axios({
			method: "get",
			url: folderUrl,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({
					type: "SUCCESS_GET_ALL_ITEM_FOLDER",
					itemFolders: res.data.data.items,
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	resetItemsInFolder: () => async (dispatch, getState) => {
		dispatch({ type: "RESET_ITEMS_IN_FOLDER" });
	},
	getAllRelationField: () => async (dispatch, getState) => {
		let url = config.api.url + "v1/relation/field/all ";
		let {token} = getAuthData()

		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({
					type: "SUCCESS_GET_ALL_RELATIONAL_FIELD",
					fields: res.data.data.public,
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	getOtherAccounts: () => async (dispatch, getState) => {
		dispatch({ type: "REQUEST_GET_ACCOUNTS" });
		let url = config.api.url + "api/accounts/getOtherAccounts";
		let {token} = getAuthData()
		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({
						type: "SUCCESS_GET_ACCOUNTS",
						accounts: res.data.data,
					});
				} else {
				}
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	shareCampaign: (campaignId, ids,isShare,description,category,image,newData) => async (dispatch, getState) => {
		dispatch({type:Constants.REQUEST_GET_SHARE_CAMPAIGN})
		let url = config.api.url + "api/campaign/shareCampaign/" + campaignId;
		let {token} = getAuthData()

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				accounts: ids,
				template:{
					"share_as_template":isShare,
					"description":description,
					"category_id":category,
					"image":image,
					"data":newData
				}
			},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if (res.data.status === "success") {
					dispatch({
						type: Constants.SUCCESS_GET_SHARE_CAMPAIGN,
						apiResponse:apiResponse
					});
				}
				else {
					dispatch({
						type: Constants.FAILED_GET_SHARE_CAMPAIGN,
						apiResponse:apiResponse
					});
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_GET_SHARE_CAMPAIGN,apiResponse:errorResponse})
			});
	},
    getInUseItems:()=>async (dispatch,getState)=>{
	    dispatch({type: "REQUEST_IN_USE_ITEMS"})
        // let items = {
	    //     email:[427,403],
        //     letter:[],
        //     sms:[],
        //     page:[],
        //     group:[],
        //     filter:[],
        //     form:[150]
        // }

        let url = config.api.url + "api/campaign/getSelectedItemIdsOfCampaign";
		let {token} = getAuthData()

        axios({
            method: "get",
            url: url,
            headers: {
                Authorization: token,
            },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    dispatch({type:"SUCCESS_IN_USE_ITEMS",items:res.data.data})
                } else {
                }
            })
            .catch((e) => {
                dispatch({type:"FAILED_IN_USE_ITEMS"})
            });

    },
	getUserProfileDataForCampaign:()=>async (dispatch,getState) =>{
		dispatch({type:Constants.REQUEST_GET_USER_PROFILE_DATA_FOR_CAMPAIGN})

		let url = config.api.url + "api/profile";
		let {token} = getAuthData()
		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({type:Constants.SUCCESS_GET_USER_PROFILE_DATA_FOR_CAMPAIGN,profile:res.data.data})
				} else {
				}
			})
			.catch((e) => {
				dispatch({type:"FAILED_IN_USE_ITEMS"})
			});
	},
	getTemplateCategories:() =>async (dispatch,getState) =>{
		dispatch({type:Constants.REQUEST_CAMPAIGN_GET_TEMPLATE_CATEGORIES})

		let url = config.api.url + "api/template_categories/getTemplateCategories";
		let {token} = getAuthData()

		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({type:Constants.SUCCESS_CAMPAIGN_GET_TEMPLATE_CATEGORIES,categories:res.data.data})
				} else {
				}
			})
			.catch((e) => {
				dispatch({type:Constants.FAILED_CAMPAIGN_GET_TEMPLATE_CATEGORIES})
			});
	},
	getTemplate: () =>async (dispatch,getState) =>{
		dispatch({type:Constants.REQUEST_GET_CAMPAIGN_TEMPLATE})
		let {token} = getAuthData()
		let url = config.api.url + "api/campaign/getCampaignTemplates";


		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				if (res.data.status === "success") {
					dispatch({type:Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,templates:res.data.data})
				} else {
				}
			})
			.catch((e) => {
				dispatch({type:Constants.FAILED_GET_CAMPAIGN_TEMPLATE})
			});
	},
	// copyTemplate:(name,parentId,campaignData)=>async (dispatch,getState) =>{
	// 	let url = config.api.url + "v1/campaign";
	// 	let token = localStorage.getItem("user_token");
	//
	// 	axios({
	// 		method: "POST",
	// 		url: url,
	// 		headers: {
	// 			Authorization: token,
	// 		},
	// 		data: {
	// 			name: name,
	// 			parent_id: parentId,
	// 			data:campaignData
	// 		},
	// 	})
	// 		.then((res) => {
	// 			if (res.data.status === "success") {
	// 				dispatch({
	// 					type: "ADD_CAMPAIGN_SUCCESS",
	// 					campaign: [res.data.data],
	// 				});
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			let aaaa = e;
	// 		});
	// }

	deleteTemplate: (ids) => async (dispatch,getState) =>{
		dispatch({type:Constants.REQUEST_DELETE_CAMPAIGN_TEMPLATE})
		let {token} = getAuthData()
		let url = config.api.url + "api/campaign/deleteTemplate";
		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				ids: ids
			},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if (res.data.status === "success") {
					dispatch({
						type: Constants.SUCCESS_DELETE_CAMPAIGN_TEMPLATE,
						apiResponse:apiResponse,
						tempId:ids[0]
					});

				}
				else {
					dispatch({
						type: Constants.FAILED_DELETE_CAMPAIGN_TEMPLATE,
						apiResponse:apiResponse
					});
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_DELETE_CAMPAIGN_TEMPLATE,apiResponse:errorResponse})
			});

	},
	resetResponse:() =>async (dispatch,getState) =>{
		dispatch({
			type:Constants.RESET_CAMPAIGN_RESPONSE
		})
	},
	manuallySortCampaign:(currentItems,listFor,itemId,parentId, folderId, type="")=>(dispatch,getState)=>{
		debugger;
		dispatch({type: Constants.REQUEST_MANUAL_SORT_CAMPAIGN});
		const selectedItem = currentItems.find((i) => i.id === itemId);

        let sortedItems=[];
        for(let i = 0, j=0; i < currentItems.length; i++,j++){
            if(currentItems[i].id === parentId){
                sortedItems[j] = selectedItem;
                sortedItems[j+1] = currentItems[i];
            }else if(currentItems[i].id === itemId){

            }else{
                sortedItems.push(currentItems[i]);
            }
        }
		const sortedItemsIds = sortedItems.map(item=> item.id);
		debugger;

		let {token} = getAuthData()
		let url;
		if(type){
			url = config.api.url + `api/${listFor}/sortFolderManually/${folderId}`;
        }else{
            url = config.api.url + `api/${listFor}/sortManually/${folderId}`;
        }

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				data: sortedItemsIds
			},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if (res.data.status === "success") {
					if(type){
						dispatch({type: Constants.SUCCESS_MANUAL_SORT_CAMPAIGN_FOLDERS, folders: res.data.data.items});
					}else{
						dispatch({type: Constants.SUCCESS_MANUAL_SORT_CAMPAIGN, campaigns: res.data.data.items});
					}
				}
				else {
						dispatch({
							type: Constants.FAILED_MANUAL_SORT_CAMPAIGN,
							campaignApiResponse:apiResponse
						});
					
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_MANUAL_SORT_CAMPAIGN,campaignApiResponse:errorResponse})
			});
		
	},
	getAllTags:() =>(dispatch, getState)=>{
		dispatch({type: Constants.REQUEST_GET_ALL_TAGS});
		let {token} = getAuthData()
		let url = config.api.url + `api/tags/getTagList`;
		axios({
			method: "GET",
			url: url,
			headers:{
				Authorization: token,
			}
		}).then(res=>{
			if(res.data.status === "success"){
				dispatch({type: Constants.SUCCESS_GET_ALL_TAGS, tagList: res.data.data});
			}else{
				dispatch({type: Constants.FAILED_GET_ALL_TAGS});
			}
		}).catch((e)=>{
			dispatch({type: Constants.FAILED_GET_ALL_TAGS});
		})
	}

};
