import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IntlMessages from "util/IntlMessages";

import AccountInformation from "./AccountInformation";
import InputField from "./InputField";

function TabContainer({children, dir}) {
    return (
        <div dir={dir} style={{padding: 8 * 3}}>
            {children}
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired
};

class FullWidthTabs extends Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleChangeIndex = index => {
        this.setState({value: index});
    };

    render() {
        // console.log("value ->", this.props.value);
        const {theme} = this.props;
        // console.log("props full -> ", this.props);

        return (
            <div className="w-100">
              {this.props.match.params.menu === "dashboard_login" ?
                  <div>
                    <AppBar position="static" color="default">
                      <Tabs
                          value={this.state.value}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          scrollButtons="on"
                      >
                        {this.props.match.params.menu === "dashboard_login" ? (
                            <Tab className="tab" label={<IntlMessages id="_DETAILS"/>}/>
                        ) : (
                            // <Tab className="tab" label={""} />
                            null
                        )}

                        {this.props.match.params.menu === "dashboard_login" ? (
                            <Tab className="tab" label={<IntlMessages id="_CREATED_BY"/>}/>
                        ) : null}
                      </Tabs>
                    </AppBar>


                    <SwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer dir={theme.direction}>
                        {
                          <AccountInformation
                              history={this.props.history}
                              ref="AccountInfo"
                              disableBtn={this.props.disableBtn}
                              match={this.props.match}
                          />
                        }
                      </TabContainer>

                      <TabContainer dir={theme.direction}>
                        {
                          <InputField
                              authUser={this.props.authUser}
                              match={this.props.match}
                              type="password"
                          />
                        }
                      </TabContainer>
                    </SwipeableViews>
                  </div>
                  :
                  <div>
                    <AccountInformation
                        history={this.props.history}
                        ref="AccountInfo"
                        disableBtn={this.props.disableBtn}
                        match={this.props.match}
                    />
                  </div>

              }

            </div>
        );
    }
}

FullWidthTabs.propTypes = {
    theme: PropTypes.object.isRequired
};

export default withStyles(null, {withTheme: true})(FullWidthTabs);
