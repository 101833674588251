import {
  FETCH_TRASHVIEW,
  FETCH_TRASHVIEW_SUCCESS
} from "constants/ActionTypes";

export const fetchTrashView = params => {
  return {
    type: FETCH_TRASHVIEW,
    payload: params
  };
};

export const fetchTrashViewSuccess = data => {
  return {
    type: FETCH_TRASHVIEW_SUCCESS,
    payload: data
  };
};
