import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContainerHeader from "components/ContainerHeader/index";


//material ui
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Skeleton from "@material-ui/lab/Skeleton";
import Fab from '@material-ui/core/Fab';
import { withStyles } from "@material-ui/core/styles";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button } from "@material-ui/core";

//theme
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Notification from "./components/Notification";
import EmailStatistics from "./components/EmailStatistics";
import EmailPercentage from "./components/EmailPercentage";
import InfoCard from "./components/InfoCard";
import QuickStart from "./components/QuickStart";
import NewRelation from "./components/NewRelation";
import PackageDetail from "./components/PackageDetail";
import { NavLink } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { actionCreators } from "actions/Home";
import { getFullUserInfo } from "actions/Profile";
import { bindActionCreators } from "redux";

import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";
import { fetchHomeData } from "actions/Home";

//custom
import CustomDialog, { dialogStyles } from "../customComponents/dialog/customDialog";
import IntlMessages from "util/IntlMessages";
import { convertStringToCurrency } from "../../../common/functions/EproCommonFunctions";
import { SettingContext } from "context/SettingContext";
import moment from "moment";
import EclicksProTour from "../customComponents/UserTour/EclicksProTour";

import PaymentReportTable from "./components/PaymentReportTable";
import InvoiceTable from "../customComponents/table/invoiceTable/InvoiceTable";
import OrderInvoice from "./components/OrderInvoice";
import CustomButton from "../customComponents/button/CustomButton";

//others
import config from "../../../config";
import { getAuthData } from "../../../common/helpers/AuthHelper";
import EproDialog from "../customComponents/dialog/EproDialog";
import { Tabs, Tab } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import TabList from '@material-ui/lab/TabList';
import TabContext from '@material-ui/lab/TabContext';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { injectIntl } from "react-intl";
import { getResponseForNotificaion } from './../../../common/functions/EproCommonFunctions';
import axios from 'axios';


const useStyles = theme => ({
  reportFab: {
    backgroundColor: "#F5766F",
    color: "#ffffff"
  },
  indicator: {
    backgroundColor: 'green'
  },
  searchBoxPaper: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: "white",
    overflow: 'hidden',
    width: "300px",
    height: "33px",
    marginBottom: "2px",
    backgroundColor: "#C8E6C9",
    // marginTop: '10px',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIconButton: {
    padding: "7px 15px",
    backgroundColor: "rgb(67, 160, 71)",
    borderRadius: 0,
    margin: 0,
    color: 'white',
  },
  searchContainer: {
    marginBottom: '10px',
  }
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const statusColorObj = {
  "subscription": "green",
  "unsubscribe": "red",
  "credit purchase": "blue",
  "user creation": "orange",
}

class Home extends React.Component {
  static contextType = SettingContext;
  constructor(props) {
    super(props);
    this.state = {
      openPaymentHistoryDialog: false,
      paymentHistoryLoader: true,
      expanded: "",
      openOrderDialog: false,
      selectedCredit: null,
      selectedCreditPrice: 0,
      selectedCreditAmount: 0,
      paymentTab: 0,
      localPaymentList: [],
      localLastPayments: [],
      localAllTenantPayments: [],
      searchText: "",
    }
  }
  componentDidMount() {
    const { authUser } = this.props;
    //this.props.fetchHomeData(authUser);
    this.props.getHomeData()
    this.props.getMenuItems()
    this.props.getHomePermissions()
    this.props.getCredits()
    this.props.getFullUserInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data.paymentList !== this.props.data.paymentList) {
        this.setState({
            localPaymentList: this.props.paymentList
        })
    }

    if (this.props.data?.data?.new && (prevProps.data?.data?.new.lastPayment !== this.props.data?.data?.new.lastPayment)) {
      // Sorting and adding the payment list
        const temp = this.props.data.data.new.lastPayment;
        // const result = this.sortObjectByPayDate(temp);
        this.setState({
            localLastPayments: temp
        })
    }

    if (this.props.data?.data?.new && (prevProps.data?.data?.new.allTenantPaymentList !== this.props.data?.data?.new.allTenantPaymentList)) {
      // Sorting and adding the payment list

      const temp = this.props.data.data.new.allTenantPaymentList;

        this.setState({
            localAllTenantPayments: temp
        })
    }
}

  showPaymentHistory = () => {
    this.setState({
      openPaymentHistoryDialog: true
    })
    setTimeout(function () {
      this.setState({
        paymentHistoryLoader: false
      })
    }.bind(this), 2000);
  }
  handleChangeAccordian = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({
        expanded: panel
      })
    } else {
      this.setState({
        expanded: ""
      })
    }

  }
  handleClickOpenOrderDialog = () => {
    this.props.getSelectedCredit(this.state.selectedCredit.id)
    this.setState({
      openOrderDialog: true
    })
  }
  handleClose = () => {
    this.setState({
      openPaymentHistoryDialog: false,
      openOrderDialog: false
    })
  }
  handleDeliveryStatus = (paymentId, itemId) => {
    this.props.updateOrderStatus(paymentId, itemId)
  }
  handleChangeCredit = (value) => {
    let credits = this.props.data.credits
    let credit = credits.find(c => c.credit_amount === value.toString())
    if (credit) {
      this.setState({
        selectedCredit: credit,
        selectedCreditPrice: credit.price,
        selectedCreditAmount: credit.credit_amount

      })
    } else {
      this.setState({
        selectedCredit: null,
        selectedCreditPrice: 0,
        selectedCreditAmount: 0

      })
    }
  }
  handleConfirmOrder = () => {
    let credit = this.state.selectedCredit
    if (credit) {
      let url = `${config.api.url}show/credit/${getAuthData().user.uid}/${credit.id}`
      //window.location.replace(url)
      window.location.assign(url)
    }

  }

  handlePaymentTabChange = (event, newValue) => {
    this.setState({ paymentTab: newValue })
  };

  handleSearchTextOnChange = (event) => {
    this.setState({searchText: event.target.value})
  }

  handleTenantPaymentSearch = () => {
        let {token} = getAuthData()
        let url = config.api.url+ "api/payment_history?search_value=" + this.state.searchText;
        
        axios({
            method: "GET",
            url: url,
            headers: {
            Authorization: token,
            },
        })
        .then((res) => {
            if (res.status === 200) {
              this.setState({
                localLastPayments: res.data.lastPayment,
                localAllTenantPayments: res.data.allTenantPaymentList,
              })
            } else {
              
            }
        })
        .catch((e) => {
    });
  }

//   sortObjectByPayDate = (obj) => {
//     let sorted = {}
//     const keys = Object.keys(obj);
//     const values = Object.values(obj);
// debugger;
//     let tempArr = [];
//     for (let i = 0; i < values.length; i++) {
//       const tempValue = {...values[i], tempKey: keys[i]}
//       tempArr.push(tempValue);
//     }

//     tempArr.sort(function(a, b) {
//       return parseInt(b.paid_at - a.paid_at);
//     })

//     let tempObj = {}
//     tempArr.map((item) => {
//       const key = item.tempKey;
//       delete item.tempKey;
//       tempObj[key] = item;
//     })
// debugger;
//     return tempObj;
//   }

  render() {
    const { match, classes } = this.props;
    const { data, loader, dialogLoader, authorizedItems = [], homePermissions, paymentList, credits, totalCredit, selectedCredit } = this.props.data;
    let totalPayments = 0;
    let showItems = []
    let settings = []
    let vat = ""
    const search_placeholder = this.props.intl.formatMessage({ id: "_SEARCH_TEXT" });
    /*
      Status options
      "0": None, "1": View, "2": Edit
    */
    let showPackageUpdate, showSendCredit, enableBuyNowBtn, enableSelectAmount = false
    let selectAmountStatus, buyNowBtnStatus = "";

    if (homePermissions !== null && homePermissions !== undefined) {
      if (parseInt(homePermissions.package_upgrade_button) > 0) {
        showPackageUpdate = true
      } else {
        showPackageUpdate = false
      }

      if (parseInt(homePermissions.send_credit) > 0) {
        showSendCredit = true
      } else {
        showSendCredit = false
      }

      // if (parseInt(homePermissions.buy_now_button) > 0) {
      //   enableBuyNowBtn = true
      // } else {
      //   enableBuyNowBtn = false
      // }

      // if (parseInt(homePermissions.credit_amount) > 0) {
      //   enableSelectAmount = true
      // } else {
      //   enableSelectAmount = false
      // }
      if (homePermissions?.credit_amount) {
        selectAmountStatus = homePermissions.credit_amount
      }
      if (homePermissions?.buy_now_button) {
        buyNowBtnStatus = homePermissions.buy_now_button
      }
    }




    let defaultMoneyFormat = "EUR";
    if (this.context.settings.status !== "error") {
      settings = JSON.parse(this.context.settings.data);
      defaultMoneyFormat = settings["#DEFAULT_MONEY_FORMAT"]
      vat = settings["#ECLICKSPRO_CREDIT_DEFAULT_VAT"]
    }
    let totalSalesPerMonth = 0
    let beeUid = "A-S00040523"
    if (data !== null) {
      if (data.new) {
        totalSalesPerMonth = data.new.totalSalesPerMonth

        if (data.new.beeUid !== null && data.new.beeUid !== "") {
          beeUid = data.new.beeUid
        }
      }
      localStorage.setItem("beeUid", beeUid)



    }

    if (paymentList.length > 0) {
      // //count
      for (let i = 0; i < paymentList.length; i++) {
        let t = parseInt(paymentList[i].itemRows.length)
        totalPayments = totalPayments + t
      }
    }

    // console.log("data home-> ", data);
    if (data && data.status === "error") {
      localStorage.clear();
      window.location.reload();
    }
    let skWidth = 650
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          {(loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )) || (
              <div>
                <EclicksProTour
                  menu={"home"}
                  view={"list"}
                />
                <ContainerHeader
                  //title={"Welcome " + data.new.userName}
                  name={data.new.userName}
                  match={match}
                  data={data.new?.currentPackage}
                />
                <div className="row">
                  <div className="col-md-3 col-12">
                    <Notification
                      relations={data.new.youHave.relations}
                      campaignRunning={data.new.youHave.campaigns}
                      emailSent={data.new.youHave.sent}
                      emailPending={data.new.youHave.pending}
                      appointments={data.new.youHave.appointments}
                      // payments={data.new.youHave.payments}
                      payments={totalPayments}
                      openPayments={this.showPaymentHistory}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <EmailStatistics emailStat={data.new.grpahData} />
                  </div>
                  <div className="col-md-3 col-12">
                    <EmailPercentage data={data.new.emailPercentage} />
                  </div>
                </div>

                {/*p-1*/}
                <div className="row">
                  {authorizedItems && authorizedItems.find(a => a.name === "campaign") !== undefined ?
                    <div className="col-2">
                      <NavLink
                        to="/app/custom-views/campaign"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="campaign"
                          color="#575757"
                          value={data.new.eachTableDataCount.campaign}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2">
                      <InfoCard
                        type="campaign"
                        color="#575757"
                        value={data.new.eachTableDataCount.campaign}
                      />
                    </div>
                  }



                  {/*p-2*/}
                  {authorizedItems && authorizedItems.find(a => a.name === "email") !== undefined ?
                    <div className="col-2 email-card">
                      <NavLink
                        to="/app/custom-views/email"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="email"
                          color="#4caf50"
                          value={data.new.eachTableDataCount.email}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2 email-card">
                      <InfoCard
                        type="email"
                        color="#4caf50"
                        value={data.new.eachTableDataCount.email}
                      />
                    </div>
                  }

                  {/*p-3*/}
                  {authorizedItems && authorizedItems.find(a => a.name === "form") !== undefined ?
                    <div className="col-2">
                      <NavLink
                        to="/app/custom-views/form"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="form"
                          color="#ffc107"
                          value={data.new.eachTableDataCount.form}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2">
                      <InfoCard
                        type="form"
                        color="#ffc107"
                        value={data.new.eachTableDataCount.form}
                      />
                    </div>
                  }


                  {/*p-4*/}
                  {authorizedItems && authorizedItems.find(a => a.name === "page") !== undefined ?
                    <div className="col-2">
                      <NavLink
                        to="/app/custom-views/page"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="page"
                          color="#f44336"
                          value={data.new.eachTableDataCount.page}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2">
                      <InfoCard
                        type="page"
                        color="#f44336"
                        value={data.new.eachTableDataCount.page}
                      />
                    </div>
                  }


                  {/*p-5*/}
                  {authorizedItems && authorizedItems.find(a => a.name === "sms") !== undefined ?
                    <div className="col-2">
                      <NavLink
                        to="/app/custom-views/sms"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="sms"
                          color="#00bcd4"
                          value={data.new.eachTableDataCount.sms}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2">
                      <InfoCard
                        type="sms"
                        color="#00bcd4"
                        value={data.new.eachTableDataCount.sms}
                      />
                    </div>
                  }

                  {/*p-6*/}
                  {authorizedItems && authorizedItems.find(a => a.name === "Letter") !== undefined ?
                    <div className="col-2">
                      <NavLink
                        to="/app/custom-views/Letter"
                        style={{ textDecoration: "none", color: "#575757" }}
                      >
                        <InfoCard
                          type="letter"
                          color="#2196f3"
                          value={data.new.eachTableDataCount.letter}
                        />
                      </NavLink>
                    </div>
                    :
                    <div className="col-2">
                      <InfoCard
                        type="letter"
                        color="#2196f3"
                        value={data.new.eachTableDataCount.letter}
                      />
                    </div>
                  }


                </div>

                <div className="row">
                  {data.new.newUserStatus === 1 ? (
                    <div className="col-4">
                      <QuickStart />
                    </div>
                  ) : (
                    <div className="col-6">
                      <PackageDetail
                        data={data.new.userPart.credits}
                        currentPackage={data.new.currentPackage}
                        credits={credits}
                        totalCredit={totalCredit}
                        relations={data.new.userPart.relations}
                        totalRelations={data.new.youHave.relations}
                        totalSales={totalSalesPerMonth}
                        defaultMoneyFormat={defaultMoneyFormat}
                        showPackageUpdate={showPackageUpdate}
                        showSendCredit={showSendCredit}
                        // enableBuyNowBtn={enableBuyNowBtn}
                        buyNowBtnStatus={buyNowBtnStatus}
                        // enableSelectAmount={enableSelectAmount}
                        selectAmountStatus={selectAmountStatus}
                        handleChangeCredit={this.handleChangeCredit}
                        selectedCredit={this.state.selectedCredit}
                        selectedCreditPrice={this.state.selectedCreditPrice}
                        selectedCreditAmount={this.state.selectedCreditAmount}
                        handleClickOrder={this.handleClickOpenOrderDialog}
                      />
                    </div>
                  )}
                  <div className="col-6">
                    <NewRelation data={data.new.newRelations} />
                  </div>
                </div>
              </div>
            )}
        </div>

        {/*payment history dialog*/}
        <CustomDialog
          open={this.state.openPaymentHistoryDialog}
          handleClose={this.handleClose}
          dialogContent={false}
          titleTextKey={"_ECLICKSPRO_PAYMENT_HISTORY"}
          maxWidth={"lg"}
          actionButtons={['close']}
        >
          <DialogContent style={dialogStyles.dialogContentStyles}>
            {this.state.paymentHistoryLoader ?
              <div>
                <Skeleton variant="text" width={1230} height={120} />
                <Skeleton variant="text" width={1230} height={50} />
                <Skeleton variant="text" width={1230} height={120} />
                <Skeleton variant="text" width={1230} height={50} />
                <Skeleton variant="text" width={1230} height={120} />

              </div>
              :
              <div>
                <div className="w-100">
                  <TabContext value={this.state.paymentTab}>
                    <div style={{ borderBottom: '1px solid green', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <TabList classes={{ indicator: classes.indicator }} onChange={this.handlePaymentTabChange} aria-label="basic tabs example">
                        <Tab label={<IntlMessages id="_ECLICKSPRO_HOME_CAMPAING_PAYMENT" />} value={0} />
                        {
                          this.props.profile?.info?.data?.role === "app_owner" ? (
                            <Tab label={<IntlMessages id="_ECLICKSPRO_HOME_TENANT_PAYMENT" />} value={1} />
                          ) : <></>
                        }

                      </TabList>

                      {/* <Grid item xs={12} style={{marginLeft: 'auto', width: 300}}> */}
                      {
                        this.state.paymentTab === 1 && (
                          <Paper component="form" className={classes.searchBoxPaper}>
                        <InputBase
                          className={classes.searchInput}
                          placeholder={search_placeholder}
                          inputProps={{ 'aria-label': 'Search Field' }}
                          onChange={this.handleSearchTextOnChange}
                        // defaultValue={this.state.searchValue}
                        // inputRef={el => this.inputFieldRef = el}
                        // onKeyDown={(e) => {
                        //   if (e.keyCode === 13) {
                        //     e.preventDefault();
                        //     this.onSearchClick(e)
                        //   }
                        // }}
                        />
                        <IconButton
                          className={classes.searchIconButton}
                          aria-label="search"
                          onClick={this.handleTenantPaymentSearch}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                        )
                      }
                      
                      {/* </Grid> */}

                    </div>
                    <TabPanel value={0}>
                      {(paymentList && paymentList.length > 0) ? paymentList?.map((payment, index) => {
                        return (
                          <ExpansionPanel expanded={this.state.expanded === payment.id} elevation={0} id={payment.id} onChange={this.handleChangeAccordian(payment.id)} style={{ marginTop: "20px" }}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id={payment.id}
                              style={{ backgroundColor: "#F9F9F9" }}
                              elevation={0}
                            >
                              <Grid container>
                                <Grid item xs={1}>
                                  <Fab className={classes.reportFab} aria-label="add" size={"small"}>
                                    {payment.itemRows.length}
                                  </Fab>
                                </Grid>
                                <Grid item xs={9}>
                                  <div style={{ margin: "8px 0px 0px -40px" }} >
                                    <Typography>{payment.name}</Typography>
                                  </div>
                                </Grid>
                                <Grid container item justify={"flex-end"} xs={2}>
                                  <div style={{ margin: "6px 20px 0px 0px" }}>
                                    {convertStringToCurrency(payment.totalPrice, defaultMoneyFormat, ".").formattedPrice.length < 11 ?
                                      <Chip label={convertStringToCurrency(payment.totalPrice, defaultMoneyFormat, ".").formattedPrice} style={{ width: "100px" }} />
                                      :
                                      <Chip label={convertStringToCurrency(payment.totalPrice, defaultMoneyFormat, ".").formattedPrice} />
                                    }

                                  </div>
                                </Grid>
                              </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <PaymentReportTable
                                paymentId={payment.id}
                                rows={payment.itemRows}
                                defaultMoneyFormat={defaultMoneyFormat}
                              // handleDeliveryStatus={this.handleDeliveryStatus}
                              />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        )
                      }) : <div>
                        <IntlMessages id="ECLICKSPRO_DATA_NOT_FOUND" />
                      </div>}
                    </TabPanel>
                    {
                      this.props.profile?.info?.data?.role === "app_owner" && (
                        <TabPanel value={1}>
                          {(this.state.localLastPayments && Object.keys(this.state.localLastPayments).length > 0) ? Object.keys(this.state.localLastPayments)?.map((paymentKey, index) => {
                            const payment = this.state.localLastPayments[paymentKey];
                            return (
                              <ExpansionPanel expanded={this.state.expanded === payment.id} elevation={0} id={payment.id} onChange={this.handleChangeAccordian(payment.id)} style={{ marginTop: "20px" }}>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id={payment.id}
                                  style={{ backgroundColor: "#F9F9F9" }}
                                  elevation={0}
                                >
                                  <Grid container>
                                    <Grid item xs={1}>
                                      <Fab className={classes.reportFab} aria-label="add" size={"small"}>
                                        {this.state.localAllTenantPayments[paymentKey] && this.state.localAllTenantPayments[paymentKey].length}
                                      </Fab>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div style={{ margin: "8px 0px 0px -40px" }} >
                                        <Typography style={{ width: 'calc(100% - 50px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{payment.name}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <div style={{ margin: "8px 0px 0px -40px" }} >
                                        <Typography style={{ width: 'calc(100% - 50px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{payment.email}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div style={{ margin: "8px 0px 0px -40px" }} >
                                        <Typography>{moment.unix(payment.created_at).format("DD/MM/YYYY")}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <div style={{ color: statusColorObj[payment?.status?.toLowerCase()], fontWeight: "bold" }}>
                                        <Typography>{payment.status}</Typography>
                                      </div>
                                    </Grid>
                                    <Grid container item justify={"flex-end"} xs={2}>
                                      <div style={{ margin: "6px 20px 0px 0px" }}>
                                        {convertStringToCurrency(payment.total, defaultMoneyFormat, ".").formattedPrice.length < 11 ?
                                          <Chip label={convertStringToCurrency(payment.total, defaultMoneyFormat, ".").formattedPrice} style={{ width: "100px" }} />
                                          :
                                          <Chip label={convertStringToCurrency(payment.total, defaultMoneyFormat, ".").formattedPrice} />
                                        }

                                      </div>
                                    </Grid>
                                  </Grid>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <PaymentReportTable
                                    paymentId={payment.id}
                                    rows={this.state.localAllTenantPayments[paymentKey]}
                                    defaultMoneyFormat={defaultMoneyFormat}
                                  // handleDeliveryStatus={this.handleDeliveryStatus}
                                  />
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            )
                          }) : <div>
                            <IntlMessages id="ECLICKSPRO_DATA_NOT_FOUND" />
                          </div>}
                        </TabPanel>
                      )
                    }

                  </TabContext>



                </div>

              </div>
            }
          </DialogContent>
        </CustomDialog>




        {/*Order dialog*/}
        {/*<CustomDialog*/}
        {/*  open={this.state.openOrderDialog}*/}
        {/*  handleClose={this.handleClose}*/}
        {/*  dialogContent={false}*/}
        {/*  titleTextKey={"_ECLICKSPRO_ORDER"}*/}
        {/*  maxWidth={"md"}*/}
        {/*  dialogActions={false}*/}
        {/*//actionButtons={['close']}*/}
        {/*>*/}
        {/*  <DialogContent style={dialogStyles.dialogContentStyles}>*/}
        {/*    {false ?*/}
        {/*      <div>*/}
        {/*        <Skeleton variant="text" width={1230} height={120} />*/}
        {/*        <Skeleton variant="text" width={1230} height={50} />*/}
        {/*        <Skeleton variant="text" width={1230} height={120} />*/}
        {/*        <Skeleton variant="text" width={1230} height={50} />*/}
        {/*        <Skeleton variant="text" width={1230} height={120} />*/}

        {/*      </div>*/}
        {/*      :*/}
        {/*      <div>*/}
        {/*        <OrderInvoice*/}
        {/*          item={this.state.selectedCredit}*/}
        {/*          vat={vat}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    }*/}
        {/*  </DialogContent>*/}
        {/*  <DialogActions style={dialogStyles.dialogActionStylesForInd}>*/}
        {/*    <CustomButton onClick={this.handleConfirmOrder} color={"green"}><IntlMessages id={"_ECLICKSPRO_CONFIRM"} /></CustomButton>*/}
        {/*    <CustomButton onClick={this.handleClose} color={"grey"}><IntlMessages id={"_CLOSE"} /></CustomButton>*/}
        {/*  </DialogActions>*/}
        {/*</CustomDialog>*/}


        <EproDialog
          open={this.state.openOrderDialog}
          handleClose={this.handleClose}
          handleOrder={this.handleConfirmOrder}
          title={"_ECLICKSPRO_ORDER"}
          onlyText={false}
          maxWidth={'md'}
          actions={['order', 'close']}
        >
          <div>
            {dialogLoader ?
              <div>
                <Skeleton variant="text" width={skWidth} height={50} />
                <Skeleton variant="text" width={skWidth} height={10} />
                <Skeleton variant="text" width={skWidth} height={50} />
                <Skeleton variant="text" width={skWidth} height={10} />
                <Skeleton variant="text" width={skWidth} height={50} />
              </div>
              :
              <div>
                {selectedCredit ?
                  <div>
                    <OrderInvoice
                      item={selectedCredit}
                    />
                  </div>
                  :
                  <div>
                    <p>no credit selected</p>
                  </div>
                }
              </div>

            }
          </div>
        </EproDialog>
      </div>
    );
  }
}

// const mapStateToProps = ({ auth, home }) => {
//   // console.log("auth->", auth);
//   // console.log("home->", home);
//   const { alertMessage, showMessage, authUser, status } = auth;
//   const { loader, token, data } = home;
//   return { loader, alertMessage, showMessage, authUser, status, token, data };
// };
//
// export default connect(mapStateToProps, {
//   userSignIn,
//   hideMessage,
//   showAuthLoader,
//   fetchHomeData,
// })(Home);

const mapStateToProps = (state) => ({
  data: state.home,
  profile: state.profile,
})

export default connect(
  state => mapStateToProps,
  dispatch => bindActionCreators({ ...actionCreators, getFullUserInfo }, dispatch)
)(withStyles(useStyles)(injectIntl(Home)))