import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AdjustIcon from '@material-ui/icons/Adjust';
import IntlMessages from "../../../../util/IntlMessages";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import TextHoverToolTip from "../../customComponents/toolTip/TextHoverToolTip";
import { convertStringToCurrency } from "../../../../common/functions/EproCommonFunctions";
import DetailToolTip from "../../customComponents/toolTip/detailToolTip";
import Radio from '@material-ui/core/Radio';
import { useState, useEffect } from "react";
const statusColorObj = {
    "subscription": "green",
    "unsubscribe": "red",
    "credit purchase": "blue",
    "user creation": "orange",
}


const useStyles = makeStyles({
    table: {
        minWidth: "900px",
    },
    tableContainer: {
        margin: "0px 20px 0px 20px"
    }
});

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


const PaymentReportTable = (props) => {
    const classes = useStyles();
    const { rows = [], defaultMoneyFormat = "EUR" } = props
    const [currentRows, setCurrentRows] = useState([]);

    useEffect(() => {
        if(rows.length > 0){
            const tempRows = rows;
            tempRows.sort((a, b) => {
                return parseInt(b.paid_at) - parseInt(a.paid_at);
            })
            setCurrentRows(tempRows);
        }else{
            setCurrentRows(props.rows);
        }
    }, [props.rows])

    const handleDeliveryStatus = (event) => {
        debugger
        let paymentId = event.target.id.split("-")[1]
        let itemId = event.target.id.split("-")[2]
        props.handleDeliveryStatus(paymentId, itemId)
    }

    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">{<IntlMessages id="_NAME" />}</TableCell>
                        <TableCell>{<IntlMessages id="_EMAIL" />}</TableCell>
                        {/* <TableCell align="left">{<IntlMessages id="_ADDRESS"/>}</TableCell> */}
                        <TableCell align="left">{<IntlMessages id="_DATE" />}</TableCell>
                        {/* <TableCell align="left">{<IntlMessages id="_QTY"/>}</TableCell> */}
                        <TableCell align="left">{<IntlMessages id="_ECLICKSPRO_AMOUNT" />}</TableCell>
                        <TableCell align="left">{<IntlMessages id="_ECLICKSPRO_STATUS" />}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentRows.map((row) => (
                        <TableRow key={row.name}>
                            {/* <TableCell align="left">
                                    <Radio
                                        checked={row.delivered === "1"}
                                        color={"primary"}
                                        onClick={handleDeliveryStatus}
                                        id={`payment-${props.paymentId}-${row.id}`}

                                    />
                                </TableCell> */}
                            <TableCell scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell scope="row">
                                {row.email}
                            </TableCell>
                            {/* <TableCell align="left">
                                    {(row.address1 !== undefined && row.address1 !== null) && `${row.address1}, `}
                                    {(row.address2 !== undefined && row.address2 !== null) && `${row.address2}, `}
                                    {(row.zip_code !== undefined && row.zip_code !== null)&& `${row.zip_code}, `}
                                    {(row.city !== undefined && row.city !== null) && `${row.city} `}
                                    {(row.country !== undefined && row.country !== null)&& `, ${row.country} `}
                                </TableCell> */}
                            <TableCell align="left">
                                {
                                    row?.paid_at 
                                    ? moment.unix(row.paid_at).format("DD/MM/YYYY")
                                    : "----"
                                }
                                
                            </TableCell>
                            {/* <TableCell align="left">
                                    {row.qty}
                                </TableCell> */}
                            <TableCell align="left">
                                {convertStringToCurrency(row.price, defaultMoneyFormat, ".").formattedPrice}
                            </TableCell>
                            <TableCell align="left" style={{ color: statusColorObj[row?.status?.toLowerCase()], fontWeight: "bold" }}>
                                {row.status}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export default PaymentReportTable