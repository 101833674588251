import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_LETTER_RELATION } from "constants/ActionTypes";
import { fetchLetterRelationSuccess} from "actions/LetterRelation";

import config from "../config";

// letter
const fetchLetterRelationData = async params => {
  const { token } = params;

  const response = await fetch(`${config.api.url}v1/get/relations?limit=5&offset=0&zip_code=2&address=2&address_number=2`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchLetterRelationData({ payload }) {
  try {
    const getLetterRelationData = yield call(fetchLetterRelationData, payload);
    yield put(fetchLetterRelationSuccess(getLetterRelationData));
  } catch (e) {
    console.error(e);
  }
}

export function* getLetterRelationData() {
  yield takeEvery(FETCH_LETTER_RELATION, getFetchLetterRelationData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getLetterRelationData)]);
}
