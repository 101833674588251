import { FETCH_EDITVIEW, FETCH_EDITVIEW_SUCCESS } from "constants/ActionTypes";

export const fetchEditView = params => {
    return {
        type: FETCH_EDITVIEW,
        payload: params
    };
};

export const fetchEditViewSuccess = data => {
    return {
        type: FETCH_EDITVIEW_SUCCESS,
        payload: data
    };
};