import React, { Fragment } from "react";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import { compose } from "redux";
import Select from "react-select";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { userSignIn } from "actions/Auth";
import { fetchEditView } from "actions/EditView";
import {
	resetAll,
	requestEnableButton,
	requestDisableButton
} from "../../../../actions/AccountInformation";
import moment from "moment";
import { DatePicker } from "material-ui-pickers";
import { injectIntl } from "react-intl";
import md5 from "md5";

import {
	Checkbox,
	Button,
	CircularProgress,
	TextareaAutosize,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import CustomFieldDialog from "./CustomFieldDialog";
import RelationDialogBox from "./RelationDialogBox";
import EclicksProTour from "../../customComponents/UserTour/EclicksProTour";
import { getAuthData } from "common/helpers/AuthHelper";
import config from '../../../../config.js';
import axios from "axios";

const styles = {
	input1: {
		height: 1,
	},
};
const AlertBox = ({ flag, closeAlertBox, handleDelete }) => {
	const handleClose = () => {
		closeAlertBox();
	};

	return (
		<div>
			<Dialog
				open={flag}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<IntlMessages id="_ARE_YOU_SURE_TO_DELETE" />
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<IntlMessages id="_DELETE_CONFIRMATION_MESSAGE" />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						<IntlMessages id="_CANCEL" />
					</Button>
					<Button onClick={handleDelete} color="primary" autoFocus>
						<IntlMessages id="_CONFIRM_DELETE" />
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

class AccountInformationApp extends React.Component {
	state = {
		permissionFields: [],
		selectedDate: null,
		disableBtn: true,
		flag: false,
		file: {
			logo: null,
			favicon: null,
			email_logo: null,
			background_image: null,
		},
		permission: [],
		notify_option_1: false,
		notify_option_2: false,
		password: "",
		notiifyPass2: "",
		userInfo: null,
		unq: true,
		customRelationField: [],
		allTags: [],
		tagList:[],
		openCustomField: false
	};

	componentDidMount() {
		this.getAllTags();
	}

	componentWillMount() {
		const { authUser, match } = this.props;
		const params = {
			token: authUser,
			tableName: match.params.menu,
			id: match.params.id,
		};
		this.props.fetchEditView(params);

		if (match.params.menu === "relation") {
			fetch(`${config.api.url}v1/relation/field`, {
				method: "GET",
				headers: {
					Authorization: authUser,
				},
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.status === "success") {
						this.setState({ customRelationField: response.data.items });
					}
				});
		}
	}
	componentDidUpdate(prevProps, prevState) {
			if (prevProps.item!== this.props.item && this.props.item !== null) {
				this.setState({ permissionFields: this.props.item.field_permissions, tagList: this.props.item.tagList?.map(item=>({value: item.tags_id, label: item.tag})) });

				let notifyOption = this.props.item.data.find(a =>a.field_name === "notify_option")
				if(notifyOption !== undefined){
					let option = notifyOption.value
					if(option === "1" || option === "2"){
						this.setState({
							notify_option_1:true
						})
					}
					if(option === "3" ||option === "2"){
						this.setState({
							notify_option_2:true
						})
					}
				}


				let lookups = this.props.item.data.filter(a =>a.field_type === "lookup")
				if(lookups.length > 0){
					for(let l = 0;l < lookups.length;l++){
						this.setState({
							[lookups[l].field_name]:lookups[l].value
						})
					}
				}

				let textarea = this.props.item.data.filter(a =>a.field_type === "textarea")
				if(textarea.length > 0){
					for(let l = 0;l < textarea.length;l++){
						this.setState({
							[textarea[l].field_name]:textarea[l].value
						})
					}
				}

				let email = this.props.item.data.filter(a =>a.field_type === "email")
				if(email.length > 0){
					for(let l = 0;l < email.length;l++){
						this.setState({
							[email[l].field_name]:email[l].value
						})
					}
				}

				let file = {
					logo: this.state.file.logo,
					favicon: this.state.file.favicon,
					email_logo: this.state.file.email_logo,
					background_image: this.state.file.background_image,
				}
				let logo = this.props.item.data.find(a =>a.field_name === "logo")
				if(logo !== undefined){
					file.logo = logo.value
				}

				let favicon = this.props.item.data.find(a =>a.field_name === "favicon")
				if(favicon !== undefined){
					file.favicon = favicon.value
				}

				let emailLogo = this.props.item.data.find(a =>a.field_name === "email_logo")
				if(emailLogo !== undefined){
					file.email_logo = emailLogo.value
				}

				let backgroundImage = this.props.item.data.find(a =>a.field_name === "background_image")
				if(backgroundImage !== undefined){
					file.background_image = backgroundImage.value
				}
				this.setState({
					file:file
				})


			}




	}

	fetchValue = () => {
		const { authUser, match } = this.props;
		const params = {
			token: authUser,
			tableName: match.params.menu,
			id: match.params.id,
		};
		this.props.fetchEditView(params);
	};

	handleChange = (name) => (event) => {
		debugger;
		this.setState({
			[name]: event.target.value,
		});
		setTimeout(this.validate,5000)
	};

	closeAlertBox = () => {
		this.setState({ flag: false });
	};

	openAlertBox = () => {
		this.setState({ flag: true });
	};

	fetchUserInfo = async () => {
		const { authUser, match } = this.props;
		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/ListingEdit/${match.params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			this.setState({ userInfo: response });
		} else {
			this.setState({ userInfo: null });
		}
	};

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.acInfoBtnclickSave &&
			nextProps.acInfoBtnclickSave !== this.props.acInfoBtnclickSave
		) {
			this.handleSaveButton();
			this.props.resetAll();
		}
		if (
			nextProps.acInfoBtnclickSaveAndNext &&
			nextProps.acInfoBtnclickSaveAndNext !== this.props.acInfoBtnclickSaveAndNext
		) {
			this.handleSaveAndNext();
			this.props.resetAll();
		}
		if (
			nextProps.acInfoBtnclickSaveAndClose &&
			nextProps.acInfoBtnclickSaveAndClose !==
				this.props.acInfoBtnclickSaveAndClose
		) {
			this.handleSaveAndClose();
			this.props.resetAll();
		}
		if (
			nextProps.acInfoBtnClickAdd &&
			nextProps.acInfoBtnClickAdd !== this.props.acInfoBtnClickAdd
		) {
			this.handleAdd();
			this.props.resetAll();
		}
		if (
			nextProps.acInfoBtnClickDelete &&
			nextProps.acInfoBtnClickDelete !== this.props.acInfoBtnClickDelete
		) {
			this.openAlertBox();
			this.props.resetAll();
		}
	}



	handleSaveButton = async () => {
		this.setState({ disableBtn: true });
		const { authUser, match } = this.props;
		let newBody = {};

		for (let key in this.state) {
			if (
				!(
					key === "selectedDate" ||
					key === "validationStatus" ||
					key === "disableBtn" ||
					key === "permissionFields"
				)
			) {
				if (key === "password") {
					newBody[key] = md5(this.state[key]);
				} else if (key === "customFields") {
					newBody[key] = JSON.stringify(this.state[key]).replace(/"/g, '"');
				} else if(key === "tagList"){
					newBody["tags"] = this.state[key]?.map(item=>item.value);
				} else {
					newBody[key] = this.state[key];
				}
			}
		}
		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/savePostValue/${match.params.id}`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			NotificationManager.info(response.msg);
		}else{
			NotificationManager.error(response.msg);
		}
	};

	handleSaveAndNext = async () => {
		this.setState({ disableBtn: true });
		const { authUser, match, history, item } = this.props;
		let newBody = {};
		for (let key in this.state) {
			if (
				!(
					key === "selectedDate" ||
					key === "validationStatus" ||
					key === "disableBtn" ||
					key === "permissionFields"
				)
			) {
				if (key === "password") {
					//newBody[key] = md5(this.state[key]);
					newBody[key] = this.state[key];
				} else if (key === "customFields") {
					newBody[key] = JSON.stringify(this.state[key]).replace(/"/g, '"');
				} else {
					newBody[key] = this.state[key];
				}
			}
		}

		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/savePostValue/${match.params.id}`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.catch((err) => console.error(err));

		if (response.status === "success") {
			NotificationManager.info(response.msg);
			history.push(
				`/app/listing/${match.params.menu}/edit/${item.position_data.next_record_id}`
			);
			window.location.reload();
		}else{
			NotificationManager.error(response.msg);
		}
	};

	handleSaveAndClose = async () => {
		this.setState({ disableBtn: true });
		const { authUser, match, history } = this.props;
		let newBody = {};
		for (let key in this.state) {
			if (
				!(
					key === "selectedDate" ||
					key === "validationStatus" ||
					key === "disableBtn" ||
					key === "permissionFields"
				)
			) {
				if (key === "password") {
					newBody[key] = this.state[key];
				} else if (key === "customFields") {
					newBody[key] = JSON.stringify(this.state[key]).replace(/"/g, '"');
				} else {
					newBody[key] = this.state[key];
				}
			}
		}

		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/savePostValue/${match.params.id}`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			NotificationManager.info(response.msg);
			return history.goBack();
		}else{
			NotificationManager.error(response.msg);
		}
	};

	handleDelete = async () => {
		const { authUser, match, history } = this.props;

		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/itemDelete`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					ids: [match.params.id],
					hardDelete: "0",
				}),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.error(err));

		if (response.status === "success") {
			const promise1 = new Promise((resolve, reject) => {
				setTimeout(() => resolve(NotificationManager.info(response.message)), 1000);
			});
			promise1.then(history.push(`/app/listing/${match.params.menu}`));
		}
	};

	toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	validate = () =>{
		const required = document.querySelectorAll("#require");

		// debugger;
		let isValidTextField = false
		let isValidImageField = false
		let isValidLookup = false
		let isValidSelectField = false
		let isValidTextArea = false
		let isValidEmail = false

		if (required.length !== 0) {
			let i = 0;
			while (i < required.length) {
				// console.log(required[i]);
				if (required[i].value.length === 0) {
					//this.setState({ disableBtn: true });
					isValidTextField = false
					i = required.length;
				} else {
					//this.setState({ disableBtn: false });
					isValidTextField = true
					i++;
				}
			}
		} else {
			//this.setState({ disableBtn: false });
			isValidTextField = true
		}

		let fields = this.props.item.data
		if(fields === null || fields === undefined){
			fields = []
		}

		// validation for image
		let requiredImageFields = fields.filter(a =>a.required === "true" && a.field_type === "image")
		if(requiredImageFields.length > 0){
			let invalidCount = 0;
			for(let i = 0;i<requiredImageFields.length;i++){
				let isValid =  this.state.file[requiredImageFields[i].field_name] === (null || "") ? false:true
				if(!isValid){
					invalidCount = invalidCount+1
				}
				//let aa = isExistImage
			}
			if(invalidCount > 0){
				isValidImageField = false
			}else{
				isValidImageField = true
			}
		}else {
			isValidImageField = true
		}
		// validation for image

		//validation for lookup
		let requiredLookupFields = fields.filter(a =>a.required === "true" && a.field_type === "lookup")
		if(requiredLookupFields.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredLookupFields.length;i++){
				let ddlValue = this.state[requiredLookupFields[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === "" || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidLookup = false
				}else {
					isValidLookup = true
				}
			}
		}else {
			isValidLookup = true
		}
		//validation for lookup

		//validation for select
		let requiredSelectFields = fields.filter(a =>a.required === "true" && a.field_type === "select")
		if(requiredSelectFields.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredSelectFields.length;i++){
				let ddlValue = this.state[requiredSelectFields[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === "" || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidSelectField = false
				}else {
					isValidSelectField = true
				}
			}
		}else {
			isValidSelectField = true
		}
		//validation for select

		//validation for text area
		let requiredTextArea = fields.filter(a =>a.required === "true" && a.field_type === "textarea")
		if(requiredTextArea.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredTextArea.length;i++){
				let ddlValue = this.state[requiredTextArea[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(ddlValue === "" || ddlValue === undefined || ddlValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidTextArea = false
				}else {
					isValidTextArea = true
				}
			}
		}else {
			isValidTextArea = true
		}
		//validation for text area

		//validation for text email
		let requiredEmail = fields.filter(a =>a.required === "true" && a.field_type === "email")
		if(requiredEmail.length > 0){
			let invalidCount = 0
			for(let i = 0;i<requiredEmail.length;i++){
				let emailValue = this.state[requiredEmail[i].field_name]
				//let bb = requiredLookupFields[i].field_name
				if(emailValue === " " ||emailValue === "" || emailValue === undefined || emailValue === null){
					invalidCount = invalidCount+1
				}
				//disableSave =  this.state[requiredLookupFields[i].field_name] === (null || undefined) ? true:false
				//let aa = isExistImage
				if(invalidCount > 0){
					isValidEmail = false
				}else {
					isValidEmail = true
				}
			}
		}else {
			isValidEmail = true
		}
		//validation for text area
		if(isValidTextField  && isValidImageField && isValidLookup && isValidSelectField && isValidTextArea && isValidEmail){
			this.setState({disableBtn:false})
			this.props.requestEnableButton();
		}else{
			this.setState({disableBtn:true});
			this.props.requestDisableButton()
		}
	}
	//end of validate

	handleInput = (name, type) => async (event) => {
		// console.log(name, event.target.value);

		// const required = document.querySelectorAll("#require");
		//
		// if (required.length !== 0) {
		// 	let i = 0;
		// 	while (i < required.length) {
		// 		if (required[i].value.length === 0 && !required[i].disabled) {
		// 			this.setState({ disableBtn: true });
		// 			i = required.length;
		// 		} else {
		// 			this.setState({ disableBtn: false });
		// 			this.props.requestEnableButton();
		// 			i++;
		// 		}
		// 	}
		// } else {
		// 	this.setState({ disableBtn: false });
		// 	this.props.requestEnableButton();
		// }

		if (name === "set_time_in_month") {
			if (event.target.value < 1) {
				event.target.value = "";
			} else {
				event.target.value = Math.round(event.target.value);
			}
			this.validate();
		}

		if (type === "image") {
			if (!event.target.files[0]) {
				this.setState({
					file: { ...this.state.file, [name]: null },
				}, ()=>{
					this.validate()
				});
			} else {
				const fileName = URL.createObjectURL(event.target.files[0]);
				this.setState({
					file: { ...this.state.file, [name]: fileName },
				}, ()=>{
					this.validate()
				});
			}
		}

		if (type && type === "datetime") {
			this.setState({ [name]: event, selectedDate: event }, ()=>{
				this.validate()
			});
		} else if (type && type === "single_checkbox") {
			this.setState({ [name]: event.target.checked }, ()=>{
				this.validate()
			});
		} else if (type && (type === "image" || type === "file")) {
			if (event.target.files[0]) {
				const file = event.target.files[0];
				const base64 = await this.toBase64(file);
				this.setState({ [name]: base64 }, ()=>{
					this.validate()
				});
			}
		} else if (type && type === "customFields") {
			// console.log("this.state.customFields ------>", this.state.customFields);
			// console.log({ [name]: event.target.value });
			if (this.state.customFields) {
				this.setState({
					customFields: {
						...this.state.customFields,
						[name]: event.target.value,
					},
				}, ()=>{
					this.validate()
				});
			} else {
				this.setState({ customFields: { [name]: event.target.value } }, ()=>{
					this.validate()
				});
			}
		} else {
			this.setState({ [name]: event.target.value }, ()=>{
				this.validate()
			});
		}
		// setTimeout(this.validate,5000)
	};

	handleChange = (name, isRequired) => (selectedOption) => {
		// exceptional for 'permission_row'
		
		const { authUser, match, item } = this.props;
		if (match.params.menu === "permissions_row") {
			// for usergroup
			if (name === "role") {
				fetch(`${config.api.url}api/permissions_row/update_user_role`, {
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						id: match.params.id,
						[name]: selectedOption.value,
						menu: item.data[2].value,
					}),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.status === "success") {
							NotificationManager.info(json.message);
						} else {
							NotificationManager.error(json.message);
						}
					});
			}
			// for menu
			if (name === "menu") {
				this.setState({ newMenu: selectedOption.value });
				fetch(`${config.api.url}api/permissions_row/update_menu`, {
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						id: match.params.id,
						role: item.data[1].value,
						[name]: selectedOption.value,
					}),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.status === "success") {
							this.setState({ permissionFields: json.subviewdata });
							NotificationManager.info(json.message);
						} else {
							NotificationManager.error(json.message);
						}
					});
			}
			// for add permission
			if (name === "add_permission") {
				fetch(`${config.api.url}api/permissions_row/update_add_permission`, {
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						id: match.params.id,
						[name]: selectedOption.value,
					}),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.status === "success") {
							NotificationManager.info(json.message);
						} else {
							NotificationManager.error(json.message);
						}
					});
			}
			// for delete permission
			if (name === "delete_permission") {
				fetch(`${config.api.url}api/permissions_row/update_deleted_permission`, {
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						id: match.params.id,
						[name]: selectedOption.value,
					}),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.status === "success") {
							NotificationManager.info(json.message);
						} else {
							NotificationManager.error(json.message);
						}
					});
			}
			// for export permission
			if (name === "export_permission") {
				fetch(`${config.api.url}api/permissions_row/update_export_permission`, {
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						id: match.params.id,
						[name]: selectedOption.value,
					}),
				})
					.then((res) => res.json())
					.then((json) => {
						if (json.status === "success") {
							NotificationManager.info(json.message);
						} else {
							NotificationManager.error(json.message);
						}
					});
				this.validate()
			}
		} else {
			if(name === "tagList"){
				this.setState({[name]: selectedOption? selectedOption: []});
			}else{
				this.setState({ [name]: selectedOption.value });
			}
			

			//Notify Option
			if (name === "notify_option") {
				if (selectedOption.value === "1") {
					this.setState({ notify_option_1: true, notify_option_2: false });
				}
				if (selectedOption.value === "2") {
					this.setState({ notify_option_1: true, notify_option_2: true });
				}
				if (selectedOption.value === "3") {
					this.setState({ notify_option_1: false, notify_option_2: true });
				}
			}
			if (name === "mandatory_password_change") {
				if (selectedOption.value === "0") {
					document.getElementById("mnth").disabled = true;
				} else {
					document.getElementById("mnth").disabled = false;
				}
			}
			
			this.validate()
		}
	};

	handlePermissionChange = (name) => async (selectedOption) => {
		const roleName = this.props.item.data.filter(
			(dt) => dt.field_name === "role"
		);
		const { authUser, match, item } = this.props;

		let newBody = {
			role: roleName[0].value,
			table: this.state.newMenu || item.data[2].value,
			permission: selectedOption.value,
			fieldName: `${name}@permissions_row`,
		};
		const response = await fetch(
			`${config.api.url}api/${match.params.menu}/setPermissionApi`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newBody),
			}
		)
			.then((res) => res.json())
			.then((json) => json)
			.catch((err) => console.err(err));
		if (response.status === "success") {
			NotificationManager.info(response.message);
		}
	};

	handleEmailUniqueness = (name) => async (event) => {
		const { authUser, match } = this.props;
		const response = await fetch(
			`${config.api.url}/api/${match.params.menu}/check_field_uniqueness_api`,
			{
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					table_name: match.params.menu,
					field_name: name,
					field_value: event.target.value,
					id: "",
				}),
			}
		)
			.then((res) => res.json())
			.then((json) => json);

		if (response.status === "error") {
			NotificationManager.error(response.message);
			this.setState({ disableBtn: true });
		}
	};

	handleRequired = (required) => (e) => {
		if (required === "true" && e.target.value.length === 0) {
			this.setState({ disableBtn: true });
			NotificationManager.error("The field is empty");
		}
	};
	//NOTIFY OPTION PASSWORD
	handleNotifyOptionPass1 = (e) => {
		this.setState({ password: e.target.value });
	};
	handleNotifyOptionPass2 = (e) => {
		this.setState({ notiifyPass2: e.target.value });
	};
	handleNotifyOption = async () => {
		const { item } = this.props;
		const { password, notiifyPass2 } = this.state;
		if (this.state.notify_option_1 && !this.state.notify_option_2) {
			const { authUser, match } = this.props;

			const response = await fetch(
				`${config.api.url}/api/${match.params.menu}/send_invitation`,
				{
					method: "POST",
					headers: {
						Authorization: authUser,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: this.state.email || item.data[4].value,
						send_password: 0,
						user_id: match.params.id,
						raw_password: "",
						language: this.state.language || item.data[8].value,
					}),
				}
			)
				.then((res) => res.json())
				.then((json) => json);

			if (response.status === "success") {
				NotificationManager.info(response.message);
			} else {
				NotificationManager.error(response.message);
			}
		}
		if (this.state.notify_option_1 && this.state.notify_option_2) {
			if (password !== notiifyPass2) {
				NotificationManager.error(<IntlMessages id={"_BOTH_PASSWORD_DO_NOT_MATCH"}/>);
			} else {
				if (password.length < 8) {
					NotificationManager.error("Password must be of 8 or more character");
				} else {
					const { authUser, match } = this.props;

					const response = await fetch(
						`${config.api.url}/api/${match.params.menu}/send_invitation`,
						{
							method: "POST",
							headers: {
								Authorization: authUser,
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								email: this.state.email || item.data[4].value,
								send_password: 1,
								user_id: match.params.id,
								raw_password: password,
								language: "3",
							}),
						}
					)
						.then((res) => res.json())
						.then((json) => json);

					if (response.status === "success") {
						NotificationManager.info(response.message);
					} else {
						NotificationManager.error(response.message);
					}
				}
			}
		}
	};



	handleUnsubscribeRelation = async () => {
		this.setState({ openRelationDialog: false });
		const { authUser, match } = this.props;
		const response = await fetch(
			`${config.api.url}v1/relation/unsubscribe/${match.params.id}`,
			{
				method: "PUT",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					optin: 0,
				}),
			}
		)
			.then((res) => res.json())
			.then((json) => json);

		if (response.status === "success") {
			NotificationManager.info(response.message);
		} else {
			NotificationManager.error(response.message);
		}
	};

	handleAddRelationToGroup = async (value) => {
		this.setState({ openRelationDialog: false, typeRelationDialog: null });
		// const { selected } = this.state;
		const { authUser, match } = this.props;

		for (let i = 0; i < value.length; i++) {
			const response = await fetch(`${config.api.url}v1/group/add/${value[i]}`, {
				method: "POST",
				headers: {
					Authorization: authUser,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					relation: match.params.id,
				}),
			})
				.then((res) => res.json())
				.then((json) => json);

			if (i === value.length - 1 && response.status === "success") {
				NotificationManager.info(response.message);
				setTimeout(() => this.fetchValue(), 1000);
			}
		}
	};

	handleCloseCustomField = () => {
		this.setState({ openCustomField: false, openRelationDialog: false });
	};

	addCustomFields = async (name, type, description) => {
		const response = await fetch(`${config.api.url}v1/relation/field`, {
			method: "POST",
			headers: {
				Authorization: this.props.authUser,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: name,
				type: type,
				description: description,
			}),
		}).then((res) => res.json());

		if (response.status === "success") {
			NotificationManager.info(response.message);

			const { authUser, match } = this.props;
			const params = {
				token: authUser,
				tableName: match.params.menu,
				id: match.params.id,
			};
			this.props.fetchEditView(params);

			if (match.params.menu === "relation") {
				fetch(`${config.api.url}v1/relation/field`, {
					method: "GET",
					headers: {
						Authorization: authUser,
					},
				})
					.then((res) => res.json())
					.then((response) => {
						if (response.status === "success") {
							this.setState({ customRelationField: response.data.items });
						}
					});
			}



		} else {
			NotificationManager.error(response.message);
		}
	};
	handleAdd = () => {
		this.props.history.push({
			pathname: `/app/new/listing/${this.props.match.params.menu}`,
			state: { data: this.props.item },
		});
	};

	getAllTags = () =>{
		let {token} = getAuthData()
		let url = config.api.url + `api/tags/getTagList`;
		axios({
			method: "GET",
			url: url,
			headers:{
				Authorization: token,
			}
		}).then(res=>{
			if(res.data.status === "success"){
				this.setState({allTags: res.data.data})
			}else{
			}
		}).catch((e)=>{
		})
	}
	
	render() {
		const { match, loader, item, intl, history } = this.props;
		const { notify_option_1, notify_option_2, customRelationField } = this.state;
		const tempFieldName = [];
		// console.log("this.props ===>", this.props);
		// console.log("customRelationField --->", customRelationField);
		let invitationInfo = ""
		let asteriskColor = "grey"
		if(item !== null && item !== undefined){
			invitationInfo = item.render_user_invitation_info
		}

		if (!this.state.permissionFields && item) {
			if (item.field_permissions) {
				this.setState({ permissionFields: item.field_permissions });
			}
		}
		return (
			<div className={"account-info-wrapper"}>
				<div className="row mb-3">
					<div
						className="col-md-12 col-12"
						style={{
							display: "flex",
							justifyContent: "flex-end",
							flexWrap: "wrap",
							marginTop: "20px",
						}}
					>
						{match.params.menu === "relation" && (
							<Button
								variant="contained"
								className="jr-btn bg-teal text-white jr-btn-lg"
								onClick={() => this.setState({ openCustomField: true })}
								style={{
									maxHeight: "39.19px",
									minHeight: "39.19px",
									marginTop: "5px",
								}}
							>
								<IntlMessages id="_ECLICKSPRO_ADD_CUSTOM_FIELD" />
							</Button>
						)}
						{match.params.menu === "relation" && (
							<Button
								variant="contained"
								color="secondary"
								className="jr-btn text-white jr-btn-lg"
								onClick={() =>
									this.setState({
										typeRelationDialog: "relation",
										openRelationDialog: true,
									})
								}
								style={{
									maxHeight: "39.19px",
									minHeight: "39.19px",
									marginTop: "5px",
								}}
							>
								<IntlMessages id="_ECLICKSPRO_ADD_RELATION_TO_GROUP" />
							</Button>
						)}
						{match.params.menu === "relation" && (
							<Button
								variant="contained"
								className="jr-btn bg-red text-white jr-btn-lg"
								onClick={() =>
									this.setState({
										typeRelationDialog: "unsubscribe",
										openRelationDialog: true,
									})
								}
								style={{
									maxHeight: "39.19px",
									minHeight: "39.19px",
									marginTop: "5px",
								}}
							>
								<IntlMessages id="_ECLICKSPRO_UNSUBSCRIBE_RELATION" />
							</Button>
						)}

						{/*{match.params.menu !== "permissions_row" && (*/}
						{/*  <Button*/}
						{/*    disabled={this.state.disableBtn}*/}
						{/*    variant="contained"*/}
						{/*    color="primary"*/}
						{/*    className="jr-btn text-white jr-btn-lg"*/}
						{/*    onClick={this.handleSaveButton}*/}
						{/*    style={{*/}
						{/*      maxHeight: "39.19px",*/}
						{/*      minHeight: "39.19px",*/}
						{/*      marginTop: "5px",*/}
						{/*    }}*/}
						{/*  >*/}
						{/*    <IntlMessages id="_SAVE" />*/}
						{/*  </Button>*/}
						{/*)}*/}

						{/*{item &&*/}
						{/*  item.position_data.next_record_id !== 0 &&*/}
						{/*  match.params.menu !== "permissions_row" && (*/}
						{/*    <Button*/}
						{/*      disabled={this.state.disableBtn}*/}
						{/*      variant="contained"*/}
						{/*      color="primary"*/}
						{/*      className="jr-btn text-white jr-btn-lg"*/}
						{/*      onClick={this.handleSaveAndNext}*/}
						{/*      style={{*/}
						{/*        maxHeight: "39.19px",*/}
						{/*        minHeight: "39.19px",*/}
						{/*        marginTop: "5px",*/}
						{/*      }}*/}
						{/*    >*/}
						{/*      <IntlMessages id="_SAVE_AND_NEXT" />*/}
						{/*    </Button>*/}
						{/*  )}*/}

						{/*{match.params.menu !== "permissions_row" && (*/}
						{/*  <Button*/}
						{/*    disabled={this.state.disableBtn}*/}
						{/*    variant="contained"*/}
						{/*    color="primary"*/}
						{/*    className="jr-btn text-white jr-btn-lg"*/}
						{/*    onClick={this.handleSaveAndClose}*/}
						{/*    style={{*/}
						{/*      maxHeight: "39.19px",*/}
						{/*      minHeight: "39.19px",*/}
						{/*      marginTop: "5px",*/}
						{/*    }}*/}
						{/*  >*/}
						{/*    <IntlMessages id="_SAVE_AND_CLOSE" />*/}
						{/*  </Button>*/}
						{/*)}*/}

						{/*{item &&*/}
						{/*  item.add_delete_permissions.add_permission === "1" &&*/}
						{/*  match.params.menu !== "permissions_row" && (*/}
						{/*    <Button*/}
						{/*      variant="contained"*/}
						{/*      className="jr-btn bg-cyan text-white jr-btn-lg"*/}
						{/*      style={{*/}
						{/*        maxHeight: "39.19px",*/}
						{/*        minHeight: "39.19px",*/}
						{/*        marginTop: "5px",*/}
						{/*      }}*/}
						{/*      onClick={this.handleAdd}*/}
						{/*    >*/}
						{/*      /!*<Link*!/*/}
						{/*      /!*  to={{*!/*/}
						{/*      /!*    pathname: `/app/new/listing/${match.params.menu}`,*!/*/}
						{/*      /!*    state: { data: this.props.item },*!/*/}
						{/*      /!*  }}*!/*/}
						{/*      /!*  style={{ color: "white", textDecoration: "none" }}*!/*/}
						{/*      /!*>*!/*/}
						{/*        /!*<IntlMessages id="_ADD" />*!/*/}
						{/*      /!*</Link>*!/*/}
						{/*      {<IntlMessages id="_ADD" />}</Button>*/}
						{/*  )}*/}

						{/*{item && item.add_delete_permissions.delete_permission === "1" && (*/}
						{/*  <Button*/}
						{/*    variant="contained"*/}
						{/*    className="jr-btn bg-red text-white jr-btn-lg"*/}
						{/*    onClick={() => this.openAlertBox()}*/}
						{/*    style={{*/}
						{/*      maxHeight: "39.19px",*/}
						{/*      minHeight: "39.19px",*/}
						{/*      marginTop: "5px",*/}
						{/*    }}*/}
						{/*  >*/}
						{/*    <IntlMessages id="_DELETE" />*/}
						{/*  </Button>*/}
						{/*)}*/}
					</div>
				</div>
				{(loader && (
					<div className="loader-view">
						<CircularProgress />
					</div>
				)) ||
					(item && (
						<div className="row">
							{match.params.menu === "relation" && (
								<div className="col-12">
									{item && item.groups && (
										<div
											style={{
												padding: "12px 0px",
												color: "#4caf50",
												fontSize: "1rem",
											}}
										>
											<IntlMessages id="_ECLICKSPRO_RELATION_IN_GROUP" /> :{" "}
											{item.groups.map((group) => (
												<span
													// onClick={() =>
													//   history.push(
													//     `/app/custom-views/group/edit/${group.id}`
													//   )
													// }
													style={{
														backgroundColor: "rgb(255, 152, 0, 0.75)",
														color: "white",
														marginLeft: "1rem",
														padding: "6px 12px",
														borderRadius: "3px",
													}}
													key={group.id}
												>
													{group.name}
												</span>
											))}
										</div>
									)}

									<div className="row">
										<div className="col-12 mt-3">
											<div
												style={{
													backgroundColor: "#4caf50",
													fontSize: "12px",
													color: "white",
													padding: "8px",
													width: "100%",
												}}
											>
												<IntlMessages id="_ECLICKSPRO_PERSONAL_INFORMATION" />
											</div>
										</div>
										{item.data.map((field, i) => {
											if(field.permission_type !== "0"){

												//these fields are in personal information criteria, so this if block
												if (
													field.field_name === "initials" ||
													field.field_name === "firstname" ||
													field.field_name === "middlename" ||
													field.field_name === "lastname" ||
													field.field_name === "external_id" ||
													field.field_name === "company_name" ||
													field.field_name === "job_title" ||
													field.field_name === "department" ||
													field.field_name === "gender"||
													field.field_name === "po_box"
												) {
													tempFieldName.push(field.field_name);
													return (
														<div className="col-md-4 col-12">
															{field.field_type === "input" && (
																<Fragment>
																	<TextField
																		label={intl.formatMessage({
																			id: "_" + field.field_name.toUpperCase(),
																		})}
																		className=""
																		id={field.required === "true" ? "require" : "non-required"}
																		required={field.required === "true"}
																		disabled={field.permission_type === "1"}
																		key={i}
																		type="text"
																		defaultValue={field.value}
																		margin="normal"
																		onChange={this.handleInput(field.field_name)}
																		fullWidth
																		style={{ backgroundColor: "white" }}
																		onBlur={this.handleRequired(field.required)}
																		style={{
																			backgroundColor: "white",
																			marginTop: "25px",
																		}}
																	/>
																</Fragment>
															)}

															{field.field_type === "select" && (
																<Fragment>
																	<label style={{ fontSize: "16px" }}>
																		{" "}
																		<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																		{field.required === "true" && (
																			<span style={{ color: asteriskColor }}>* </span>
																		)}
																		:{" "}
																	</label>
																	<Select
																		className="mb-3 mt-3"
																		onChange={this.handleChange(field.field_name, field.required)}
																		defaultValue={{
																			value: field.value,
																			label: field.lebel,
																		}}
																		isDisabled={field.permission_type === "1"}
																		options={field.option.map((option) => ({
																			value: option.key.toString(),
																			label: option.values,
																		}))}
																		InputProps={{
																			classes: {
																				input: this.props.classes.input1,
																			},
																		}}
																	/>
																</Fragment>
															)}
														</div>
													);
												}

											}

										})}
									</div>

									<div className="row">
										<div className="col-12 mt-3">
											<div
												style={{
													backgroundColor: "#4caf50",
													fontSize: "12px",
													color: "white",
													padding: "8px",
													width: "100%",
												}}
											>
												<IntlMessages id="_ECLICKSPRO_LIVING_INFORMATION" />
											</div>
										</div>
										{item.data.map((field, i) => {
											if(field.permission_type !== "0"){
												if (
													field.field_name === "address" ||
													field.field_name === "address_number" ||
													field.field_name === "zip_code" ||
													field.field_name === "state" ||
													field.field_name === "city" ||
													field.field_name === "country" ||
													field.field_name === "rating" ||
													field.field_name === "note"
												) {
													tempFieldName.push(field.field_name);
													return (
														<div className="col-md-4 col-12">
															<Fragment>
																<TextField
																	label={intl.formatMessage({
																		id: "_" + field.field_name.toUpperCase(),
																	})}
																	className=""
																	id={field.required === "true" ? "require" : "non-required"}
																	required={field.required === "true"}
																	disabled={field.permission_type === "1"}
																	key={i}
																	type="text"
																	defaultValue={field.value}
																	margin="normal"
																	onChange={this.handleInput(field.field_name)}
																	fullWidth
																	style={{ backgroundColor: "white" }}
																	onBlur={this.handleRequired(field.required)}
																	style={{
																		backgroundColor: "white",
																		marginTop: "25px",
																	}}
																/>
															</Fragment>
														</div>
													);
												}
											}
										})}
									</div>

									<div className="row">
										<div className="col-12 mt-3">
											<div
												style={{
													backgroundColor: "#4caf50",
													fontSize: "12px",
													color: "white",
													padding: "8px",
													width: "100%",
												}}
											>
												<IntlMessages id="_ECLICKSPRO_CONTACT_INFORMATION" />
											</div>
										</div>
										{item.data.map((field, i) => {
											if(field.permission_type !== "0"){
												if (
													field.field_name === "email" ||
													field.field_name === "optin" ||
													field.field_name === "optin_sms" ||
													field.field_name === "optin_letter" ||
													field.field_name === "optin_date" ||
													field.field_name === "optout_date" ||
													field.field_name === "phonenumber_work" ||
													field.field_name === "phonenumber_mobile" ||
													field.field_name === "birthday" ||
													field.field_name === "soft_bounces" ||
													field.field_name === "hard_bounces"
												) {
													tempFieldName.push(field.field_name);
													return (
														<div className="col-md-4 col-12">
															{field.field_type === "input" && (
																<Fragment>
																	<TextField
																		label={intl.formatMessage({
																			id: "_" + field.field_name.toUpperCase(),
																		})}
																		className=""
																		id={field.required === "true" ? "require" : "non-required"}
																		required={field.required === "true"}
																		disabled={field.permission_type === "1"}
																		key={i}
																		type="text"
																		defaultValue={field.value}
																		margin="normal"
																		onChange={this.handleInput(field.field_name)}
																		fullWidth
																		style={{ backgroundColor: "white" }}
																		onBlur={this.handleRequired(field.required)}
																		style={{
																			backgroundColor: "white",
																			marginTop: "25px",
																		}}
																	/>
																</Fragment>
															)}
															{field.field_type === "email" && (
																<Fragment>
																	<TextField
																		label={intl.formatMessage({
																			id: "_" + field.field_name.toUpperCase(),
																		})}
																		className=""
																		id={field.required === "true" ? "require" : "non-required"}
																		required={field.required === "true"}
																		disabled={field.permission_type === "1"}
																		key={i}
																		type="text"
																		defaultValue={field.value}
																		margin="normal"
																		onChange={this.handleInput(field.field_name)}
																		fullWidth
																		style={{ backgroundColor: "white" }}
																		onBlur={this.handleRequired(field.required)}
																		style={{
																			backgroundColor: "white",
																			marginTop: "25px",
																		}}
																	/>
																</Fragment>
															)}

															{field.field_type === "select" && (
																<Fragment>
																	<label style={{ fontSize: "16px" }}>
																		{" "}
																		<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																		{field.required === "true" && (
																			<span style={{ color: asteriskColor }}>* </span>
																		)}
																		:{" "}
																	</label>
																	<Select
																		className="mb-3 mt-3"
																		onChange={this.handleChange(field.field_name, field.required)}
																		defaultValue={{
																			value: field.value,
																			label: field.lebel,
																		}}
																		isDisabled={field.permission_type === "1"}
																		options={field.option.map((option) => ({
																			value: option.key.toString(),
																			label: option.values,
																		}))}
																		InputProps={{
																			classes: {
																				input: this.props.classes.input1,
																			},
																		}}
																	/>
																</Fragment>
															)}
														</div>
													);
												}
											}

										})}
									</div>

									<div className="row">
										<div className="col-12 mt-3">
											<div
												style={{
													backgroundColor: "#4caf50",
													fontSize: "12px",
													color: "white",
													padding: "8px",
													width: "100%",
												}}
											>
												<IntlMessages id="_ECLICKSPRO_CUSTOM_FIELDS" />
											</div>
										</div>
										{customRelationField.map((field, i) => {
											return (
												<div className="col-md-4 col-12">
													<Fragment>
														<TextField
															className={"common-edit-text-for-gt"}
															label={intl.formatMessage({
																id: "_" + field.name.toUpperCase(),
															})}
															key={i}
															type="text"
															defaultValue={this.props.item.customFieldsData.map((data) => {
																if (data.name === field.name) {
																	return data.content;
																}
															})}
															margin="normal"
															onChange={this.handleInput(field.name, "customFields")}
															fullWidth
															style={{ backgroundColor: "white" }}
															style={{
																backgroundColor: "white",
																marginTop: "25px",
															}}
														/>
													</Fragment>
												</div>
											);
										})}
										<div className="col-md-4 col-12">
											<Fragment>
												<label style={{ fontSize: "16px" }}>
													<IntlMessages id={"_" + "tags".toUpperCase()} />{" "}
													{/* {field.required === "true" && (
														<span style={{ color: asteriskColor }}>* </span>
													)}
													:{" "} */}
												</label>
												<Select
													className="primary mt-3"
													isMulti
													onChange={this.handleChange("tagList")}
													// defaultValue={}
													value={this.state.tagList}
													// isDisabled={field.permission_type === "1"}
													options={this.state.allTags.length === 0 ? [] : this.state.allTags?.map((option) => ({
														value: option?.id,
														label: option.tag,
													}))}
												/>
											</Fragment>
										</div>
													
									</div>
								</div>
							)}

							{match.params.menu !== "relation" &&
								item.data.map((field, i) => {
									if (field.field_type !== "hidden" && field.permission_type !== "0") {
										return (
											<div className="col-md-4 col-12" style={{ marginTop: "20px" }} key={`${field.field_type}_${field.field_name}`}>
												{field.field_type === "input" && (
													<Fragment>
														<TextField
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															className=""
															id={field.required === "true" ? "require" : "non-required"}
															required={field.required === "true"}
															disabled={field.field_name === "current_package" || field.field_name === "pay_per" || field.permission_type === "1"}
															key={i}
															type="text"
															defaultValue={field.value}
															margin="normal"
															onChange={this.handleInput(field.field_name)}
															fullWidth
															style={{ backgroundColor: "white" }}
															onBlur={this.handleRequired(field.required)}
															style={{
																backgroundColor: "white",
																marginTop: "25px",
															}}
														/>
													</Fragment>
												)}

												{field.field_type === "textarea" && (
													<Fragment>
														<label style={{ fontSize: "16px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />
														</label>
														<TextareaAutosize
															className="mb-3"
															key={i}
															disabled={field.permission_type === "1"}
															defaultValue={field.value}
															rowsMin={10}
															rowsMax={10}
															cols={40}
															onChange={this.handleInput(field.field_name)}
															style={{
																backgroundColor: "white",
																width: "100%",
															}}
														/>
													</Fragment>
												)}

												{field.field_type === "auto_inc_number" && (
													<Fragment>
														<TextField
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															className="mb-3"
															key={i}
															type="text"
															disabled={field.permission_type === "1"}
															defaultValue={field.value}
															onChange={this.handleInput(field.field_name)}
															fullWidth
															disabled
														/>
													</Fragment>
												)}

												{field.field_type === "email" && field.unique === "true" && (
													<Fragment>
														<TextField
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															id={field.required === "true" ? "require" : "non-required"}
															required={field.required === "true"}
															className="mb-3"
															key={i}
															type="text"
															defaultValue={field.value}
															disabled={field.permission_type === "1"}
															onChange={this.handleInput(field.field_name)}
															fullWidth
															style={{
																backgroundColor: "white",
																marginTop: "25px",
															}}
															onBlur={this.handleEmailUniqueness(field.field_name)}
														/>
														{this.state.validationStatus ? (
															<span style={{ color: "red" }}>
																{this.state.validationStatus}
															</span>
														) : null}
													</Fragment>
												)}

												{field.field_type === "email" && field.unique === "false" && (
													<Fragment>
														<TextField
															required={field.required === "true"}
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															id={field.required === "true" ? "require" : "non-required"}
															className="mb-3"
															key={i}
															type="text"
															disabled={field.permission_type === "1"}
															defaultValue={field.value}
															onChange={this.handleInput(field.field_name)}
															fullWidth
															style={{
																backgroundColor: "white",
																marginTop: "25px",
															}}
															onBlur={this.handleRequired(field.required)}
														/>
													</Fragment>
												)}

												{field.field_type === "money" && (
													<Fragment>
														<TextField
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															className="mb-3"
															key={i}
															type="number"
															disabled={field.permission_type === "1"}
															defaultValue={field.value}
															onChange={this.handleInput(field.field_name)}
															fullWidth
															style={{
																backgroundColor: "white",
																marginTop: "25px",
															}}
														/>
													</Fragment>
												)}

												{field.field_type === "number" && (
													<Fragment>
														<TextField
															label={intl.formatMessage({
																id: "_" + field.field_name.toUpperCase(),
															})}
															className="mb-3"
															id="mnth"
															key={i}
															type="number"
															defaultValue={field.value}
															disabled={field.permission_type === "1"}
															onChange={this.handleInput(field.field_name)}
															fullWidth
															style={{
																backgroundColor: "white",
																alignContent: "center",
																marginTop: "25px",
															}}
															inputProps={{ min: "1", step: "1" }}
														/>
													</Fragment>
												)}

												{field.field_type === "datetime" && (
													<Fragment>
														<label style={{ fontSize: "16px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />
														</label>
														<DatePicker
															key={i}
															className="mb-3"
															fullWidth
															disabled={field.field_name === "start_date" || field.field_name === "end_date" || field.permission_type === "1"}
															value={
																this.state[field.field_name]
																	? this.state[field.field_name]
																	: (field.value.length > 0 && field.value !== "0")
																	? moment.unix(field.value)
																	: null
															}
															onChange={this.handleInput(field.field_name, field.field_type)}
															animateYearScrolling={false}
															leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
															rightArrowIcon={<i className="zmdi zmdi-arrow-forward"/>}
															// format="dd/MM/yyyy"
															// views={["year", "month", "date"]}
														/>
													</Fragment>
												)}

												{field.field_type === "single_checkbox" && (
													<Fragment>
														<label style={{ fontSize: "16px", marginTop: "40px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />
														</label>
														<Checkbox
															key={i}
															className="ml-2"
															color="primary"
															defaultValue={field.value}
															onChange={this.handleInput(field.field_name, field.field_type)}
															style={{ marginTop: "7px" }}
															disabled={field.permission_type === "1"}
														/>
													</Fragment>
												)}

												{field.field_type === "file" && (
													<Fragment>
														<label style={{ fontSize: "16px", marginTop: "45px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />
														</label>
														<Input
															id={field.field_name}
															key={i}
															type="file"
															className="ml-2"
															disabled={field.permission_type === "1"}
															disableUnderline={true}
															onChange={this.handleInput(field.field_name, field.field_type)}
															style={{ display: "none", marginTop: "20px" }}
														/>
														<label htmlFor={field.field_name}>
															<Button
																disabled={field.permission_type === "1"}
																variant="contained"
																// className="jr-btn bg-green text-white jr-btn-lg"
																component="span"
																style={{
																	maxHeight: "30.19px",
																	minHeight: "30.19px",
																	marginLeft: "20px",
																	marginTop: "5px",
																	backgroundColor:
																		field.permission_type === "1" ? "#e0e0e0" : "#4CAF50",
																	color: "white",
																}}
															>
																Choose file
															</Button>
														</label>
													</Fragment>
												)}

												{field.field_type === "image" && (
													<Fragment>
														<label style={{ fontSize: "16px", marginTop: "45px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />
														</label>
														<Input
															id={field.field_name}
															key={i}
															type="file"
															className="ml-2"
															disabled={field.permission_type === "1"}
															disableUnderline={true}
															onChange={this.handleInput(field.field_name, field.field_type)}
															style={{ display: "none" }}
														/>
														<label htmlFor={field.field_name}>
															<Button
																disabled={field.permission_type === "1"}
																variant="contained"
																// className="jr-btn bg-cyan text-white jr-btn-lg"
																component="span"
																style={{
																	maxHeight: "30.19px",
																	minHeight: "30.19px",
																	marginLeft: "20px",
																	marginTop: "5px",
																	color: "white",
																	backgroundColor:
																		field.permission_type === "1" ? "#e0e0e0" : "#00bcd4",
																}}
															>
																Choose file
															</Button>
															{this.state.file[field.field_name] ? (
																<img
																	style={{ width: "80px" }}
																	src={this.state.file[field.field_name]}
																/>
															) : field.value ? (
																<img style={{ width: "80px" }} src={field.value} />
															) : null}
														</label>
													</Fragment>
												)}

												{field.field_type === "select" && (
													<Fragment>
														<label style={{ fontSize: "16px" }}>
															{" "}
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
															{field.required === "true" && (
																<span style={{ color: asteriskColor }}>* </span>
															)}
															:{" "}
														</label>
														<Select
															className="mb-3 mt-3"
															onChange={this.handleChange(field.field_name, field.required)}
															defaultValue={{
																value: field.value,
																label: field.lebel,
															}}
															isDisabled={field.permission_type === "1"}
															options={field.option.map((option) => ({
																value: option.key.toString(),
																label: option.values,
															}))}
															InputProps={{
																classes: { input: this.props.classes.input1 },
															}}
														/>
													</Fragment>
												)}
												{field.field_type === "select" &&
													field.field_name === "notify_option" &&
													notify_option_2 === true && (
														<Fragment style={{ marginTop: "20px" }}>
															<TextField
																label={intl.formatMessage({
																	id: "_" + field.field_name.toUpperCase(),
																})}
																className="mt-3 mb-3"
																key={i}
																type="password"
																label={<IntlMessages id={"_PASSWORD"} />}
																disabled={field.permission_type === "1"}
																onChange={this.handleNotifyOptionPass1}
																fullWidth
																variant="outlined"
																style={{
																	backgroundColor: "white",
																	marginTop: "25px",
																}}
																autoComplete="off"
															/>
															<TextField
																label={<IntlMessages id={"_REPEAT_PASSWORD"} />}
																className="mb-3"
																key={i}
																type="password"
																autoComplete="off"
																onChange={this.handleNotifyOptionPass2}
																fullWidth
																disabled={field.permission_type === "1"}
																variant="outlined"
																style={{
																	backgroundColor: "white",
																	marginTop: "25px",
																}}
															/>
														</Fragment>
													)}
												{field.field_type === "select" &&
													field.field_name === "notify_option" &&
													notify_option_1 === true && (
													<div>
														<Button
															variant="contained"
															className="jr-btn bg-green text-white jr-btn-lg"
															onClick={this.handleNotifyOption}
															style={{
																maxHeight: "39.19px",
																minHeight: "39.19px",
																marginTop: "10px",
															}}
														>
															<IntlMessages id="_SEND_INVITATION_EMAIL" />
															<i className="zmdi zmdi-email-alt"></i>
														</Button>
														{" "}
														<p style={{marginTop:"20px"}}>
															{invitationInfo}
														</p>
													</div>
													)}

												{(field.field_type === "lookup" ||
													field.field_type === "custom_select_opening_view" ||
													field.field_type === "custom_select") && (
													<Fragment>
														<label style={{ fontSize: "16px" }}>
															<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
															{field.required === "true" && (
																<span style={{ color: asteriskColor }}>* </span>
															)}
															:{" "}
														</label>
														<Select
															className="primary mt-3"
															onChange={this.handleChange(field.field_name, field.required)}
															defaultValue={{
																value: field.value,
																label: field.lebel,
															}}
															isDisabled={field.permission_type === "1"}
															options={field.option.map((option) => ({
																value: option.key.toString(),
																label: option.value,
															}))}
														/>
													</Fragment>
												)}
											</div>
										);
									}
								})}

							{/* for field permission */}
							{this.props.match.params.menu === "permissions_row" &&
								<>
									{this.state.permissionFields && (
										<div className="row" id="permission">
											<h1>
												<IntlMessages id="_ECLICKSPRO_FIELD_PERMISSIONS" />
											</h1>
											<hr></hr>
											<div id="permission_row">
												{this.state.permissionFields.map((field) => {
													return (
														<div key={field.field_name} style={{ marginTop: "12px" }}>
															<label style={{ fontSize: "16px" }}>
																{" "}
																<IntlMessages id={"_" + field.field_name.toUpperCase()} />{" "}
																{field.required === "true" && (
																	<span style={{ color: asteriskColor }}>* </span>
																)}
																:{" "}
															</label>
															{field.field_type === "select" && (
																<Select
																	className="primary1"
																	onChange={this.handlePermissionChange(field.field_name)}
																	defaultValue={{
																		value: field.value && field.value.toString(),
																		label: field.lebel,
																	}}
																	disabled={field.permission_type === "1"}
																	options={field.option && field.option.map((option) => ({
																		value: option.key.toString(),
																		label: option.value,
																	})) || []}
																/>
															)}
														</div>
													);
												})}
											</div>
										</div>
									)}
								</>
							}
						</div>
					))}
				<AlertBox
					flag={this.state.flag}
					closeAlertBox={this.closeAlertBox}
					handleDelete={this.handleDelete}
				/>
				<CustomFieldDialog
					tableName={match.params.menu}
					open={this.state.openCustomField}
					handleRequestClose={this.handleCloseCustomField}
					addCustomFields={this.addCustomFields}
				/>
				{this.state.typeRelationDialog && (
					<RelationDialogBox
						open={this.state.openRelationDialog}
						type={this.state.typeRelationDialog}
						handleRequestClose={this.handleCloseCustomField}
						handleUnsubscribeRelation={this.handleUnsubscribeRelation}
						handleAddRelationToGroup={this.handleAddRelationToGroup}
					/>
				)}
			</div>
		);
	}
}
const mapStateToProps = ({ auth, editView, accountInformation }) => {
	const { authUser } = auth;
	const { loader, params, item } = editView;
	return {
		authUser,
		loader,
		params,
		item,
		acInfoBtnclickSave: accountInformation.acInfoBtnclickSave,
		acInfoBtnClickAdd: accountInformation.acInfoBtnClickAdd,
		acInfoBtnClickDelete: accountInformation.acInfoBtnClickDelete,
		acInfoBtnclickSaveAndNext: accountInformation.acInfoBtnclickSaveAndNext,
		acInfoBtnclickSaveAndClose: accountInformation.acInfoBtnclickSaveAndClose,
	};
};

const AccountInformation = compose(
	connect(mapStateToProps, {
		userSignIn,
		fetchEditView,
		resetAll,
		requestEnableButton,
		requestDisableButton
	}),
	withStyles(styles),
	injectIntl
);

export default AccountInformation(AccountInformationApp);
