import React from "react";

const SearchBox = ({
  backgroundColor,
  color,
  styleName,
  placeholder,
  onChange,
  onKeyDown,
  value,
  onFocus,
  onBlur
}) => {
  return (
    <div className={`search-bar right-side-icon bg-transparent ${styleName}`}>
      <div className="form-group">
        <input
          className="form-control border-0"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#C8E6C9",
            color: color ? color : "#000"
          }}
        />
        <button className="search-icon">
          <i
            className="zmdi zmdi-search zmdi-hc-lg"
            style={{ color: "#000" }}
          />
        </button>
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: ""
};
