import {
    FETCH_SMS_RELATION,
    FETCH_SMS_RELATION_SUCCESS
  } from "constants/ActionTypes";
  
  const INIT_STATE = {
    loader: true,
    smsRelations: null,
    smsRelationParams: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_SMS_RELATION: {
        return {
          ...state,
          loader: true,
          smsRelationParams: action.payload
        };
      }
      case FETCH_SMS_RELATION_SUCCESS: {
        return {
          ...state,
          loader: false,
          smsRelations: action.payload
        };
      }
      default:
        return state;
    }
  };
  