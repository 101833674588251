import {
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FOLDER,
  FETCH_GROUP_FOLDER_SUCCESS,
  FETCH_GROUP_RELATION,
  FETCH_GROUP_RELATION_SUCCESS
} from "constants/ActionTypes";

import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import {getResponseForNotificaion} from "../common/functions/EproCommonFunctions";
import { httpService } from "../common/helpers/HttpService";
import { getAuthData } from "../common/helpers/AuthHelper";

let actionsFor = "group"


export const fetchGroup = params => {
  return {
    type: FETCH_GROUP,
    payload: params
  };
};

export const fetchGroupSuccess = groups => {
  return {
    type: FETCH_GROUP_SUCCESS,
    payload: groups
  };
};

export const fetchGroupFolder = params => {
  return {
    type: FETCH_GROUP_FOLDER,
    payload: params
  };
};

export const fetchGroupFolderSuccess = folders => {
  return {
    type: FETCH_GROUP_FOLDER_SUCCESS,
    payload: folders
  };
};

export const fetchGroupRelation = params => {
  return {
    type: FETCH_GROUP_RELATION,
    payload: params
  };
};

export const fetchGroupRelationSuccess = relations => {
  return {
    type: FETCH_GROUP_RELATION_SUCCESS,
    payload: relations
  };
};

export const actionCreators = {
  //getById

  //getAllInFolder
  getItemsInFolder:(folderId)=>async (dispatch,getState) =>{
    dispatch({ type: Constants.REQUEST_GET_ALL_GROUP_IN_FOLDER });


    let url = config.api.url + "v1/"+actionsFor+"?folder_id=" + folderId;
    let folderUrl = config.api.url + "v1/folder/"+actionsFor+"/" + folderId;
    let {token} = getAuthData()

    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
        .then((res) => {
          // dispatch({ type: "GET_ALL_FORM_SUCCESS", forms: res.data.data.items });
          if(res.data.status === "success"){
            let items = res.data.data.items
            axios({
              method: "get",
              url: folderUrl,
              headers: {
                Authorization: token,
              },
            })
                .then((res) => {
                  if(res.data.status === "success"){
                    dispatch({type:Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER,items:items,folders:res.data.data.items})
                    //dispatch({type:Constants.SUCCESS_GET_ALL_GROUP_IN_FOLDER,items:[],folders:[]})
                  }
                  //dispatch({ type: "GET_ALL_FOLDER_SUCCESS", folders: res.data.data.items });
                })
                .catch((e) => {
                  let aaaa = e;
                });
          }

        })
        .catch((e) => {
         // let aaaa = e;
        });


  },
  //
  addFolder: (name, parentId) => async (dispatch, getState) => {
    dispatch({type:Constants.REQUEST_ADD_FOLDER_IN_GROUP})
    let url = config.api.url + "v1/folder/";
    let {token} = getAuthData()

    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { name: name, type: actionsFor, parent_id: parentId },
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if(res.data.status === "success"){
            dispatch({
              type: Constants.SUCCESS_ADD_FOLDER_IN_GROUP,
              folders: [res.data.data],
              apiResponse:apiResponse

            });
          }else {
            dispatch({type:Constants.FAILED_ADD_FOLDER_IN_GROUP,apiResponse:apiResponse})
          }

        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({type:Constants.FAILED_ADD_FOLDER_IN_EMAIL,apiResponse:errorResponse})
        });
  },
  addItem: (name, parentId) => async (dispatch, getState) => {
    dispatch({type: Constants.REQUEST_ADD_GROUP})
    let url = config.api.url + "v1/"+actionsFor;
    let {token} = getAuthData()

    axios({
      method: "POST",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { name: name, parent_id: parentId },
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {
            dispatch({
              type: Constants.SUCCESS_ADD_GROUP,
              item: [res.data.data],
              apiResponse:apiResponse
            });
          }else {
            dispatch({type:Constants.FAILED_ADD_GROUP,apiResponse:apiResponse})
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({type:Constants.FAILED_ADD_GROUP,apiResponse:errorResponse})
        });
  },
  handleRename: (id, name) => async (dispatch, getState) => {
    dispatch({type:Constants.REQUEST_RENAME_GROUP})
    let url = config.api.url + "v1/"+actionsFor+"/" + id;
    let {token} = getAuthData()
    axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: token,
      },
      data: {
        name: name,
      },
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if(res.data.status === "success"){
            dispatch({
              type: Constants.SUCCESS_RENAME_GROUP,
              item: [res.data.data],
              itemId: id,
              apiResponse:apiResponse
            });
          }else {
            dispatch({type:Constants.FAILED_RENAME_GROUP,apiResponse:apiResponse})
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({type:Constants.FAILED_RENAME_GROUP,apiResponse:errorResponse})
        });
  },
  renameFolder: (id, name) => async (dispatch, getState) => {
    let url = config.api.url + "v1/folder/" + id;
    let {token} = getAuthData()

    axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: token,
      },
      data: {
        name: name,
      },
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if(res.data.status === "success"){
            dispatch({
              type: Constants.SUCCESS_RENAME_GROUP_FOLDER,
              folder: [res.data.data],
              folderId: id,
            });
          }else {
            dispatch({type: Constants.FAILED_RENAME_GROUP_FOLDER,apiResponse:apiResponse})
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({type: Constants.FAILED_RENAME_GROUP_FOLDER,apiResponse:errorResponse})
        });
  },
  deleteSelected: (itemIds, folderIds) => async (dispatch, getState) => {

    let itemUrl = config.api.url + "v1/"+actionsFor+"/";
    let folderUrl = config.api.url + "v1/folder/";
    let {token} = getAuthData()


    if (itemIds.length > 0) {
      for (let i = 0; i < itemIds.length; i++) {
        let url = "";
        url = itemUrl + itemIds[i];
        axios({
          method: "DELETE",
          url: url,
          headers: {
            Authorization: token,
          },
        })
            .then((res) => {
              let apiResponse = getResponseForNotificaion(res)
              if(res.data.status === "success"){
                dispatch({ type: Constants.SUCCESS_DELETE_GROUP, itemId: itemIds[i],apiResponse: apiResponse });
              }else {
                dispatch({type:Constants.FAILED_DELETE_GROUP,apiResponse: apiResponse})
              }

            })
            .catch((e) => {
            });
      }
    }
    if (folderIds.length > 0) {
      for (let j = 0; j < folderIds.length; j++) {
        let url = "";
        url = folderUrl + folderIds[j];
        axios({
          method: "DELETE",
          url: url,
          headers: {
            Authorization: token,
          },
        })
            .then((res) => {
              dispatch({
                type: Constants.SUCCESS_DELETE_GROUP_FOLDER,
                folderId: folderIds[j],
              });
            })
            .catch((e) => console.log(e));
      }
    }
  },
  updateItemParent: (itemId, parentId) => async (dispatch, getState) => {
    let url = config.api.url + "v1/"+actionsFor+"/" + itemId;
    let {token} = getAuthData()

    axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: token,
      },
      data: {
        folder_id: parentId,
      },
    })
        .then((res) => {
          dispatch({
            type: Constants.SUCCESS_UPDATE_GROUP_PARENT,
            fromId: itemId,
          });
        })
        .catch((e) => console.log(e));
  },
  updateFolderParent: (folderId, parentId) => async (dispatch, getState) => {
    let url = config.api.url + "v1/folder/" + folderId;
    let {token} = getAuthData()

    axios({
      method: "PUT",
      url: url,
      headers: {
        Authorization: token,
      },
      data: {
        parent_id: parentId,
      },
    })
        .then((res) => {
          dispatch({
            type: Constants.SUCCESS_UPDATE_GROUP_FOLDER_PARENT,
            fldrId: folderId,
          });
        })
        .catch((e) => console.log(e));
  },
  // getCampaignOfThisItem:(type,id)=>async (dispatch,getState) =>{
  //   let url = config.api.url + "api/campaign/getCampaignOfThisItem"
  //   let token = localStorage.getItem("user_token");
  //   dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
  //   debugger
  //   let request = await httpService.postAsync(url,{'type':type,'id':id})
  //   if(request.data.status === "success"){
  //     debugger
  //     let obj = null
  //     if(request.data.isSelected !== 0){
  //       obj = {
  //         campaignId:request.data.campaignId,
  //         campaignName:request.data.campaignName
  //       }
  //     }
  //     dispatch({ type: Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,payload:obj });
  //   }else{
  //     dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
  //   }
  // },
  getCampaignOfThisItem:(type,id)=>async (dispatch,getState) =>{
    let url = config.api.url + "api/campaign/getCampaignOfThisItem"
    dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
    let {token} = getAuthData()

    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { type: type, typeId:id},
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {
            let obj = null
            if(res.data.isSelected !== 0){
              obj = {
                campaignId:res.data.campaignId,
                campaignName:res.data.campaignName
              }
            }
            dispatch({ type: Constants.SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM,payload:obj });
          } else {
            dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
        });
  },
  removeRelationFromGroup:(groupId,relations)=>async (dispatch,getState) =>{
    let url = config.api.url + "v1/group/removeRelation"
    dispatch({ type: Constants.REQUEST_DELETE_TABLE_ROWS });
    let {token} = getAuthData()

    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: token,
      },
      data: { groupId: groupId, relationIds:relations},
    })
        .then((res) => {
          let apiResponse = getResponseForNotificaion(res)
          if (res.data.status === "success") {

            dispatch({ type: Constants.SUCCESS_DELETE_TABLE_ROWS, apiResponse:apiResponse });
          } else {
            dispatch({ type: Constants.FAILED_DELETE_TABLE_ROWS, apiResponse:apiResponse});
          }
        })
        .catch((e) => {
          let errorResponse = getResponseForNotificaion(null,{})
          dispatch({ type: Constants.FAILED_DELETE_TABLE_ROWS, apiResponse:errorResponse});
        });
  },
  resetResponse:()=>async (dispatch,getState)=>{
    dispatch({type:Constants.RESET_GROUP_RESPONSE})
  },
  resetState:()=>async (dispatch,getState) => {
    dispatch({type:Constants.RESET_GROUP_STATE})
  }
}
