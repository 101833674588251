import React from "react";
import IntlMessages from "util/IntlMessages";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { NavLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    relationDetailArea:{
        fontSize:"14px"
    },
    relationButtonArea:{
        justifyContent: "flex-end",
        display: "flex",
        alignItems: "center",
    },
    relationLabel:{
        width: "100px",
        fontWeight: "bold",
        display: "inline-block"
    },
    newRelationTitleArea:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    newRelationTitle:{
        fontWeight: "bold",
        fontSize: "1rem",
        display: "contents",
        textTransform:"uppercase"
    },
    newRelationFooter:{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "5px",
    },
    newRelationHrLine:{
        margin: "0.80rem auto"
    }
})

const Relation = ({ id, firstname, lastname, email }) => {
    const classes = useStyles()
  return (
    <div className="row" key={id}>
      <div className="col-10">
          <div className={classes.relationDetailArea}>
              <div className="mb-1">
                  <span className={classes.relationLabel}>
                    <IntlMessages id="_FIRST_NAME" />
                  </span>
                  <span>{firstname}</span>
              </div>
              <div className="mb-1">
                  <span className={classes.relationLabel}>
                    <IntlMessages id="_LAST_NAME" />
                  </span>
                  <span>{lastname}</span>
              </div>

              <div className="mb-1">
                  <span className={classes.relationLabel}>
                    <IntlMessages id="_EMAIL" />
                  </span>
                  <span>{email}</span>
              </div>
          </div>
      </div>
      <div className="col-2">
          <div className={classes.relationButtonArea}>
              <NavLink
                  to={"/app/listing/relation/edit/" + id}
                  style={{ color: "#575757" }}
              >
                  <ArrowRightIcon fontSize="large" />
              </NavLink>
          </div>
      </div>
    </div>
  );
};

const NewRelation = ({ data }) => {
    const classes = useStyles()
  return (
    <div className="jr-card jr-card-widget jr-card-ticketlist card">
      <div className={classes.newRelationTitleArea}>
        <p className={classes.newRelationTitle}>
          <IntlMessages id="_ECLICKSPRO_NEW_RELATIONS" />
        </p>
        {/* <KeyboardArrowDownIcon fontSize="default" /> */}
      </div>

      {data.map((relation, i) => {
        if (i < 4) {
          return (
            <div key={"newrel-"+i}>
              <Relation
                id={relation.id}
                firstname={relation.firstname}
                lastname={relation.lastname}
                email={relation.email}
              />
              <hr className={classes.newRelationHrLine} />
            </div>
          );
        }
      })}
      <div className={classes.newRelationFooter}>
        <NavLink to="/app/listing/relation" style={{ color: "#575757" }}>
          <IntlMessages id="_ECLICKSPRO_SEE_MORE" />
        </NavLink>
      </div>
    </div>
  );
};

export default withRouter(NewRelation);
