import {
    FETCH_PAGE,
    FETCH_PAGE_SUCCESS,
    FETCH_PAGE_FOLDER,
    FETCH_PAGE_FOLDER_SUCCESS,
    FETCH_PAGE_BY_ID,
    FETCH_PAGE_BY_ID_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants";

const INIT_STATE = {
    loader: true,
    openFullScreenLoader: false,
    page: null,
    pages: null,
    folder: null,
    pageParams: null,
    folderParams: null,
    pixelCode: null,
    footerPixelCode: null,
    productPrice: "",
    productDescription: "",
    productVat: "",
    pagePutStatus: null,
    pageApiResponse: null,
    campaignIdOfThisItem: null,
    generatedEmailData: "",
    loader1: false,
    profile: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case Constants.REQUEST_GET_PAGE_BY_ID: {
            return {
                ...state,
                loader: true,
                page: null,
                pixelCode: null,
                footerPixelCode: null,
                productPrice: "",
                productDescription: "",
                productVat: ""
            };
        }
        case Constants.SUCCESS_GET_PAGE_BY_ID: {
            return {
                ...state,
                loader: false,
                page: action.data,
                pixelCode: action.pixelCode,
                footerPixelCode: action.footerPixelCode,
                productPrice: action.productPrice,
                productDescription: action.productDescription,
                productVat: action.productVat
            };
        }
        case Constants.FAILED_GET_PAGE_BY_ID: {
            return {
                ...state
            };
        }
        case Constants.REQUEST_PUT_PAGE: {
            return {
                ...state,
                //pagePutStatus:null
                openFullScreenLoader: true,
                pageApiResponse: null
            };
        }
        case Constants.SUCCESS_PUT_PAGE: {
            return {
                ...state,
                page: action.page,
                openFullScreenLoader: false,
                //pagePutStatus:"success"
                pageApiResponse: action.apiResponse
            };
        }
        case Constants.FAILED_PUT_PAGE: {
            return {
                ...state,
                //pagePutStatus:"failed"
                pageApiResponse: action.apiResponse
            };
        }
        case Constants.REQUEST_RENAME_PAGE: {
            return {
                ...state,
                openFullScreenLoader: true
            };
        }
        case Constants.SUCCESS_RENAME_PAGE: {
            return {
                ...state,
                page: action.formObj,
                openFullScreenLoader: false,
                pageApiResponse: action.apiResponse
            };
        }
        case Constants.FAILED_RENAME_PAGE: {
            return {
                ...state,
                pageApiResponse: action.apiResponse,
                openFullScreenLoader: false
            };
        }
        case FETCH_PAGE: {
            return {
                ...state,
                loader: true,
                poageParams: action.payload
            };
        }
        case FETCH_PAGE_SUCCESS: {
            return {
                ...state,
                loader: false,
                pages: action.payload
            };
        }
        case FETCH_PAGE_FOLDER: {
            return {
                ...state,
                loader: true,
                folderParams: action.payload
            };
        }
        case FETCH_PAGE_FOLDER_SUCCESS: {
            return {
                ...state,
                loader: false,
                folder: action.payload
            };
        }
        case FETCH_PAGE_BY_ID: {
            return { ...state, loader: true, pageDataParams: action.payload };
        }
        case FETCH_PAGE_BY_ID_SUCCESS: {
            return { ...state, loader: false, pageData: action.payload };
        }
        case Constants.RESET_SUCCESS_STATUS: {
            return {
                ...state,
                pagePutStatus: null
            };
        }
        case Constants.RESET_PAGE_API_RESPONSE: {
            return {
                ...state,
                pageApiResponse: null
            };
        }
        case Constants.START_PAGE_LOADER_DIALOG: {
            return {
                ...state,
                openFullScreenLoader: true
            };
        }
        case Constants.STOP_PAGE_LOADER_DIALOG: {
            return {
                ...state,
                openFullScreenLoader: false
            };
        }
        case Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM: {
            return {
                ...state,
                campaignIdOfThisItem: null
            };
        }
        case Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE: {
            return {
                ...state,
                campaignIdOfThisItem: action.payload
            };
        }
        case Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM: {
            return {
                ...state,
                campaignIdOfThisItem: null
            };
        }

        case Constants.REQUEST_TEXT_GENERATE: {
            return {
                ...state,
                generatedEmailData: null
            };
        }
        case Constants.SUCCESS_TEXT_GENERATE: {
            return {
                ...state,
                generatedEmailData: action.apiResponse.choices[0].text
            };
        }
        case Constants.FAILED_TEXT_GENERATE: {
            return {
                ...state,
                generatedEmailData: action.apiResponse
            };
        }
        case Constants.Loader1: {
            return {
                ...state,
                loader1: action.payload
            };
        }
        case Constants.GET_PROFILE: {
            return {
                ...state,
                profile: action.payload
            };
        }

        default:
            return state;
    }
};
