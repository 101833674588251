import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
	BELOW_THE_HEADER,
	COLLAPSED_DRAWER,
	FIXED_DRAWER,
	HORIZONTAL_NAVIGATION,
	INSIDE_THE_HEADER,
} from "constants/ActionTypes";
import SearchBox from "components/SearchBox";
// import MailNotification from "../MailNotification/index";
// import AppNotification from "../AppNotification/index";
// import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav,startUserTour } from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import Menu from "components/TopNav/Menu";
import UserInfoPopup from "components/UserInfo/UserInfoPopup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NearMeIcon from '@material-ui/icons/NearMe';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import schedulerLogo from '../../assets/images/shedular.png'
import { NotificationManager, NotificationContainer } from 'react-notifications';
import CustomDialog from './../../app/routes/customComponents/dialog/customDialog';
import { DialogContent } from "@material-ui/core";

class Header extends React.Component {
	componentWillUnmount() {
		this.props.switchLanguage({
			languageId: "english",
			locale: "en",
			name: "English",
			icon: "gb"
		  }, false)
	}
	onAppNotificationSelect = () => {
		this.setState({
			appNotification: !this.state.appNotification,
		});
	};
	onMailNotificationSelect = () => {
		this.setState({
			mailNotification: !this.state.mailNotification,
		});
	};
	onLangSwitcherSelect = (event) => {
		this.setState({
			langSwitcher: !this.state.langSwitcher,
			anchorEl: event.currentTarget,
		});
	};
	onSearchBoxSelect = () => {
		this.setState({
			searchBox: !this.state.searchBox,
		});
	};
	onAppsSelect = () => {
		this.setState({
			apps: !this.state.apps,
		});
	};
	onUserInfoSelect = () => {
		this.setState({
			userInfo: !this.state.userInfo,
		});
	};
	handleRequestClose = () => {
		this.setState({
			langSwitcher: false,
			userInfo: false,
			mailNotification: false,
			appNotification: false,
			searchBox: false,
			apps: false,
		});
	};
	onToggleCollapsedNav = (e) => {
		const val = !this.props.navCollapsed;
		this.props.toggleCollapsedNav(val);
	};

	startUserTour = () =>{
		this.props.startUserTour()
	}

	// Admin Panels
	Apps = () => {
		return (
			<ul className="jr-list jr-list-half">
				{/*<li className="jr-list-item">*/}
				{/*	<Link className="jr-list-link" to="/app/calendar/basic">*/}
				{/*		<i className="zmdi zmdi-eye zmdi-hc-fw" />*/}
				{/*		<span className="jr-list-text">*/}
				{/*			<IntlMessages id="_ADMIN" />*/}
				{/*		</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}

				<li className="jr-list-item">
					<Link className="jr-list-link" to="/app/listing/translations">
						<i className="zmdi zmdi-translate zmdi-hc-fw" />
						<span className="jr-list-text">
							<IntlMessages id="_TRANSLATE" />
						</span>
					</Link>
				</li>

				<li className="jr-list-item">
					<Link className="jr-list-link" to="/app/mail">
						<i className="zmdi zmdi-pin-help zmdi-hc-fw" />
						<span className="jr-list-text">
							<IntlMessages id="_ACCESS_LOG" />
						</span>
					</Link>
				</li>

				{/*<li className="jr-list-item">*/}
				{/*	<Link className="jr-list-link" to="/app/chat">*/}
				{/*		<i className="zmdi zmdi-ungroup zmdi-hc-fw" />*/}
				{/*		<span className="jr-list-text">*/}
				{/*			<IntlMessages id="_PORTAL" />*/}
				{/*		</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}
				{/*<li className="jr-list-item">*/}
				{/*	<Link className="jr-list-link" to="/app/listing/processes">*/}
				{/*		<i className="zmdi zmdi-time-restore zmdi-hc-fw mr-1" />*/}
				{/*		<span className="jr-list-text">*/}
				{/*			<IntlMessages id="_SYSTEM_LOGS" />*/}
				{/*		</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}
				{/*<li className="jr-list-item">*/}
				{/*	<Link className="jr-list-link" to="/app/contact">*/}
				{/*		<i className="zmdi zmdi-accounts-outline zmdi-hc-fw" />*/}
				{/*		<span className="jr-list-text">*/}
				{/*			<IntlMessages id="_CONNECTED_ACCOUNT" />*/}
				{/*		</span>*/}
				{/*	</Link>*/}
				{/*</li>*/}
			</ul>
		);
	};

	constructor() {
		super();
		this.state = {
			anchorEl: undefined,
			anchorElTS:null,
			searchBox: false,
			searchText: "",
			mailNotification: false,
			userInfo: false,
			langSwitcher: false,
			appNotification: false,
			isSchedulerDialogOpen: false,
		};
	}

	updateSearchText(evt) {
		this.setState({
			searchText: evt.target.value,
		});
	}
	handleSchedulerClick = (e, url) =>{
		e.preventDefault();
		e.stopPropagation();

		if(url){
			const search = url.split("/").at(-1)
			const urlParams = new URLSearchParams(search);
			const apiKeyParam = urlParams.get("api")
			if(apiKeyParam){
				window.open(url, '_blank');
			}else{
				this.setState({isSchedulerDialogOpen: true})
			}
		}else{
			NotificationManager.info("No valid URL.");
		}
	}

	handleSchedulerDialogClose = () => {
		this.setState({isSchedulerDialogOpen: false})
	}

	handleSchedulerDialogOk = () => {
		this.props.history.push("/app/profile",{tabValue:6});
		this.setState({isSchedulerDialogOpen: false})
	}

	render() {
		const {
			drawerType,
			locale,
			navigationStyle,
			horizontalNavPosition,
			location,
			isTourGuidExist,
			schedulerUrl
		} = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? "d-block d-xl-none"
			: drawerType.includes(COLLAPSED_DRAWER)
			? "d-block"
			: "d-none";

		// const path = location.pathname;
		// const isFormEdit = path.indexOf("form/edit/");

		return (
			<AppBar
				className={`app-main-header ${
					navigationStyle === HORIZONTAL_NAVIGATION &&
					horizontalNavPosition === BELOW_THE_HEADER
						? "app-main-header-top"
						: ""
				}`}
			>
				<Toolbar className="app-toolbar" disableGutters={false}>
					{navigationStyle === HORIZONTAL_NAVIGATION ? (
						<div
							className="d-block d-md-none pointer mr-3"
							onClick={this.onToggleCollapsedNav}
						>
							<span className="jr-menu-icon menu-for-tour">
								<span className="menu-icon" />
							</span>
						</div>
					) : (
						<IconButton
							className={`jr-menu-icon mr-3 ${drawerStyle}`}
							aria-label="Menu"
							onClick={this.onToggleCollapsedNav}
						>
							<span className="menu-icon" />
						</IconButton>
					)}

					<Link className="app-logo mr-2 d-none d-sm-block" to="/app/home">
						<img
							src={require("assets/images/logo.png")}
							alt="Bizzmail"
							title="Bizzmail"
						/>
					</Link>
					<div>
						{/*<MuiMenu*/}
						{/*	id="simple-menu"*/}
						{/*	anchorEl={this.state.anchorElTS}*/}
						{/*	// keepMounted*/}
						{/*	open={Boolean(this.state.anchorElTS)}*/}
						{/*	onClose={this.handleCloseMenu}*/}
						{/*>*/}
						{/*	<MenuItem onClick={this.handleSelectMenuItem}>Profile</MenuItem>*/}
						{/*	<MenuItem onClick={this.handleSelectMenuItem}>My account</MenuItem>*/}
						{/*	<MenuItem onClick={this.handleSelectMenuItem}>Logout</MenuItem>*/}
						{/*</MuiMenu>*/}
					</div>
					
					<div className="ml-auto">
						<a className="scheduler-container " 
							href={schedulerUrl} 
							// target={"_blank"}
							onClick={(e)=>this.handleSchedulerClick(e, schedulerUrl)}
							>
							<img src={schedulerLogo} alt="scheduler"/>
						<p>
							<IntlMessages id="_SCHEDULER"/>
						</p>
						</a>
					</div>

					{/* {isFormEdit > 0 ? (
						<Tooltip title="Go to all Forms">
							<IconButton
								onClick={() => {
									window.history.back();
								}}
							>
								<ArrowBackIcon style={{ color: "white" }} />
							</IconButton>
						</Tooltip>
					) : (
						""
					)} */}
					{/* <SearchBox
            styleName="d-none d-lg-block"
            placeholder=""
            onChange={this.updateSearchText.bind(this)}
            value={this.state.searchText}
          /> */}
					{navigationStyle === HORIZONTAL_NAVIGATION &&
						horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

					{/*<ul className="header-notifications list-inline ml-auto">*/}
					<ul className="header-notifications">
						<li className="list-inline-item">
							<Dropdown
								className="quick-menu app-notification"
								isOpen={this.state.apps}
								toggle={this.onAppsSelect.bind(this)}
							>
								<DropdownToggle
									className="d-inline-block"
									tag="span"
									data-toggle="dropdown"
								>
									<span className="app-notification-menu">
										<i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
										<span>Admin Options</span>
									</span>
								</DropdownToggle>

								<DropdownMenu style={{ width: "250px" }}>{this.Apps()}</DropdownMenu>
							</Dropdown>
						</li>

						<li className="d-inline-block d-lg-none list-inline-item">
							<Dropdown
								className="quick-menu nav-searchbox"
								isOpen={this.state.searchBox}
								toggle={this.onSearchBoxSelect.bind(this)}
							>
								<DropdownToggle
									className="d-inline-block"
									tag="span"
									data-toggle="dropdown"
								>
									<IconButton className="icon-btn">
										<i className="zmdi zmdi-search zmdi-hc-fw" />
									</IconButton>
								</DropdownToggle>

								<DropdownMenu right className="p-0">
									<SearchBox
										styleName="search-dropdown"
										placeholder=""
										onChange={this.updateSearchText.bind(this)}
										value={this.state.searchText}
									/>
								</DropdownMenu>
							</Dropdown>
						</li>

						<li className="list-inline-item">
							<Dropdown
								className="quick-menu"
								isOpen={this.state.langSwitcher}
								toggle={this.onLangSwitcherSelect.bind(this)}
							>
								<DropdownToggle
									className="d-inline-block"
									tag="span"
									data-toggle="dropdown"
								>
									<IconButton className="icon-btn">
										<i className={`flag flag-24 flag-${locale.icon}`} />
									</IconButton>
								</DropdownToggle>

								<DropdownMenu right className="w-50">
									<LanguageSwitcher
										switchLanguage={this.props.switchLanguage}
										handleRequestClose={this.handleRequestClose}
										resetLanguage={this.props.resetLanguage}
									/>
								</DropdownMenu>
							</Dropdown>
						</li>
						<li className="list-inline-item">
							{/*{isTourGuidExist === true?*/}
							{/*	<IconButton onClick={this.startUserTour}>*/}
							{/*		<FlightTakeoffIcon style={{color:"#fff"}}/>*/}
							{/*	</IconButton>*/}
							{/*	:*/}
							{/*	<div></div>*/}
							{/*}*/}
							<Tooltip title={<IntlMessages id="_TOUR_GUIDE" />}>
								<IconButton edge={"start"} onClick={this.startUserTour}>
									<FlightTakeoffIcon style={{color:"#fff"}}/>
								</IconButton>
							</Tooltip>

						</li>

						{/* <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.appNotification}
                toggle={this.onAppNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center"
                    heading={<IntlMessages id="appNotification.title" />}
                  />
                  <AppNotification />
                </DropdownMenu>
              </Dropdown>
            </li>

            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.mailNotification}
                toggle={this.onMailNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center"
                    heading={<IntlMessages id="mailNotification.title" />}
                  />
                  <MailNotification />
                </DropdownMenu>
              </Dropdown>
            </li> */}

						{navigationStyle === HORIZONTAL_NAVIGATION && (
							<li className="list-inline-item user-nav">
								<Dropdown
									className="quick-menu"
									isOpen={this.state.userInfo}
									toggle={this.onUserInfoSelect.bind(this)}
								>
									<DropdownToggle
										className="d-inline-block"
										tag="span"
										data-toggle="dropdown"
									>
										<IconButton className="icon-btn size-30">
											<Avatar
												alt="..."
												src={"https://via.placeholder.com/150x150"}
												className="size-30"
											/>
										</IconButton>
									</DropdownToggle>

									<DropdownMenu right>
										<UserInfoPopup />
									</DropdownMenu>
								</Dropdown>
							</li>
						)}
					</ul>

					<div className="ellipse-shape"></div>

					<CustomDialog
						open={this.state.isSchedulerDialogOpen}
						handleClose={this.handleSchedulerDialogClose}
						handleOk={this.handleSchedulerDialogOk}
						dialogContent={false}
						titleTextKey={"_ECLICKSPRO_SCHEDULER_DIALOG_TITLE"}
						maxWidth={"xs"}
						actionButtons={['ok', 'close']}
					>
						<DialogContent>
							<IntlMessages id="_ECLICKSPRO_SCHEDULER_DIALOG_CONTENT"/>
						</DialogContent>
					</CustomDialog>
				</Toolbar>
				<NotificationContainer />
			</AppBar>
		);
	}
}

const mapStateToProps = ({ settings, home }) => {
	const {
		drawerType,
		locale,
		navigationStyle,
		horizontalNavPosition,
		isTourGuidExist
	} = settings;
	const {schedulerUrl} = home;
	return { drawerType, locale, navigationStyle, horizontalNavPosition,isTourGuidExist, schedulerUrl };
};

export default withRouter(
	connect(mapStateToProps, { toggleCollapsedNav, switchLanguage,startUserTour })(Header)
);
