import React,{useState,useEffect,useContext} from "react";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../actions/Profile";

//material ui
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IntlMessages from "../../../../util/IntlMessages";
import PackageDetailCard from "./component/PackageDetailCard";
import ContainerHeader from "../../../../components/ContainerHeader";
import { eclicksProColors } from "../../../../common/functions/EproCommonFunctions";
import CustomDialog, { dialogStyles } from "../../customComponents/dialog/customDialog";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from "@material-ui/core/DialogContent";
import { TextField } from "@material-ui/core";
import CircularLoader from "../../customComponents/loader/CircularLoader";
import OrderForm from "./component/OrderForm";
import { NotificationContainer, NotificationManager } from "react-notifications";

//others
import config from "../../../../config";
import { getAuthData } from "../../../../common/helpers/AuthHelper";
import {SettingContext} from "../../../../context/SettingContext";

const useStyles = makeStyles({
    root:{},
    packagesWrapper:{
        marginTop:"50px"
    },
    packageSelector:{
       // height:"200px",
        padding:"15px",
        textAlign:"center"
    },
    packageSelectorWrapper:{
        marginTop:"38px"
    }
})

// const marks = [{ value: 0, label: '0°C', }, { value: 20, label: '20°C', }, { value: 37, label: '37°C', }, { value: 100, label: '100°C', },];
const packageList = [
    {name:"light"},
    {name:"plus"},
    {name:"professional"}
]

const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const CustomPaper = withStyles({
    root:{
        border:`1px solid ${eclicksProColors.green}`
    }
})(Paper)

const DurationButton = withStyles({
    root:{
        minWidth:"85px"
    }
})(Button)



function ProductUpdate(props) {
    const classes = useStyles();
    const {match,profileData} = props

    //states
    const [selectedContacts,setSelectedContacts] = useState(0)
    const [selectedDuration,setDuration] = useState("monthly")
    const [fees,setFees] = useState([])
    const [selectedPackage,setSelectedPackage] = useState([])
    const [sliderValues,setSliderValues] = useState([])
    const [sliderMaxValue,setSliderMaxValue] = useState(0)
    const [sliderMinValue,setSliderMinValue] = useState(0)
    const [orderOpen,setOpenOrder] = useState(false)
    const [companyInfo,setCompanyInfo] = useState(null)

    const settingContext = useContext(SettingContext)
    let settings = null
    if(settingContext){
        settings = JSON.parse(settingContext.settings.data)
    }

    useEffect(()=>{
        props.getPackageSubscriptionFees()
        //props.getCompanyInfo()
    },[])

    useEffect(() => {
        setSliderValues(getSliderValues(profileData.subscriptionFees).values)
        setSliderMaxValue(getSliderValues(profileData.subscriptionFees).maxValue)
        setSelectedContacts(getSliderValues(profileData.subscriptionFees).minValue)
        let pack = getPackage(profileData.subscriptionFees,getSliderValues(profileData.subscriptionFees).minValue)
        if(pack){
            setSelectedPackage(pack)
        }
        setFees(profileData.subscriptionFees)

    },[profileData.subscriptionFees])

    useEffect(() =>{
        setCompanyInfo(profileData.companyInfo)
    },[profileData.companyInfo])

    //for notification
    useEffect(()=>{
        if(profileData.profileApiResponse){
            NotificationManager[profileData.profileApiResponse.alertType](profileData.profileApiResponse.message)
            props.resetResponse()
        }
    },[profileData.profileApiResponse])




    function getSliderValues(fees = []){
        let values = []
        let minValue = fees.length > 0? parseInt(fees[0].contacts): 0;
        let maxValue = 0
        for(let i = 0;i <fees.length;i++){
            //set minValue
            if(minValue > parseInt(fees[i].contacts)){
                minValue = parseInt(fees[i].contacts)
            }

            //set maxValue
            if(maxValue < parseInt(fees[i].contacts)){
                maxValue = parseInt(fees[i].contacts)
            }

            values.push({
                value:fees[i].contacts,
                label:""
            })
        }
        maxValue = maxValue
        return {
            values,
            minValue,
            maxValue
        };
    }
    
    function getPackage(fees = [],contact) {
        let pack = fees.find(a =>a.contacts === contact.toString())
        return pack
    }

    function handleChangeSlider(event,newValue) {
        setSelectedContacts(newValue)
        let p = getPackage(fees,newValue)
        if(p){
            setSelectedPackage(p)
        }
    }
    function handleDuration(event) {
        let dur = event.currentTarget.id
        setDuration(dur)
    }
    function openOrder(type,pId,dur) {
        //props.getCompanyInfo()
        //setOpenOrder(true)
        let user = getAuthData().user
        let url = `${config.api.url}show/subcriptionPayment?user_id=${user.uid}&subcription_id=${pId}&package_type=${type}&pay_per=${dur}`
        window.location.assign(url)
    }
    function handleClose(){
        setOpenOrder(false)
    }
    
    function handleSaveCompany(companyName,addressLineOne,addressLineTwo,zipcode,city,country,notes,packType) {
        props.updateCompanyInfo(companyName,addressLineOne,addressLineTwo,zipcode,city,country,notes,selectedPackage.id,selectedDuration,packType)
        handleClose()
    }

    return(
        <div className={"app-wrapper"}>
            <ContainerHeader
                title={<IntlMessages id="_ECLICKSPRO_SUBSCRIPTIONS_AND_PAYMENTS" />}
                match={match}
            />
            <Container>
                <div className={classes.packagesWrapper}>
                    <Grid container>
                        <Grid item md={2}>
                            <div className={classes.packageSelectorWrapper}></div>
                            <CustomPaper elevation={3}>
                                <div className={classes.packageSelector}>
                                    <p>How many contacts do you have?</p>
                                    <div>
                                        <h3>{selectedContacts}</h3>
                                    </div>
                                    <div>
                                        <PrettoSlider
                                            marks={sliderValues}
                                            value={selectedContacts}
                                            onChange={handleChangeSlider}
                                            aria-labelledby="input-slider"
                                            step={null}
                                            min={sliderMinValue}
                                            max={sliderMaxValue+100}
                                        />
                                    </div>
                                    <div>
                                        <Divider/>
                                    </div>
                                    <div>
                                        <p><IntlMessages id="_ECLICKSPRO_PAY_PER"></IntlMessages></p>
                                    </div>
                                    <div style={{margin:"10px 0 10px 0"}}>
                                        <ButtonGroup>
                                            <DurationButton color={selectedDuration === 'monthly' ? 'primary' : 'info'} id={'monthly'} onClick={handleDuration}>
                                                <IntlMessages id="_MONTH"></IntlMessages>
                                            </DurationButton>
                                            <DurationButton color={selectedDuration === 'yearly' ? 'primary' : 'info'} id={'yearly'} onClick={handleDuration}>
                                                <IntlMessages id="_YEAR"></IntlMessages>
                                            </DurationButton>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </CustomPaper>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={9}>
                            <div>
                                <Grid container spacing={5}>
                                    {packageList.map((p,index) =>{
                                        return(
                                            <Grid key={index} item md={4}>
                                                <PackageDetailCard
                                                    name={p.name}
                                                    pack={selectedPackage}
                                                    duration={selectedDuration}
                                                    openOrder={openOrder}
                                                    settings={settings}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>

            {/*dialog*/}
            <CustomDialog
                open={orderOpen}
                dialogContent={false}
                dialogAction={false}
                handleClose={handleClose}
                titleTextKey={"_ECLICKSPRO_ORDER"}
                maxWidth={"sm"}
            >
                {profileData.companyInfoLoader?
                    <div>
                        <CircularLoader/>
                    </div>
                    :
                    <React.Fragment>
                        <OrderForm
                            info={companyInfo}
                            handleSave={handleSaveCompany}
                            handleClose={handleClose}
                        />
                    </React.Fragment>

                }
            </CustomDialog>
            <NotificationContainer />
        </div>
    )
}
const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(ProductUpdate);
