import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { userSignIn } from "actions/Auth";
import { fetchTrashView } from "actions/TrashView";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import IntlMessages from "util/IntlMessages";
import EnhancedTable from "./components/EnhancedTable";
import config from "config";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";


class TrashView extends React.Component {
  state = {
    searchText: "",
    itemPerPage: "10",
    pageNumber: 1,
    searchFlag: false,
    selected: null,
    flag: false
  };
  componentWillMount() {
    const { authUser, match } = this.props;
    const { searchText, itemPerPage, pageNumber } = this.state;
    const params = {
      token: authUser,
      value: match.params.menu,
      search: searchText,
      itemPerPage: itemPerPage,
      pageNumber: pageNumber
    };
    this.props.fetchTrashView(params);
  }

  fetchItemsPerPage = value => {
    // console.log("Item per page value-> ", value);
    this.setState({ itemPerPage: value, flag: true });
  };

  getItemsId = value => {
    const { selected } = this.state;
    if (selected !== value) {
      this.setState({ selected: value });
    }
  };

  untrashItem = () => {
    const { authUser, params } = this.props;
    const { selected } = this.state;
    if (!selected || selected.length !== 0) {
      // console.log("selected id->", selected);
      fetch(`${config.api.url}/api/${params.value}/trash_data`, {
        method: "POST",
        headers: {
          Authorization: authUser,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ids: selected,
          un_trash: "1"
        })
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === "success") {
            window.location.reload();
          }
        })
        .catch(error => console.error(error));
    }else{
      NotificationManager.error("Please select an item!")
    }
  };

  getPageNumber = value => {
    // console.log("getPageNumber ->", value);
    this.setState({ pageNumber: value, flag: true });
  };

  render() {
    const { match, history, loader, data, authUser, params } = this.props;
    const {
      searchText,
      searchFlag,
      itemPerPage,
      pageNumber,
      flag
    } = this.state;
    // console.log("this.props from trash view ->", this.props);

    if ((params && !(params.value === match.params.menu)) || flag) {
      const newParams = {
        token: authUser,
        value: match.params.menu,
        search: searchText,
        itemPerPage: itemPerPage,
        pageNumber: pageNumber
      };
      this.setState({ flag: false });
      this.props.fetchTrashView(newParams);
    }

    const title = <IntlMessages id={"_" + match.params.menu.toUpperCase()} />;
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1>
                <IntlMessages id="_TRASH_VIEW" /> :{" "}
                <span style={{ color: "#4caf50" }}>
                  {match.params.menu.toUpperCase()}
                </span>
                <span style={{ marginLeft: "20px" }}>
                  <Tooltip title="Go Back">
                    <IconButton
                      aria-label="Go Back"
                      style={{ border: "1px solid rgb(33, 150, 243)" }}
                      onClick={() => history.goBack()}
                    >
                      <ArrowBackIcon
                        style={{ color: "rgb(33, 150, 243)" }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </span>
                <span style={{ marginLeft: "20px" }}>
                  <Tooltip title="Undo Your Trash Items">
                    <IconButton
                      aria-label="Untrash"
                      style={{ border: "1px solid #4caf50" }}
                      onClick={this.untrashItem}
                    >
                      <UndoOutlinedIcon
                       
                        style={{ color: "#4caf50" }}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </span>
              </h1>
            </div>
            <div className="col-md-6 col-12">
              <div style={{ position: "relative" }}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {(loader && (
                <div className="loader-view">
                  <CircularProgress />
                </div>
              )) || (
                <EnhancedTable
                  title={title}
                  column={data.listing_field}
                  rows={data.data}
                  token={authUser}
                  menu={params.value}
                  searchValue={this.searchValue}
                  fetchItemsPerPage={this.fetchItemsPerPage}
                  flag={searchFlag}
                  tableName={params.value}
                  itemPerPage={itemPerPage}
                  totalItems={data.total_item}
                  getPageNumber={this.getPageNumber}
                  pageNumber={pageNumber}
                  searchText={searchText}
                  getItemsId={this.getItemsId}
                />
              )}
            </div>
          </div>
          <NotificationContainer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, trashView }) => {
  // console.log("auth->", auth);
  // console.log("editview->", editView);
  const { authUser } = auth;
  const { loader, params, data } = trashView;
  return { authUser, loader, params, data };
};

export default connect(mapStateToProps, {
  userSignIn,
  fetchTrashView
})(TrashView);
