export const getResponseForNotificaion = (response, customResponse) => {
    if (customResponse === undefined) {
        customResponse = null
    }
    let apiResponse = {
        "status": "",
        "message": "",
        "status_code": "",
        "alertType": "error",
    }
    if (customResponse === null) {
        if (response !== undefined && response !== null) {
            apiResponse.status = response.data.status
            apiResponse.message = response.data.message
            apiResponse.status_code = response.data.status_code
            apiResponse.alertType = response.data.status === "success" ? "info" : "error"
        }
    } else {
        apiResponse.status = customResponse.status === undefined ? "error" : customResponse.status
        apiResponse.message = customResponse.message === undefined ? "unable to fetch data" : customResponse.message
        apiResponse.status_code = customResponse.status_code === undefined ? "404" : customResponse.status_code
        apiResponse.alertType = customResponse.alertType === undefined ? "error" : customResponse.alertType
    }
    return apiResponse
}


export const validateEmail = (email) => {
    //let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (mailformat.test(email)) {
        return true
    } else {
        return false
    }
}

export const convertStringToCurrency = (price, currency, decimalPointInNumber, separator, decimalPoint, isApiValue = false) => {

    //let {separator,decimalPoint} = this.state

    //let re = /+e.target.value+/
    // let re = new RegExp(e.target.value);
    // let b = re

    // let regE = /[0-9]/
    // let ttt = regE.test(price)

    let euroSign = "\u20AC";//Unicode Character 'EURO SIGN'
    let dollarSign = "\u0024";//Unicode Character 'DOLLAR SIGN'

    let presentCurrencySign = null
    let outPutCurrencySymbol = null

    let valueStr = ""

    if (price === undefined) {
        return {
            formattedPrice: "",
            decimalPrice: ""
        }
    }
    price = price.toString()

    if(price.includes(euroSign) || price.includes(dollarSign)){
        if(price.includes(euroSign)){
            presentCurrencySign = euroSign
        }else{
            presentCurrencySign = dollarSign
        }

        let indexOfSign = price.indexOf(presentCurrencySign)
        if(indexOfSign === 0){
            price = price.split(presentCurrencySign)[1]
        }
    }

    let numberArr = ['0','1','2','3','4','5','6','7','8','9',',','.']

    for(let i = 0; i < price.length;i++){
        if(numberArr.includes(price[i])){
            valueStr = valueStr+price[i]
        }
    }


    // let b = "\u20AC"+"100030"
    // let cc = b.includes(aa)
    // let dd = b.indexOf(aa)
    // let eee = b.split(aa)

    if(currency === undefined || currency === null){
        currency = "EUR"
    }

    if (separator === undefined && decimalPoint === undefined) {
        if (currency === "EUR") {
            separator = "."
            decimalPoint = ","
            outPutCurrencySymbol = euroSign
        } else {
            separator = ","
            decimalPoint = "."
            outPutCurrencySymbol = dollarSign
        }
    }


    let splitOn = ""
    if(isApiValue){
        splitOn = "."
    }else{
        if (decimalPointInNumber === null || decimalPointInNumber === undefined) {
            splitOn = decimalPoint
        } else {
            splitOn = decimalPointInNumber
        }
    }



    let leftDecimalPart = valueStr.split(splitOn)[0]
    let rightDecimalPart = valueStr.split(splitOn)[1]

    // if(rightDecimalPart === undefined){
    //     rightDecimalPart = ""
    // }



    let isNumberr = parseInt(rightDecimalPart,10)

    let reValueStr = reverseString(leftDecimalPart)
    reValueStr = removeSeparator(reValueStr)

    let leftPartInInt = parseInt(reValueStr,10)

    //let cleanRightPart = removeSeparator(rightDecimalPart)
    //let rightPartInInt = parseInt(cleanRightPart,10)
    let isValidLeftPart = true
    let isValidRightPart = true



    // if(!isValidRightPart || !isValidLeftPart){
    //     return {
    //         formattedPrice: "",
    //         decimalPrice: ""
    //     }
    // }


    let length = reValueStr.length
    let returnValue = ""
    let sepPosition = 2



    for (let i = 0; i < length; i++) {
        let v = reValueStr[i]
        if (i === sepPosition) {
            if (v === separator) {
                returnValue = returnValue + v
            } else {
                returnValue = returnValue + v + separator
            }

            sepPosition = sepPosition + 2
        } else {
            returnValue = returnValue + v
        }

    }

    if (returnValue[returnValue.length - 1] === separator) {
        returnValue = returnValue.slice(0, -1)
    }


    let finalValue = reverseString(returnValue)
    if (rightDecimalPart === undefined) {
        finalValue = outPutCurrencySymbol+finalValue
    }else{
        finalValue = outPutCurrencySymbol+finalValue + decimalPoint + rightDecimalPart
    }

    let decimalValue = ""
    if (rightDecimalPart === undefined) {
        decimalValue = removeSeparator(leftDecimalPart)
    } else {
        decimalValue = removeSeparator(leftDecimalPart) + "." + rightDecimalPart
    }
    function reverseString(str) {
        // Step 1. Use the split() method to return a new array
        let splitString = str.split(""); // var splitString = "hello".split("");
        // ["h", "e", "l", "l", "o"]

        // Step 2. Use the reverse() method to reverse the new created array
        let reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
        // ["o", "l", "l", "e", "h"]

        // Step 3. Use the join() method to join all elements of the array into a string
        let joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
        // "olleh"

        //Step 4. Return the reversed string
        return joinArray; // "olleh"
    }

    function removeSeparator(str) {
        let splitString = str.split(separator);
        let joinArray = splitString.join("");
        return joinArray
    }

    return {
        formattedPrice: finalValue,
        decimalPrice: decimalValue,
        currencySymbol: outPutCurrencySymbol
    }
}

export const getArrayFromObject = (obj) =>{
    let arr = []
    let objKeys = Object.keys(obj)

    for(let i = 0;i < objKeys.length;i++){
        arr.push(obj[objKeys[i]])
    }

    return arr
}

export const reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    let splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    let reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    let joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
}

export const getBase64 =async file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};


export const eclicksProColors = {
    green:"#4CAF50",
    red:"#D9001B",
    lightGray:"#f2f2f2",
    citrine:"#E0CA3C",
    blue1:"#00509d",
    textBlack:"#000000",
    textGray:"#696F71"
}


//replace char
export function replaceChar(replaceChar, replaceWith) {

    return ""
}