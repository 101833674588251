import React from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "../../../../util/IntlMessages";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../button/CustomButton";

//for test color scheme
import { ThemeProvider } from "@material-ui/styles";
import greenTheme from "../../../../containers/themes/greenTheme";
//for test color scheme

const useStyles = makeStyles({
    dialogContentText: {
        //backgroundColor: 'red',
        //color: props => props.color,
        marginTop: "23px"
    },
    dialogAction: {
        padding: "8px 22px 22px 24px", //"6px 22px 8px 24px"
        marginRight: "2px"
    },
    closeIconWrapper: {
        marginRight: "-7px"
    }
});

const CustomDialog = props => {
    const classes = useStyles(props);
    let fullWidth = props.fullWidth === undefined ? true : props.fullWidth;
    let maxWidth = props.maxWidth === undefined ? "md" : props.maxWidth;
    let dialogTitle = props.dialogTitle === false ? props.dialogTitle : true;
    let customTitleText = props.customTitleText === true ? true : false;
    let titleTextKey =
        props.titleTextKey === undefined ? "_ON_TEXT" : props.titleTextKey;

    let appBarInTop = props.appBarInTop === false ? false : true;

    // content
    let dialogContent = props.dialogContent === false ? false : true;
    let contentTextKey =
        props.contentTextKey === undefined
            ? "_NO_LANGUAGE_KEY_FOR_TEXT_CONTENT"
            : props.contentTextKey;

    //actions
    let dialogAction = props.dialogAction === false ? false : true;
    let actionButtons =
        props.actionButtons === undefined ? [] : props.actionButtons;

    return (
        <ThemeProvider theme={createMuiTheme(greenTheme)}>
            <Dialog
                open={props.open}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={props.onClose}
                aria-labelledby="custom-dailog-title"
            >
                {dialogTitle && (
                    <>
                        {appBarInTop ? (
                            <>
                                {customTitleText ? (
                                    <AppBar position="static">
                                        <Toolbar
                                            variant="dense"
                                            style={{ minHeight: "60px" }}
                                        >
                                            <Grid container>
                                                <Grid item md={11}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                    >
                                                        {props.titleText}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={1}
                                                    justify="flex-end"
                                                >
                                                    {/*this.handleDelete*/}
                                                    <div
                                                        className={
                                                            classes.closeIconWrapper
                                                        }
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            className="actionDialogTitleButton"
                                                            onClick={
                                                                props.handleClose
                                                            }
                                                        >
                                                            <CloseIcon
                                                                style={{
                                                                    color:
                                                                        "white"
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>
                                ) : (
                                    <AppBar position="static">
                                        <Toolbar
                                            variant="dense"
                                            style={{ minHeight: "60px" }}
                                        >
                                            <Grid container>
                                                <Grid item md={11}>
                                                    <Typography
                                                        variant="h6"
                                                        color="inherit"
                                                    >
                                                        <IntlMessages
                                                            id={titleTextKey}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={1}
                                                    justify="flex-end"
                                                >
                                                    {/*this.handleDelete*/}
                                                    <div
                                                        className={
                                                            classes.closeIconWrapper
                                                        }
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            className="actionDialogTitleButton"
                                                            onClick={
                                                                props.handleClose
                                                            }
                                                        >
                                                            <CloseIcon
                                                                style={{
                                                                    color:
                                                                        "white"
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>
                                )}
                            </>
                        ) : (
                            <>
                                {customTitleText ? (
                                    <DialogTitle id="custom-dailog-title">
                                        {props.titleText}
                                    </DialogTitle>
                                ) : (
                                    <DialogTitle id="custom-dailog-title">
                                        <IntlMessages
                                            id={titleTextKey}
                                        ></IntlMessages>
                                    </DialogTitle>
                                )}
                            </>
                        )}
                    </>
                )}
                {props.children}
                {dialogContent && (
                    <DialogContent>
                        <DialogContentText
                            className={classes.dialogContentText}
                        >
                            <IntlMessages id={contentTextKey} />
                        </DialogContentText>
                    </DialogContent>
                )}

                {dialogAction && (
                    <DialogActions className={classes.dialogAction}>
                        {actionButtons.some(a => a === "ok") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleOk}
                            >
                                <IntlMessages id="_OK"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "add") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleAdd}
                            >
                                <IntlMessages id="_ADD"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "save") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleSave}
                            >
                                <IntlMessages id="_SAVE"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "Generate Text") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleSave}
                                disabled={props.btnDisabled}
                            >
                                <IntlMessages id="_GENERATE_TEXT"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "Generate Email") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleSave}
                                disabled={props.btnDisabled}
                            >
                                <IntlMessages id="_GENERATE_EMAIL"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "Copy Text") && (
                            <CustomButton
                                color="green"
                                onClick={props.copyText}
                                id="copyButton"
                            >
                                <IntlMessages id="_COPY_TEXT"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(
                            a => a === "Clear Conversation"
                        ) && (
                            <CustomButton
                                color="green"
                                onClick={props.clearConversation}
                            >
                                <IntlMessages id="_CLEAR_CONVERSATION"></IntlMessages>
                            </CustomButton>
                        )}

                        {actionButtons.some(a => a === "share") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleShare}
                            >
                                <IntlMessages id="_SHARE"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "send") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleSend}
                            >
                                <IntlMessages id="_SEND"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "executeNow") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleExecuteNow}
                            >
                                <IntlMessages id="_EXECUTE_NOW"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "copy") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleCopy}
                            >
                                <IntlMessages id="_COPY"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "yes") && (
                            <CustomButton
                                color="green"
                                onClick={props.handleYes}
                            >
                                <IntlMessages id="_YES"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "reset") && (
                            <CustomButton
                                color="white"
                                onClick={props.handleReset}
                            >
                                <IntlMessages id="_RESET"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "delete") && (
                            <CustomButton
                                color="red"
                                onClick={props.handleDelete}
                            >
                                <IntlMessages id="_DELETE"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "remove") && (
                            <CustomButton
                                color="red"
                                onClick={props.handleRemove}
                            >
                                <IntlMessages id="_REMOVE"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "close") && (
                            <CustomButton
                                color="grey"
                                onClick={props.handleClose}
                            >
                                <IntlMessages id="_CLOSE"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "cancel") && (
                            <CustomButton
                                color="red"
                                onClick={props.handleCancel}
                            >
                                <IntlMessages id="_CANCEL"></IntlMessages>
                            </CustomButton>
                        )}
                        {actionButtons.some(a => a === "no") && (
                            <CustomButton color="red" onClick={props.handleNo}>
                                <IntlMessages id="_NO"></IntlMessages>
                            </CustomButton>
                        )}
                    </DialogActions>
                )}
            </Dialog>
        </ThemeProvider>
    );
};
export default CustomDialog;

//propTypes
//dialogTitle:bool - if true it will show provided title other wise you have to pass in children
//dialogTitleText -
//dialogTitleLanguageKye

//dialogContent:bool
//dialogContentLanguageKey:

//dialogAction:bool
//actionButtons:arrayOfString ["ok",save,"cancel","close"]

export const dialogStyles = {
    dialogContentStyles: {
        marginTop: "23px"
    },
    dialogActionStyles: {
        padding: "6px 22px 22px 24px",
        marginRight: "2px"
    },
    dialogActionStylesForInd: {
        padding: "6px 22px 0px 24px",
        marginRight: "2px"
    }
};
