export const REQUEST_GET_USER_WITH_RELATION = "REQUEST_GET_USER_WITH_RELATION";
export const SUCCESS_GET_USER_WITH_RELATION = "REQUEST_GET_USER_WITH_RELATION";
export const FAILED_GET_USER_WITH_RELATION = "REQUEST_GET_USER_WITH_RELATION";

export const REQUEST_GET_ALL_USERS_BY_ACCOUNT =
    "REQUEST_GET_ALL_USERS_BY_ACCOUNT";
export const SUCCESS_GET_ALL_USERS_BY_ACCOUNT =
    "SUCCESS_GET_ALL_USERS_BY_ACCOUNT";
export const FAILED_GET_ALL_USERS_BY_ACCOUNT =
    "FAILED_GET_ALL_USERS_BY_ACCOUNT";

// common

export const RESET_SUCCESS_STATUS = "RESET_SUCCESS_STATUS";

export const REQUEST_GET_CAMPAIGN_OF_THIS_ITEM =
    "REQUEST_GET_CAMPAIGN_OF_THIS_ITEM";
export const SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM =
    "SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM";
export const FAILED_GET_CAMPAIGN_OF_THIS_ITEM =
    "FAILED_GET_CAMPAIGN_OF_THIS_ITEM";
export const REQUEST_MANUAL_SORT_ITEM = "REQUEST_MANUAL_SORT_ITEM";
export const SUCCESS_MANUAL_SORT_ITEM = "SUCCESS_MANUAL_SORT_ITEM";
export const SUCCESS_MANUAL_SORT_ITEM_FOLDERS =
    "SUCCESS_MANUAL_SORT_ITEM_FOLDERS";
export const FAILED_MANUAL_SORT_ITEM = "FAILED_MANUAL_SORT_ITEM";
//common

//auth
export const REQUEST_SIGN_IN = "REQUEST_SIGN_IN";
export const SUCCESS_SIGN_IN = "SUCCESS_SIGN_IN";
export const FAILED_SIGN_IN = "FAILED_SIGN_IN";

export const REQUEST_CHECK_AUTHORIZATION = "REQUEST_CHECK_AUTHORIZATION";
export const SUCCESS_CHECK_AUTHORIZATION = "SUCCESS_CHECK_AUTHORIZATION";
export const FAILED_CHECK_AUTHORIZATION = "FAILED_CHECK_AUTHORIZATION";

export const RESET_AUTH_API_RESPONSE = "RESET_AUTH_API_RESPONSE";

//home
export const REQUEST_GET_HOME_DATA = "REQUEST_GET_HOME_DATA";
export const SUCCESS_GET_HOME_DATA = "SUCCESS_GET_HOME_DATA";
export const FAILED_GET_HOME_DATA = "FAILED_GET_HOME_DATA";

export const REQUEST_GET_PAYMENT_LIST = "REQUEST_GET_PAYMENT_LIST";
export const SUCCESS_GET_PAYMENT_LIST = "SUCCESS_GET_PAYMENT_LIST";
export const FAILED_GET_PAYMENT_LIST = "FAILED_GET_PAYMENT_LIST";

export const REQUEST_AUTHORIZED_ITEMS_HOME = "REQUEST_AUTHORIZED_ITEMS_HOME";
export const SUCCESS_AUTHORIZED_ITEMS_HOME = "SUCCESS_AUTHORIZED_ITEMS_HOME";
export const FAILED_AUTHORIZED_ITEMS_HOME = "FAILED_AUTHORIZED_ITEMS_HOME";

export const REQUEST_GET_ALL_HOME_PERMISSIONS =
    "REQUEST_GET_ALL_HOME_PERMISSIONS";
export const SUCCESS_GET_ALL_HOME_PERMISSIONS =
    "SUCCESS_GET_ALL_HOME_PERMISSIONS";
export const FAILED_GET_ALL_HOME_PERMISSIONS =
    "FAILED_GET_ALL_HOME_PERMISSIONS";

export const REQUEST_GET_CREDITS_HOME = "REQUEST_GET_CREDITS_HOME";
export const SUCCESS_GET_CREDITS_HOME = "SUCCESS_GET_CREDITS_HOME";
export const FAILED_GET_CREDITS_HOME = "FAILED_GET_CREDITS_HOME";

export const REQUEST_GET_SELECTED_CREDIT_HOME =
    "REQUEST_GET_SELECTED_CREDIT_HOME";
export const SUCCESS_GET_SELECTED_CREDIT_HOME =
    "SUCCESS_GET_SELECTED_CREDIT_HOME";
export const FAILED_GET_SELECTED_CREDIT_HOME =
    "FAILED_GET_SELECTED_CREDIT_HOME";

//email
export const REQUEST_GET_EMAIL_BY_ID = "REQUEST_GET_EMAIL_BY_ID";
export const SUCCESS_GET_EMAIL_BY_ID = "SUCCESS_GET_EMAIL_BY_ID";
export const FAILED_GET_EMAIL_BY_ID = "FAILED_GET_EMAIL_BY_ID";

export const REQUEST_GET_ALL_EMAIL_IN_FOLDER =
    "REQUEST_GET_ALL_EMAIL_IN_FOLDER";
export const SUCCESS_GET_ALL_EMAIL_IN_FOLDER =
    "SUCCESS_GET_ALL_EMAIL_IN_FOLDER";
export const FAILED_GET_ALL_EMAIL_IN_FOLDER = "FAILED_GET_ALL_EMAIL_IN_FOLDER";

export const REQUEST_ADD_FOLDER_IN_EMAIL = "REQUEST_ADD_FOLDER_IN_EMAIL";
export const SUCCESS_ADD_FOLDER_IN_EMAIL = "SUCCESS_ADD_FOLDER_IN_EMAIL";
export const FAILED_ADD_FOLDER_IN_EMAIL = "FAILED_ADD_FOLDER_IN_EMAIL";

export const REQUEST_RENAME_EMAIL_FOLDER = "REQUEST_RENAME_EMAIL_FOLDER";
export const SUCCESS_RENAME_EMAIL_FOLDER = "SUCCESS_RENAME_EMAIL_FOLDER";
export const FAILED_RENAME_EMAIL_FOLDER = "FAILED_RENAME_EMAIL_FOLDER";

export const REQUEST_RENAME_EMAIL = "REQUEST_RENAME_EMAIL";
export const SUCCESS_RENAME_EMAIL = "SUCCESS_RENAME_EMAIL";
export const FAILED_RENAME_EMAIL = "FAILED_RENAME_EMAIL";

export const REQUEST_ADD_EMAIL = "REQUEST_ADD_EMAIL";
export const SUCCESS_ADD_EMAIL = "SUCCESS_ADD_EMAIL";
export const FAILED_ADD_EMAIL = "FAILED_ADD_EMAIL";

export const REQUEST_PUT_EMAIL = "REQUEST_PUT_EMAIL";
export const SUCCESS_PUT_EMAIL = "SUCCESS_PUT_EMAIL";
export const FAILED_PUT_EMAIL = "FAILED_PUT_EMAIL";

export const REQUEST_SEND_EMAIL = "REQUEST_SEND_EMAIL";
export const SUCCESS_SEND_EMAIL = "SUCCESS_SEND_EMAIL";
export const FAILED_SEND_EMAIL = "FAILED_SEND_EMAIL";

export const REQUEST_GET_EMAIL_SUGGESTION = "REQUEST_GET_EMAIL_SUGGESTION";
export const SUCCESS_GET_EMAIL_SUGGESTION = "SUCCESS_GET_EMAIL_SUGGESTION";
export const FAILED_GET_EMAIL_SUGGESTION = "FAILED_GET_EMAIL_SUGGESTION";

export const START_EMAIL_LOADER_DIALOG = "START_EMAIL_LOADER_DIALOG";
export const STOP_EMAIL_LOADER_DIALOG = "STOP_EMAIL_LOADER_DIALOG";

export const REQUEST_GET_ALL_RELATION_FIELDS =
    "REQUEST_GET_ALL_RELATION_FIELDS";
export const SUCCESS_GET_ALL_RELATION_FIELDS =
    "SUCCESS_GET_ALL_RELATION_FIELDS";
export const FAILED_GET_ALL_RELATION_FIELDS = "FAILED_GET_ALL_RELATION_FIELDS";

export const RESET_EMAIL_API_RESPONSE = "RESET_EMAIL_API_RESPONSE";
//email

//form
//getById
export const REQUEST_GET_FROM_BY_ID = "REQUEST_GET_FROM_BY_ID";
export const SUCCESS_GET_FROM_BY_ID = "SUCCESS_GET_FROM_BY_ID";
export const FAILED_GET_FROM_BY_ID = "FAILED_GET_FROM_BY_ID";

//getAll
export const REQUEST_GET_ALL_FORM_IN_FOLDER = "REQUEST_GET_ALL_FORM_IN_FOLDER";
export const SUCCESS_GET_ALL_FORM_IN_FOLDER = "SUCCESS_GET_ALL_FORM_IN_FOLDER";
export const FAILED_GET_ALL_FORM_IN_FOLDER = "FAILED_GET_ALL_FORM_IN_FOLDER";

export const REQUEST_ADD_FOLDER_IN_FORM = "REQUEST_ADD_FOLDER_IN_FORM";
export const SUCCESS_ADD_FOLDER_IN_FORM = "SUCCESS_ADD_FOLDER_IN_FORM";
export const FAILED_ADD_FOLDER_IN_FORM = "FAILED_ADD_FOLDER_IN_FORM";

export const REQUEST_ADD_FORM = "REQUEST_ADD_FORM";
export const SUCCESS_ADD_FORM = "SUCCESS_ADD_FORM";
export const FAILED_ADD_FORM = "FAILED_ADD_FORM";

//put
export const REQUEST_PUT_FORM_DATA = "REQUEST_PUT_FORM_DATA";
export const SUCCESS_PUT_FORM_DATA = "SUCCESS_PUT_FORM_DATA";
export const FAILED_PUT_FORM_DATA = "FAILED_PUT_FORM_DATA";
//rename
export const REQUEST_RENAME_FORM_FOLDER = "REQUEST_RENAME_FORM_FOLDER";
export const SUCCESS_RENAME_FORM_FOLDER = "SUCCESS_RENAME_FORM_FOLDER";
export const FAILED_RENAME_FORM_FOLDER = "FAILED_RENAME_FORM_FOLDER";

export const REQUEST_RENAME_FORM = "REQUEST_RENAME_FORM";
export const SUCCESS_RENAME_FORM = "SUCCESS_RENAME_FORM";
export const FAILED_RENAME_FORM = "FAILED_RENAME_FORM";

export const START_FORM_LOADER_DIALOG = "START_FORM_LOADER_DIALOG";
export const STOP_FORM_LOADER_DIALOG = "STOP_FORM_LOADER_DIALOG";

export const RESET_FORM_API_RESPONSE = "RESET_FORM_API_RESPONSE";
//form

// page
export const REQUEST_GET_PAGE_BY_ID = "REQUEST_GET_PAGE_BY_ID";
export const SUCCESS_GET_PAGE_BY_ID = "SUCCESS_GET_PAGE_BY_ID";
export const FAILED_GET_PAGE_BY_ID = "FAILED_GET_PAGE_BY_ID";

export const REQUEST_GET_ALL_PAGE_IN_FOLDER = "REQUEST_GET_ALL_PAGE_IN_FOLDER";
export const SUCCESS_GET_ALL_PAGE_IN_FOLDER = "SUCCESS_GET_ALL_PAGE_IN_FOLDER";
export const FAILED_GET_ALL_PAGE_IN_FOLDER = "FAILED_GET_ALL_PAGE_IN_FOLDER";

export const REQUEST_ADD_FOLDER_IN_PAGE = "REQUEST_ADD_FOLDER_IN_PAGE";
export const SUCCESS_ADD_FOLDER_IN_PAGE = "SUCCESS_ADD_FOLDER_IN_PAGE";
export const FAILED_ADD_FOLDER_IN_PAGE = "FAILED_ADD_FOLDER_IN_PAGE";

export const REQUEST_ADD_PAGE = "REQUEST_ADD_PAGE";
export const SUCCESS_ADD_PAGE = "SUCCESS_ADD_PAGE";
export const FAILED_ADD_PAGE = "FAILED_ADD_PAGE";

export const REQUEST_PUT_PAGE = "REQUEST_PUT_PAGE";
export const SUCCESS_PUT_PAGE = "SUCCESS_PUT_PAGE";
export const FAILED_PUT_PAGE = "FAILED_PUT_PAGE";

export const REQUEST_SEND_PAGE = "REQUEST_SEND_PAGE";
export const SUCCESS_SEND_PAGE = "SUCCESS_SEND_PAGE";
export const FAILED_SEND_PAGE = "FAILED_SEND_PAGE";

export const REQUEST_RENAME_PAGE_FOLDER = "REQUEST_RENAME_PAGE_FOLDER";
export const SUCCESS_RENAME_PAGE_FOLDER = "SUCCESS_RENAME_PAGE_FOLDER";
export const FAILED_RENAME_PAGE_FOLDER = "FAILED_RENAME_PAGE_FOLDER";

export const REQUEST_RENAME_PAGE = "REQUEST_RENAME_PAGE";
export const SUCCESS_RENAME_PAGE = "SUCCESS_RENAME_PAGE";
export const FAILED_RENAME_PAGE = "FAILED_RENAME_PAGE";

export const START_PAGE_LOADER_DIALOG = "START_PAGE_LOADER_DIALOG";
export const STOP_PAGE_LOADER_DIALOG = "STOP_PAGE_LOADER_DIALOG";

export const RESET_PAGE_API_RESPONSE = "RESET_PAGE_API_RESPONSE";
// page

// letter
export const REQUEST_GET_LETTER_BY_ID = "REQUEST_GET_LETTER_BY_ID";
export const SUCCESS_GET_LETTER_BY_ID = "SUCCESS_GET_LETTER_BY_ID";
export const FAILED_GET_LETTER_BY_ID = "FAILED_GET_LETTER_BY_ID";

export const REQUEST_GET_ALL_LETTER_IN_FOLDER =
    "REQUEST_GET_ALL_LETTER_IN_FOLDER";
export const SUCCESS_GET_ALL_LETTER_IN_FOLDER =
    "SUCCESS_GET_ALL_LETTER_IN_FOLDER";
export const FAILED_GET_ALL_LETTER_IN_FOLDER =
    "FAILED_GET_ALL_LETTER_IN_FOLDER";

export const REQUEST_ADD_FOLDER_IN_LETTER = "REQUEST_ADD_FOLDER_IN_LETTER";
export const SUCCESS_ADD_FOLDER_IN_LETTER = "SUCCESS_ADD_FOLDER_IN_LETTER";
export const FAILED_ADD_FOLDER_IN_LETTER = "FAILED_ADD_FOLDER_IN_LETTER";

export const REQUEST_ADD_LETTER = "REQUEST_ADD_LETTER";
export const SUCCESS_ADD_LETTER = "SUCCESS_ADD_LETTER";
export const FAILED_ADD_LETTER = "FAILED_ADD_LETTER";

export const REQUEST_PUT_LETTER = "REQUEST_PUT_LETTER";
export const SUCCESS_PUT_LETTER = "SUCCESS_PUT_LETTER";
export const FAILED_PUT_LETTER = "FAILED_PUT_LETTER";

export const REQUEST_SEND_LETTER = "REQUEST_SEND_LETTER";
export const SUCCESS_SEND_LETTER = "SUCCESS_SEND_LETTER";
export const FAILED_SEND_LETTER = "FAILED_SEND_LETTER";

export const REQUEST_RENAME_LETTER = "REQUEST_RENAME_LETTER";
export const SUCCESS_RENAME_LETTER = "SUCCESS_RENAME_LETTER";
export const FAILED_RENAME_LETTER = "FAILED_RENAME_LETTER";

export const REQUEST_RENAME_LETTER_FOLDER = "REQUEST_RENAME_LETTER_FOLDER";
export const SUCCESS_RENAME_LETTER_FOLDER = "SUCCESS_RENAME_LETTER_FOLDER";
export const FAILED_RENAME_LETTER_FOLDER = "FAILED_RENAME_LETTER_FOLDER";

export const START_LETTER_LOADER_DIALOG = "START_LETTER_LOADER_DIALOG";
export const STOP_LETTER_LOADER_DIALOG = "STOP_LETTER_LOADER_DIALOG";

export const RESET_LETTER_API_RESPONSE = "RESET_LETTER_API_RESPONSE";
// letter

//campaign
export const REQUEST_GET_CAMPAIGN = "REQUEST_GET_CAMPAIGN";
export const SUCCESS_GET_CAMPAIGN = "SUCCESS_GET_CAMPAIGN";
export const FAILED_GET_CAMPAIGN = "FAILED_GET_CAMPAIGN";

export const REQUEST_GET_ALL_CAMPAIGN_IN_FOLDER =
    "REQUEST_GET_ALL_CAMPAIGN_IN_FOLDER";
export const SUCCESS_GET_ALL_CAMPAIGN_IN_FOLDER =
    "SUCCESS_GET_ALL_CAMPAIGN_IN_FOLDER";
export const FAILED_GET_ALL_CAMPAIGN_IN_FOLDER =
    "FAILED_GET_ALL_CAMPAIGN_IN_FOLDER";

export const REQUEST_RENAME_CAMPAIGN = "REQUEST_RENAME_CAMPAIGN";
export const SUCCESS_RENAME_CAMPAIGN = "SUCCESS_RENAME_CAMPAIGN";
export const FAILED_RENAME_CAMPAIGN = "FAILED_RENAME_CAMPAIGN";

export const REQUEST_RENAME_CAMPAIGN_FOLDER = "REQUEST_RENAME_CAMPAIGN_FOLDER";
export const SUCCESS_RENAME_CAMPAIGN_FOLDER = "SUCCESS_RENAME_CAMPAIGN_FOLDER";
export const FAILED_RENAME_CAMPAIGN_FOLDER = "FAILED_RENAME_CAMPAIGN_FOLDER";

export const REQUEST_GET_USER_PROFILE_DATA_FOR_CAMPAIGN =
    "REQUEST_GET_USER_PROFILE_DATA_FOR_CAMPAIGN";
export const SUCCESS_GET_USER_PROFILE_DATA_FOR_CAMPAIGN =
    "SUCCESS_GET_USER_PROFILE_DATA_FOR_CAMPAIGN";
export const FAILED_GET_USER_PROFILE_DATA_FOR_CAMPAIGN =
    "FAILED_GET_USER_PROFILE_DATA_FOR_CAMPAIGN";

export const REQUEST_CAMPAIGN_GET_TEMPLATE_CATEGORIES =
    "REQUEST_CAMPAIGN_GET_TEMPLATE_CATEGORIES";
export const SUCCESS_CAMPAIGN_GET_TEMPLATE_CATEGORIES =
    "SUCCESS_CAMPAIGN_GET_TEMPLATE_CATEGORIES";
export const FAILED_CAMPAIGN_GET_TEMPLATE_CATEGORIES =
    "FAILED_CAMPAIGN_GET_TEMPLATE_CATEGORIES";

export const REQUEST_GET_CAMPAIGN_TEMPLATE = "REQUEST_GET_CAMPAIGN_TEMPLATE";
export const SUCCESS_GET_CAMPAIGN_TEMPLATE = "SUCCESS_GET_CAMPAIGN_TEMPLATE";
export const FAILED_GET_CAMPAIGN_TEMPLATE = "FAILED_GET_CAMPAIGN_TEMPLATE";

export const REQUEST_GET_SHARE_CAMPAIGN = "REQUEST_GET_SHARE_CAMPAIGN";
export const SUCCESS_GET_SHARE_CAMPAIGN = "SUCCESS_GET_SHARE_CAMPAIGN";
export const FAILED_GET_SHARE_CAMPAIGN = "FAILED_GET_SHARE_CAMPAIGN";

export const REQUEST_DELETE_CAMPAIGN_TEMPLATE =
    "REQUEST_DELETE_CAMPAIGN_TEMPLATE";
export const SUCCESS_DELETE_CAMPAIGN_TEMPLATE =
    "SUCCESS_DELETE_CAMPAIGN_TEMPLATE";
export const FAILED_DELETE_CAMPAIGN_TEMPLATE =
    "FAILED_DELETE_CAMPAIGN_TEMPLATE";

export const REQUEST_EXECUTE_CAMPAIGN_NOW = "REQUEST_EXECUTE_CAMPAIGN_NOW";
export const SUCCESS_EXECUTE_CAMPAIGN_NOW = "SUCCESS_EXECUTE_CAMPAIGN_NOW";
export const FAILED_EXECUTE_CAMPAIGN_NOW = "FAILED_EXECUTE_CAMPAIGN_NOW";

export const RESET_CAMPAIGN_RESPONSE = "RESET_CAMPAIGN_RESPONSE";
export const REQUEST_MANUAL_SORT_CAMPAIGN = "REQUEST_MANUAL_SORT_CAMPAIGN";
export const SUCCESS_MANUAL_SORT_CAMPAIGN = "SUCCESS_MANUAL_SORT_CAMPAIGN";
export const SUCCESS_MANUAL_SORT_CAMPAIGN_FOLDERS =
    "SUCCESS_MANUAL_SORT_CAMPAIGN_FOLDERS";
export const FAILED_MANUAL_SORT_CAMPAIGN = "FAILED_MANUAL_SORT_CAMPAIGN";
//campaign

//group
export const REQUEST_GET_ALL_GROUP_IN_FOLDER =
    "REQUEST_GET_ALL_GROUP_IN_FOLDER";
export const SUCCESS_GET_ALL_GROUP_IN_FOLDER =
    "SUCCESS_GET_ALL_GROUP_IN_FOLDER";
export const FAILED_GET_ALL_GROUP_IN_FOLDER = "FAILED_GET_ALL_GROUP_IN_FOLDER";

export const REQUEST_ADD_FOLDER_IN_GROUP = "REQUEST_ADD_FOLDER_IN_GROUP";
export const SUCCESS_ADD_FOLDER_IN_GROUP = "SUCCESS_ADD_FOLDER_IN_GROUP";
export const FAILED_ADD_FOLDER_IN_GROUP = "FAILED_ADD_FOLDER_IN_GROUP";

export const REQUEST_ADD_GROUP = "REQUEST_ADD_GROUP";
export const SUCCESS_ADD_GROUP = "SUCCESS_ADD_GROUP";
export const FAILED_ADD_GROUP = "FAILED_ADD_GROUP";

export const REQUEST_RENAME_GROUP = "REQUEST_RENAME_GROUP";
export const SUCCESS_RENAME_GROUP = "SUCCESS_RENAME_GROUP";
export const FAILED_RENAME_GROUP = "FAILED_RENAME_GROUP";

export const REQUEST_RENAME_GROUP_FOLDER = "REQUEST_RENAME_GROUP_FOLDER";
export const SUCCESS_RENAME_GROUP_FOLDER = "SUCCESS_RENAME_GROUP_FOLDER";
export const FAILED_RENAME_GROUP_FOLDER = "FAILED_RENAME_GROUP_FOLDER";

export const REQUEST_DELETE_GROUP = "REQUEST_DELETE_GROUP";
export const SUCCESS_DELETE_GROUP = "SUCCESS_DELETE_GROUP";
export const FAILED_DELETE_GROUP = "FAILED_DELETE_GROUP";

export const REQUEST_DELETE_GROUP_FOLDER = "REQUEST_DELETE_GROUP_FOLDER";
export const SUCCESS_DELETE_GROUP_FOLDER = "SUCCESS_DELETE_GROUP_FOLDER";
export const FAILED_DELETE_GROUP_FOLDER = "FAILED_DELETE_GROUP_FOLDER";

export const REQUEST_UPDATE_GROUP_PARENT = "REQUEST_UPDATE_GROUP_PARENT";
export const SUCCESS_UPDATE_GROUP_PARENT = "SUCCESS_UPDATE_GROUP_PARENT";
export const FAILED_UPDATE_GROUP_PARENT = "FAILED_UPDATE_GROUP_PARENT";

export const REQUEST_UPDATE_GROUP_FOLDER_PARENT =
    "REQUEST_UPDATE_GROUP_FOLDER_PARENT";
export const SUCCESS_UPDATE_GROUP_FOLDER_PARENT =
    "SUCCESS_UPDATE_GROUP_FOLDER_PARENT";
export const FAILED_UPDATE_GROUP_FOLDER_PARENT =
    "FAILED_UPDATE_GROUP_FOLDER_PARENT";

export const RESET_GROUP_RESPONSE = "RESET_GROUP_RESPONSE";
export const RESET_GROUP_STATE = "RESET_GROUP_STATE";
//group

// tags
export const REQUEST_GET_ALL_TAGS = "REQUEST_GET_ALL_TAGS";
export const SUCCESS_GET_ALL_TAGS = "SUCCESS_GET_ALL_TAGS";
export const FAILED_GET_ALL_TAGS = "FAILED_GET_ALL_TAGS";
// tags

//folder View
export const REQUEST_GET_ALL_ITEMS_IN_FOLDER =
    "REQUEST_GET_ALL_ITEMS_IN_FOLDER";
export const SUCCESS_GET_ALL_ITEMS_IN_FOLDER =
    "SUCCESS_GET_ALL_ITEMS_IN_FOLDER";
export const FAILED_GET_ALL_ITEMS_IN_FOLDER = "FAILED_GET_ALL_ITEMS_IN_FOLDER";

export const REQUEST_ADD_NEW_FOLDER = "REQUEST_ADD_NEW_FOLDER";
export const SUCCESS_ADD_NEW_FOLDER = "SUCCESS_ADD_NEW_FOLDER";
export const FAILED_ADD_NEW_FOLDER = "FAILED_ADD_NEW_FOLDER";

export const REQUEST_ADD_NEW_ITEM = "REQUEST_ADD_NEW_ITEM";
export const SUCCESS_ADD_NEW_ITEM = "SUCCESS_ADD_NEW_ITEM";
export const FAILED_ADD_NEW_ITEM = "FAILED_ADD_NEW_ITEM";

// export const REQUEST_ADD_FOLDER_IN_GROUP = "REQUEST_ADD_FOLDER_IN_GROUP"
// export const SUCCESS_ADD_FOLDER_IN_GROUP = "SUCCESS_ADD_FOLDER_IN_GROUP"
// export const FAILED_ADD_FOLDER_IN_GROUP = "FAILED_ADD_FOLDER_IN_GROUP"
//
// export const REQUEST_ADD_GROUP = "REQUEST_ADD_GROUP"
// export const SUCCESS_ADD_GROUP = "SUCCESS_ADD_GROUP"
// export const FAILED_ADD_GROUP = "FAILED_ADD_GROUP"
//
export const REQUEST_RENAME_ITEM = "REQUEST_RENAME_ITEM";
export const SUCCESS_RENAME_ITEM = "SUCCESS_RENAME_ITEM";
export const FAILED_RENAME_ITEM = "FAILED_RENAME_ITEM";

export const REQUEST_RENAME_FOLDER = "REQUEST_RENAME_FOLDER";
export const SUCCESS_RENAME_FOLDER = "SUCCESS_RENAME_FOLDER";
export const FAILED_RENAME_FOLDER = "FAILED_RENAME_FOLDER";

export const REQUEST_DELETE = "REQUEST_DELETE";
export const SUCCESS_DELETE = "SUCCESS_DELETE";
export const FAILED_DELETE = "FAILED_DELETE";

export const REQUEST_GET_SHARE_PORTAL_DATA = "REQUEST_GET_SHARE_PORTAL_DATA";
export const SUCCESS_GET_SHARE_PORTAL_DATA = "SUCCESS_GET_SHARE_PORTAL_DATA";
export const FAILED_GET_SHARE_PORTAL_DATA = "FAILED_GET_SHARE_PORTAL_DATA";

export const REQUEST_GET_WESP = "REQUEST_GET_WESP";
export const SUCCESS_GET_WESP = "SUCCESS_GET_WESP";
export const FAILED_GET_WESP = "FAILED_GET_WESP";

export const REQUEST_UPDATE_SHARE_PORTAL = "REQUEST_UPDATE_SHARE_PORTAL";
export const SUCCESS_UPDATE_SHARE_PORTAL = "SUCCESS_UPDATE_SHARE_PORTAL";
export const FAILED_UPDATE_SHARE_PORTAL = "FAILED_UPDATE_SHARE_PORTAL";

export const REQUEST_COPY_ITEM = "REQUEST_COPY_ITEM";
export const SUCCESS_COPY_ITEM = "SUCCESS_COPY_ITEM";
export const FAILED_COPY_ITEM = "FAILED_COPY_ITEM";

export const REQUEST_DELETE_FOLDER = "REQUEST_DELETE_FOLDER";
export const SUCCESS_DELETE_FOLDER = "SUCCESS_DELETE_FOLDER";
export const FAILED_DELETE_FOLDER = "FAILED_DELETE_FOLDER";

export const REQUEST_DELETE_ITEM = "REQUEST_DELETE_ITEM";
export const SUCCESS_DELETE_ITEM = "SUCCESS_DELETE_ITEM";
export const FAILED_DELETE_ITEM = "FAILED_DELETE_ITEM";

export const REQUEST_UPDATE_ITEM_PARENT = "REQUEST_UPDATE_GROUP_PARENT";
export const SUCCESS_UPDATE_ITEM_PARENT = "SUCCESS_UPDATE_GROUP_PARENT";
export const FAILED_UPDATE_ITEM_PARENT = "FAILED_UPDATE_GROUP_PARENT";

export const REQUEST_UPDATE_FOLDER_PARENT = "REQUEST_UPDATE_FOLDER_PARENT";
export const SUCCESS_UPDATE_FOLDER_PARENT = "SUCCESS_UPDATE_FOLDER_PARENT";
export const FAILED_UPDATE_FOLDER_PARENT = "FAILED_UPDATE_FOLDER_PARENT";

export const RESET_FOLDER_VIEW_RESPONSE = "RESET_FOLDER_VIEW_RESPONSE";
// export const RESET_FOLDER_VIEW_STATE = "RESET_FOLDER_VIEW_STATE"

export const RESET_FOLDER_VIEW_STATE = "RESET_FOLDER_VIEW_STATE";

//sms
export const REQUEST_PUT_SMS = "REQUEST_PUT_SMS";
export const SUCCESS_PUT_SMS = "SUCCESS_PUT_SMS";
export const FAILED_PUT_SMS = "FAILED_PUT_SMS";

// schedule
export const REQUEST_GET_SEND_QUEUE = "REQUEST_GET_SEND_QUEUE";
export const SUCCESS_GET_SEND_QUEUE = "SUCCESS_GET_SEND_QUEUE";
export const FAILED_GET_SEND_QUEUE = "FAILED_GET_SEND_QUEUE";

export const REQUEST_GET_SCHEDULE_HISTORY = "REQUEST_GET_SCHEDULE_HISTORY";
export const SUCCESS_GET_SCHEDULE_HISTORY = "SUCCESS_GET_SCHEDULE_HISTORY";
export const FAILED_GET_SCHEDULE_HISTORY = "FAILED_GET_SCHEDULE_HISTORY";

export const REQUEST_DELETE_SEND_QUEUE_ITEMS =
    "REQUEST_DELETE_SEND_QUEUE_ITEMS";
export const SUCCESS_DELETE_SEND_QUEUE_ITEMS =
    "SUCCESS_DELETE_SEND_QUEUE_ITEMS";
export const FAILED_DELETE_SEND_QUEUE_ITEMS = "FAILED_DELETE_SEND_QUEUE_ITEMS";

export const RESET_SCHEDULE_API_RESPONSE = "RESET_SCHEDULE_API_RESPONSE";
// schedule

//Filter
export const REQUEST_GET_FILTER_BY_ID = "REQUEST_GET_FILTER_BY_ID";
export const SUCCESS_GET_FILTER_BY_ID = "SUCCESS_GET_FILTER_BY_ID";
export const FAILED_GET_FILTER_BY_ID = "FAILED_GET_FILTER_BY_ID";

//Filter

//Import translation
export const REQUEST_UPLOAD_IMPORT_TRANSLATION_FILE =
    "REQUEST_UPLOAD_IMPORT_TRANSLATION_FILE";
export const SUCCESS_UPLOAD_IMPORT_TRANSLATION_FILE =
    "SUCCESS_UPLOAD_IMPORT_TRANSLATION_FILE";
export const FAILED_UPLOAD_IMPORT_TRANSLATION_FILE =
    "FAILED_UPLOAD_IMPORT_TRANSLATION_FILE";

export const REQUEST_IMPORT_UPLOADED_TRANSLATION_FILE =
    "REQUEST_IMPORT_UPLOADED_TRANSLATION_FILE";
export const SUCCESS_IMPORT_UPLOADED_TRANSLATION_FILE =
    "SUCCESS_IMPORT_UPLOADED_TRANSLATION_FILE";
export const FAILED_IMPORT_UPLOADED_TRANSLATION_FILE =
    "FAILED_IMPORT_UPLOADED_TRANSLATION_FILE";

export const RESET_IMPORT_TRANSLATION_API_RESPONSE =
    "RESET_IMPORT_TRANSLATION_API_RESPONSE";
//Import translation

//Table
export const REQUEST_GET_TABLE_DATA = "REQUEST_TABLE_DATA";
export const SUCCESS_GET_TABLE_DATA = "SUCCESS_TABLE_DATA";
export const FAILED_GET_TABLE_DATA = "FAILED_TABLE_DATA";

export const REQUEST_GET_TABLE_ROW_BY_ID = "REQUEST_GET_TABLE_ROW_BY_ID";
export const SUCCESS_GET_TABLE_ROW_BY_ID = "SUCCESS_GET_TABLE_ROW_BY_ID";
export const FAILED_GET_TABLE_ROW_BY_ID = "FAILED_GET_TABLE_ROW_BY_ID";

export const REQUEST_GET_TABLE_ROWS = "REQUEST_TABLE_ROWS";
export const SUCCESS_GET_TABLE_ROWS = "SUCCESS_TABLE_ROWS";
export const FAILED_GET_TABLE_ROWS = "FAILED_TABLE_ROWS";

export const REQUEST_GET_ALL_ACCOUNT = "REQUEST_GET_ALL_ACCOUNT";
export const SUCCESS_GET_ALL_ACCOUNT = "SUCCESS_GET_ALL_ACCOUNT";
export const FAILED_GET_ALL_ACCOUNT = "FAILED_GET_ALL_ACCOUNT";

export const REQUEST_DELETE_TABLE_ROWS = "REQUEST_DELETE_TABLE_ROWS";
export const SUCCESS_DELETE_TABLE_ROWS = "SUCCESS_DELETE_TABLE_ROWS";
export const FAILED_DELETE_TABLE_ROWS = "FAILED_DELETE_TABLE_ROWS";

export const RESET_LISTING_API_RESPONSE = "RESET_LISTING_API_RESPONSE";

//Relation Import
export const REQUEST_RELATION_IMPORT = "REQUEST_RELATION_IMPORT";
export const SUCCESS_RELATION_IMPORT = "SUCCESS_RELATION_IMPORT";
export const FAILED_RELATION_IMPORT = "FAILED_RELATION_IMPORT";

export const REQUEST_RELATION_IMPORT_PREVIEW =
    "REQUEST_RELATION_IMPORT_PREVIEW";
export const SUCCESS_RELATION_IMPORT_PREVIEW =
    "SUCCESS_RELATION_IMPORT_PREVIEW";
export const FAILED_RELATION_IMPORT_PREVIEW = "FAILED_RELATION_IMPORT_PREVIEW";

export const REQUEST_IMPORT_SETTINGS = "REQUEST_IMPORT_SETTINGS";
export const SUCCESS_IMPORT_SETTINGS = "SUCCESS_IMPORT_SETTINGS";
export const FAILED_IMPORT_SETTINGS = "FAILED_IMPORT_SETTINGS";

export const REQUEST_FINAL_RELATION_IMPORT = "REQUEST_FINAL_RELATION_IMPORT";
export const SUCCESS_FINAL_RELATION_IMPORT = "SUCCESS_FINAL_RELATION_IMPORT";
export const FAILED_FINAL_RELATION_IMPORT = "FAILED_FINAL_RELATION_IMPORT";

export const ClOSE_FINAL_IMPORT_SUCCESS = "ClOSE_FINAL_IMPORT_SUCCESS";
export const CHANGE_IMPORT_STEP_STATUS = "CHANGE_IMPORT_STEP_STATUS";
export const RESET_RELATION_IMPORT_API_RESPONSE =
    "RESET_RELATION_IMPORT_API_RESPONSE";
//Relation Import

//user
export const REQUEST_GET_USER_INFO = "REQUEST_GET_USER_INFO";
export const SUCCESS_GET_USER_INFO = "SUCCESS_GET_USER_INFO";
export const FAILED_GET_USER_INFO = "FAILED_GET_USER_INFO";

// scheduler
export const REQUEST_GET_SCHEDULER_INFO = "REQUEST_GET_SCHEDULER_INFO";
export const SUCCESS_GET_SCHEDULER_INFO = "SUCCESS_GET_SCHEDULER_INFO";
export const FAILED_GET_SCHEDULER_INFO = "FAILED_GET_SCHEDULER_INFO";

export const REQUEST_POST_USER_MOLLIE_INFO = "REQUEST_POST_USER_MOLLIE_INFO";
export const SUCCESS_POST_USER_MOLLIE_INFO = "SUCCESS_POST_USER_MOLLIE_INFO";
export const FAILED_POST_USER_MOLLIE_INFO = "FAILED_POST_USER_MOLLIE_INFO";

export const RESET_PROFILE_RESPONSE = "RESET_PROFILE_RESPONSE";

//profile
export const REQUEST_GET_PERSONAL_DOMAIN = "REQUEST_GET_PERSONAL_DOMAIN";
export const SUCCESS_GET_PERSONAL_DOMAIN = "SUCCESS_GET_PERSONAL_DOMAIN";
export const FAILED_GET_PERSONAL_DOMAIN = "FAILED_GET_PERSONAL_DOMAIN";

export const REQUEST_GET_PERSONAL_DOMAIN_ITEMS =
    "REQUEST_GET_PERSONAL_DOMAIN_ITEMS";
export const SUCCESS_GET_PERSONAL_DOMAIN_ITEMS =
    "SUCCESS_GET_PERSONAL_DOMAIN_ITEMS";
export const FAILED_GET_PERSONAL_DOMAIN_ITEMS =
    "FAILED_GET_PERSONAL_DOMAIN_ITEMS";

export const REQUEST_UPDATE_COMPANY_INFO = "REQUEST_UPDATE_COMPANY_INFO";
export const SUCCESS_UPDATE_COMPANY_INFO = "SUCCESS_UPDATE_COMPANY_INFO";
export const FAILED_UPDATE_COMPANY_INFO = "FAILED_UPDATE_COMPANY_INFO";

export const REQUEST_GET_SUBSCRIPTION_FEES = "REQUEST_GET_SUBSCRIPTION_FEES";
export const SUCCESS_GET_SUBSCRIPTION_FEES = "SUCCESS_GET_SUBSCRIPTION_FEES";
export const FAILED_GET_SUBSCRIPTION_FEES = "FAILED_GET_SUBSCRIPTION_FEES";

export const REQUEST_SAVE_SUBSCRIPTION_FEES = "REQUEST_SAVE_SUBSCRIPTION_FEES";
export const SUCCESS_SAVE_SUBSCRIPTION_FEES = "SUCCESS_SAVE_SUBSCRIPTION_FEES";
export const FAILED_SAVE_SUBSCRIPTION_FEES = "FAILED_SAVE_SUBSCRIPTION_FEES";

export const REQUEST_SAVE_PERSONAL_DOMAIN = "REQUEST_SAVE_PERSONAL_DOMAIN";
export const SUCCESS_SAVE_PERSONAL_DOMAIN = "SUCCESS_SAVE_PERSONAL_DOMAIN";
export const FAILED_SAVE_PERSONAL_DOMAIN = "FAILED_SAVE_PERSONAL_DOMAIN";

export const CHECK_PERSONAL_DOMAIN = "CHECK_PERSONAL_DOMAIN";
export const SUCCESS_CHECK_PERSONAL_DOMAIN = "SUCCESS_CHECK_PERSONAL_DOMAIN";
export const FAILED_CHECK_PERSONAL_DOMAIN = "FAILED_CHECK_PERSONAL_DOMAIN";

export const REQUEST_GENERATE_API_KEY = "REQUEST_GENERATE_API_KEY";
export const SUCCESS_GENERATE_API_KEY = "SUCCESS_GENERATE_API_KEY";
export const FAILED_GENERATE_API_KEY = "FAILED_GENERATE_API_KEY";
export const REQUEST_GET_COMPANY_INFO = "REQUEST_GET_COMPANY_INFO";
export const SUCCESS_GET_COMPANY_INFO = "SUCCESS_GET_COMPANY_INFO";
export const FAILED_GET_COMPANY_INFO = "FAILED_GET_COMPANY_INFO";

export const REQUEST_DELETE_SUBSCRIPTION_FEE =
    "REQUEST_DELETE_SUBSCRIPTION_FEE";
export const SUCCESS_DELETE_SUBSCRIPTION_FEE =
    "SUCCESS_DELETE_SUBSCRIPTION_FEE";
export const FAILED_DELETE_SUBSCRIPTION_FEE = "FAILED_DELETE_SUBSCRIPTION_FEE";

export const REQUEST_ADD_CREDIT = "REQUEST_ADD_CREDIT";
export const SUCCESS_ADD_CREDIT = "SUCCESS_ADD_CREDIT";
export const FAILED_ADD_CREDIT = "FAILED_ADD_CREDIT";

export const REQUEST_GET_CREDIT = "REQUEST_GET_CREDIT";
export const SUCCESS_GET_CREDIT = "SUCCESS_GET_CREDIT";
export const FAILED_GET_CREDIT = "FAILED_GET_CREDIT";

export const REQUEST_DELETE_CREDIT = "REQUEST_DELETE_CREDIT";
export const SUCCESS_DELETE_CREDIT = "SUCCESS_DELETE_CREDIT";
export const FAILED_DELETE_CREDIT = "FAILED_DELETE_CREDIT";

export const REQUEST_GET_PAYMENT_HISTORY = "REQUEST_GET_PAYMENT_HISTORY";
export const SUCCESS_GET_PAYMENT_HISTORY = "SUCCESS_GET_PAYMENT_HISTORY";
export const FAILED_GET_PAYMENT_HISTORY = "FAILED_GET_PAYMENT_HISTORY";

export const REQUEST_GET_PAYMENT_INVOICE_DATA =
    "REQUEST_GET_PAYMENT_INVOICE_DATA";
export const SUCCESS_GET_PAYMENT_INVOICE_DATA =
    "SUCCESS_GET_PAYMENT_INVOICE_DATA";
export const FAILED_GET_PAYMENT_INVOICE_DATA =
    "FAILED_GET_PAYMENT_INVOICE_DATA";

export const REQUEST_CANCEL_CURRENT_SUBSCRIPTION =
    "REQUEST_CANCEL_CURRENT_SUBSCRIPTION";
export const SUCCESS_CANCEL_CURRENT_SUBSCRIPTION =
    "SUCCESS_CANCEL_CURRENT_SUBSCRIPTION";
export const FAILED_CANCEL_CURRENT_SUBSCRIPTION =
    "FAILED_CANCEL_CURRENT_SUBSCRIPTION";

//settings
export const SHOW_USER_TOUR = "SHOW_USER_TOUR";
export const HIDE_USER_TOUR = "HIDE_USER_TOUR";
export const START_USER_TOUR = "START_USER_TOUR";
export const STOP_USER_TOUR = "STOP_USER_TOUR";
export const SET_TOUR_STEPS = "SET_TOUR_STEPS";
export const SHOW_TOUR_VIDEO = "SHOW_TOUR_VIDEO";
export const CLOSE_TOUR_VIDEO = "CLOSE_TOUR_VIDEO";
export const RESET_TOUR_GUIDE = "RESET_TOUR_GUIDE";

//bee plugin
export const REQUEST_BEE_AUTHENTICATION = "REQUEST_BEE_AUTHENTICATION";
export const SUCCESS_BEE_AUTHENTICATION = "SUCCESS_BEE_AUTHENTICATION";
export const FAILED_BEE_AUTHENTICATION = "FAILED_BEE_AUTHENTICATION";

// custom view items

// Email Generation Open Ai

export const SUCCESS_GENERATE_EMAIL = "SUCCESS_GENERATE_EMAIL";
export const REQUEST_GENERATE_EMAIL = "REQUEST_GENERATE_EMAIL";
export const FAILED_GENERATE_EMAIL = "FAILED_GENERATE_EMAIL";

// Text Generator open ai

export const SUCCESS_TEXT_GENERATE = "SUCCESS_TEXT_GENERATE";
export const REQUEST_TEXT_GENERATE = "REQUEST_TEXT_GENERATE";
export const FAILED_TEXT_GENERATE = "FAILED_TEXT_GENERATE";

export const GET_PROFILE = "GET_PROFILE";

export const Loader1 = "Loader1";
