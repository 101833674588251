import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_CHILD,
  FETCH_CATEGORIES_CHILD_SUCCESS,
  FETCH_CATEGORIES_ROOT,
  FETCH_CATEGORIES_ROOT_SUCCESS
} from "constants/ActionTypes";

export const fetchCategories = params => {
  return {
    type: FETCH_CATEGORIES,
    payload: params
  };
};

export const fetchCategoriesSuccess = category => {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: category
  };
};

export const fetchCategoriesRoot = params => {
  return {
    type: FETCH_CATEGORIES_ROOT,
    payload: params
  };
};

export const fetchCategoriesRootSuccess = root => {
  return {
    type: FETCH_CATEGORIES_ROOT_SUCCESS,
    payload: root
  };
};

export const fetchCategoriesChild = params => {
  return {
    type: FETCH_CATEGORIES_CHILD,
    payload: params
  };
};

export const fetchCategoriesChildSuccess = child => {
  return {
    type: FETCH_CATEGORIES_CHILD_SUCCESS,
    payload: child
  };
};
