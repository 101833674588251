import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_FORM,FETCH_FORM_BY_ID } from "constants/ActionTypes";
import { fetchFormSuccess,fetchFormByIdSuccess} from "actions/Form";

import config from "../config";

// form
const fetchFormData = async params => {
  const { token } = params;

  const response = await fetch(`${config.api.url}v1/form`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchFormData({ payload }) {
  try {
    const getFormData = yield call(fetchFormData, payload);
    yield put(fetchFormSuccess(getFormData));
  } catch (e) {
    console.error(e);
  }
}

export function* getFormData() {
  yield takeEvery(FETCH_FORM, getFetchFormData);
}

//form by Id
const fetchFormDataById = async params => {
    const { token, id } = params;
    const response = await fetch(`${config.api.url}v1/form/${id}`, {
      method: "GET",
      headers: {
        Authorization: token
      }
    })
      .then(res => res.json())
      .then(json => json);
  
    if (response.status === "success") {
      return response.data;
    }
  };
  
  function* getFetchFormDataById({ payload }) {
    try {
      const getFormDataById = yield call(fetchFormDataById, payload);
      yield put(fetchFormByIdSuccess(getFormDataById));
    } catch (e) {
      console.error(e);
    }
  }
  
  export function* getFormDataById() {
    yield takeEvery(FETCH_FORM_BY_ID, getFetchFormDataById);
  }

//   export saga
export default function* rootSaga() {
  yield all([fork(getFormData),fork(getFormDataById)]);
}
