import React, { Component, useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import PdfViewer from "../../../../customComponents/Pdf/PdfViewer";
import { Container } from "@material-ui/core";

//components
import InvoiceHeader from "./components/InvoiceHeader";
import InvoiceInfo from "./components/InvoiceInfo";
import InvoiceUserInfo from "./components/InvoiceUserInfo";
import InvoiceItemTable from "./components/InvoiceItemTable";
import InvoiceFooter from "./components/InvoiceFooter";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators} from "../../../../../../actions/Profile";

// Create styles
const styles = StyleSheet.create({
    pdfViewer:{
        width:"100%",
        height:"800px"
    },
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    invoiceWrapperView:{
        width:"100%"
    },
    invoiceBodyWrapper:{
        margin:"30px"
    }
});

function PaymentInvoice(props){
    //state
    const [isReady,setIsReady] = useState(false)
    
    
    const {match,data} = props
    //const {loader,paymentInvoice} = props.profileData


    //componentDidMount
    useEffect(() =>{
        // let url = window.location.href;
        // let urlArray = url.split("/");
        // let id = parseInt(urlArray[urlArray.length - 1]);

        if(!isReady){
            setTimeout(() =>{
                setIsReady(true)
            },1)
        }
       // props.getPaymentInvoiceData(id)
    },[]);

    //componentDidUpdate
    // useEffect(() =>{
    //     console.log(`didUpdate: ${count}`)
    //     if(count<1){
    //         setCount(count+1)
    //     }
    // },[count])

    // useEffect(() =>{
    //     if(props.paymentInvoice && !isReady){
    //         setTimeout(() =>{
    //             console.log(`didMount ${true}`)
    //             setIsReady(true)
    //         },1)
    //     }
    // },[props.paymentInvoice])


    return(
        <div >
            <Container>
                {isReady  &&
                    <div>
                        <PdfViewer style={styles.pdfViewer}>
                            <Document>
                                <Page size="A4" style={styles.page}>
                                    <View style={styles.invoiceWrapperView}>
                                        {/*header*/}
                                        <InvoiceHeader/>
                                        {/*header*/}
                                        <View style={styles.invoiceBodyWrapper}>
                                            <InvoiceInfo
                                                data={data}
                                            />
                                            <InvoiceUserInfo
                                                data={data}
                                            />
                                            <InvoiceItemTable
                                                data={data}
                                            />
                                            <InvoiceFooter
                                                data={data}
                                            />
                                        </View>
                                    </View>
    
                                </Page>
                            </Document>
                        </PdfViewer>
                    </div>
                }
            </Container>
        </div>

    )
}
//export default PaymentInvoice
const mapStateToProps = state => ({
    profileData:state.profile
});
export default connect(
    state => mapStateToProps,
    dispatch => bindActionCreators({ ...actionCreators }, dispatch)
)(PaymentInvoice);








// import React from "react";
// import Pdf from "react-to-pdf";
//
// //material ui
// import { makeStyles,withStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
// import Slider from '@material-ui/core/Slider';
// import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
//
// const useStyle = makeStyles({
//     invoiceContainer:{
//         margin:"50px 0px 0px 50px",
//         textAlign:"center"
//     },
//     invoiceWrapper:{
//         margin:"50px 50px 50px 50px"
//     },
//     invoiceHeader:{
//         margin:"50px 50px 50px 50px"
//     }
// })
//
// const ref = React.createRef();
//
// function PaymentInvoice() {
//     const classes = useStyle()
//
//     return(
//         <div className={classes.invoiceContainer}>
//             <Pdf targetRef={ref} filename="code-example.pdf">
//                 {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
//             </Pdf>
//             <div>
//                 <div className={classes.invoiceWrapper} ref={ref}>
//                     <div className={classes.invoiceHeader}>
//                         <h1>Invoice</h1>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default PaymentInvoice


