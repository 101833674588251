import {
  FETCH_FILTER,
  FETCH_FILTER_SUCCESS,
  FETCH_FILTER_BY_ID,
  FETCH_FILTER_BY_ID_SUCCESS,
} from "constants/ActionTypes";
import * as Constants from "../common/Constants";
import config from "../config";
import axios from "axios";
import {getArrayFromObject} from "../common/functions/EproCommonFunctions";

let token = localStorage.getItem("user_token");

export const fetchFilter = (params) => {
  return {
    type: FETCH_FILTER,
    payload: params,
  };
};

export const fetchFilterSuccess = (filters) => {
  return {
    type: FETCH_FILTER_SUCCESS,
    payload: filters,
  };
};

export const fetchFilterById = (params) => {
  return {
    type: FETCH_FILTER_BY_ID,
    payload: params,
  };
};

export const fetchFilterByIdSuccess = (filterById) => {
  return {
    type: FETCH_FILTER_BY_ID_SUCCESS,
    payload: filterById,
  };
};

export const actionCreators = {
  getFilterById:(id)=>async (dispatch,getState) =>{
    dispatch({ type: Constants.REQUEST_GET_FILTER_BY_ID, });
    let url = config.api.url + "api/filter/get/"+id;
    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: token,
      },
    })
        .then((res) => {
          //debugger
          if(res.data.status === "success"){
            let filter = res.data.data;
            let columns = []
            if(filter !== null){
              let obj = JSON.parse(filter.data)
              //columns = getArrayFromObject(obj)
              columns = obj.columns
            }
            dispatch({ type: Constants.SUCCESS_GET_FILTER_BY_ID, filter: filter, columns:columns});
          }
        })
        .catch();
  }
}
