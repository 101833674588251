import * as Constants from "../common/Constants"

const INIT_STATE = {
    beeLoader:false,
    beeToken:null
}
export default (state = INIT_STATE,action) =>{
    switch (action.type) {
        case Constants.REQUEST_BEE_AUTHENTICATION:
            return{
                ...state,
                beeLoader: true,
                beeToken: null
            }
        case Constants.SUCCESS_BEE_AUTHENTICATION:
            return {
                ...state,
                beeLoader: false,
                beeToken: action.token
            }
        default:
            return {
                ...state
            }
    }
}