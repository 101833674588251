// dev
// export default {
//     api: {
//         url: "https://api.dev.bootwo.com/",
//         // languageUrl: "https://dp35ego08pnml.cloudfront.net/dev/"
//         languageUrl:
//             "https://eclickspro-translations.s3.eu-west-1.amazonaws.com/dev/"
//         // "https://eclickspro-dev-environment.s3-eu-west-1.amazonaws.com/translations/"
//     },
//     siteKey: "6Ldv4VoUAAAAAHPT170_wQfYoytGSFG1Pv5lBfhQ"
// };
// dev

// // //production
export default {
    api: {
        url: "https://api.e-clicks.pro/",
        languageUrl: "https://d38lf8liqjbpq2.cloudfront.net/production/"
    },
    siteKey: "6Ldv4VoUAAAAAHPT170_wQfYoytGSFG1Pv5lBfhQ"
};
// //production

// export default {

//   api: {
//     url: "http://localhost/bizzmail/bizzmail_in_portal/",
//     languageUrl: "//dp35ego08pnml.cloudfront.net/dev/"
//   },
//   siteKey: "6Ldv4VoUAAAAAHPT170_wQfYoytGSFG1Pv5lBfhQ"
// };
