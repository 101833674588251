import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';



// const TextHoverToolTip = withStyles((theme) => ({
//     tooltip: {
//         backgroundColor: '#f5f5f9',
//         color: 'rgba(0, 0, 0, 0.87)',
//         maxWidth: 420,
//         fontSize: theme.typography.pxToRem(14),
//         border: '1px solid #dadde9',
//     },
// }))(Tooltip);

// export default TextHoverToolTip;


function TextHoverToolTip(props){
    const CustomToolTip = withStyles({
        root:{
    
        },
        tooltip: {
            backgroundColor:'#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 420,
            fontSize: '14px',
            border: '1px solid #dadde9'
        }
    })(Tooltip)
    
    return(
        <CustomToolTip {...props}>
            {props.children}
        </CustomToolTip>
    )
}
export default TextHoverToolTip