import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_EMAIL_RELATION } from "constants/ActionTypes";
import { fetchEmailRelationSuccess} from "actions/EmailRelation";

import config from "../config";

// letter
const fetchEmailRelationData = async params => {
  const { token,email } = params;

  const response = await fetch(`${config.api.url}v1/get/relations?limit=5&offset=0&email=${email}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .then(json => json);
  return response;
};

function* getFetchEmailRelationData({ payload }) {
  try {
    const getEmailRelationData = yield call(fetchEmailRelationData, payload);
    yield put(fetchEmailRelationSuccess(getEmailRelationData));
  } catch (e) {
    console.error(e);
  }
}

export function* getEmailRelationData() {
  yield takeEvery(FETCH_EMAIL_RELATION, getFetchEmailRelationData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getEmailRelationData)]);
}
