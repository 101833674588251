import React from "react";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import DehazeIcon from "@material-ui/icons/Dehaze";

import MailIcon from "@material-ui/icons/Mail";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import PagesIcon from "@material-ui/icons/Pages";
import DescriptionIcon from "@material-ui/icons/Description";
import SendIcon from "@material-ui/icons/Send";
import CallIcon from "@material-ui/icons/Call";
import RepeatIcon from "@material-ui/icons/Repeat";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ForwardIcon from "@material-ui/icons/Forward";
import GroupIcon from "@material-ui/icons/Group";
import FilterListIcon from "@material-ui/icons/FilterList";
import LanguageIcon from "@material-ui/icons/Language";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import CommentIcon from "@material-ui/icons/Comment";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import TimerIcon from "@material-ui/icons/Timer";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Avatar from "@material-ui/core/Avatar";
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachFile from "@material-ui/icons/AttachFile";

const CustomIcon = (props)=>{
    const color = props.color === undefined? "white":props.color
    let id = props.id === undefined ? "custom-control":props.id
    switch (props.name) {
        case "AlternateEmailIcon":
            return <AlternateEmailIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "AddCircleOutlineIcon":
            return <AddCircleOutlineIcon fontSize={props?.fontSize}id={id} style={{color: color}}/>
        case "MailIcon":
            return <MailIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "PermPhoneMsgIcon":
            return <PermPhoneMsgIcon id={id} style={{color: color}}/>
        case "PagesIcon":
            return <PagesIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "DescriptionIcon":
            return <DescriptionIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "SendIcon":
            return <SendIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "TimerIcon":
            return <TimerIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "CallIcon":
            return <CallIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "MoveToInboxIcon":
            return <MoveToInboxIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "ArrowRightAltIcon":
            return <ArrowRightAltIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "ForwardIcon":
            return <ForwardIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "GroupIcon":
            return <GroupIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "FilterListIcon":
            return <FilterListIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "LanguageIcon":
            return <LanguageIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "RepeatIcon":
            return <RepeatIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "SystemUpdateAltIcon":
            return <SystemUpdateAltIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "InfoIcon":
            return <InfoIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "CallSplitIcon":
            return <CallSplitIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "CloseIcon":
            return <CloseIcon fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "AttachFile":
            return <AttachFile fontSize={props?.fontSize} id={id} style={{color: color}}/>
        case "Mollie":
            return <Avatar alt="Remy Sharp" src={"https://project-eclicksproproduction.s3-eu-west-1.amazonaws.com/static-images/mollie.jpg"} />
        case "TagsIcon":
            return <Avatar alt="Tag" src={"https://s3.eu-west-1.amazonaws.com/app.e-clicks.pro/static/media/tagsicon.png"}/>
        default:
            return <HourglassEmptyIcon id={id} style={{color: color}}/>

    }

}
export default CustomIcon