import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "../button/CustomButton";
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    dialogContentText: {
        //backgroundColor: 'red',
        //color: props => props.color,
        marginTop:"18px"
    },
    dialogAction:{
        padding:"6px 22px 8px 24px"
    }
});

const CustomAlertDialog = (props) => {
    const classes = useStyles(props);
    let titleKey = props.titleKey === undefined ? "_ALERT":props.titleKey;
    let contentTextKey = props.contentTextKey === undefined ? "_CLOSE":props.contentTextKey;
    let fullWidth = props.fullWidth === undefined ? true:props.fullWidth
    let maxWidth = props.maxWidth === undefined ? "sm":props.maxWidth
    return(
        <Dialog
            open={props.open}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={props.handleClose}
            aria-labelledby="custom-dailog-title"
        >
            {props.appBarInTop !== false?
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Grid container>
                            <Grid item md={11}>
                                <Typography variant="h6" color="inherit">
                                    <IntlMessages id={titleKey}/>
                                </Typography>
                            </Grid>
                            <Grid container item md={1} justify="flex-end">
                                {/*this.handleDelete*/}
                                <IconButton
                                    size="small"
                                    className="actionDialogTitleButton"
                                    onClick={props.handleClose}
                                >
                                    <CloseIcon style={{ color: "white" }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                :
                <DialogTitle id="custom-dailog-title">{titleKey}</DialogTitle>
            }
            <DialogContent dividers>
                <DialogContentText className={classes.dialogContentText}>
                    <IntlMessages id={contentTextKey}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <CustomButton color="grey" onClick={props.handleClose}>
                    <IntlMessages id="_CLOSE"></IntlMessages>
                </CustomButton>
            </DialogActions>



        </Dialog>
    )

}
export default CustomAlertDialog