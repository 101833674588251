import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  resetPassword,
  resetPasswordSuccess
} from "actions/Auth";

import config from "../config";
class RetypePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      newPassword: "",
      repeatPassword: "",
      loader: false
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  render() {
    const { newPassword, repeatPassword, loader } = this.state;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="MyBizzmail">
              <img
                src={require("assets/images/logo.png")}
                alt="bizzmail"
                title="MyBizzmail"
                width="250"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="_CHANGE_PASSWORD" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    type="password"
                    label={<IntlMessages id="_NEW_PASSWORD" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ newPassword: event.target.value })
                    }
                    defaultValue={newPassword}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <TextField
                    type="password"
                    label={<IntlMessages id="_REPEAT_PASSWORD" />}
                    fullWidth
                    onChange={event =>
                      this.setState({ repeatPassword: event.target.value })
                    }
                    defaultValue={repeatPassword}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        let msg;
                        const reGexLC = new RegExp("^(?=.*[a-z])");
                        const reGexUC = new RegExp("(?=.*[A-Z])");
                        const reGexN = new RegExp("(?=.*[0-9])");
                        const reGexSC = new RegExp("(?=.*[!@#\$%\^&\*])");

                        if (newPassword.length < 8 || !reGexLC.test(newPassword) || !reGexUC.test(newPassword) || !reGexN.test(newPassword) || !reGexSC.test(newPassword)) {
                          msg = <IntlMessages id="_ECLICKSPRO_PASSWORD_VALIDATION" />;
                          return NotificationManager.error(msg);
                        }

                        if (repeatPassword.length === 0) {
                          msg = <IntlMessages id="_ECLICKSPRO_REPEAT_PASSWORD_NULL" />
                          return NotificationManager.error(msg);
                        }

                        if (!(newPassword === repeatPassword)) {
                          msg = <IntlMessages id="_PASSWORD_IS_NOT_MATCHED" />;
                          return NotificationManager.error(msg);
                        }

                        this.setState({ loader: true });
                        fetch(`${config.api.url}api/password_save`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify({
                            token: this.props.match.params.token,
                            new_password: newPassword,
                            re_password: repeatPassword
                          })
                        })
                          .then(response => response.json())
                          .then(json => {
                            if (json.status === "success") {
                              this.setState({ loader: false });
                              return window.location.replace("/signin");
                            }
                            if (json.status === "error") {
                              this.setState({ loader: false });
                              return NotificationManager.error(json.message);
                            }
                          })
                          .catch(error => NotificationManager.error(error));
                      }}
                      variant="contained"
                      color="primary"

                    >
                      <IntlMessages id="_CHANGE_PASSWORD" />
                    </Button>
                    <Link to="/signin">
                      <IntlMessages id="_GO_LOGIN_PAGE" />
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  // console.log("auth->", auth);
  const { loader, alertMessage, showMessage, authUser, status } = auth;
  return { loader, alertMessage, showMessage, authUser, status };
};

export default connect(mapStateToProps, {
  userSignIn,
  resetPassword,
  resetPasswordSuccess,
  hideMessage,
  showAuthLoader
})(RetypePassword);
