import {
    FETCH_EMAIL,
    FETCH_EMAIL_SUCCESS,
    FETCH_EMAIL_FOLDER,
    FETCH_EMAIL_FOLDER_SUCCESS,
    FETCH_EMAIL_BY_ID,
    FETCH_EMAIL_BY_ID_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants";

const INIT_STATE = {
    loader: true,
    openFullScreenLoader: false,
    emails: [],
    folder: [],
    emailData: null,
    emailParams: null,
    folderParams: null,
    emailDataParams: null,
    email: null,
    emailSuggestionLoad: false,
    emailSuggestions: [],
    relationFields: [],
    subjectLine: "",
    fromAddress: "",
    fromName: "",
    toAddress: "",
    toName: "",
    newItemCreated: false,
    newItem: null,
    emailApiResponse: null,
    campaignIdOfThisItem: null,
    generatedEmailData: "",
    loader1: false,
    profile: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case Constants.REQUEST_GET_EMAIL_BY_ID: {
            return {
                ...state,
                email: null,
                loader: true,
                subjectLine: "",
                fromAddress: "",
                fromName: "",
                toAddress: "",
                toName: ""
            };
        }
        case Constants.SUCCESS_GET_EMAIL_BY_ID: {
            return {
                ...state,
                email: action.emailObj,
                subjectLine: action.subjectLine,
                fromAddress: action.fromAddress,
                fromName: action.fromName,
                toAddress: action.toAddress,
                toName: action.toName,
                loader: false
            };
        }
        case Constants.REQUEST_GET_ALL_EMAIL_IN_FOLDER: {
            return {
                ...state,
                loader: true,
                emails: [],
                folder: []
            };
        }
        case Constants.SUCCESS_GET_ALL_EMAIL_IN_FOLDER: {
            return {
                ...state,
                loader: false,
                emails: action.items,
                folder: action.folders
            };
        }
        case Constants.FAILED_GET_ALL_EMAIL_IN_FOLDER: {
            return {
                ...state
            };
        }
        case Constants.REQUEST_ADD_FOLDER_IN_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: true
            };
        }
        case Constants.SUCCESS_ADD_FOLDER_IN_EMAIL: {
            return {
                ...state,
                folder: [...action.folders, ...state.folder],
                emailApiResponse: action.apiResponse,
                openFullScreenLoader: false
            };
        }
        case Constants.FAILED_ADD_FOLDER_IN_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
            };
        }
        case Constants.REQUEST_ADD_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: true
            };
        }
        case Constants.SUCCESS_ADD_EMAIL: {
            return {
                ...state,
                emails: [...action.item, ...state.emails],
                newItemCreated: true,
                newItem: action.item[0],
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
            };
        }
        case Constants.FAILED_ADD_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
            };
        }
        case Constants.REQUEST_PUT_EMAIL: {
            return {
                ...state,
                emailPutStatus: null,
                emailApiResponse: null
            };
        }
        case Constants.SUCCESS_PUT_EMAIL: {
            return {
                ...state,
                //loader: false,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
                //emailPutStatus: "success",
            };
        }
        case Constants.FAILED_PUT_EMAIL: {
            return {
                ...state,
                //emailPutStatus: "failed",
                emailApiResponse: action.apiResponse
            };
        }
        case Constants.REQUEST_RENAME_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: true,
                emailApiResponse: null
            };
        }
        case Constants.SUCCESS_RENAME_EMAIL: {
            return {
                ...state,
                email: action.emailObj,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
            };
        }
        case Constants.REQUEST_SEND_EMAIL: {
            return {
                ...state,
                //emailSendStatus: null,
                emailApiResponse: null
            };
        }
        case Constants.SUCCESS_SEND_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
                //emailSendStatus: "success",
            };
        }
        case Constants.FAILED_SEND_EMAIL: {
            return {
                ...state,
                openFullScreenLoader: false,
                emailApiResponse: action.apiResponse
                //emailSendStatus: "failed",
            };
        }
        //for resetting all success notification state
        case Constants.RESET_SUCCESS_STATUS: {
            return {
                ...state,
                emailSendStatus: null,
                emailPutStatus: null
            };
        }
        case Constants.START_EMAIL_LOADER_DIALOG: {
            return {
                ...state,
                openFullScreenLoader: true
            };
        }
        case Constants.STOP_EMAIL_LOADER_DIALOG: {
            return {
                ...state,
                openFullScreenLoader: false
            };
        }
        case Constants.REQUEST_GET_EMAIL_SUGGESTION: {
            return {
                ...state,
                emailSuggestionLoad: true,
                emailSuggestions: [],
                emailSent: ""
            };
        }
        case Constants.SUCCESS_GET_EMAIL_SUGGESTION: {
            return {
                ...state,
                emailSuggestionLoad: false,
                emailSuggestions: action.emailSuggestions
            };
        }
        case Constants.REQUEST_GET_ALL_RELATION_FIELDS: {
            return {
                ...state,
                relationFields: []
            };
        }
        case Constants.SUCCESS_GET_ALL_RELATION_FIELDS: {
            return {
                ...state,
                relationFields: action.fields
            };
        }
        case "UPDATE_SUBJECT_LINE": {
            return {
                ...state,
                subjectLine: action.subjectLine
            };
        }
        case "UPDATE_FROM_NAME": {
            return {
                ...state,
                fromName: action.fromName
            };
        }
        case "UPDATE_TO_NAME": {
            return {
                ...state,
                toName: action.toName
            };
        }
        case "UPDATE_FROM_ADDRESS": {
            return {
                ...state,
                fromAddress: action.fromAddress
            };
        }
        case "UPDATE_TO_ADDRESS": {
            return {
                ...state,
                toAddress: action.toAddress
            };
        }
        case Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM: {
            return {
                ...state,
                campaignIdOfThisItem: null
            };
        }
        case Constants.SUCCESS_GET_CAMPAIGN_OF_THIS_ITEM: {
            return {
                ...state,
                campaignIdOfThisItem: action.payload
            };
        }
        case Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM: {
            return {
                ...state,
                campaignIdOfThisItem: null
            };
        }
        case FETCH_EMAIL: {
            return { ...state, loader: true, emailParams: action.payload };
        }
        case FETCH_EMAIL_SUCCESS: {
            return { ...state, loader: false, emails: action.payload };
        }
        case FETCH_EMAIL_FOLDER: {
            return { ...state, loader: true, folderParams: action.payload };
        }
        case FETCH_EMAIL_FOLDER_SUCCESS: {
            return { ...state, loader: false, folder: action.payload };
        }
        case FETCH_EMAIL_BY_ID: {
            return { ...state, loader: true, emailDataParams: action.payload };
        }
        case FETCH_EMAIL_BY_ID_SUCCESS: {
            return { ...state, loader: false, emailData: action.payload };
        }
        case Constants.REQUEST_GENERATE_EMAIL: {
            return {
                ...state,
                generatedEmailData: null
            };
        }
        case Constants.SUCCESS_GENERATE_EMAIL: {
            return {
                ...state,
                generatedEmailData: action.apiResponse.choices[0].text
            };
        }
        case Constants.FAILED_GENERATE_EMAIL: {
            return {
                ...state,
                generatedEmailData: action.apiResponse.choices[0].text
            };
        }
        case Constants.Loader1: {
            return {
                ...state,
                loader1: action.payload
            };
        }
        case Constants.GET_PROFILE: {
            return {
                ...state,
                profile: action.payload
            };
        }
        default:
            return state;
    }
};
