import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  FETCH_RELATION_GROUP,
  FETCH_RELATION_GROUP_FOLDER,
} from "constants/ActionTypes";
import {
  fetchRelationGroupSuccess,
  fetchRelationGroupFolderSuccess,
} from "actions/Relation";

import config from "../config";

// relation group
const fetchRelationGroupData = async (params) => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/group?folder_id=${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((json) => json);
  return response;
};

function* getFetchRelationGroupData({ payload }) {
  try {
    const getRelationGroupData = yield call(fetchRelationGroupData, payload);
    yield put(fetchRelationGroupSuccess(getRelationGroupData));
  } catch (e) {
    console.error(e);
  }
}

export function* getRelationGroupData() {
  yield takeEvery(FETCH_RELATION_GROUP, getFetchRelationGroupData);
}

// relation group Folder
const fetchRelationGroupFolderData = async (params) => {
  const { token, id } = params;

  const response = await fetch(`${config.api.url}v1/folder/group/${id}`, {
    method: "GET",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((json) => json);
  return response;
};

function* getFetchRelationGroupFolderData({ payload }) {
  try {
    const getRelationGroupFolderData = yield call(
      fetchRelationGroupFolderData,
      payload
    );
    yield put(fetchRelationGroupFolderSuccess(getRelationGroupFolderData));
  } catch (e) {
    console.error(e);
  }
}

export function* getRelationGroupFolderData() {
  yield takeEvery(FETCH_RELATION_GROUP_FOLDER, getFetchRelationGroupFolderData);
}

//   export saga
export default function* rootSaga() {
  yield all([fork(getRelationGroupData), fork(getRelationGroupFolderData)]);
}
