import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import IntlMessages from "../../../../util/IntlMessages";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import config from "../../../../config";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const useStyles = theme => ({
    eproLabel:{
        margin:"15px 0px 15px 0px",
    },
    labelLink:{
        color:"#B73110",
        '&:hover':{
            color:"#DEA79A",
            textDecoration:"none"
        }
    }
});

class ApiData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableTextField:true,
            disableBtn:false
        }
    }
    componentDidMount() {
        if(this.props.info !== null){
            let key = this.props.info.data.mollie_api_key
            let secret = this.props.info.data.mollie_api_secret
            let name = this.props.info.data.mollie_name
            let email = this.props.info.data.mollie_email
            // const {mollie_api_key = "" as key, mollie_api_secret = "" as secret } = this.props.info.data
            this.setState({
                mollieApiKey:key,
                mollieApiSecret:secret,
                mollieName:name,
                mollieEmail:email
            })
            if(this.props.secretKey && this.props.publicKey && this.props.info?.data?.api_key){
                this.setState({disableBtn: true})
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.info !== null){
            if(this.props.info.data !== prevProps.info.data){
                let key = this.props.info.data.mollie_api_key
                let secret = this.props.info.data.mollie_api_secret
                this.setState({
                    mollieApiKey:key,
                    mollieApiSecret:secret
                })
            }
        }
    }

    updateMollieInfo = () =>{
        if((this.state.mollieApiSecret === null && this.state.mollieApiKey === null)){
            return
        }
        this.props.updateMollieInfo(this.state.mollieApiKey,this.state.mollieApiSecret,this.state.mollieName,this.state.mollieEmail)
    }
    handleChange = (event) =>{
        let id = event.target.id
        this.setState({
            [id]:event.target.value
        })
    }
    generateKey = () =>{
        this.setState({
            disableBtn: true
        })
        this.props.generateKey()
    }
    render() {
        const {classes, logo, info} = this.props
        const {disableTextField, disableBtn} = this.state
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <div>
                            <TextField
                                // autoFocus
                                //margin="dense"
                                id="apiKey"
                                label={<IntlMessages id="_ECLICKSPRO_API_KEY" /> }
                                type="text"
                                value={info?.data?.api_key}
                                fullWidth
                                disabled={disableTextField}
                                // onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <TextField
                                // autoFocus
                                //margin="dense"
                                id="mollieName"
                                label={<IntlMessages id="_ECLICKSPRO_PUBLIC_KEY" /> }
                                type="text"
                                value={this.props.publicKey}
                                fullWidth
                                disabled={disableTextField}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                            <TextField
                                // autoFocus
                               // margin="dense"
                                id="mollieEmail"
                                label={<IntlMessages id="_ECLICKSPRO_SECRET_KEY" /> }
                                type="text"
                                value={this.props.secretKey}
                                fullWidth
                                disabled={disableTextField}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{marginTop:"10px"}}>
                            <Button
                                color={'primary'}
                                variant="contained"
                                disabled={disableBtn}
                                onClick={this.generateKey}
                            >
                                { (!this.props.secretKey && !this.props.publicKey && !this.props.info?.data?.api_key) ? 
                                        <IntlMessages id="_ECLICKSPRO_GENERATE_KEY" /> : 
                                        (this.props.secretKey && this.props.publicKey && this.props.info?.data?.api_key)?
                                            <IntlMessages id="_ECLICKSPRO_GENERATE_KEY" />:
                                            <IntlMessages id="_ECLICKSPRO_UPGRADE_KEY" />
                                }
                            </Button>
                        </div>
                    </Grid>
                </Grid>


            </div>
        );
    }
}

export default withStyles(useStyles)(ApiData);