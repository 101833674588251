import React from "react";
import IntlMessages from "../../../../util/IntlMessages";

const AccessDenied = ()=>{
    return(
        <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
            <div className="page-error-content">
                <div className="error-code mb-4 animated zoomInDown">403</div>
                <h1 className="text-center fw-regular title bounceIn animation-delay-10 animated mb-4">
                    <IntlMessages id="_ACCESS_DENIED" />
                </h1>
                <div className="mb-4">
                    <h2 className="text-center flipInX animation-delay-16 animated">
                        <IntlMessages id="_YOUR_USERGROUP_HAS_NO_PERMISSION_TO_ACCESS_THIS_PAGE" />
                    </h2>
                </div>
                <h4 className="text-center zoomIn animation-delay-20 animated">
                    <IntlMessages id="_YOU_WILL_BE_REDIRECT_TO_HOME_PAGE_IN" /> 5 Second
                </h4>
            </div>
            {void setTimeout(() => window.location.replace("/app/home"), 5000)}
        </div>
    )
}
export default AccessDenied