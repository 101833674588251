import React from "react";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IntlMessages from "util/IntlMessages";

import CircularFab from "./CircularFab";

class AccountInformation extends React.Component {
  state = {
    email: "",
    firstname: "",
    lastname: "",
    mobileNumber: "",
    countryCode: "",
    language: ""
  };

  componentDidMount() {
    const { data } = this.props;
    const {
      email,
      first_name,
      last_name,
      mobile_postfix,
      mobile_prefix,
      language
    } = data;
    this.setState({
      email,
      firstname: first_name,
      lastname: last_name,
      mobileNumber: mobile_postfix,
      countryCode: mobile_prefix,
      language
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { mobileCodes, languages, token } = this.props;
    return (
      <div className="row">
        <div className="col-md-4 col-12">
          <TextField
            id="email"
            label={<IntlMessages id="_EMAIL" />}
            value={this.state.email}
            onChange={this.handleChange("email")}
            margin="normal"
            fullWidth
            disabled
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="firstname"
            label={
              <IntlMessages id="_FIRSTNAME" />
            }
            value={this.state.firstname}
            onChange={this.handleChange("firstname")}
            margin="normal"
            fullWidth
          />
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="lastname"
            label={
              <IntlMessages id="_LASTNAME" />
            }
            value={this.state.lastname}
            onChange={this.handleChange("lastname")}
            margin="normal"
            fullWidth
          />
        </div>
        <div className="col-md-4 col-12">
          <FormControl className="w-100 mt-3">
            <InputLabel htmlFor="age-native-simple">
              {
                <IntlMessages id="_COUNTRYCODE" />
              }
            </InputLabel>
            <Select
              native
              value={this.state.countryCode}
              onChange={this.handleChange("countryCode")}
              input={<Input id="age-native-simple" />}
            >
              <option value="" disabled />
              {mobileCodes.map((code, i) => {
                return (
                  <option key={i} value={code.dialing_codes}>
                    {code.country_dialing_code}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-4 col-12">
          <TextField
            id="mobileNumber"
            label={
              <IntlMessages id="_MOBILE_NUMBER" />
            }
            value={this.state.mobileNumber}
            onChange={this.handleChange("mobileNumber")}
            margin="normal"
            fullWidth
          />
        </div>
        <div className="col-md-3 col-12">
          <FormControl className="w-100 mt-3">
            <InputLabel htmlFor="age-native-simple">
              {<IntlMessages id="_LANGUAGE" />}
            </InputLabel>
            <Select
              native
              value={this.state.language}
              onChange={this.handleChange("language")}
              input={<Input id="age-native-simple" />}
            >
              {languages.map((language, i) => (
                <option key={i} value={language.name}>
                  {language.name.toUpperCase()}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-1 col-12" style={{ marginLeft: "-10px" }}>
          <CircularFab token={token} data={this.state} />
        </div>
      </div>
    );
  }
}

export default AccountInformation;
