import {
  FETCH_SCHEDULE_HISTORY,
  FETCH_SCHEDULE_SEND_QUEUE,
  FETCH_SCHEDULE_HISTORY_SUCCESS,
  FETCH_SCHEDULE_SEND_QUEUE_SUCCESS
} from "constants/ActionTypes";
import * as Constants from "../common/Constants"

let initialPageObj = {
  items:[],
  offset:"",
  results:0,
  total:0
}

const INIT_STATE = {
  loader: true,
  scheduleHistory: initialPageObj,
  historyLoader:false,
  historyParam: null,
  queueLoader:false,
  queue: initialPageObj,
  queueParams: null,
  scheduleApiResponse:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_HISTORY: {
      return {
        ...state,
        loader: true,
        historyParam: action.payload
      };
    }
    case FETCH_SCHEDULE_HISTORY_SUCCESS: {
      return {
        ...state,
        loader: false,
        scheduleHistory: action.payload
      };
    }
    case FETCH_SCHEDULE_SEND_QUEUE: {
      return {
        ...state,
        loader: true,
        queueParams: action.payload
      };
    }
    case FETCH_SCHEDULE_SEND_QUEUE_SUCCESS: {
      return {
        ...state,
        queueLoader: false,
        queue: action.payload
      };
    }
    case Constants.REQUEST_GET_SEND_QUEUE:{
      return {
        ...state,
        queueLoader: true,
        queue: initialPageObj
      }
    }
    case Constants.SUCCESS_GET_SEND_QUEUE:{
      return {
        ...state,
        queueLoader: false,
        queue: action.queue
      }
    }
    case Constants.FAILED_GET_SEND_QUEUE:{
      return {
        ...state,
        loader: false,
        queue: initialPageObj
      }
    }
    case Constants.REQUEST_GET_SCHEDULE_HISTORY:{
      return {
        ...state,
        historyLoader: true,
        scheduleHistory:initialPageObj

      }
    }
    case Constants.SUCCESS_GET_SCHEDULE_HISTORY:{
      return {
        ...state,
        historyLoader: false,
        scheduleHistory:action.history
      }
    }
    case Constants.FAILED_GET_SCHEDULE_HISTORY:{
      return {
        ...state,
        historyLoader: false,
        scheduleHistory:initialPageObj
      }
    }
    case Constants.REQUEST_DELETE_SEND_QUEUE_ITEMS:{
      return {
        ...state,
        scheduleApiResponse: null
      }
    }
    case Constants.SUCCESS_DELETE_SEND_QUEUE_ITEMS:{
      return {
        ...state,
        scheduleApiResponse: action.apiResponse
      }
    }
    case Constants.FAILED_DELETE_SEND_QUEUE_ITEMS:{
      return {
        ...state,
        scheduleApiResponse: action.apiResponse
      }
    }
    case Constants.RESET_SCHEDULE_API_RESPONSE:{
      return {
        ...state,
        scheduleApiResponse: null
      }
    }
    default: {
      return state;
    }
  }
};
