import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessages from "util/IntlMessages";
import config from "config";

class EditDialogBox extends React.Component {
  state = {
    name: "",
    checkedA: false
  };

  handleRequestClose = () => {
    this.props.handleRequestClose();
  };

  handleChange = name => (event, checked) => {
    this.setState({ [name]: checked });
  };

  handleRequestEdit = async () => {
    const { token, tableName, templateId } = this.props;
    const { name, checkedA } = this.state;
    // console.log('name from edit ->', name);
    const response = await fetch(
      `${config.api.url}/api/template_edit/${tableName}/${templateId}`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          newviewname: name,
          for_all: checkedA ? "1" : ""
        })
      }
    )
      .then(res => res.json())
      .then(json => json)
      .catch(e => console.log(e));

    if (response.status === "success") return window.location.reload();
  };

  render() {
    const { openEditBox } = this.props;
    const label = <IntlMessages id="_EDIT" />;

    return (
      <div>
        <Dialog open={openEditBox} onClose={this.handleRequestClose}>
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={label}
              type="text"
              fullWidth
              onChange={event => this.setState({ name: event.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              <IntlMessages id="_CLOSE" />
            </Button>
            <Button onClick={this.handleRequestEdit} color="primary">
              <IntlMessages id="_EDIT" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default EditDialogBox;
