import React from "react";
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

// Create styles
const styles = StyleSheet.create({
    userInfoWrapper:{
        margin:"40px 0px 0px 5px"
    },
    text:{
        fontSize:"12",
        margin:"2px 0",
        color:eclicksProColors.textGray
    },
    indText:{
        fontSize:"12",
        margin:"2px 0 2px 50px",
        color:eclicksProColors.textGray
    }
});

function InvoiceUserInfo(props) {
    const {data} = props
    return(
        <View style={styles.userInfoWrapper}>
            <Text style={styles.text}>{`Company Name: ${data.company_name ? data.company_name : "............."}`}</Text>
            <Text style={styles.text}>{`Address: ${data.address_line_one ? data.address_line_one : ""} ${data.address_line_two ? data.address_line_two : ""} `}</Text>
            <Text style={styles.indText}>{`${data.city ? data.city : ""}${data.city ? "," : ""} ${data.zipcode ? data.zipcode : ""}${data.zipcode ? "," : ""} ${data.country ? data.country : ""}`}</Text>
            <Text style={styles.text}>{`User name: ${data.first_name?  data.first_name:"............."} ${data.last_name ? data.last_name : "............."}`}</Text>
            <Text style={styles.text}>{`Email: ${data.email ? data.email : "............."}`}</Text>
        </View>
    )
}
export default InvoiceUserInfo