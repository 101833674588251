import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { FETCH_HOME } from "constants/ActionTypes";
import { fetchHomeDataSuccess } from "actions/Home";

import config from "../config";

// Api Functions
const fetchHomeData = async token => {
  // console.log("token ->", token);
  const response = await fetch(`${config.api.url}api/home`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(json => json)
    .catch(error => error);
  // console.log("response ->", response);
  return response;
};

// Saga Functions
function* getFetchHomeData({ payload }) {
  // console.log(payload);
  try {
    const getHomeData = yield call(fetchHomeData, payload);
    // console.log("getMenu->", getMenu);
    yield put(fetchHomeDataSuccess(getHomeData));
  } catch (e) {
  }
}

// exporting the saga function
export function* getHomeData() {
  yield takeEvery(FETCH_HOME, getFetchHomeData);
}

export default function* rootSaga() {
  yield all([fork(getHomeData)]);
}
