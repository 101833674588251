import {
  FETCH_RELATION_GROUP_FOLDER,
  FETCH_RELATION_GROUP_FOLDER_SUCCESS,
  FETCH_RELATION_GROUP,
  FETCH_RELATION_GROUP_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  relationGroups: null,
  folder: null,
  relationGroupParams: null,
  folderParams: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_RELATION_GROUP_FOLDER: {
      return {
        ...state,
        loader: true,
        relationGroupParams: action.payload,
      };
    }
    case FETCH_RELATION_GROUP_FOLDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        relationGroups: action.payload,
      };
    }
    case FETCH_RELATION_GROUP: {
      return {
        ...state,
        loader: true,
        folderParams: action.payload,
      };
    }
    case FETCH_RELATION_GROUP_SUCCESS: {
      return {
        ...state,
        loader: false,
        folder: action.payload,
      };
    }
    default:
      return state;
  }
};
