import React from "react";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

const styles = StyleSheet.create({
     invoiceTableWrapper:{
         marginTop:"20px",
     },
    tableHeader:{
         // backgroundColor:"#EFEFEF",
         width:"100%",
        // borderBottom:true,
        // borderBottomColor:"#A3A3A3",
        // borderBottomStyle:{
        //     height:"5px"
        // }
    },
    paymentMethodView:{
       margin:"5px"
    },
    paymentMethodText:{
        fontSize:"12",
        fontWeight:"bold",
        color:eclicksProColors.textGray
    }
});

function InvoiceItemTable(props) {
    const headerCols = ['Package type','Start date', 'End date', 'Status','Price']
    
    const footer = ["","","","Vat",props.data.vat]
    const blankRows = [
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""],
        ["","","","",""]
    ]
    const {data} = props
    console.log(data)
    const rows =[
        [data.package_type ? data.package_type : "",data.start_date ? data.start_date : "",data.end_date ? data.end_date : "",data.payment_status ? data.payment_status : "",data.price ? data.regular_price : ""]
    ]
    return(
        <View style={styles.invoiceTableWrapper}>
            <View style={styles.paymentMethodView}>
                <Text style={styles.paymentMethodText}>{`Payment via: Sefa`}</Text>
            </View>

            <View style={styles.tableHeader}>
                <InvoiceTableRow
                     rowData={headerCols}
                />
            </View>

            {
                rows.map((r,i) =>{
                    return(
                        <InvoiceTableRow rowData={r}/>
                    )
                })
            }

            {/*vat*/}
            <View style={styles.tableHeader}>
                <InvoiceTableRow
                    rowData={footer}
                />
            </View>
            {/*vat*/}

            <InvoiceTableFooter
                data={data}
            />

            {
                blankRows.map((r,i) =>{
                    return(
                        <InvoiceTableRow rowData={r}/>
                    )
                })
            }
        </View>
    )
}
export default InvoiceItemTable