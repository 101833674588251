import {
  FETCH_TRASHVIEW,
  FETCH_TRASHVIEW_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  params: null,
  data: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRASHVIEW: {
      return {
        ...state,
        loader: true,
        params: action.payload
      };
    }
    case FETCH_TRASHVIEW_SUCCESS: {
      return {
        ...state,
        loader: false,
        data: action.payload
      };
    }
    default:
      return state;
  }
};
