import {
	FETCH_FORM,
	FETCH_FORM_SUCCESS,
	FETCH_FORM_BY_ID,
	FETCH_FORM_BY_ID_SUCCESS,
} from "constants/ActionTypes";
import axios from "axios";
import config from "../config";
import * as Constants from "../common/Constants"
import {getResponseForNotificaion} from "../common/functions/EproCommonFunctions";
import { getAuthData } from "../common/helpers/AuthHelper";

export const fetchForm = (params) => {
	return {
		type: FETCH_FORM,
		payload: params,
	};
};

export const fetchFormSuccess = (forms) => {
	return {
		type: FETCH_FORM_SUCCESS,
		payload: forms,
	};
};

export const fetchFormById = (params) => {
	return {
		type: FETCH_FORM_BY_ID,
		payload: params,
	};
};

export const fetchFormByIdSuccess = (formData) => {
	return {
		type: FETCH_FORM_BY_ID_SUCCESS,
		payload: formData,
	};
};

// after applying thunk middleware in store -> index
export const actionCreators = {

	//getById
	getFormById: (id) => async (dispatch, getState) => {
		dispatch({ type: Constants.REQUEST_GET_FROM_BY_ID });
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form/" + id;
		let {token} = getAuthData()
		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				let successPage = ""
				let pixelCode = null
				let footerPixelCode = null
				let productPrice = ""
				let productDescription = ""
				let productVat = ""

				if(res.data.data.data !== null && res.data.data.data !== ""){
					successPage = res.data.data.data.successPage
					pixelCode = res.data.data.data.pixelCode
					footerPixelCode = res.data.data.data.footerPixelCode
					productPrice = res.data.data.data.price
					productDescription = res.data.data.data.item
					productVat = res.data.data.data.vat
				}
				dispatch({
					type: Constants.SUCCESS_GET_FROM_BY_ID,
					data: res.data.data,
					successPage: successPage,
					pixelCode: pixelCode,
					footerPixelCode: footerPixelCode,
					productPrice:productPrice,
					productDescription:productDescription,
					productVat:productVat
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	//getAll

	//put
	putFormData: (form, jsonData, htmlData, successPage,pixelCode, footerPixelCode,useAsProduct,productPrice,productDescription,productVat) => async (
		dispatch,
		getState
	) => {
		dispatch({type:Constants.REQUEST_PUT_FORM_DATA})
		let {token} = getAuthData()
		form.data = jsonData;
		const parseData = JSON.parse(jsonData);
		let authInfo = null
		if(token !== null){
			authInfo = JSON.parse(atob(token.split(".")[1]));
		}

		parseData.successPage = successPage;
		parseData.pixelCode = pixelCode;
		parseData.footerPixelCode = footerPixelCode;
		parseData.page.title = form.name;
		parseData.page.description = form.name;
		parseData.useAsProduct = useAsProduct === true ? true:false
		parseData.price = productPrice === undefined ? "":productPrice
		parseData.item = productDescription === undefined ? "" : productDescription
		parseData.vat = productVat === undefined ? "": productVat
		parseData.userId = authInfo.uid
		// const strData = JSON.stringify(jsonData);
		// const strDataFrom = JSON.stringify(parseData);
		// const updatedData = form;
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form/" + form.id;


		axios({
			method: "put",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { data: parseData, html: htmlData },
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if(res.data.status === "success"){
					let form = res.data.data
					dispatch({type:Constants.SUCCESS_PUT_FORM_DATA,form:form,apiResponse:apiResponse})
				}else {
					dispatch({type:Constants.FAILED_PUT_FORM_DATA,apiResponse:apiResponse})
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_PUT_FORM_DATA,apiResponse:errorResponse})
			});
	},

	//rename
	putFormName: (id, name) => async (dispatch, getState) => {
		dispatch({type:Constants.REQUEST_RENAME_FORM})
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form/" + id;
		let {token} = getAuthData()

		axios({
			method: "put",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { name: name },
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if(res.data.status === "success"){
					dispatch({
						type: Constants.SUCCESS_RENAME_FORM,
						formObj: res.data.data,
						apiResponse:apiResponse
					});
				}else{
					dispatch({type:Constants.FAILED_RENAME_FORM,apiResponse:apiResponse})
				}

			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({type:Constants.FAILED_RENAME_FORM,apiResponse:errorResponse})
			});
	},
	// updateFormInfo: (id, value) => async (dispatch, getState) => {
	// 	if (id === "successPage") {
	// 		dispatch({ type: "UPDATE_SUCCESS_PAGE", successPage: value });
	// 	}
	// },
	addFolder: (name, parentId) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/folder/";
		let {token} = getAuthData()

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { name: name, type: "form", parent_id: parentId },
		})
			.then((res) => {
				dispatch({
					type: "ADD_FOLDER_SUCCESS",
					folders: [res.data.data],
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	addForm: (name, parentId) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form";
		let {token} = getAuthData()

		axios({
			method: "POST",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { name: name, parent_id: parentId },
		})
			.then((res) => {
				dispatch({
					type: "ADD_FORM_SUCCESS",
					forms: [res.data.data],
				});
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	fetchForm: (fldId) => async (dispatch, getState) => {
		dispatch({ type: "GET_FORMS" });
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form?folder_id=" + fldId;
		let folderUrl = apiUrl + "v1/folder/form/" + fldId;
		let {token} = getAuthData()
		axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({ type: "GET_ALL_FORM_SUCCESS", forms: res.data.data.items });
			})
			.catch((e) => {
				let aaaa = e;
			});

		axios({
			method: "get",
			url: folderUrl,
			headers: {
				Authorization: token,
			},
		})
			.then((res) => {
				dispatch({ type: "GET_ALL_FOLDER_SUCCESS", folders: res.data.data.items });
			})
			.catch((e) => {
				let aaaa = e;
			});
	},
	deleteAll: (formIds, folderIds) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let formUrl = apiUrl + "v1/form/";
		let folderUrl = apiUrl + "v1/folder/";
		let {token} = getAuthData()

		if (formIds.length > 0) {
			for (let i = 0; i < formIds.length; i++) {
				let url = "";
				url = formUrl + formIds[i];
				axios({
					method: "DELETE",
					url: url,
					headers: {
						Authorization: token,
					},
				})
					.then((res) => {
						dispatch({ type: "DELETE_FORM_SUCCESS", formId: formIds[i] });
					})
					.catch((e) => {
					});
			}
		}
		if (folderIds.length > 0) {
			for (let j = 0; j < folderIds.length; j++) {
				let url = "";
				url = folderUrl + folderIds[j];
				axios({
					method: "DELETE",
					url: url,
					headers: {
						Authorization: token,
					},
				})
					.then((res) => {
						dispatch({ type: "DELETE_FOLDER_SUCCESS", folderId: folderIds[j] });
					})
					.catch((e) => console.log(e));
			}
		}
	},
	renameForm: (id, name) => async (dispatch, getState) => {
		let url = config.api.url + "v1/form/" + id;
		let {token} = getAuthData()
		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				name: name,
			},
		})
			.then((res) => {
				dispatch({
					type: "RENAME_FORM_SUCCESS",
					form: [res.data.data],
					formId: id,
				});
			})
			.catch((e) => console.log(e));
	},
	renameFolder: (id, name) => async (dispatch, getState) => {
		let url = config.api.url + "v1/folder/" + id;
		let {token} = getAuthData()
		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				name: name,
			},
		})
			.then((res) => {
				dispatch({
					type: "RENAME_FOLDER_SUCCESS",
					folder: [res.data.data],
					folderId: id,
				});
			})
			.catch((e) => console.log(e));
	},
	updateFormParent: (formId, parentId) => async (dispatch, getState) => {
		let a = formId;
		let b = parentId;
		let url = config.api.url + "v1/form/" + formId;
		let {token} = getAuthData()

		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				folder_id: parentId,
			},
		})
			.then((res) => {
				dispatch({
					type: "UPDATE_FORM_PARENT_SUCCESS",
					frmId: formId,
				});
			})
			.catch((e) => console.log(e));
	},
	updateFolderParent: (folderId, parentId) => async (dispatch, getState) => {
		let a = parentId;
		let url = config.api.url + "v1/folder/" + folderId;
		let {token} = getAuthData()

		axios({
			method: "PUT",
			url: url,
			headers: {
				Authorization: token,
			},
			data: {
				parent_id: parentId,
			},
		})
			.then((res) => {
				dispatch({
					type: "UPDATE_FOLDER_PARENT_SUCCESS",
					fldrId: folderId,
				});
			})
			.catch((e) => console.log(e));
	},
	copyForm: (formId, parentId) => async (dispatch, getState) => {
		let apiUrl = config.api.url;
		let url = apiUrl + "v1/form/" + formId;
		let {token} = getAuthData()

		let getForm = await axios({
			method: "get",
			url: url,
			headers: {
				Authorization: token,
			},
		});

		let form = await getForm.data.data;
		if (form !== null || form !== undefined) {
			let postUrl = config.api.url + "v1/form";
			let cName = "Copy-" + form.name;

			axios({
				method: "POST",
				url: postUrl,
				headers: {
					Authorization: token,
				},
				data: {
					name: cName,
					html: form.html,
					data: form.content,
					parent_id: parentId,
				},
			})
				.then((res) => {
					dispatch({
						type: "ADD_FORM_SUCCESS",
						forms: [res.data.data],
					});
				})
				.catch((e) => {
					let aaaa = e;
				});
		}
	},
	getCampaignOfThisItem:(type,id)=>async (dispatch,getState) =>{
		let url = config.api.url + "api/campaign/getCampaignOfThisItem"
		dispatch({ type: Constants.REQUEST_GET_CAMPAIGN_OF_THIS_ITEM });
		let {token} = getAuthData()
		axios({
			method: "post",
			url: url,
			headers: {
				Authorization: token,
			},
			data: { type: type, typeId:id},
		})
			.then((res) => {
				let apiResponse = getResponseForNotificaion(res)
				if (res.data.status === "success") {
					let obj = null
					if(res.data.isSelected !== 0){
						obj = {
							campaignId:res.data.campaignId,
							campaignName:res.data.campaignName
						}
					}
					dispatch({ type: Constants.SUCCESS_GET_CAMPAIGN_TEMPLATE,payload:obj });
				} else {
					dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
				}
			})
			.catch((e) => {
				let errorResponse = getResponseForNotificaion(null,{})
				dispatch({ type: Constants.FAILED_GET_CAMPAIGN_OF_THIS_ITEM,payload:null});
			});
	},
	cleanList: () => async (dispatch, getState) => {
		dispatch({ type: "GET_ALL_EMPTY" });
	},
	makeFormEmpty: () => async (dispatch, getState) => {
		dispatch({ type: "GET_FORM_EMPTY" });
	},
	requestFormData: () => async (dispatch, getState) => {},
	startLoaderDialog:()=>async (dispatch,getState)=>{
		dispatch({type:Constants.START_FORM_LOADER_DIALOG})
	},
	stopLoaderDialog:()=>async (dispatch,getState)=>{
		dispatch({type:Constants.STOP_FORM_LOADER_DIALOG})
	},
	resetResponse:() => async (dispatch,getState) =>{
		dispatch({type:Constants.RESET_FORM_API_RESPONSE})
	}
};
