import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import Settings from "./Settings";
import ChatData from "./Chat";
import Contact from "./Contact";
import Mail from "./Mail";
import ToDo from "./ToDo";
import Auth from "./Auth";
import Menu from "./Menu";
import Profile from "./Profile";
import Table from "./Table";
import Home from "./Home";
import EditView from "./EditView";
import TrashView from "./TrashView";
import Email from "./Email";
import Sms from "./Sms";
import Letter from "./Letter";
import Page from "./Page";
import Group from "./Group";
import Filter from "./Filter";
import Form from "./Form";
import {textReducer} from "./Send";
import EmailRelation from "./EmailRelation";
import LetterRelation from "./LetterRelation";
import SmsRelation from "./SmsRelation";
import Report from "./Report";
import Categories from "./Categories";
import Schedule from "./Schedule";
import Relation from "./Relation";
import ImportReducer from "./ImportReducer";
import AccountInformation from "./AccountInformation"
import CampaignReducer from "./CampaignReducer"
import FolderViewReducer from "./FolderViewReducer";
import ImportTranslations from "./importTranslationsReducer";
import BeeReducer from "./BeeReducer";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        chatData: ChatData,
        contacts: Contact,
        mail: Mail,
        toDo: ToDo,
        auth: Auth,
        menu: Menu,
        profile: Profile,
        table: Table,
        home: Home,
        editView: EditView,
        trashView: TrashView,
        email: Email,
        sms: Sms,
        letter: Letter,
        page: Page,
        group: Group,
        form: Form,
        filter: Filter,
        text: textReducer,
        emailRelation: EmailRelation,
        smsRelation: SmsRelation,
        letterRelation: LetterRelation,
        report: Report,
        categories: Categories,
        schedule: Schedule,
        relation: Relation,
        importReducer: ImportReducer,
        accountInformation: AccountInformation,
        campaign: CampaignReducer,
        folderView:FolderViewReducer,
        importTranslations:ImportTranslations,
        bee:BeeReducer
    });
