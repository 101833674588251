import React, { Component } from "react";
import { Document, Page } from 'react-pdf';
import pdfFile from "../../../../../assets/gdrp/test.pdf"
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import Iframe from 'react-iframe'

import DoneIcon from '@material-ui/icons/Done';

import IntlMessages from "util/IntlMessages";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../../actions/Menu";

class Gdrp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPdf:false
        }
    }
    componentDidMount() {
        this.props.getGdprStatus()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debugger
        if(prevProps.menu.gdpr !== this.props.menu.gdpr && this.props.menu.gdpr !== null){
            this.setState({
                count:this.state.showPdf = true
            })
        }
    }
    handleAcceptGdrp = () =>{
        this.props.acceptGdpr()
    }
    render() {
        debugger
        return (
            <div>
                <Grid container>
                    <Grid item md={2}></Grid>
                    <Grid container item md={8} justify="center">
                        <Card style={{width:"100%"}}>
                            <CardHeader
                                title="GDPR compliance"
                            />
                            <CardMedia>
                                <Iframe src="https://view.mybizzmail.com/Verwerkersovereenkomst_Bizzmark_15-05-2018.pdf"
                                        width="100%"
                                        height="600px"
                                        id="myId"
                                        className="myClassname"
                                        display="initial"
                                        position="relative"/>
                            </CardMedia>
                            <CardContent>
                                <Grid container>
                                    {/*<Grid item md={2}></Grid>*/}
                                    <Grid item md={12}>
                                        {this.props.menu.gdpr === null ?
                                            <div>
                                                <Checkbox
                                                    //checked={checked}
                                                    color="primary"
                                                    onChange={this.handleAcceptGdrp}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <h4>
                                                    <span style={{color:"#4CAF50"}}><DoneIcon/></span>
                                                    <IntlMessages id="_ECLICKSPRO_GDPR_CONDITION_ACCEPTED_TEXT" />
                                                    {" "}
                                                    <span style={{fontWeight:"bold"}}>
                                                        {this.props.menu.gdpr.email}
                                                    </span>
                                                    {" "}
                                                    <IntlMessages id="_ON" />
                                                    {" "}
                                                    <span>{this.props.menu.gdpr.date}</span>
                                                </h4>
                                            </div>
                                        }

                                    </Grid>
                                    {/*<Grid item md={2}></Grid>*/}
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item md={2}></Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    menu: state.menu,
});
export default connect(
    (state) => mapStateToProps,
    (dispatch) => bindActionCreators({...actionCreators}, dispatch)
)(Gdrp);