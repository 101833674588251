import React from "react";
import IntlMessages from "util/IntlMessages";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AlternateEmailTwoToneIcon from "@material-ui/icons/AlternateEmailTwoTone";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import PermPhoneMsgTwoToneIcon from "@material-ui/icons/PermPhoneMsgTwoTone";
import PagesTwoToneIcon from "@material-ui/icons/PagesTwoTone";
import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";

function hexToRgb(hex, alpha) {
  hex = hex.replace("#", "");
  let r = parseInt(
    hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  );
  let g = parseInt(
    hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  );
  let b = parseInt(
    hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  );
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const InfoCard = (props) => {
  return (
    <div
      className="jr-card net-chart"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="jr-card-thumb"
        style={{
          backgroundColor: props.color,
          boxShadow: `0 6px 20px 0 ${hexToRgb(
            props.color,
            0.19
          )},0 8px 17px 0 ${hexToRgb(props.color, 0.2)}`,
        }}
      >
        {props.type === "campaign" && <AccountTreeIcon fontSize="default" />}
        {props.type === "email" && (
          <AlternateEmailTwoToneIcon fontSize="default" />
        )}
        {props.type === "letter" && <EmailTwoToneIcon fontSize="default" />}
        {props.type === "sms" && <PermPhoneMsgTwoToneIcon fontSize="default" />}
        {props.type === "page" && <PagesTwoToneIcon fontSize="default" />}
        {props.type === "form" && <ListAltTwoToneIcon fontSize="default" />}
      </div>
      <div className="jr-card-body br-break">
        <h1 className="mb-0" style={{ fontSize: "2rem", textAlign: "center" }}>
          <strong>{props.value}</strong>
        </h1>
        <p className="mb-0">
          <IntlMessages id={"_" + props.type.toUpperCase()} />
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
InfoCard.defaultProps = {
  styleName: "",
};
