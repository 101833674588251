import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import IntlMessages from "../../../../util/IntlMessages";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import config from "../../../../config";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const useStyles = theme => ({
    eproLabel:{
        margin:"15px 0px 15px 0px",
    },
    labelLink:{
        color:"#B73110",
        '&:hover':{
            color:"#DEA79A",
            textDecoration:"none"
        }
    }
});

class AddPaymentMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mollieApiKey:"",
            mollieApiSecret:"",
            mollieName:"",
            mollieEmail:"",
        }
    }
    componentDidMount() {
        if(this.props.info !== null){
            let key = this.props.info.data.mollie_api_key
            let secret = this.props.info.data.mollie_api_secret
            let name = this.props.info.data.mollie_name
            let email = this.props.info.data.mollie_email
            // const {mollie_api_key = "" as key, mollie_api_secret = "" as secret } = this.props.info.data
            this.setState({
                mollieApiKey:key,
                mollieApiSecret:secret,
                mollieName:name,
                mollieEmail:email
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.info !== null){
            if(this.props.info.data !== prevProps.info.data){
                let key = this.props.info.data.mollie_api_key
                let secret = this.props.info.data.mollie_api_secret
                this.setState({
                    mollieApiKey:key,
                    mollieApiSecret:secret
                })
            }
        }
    }

    updateMollieInfo = () =>{
        if((this.state.mollieApiSecret === null && this.state.mollieApiKey === null)){
            return
        }
        this.props.updateMollieInfo(this.state.mollieApiKey,this.state.mollieApiSecret,this.state.mollieName,this.state.mollieEmail)
    }
    handleChange = (event) =>{
        let id = event.target.id
        this.setState({
            [id]:event.target.value
        })
    }
    render() {
        const {classes} = this.props
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="mollieName"
                                label={<IntlMessages id="_ECLICKSPRO_MOLLIE_NAME" /> }
                                type="text"
                                value={this.state.mollieName}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="mollieEmail"
                                label={<IntlMessages id="_ECLICKSPRO_MOLLIE_EMAIL" /> }
                                type="text"
                                value={this.state.mollieEmail}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div>
                            <TextField
                               // autoFocus
                                margin="dense"
                                id="mollieApiKey"
                                label={<IntlMessages id="_ECLICKSPRO_MOLLIE_API_KEY" /> }
                                type="text"
                                value={this.state.mollieApiKey}
                                fullWidth
                                // error={this.state.mollieApiKey === ""}
                                // helperText={this.state.mollieApiKey === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}
                                onChange={this.handleChange}
                            />

                            <label className={classes.eproLabel}>
                                <a className={classes.labelLink} href="https://www.mollie.com/en" target="_blank">{<IntlMessages id="_ECLICKSPRO_MOLLIE_PLEASE_ACTIVE_YOUR_PAYMENT_SETTINGS_ON_MOLLIE"/>}</a>
                            </label>
                        </div>
                    </Grid>
                    {/*<Grid item xs={4}>*/}
                    {/*    <div>*/}
                    {/*        <TextField*/}
                    {/*            margin="dense"*/}
                    {/*            id="mollieApiSecret"*/}
                    {/*            label={<IntlMessages id="_ECLICKSPRO_MOLLIE_API_SECRET" /> }*/}
                    {/*            type="text"*/}
                    {/*            value={this.state.mollieApiSecret}*/}
                    {/*            fullWidth*/}
                    {/*            // error={this.state.mollieApiSecret === ""}*/}
                    {/*            // helperText={this.state.mollieApiSecret === "" ? <IntlMessages id="_ECLICKSPRO_THIS_FIELD_IS_REQUIRED" /> : ""}*/}
                    {/*            onChange={this.handleChange}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        <div>
                            <Button
                                variant="contained"
                                className="jr-btn bg-success text-white"
                                style={{ marginTop: "18px" }}
                                onClick={this.updateMollieInfo}
                            >
                                <SaveIcon fontSize="large" />
                            </Button>
                        </div>
                    </Grid>

                </Grid>
            </div>
        );
    }
}

export default withStyles(useStyles)(AddPaymentMethod);