import React, { createContext, useState, useEffect } from "react";
import config from "config";
import { getAuthData } from "../common/helpers/AuthHelper";

const getMenuData = async () => {
  let {token} = getAuthData()
  const response = await fetch(`${config.api.url}api/menu`, {
    method: "POST",
    headers: {
      Authorization: token,
    },
  })
    .then((res) => res.json())
    .then((json) => json);
  return response;
};

export const MenuContext = createContext();

export const MenuProvider = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getMenuData().then(setData);
  }, []);

  if (data == null) {
    return null; // or Loading or whatever
  }

  return (
    <MenuContext.Provider value={{ menu: data }}>
      {props.children}
    </MenuContext.Provider>
  );
};
