import { FETCH_TABLE, FETCH_TABLE_SUCCESS } from "constants/ActionTypes";
import * as Constants from "../common/Constants"

const INIT_STATE = {
  loader: true,
  rowLoader:false,
  params: null,
  data: null,
  rows:[],
  columns:null,
  totalItem:0,
  allSavedTemplate:[],
  allAccounts:[],
  rowFields:[],
  permissions:null,
  positionData:null,
  listingApiResponse:null,
  listingResponseStatus:null,
  refetchRows:false,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TABLE: {
      // console.log("action.payload FETCH_TABLE->", action);
      return {
        ...state,
        loader: true,
        params: action.payload
      };
    }
    case FETCH_TABLE_SUCCESS: {
      // console.log("action.payload FETCH_TABLE_SUCCESS ->", action);
      return {
        ...state,
        loader: false,
        data: action.payload
      };
    }
    case Constants.REQUEST_GET_TABLE_DATA:{
      return {
        ...state,
        loader: true,
        data:null,
        rows:null,
        columns:null,
        totalItem: 0,
        allSavedTemplate: [],
        listingApiResponse: null,
        listingResponseStatus: null
      }
    }
    case Constants.SUCCESS_GET_TABLE_DATA:{
      return {
        ...state,
        loader: false,
        rows:action.rows,
        columns:action.columns,
        totalItem: action.totalItem,
        allSavedTemplate: action.allSavedTemplate,
        data:action.data,
        // listingApiResponse: action.apiResponse,
        listingResponseStatus:null
      }
    }
    case Constants.FAILED_GET_TABLE_DATA:{
      return {
        ...state,
        loader: true,
        //listingApiResponse: action.apiResponse,
        listingResponseStatus: action.status

      }
    }
    case Constants.REQUEST_GET_TABLE_ROWS:{
      return {
        ...state,
        rowLoader: true,
        totalItem:0,
        rows: []
      }
    }
    case Constants.SUCCESS_GET_TABLE_ROWS:{
      return {
        ...state,
        rowLoader: false,
        totalItem:action.totalItem,
        rows: action.rows

      }
    }
    case Constants.REQUEST_GET_TABLE_ROW_BY_ID:{
      return {
        ...state,
        rowFields: [],
        permissions: null,
        positionData: null
      }
    }
    case Constants.SUCCESS_GET_TABLE_ROW_BY_ID:{
      return {
        ...state,
        rowFields: action.rowFields,
        permissions: action.permissions,
        positionData: action.positionData
      }
    }
    case Constants.RESET_LISTING_API_RESPONSE:{
      return {
        ...state,
        listingApiResponse: null
      }
    }
    case Constants.REQUEST_GET_ALL_ACCOUNT:{
      return {
        ...state,
        allAccounts: []
      }
    }
    case Constants.SUCCESS_GET_ALL_ACCOUNT:{
      return {
        ...state,
        allAccounts: action.accounts
      }
    }
    case Constants.REQUEST_DELETE_TABLE_ROWS:{
      return{
        ...state,
        refetchRows:false,
        listingApiResponse:null
      }
    }
    case Constants.SUCCESS_DELETE_TABLE_ROWS:{
      return{
        ...state,
        refetchRows:true,
        listingApiResponse:action.apiResponse
      }
    }
    case Constants.FAILED_DELETE_TABLE_ROWS:{
      return{
        ...state,
        refetchRows:true,
        listingApiResponse:action.apiResponse
      }
    }
    case Constants.FAILED_GET_ALL_ACCOUNT:{
      return {
        ...state,
        allAccounts: []
      }
    }
    default:
      return state;
  }
};
