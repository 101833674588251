import React from "react";
import { Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// const srcUrl = "https://s3-eu-west-1.amazonaws.com/app.e-clicks.pro/static/media/logo.7cd714d7.png"
import srcUrl from "../../../../../../../assets/images/logo.png"
import { eclicksProColors } from "../../../../../../../common/functions/EproCommonFunctions";

const styles = StyleSheet.create({
    invoiceInfo:{
        // marginTop:"300px"
        display:"flex",
        flexDirection:"row"
    },
    logo:{
        width:"40%",
        // backgroundColor:"#A3A3A3",
    },
    invoiceNumber:{
        width: "60%",
        // backgroundColor: "#FAD8D6",
        margin:"12px 10x 10px 0px"
    },
    image:{
        width:"60%"
    },
    text:{
        fontSize:"10",
        textAlign:"right",
        color:eclicksProColors.textGray,
        marginTop:"10px"
    }
});

function InvoiceInfo(props) {
    const {data} = props
    let d = new Date(parseInt(data.created_at) * 1000)
    let dArr = d.toString().split(" ")
    return(
        <View style={styles.invoiceInfo}>
            <View style={styles.logo}>
                <Image style={styles.image} src={srcUrl}/>
            </View>
            <View style={styles.invoiceNumber}>
                <Text style={styles.text}>{`Invoice no: ${data.id}`}</Text>
                <Text style={styles.text}>{`${dArr[1]}-${dArr[2]}-${dArr[3]}` }</Text>
            </View>
        </View>
    )
}
export default InvoiceInfo;