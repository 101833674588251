import React, {Component, Fragment} from "react";
import CircularLoader from "../loader/CircularLoader";
import {Button, Checkbox, CircularProgress, TextareaAutosize} from "@material-ui/core";
import IntlMessages from "../../../../util/IntlMessages";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import {DatePicker} from "material-ui-pickers";
import moment from "moment";
import Input from "@material-ui/core/Input";
import CustomFieldDialog from "../../editView/components/CustomFieldDialog";
import RelationDialogBox from "../../editView/components/RelationDialogBox";

class FieldsView extends Component {
    render() {
        const {tableName,loader,item,intl} = this.props
        const tempFieldName = [];
        let invitationInfo = ""
        let asteriskColor = "grey"


        return (
            <div>
                {loader?
                    <CircularLoader/>
                    :
                    <div>
                        <div className="row mb-3">
                            <div
                                className="col-md-12 col-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    flexWrap: "wrap",
                                    marginTop: "20px",
                                }}
                            >
                                {tableName === "relation" && (
                                    <Button
                                        variant="contained"
                                        className="jr-btn bg-teal text-white jr-btn-lg"
                                        onClick={() => this.setState({ openCustomField: true })}
                                        style={{
                                            maxHeight: "39.19px",
                                            minHeight: "39.19px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <IntlMessages id="_ECLICKSPRO_ADD_CUSTOM_FIELD" />
                                    </Button>
                                )}
                                {tableName === "relation" && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="jr-btn text-white jr-btn-lg"
                                        onClick={() =>
                                            this.setState({
                                                typeRelationDialog: "relation",
                                                openRelationDialog: true,
                                            })
                                        }
                                        style={{
                                            maxHeight: "39.19px",
                                            minHeight: "39.19px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <IntlMessages id="_ECLICKSPRO_ADD_RELATION_TO_GROUP" />
                                    </Button>
                                )}
                                {tableName === "relation" && (
                                    <Button
                                        variant="contained"
                                        className="jr-btn bg-red text-white jr-btn-lg"
                                        onClick={() =>
                                            this.setState({
                                                typeRelationDialog: "unsubscribe",
                                                openRelationDialog: true,
                                            })
                                        }
                                        style={{
                                            maxHeight: "39.19px",
                                            minHeight: "39.19px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <IntlMessages id="_ECLICKSPRO_UNSUBSCRIBE_RELATION" />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                //    end of content div
                }
                
            </div>
        );
    }
}

export default FieldsView;