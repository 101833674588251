import { FETCH_EDITVIEW, FETCH_EDITVIEW_SUCCESS } from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  params: null,
  item: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_EDITVIEW: {
      return {
        ...state,
        loader: true,
        params: action.payload
      };
    }
    case FETCH_EDITVIEW_SUCCESS: {
      return {
        ...state,
        loader: false,
        item: action.payload
      };
    }
    default:
      return state;
  }
};
